import React, {useCallback, useEffect, useRef, useState} from 'react';
import { createPortal } from 'react-dom';

import styles from './Modal.module.scss';
import classNames from 'classnames';

import {Button, Card} from 'ui/basic';

import { useTranslate } from 'utils/translator';
import {useDebounce, useWindowHeight, useWindowWidth} from 'utils/hooks';
import {pxToNumber} from 'utils/styleTools';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';

const Modal = (props) => {
  const {
    header, headerPosition = 'left',
    subHeader, subHeaderPosition = 'left',
    preHeader,
    children,
    controlScrollbar = true,
    hint, hintPosition = 'center',
    buttonsPosition = 'right',
    buttonsDirection = 'row',
    primaryButtonTitle, primaryButtonDisabled,
    secondaryButtonTitle, secondaryButtonDisabled,
    redButtonTitle, redButtonDisabled,
    showButtonProgressIndicator,
    ignoredKeys = [],
    feedbackSubmitted, onFeedbackSubmit,
    closeOnCancel = true,
    onClose = () => {},
    closeOnConfirm = true,
    onConfirm
  } = props;

  const translate = useTranslate();

  const modalRef = useRef();
  const modalParentRef = useRef();

  // STATES
  const [isClosing, setIsClosing] = useState(false);
  const [scrollable, setScrollable] = useState();

  const windowHeight = useWindowHeight();
  const windowWidth = useWindowWidth();
  const debouncedWindowHeight = useDebounce(windowHeight, 250);
  const debouncedWindowWidth = useDebounce(windowWidth, 250);

  useEffect(() => {
    if (!modalRef.current || !modalRef.current.children || modalRef.current.children.length === 0) {
      return;
    }

    if (window.innerHeight < (modalRef.current.children[0].clientHeight + pxToNumber(styles.spaceL) + pxToNumber(styles.spaceL))) {
      setScrollable(true);
    } else {
      setScrollable(false);
    }
  }, [ debouncedWindowHeight, debouncedWindowWidth, children ]);

  const handleConfirm = useCallback(() => {
    if (!closeOnConfirm && onConfirm) {
      onConfirm();
      return;
    }

    setIsClosing(true);

    setTimeout(() => {
      if (onConfirm) {
        onConfirm();
      }
    }, 250);
  }, [closeOnConfirm, onConfirm]);

  const handleClose = useCallback((closeButtonClicked) => {
    if (!closeOnCancel && onClose) {
      onClose(closeButtonClicked);
      return;
    }

    setIsClosing(true);

    setTimeout(() => {
      if (onClose) {
        onClose(closeButtonClicked);
      }
    }, 250);
  }, [closeOnCancel, onClose]);

  const handleKeyDown = useCallback((event) => {
    if (ignoredKeys.includes(event.key)) {
      return;
    }

    if (event.key === 'Enter') {
      // do not proceed if confirmation buttons are disabled
      if ((redButtonTitle && redButtonDisabled) || (primaryButtonTitle && primaryButtonDisabled)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      if (onConfirm) {
        handleConfirm();
      }
      else if (onClose) {
        handleClose(false);
      }
    } else if (event.key === 'Escape' && onClose) {
      event.preventDefault();
      event.stopPropagation();
      handleClose(false);
    }
  }, [
    onConfirm, onClose, handleConfirm, handleClose, ignoredKeys,
    primaryButtonTitle, primaryButtonDisabled, redButtonTitle, redButtonDisabled
  ]);

  const handleFeedbackSubmit = (feedback) => {
    if (onFeedbackSubmit) {
      onFeedbackSubmit(feedback);
    }
  }

  useEffect(() => {
    if (controlScrollbar) {
      disableScrollingOnBody();
    }

    return () => {
      if (controlScrollbar) {
        enableScrollingOnBody();
      }
    }
  }, [controlScrollbar]);


  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, [modalRef]);

  useEffect(() => {
    if (modalParentRef.current) {
      modalParentRef.current.scrollTo(0, 0);
    }
  }, []);

  return createPortal(
    <div
      ref={modalParentRef}
      className={classNames(
        styles.modalParent,
        {[styles.isClosing]: isClosing}
      )}
      onClick={(event) => {
        if (modalParentRef.current === event.target || modalRef.current === event.target) {
          handleClose(false);
        }
      }}
      onKeyUp={handleKeyDown}
    >
      <div className={classNames(
        styles.modal,
        {[styles.isClosing]: isClosing},
        {[styles.scrollable]: scrollable}
      )} ref={modalRef} tabIndex={'0'}>
        <Card hasPaddingsForModal>
          {/*PRE HEADER*/}
          {preHeader && <div className={styles.preHeader}>{preHeader}</div>}

          {/*HEADER*/}
          <div className={classNames(styles.header, styles[headerPosition.toLowerCase()])}>{header}</div>

          {/*SUB HEADER*/}
          {subHeader &&
          <div className={classNames(styles.subHeader, styles[subHeaderPosition.toLowerCase()])}>{subHeader}</div>
          }

          {/*CONTENT*/}
          <div className={styles.content}>
            {children}
          </div>

          {/*FOOTER*/}
          <div className={classNames(
            styles.footer,
            styles[buttonsPosition.toLowerCase()],
            styles[buttonsDirection.toLowerCase()]
          )}>
            {/*SECONDARY BUTTON*/}
            {secondaryButtonTitle &&
            <Button
              size={'S'}
              looks={'secondary'}
              disabled={secondaryButtonDisabled}
              showProgressIndicator={showButtonProgressIndicator}
              onClick={() => handleClose(true)}
            >
              {secondaryButtonTitle}
            </Button>
            }

            {/*PRIMARY BUTTON*/}
            {primaryButtonTitle &&
            <Button
              size={'S'}
              disabled={primaryButtonDisabled}
              onClick={handleConfirm}
            >
              {primaryButtonTitle}
            </Button>
            }

            {/*RED BUTTON*/}
            {redButtonTitle &&
            <Button
              size={'S'}
              looks={'cancel'}
              disabled={redButtonDisabled}
              onClick={handleConfirm}
            >
              {redButtonTitle}
            </Button>
            }
          </div>

          {/*HINT*/}
          {hint &&
          <div className={classNames(styles.hint, styles[hintPosition.toLowerCase()])}>{hint}</div>
          }

          {/*FEEDBACK*/}
          {onFeedbackSubmit &&
          <div className={styles.feedback}>
            {feedbackSubmitted &&
            <div className={styles.feedbackTitle}>
              {translate('mind_journey_dim_report__feedback_success') || 'Thanks for the feedback!'}
            </div>
            }

            {!feedbackSubmitted &&
            <>
              <div className={styles.feedbackTitle}>
                {translate('feedback_question') || 'War diese Information hilfreich?'}
              </div>
              <div className={styles.feedbackButtons}>
                <Button
                  size={'S'}
                  looks={'tertiary'}
                  onClick={() => handleFeedbackSubmit('Yes')}
                >
                  {translate('feedback_yes_lbl') || 'Yes'}
                </Button>
                <Button
                  size={'S'}
                  looks={'tertiary'}
                  onClick={() => handleFeedbackSubmit('No')}
                >
                  {translate('feedback_no_lbl') || 'No'}
                </Button>
              </div>
            </>
            }

          </div>
          }
        </Card>
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
