// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './HotChipsPeople.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group'

// OTHER COMPONENTS
import { Button, InputNext, Chip, BluCSSTransition, ImgCircle } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { MIN_SEARCH_LENGTH, DEBOUNCE_TIME } from 'api';
import { useDebounce } from 'utils/hooks';
import { highlight } from 'utils/textTools';


// STORE

// CONFIG & DATA
const CONFIG = {
  maxResults: 5,
  moreResults: 10,
  blurDelay: 20,
};

// COMPONENT: HotChipsPeople
const HotChipsPeople = (props) => {
  // PROPS
  const {
    type = 'text',
    values,
    label, placeholder,
    hint, emailHint, emailHintError,
    addNewUsers = true,
    showImage = true,
    noResultsHint,
    disabled,
    validate, onUpdate,
    // params for employeeSearch
    onSearch,
    searchResults,
    loadingSearchResults,
  } = props;

  const translate = useTranslate();

  // COMPONENT/UI STATE and REFS
  const [ list, setList ] = useState(values || []);
  const [ value, setValue ] = useState('');
  const [ error, setError ] = useState(null);
  const [ hideFlyout, setHideFlyout ] = useState(true);
  const [ hideFlyoutOverride, setHideFlyoutOverride ] = useState(false);
  const [ blurTimeout, setBlurTimeout ] = useState();
  const [ showMoreResults, setShowMoreResults ] = useState(false);
  const [ activeResultIndex, setActiveResultIndex ] = useState(0);
  const [ isEmail, setIsEmail ] = useState(false);
  // disabled
  const [bigButtonDisabled, setBigButtonDisabled] = useState(true);

  // SPECIAL HOOKS
  const debouncedValue = useDebounce(value, DEBOUNCE_TIME);

  // EFFECT HOOKS
  useEffect(() => {
    if (
      (debouncedValue && debouncedValue.length >= MIN_SEARCH_LENGTH)
      || debouncedValue === '' // allow reset
    ) {
      // don't propagate emails
      if(!isEmail) {
        onSearch(debouncedValue);
      }
    }
  }, [debouncedValue, onSearch, isEmail]);

  useEffect(() => {
    if(searchResults || loadingSearchResults) {
      setHideFlyout(false);
      setShowMoreResults(false);
    }
  }, [searchResults, loadingSearchResults]);

  useEffect(() => {
    setList(values);
  }, [values]);

  // STORE HOOKS

  // METHODS

  // HANDLES
  const handleSearchResultClick = (result) => {
    addItem(result);
    setValue('');
    setActiveResultIndex(0);
    setHideFlyout(true);
  };
  const handleBlur = () => {
    // handle blur with a delay so focus can be prevent blur
    // when for instance the "show more" button in the
    // result flyout is clicked
    setBlurTimeout(setTimeout(() => {
      setHideFlyoutOverride(true)
    }, CONFIG.blurDelay));
  };
  const handleFocus = () => {
    setHideFlyoutOverride(false);
    if (blurTimeout) {
      clearTimeout(blurTimeout);
      setBlurTimeout();
    }
  };

  // HELPERS, HANDLES, RENDERS
  const addItem = (item) => {
    // prevent duplicates
    if (list.map(listItem => listItem.value).indexOf(item.value) !== -1) {
      setError({ duplicate: true })
      return;
    }
    list.unshift(item); // add at the beginning for more dynamic feel on mobile
    const newList = [...list];
    setList(newList);
    onUpdate && onUpdate(newList);
  };
  const removeItem = (item) => {
    const newList = list.filter((listItem) => listItem !== item);
    setList(newList);
    onUpdate && onUpdate(newList);
  }

  // RENDER: HotChips
  return (
    <div className={classNames(styles.hotChipsPeople)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {label && <div className={styles.label}>
        { label }
      </div>}

      {/* INPUT */}
      <InputNext
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete='chrome-off'
        validate={ // validate only if it's an email
          isEmail
          ? validate
          : {}
        }
        onConfirm={(confirmedValue) => {
          if (!isEmail && !hideFlyout && searchResults && searchResults[activeResultIndex]) {
            handleSearchResultClick(searchResults[activeResultIndex]);
          }
          else if (isEmail) {
            if (addNewUsers) {
              handleSearchResultClick({
                isEmail: true,
                value: confirmedValue,
                label: confirmedValue,
              });
            }
          }
        }}
        onArrowUp={(event) => {
          if (!hideFlyout && searchResults) {
            event.preventDefault();
            let newResultIndex = activeResultIndex - 1;
            if (newResultIndex >= 0) {
              setActiveResultIndex(newResultIndex);
            }
          }
        }}
        onArrowDown={(event) => {
          if (!hideFlyout && searchResults) {
            event.preventDefault();
            let newResultIndex = activeResultIndex + 1;
            if (newResultIndex <= searchResults.length - 1) {
              setActiveResultIndex(newResultIndex);
            }
          }
        }}
        onError={(error) => {
          setError(error);
        }}
        onChange={(newValue, newError) => {
          setValue(newValue);
          if (newValue === '') {
            setHideFlyout(true);
          }
          if (newValue.includes('@')) {
            setIsEmail(true);
          }
          else {
            setIsEmail(false);
          }
          if (error) {
            setError(null);
          }
          const disabled = newError ? true : false;
          setBigButtonDisabled(disabled);
        }}
        bigButton
        bigButtonDisabled={bigButtonDisabled || (isEmail && error) || (!isEmail && (!searchResults || !searchResults.length))}
      />

      {/* FLYOUT */}
      <div className={styles.flyoutContainer}
        onFocus={handleFocus}
      >
        { !hideFlyout && !hideFlyoutOverride && (
          <div className={styles.flyout}>

            {/* RESULTS, NOT AN EMAIL */}
            { searchResults && searchResults.length !== 0
            && !isEmail && !loadingSearchResults
            && (
              <div>
                { searchResults.slice(0, showMoreResults ? CONFIG.moreResults : CONFIG.maxResults) // show max 5 for now
                  .map((result, index) => {
                  const labels = result.label && result.label.split(' ');
                  return (
                    <div
                      key={result.value}
                      tabIndex={'0'}
                      className={classNames(styles.searchResult, {
                        [styles.active]: activeResultIndex === index
                      })}
                      onClick={() => {handleSearchResultClick(result)}}>
                      { result.img && (
                        <div className={styles.imgCircle}>
                          <ImgCircle className='darker' size='S' label1={labels[0]} label2={labels[1]} src={result.img} />
                        </div>
                      )}
                      <div className={styles.labels}>
                        <div className={styles.label}>
                          { highlight(result.label, value) }
                        </div>
                        <div className={styles.subLabel}>
                          { highlight(result.subLabel, value) }
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* SHOW MORE */}
                { searchResults.length > 5 && !showMoreResults && (
                  <div className={styles.showMoreResults}>
                    <Button looks='tertiary' size='S'
                      onClick={() => {
                        setShowMoreResults(true);
                        handleFocus(); // prevent blur
                      }}>
                      { translate('create_role_add_emp_show_more') || 'Weitere anzeigen'}
                    </Button>
                  </div>
                )}
              </div>
            )}

            {/* NO RESULTS, NOT AN EMAIL */}
            { searchResults && searchResults.length === 0
            && !isEmail && !loadingSearchResults
            && (
              <div className={styles.message}>
                { noResultsHint}
              </div>
            )}

            {/* EMAIL */}
            { isEmail && !loadingSearchResults && (
              <div className={styles.message}>
                {addNewUsers && (
                  <>
                    { !error && (
                      emailHint
                    )}
                    { error && (
                      emailHintError
                    )}
                  </>
                )}

                {(!addNewUsers && isEmail) && (
                  translate('hotchips_adding_users_not_possible_hint') || 'You don’t have the necessary rights to invite employees to the application'
                )}
              </div>
            )}

            {/* LOADING  */}
            { loadingSearchResults && (
              <div className={styles.skeleton}>
                <div className={styles.bar1}>
                </div>
                <div className={styles.bar2}>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* HINT */}
      {hint && <div className={classNames(styles.hint)}>
        { hint }
      </div>}




      {/* CHIPS */}
      <div className={styles.chips}>
        <TransitionGroup>
          { list.map((item) => {
            const { isEmail, img, label } = item;
            return (
              // key=item.value because key=index scrambles the animations when removing items
              <BluCSSTransition key={item.value}
                classNames={{ ...styles }}>
                <Chip
                  key={item.value}
                  img={img}
                  showImage={showImage}
                  label={!isEmail ? label : ''}
                  onClick={() => {
                    removeItem(item);
                  }}
                >
                  {isEmail ? label : ''}
                </Chip>
              </BluCSSTransition>
            );
          })}
        </TransitionGroup>
      </div>

    </div>
  );
};

export default HotChipsPeople;
