import React, {useEffect, useState} from 'react';
import styles from './CompetenciesResult.module.scss';

import {useDispatch, useSelector} from 'react-redux';

import {getResultInfoDescription, getResultInfoTitle, getTranslationIds} from './translationIds';

// ASSETS
import {IconsSvg} from 'assets/icons';

// UTILS
import { useTranslate } from 'utils/translator';

import {normalizeText} from 'utils/normalizeText';
import {useBreakpoint} from 'utils/hooks';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

import {getAssessment} from 'store/actions';

import {InfoTitle, Modal} from 'ui/basic';
import CompetenciesResultSkeleton from './CompetenciesResultSkeleton';
import SteppedScaleResultItem from '../SteppedScaleResultItem';

const DEFAULT_INFO_TITLE = 'Leadership Competencies';
const DEFAULT_INFO_DESCRIPTION =
  'Due to the constantly changing requirements in today\'s world,' +
  'the role of the manager also changes. Managers are therefore faced' +
  'with the challenge of continuously developing their skills and adapting' +
  'their leadership style to the respective context.' +
  'The selection of suitable managers in the company is also of great importance,' +
  'since the satisfaction and loyalty of your own employees can thus be significantly' +
  'influenced (positively and negatively).';


const CompetenciesResult = (props) => {
  const {
    assessmentType = ASSESSMENT_TYPES.KEY_COMPETENCIES,
    results,
    noDataText,
    footerText,
    showExportIcon,
    exportView,
    onExport = () => {}
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  const DownloadIcon = IconsSvg.DownloadSvg;

  const [infoPageIsVisible, setInfoPageIsVisible] = useState(false);
  const [infoPageTitle, setInfoPageTitle] = useState();
  const [infoPageDescription, setInfoPageDescription] = useState();

  const translationIds = getTranslationIds(assessmentType);

  const currentLanguage = useSelector(state => state.localisation?.currentLanguage);

  const resultSchemaLanguage = useSelector(state => state.assessments?.[assessmentType]?.resultSchemaLanguage);
  const resultSchema = useSelector(state => state.assessments?.[assessmentType]?.resultSchema);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (!resultSchema || !results) {
      return;
    }

    const mappedData = resultSchema.map(resultSchemaItem => {
      const dimensionResultItem = results.find(resultItem => resultItem.id === resultSchemaItem.id);

      return {
        id: resultSchemaItem.id,
        name: resultSchemaItem.name,
        result: dimensionResultItem && dimensionResultItem.result,
        referenceValue: dimensionResultItem && dimensionResultItem.range && dimensionResultItem.range[0],
        subDimensions: resultSchemaItem.subDimensions.map(subDimension => {
          const subDimensionResultItem = results.find(resultItem => resultItem.id === subDimension.id);
          return {
            id: subDimension.id,
            name: subDimension.name,
            result: subDimensionResultItem && subDimensionResultItem.result,
            referenceValue: subDimensionResultItem && subDimensionResultItem.range && subDimensionResultItem.range[0]
          }
        })
      }
    })

    setData(mappedData);
  }, [resultSchema, results]);

  useEffect(() => {
    if (!resultSchema || resultSchemaLanguage !== currentLanguage) {
      dispatch(getAssessment(assessmentType));
    }
  }, [
    dispatch,
    assessmentType,
    resultSchema,
    currentLanguage,
    resultSchemaLanguage
  ]);

  if (!data || data.length === 0 || !resultSchema) {
    return (
      <div className={styles.competenciesResult}>
        <CompetenciesResultSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.competenciesResult}>
      <div id={'container-id'} className={styles.container}>
        <div className={styles.mainHeader}>
          {exportView ?
            <span className='blu-typeLabel'>{translate(translationIds.resultTitle) || normalizeText(assessmentType)}</span> :
            <InfoTitle
              title={translate(translationIds.resultTitle) || normalizeText(assessmentType)}
              onClick={() => {
                setInfoPageTitle(translate(translationIds.resultInfoTitle) || DEFAULT_INFO_TITLE);
                setInfoPageDescription(translate(translationIds.resultInfoDescription) || DEFAULT_INFO_DESCRIPTION);
                setInfoPageIsVisible(true);
              }}
            />
          }

          {(!bp.isXs && !exportView && showExportIcon) &&
          <DownloadIcon onClick={onExport}/>
          }
        </div>

        {noDataText && <div className={styles.noDataText}>{noDataText}</div>}

        {!noDataText &&
          <>
            <div className={styles.rows}>
              {data.map((dimension, index) => (
                <SteppedScaleResultItem
                  key={`dimension-${index}`}
                  data={dimension}
                  isExpandable={!exportView}
                  onInfoClick={(facetIndex) => {
                    setInfoPageTitle(translate(getResultInfoTitle(assessmentType, index + 1, facetIndex)) || DEFAULT_INFO_TITLE);
                    setInfoPageDescription(translate(getResultInfoDescription(assessmentType, index + 1, facetIndex)) || DEFAULT_INFO_DESCRIPTION);
                    setInfoPageIsVisible(true);
                  }}
                />
              ))}
            </div>

            {footerText && <div className={styles.footerText}>{footerText}</div>}
          </>
        }

        {infoPageIsVisible &&
        <Modal
          header={infoPageTitle}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          onConfirm={() => setInfoPageIsVisible(false)}
          onClose={() => setInfoPageIsVisible(false)}
        >
          {infoPageDescription}
        </Modal>
        }
      </div>
    </div>
  )
};

export default CompetenciesResult;
