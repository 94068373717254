// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './TeamDiagramBipolarNext.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { PillGroup, ImgPin } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { numberWithForcedDecimals } from 'utils/numberFormatting';

// STORE

// CONFIG & DATA

// COMPONENT: TeamDiagramBipolarNext
const TeamDiagramBipolarNext = (props) => {
  // PROPS
  const {
    // ranges
    showRanges = false,
    labelRange = '',
    // all
    labelLeft = '',
    labelCenter = '',
    labelRight = '',
    grid = [],
    valueRange = [-5, 5],
    dimensions = [],
    onItemClick = () => {},
  } = props;

  // COMPONENT/UI STATE and REFS

  // buildup
  const [ isBuildUp, setIsBuildUp ] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBuildUp(false);
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, []);


  // activeDimension & activeDimensionIndex
  const [ activeDimension, setActiveDimension ] = useState({ data: [] });
  const [ activeDimensionIndex, setActiveDimensionIndex ] = useState(0);
  useEffect(() => {
    const index = activeDimensionIndex >= dimensions.length - 1
      ? dimensions.length - 1
      : activeDimensionIndex;

    const activeDimension = dimensions[index];
    setActiveDimensionIndex(index);
    setActiveDimension(activeDimension);

    // update labels if dimensions have own labels
    setLabelLeftOverride(activeDimension.labelLeft);
    setLabelCenterOverride(activeDimension.labelCenter);
    setLabelRightOverride(activeDimension.labelRight);

  }, [dimensions, activeDimensionIndex]);

  // labels
  const [ labelLeftOverride, setLabelLeftOverride ] = useState();
  const [ labelCenterOverride, setLabelCenterOverride ] = useState();
  const [ labelRightOverride, setLabelRightOverride ] = useState();

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handlePillClick = (item, index) => {
    setActiveDimensionIndex(index);
  };


  // HELPERS

  // RENDERS

  // RENDER: TeamDiagramBipolarNext
  return (
    <div className={classNames(styles.teamDiagramBipolarNext)}>

      {/* PILLGROUP WITH DIMENSIONS */}
      <PillGroup
        pillItems={dimensions.map(({ label }) => {return { label };})}
        onPillClick={handlePillClick}
      />

      {/* LABELS */}
      <div className={styles.labels}>
        <div className={styles.labelLeft}>
          { labelLeftOverride || labelLeft }
        </div>
        <div className={styles.labelCenter}>
          { labelCenterOverride || labelCenter }
        </div>
        <div className={styles.labelRight}>
          { labelRightOverride || labelRight }
        </div>
      </div>

      {/* DIAGRAM */}
      <div className={styles.diagramContainer}>
        {/* GRID */}
        <div className={styles.grid}>
          <div className={styles.lineCenter}></div>

          {grid.map((value) => {
            const valueDistance = value / valueRange[0] * 50;
            const marginLeft = 50 + valueDistance;
            return (
              <div key={value} className={styles.line}
                style={{ marginLeft: `${marginLeft}%` }}></div>
            );
          })}
        </div>

        {/* BARS */}
        { activeDimension.data.map((item, index) => {

          const value = item.value;
          const maxLeft = valueRange[0];
          const maxRight = valueRange[1];

          const leftWidth = value >= 0
            ? 0
            : value / maxLeft * 100;
          const rightWidth = value <= 0
            ? 0
            : value / maxRight * 100;

          let markerPosition = value < 0
            ? 50 - leftWidth / 2
            : 50 + rightWidth / 2;
          markerPosition = isBuildUp
            ? 50
            : markerPosition;

          const moveToLeft = value > 0;

          return (
            <div key={index} className={styles.barContainer}
              onClick={() => {
                onItemClick({
                  ...item,
                  dimensionId: activeDimension.id,
                  dimensionName: activeDimension.label
                }, index);
              }}>

              {/* MARKER */}
              <div className={styles.markerContainer}>
                <div className={styles.markerMovable}
                  style={{ marginLeft: `${markerPosition}%`}}>

                  {/* MARKER */}
                  <ImgPin fullLabel={item.label} src={item.img} />

                  {/* LABEL */}
                  <div className={classNames(styles.markerLabel, {
                    [styles.moveToLeft]: moveToLeft
                  })}> { item.label } </div>

                </div>
              </div>


              {/* LEFT */}
              <div className={styles.barLeft}>
                <div className={styles.bar}
                    style={{ width: `${isBuildUp ? 0 : leftWidth}%` }}>
                  <div className={styles.barFill}></div>
                  <div className={styles.barHover}></div>
                </div>
              </div>

              {/* RIGHT */}
              <div className={styles.barRight}>
                <div className={styles.bar}
                  style={{ width: `${isBuildUp ? 0 : rightWidth}%` }}>
                  <div className={styles.barFill}></div>
                  <div className={styles.barHover}></div>
                </div>
              </div>
            </div>
          );
        })}

        {/* RANGE AREA */}
        { showRanges && activeDimension.range && (
          <div className={styles.rangeContainer}>
            <div className={styles.range}
              style={{
                width: isBuildUp ? '0%' : `${(activeDimension.range[1] - activeDimension.range[0]) * 100 / (valueRange[1] - valueRange[0])}%`,
                marginLeft: isBuildUp ? '0%' : `${(activeDimension.range[0] - valueRange[0]) * 100 / (valueRange[1] - valueRange[0])}%`,
              }}>
            </div>
          </div>
        )}
      </div>

      {/* RANGE LEGEND */}
      { showRanges && labelRange && activeDimension.range && (
        <div className={styles.rangeLabel}>
          <span className={styles.rangeLegend}></span>
          { labelRange }
          <span className={styles.highlight}>
            { numberWithForcedDecimals(activeDimension.range[0]) } { translate('component_team_diagrams_next_range_to') || 'bis' } { numberWithForcedDecimals(activeDimension.range[1], { addPlus: true }) }
          </span>
        </div>
      )}



    </div>
  );
};

export default TeamDiagramBipolarNext;
