// redux-sage
import { call, put, takeLatest } from 'redux-saga/effects';

// candidates
import * as fromActions from './candidate.actions';

// api
import * as api from 'api';

import {getMappedCandidate} from 'utils/users';
import {ASSESSMENT_TYPES, NINE_LEVELS_ORDER} from 'utils/configuration/const/assessment-types';

function* getCandidate(action) {
  const candidateId = action.payload.id;

  const { status, ok, data } = yield call(api.get, `recruiting/candidates/${candidateId}`, {expand: 'jobs'});

  if (ok && status === 200) {
    yield put(fromActions.getCandidateSuccess({
      data: getMappedCandidate(data)
    }));
  }
  else {
    yield put(fromActions.getCandidateFail({error: data.error}))
  }

}

export function* watchGetCandidate() {
  yield takeLatest(fromActions.GET_CANDIDATE, getCandidate);
}


function* getCandidateAssessments(action) {
  const candidateId = action.payload.id;

  const { status, ok, data } = yield call(api.get, 'core/user/profile', {id: candidateId});

  if (ok && status === 200) {
    const nineLevelsAssessment = data.profile.find(p => p.assessment === ASSESSMENT_TYPES.NINE_LEVELS);

    //change structure of 9levels assessment result to have negative and positive values for each level;
    if (nineLevelsAssessment && nineLevelsAssessment.result) {
      let mappedResults = [];
      const signatureValues = [];
      const nineLevelsAssessmentResults = nineLevelsAssessment.result.results;

      nineLevelsAssessmentResults.forEach(assResult => {
        //replace cyan with turquoise. 9levels returns cyan whereas on front-end 'turquoise' is used;
        const assessmentResultId = assResult.id.replace('cyan', 'turquoise');
        const assessmentResultName = assResult.name.replace('cyan', 'turquoise');

        const obj = {id: assessmentResultId, name: assessmentResultName};

        if (assResult.id.includes('highvalues')) {
          signatureValues.push(assResult);
          return;
        }

        if (assResult.id.includes('res')) {
          return;
        }

        const positiveResult = assResult.result;
        const negativeResult = nineLevelsAssessmentResults.find(r => r.id === assResult.id.split('_').join('_res_')).result;

        obj.result = [-(Math.abs(Math.ceil(negativeResult))), Math.ceil(positiveResult)];

        mappedResults[NINE_LEVELS_ORDER[assessmentResultId.split('levels_')[1].toLowerCase()]] = obj;
      });

      nineLevelsAssessment.result.results = mappedResults;
      nineLevelsAssessment.result.signatureValues = signatureValues;
    }

    const unorderedCoreAssessments = [];
    const orderedCoreAssessments = [];
    const customAssessments = [];

    data.profile.forEach(p => {
      if (Object.values(ASSESSMENT_TYPES).includes(p.assessment)) {
        unorderedCoreAssessments.push(p);
      } else {
        customAssessments.push(p);
      }
    });

    // order core assessments
    Object.values(ASSESSMENT_TYPES).forEach(assessmentId => {
      const thisAssessment = unorderedCoreAssessments.find(a => a.assessment === assessmentId);
      if (thisAssessment) {
        orderedCoreAssessments.push(thisAssessment);
      }
    })

    yield put(fromActions.getCandidateAssessmentsSuccess({
      data: [...orderedCoreAssessments, ...customAssessments]
    }));
  }
  else {
    yield put(fromActions.getCandidateAssessmentsFail({error: data.error}))
  }
}

export function* watchGetCandidateAssessments() {
  yield takeLatest(fromActions.GET_CANDIDATE_ASSESSMENTS, getCandidateAssessments);
}
