import React, {useEffect, useRef, useState} from 'react';
import styles from './MyProfile.module.scss';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// 3RD PARTY
import classNames from 'classnames';
import { toPng } from 'html-to-image';

import * as api from 'api';
import {
  getCareerLevel,
  getCompanyEntryDate,
  getEmail,
  getFirstName,
  getLastName,
  getProfession,
  getUserPicture
} from 'store/selectors/currentUser';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromWellBeingSelectors from 'store/selectors/wellBeing';
import * as fromSelectors from 'store/selectors/staticValues';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { selectCapabilitiesNext } from 'store/selectors/configuration';

import { getAssessment, getAssessmentResult, getCurrentUser, getWellBeingIndex, listAssessments } from 'store/actions';

import { useTranslate } from 'utils/translator';
import { timestampToDate } from 'utils/dateTools';
import { isAssessmentCompleted } from 'utils/assessment';
import { getMappedCandidate } from 'utils/users';
import { CONFIGURATION, CONFIGURATION_OPTIONS } from 'utils/configuration';
import { MAIL_TYPES } from 'utils/configuration/const/mailTypes';
import {
  NINE_LEVELS_ORDER,
  ASSESSMENT_TYPES
} from 'utils/configuration/const/assessment-types';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { scrollIntoView } from 'utils/scrolling';

import { ASSESSMENT_NEXT_TYPES } from 'ui/molecules/AssessmentNext/AssessmentNext.config';
import { hasStoredAnswers } from 'ui/molecules/AssessmentNext/AssessmentNext.logic';
import RmpImport from 'features/framework/pages/Overview/RmpImport';
import ShareProfileLinkModal from 'features/+employees/pages/EmployeeProfile/ShareProfileLinkModal';
import CandidateProfile from 'ui/basic/molecules/CandidateProfile';
import { EditCandidateProfilePanel, LoadingHint, OverlayModal } from 'ui/basic';
import {
  EditProfilePanel,
  Button,
  Link,
  Profile,
  Scrollable,
  ButtonCircular,
  AssessmentTeaserNext, OptionsMenu,
  Modal
} from 'ui/basic';
import {
  Big5Result,
  PotentialResult,
  WorkPreferencesResult,
  CompetenciesResult,
  RmpResult,
  WellBeingResult, NineLevelsResult,
  SkillsResult, CliftonResult
} from 'ui/molecules';

const ASSESSMENT_RESULT_TITLES = {
  [ASSESSMENT_TYPES.BIG5]: 'big5_results_title',
  [ASSESSMENT_TYPES.POTENTIAL]: 'potential_results_title',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'workpreferences_results_title',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'keycomp_results_title',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'leadershipcomp_results_title',
  [ASSESSMENT_TYPES.RMP]: 'rmp_results_title',
  [ASSESSMENT_TYPES.NINE_LEVELS]: '9levels_results_title'
};

const OPTION_TYPES = {
  SHARE_PROFILE_LINK: 'SHARE_PROFILE_LINK',
  EXPORT_ASSESSMENTS_RESULTS: 'EXPORT_ASSESSMENTS_RESULTS'
}


const MyProfile = (props) => {
  const { fullWidth } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  // STATE
  const [ showEditProfilePage, setShowEditProfilePage ] = useState(false);
  const [ showRmpReport, setShowRmpReport ] = useState(false);
  const [ showBig5Report, setShowBig5Report ] = useState(false);
  const [ showNineLevelsReport, setShowNineLevelsReport ] = useState(false);

  const [ teasers, setTeasers ] = useState([]);

  const [ teasersPageLeftAvailable, setTeasersPageLeftAvailable ] = useState(false);
  const [ teasersPageRightAvailable, setTeasersPageRightAvailable ] = useState(false);
  const [ teasersPagePosition, setTeasersPagePosition ] = useState(0);

  // RMP IMPORT
  const [ rmpImportModalVisible, setRmpImportModalVisible ] = useState();

  // SHARE PROFILE LINK MODAL
  const [ shareProfileLinkModalVisible, setShareProfileLinkModalVisible ] = useState();

  // TEASERS
  const scrollableRef = useRef();

  // SELECTORS
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const currentLanguage = useSelector(state => state.localisation?.currentLanguage);

  const firstName = useSelector(state => getFirstName(state));
  const lastName = useSelector(state => getLastName(state));
  const email = useSelector(state => getEmail(state));
  const userPicture = useSelector(state => getUserPicture(state));
  const profession = useSelector(state => getProfession(state));
  const careerLevel = useSelector(state => getCareerLevel(state));
  const companyEntryDate = useSelector(state => getCompanyEntryDate(state));

  const careerLevelOptions = useSelector(fromSelectors.selectCareerLevelOptions);

  const assessments = useSelector(state => state.assessments);

  const myWellBeing = useSelector(fromWellBeingSelectors.getMyWellBeing);

  // UPDATE USER: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ updateUserConfirmationModalVisible, setUpdateUserConfirmationModalVisible ] = useState();
  const [ updateUserData, setUpdateUserData ] = useState();
  const [ updateUserProcessing, setUpdateUserProcessing ] = useState();
  const updateUser = () => {
    setUpdateUserProcessing(true);
    api.post('core/user/update', updateUserData)
    .then(({ ok, status }) => {
      setUpdateUserProcessing(false);
      if (ok && status === 200) {
        dispatch(getCurrentUser());
        setUpdateUserConfirmationModalVisible(false);
        setShowEditProfilePage(false);
      }
    })
    .catch(error => {
      console.error(error.message);
      setUpdateUserProcessing(false);
    })
  }

  useEffect(() => {
    if (me.canAccessWellBeing && !myWellBeing) {
      dispatch(getWellBeingIndex());
    }
  }, [dispatch, me.canAccessWellBeing, myWellBeing]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);
  const assessmentsFromStoreWithNoClifton = useSelector(fromAssessmentsSelectors.selectAssessmentsWithNoClifton);

  const [ assessmentRequested, setAssessmentRequested ] = useState(false);
  useEffect(() => {
    if (assessmentRequested || !assessmentsFromStore?.length) {
      return;
    }

    assessmentsFromStore.forEach(assessment => {
      // if assessment is a custom assessment
      if (!Object.values(ASSESSMENT_TYPES).includes(assessment.id) && (
        !assessment.resultSchema || assessment.resultSchemaLanguage !== currentLanguage
      )) {
        dispatch(getAssessment(assessment.id));
      }
    })

    setAssessmentRequested(true);
  }, [
    dispatch,
    assessmentRequested,
    assessmentsFromStore,
    currentLanguage
  ]);

  const [ assessmentResultRequested, setAssessmentResultRequested ] = useState();
  useEffect(() => {
    if (assessmentResultRequested || !assessmentsFromStore?.length) {
      return;
    }

    assessmentsFromStore.forEach(assessment => {
      if ((!assessment.results || assessment.results.length === 0) &&
        (assessment.stages && assessment.stages.every(stage => stage.questionsAnswered > 0 && stage.questionsAnswered >= stage.length))
      ) {
        dispatch(getAssessmentResult(assessment.id));
      }
    })

    setAssessmentResultRequested(true);
  }, [
    dispatch,
    assessmentsFromStore,
    assessmentResultRequested
  ]);

  const [ assessmentsListRequested, setAssessmentsListRequested ] = useState(false);
  useEffect(() => {
    if (assessmentsListRequested) {
      return;
    }

    setAssessmentsListRequested(true);
    dispatch(listAssessments());
  }, [ dispatch, assessmentsListRequested ]);

  useEffect(() => {
    const teasers = [];

    const filteredTeasers = assessmentsFromStoreWithNoClifton.filter(assessment => {
      const thisAssessment = assessments[assessment.id];
      return !isAssessmentCompleted(thisAssessment) &&
        thisAssessment &&
        (thisAssessment.userAvailable === undefined || thisAssessment.userAvailable)
    })

    filteredTeasers.forEach(assessment => {
      const assessmentName = ASSESSMENT_NEXT_TYPES[assessment.id] || assessment.id;

      const storedAnswers = hasStoredAnswers(assessmentName, me.id);

      let assessmentStarted;
      if (assessment.stages) {
        assessmentStarted = assessment.stages.some(stage => stage.questionsAnswered > 0 && stage.questionsAnswered < stage.length);
      }

      // if multi session is enabled for assessment and there are answered questions
      if (assessment.multi_session && (assessmentStarted || storedAnswers)) {
        teasers.unshift({
          assessmentType: assessment.id,
          assessmentTitle: assessment.title,
          assessmentDescription: assessment.infoTexts && assessment.infoTexts.shortDescription,
          assessmentScope: assessment.infoTexts && assessment.infoTexts.requiredTime,
          assessmentDetails: assessment?.infoTexts?.longDescription,
          assessmentStarted
        });

        return;
      }

      teasers.push({
        assessmentType: assessment.id,
        assessmentTitle: assessment.title,
        assessmentDescription: assessment.infoTexts && assessment.infoTexts.shortDescription,
        assessmentScope: assessment.infoTexts && assessment.infoTexts.requiredTime,
        assessmentDetails: assessment?.infoTexts?.longDescription,
        assessmentStarted: false
      });
    });

    setTeasers(teasers);
  }, [assessments, me.id, assessmentsFromStoreWithNoClifton]);

  useEffect(() => {
    const mailType = localStorage.getItem('mailType');

    if (mailType && mailType === MAIL_TYPES.RMP_REPORT &&
      assessments && assessments[ASSESSMENT_TYPES.RMP] && assessments[ASSESSMENT_TYPES.RMP].results &&
      (assessments[ASSESSMENT_TYPES.RMP].userAvailable === undefined || assessments[ASSESSMENT_TYPES.RMP].userAvailable)
    ) {
      localStorage.removeItem('mailType');

      scrollIntoView(ASSESSMENT_TYPES.RMP);
      setShowRmpReport(true);
    }
  }, [assessments]);

  useEffect(() => {
    const queryParams = history.location.search;
    let openReportFor = queryParams.split('?openReportFor=')[1];
    openReportFor = openReportFor && openReportFor.toLowerCase
      ? openReportFor.toLowerCase()
      : openReportFor;

    let timerId;
    if (openReportFor && !timerId) {
      timerId = setTimeout(() => {
        scrollIntoView(openReportFor);

        if (openReportFor === ASSESSMENT_TYPES.BIG5) {
          setShowBig5Report(true);
        }
        if (openReportFor === ASSESSMENT_TYPES.NINE_LEVELS) {
          setShowNineLevelsReport(true);
        }
      }, 800);
    }
    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [history, assessments]);

  useEffect(() => {
    const queryParams = history.location.search;
    const assessmentId = queryParams.split('?goTo=')[1];

    if (assessmentId) {
      setTimeout(() => {
        if (assessments[assessmentId] && assessments[assessmentId].results &&
          (assessments[assessmentId].userAvailable === undefined || assessments[assessmentId].userAvailable)
        ) {
          scrollIntoView(assessmentId);
        }
      }, 3000);

    }
  }, [history, assessments]);

  const getAssessmentResults = (assessmentType) => {
    const assessmentResults = (
      assessments &&
      assessments[assessmentType] &&
      (assessments[assessmentType].userAvailable === undefined || assessments[assessmentType].userAvailable) &&
      assessments[assessmentType].results &&
      assessments[assessmentType].results
    );

    if (!assessmentResults) {
      return;
    }

    if (assessmentType === ASSESSMENT_TYPES.NINE_LEVELS) {
      let mappedResults = [];

      assessmentResults.forEach(assResult => {
        //replace cyan with turquoise. 9levels returns cyan whereas on front-end 'turquoise' is used;
        const assessmentResultId = assResult.id.replace('cyan', 'turquoise');
        const assessmentResultName = assResult.name.replace('cyan', 'turquoise');

        const obj = {id: assessmentResultId, name: assessmentResultName};

        if (assResult.id.includes('res') || assResult.id.includes('highvalues') || !assResult.id.includes('levels_')) {
          return;
        }

        const positiveResult = assResult.result;
        const negativeResult = assessmentResults.find(r => r.id === assResult.id.split('_').join('_res_')).result;

        obj.result = [-(Math.abs(Math.ceil(negativeResult))), Math.ceil(positiveResult)];

        mappedResults[NINE_LEVELS_ORDER[assessmentResultId.split('levels_')[1].toLowerCase()]] = obj;
      });

      return mappedResults;
    }

    return assessmentResults;
  };

  const showTeasers = () => {
    let showTeasers = false;
    assessmentsFromStoreWithNoClifton.forEach(assessment => {
      if (assessments[assessment.id] && (assessments[assessment.id].userAvailable === undefined ||
          assessments[assessment.id].userAvailable) && !isAssessmentCompleted(assessments[assessment.id])
      ) {
        showTeasers = true;
        return;
      }
    });
    return showTeasers;
  };

  const showResults = () => {
    let showResults = false;
    assessmentsFromStore.forEach(assessment => {
      if (assessments[assessment.id] && (assessments[assessment.id].userAvailable === undefined || assessments[assessment.id].userAvailable) &&
        isAssessmentCompleted(assessments[assessment.id])
      ) {
        showResults = true;
        return;
      }
    });
    return showResults;
  };

  const isUnassessed = () => {
    let isUnassessed = true;
    assessmentsFromStoreWithNoClifton.forEach(assessment => {
      if (assessments[assessment.id] &&
        (assessments[assessment.id].userAvailable === undefined || assessments[assessment.id].userAvailable) &&
        isAssessmentCompleted(assessments[assessment.id])
      ) {
        isUnassessed = false;
      }
    });
    return isUnassessed;
  };

  const renderResultComponent = (assessmentType, options = {}) => {
    const { exportView, diagramId } = options;
    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return (
          <div id={diagramId}>
            <Big5Result
              id={diagramId}
              results={getAssessmentResults(assessmentType)}
              openReport={showBig5Report}
              showExportIcon
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        )
      case ASSESSMENT_TYPES.POTENTIAL:
        return (
          <div id={diagramId}>
            <PotentialResult
              showCallout
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        )
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return (
          <div id={diagramId}>
            <WorkPreferencesResult
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        )
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return (
          <div id={diagramId}>
            <CompetenciesResult
              assessmentType={ASSESSMENT_TYPES.KEY_COMPETENCIES}
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        )
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return (
          <div id={diagramId}>
            <CompetenciesResult
              assessmentType={ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES}
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        )
      case ASSESSMENT_TYPES.RMP:
        return (
          <div id={diagramId}>
            <RmpResult
              id={ASSESSMENT_TYPES.RMP}
              openReport={showRmpReport}
              results={getAssessmentResults(assessmentType)}
              showPendingState
              showExportIcon
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        )
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return (
          <div id={diagramId}>
            <NineLevelsResult
              id={ASSESSMENT_TYPES.NINE_LEVELS}
              openReport={showNineLevelsReport}
              results={getAssessmentResults(assessmentType)}
              signatureValues={assessments[ASSESSMENT_TYPES.NINE_LEVELS].results ?
                assessments[ASSESSMENT_TYPES.NINE_LEVELS].results.filter(resultItem => resultItem.id.includes('highvalues')) :
                []
              }
              showExportIcon
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        )
      default:
        const thisAssessment = assessmentsFromStore.find(a => a.id === assessmentType);
        if (thisAssessment && thisAssessment.render_results === 'clifton') {
          return (
            <div id={diagramId}>
              <CliftonResult strengths={getAssessmentResults(assessmentType)} />
            </div>
          )
        } else {
          return (
            <div id={diagramId}>
              <SkillsResult
                assessmentType={assessmentType}
                results={getAssessmentResults(assessmentType)}
                showExportIcon
                exportView={exportView}
                onExport={() => openExportModal(assessmentType)}
              />
            </div>
          )
        }
    }
  };

  const getCompletedAssessments = () => {
    const results = [];
    assessmentsFromStore.forEach(assessment => {
      if (assessments[assessment.id] &&
        (assessments[assessment.id].userAvailable === undefined || assessments[assessment.id].userAvailable) &&
        isAssessmentCompleted(assessments[assessment.id])
      ) {
        results.push({
          id: assessment.id,
          name: translate(ASSESSMENT_RESULT_TITLES[assessment.id]) || assessment.title
        })
      }
    });
    return results;
  };


  const renderResultComponents = (exportView) => {
    const results = [];

    assessmentsFromStore.forEach((assessment, index) => {
      const options = {exportView};
      if (exportView) {
        Object.assign(options, {diagramId: `export-${assessment.id}`})
      }

      if (assessments[assessment.id] &&
        (assessments[assessment.id].userAvailable === undefined || assessments[assessment.id].userAvailable) &&
        (!exportView || (exportView && assessments[assessment.id] && !assessments[assessment.id].resultsPending)) &&
        isAssessmentCompleted(assessments[assessment.id])
      ) {
        results.push(
          <div
            id={assessment.id}
            key={`result-${index}`}
            className={styles.assessment}
          >
            {renderResultComponent(assessment.id, options)}
          </div>
        );
      }
    });
    return results;
  };

  // EXPORT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ diagramType, setDiagramType ] = useState();
  const [ diagramRenderProcessing, setDiagramRenderProcessing ] = useState();
  const [ diagramExportProcessing, setDiagramExportProcessing ] = useState();
  const openExportModal = (diagramType) => {
    setDiagramType(diagramType);
    setDiagramRenderProcessing(true);

    const time = diagramType === 'all' ? 2000 : 1000;
    setTimeout(() => {
      setDiagramRenderProcessing(false);
    }, time);
  };

  const cleanupExportModal = () => {
    setDiagramType();
  };

  const exportImages = () => {
    setDiagramExportProcessing(true);

    const nodes = [];
    const nodesTitles = [];

    assessmentsFromStore.forEach(assessment => {
      if (document.getElementById(`export-${assessment.id}`)) {
        nodes.push(document.getElementById(`export-${assessment.id}`));
        nodesTitles.push(assessment.id);
      }
    })

    Promise
      .all(nodes.map((node) => toPng(node)))
      .then((results) => {
        results.forEach((result, index) => {
          let fileName = firstName ? `${firstName} ` : '';
          fileName += lastName ? `${lastName}_` : '';
          fileName += nodesTitles[index];

          const link = document.createElement('a');
          link.href = result;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      })
      .finally(() => {
        setDiagramExportProcessing(false);
        cleanupExportModal();
      });
  }

  const exportImage = (diagramType) => {
    setDiagramExportProcessing(true);
    const node = document.getElementById(`export-${diagramType}`);

    toPng(node)
    .then((dataUrl) => {
      let fileName = firstName ? `${firstName} ` : '';
      fileName += lastName ? `${lastName}_` : '';
      fileName += diagramType;

      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDiagramExportProcessing(false);
      cleanupExportModal();
    })
    .catch((error) => {
      console.error('oops, something went wrong!', error);
    });
  };

  /**
   * Create list of menu options available to user.
   *
   * @returns {[Object]} List of options
   */
  const getMenuOptions = () => {
    const options = [{
      value: OPTION_TYPES.EXPORT_ASSESSMENTS_RESULTS,
      label: translate('export_assessments_results'),
    }];

    if (me.canShareOwnProfile) {
      options.unshift({
        value: OPTION_TYPES.SHARE_PROFILE_LINK,
        label: translate('manage_profile_links'),
      })
    }

    return options;
  }

  // RENDER: export view footer
  const renderExportViewFooter = () => {
    return (
      <div className={styles.exportViewFooter}>
        <Button
          size='M'
          disabled={diagramRenderProcessing || diagramExportProcessing}
          onClick={() => {
            if (diagramType === 'all') {
              exportImages();
            } else {
              exportImage(diagramType);
            }
          }}
        >{ translate('download') || 'Download' }</Button>
      </div>
    )
  }

  // RENDER: MyProfile
  return (
    <div className={styles.myProfile}>
      <div className={styles.gridContainer}>
        <div className={styles.personalInfo}>
          <div className={classNames(
            styles.subBlock,
            {[styles.sticky]: me.role !== MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE}
          )}>
            {/* if current user is candidate */}
            { me.role === MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE && (
              <CandidateProfile candidate={getMappedCandidate(me)} />
            )}

            {/* if user is not a candidate */}
            { me.role !== MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE && (
              <Profile roleClickable={capabilitiesNext.rolesView} user={{
                id: me.id,
                name: firstName + ' ' + lastName,
                email: email,
                userPicture: userPicture,
                position: profession,
                careerLevel: (careerLevel && careerLevelOptions.find(cl => cl.value === careerLevel)) ?
                  careerLevelOptions.find(cl => cl.value === careerLevel).label :
                  '',
                since: companyEntryDate && timestampToDate(companyEntryDate),
                status: {
                  percentage: 10
                },
                orgRoles: me.organizationalRoles
              }}
              />
            )}

            <div className={styles.buttons}>
              <Button
                looks={'secondary'}
                size={'S'}
                onClick={() => setShowEditProfilePage(true)}
              >
                { translate('ps_button_editprofile') || 'Edit Profile' }
              </Button>

              { (CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST) && (
                <OptionsMenu
                  withBackground
                  flyoutPosition={'right'}
                  options={getMenuOptions()}
                  onClick={value => {
                    switch (value) {
                      case OPTION_TYPES.SHARE_PROFILE_LINK:
                        setShareProfileLinkModalVisible(true);
                        break;
                      case OPTION_TYPES.EXPORT_ASSESSMENTS_RESULTS:
                        openExportModal('all');
                        break;
                      default:
                    }
                  }}
                />
              )}
            </div>

            <div className={styles.anchors}>
              {getCompletedAssessments().map((assessment, index) => (
                <Link
                  key={`assessment-anchor-${index}`}
                  type={'anchor'}
                  onClick={() => scrollIntoView(assessment.id)}
                >
                  {assessment.name}
                </Link>
              ))}
            </div>

          </div>
        </div>

        <div className={styles.assessmentsInfo}>

          {/*ASSESSMENT TEASERS*/}
          { showTeasers() && (
            <div className={styles.assessmentTeasers}>
              <div className={styles.titleBlock}>
                <div className={styles.title}>
                  { isUnassessed()
                    ? translate('ep_headline_unassessed', ['{{username}}', firstName]) ||
                    'Hello {{username}}, start your first assessment.'.replace('{{username}}', firstName)
                    : translate('ep_headline_partially_assessed', ['{{username}}', firstName]) ||
                    'Hello {{username}}, start your next assessment.'.replace('{{username}}', firstName)
                  }
                </div>

                <div className={styles.scrollingButtons}>
                  <ButtonCircular
                    icon={'ArrowLeft'}
                    looks={'primary'}
                    disabled={!teasersPageLeftAvailable}
                    onClick={() => { scrollableRef.current.pageLeft()}}
                  />
                  <ButtonCircular
                    icon={'ArrowRight'}
                    looks={'primary'}
                    disabled={!teasersPageRightAvailable}
                    onClick={() => { scrollableRef.current.pageRight()}}
                  />
                </div>
              </div>

              {/*SCROLLABLE*/}
              <div className={styles.scrollable}>
                <Scrollable
                  ref={scrollableRef}
                  pagination
                  showPaginationBubbles
                  drag
                  pageMargin={16}
                  refresh={teasers}
                  onPaginationAvailabilities={([pageLeftAvailable, pageRightAvailable, pagePosition]) => {
                    setTeasersPageLeftAvailable(pageLeftAvailable);
                    setTeasersPageRightAvailable(pageRightAvailable);
                    if (pagePosition >= 0 &&
                      pagePosition !== teasersPagePosition &&
                      pagePosition > teasersPagePosition
                    ) {
                      setTeasersPagePosition(pagePosition);
                    }
                  }}
                >
                  { teasers.map((teaser, index) => (
                    <div className={styles.teaser} key={index}>
                      <AssessmentTeaserNext
                        assessmentType={teaser.assessmentType}
                        title={teaser.assessmentTitle}
                        description={teaser.assessmentDescription}
                        scope={teaser.assessmentScope}
                        details={teaser.assessmentDetails}
                        forContinue={teaser.assessmentStarted}
                        forImport={[ASSESSMENT_TYPES.RMP].includes(teaser.assessmentType)}
                        onClick={() => history.push(`/assessments/${teaser.assessmentType}`)}
                        onImport={() => setRmpImportModalVisible(true)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>

            </div>
          )}

          {/*WELL-BEING RESULT*/}
          { (me.canAccessWellBeing && myWellBeing) &&
          <div className={styles.wellBeingContainer}>
            { !showTeasers() && <div className={styles.title}>Hallo {firstName}, willkommen zurück.</div> }
            <WellBeingResult
              wellBeing={myWellBeing}
              fullWidth={fullWidth}
            />
          </div>
          }

          { showResults() && (
            <div className={styles.assessmentsResults}>
              <div className={styles.title}>
                { translate('ep_headline_strengths') || 'Your strengths and preferences' }
              </div>
              {renderResultComponents()}
            </div>
          )}
        </div>

        { showEditProfilePage && (
          me.role === MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE ?
            <EditCandidateProfilePanel
              showConfirmModal={data => {
                setUpdateUserData(data);
                setUpdateUserConfirmationModalVisible(true);
              }}
              onClose={() => setShowEditProfilePage(false)}
            /> :
            <EditProfilePanel onClose={() => setShowEditProfilePage(false)} />
        )}

        {/*RMP IMPORT*/}
        { rmpImportModalVisible && (
          <RmpImport onClose={() => setRmpImportModalVisible(false)} />
        )}

        {/*SHARE PROFILE LINK MODAL*/}
        { shareProfileLinkModalVisible && (
          <ShareProfileLinkModal
            passwordRequired={false}
            showPersonalLinks
            onClose={() => setShareProfileLinkModalVisible(false)}
          />
        )}

        {/*UPDATE USER CONFIRMATION MODAL*/}
        { updateUserConfirmationModalVisible && (
          <Modal
            header={translate('unsaved_changes_modal_header') || 'Du hast ungespeicherte Änderungen'}
            redButtonTitle={translate('unsaved_changes_modal_discard_btn') || 'Änderungen verwerfen'}
            redButtonDisabled={updateUserProcessing}
            secondaryButtonTitle={translate('unsaved_changes_modal_save_btn') || 'Änderungen speichern'}
            secondaryButtonDisabled={updateUserProcessing}
            closeOnCancel={false}
            onConfirm={() => {
              setUpdateUserConfirmationModalVisible(false);
              setShowEditProfilePage(false);
            }}
            onClose={(closeButtonClicked) => {
              if (!closeButtonClicked) {
                setUpdateUserConfirmationModalVisible(false);
              } else {
                updateUser();
              }
            }}
          >
            { translate('unsaved_changes_modal_copy') || 'Wie möchtest du fortfahren?' }
          </Modal>
        )}

        {/* EXPORT MODAL */}
        { diagramType && (
          <OverlayModal
            header='Export'
            footer={renderExportViewFooter()}
            onClose={() => cleanupExportModal()}
          >
            <div className={styles.exportView}>
              { diagramType === 'all' && (
                <>
                  <div className={styles.loading}>
                    {diagramRenderProcessing ?
                      <LoadingHint>{translate('export_loading') || 'Export wird bereitgestellt...'}</LoadingHint> :
                      <span className='blu-typeHint'>{translate('export_success') || 'Dein Export steht bereit.'}</span>
                    }
                  </div>
                  { renderResultComponents(true) }
                </>
              )}

              { diagramType !== 'all' && (
                <>
                  <div className={styles.loading}>
                    {diagramRenderProcessing ?
                      <LoadingHint>{translate('exports_loading') || 'Ergebnisgrafik werden exportiert...'}</LoadingHint> :
                      <span className='blu-typeHint'>{translate('export_success') || 'Dein Export steht bereit.'}</span>
                    }
                  </div>
                  { renderResultComponent(diagramType, { exportView: true, diagramId: `export-${diagramType}`}) }
                </>
              )}
            </div>
          </OverlayModal>
        )}

      </div>
      </div>
  );
};

export default MyProfile;
