// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Potential.module.scss';

// ASSETS
import { ReactComponent as Teaser } from './teaser.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: Potential
const Potential = (props) => {
  // PROPS
  const {
    controls = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Potential
  return (
    <div className={classNames(styles.potential)}>
      <div className={styles.content}>
        <div className={styles.teaser}>
          <Teaser />
        </div>
        <div className={styles.title}>
          { translate('onboarding_candidate_potential_title') || 'Die Entwicklung deines Potenzials steht im Vordergrund.'}
        </div>
        <div className={styles.text}>
          { translate('onboarding_candidate_potential_text') || `
            Mit dem Wissen über deine individuellen Stärken,
            kann dir dein künftiger Arbeitgeber nicht nur
            einen passenden Job anbieten, sondern auch
            deine Entwicklung nach dem Start effizienter
            und effektiver gestalten.
          `}

        </div>
        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        )}
      </div>
    </div>
  );
};

export default Potential;
