import React from 'react';

import marked from 'marked';
import sanitize from 'insane';
marked.setOptions({gfm: true,
  breaks: true});

export const capitalise = (string = '') => {
  if (!string || typeof(string) !== 'string') return string;

  return `${string[0].toUpperCase()}${string.slice(1)}`;
};

export const emptyDash = (value, emptySymbol = '-') => {
  return value ? value : emptySymbol;
};

export const getInitials = (label) => {
  return label.split(' ').slice(0,2)
  .map((name) => name && name.length && name[0])
  .join('');
};

export const isJSON = (text = '') => {
  try {
    return !!JSON.parse(text);
  } catch (error) {
    return false;
  }
}

export const numberWithDecimal = (number, decimalLength = 1) => {
  // console.log('number', number);
  number = number ? number : 0;
  number = Math.round(number * 10) / 10;
  const intTextLength = `${Math.floor(number)}`.length;
  const floatTextLength = `${number}`.length - intTextLength - 1;
  const formattedDecimalLength = floatTextLength > 0 ? decimalLength : 0;
  const formattedNumber = number >= 1
    ? `${number}`.substr(0, intTextLength + 1 + formattedDecimalLength)
    : '0';
  // console.log(formattedNumber, formattedDecimalLength);
  return formattedNumber;
};


export const percentWithDecimal = (number, decimalLength = 1) => {
  let formattedPercent;
  if (!number) {
    formattedPercent = '-';
  }
  else {
    formattedPercent = `${numberWithDecimal(number, decimalLength)} %`;
  }

  return formattedPercent;

};

export const joinNumbers = (
  numbers,
  delimiter = '-',
  numberFunction = (number) => number
) => {
  return numbers.map(number => numberFunction(number)).join(delimiter);
};

export const highlight = (value = '', filter = '', highlightClassName = 'blu-highlight') => {
  if (value === undefined || value === null) {
    return '-';
  }

  value = String(value); // make it a string in any case

  if (!value || !filter) {
    return emptyDash(value);
  }
  const splitFilters = filter.split(' ');
  const splitValues = value.split(' ');

  const results = splitValues
  .map((splitValue, index) => {

    const result =
    splitFilters
    .map((splitFilter, splitFilterIndex) => {
      return [splitValue.toLowerCase().indexOf(splitFilter.toLowerCase()), splitFilterIndex];
    })
    .reduce((curr, acc) => {
      const [ indexOf ] = curr;
      // returns first match
      if (indexOf !== -1) {
        return curr;
      }
      else {
        return acc;
      }
    }, [-1, -1]);

    // console.log(result);
    const [ indexOf, splitFilterIndex ] = result;

    if (indexOf !== -1) {
      const filter = splitFilters[splitFilterIndex];
      const before = splitValue.substring(0, indexOf);
      const matched = splitValue.substring(indexOf, indexOf + filter.length);
      const after = splitValue.substring(indexOf + filter.length);

      return (
        <React.Fragment key={index}>
          {before && <span>{before}</span>}
          {<span className={highlightClassName}>{matched}</span>}
          {after && <span>{after}</span>}
          {<>&nbsp;</>}
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment key={index}><>{splitValue}</><>&nbsp;</></React.Fragment>
      );
    }
  });

  return results;
};

export const easyDateMY = (date) => {
  if (!date) {
    return emptyDash(date);
  }
  date = new Date(date);
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  return `${month}/${date.getFullYear()}`;
};


// export const translate = translator;

export const markdownIdentifier = '[blu-markdown]';
export const markdownAllowedtags = [
  'h1', 'h2', 'h3', 'h4',
  'p', 'a', 'strong', 'em', 'code',
  'ul', 'ol', 'li',
  'hr', 'br', 'span'
];
export const markdownAllowedAttributes = {
  h2: 'id'
};
const markdowTOCIdentifier = '[blu-table-of-contents]';
const markdownTOCRegex = /\[blu-table-of-contents\]\(([\w\s\d.äÄöÖüÜß!?\-_]*)\)*/;
const htmlH2Regex = /<h2 id="([\w\s\d.äÄöÖüÜß!?\-_]*)">([\w\s\d.äÄöÖüÜß!?\-_]*)<\/h2>/;
const htmlH2RegexG = /<h2 id="([\w\s\d.äÄöÖüÜß!?\-_]*)">([\w\s\d.äÄöÖüÜß!?\-_]*)<\/h2>/g;
export const markdown = (string) => {
  if (typeof string !== 'string') {
    return string;
  }

  // do nothing if we aren't handling a markdown string
  if (string.indexOf(markdownIdentifier) !== 0) {
    return string;
  }

  // extract markdownString
  let markdownString = string.split(markdownIdentifier)[1];
  // parse markdown to htmlString
  let htmlString = marked(markdownString);
  // sanitize the htmlString
  let sanitizedHtmlString = sanitize(htmlString, {
    allowedTags: markdownAllowedtags,
    allowedAttributes: markdownAllowedAttributes
  });
  // match for table of contents
  const tableOfContentIndex = markdownString.indexOf(markdowTOCIdentifier);
  if (tableOfContentIndex !== -1) {
    let tocMarkdown = '';
    const tocMatch = markdownString.match(markdownTOCRegex);
    // extract tocTitle
    const tocTitle = tocMatch[1];
    tocMarkdown += `## ${tocTitle}\n`;
    // console.log(tocMatch, tocTitle);
    // parse HTML
    const h2Matches = htmlString.match(htmlH2RegexG);
    // console.log('h2Matches', h2Matches);
    for (let h2Match of h2Matches) {
      let groupMatch = h2Match.match(htmlH2Regex);
      // console.log(groupMatch);
      const anchor = groupMatch[1];
      const title = groupMatch[2];
      tocMarkdown += `[${title}](#${anchor})  \n`;
    }
    markdownString = markdownString.replace(markdownTOCRegex, tocMarkdown);
    // console.log(tocMarkdown, markdownString);
  }

  // parse markdown to htmlString
  htmlString = marked(markdownString);
  // sanitize the htmlString
  sanitizedHtmlString = sanitize(htmlString, {
    allowedTags: markdownAllowedtags,
    allowedAttributes: markdownAllowedAttributes
  });

  // return jsx result
  return (
    <div className='blu-markdown'
      dangerouslySetInnerHTML={{ __html: sanitizedHtmlString }}>
    </div>
  );
};
