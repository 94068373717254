import * as fromActionTypes from 'store/actionTypes/teamOverview';

const initialState = {
  teams: null,
  teamTypes: [],
  team: null,
  totalCount: 0,
  assessmentResults: [],
  teamProfiles: [],
  teamProfilesLoading: false,
  positions: [],

  teamCreationProcessing: false,
  teamCreationSuccess: false,
  teamCreationError: null,
  createdTeamId: null,

  addUsersProcessing: false,
  addUsersSuccess: false,

  addPositionsProcessing: false,
  addPositionsSuccess: false,

  updateTeamProcessing: false,
  updateTeamSuccess: false,
  updateTeamError: null,

  deleteTeamUserProcessing: false,
  deleteTeamUserSuccess: false,
  deleteTeamUserError: null,

  updateUserRoleProcessing: false,
  updateUserRoleSuccess: false,
  updateUserRoleError: null,
  updatedUserRole: null,

  fillPositionProcessing: false,
  fillPositionSuccess: false,
  fillPositionError: null,

  deletePositionProcessing: false,
  deletePositionSuccess: false,
  deletePositionError: null,

  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.GET_TEAMS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActionTypes.GET_TEAMS_FULFILLED: {
      const { teams, totalCount } = action.payload;
      return {
        ...state,
        teams,
        totalCount,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_TEAMS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.INIT_TEAM: {
      return {
        ...state,
        loading: false,
        team: null
      };
    }
    case fromActionTypes.GET_TEAM: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_TEAM_FULFILLED: {
      const { team } = action.payload;
      return {
        ...state,
        team,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_TEAM_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.GET_TEAM_USERS: {
      return {
        ...state,
      }
    }
    case fromActionTypes.GET_TEAM_USERS_FULFILLED: {
      const { teamUsers } = action.payload;
      const team = {...state.team, users: teamUsers}

      return {
        ...state,
        team,
        error: null
      };
    }
    case fromActionTypes.GET_TEAM_USERS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        error
      };
    }
    case fromActionTypes.GET_TEAM_TYPES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_TEAM_TYPES_FULFILLED: {
      const { teamTypes } = action.payload;

      return {
        ...state,
        teamTypes,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_TEAM_TYPES_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.INIT_TEAM_PROFILES: {
      return {
        ...state,
        teamProfiles: [],
        loading: false,
        teamProfilesLoading: false,
      };
    }
    case fromActionTypes.GET_TEAM_PROFILES: {
      return {
        ...state,
        loading: true,
        teamProfilesLoading: true,
      };
    }
    case fromActionTypes.GET_TEAM_PROFILES_FULFILLED: {
      const { teamProfiles } = action.payload;

      return {
        ...state,
        teamProfiles,
        loading: false,
        teamProfilesLoading: false,
        error: null
      };
    }
    case fromActionTypes.GET_TEAM_PROFILES_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        teamProfilesLoading: false,
        error
      };
    }
    case fromActionTypes.INIT_ADD_USERS: {
      return {
        ...state,
        addUsersProcessing: false,
        addUsersSuccess: false,
        error: null
      };
    }
    case fromActionTypes.ADD_USERS: {
      return {
        ...state,
        addUsersProcessing: true,
      };
    }
    case fromActionTypes.ADD_USERS_FULFILLED: {
      return {
        ...state,
        addUsersSuccess: true,
        addUsersProcessing: false,
        error: null
      };
    }
    case fromActionTypes.ADD_USERS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        addUsersSuccess: false,
        addUsersProcessing: false,
        error
      };
    }
    case fromActionTypes.INIT_ADD_POSITIONS: {
      return {
        ...state,
        addPositionsProcessing: false,
        addPositionsSuccess: false,
        error: null
      };
    }
    case fromActionTypes.ADD_POSITIONS: {
      return {
        ...state,
        addPositionsProcessing: true,
      };
    }
    case fromActionTypes.ADD_POSITIONS_FULFILLED: {
      return {
        ...state,
        addPositionsProcessing: false,
        addPositionsSuccess: true,
        error: null
      };
    }
    case fromActionTypes.ADD_POSITIONS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        addPositionsProcessing: false,
        addPositionsSuccess: false,
        error
      };
    }
    case fromActionTypes.GET_POSITIONS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_POSITIONS_FULFILLED: {
      const { positions } = action.payload;

      return {
        ...state,
        positions,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_POSITIONS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.GET_ASSESSMENT_RESULTS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_ASSESSMENT_RESULTS_FULFILLED: {
      const { assessmentResults } = action.payload;
      return {
        ...state,
        assessmentResults,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_ASSESSMENT_RESULTS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.UPDATE_TEAM: {
      return {
        ...state,
        updateTeamProcessing: true
      };
    }
    case fromActionTypes.UPDATE_TEAM_FULFILLED: {
      return {
        ...state,
        updateTeamProcessing: false,
        updateTeamSuccess: true,
        updateTeamError: null
      };
    }
    case fromActionTypes.UPDATE_TEAM_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        updateTeamProcessing: false,
        updateTeamSuccess: false,
        updateTeamError: error
      };
    }
    case fromActionTypes.INIT_DELETE_USER: {
      return {
        ...state,
        deleteTeamUserProcessing: false,
        deleteTeamUserSuccess: false,
        deleteTeamUserError: null
      };
    }
    case fromActionTypes.DELETE_USER: {
      return {
        ...state,
        deleteTeamUserProcessing: true
      };
    }
    case fromActionTypes.DELETE_USER_FULFILLED: {
      return {
        ...state,
        deleteTeamUserProcessing: false,
        deleteTeamUserSuccess: true,
        deleteTeamUserError: null
      };
    }
    case fromActionTypes.DELETE_USER_REJECTED: {
      const {error} = action.payload;
      return {
        ...state,
        deleteTeamUserProcessing: false,
        deleteTeamUserSuccess: false,
        deleteTeamUserError: error
      };
    }
    case fromActionTypes.INIT_UPDATE_USER_ROLE: {
      return {
        ...state,
        updateUserRoleProcessing: false,
        updateUserRoleSuccess: false,
        updateUserRoleError: null,
        updatedUserRole: null
      };
    }
    case fromActionTypes.UPDATE_USER_ROLE: {
      return {
        ...state,
        updateUserRoleProcessing: true
      };
    }
    case fromActionTypes.UPDATE_USER_ROLE_FULFILLED: {
      const {updatedUserRole} = action.payload;

      return {
        ...state,
        updateUserRoleProcessing: false,
        updateUserRoleSuccess: true,
        updateUserRoleError: null,
        updatedUserRole
      };
    }
    case fromActionTypes.UPDATE_USER_ROLE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        updateUserRoleProcessing: false,
        updateUserRoleSuccess: false,
        updateUserRoleError: error,
        updatedUserRole: null
      };
    }
    case fromActionTypes.INIT_FILL_POSITION: {
      return {
        ...state,
        fillPositionProcessing: false,
        fillPositionSuccess: false,
        fillPositionError: null
      };
    }
    case fromActionTypes.FILL_POSITION: {
      return {
        ...state,
        fillPositionProcessing: true
      };
    }
    case fromActionTypes.FILL_POSITION_FULFILLED: {
      return {
        ...state,
        fillPositionProcessing: false,
        fillPositionSuccess: true,
        fillPositionError: null
      };
    }
    case fromActionTypes.FILL_POSITION_REJECTED: {
      const {error} = action.payload;
      return {
        ...state,
        fillPositionProcessing: false,
        fillPositionSuccess: false,
        fillPositionError: error
      };
    }
    case fromActionTypes.INIT_DELETE_POSITION: {
      return {
        ...state,
        deletePositionProcessing: false,
        deletePositionSuccess: false,
        deletePositionError: null
      };
    }
    case fromActionTypes.DELETE_POSITION: {
      return {
        ...state,
        deletePositionProcessing: true
      };
    }
    case fromActionTypes.DELETE_POSITION_FULFILLED: {
      return {
        ...state,
        deletePositionProcessing: false,
        deletePositionSuccess: true,
        deletePositionError: null
      };
    }
    case fromActionTypes.DELETE_POSITION_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        deletePositionProcessing: false,
        deletePositionSuccess: false,
        deletePositionError: error
      };
    }
    default: {
      return state;
    }
  }
};

