// 3RD PARTY
import * as moment from 'moment';

// UTILS
import REGEXES from 'utils/configuration/const/regexes';

export const timestampToDate = (timestamp) => {
  //* 1000 to get milliseconds
  return new Date(timestamp * 1000)
};

export const timestampToFullDate = (timestamp, format = 'DD.MM.YYYY') => {
  return moment(timestampToDate(timestamp)).format(format)
};

export const isTimestampInPast = (timestamp) => {
  const validUntilTime = new Date(timestamp * 1000).setHours(0, 0, 0, 0);
  const todayTime = new Date().setHours(0, 0, 0, 0);

  return validUntilTime < todayTime;
};

// date string of format 'DD.MM.YYYY'
export const dateHasValidFormat = (dateString = '') => REGEXES.DATE.test(dateString);
