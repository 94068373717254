import { isNextAllowed } from './DialogSequence.logic';


// CONFIG & DATA
export const initialState = {
  // config
  id: '',
  title: '',
  allowBackNavigation: 0, // 0, 1, 2, 3, 4, 5, 6
  // intermissions: [], // passed in via config but not used in reducer

  // assessment state
  questionIndex: 0,
  lastQuestionIndex: 0,
  isEnd: false,
  started: false,
  animationCount: 0,

  // pages, questions, answers
  pages: [],
  answers: {},

  // progress
  progressPagesTotalCount: 0,
  progress: 0, // 0 - 100

  // timing
  clickBlock: false,
  lastTime: 0,
  questionTimes: [],
};


export const init = (overrideState = {}) => {
  const pages = overrideState.pages || initialState.pages;
  const progressPagesTotalCount = getProgressPagesCount(pages);
  return {
    ...initialState,
    ...overrideState,
    progressPagesTotalCount
  };
};

const getProgressPagesCount = (pages) => {
  const progressPages = pages.filter((page) => {
    return !page.isIntermission || page.countAsProgress
  });
  const progressPagesTotalCount = progressPages.length;
  return progressPagesTotalCount;
};

const getProgress = (pages, questionIndex, progressPagesTotalCount) => {
  const passedPages = pages.slice(0, questionIndex + 1);
  const currentProgressPagesCount = getProgressPagesCount(passedPages);
  const progress = 100 * currentProgressPagesCount / progressPagesTotalCount;
  return progress;
};


export const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return {
        ...initialState
      };
    }
    case 'override': {
      const overrideState = action.payload;
      let progressPagesTotalCount = state.progressPagesTotalCount;
      const overridePages = overrideState.pages;
      if (overridePages) {
        progressPagesTotalCount = getProgressPagesCount(overridePages);
      }
      return {
        ...initialState,
        ...overrideState,
        progressPagesTotalCount
      };
    }
    case 'start': {
      const lastTime = action.payload;
      const started = true;
      return {
        ...state,
        lastTime,
        started,
      };
    }
    case 'next': {
      const { answer, time, animationTime } = action.payload;
      const lastTime = time;

      // check: prevent answers during clickBlock
      if (state.clickBlock) {
        return state;
      }

      // check: boundaries, if at boundary => prevent action
      if (state.questionIndex >= state.pages.length - 1) {
        return state;
      }
      // check: boundaries, if going to boundary => set isEnd
      let isEnd = false;
      if (state.questionIndex + 1 === state.pages.length -1) {
        isEnd = true;
      }


      // check: no answer for question? => prevent action
      const currentPage = state.pages[state.questionIndex];
      const { isIntermission, isOptional, id: currentPageId } = currentPage;
      const cachedAnswer = state.answers[currentPageId];
      if (!isIntermission && !isNextAllowed(isOptional, answer, cachedAnswer)) {
        return state;
      }

      // check: skip nextPage if it has hideOnBackNavigation and hideOnBackNavigationWasSkipped
      let nextIndex = state.questionIndex + 1;
      let nextPage = state.pages[nextIndex];
      if (nextPage.hideOnBackNavigation && nextPage.hideOnBackNavigationWasSkipped && state.pages[state.questionIndex + 2]) {
        nextIndex = state.questionIndex + 2;
      }

      // let nextIndex = state.questionIndex + 1;
      // update question indices
      const questionIndex = nextIndex;
      const lastQuestionIndex = state.questionIndex;

      // track answers
      const newAnswer = answer !== undefined
        ? answer
        : cachedAnswer;
      const answers = currentPageId
        ? { ...state.answers, [currentPageId]: newAnswer }
        : { ...state.answers };

      // track progress
      const progressPagesTotalCount = state.progressPagesTotalCount;
      const progress = getProgress(state.pages, questionIndex - 1, progressPagesTotalCount);

      // track time of question
      const questionTimes = [...state.questionTimes]; // copy
      let questionTime = [ ...(questionTimes[lastQuestionIndex] || []) ];
      const totalTime = time - state.lastTime;
      questionTime.push({
        totalTime,
        animationTime,
        answer
      });
      questionTimes[lastQuestionIndex] = questionTime;

      return {
        ...state,
        // indices
        questionIndex,
        lastQuestionIndex,
        isEnd,
        animationCount: state.animationCount + 1,
        // answers
        answers,
        // progress
        progress,
        // timing
        clickBlock: true,
        lastTime,
        questionTimes,
      };
    }
    case 'prev': {
      // boundaries
      if (state.questionIndex <= 0) {
        return state;
      }

      // skip intermission that have hideOnBackNavigation
      const nextPage = state.pages[state.questionIndex - 1];

      const questionIndex = nextPage.isIntermission && nextPage.hideOnBackNavigation
        ? state.questionIndex - 2
        : state.questionIndex - 1;

      if (nextPage.isIntermission && nextPage.hideOnBackNavigation) {
        nextPage.hideOnBackNavigationWasSkipped = true;
      }

      const lastQuestionIndex = state.questionIndex;

      // track progress
      const progressPagesTotalCount = state.progressPagesTotalCount;
      const progress = getProgress(state.pages, questionIndex - 1, progressPagesTotalCount);

      return {
        ...state,
        // indices
        questionIndex,
        lastQuestionIndex,
        isEnd: false,
        animationCount: state.animationCount + 1,
        // progress
        progress,
        // timing
        clickBlock: true
      };
    }
    case 'unsetClickBlock': {
      const clickBlock = false;
      return {
        ...state,
        clickBlock
      };
    }
    default: {
      throw new Error(`action.type ${action.type} not handled`);
    }
  }
};
