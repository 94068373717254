import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import styles from './PotentialResult.module.scss';

import { useTranslate } from 'utils/translator';
import { setDecimalSeperator } from 'utils/userpreference';
import {useBreakpoint} from 'utils/hooks';

import { InfoTitle, LineDiagram } from 'ui/basic';

import {LayoutContext} from 'features/framework/components/MainLayout/MainLayout';


const PotentialResultItem = memo((props) => {
  const { index, label, result, range, exportView, onInfoClick } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const bp = useBreakpoint();

  const [width, setWidth] = useState();

  const { mainNavIsOpenDebounced } = useContext(LayoutContext) || {};

  const getWidth = useCallback(() => {
    const container = document.getElementById('container-id');
    if (bp.isXs) {
      if (!container) {
        return 244;
      }
      return document.body.clientWidth - 80;
    }

    if (bp.isS) {
      if (!container) {
        return 268;
      }
      return container.offsetWidth - 32;
    }

    if (bp.isM || bp.isL || bp.isXl) {
      if (!container) {
        return 304;
      }

      return (container.offsetWidth - 76) / 2;
    }

    return 244;
  }, [bp]);

  const handleResize = useCallback(() => {
    setWidth(getWidth());
  }, [getWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 500);
  }, [mainNavIsOpenDebounced, handleResize]);

  return (
    <div className={styles.row}>
      <LineDiagram score={result} range={range} width={width}/>
      <div className={styles.points}>
        {range ?
          (range && result ? setDecimalSeperator(result) : `${range[0]}-${range[1]}`) :
          setDecimalSeperator(result)
        }
      </div>

      {exportView ?
        <span className='blu-typeCopy'>{translate(`potential_results_label_dim${index + 1}`) || label}</span> :
        <InfoTitle
          title={translate(`potential_results_label_dim${index + 1}`) || label}
          type={'regular'}
          onClick={onInfoClick}
        />
      }
    </div>
  );
});

export default PotentialResultItem;
