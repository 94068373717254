import React from 'react';

// OTHER COMPONENTS
import { Breathe } from 'ui/molecules/AssessmentNext/intermissions/Breathe';

// STORE
import store from 'store';

// UTILS
import * as api from 'api';
import { randomizeArray } from 'utils/randomize';
import {isValid} from './numbers';
import {
  hasValidStorageValidityWindow,
  storageController,
  copyAssessmentConfig,
} from 'ui/molecules/AssessmentNext/AssessmentNext.logic';
import {ASSESSMENT_NEXT_TYPES} from 'ui/molecules/AssessmentNext/AssessmentNext.config';
import {ASSESSMENT_TYPES, NINE_LEVELS_ORDER} from 'utils/configuration/const/assessment-types';
import { eventBus } from 'architecture/eventBus';

// CONFIG
const Config = {
  breatheAtPercent: 80,
};

//sourceResults are for defining which exact results to use for mapping;
//e.g. for current (logged) user it would be state.assessments and
//for employee it would be assessment results fetched from profile
export const getResults = (assessmentId, sourceResults) => {
  const state = store.getState();
  const resultSchema = getResultSchema(assessmentId);

  let results = [];
  if (sourceResults) {
    results = sourceResults;
  } else {
    results = state && state.assessments && state.assessments[assessmentId] && state.assessments[assessmentId].results;
  }

  if (!resultSchema || !results) {
    return null;
  }

  const resultsMatrix = [];
  resultSchema.forEach((dimension, i) => {
    const subdimensions = [];
    if (dimension.subDimensions) {
      dimension.subDimensions.forEach((subDimension, j) => {
        subdimensions.push(results[i + j] ? results[i + j].result : null);
      });
    }
    resultsMatrix.push(subdimensions);
  });
  return resultsMatrix;
};


export const getMappedNineLevelsResults = (assessmentResults = []) => {
  let mappedResults = [];

  assessmentResults.forEach(assResult => {
    //replace cyan with turquoise. 9levels returns cyan whereas on front-end 'turquoise' is used;
    const assessmentResultId = assResult.id.replace('cyan', 'turquoise');
    const assessmentResultName = assResult.name.replace('cyan', 'turquoise');

    const obj = {id: assessmentResultId, name: assessmentResultName};

    if (assResult.id.includes('res') || assResult.id.includes('highvalues') || !assResult.id.includes('levels_')) {
      return;
    }

    const positiveResult = assResult.result;
    const negativeResult = assessmentResults.find(r => r.id === assResult.id.split('_').join('_res_')).result;

    obj.result = [-(Math.abs(Math.ceil(negativeResult))), Math.ceil(positiveResult)];

    mappedResults[NINE_LEVELS_ORDER[assessmentResultId.split('levels_')[1].toLowerCase()]] = obj;
  });

  return mappedResults;
};

export const getResultSchema = (assessmentId) => {
  const state = store.getState();
  const resultSchema =
    state &&
    state.assessments &&
    state.assessments[assessmentId] &&
    state.assessments[assessmentId].resultSchema;

  return resultSchema;
};

const assessmentProfile = {
  introPage: true,
  guidancePage: true,
  backAllowed: true,
  oneTimeBackAllowed: false,
  forthAllowed: true,
  randomize: false,
  timePerQuestion: 30,
  deepBreathAt: 80,
  showCopyright: false,

  showDataProtectionLink: false,
  showDataProcessingLink: false,
  showCopyrightAgreementLink: false,

  languageRequiredForRegistration: false,

  showAdditionalInfoPage: false,
  showAge: false,
  showBirthDate: false,

  waitResult: false,
  showNextAssessmentButton: true
};

export const getAssessmentProfile = (assessmentId) => {
  const state = store.getState();
  const profile = { ...assessmentProfile };
  const assessment = state && state.assessments && state.assessments[assessmentId];

  if (!assessment) {
    return null;
  }

  if (assessment.randomized !== undefined) {
    profile.randomize = assessment.randomized;
  }
  if (assessment.free_navigation !== undefined) {
    profile.backAllowed = assessment.free_navigation;
    profile.allowBackNavigation = assessment.free_navigation;
    profile.forthAllowed = assessment.free_navigation;
  }
  if (assessment.interstitial !== undefined) {
    profile.deepBreathAt = assessment.interstitial ? 80 : null;
  }
  if (assessment.registrationRequired && !assessment.registered) {
    profile.showAdditionalInfoPage = true;
  }
  if (assessment.id === ASSESSMENT_TYPES.RMP || assessment.id === ASSESSMENT_TYPES.NINE_LEVELS) {
    profile.showCopyright = true;
  }

  if (assessment.id === ASSESSMENT_TYPES.RMP) {
    profile.waitResult = true;

    profile.showDataProtectionLink = true;
    profile.showDataProcessingLink = true;
    profile.showCopyrightAgreementLink = true;

    profile.languageRequiredForRegistration = true;

    profile.showAge = true;
  }

  if (assessment.id === ASSESSMENT_TYPES.NINE_LEVELS) {
    profile.showDataProcessingLink = true;
    profile.languageRequiredForRegistration = true;

    profile.showBirthDate = true;
  }

  if (assessment.id === ASSESSMENT_TYPES.MINDFULNESS_JOURNEY) {
    profile.forthAllowed = false;
    profile.oneTimeBackAllowed = true;
    profile.showNextAssessmentButton = false;
  }

  return profile;
};

export const isAssessmentCompleted = (assessment) => {
  if (!assessment || !assessment.stages) {
    return false;
  }

  if (isValid(assessment.progress) && assessment.progress === 1) {
    return true;
  }

  return assessment.stages.every(stage => stage.questionsAnswered > 0 && (stage.questionsAnswered >= stage.length));
};

export const mapQuestions = (questions, defaultConfig) => {
  const answerOptions = defaultConfig && defaultConfig.answerOptions
    ? defaultConfig.answerOptions.map((option) => {
        return {
          label: option.text,
          value: option.option,
          labelLight: option.option,
        }
      })
    : undefined ;
  return questions.map((question) => {
    return {
      ...question,
      answerOptions, // need to be activated on each AssessmentPage
      isIntermission: question.explanatory,
      question: question.label ? question.label : question.question,
      questionLabel: question.label ? question.question : '',
      questionDescription: question.description,
    };
  });
};

// map answers from onAllAnswers callback to backend answers
export const mapAllAnswers = (answers) => {
  return Object.keys(answers)
  .map((questionId) => {
    return {
      question: questionId,
      content: answers[questionId]
    };
  });
};

// map answer from onAnswer callback to backend answer
export const mapAnswer = (answer) => {
  return {
    question: answer.questionId,
    content: answer.answer,
  };
};

// map answers from backend to frontend
export const mapFromAnswers = (answers) => {
  return answers.map((answer) => {
    return {
      questionId: answer.question,
      answer: answer.content
    };
  });
};

export const insertBreathe = (questions, percentOverride = Config.breatheAtPercent) => {
  questions = [...questions];
  const index = Math.round((questions.length - 1) * Config.breatheAtPercent / 100);
  questions.splice(index, 0, {
    isIntermission: true,
    hideOnBackNavigation: true,
    render: (next) => <Breathe onSkip={next} />,
  });

  return questions;
};

export const sortByPrevAnswers = (questions = [], prevAnswers = []) => {
  questions = [...questions];
  // reverse to put questions at beginning in right order
  prevAnswers = [...prevAnswers].reverse();

  if (prevAnswers.length) {
    prevAnswers.forEach((prevAnswer) => {
      const questionIndex = questions.findIndex((question) => {
        return question.id === prevAnswer.questionId
      });
      if (questionIndex === -1) {
        return;
      }
      const question = questions[questionIndex];
      // delete question
      questions.splice(questionIndex, 1);
      // add at beginning
      questions.unshift(question);
    });
  }

  return questions;
};


export const handleAssessmentAnswer = (answer, assessmentId) => {
  let stageNumber = answer.stageNumber || 1;
  // console.log('answer', answer);
  if (answer && answer.questionId && answer.answer !== undefined) {
    api.post(`/core/assessments/${assessmentId}/${stageNumber}/answers`, {
      answers: [mapAnswer(answer)]
    })
    .then((response) => {
      if (!response.ok) {
        console.error(response.data);
        eventBus.dispatch('assessmentNext.error', {
          message: response.data?.error?.errorMessage || response.data
        })
      }
    })
    .catch(error => {
      eventBus.dispatch('assessmentNext.error', {
        message: error.toString()
      })
    });
  }
  else {
    api.get('core/user/heartbeat');
  }
};

// call cancel before getting assessment if we aren't in a valid storage state window
export const assessmentShouldCancel = (assessmentType, userId, ) => {
  // call cancel before getting assessment if we aren't in a valid storage state window
  const config = copyAssessmentConfig(assessmentType);
  storageController.init(assessmentType, userId);
  const deflatedStorageState = storageController.loadState();
  storageController.reset();
  
  if (!deflatedStorageState) {
    return false;
  }

  return !hasValidStorageValidityWindow(deflatedStorageState, config);
};


export const initialiseAssessment = (params) => {

  const {
    userId,
    assessmentId,
    handleExit
  } = params;

  if (!userId || !assessmentId || !handleExit) {
    throw new Error(`invalid userId, assessmentId or handleExit: ${userId}, ${assessmentId}, ${handleExit}`);
  }

  let assessmentType = ASSESSMENT_NEXT_TYPES[assessmentId]
    || `${assessmentId}`; // fallback for custom assessments
  
  // Override BY type locally to retrieve cached answers and prevent canceling the assessment
  if (assessmentId === ASSESSMENT_TYPES.BALANCED_YOU) {
    assessmentType = 'balancedYou';
  }

  let assessment;
  let questions;
  let promise;

  // call cancel before getting assessment if we aren't in a valid storage state window
  const shouldCancel = assessmentShouldCancel(assessmentType, userId);
  if (!shouldCancel) {
    promise = api.get(`/core/assessments/${assessmentId}`)
  }
  else {
    // promise = api.get('core/user/heartbeat')
    promise = api.post(`core/assessments/${assessmentId}/cancel`)
    .then(() => {
      return api.get(`/core/assessments/${assessmentId}`);
    });
  }

  // get assessment
  return api.get(`/core/assessments/${assessmentId}/1/locked`)
  .then(({ok, status, data}) => {
    if (ok && status === 200 && data.locked) {
      handleExit();
    }

    return promise;
  })
  // check if user is eligble to take assessment, setConfigOverride
  .then((result) => {
    assessment = result.data;
    // console.log('assessment', assessment);

    // it is not allowed to re-take the assessment if all questions in all stages are already answered
    // not the case for Balanced You assessment. BY assessment can be taken multiple times if not locked
    if (isAssessmentCompleted(assessment) && assessment.id !== ASSESSMENT_TYPES.BALANCED_YOU) {
      handleExit();
    }

    return api.get(`/core/assessments/${assessmentId}/1/questions`);
  })
  //  get questions
  .then(({ status, ok, data, headers }) => {
    if (ok && status === 200) {

      questions = mapQuestions(data.questions, data.default);
      if (assessment.randomized) {
        questions = randomizeArray(questions);
      }
      if (assessment.interstitial) {
        // insert Breathe at 80%
        questions = insertBreathe(questions);
      }
      // console.log('questions', questions);
    }
    return api.get(`/core/assessments/${assessmentId}/1/answers`);
  })
  // get answers
  .then(({ status, ok, data, headers }) => {
    let prevAnswers;

    if (ok && status === 200) {
      prevAnswers = mapFromAnswers(data.answers);
      prevAnswers = prevAnswers && prevAnswers.length > 0 && prevAnswers;
      // console.log('prev answers', prevAnswers);

      if (assessment.randomized && prevAnswers) {
        questions = sortByPrevAnswers(questions, prevAnswers)
        // console.log('questions sorted by answers', questions);
      }
    }

    return {questions, assessment, prevAnswers};
  });

};

export const orderProfileAssessments = (assessments) => {
  const unorderedCoreAssessments = [];
  const orderedCoreAssessments = [];
  const customAssessments = [];

  // separate core and custom assessments into 2 different arrays
  assessments.forEach(assessmentItem => {
    if (Object.values(ASSESSMENT_TYPES).includes(assessmentItem.assessment)) {
      unorderedCoreAssessments.push(assessmentItem);
    } else {
      customAssessments.push(assessmentItem);
    }
  })

  // order core assessments
  Object.values(ASSESSMENT_TYPES).forEach(assessmentId => {
    const thisAssessment = unorderedCoreAssessments.find(a => a.assessment === assessmentId);
    if (thisAssessment) {
      orderedCoreAssessments.push(thisAssessment);
    }
  })

  return orderedCoreAssessments.concat(customAssessments);
}
