import * as fromActionTypes from 'store/actionTypes/profile';

export const initProfile = () => {
  return {
    type: fromActionTypes.INIT_PROFILE,
    payload: {}
  };
};

export const getProfile = (userId) => {
  return {
    type: fromActionTypes.GET_PROFILE,
    payload: {userId}
  };
};

export const getProfileFulfilled = ({ profile }) => {
  return {
    type: fromActionTypes.GET_PROFILE_FULFILLED,
    payload: { profile }
  };
};

export const getProfileRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_PROFIlE_REJECTED,
    payload: { error }
  };
};
