export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_PENDING = 'GET_CURRENT_USER_PENDING';
export const GET_CURRENT_USER_FULFILLED = 'GET_CURRENT_USER_FULFILLED';
export const GET_CURRENT_USER_REJECTED = 'GET_CURRENT_USER_REJECTED';

export const getCurrentUser = () => {
  return {
    type: GET_CURRENT_USER,
    payload: null
  };
};

export const getCurrentUserFulfilled = (user) => {
  return {
    type: GET_CURRENT_USER_FULFILLED,
    payload: user
  };
};

export const getCurrentUserRejected = ({ error }) => {
  return {
    type: GET_CURRENT_USER_REJECTED,
    payload: { error }
  };
};

