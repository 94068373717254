// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './StandaloneWrapper.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { StandaloneHeader } from './StandaloneHeader';
import { Footer } from '../Footer';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE OLD
import store from 'store';
import { Provider } from 'react-redux';


// CONFIG & DATA
// const Config = {};

// COMPONENT: StandaloneWrapper
const StandaloneWrapper = (props) => {
  // PROPS
  const {
    children,
    compensateHeader = true, // always true since we're including
    // pages from old architecture mostly
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: StandaloneWrapper
  return (
    <div className={classNames(styles.standaloneWrapper, {
      [styles.compensateHeader]: compensateHeader
    })}>
      <div className={styles.header}>
        <Provider store={store}>
          <StandaloneHeader />
        </Provider>
      </div>
      <div className={styles.content}>
        { children }
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default StandaloneWrapper;
