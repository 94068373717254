import * as fromActionTypes from 'store/actionTypes/organization';

//GET MINDFULNESS JOURNEY SCORE OF ORGANIZATION
export const getMindJourneyScore = () => {
  return {
    type: fromActionTypes.GET_MIND_JOURNEY_SCORE,
    payload: {}
  };
};

export const getMindJourneyScoreFulfilled = ({score}) => {
  return {
    type: fromActionTypes.GET_MIND_JOURNEY_SCORE_FULFILLED,
    payload: { score }
  };
};

export const getMindJourneyScoreRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_MIND_JOURNEY_SCORE_REJECTED,
    payload: { error }
  };
};

//GET ORG STATISTIC
export const getStatistic = () => {
  return {
    type: fromActionTypes.GET_STATISTIC,
    payload: {}
  };
};

export const getStatisticFulfilled = ({statistic}) => {
  return {
    type: fromActionTypes.GET_STATISTIC_FULFILLED,
    payload: { statistic }
  };
};

export const getStatisticRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_STATISTIC_REJECTED,
    payload: { error }
  };
};

//GET MINDFULNESS JOURNEY RESULTS
export const getMindJourneyResults = () => {
  return {
    type: fromActionTypes.GET_MIND_JOURNEY_RESULTS,
    payload: {}
  };
};

export const getMindJourneyResultsFulfilled = ({results}) => {
  return {
    type: fromActionTypes.GET_MIND_JOURNEY_RESULTS_FULFILLED,
    payload: { results }
  };
};

export const getMindJourneyResultsRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_MIND_JOURNEY_RESULTS_REJECTED,
    payload: { error }
  };
};
