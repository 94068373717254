// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './DialogHeader.module.scss';

// ASSETS
import { ReactComponent as Logo } from 'assets/bq_brandmark.svg';
import { ReactComponent as Close } from 'assets/icons/icn_close_big.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import DropDownSubtle  from 'ui/basic/menus/DropDownSubtle';

// UTILS

// STORE
import {useDispatch, useSelector} from 'react-redux';
import {changeLanguage} from 'store/actions';

// CONFIG & DATA
const LANGUAGES = {
  de_DE: 'Deutsch',
  en_US: 'English'
};

// COMPONENT: DialogHeader
const DialogHeader = (props) => {
  // PROPS

  const {
    title, // shows logo if falsy

    showLanguageToggle,
    smallHeader,

    onClose, // shows close button if truthy
  } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();

  //STORE: LANGUAGE
  const allLanguages = useSelector((state) => (
    (state.localisation && state.localisation.languages) ? state.localisation.languages : []
  ));

  const currentLanguage = useSelector(state => state.localisation && state.localisation.currentLanguage);


  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleLanguageChange = (index) => {
    dispatch(changeLanguage({
      language: allLanguages[index],
      staticValuesToRefetch: 'all'
    }));
  };

  // HELPERS

  // RENDERS

  // RENDER: DialogHeader
  return (
    <div className={classNames(styles.dialogHeader, {
      [styles.smallHeader]: smallHeader
    })}>
      <div className={styles.left}>

        {/* TITLE */}
        { title && (
          <div className={styles.title}>
            { title }
          </div>
        )}

        {/* LOGO */}
        { !title && (
          <div className={styles.logo}>
            <Logo />
          </div>
        )}

      </div>
      <div className={styles.right}>

        {/* LANGUAGE TOGGLE */}
        { showLanguageToggle && (
          <DropDownSubtle
            className='alignRight'
            options={allLanguages.map(language => ({
              value: language.split('_')[0].toUpperCase(), // EN
              label: LANGUAGES[language] // English
            }))}
            activeIndex={allLanguages.indexOf(currentLanguage)}
            onChange={index => handleLanguageChange(index)}
          />
        )}

        {/* CLOSE BUTTON */}
        { !showLanguageToggle && onClose && (
          <div className={styles.closeButton} onClick={onClose}>
            <Close />
          </div>
        )}
      </div>
    </div>
  );
};

export default DialogHeader;
