import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/employeeOverview';
import * as fromActions from 'store/actions/employeeOverview';
import {all} from '@redux-saga/core/effects';
import {get} from 'api';
import {getMappedEmployees} from 'utils/users';
import {LIMIT} from 'utils/configuration/const/pagination';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';

function* getEmployees({payload}) {
  try {
    let roleId, limit = LIMIT, offset, sort, sortOrder, search;

    if (payload.requestDto) {
      const requestDto = payload.requestDto;
      roleId = requestDto.roleId;
      limit = requestDto.limit ? requestDto.limit : LIMIT;
      offset = requestDto.offset;
      sort = requestDto.sort;
      sortOrder = requestDto.sortOrder;
      search = requestDto.search;
    }

    const { status, ok, data, headers } = yield call(get, 'core/company/users', {
      expand: 'user',
      role: roleId,
      limit,
      offset,
      sort,
      sortOrder,
      search
    });

    if (ok && status === 200 && data.users) {
      const tempUsers = [...data.users];

      // const userPictureResponses = yield all(data.users.map(user => call(get_base64, 'core/user/picture', {id: user.user.id})));
      // userPictureResponses
      //   .filter(response => response.ok && response.status === 200)
      //   .forEach(response => {
      //     const image = `data:image/jpg;base64,${response.data}`;
      //     const foundUser = tempUsers.find(tempUser => tempUser.user.id === response.config.params.id);
      //     foundUser.user.userPicture = image;
      //   });

      if (CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST) {
        const orgRolesResponses = yield all(
          tempUsers.map(tempUser => call(get, `/rolemapping/users/${tempUser.user.id}`))
        );

        orgRolesResponses
          .filter(response => response.ok && response.status === 200)
          .forEach(response => {
            const orgRoles = response.data.roles;
            const userId = response.config.url.split('/').pop();

            const foundUser = tempUsers.find(tempUser => tempUser.user.id === userId);
            foundUser.user.organizationalRoles = orgRoles;
          });
      }

      yield put(fromActions.getEmployeesFulfilled({
        employees: getMappedEmployees(tempUsers),
        totalCount: (headers && headers['x-total-result-count']) ? headers['x-total-result-count'] : undefined
      }));
    }
    else {
      yield put(fromActions.getEmployeesRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getEmployeesRejected({ error }));
  }
}

export function* watchGetEmployees() {
  yield takeEvery(fromActionTypes.GET_EMPLOYEES, getEmployees);
}

function* getEmployee(payload) {
  try {
    const {employeeId} = payload.payload;

    const params = new URLSearchParams();
    params.append('id', employeeId);
    params.append('expand', 'roles');
    params.append('expand', 'organizationalRoles');

    const { status, ok, data } = yield call(get, 'core/user/info', params);
    const employeeData = {...data, id: employeeId};

    if (ok && status === 200) {
      const mappedEmployee = getMappedEmployees([{user: employeeData}])[0];
      yield put(fromActions.getEmployeeFulfilled({ employee: mappedEmployee}));
    } else {
      yield put(fromActions.getEmployeeRejected({ error: data.error }));
    }
  }
  catch(error) {
    yield put(fromActions.getEmployeeRejected({ error }));
  }
}

export function* watchGetEmployee() {
  yield takeEvery(fromActionTypes.GET_EMPLOYEE, getEmployee);
}
