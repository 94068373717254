// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './AssessmentConfigurationModal.module.scss';

// ASSETS

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
import { Modal, Toggle } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_DESCRIPTIONS, ASSESSMENT_TITLES } from 'utils/configuration/const/assessment-types';

// STORE
import * as api from 'api';
import {useDispatch} from 'react-redux';
import {showToast} from 'store/actions';
import * as fromActions from 'features/+candidates/store/candidate/candidate.actions';

// CONFIG & DATA
// const Config = {};

// COMPONENT: AssessmentConfigurationModal
const AssessmentConfigurationModal = (props) => {
  // PROPS
  const { assessments, userId, onClose } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ featuresSaveProcessing, setFeaturesSaveProcessing ] = useState();

  const handleAssessmentChange = (assessment, assessmentActivated) => {
    setFeaturesSaveProcessing(true);

    const thisAssessment = assessments.find(a => a.id === assessment.id);
    thisAssessment.active = assessmentActivated;

    api.put(`/core/company/users/${userId}/features`, {
      features: assessments.map(assessment => {
        const assessmentId = assessment.isCustomAssessment ?
          `custom-assessment_${assessment.id}` :
          `ASSESSMENT_${assessment.id.toUpperCase()}`;

        return {
          id: assessmentId,
          active: assessment.active
        }
      })
    })
      .then(({ok, status, data}) => {
        setFeaturesSaveProcessing(false);

        if (ok && status === 200) {
          let toastTitle;
          let toastContent;

          if (assessmentActivated) {
            toastTitle = translate('user_settings__assessment_activated_toast_title') || 'Assessment aktiviert';
            toastContent = translate('user_settings__assessment_activated_toast_content') ||
              'Das ausgewählte Assessment ist nun für den Nutzer verfügbar.';
          } else {
            toastTitle = translate('user_settings__assessment_deactivated_toast_title') || 'Assessment deaktiviert';
            toastContent = translate('user_settings__assessment_deactivated_toast_content') ||
              'Das ausgewählte Assessment ist nicht mehr für den Nutzer verfügbar. ';
          }

          dispatch(showToast(toastTitle, toastContent));
          dispatch(fromActions.getCandidateAssessments({id: userId, showLoading: false}));
        }
      })
      .catch(error => {
        setFeaturesSaveProcessing(false);
        console.error(error.message);
      });
  };

  // RENDER: AssessmentConfigurationModal
  return (
    <Modal
      header={translate('user_settings_configure_assessments_title') || 'Verfügbare Assessments konfigurieren'}
      secondaryButtonTitle={translate('close_lbl') || 'Schließen'}
      onClose={onClose}
    >
      <div className={styles.assessmentConfigurationModal}>
        {translate('user_settings_configure_assessments_copy') ||
        'Hier kannst du die Verfügbarkeit von Assessments für diesen Nutzer ändern. '}

        <div className={styles.list}>
          { assessments.map((assessment, index) => (
            <div className={styles.listItem} key={index}>
              <div className={styles.row1}>
                <span className='blu-typeLabel'>
                  { translate(ASSESSMENT_TITLES[assessment.id]) || assessment.title || assessment.id }

                  { assessment.active && (
                    ` (${assessment.completed ?
                        (translate('reset_assessments__completed_status') || 'abgeschlossen') :
                        (translate('reset_assessments__open_status') || 'offen')
                    })`
                  )}

                  { !assessment.active && ` (${translate('not_available_lbl') || 'nicht verfügbar'})` }
                </span>
                <Toggle
                  id={`${assessment.id}_employeeProfile`}
                  checked={assessment.active}
                  disabled={featuresSaveProcessing}
                  onChange={value => handleAssessmentChange(assessment, value)}
                />
              </div>

              <div className={styles.row2}>
                { translate(ASSESSMENT_DESCRIPTIONS[assessment.id]) || assessment.description }
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AssessmentConfigurationModal;
