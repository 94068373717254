// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useRef, useCallback } from 'react';
import styles from './TextEditor.module.scss';

// ASSETS
import { ReactComponent as Bold } from './assets/bold.svg';
import { ReactComponent as Italic } from './assets/italic.svg';
import { ReactComponent as Underline } from './assets/underline.svg';
import { ReactComponent as ListOrdered } from './assets/list_ordered.svg';
import { ReactComponent as ListUnordered } from './assets/list_unordered.svg';

// 3RD PARTY
import classNames from 'classnames';
import {
  Editor, EditorState,
  RichUtils, getDefaultKeyBinding,
  // CompositeDecorator,
  convertFromRaw, convertToRaw,
} from 'draft-js';
import './css/draft.css';

// OTHER COMPONENTS
import Button from 'ui/basic/forms/Button';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA

// COMPONENT: TextEditor
const TextEditor = (props) => {
  // PROPS
  const {
    placeholder,
    content,
    disabled,
    onChange,
    onSave = () => { },
    onCancel = () => { },
  } = props;

  // COMPONENT/UI STATE and REFS
  // const decorator = new CompositeDecorator([
    //   {
      //     strategy: findLinkEntities,
      //     component: Link,
      //   },
      // ]);
  // const [ editorState, setEditorState ] = useState(EditorState.createEmpty(decorator));

  const [ editorState, setEditorState ] = useState(EditorState.createEmpty());
  const [ focussed, setFocussed ] = useState(false);
  const [ empty, setEmpty ] = useState(!content);
  const [ hidePlaceholder, setHidePlaceholder ] = useState(!content);
  const editor = useRef(null);

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS
  useEffect(() => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    // console.log('rawContent', rawContent);
    // console.log('rawContentJSON', JSON.stringify(rawContent));
    const firstBlock = rawContent.blocks && rawContent.blocks[0];
    if (firstBlock && firstBlock.text) {
      setEmpty(false);
    }
    else {
      setEmpty(true);
    }
    // HIDE PLACEHOLDER when changed to blocktype before text is entered
    let hidePlaceholder = false;
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        hidePlaceholder = true;
      }
    }
    setHidePlaceholder(hidePlaceholder);
  }, [editorState]);

  const initializeContent = useCallback(() => {
    let newEditorState;
    if (content) {
      // if content includes already formatted text
      if (content.includes('{"blocks')) {
        const contentParsed = JSON.parse(content);
        newEditorState = EditorState.createWithContent(convertFromRaw(contentParsed) /*, decorator*/)
      } else {
        // if content contains only plain text
        newEditorState = EditorState.createWithText(content);
      }
    }
    else {
      newEditorState = EditorState.createEmpty();
    }
    setEditorState(newEditorState);
  }, [content]);

  useEffect(() => {
    initializeContent(content);
  }, [content, initializeContent]);


  // STORE HOOKS

  // METHODS
  const focus = () => {
    if (disabled) return;
    if (editor.current) editor.current.focus();
    setFocussed(true);
  };

  const cancel = () => {
    // defocus and reset state
    setFocussed(false);
    initializeContent(content);
    onCancel();
  };

  const save = () => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const rawContentJSON = JSON.stringify(rawContent);
    setFocussed(false);
    onSave(rawContentJSON);
  };

  // EVENT HANDLES
  const handleKeyCommand = useCallback(
    (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
      return 'not-handled';
    },
    [setEditorState],
  );

  // ALLOW NESTED LISTS
  const mapKeyToEditorCommand = useCallback(
    e => {
      switch (e.keyCode) {
        case 9: // TAB
          const newEditorState = RichUtils.onTab(
            e,
            editorState,
            2 /* maxDepth, starting from 0 */,
          );
          if (newEditorState !== editorState) {
            setEditorState(newEditorState);
          }
          return null;
        default:
          return getDefaultKeyBinding(e);
      }
    },
    [editorState, setEditorState],
  );

  const handleChange = (editorState) => {
    setEditorState(editorState);

    if (onChange) {
      const rawContent = convertToRaw(editorState.getCurrentContent());
      const rawContentJSON = JSON.stringify(rawContent);
      onChange(rawContentJSON);
    }
  }

  // const createLink = () => {
  //   const selection = editorState.getSelection();
  //   if (!selection.isCollapsed()) {
  //     const contentState = editorState.getCurrentContent();
  //     const startKey = editorState.getSelection().getStartKey();
  //     const startOffset = editorState.getSelection().getStartOffset();
  //     const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
  //     const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

  //     let url = '';
  //     if (linkKey) {
  //       const linkInstance = contentState.getEntity(linkKey);
  //       url = linkInstance.getData().url;
  //     }

  //     // setTimeout(() => this.refs.url.focus(), 0);


  //     const urlValue = 'https://www.ecosia.org/';

  //     const contentStateWithEntity = contentState.createEntity(
  //       'LINK',
  //       'MUTABLE',
  //       {url: urlValue}
  //     );
  //     const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  //     const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

  //     setEditorState(
  //       RichUtils.toggleLink(
  //         newEditorState,
  //         newEditorState.getSelection(),
  //         entityKey
  //       )
  //     );
  //     setTimeout(() => editor.current.focus(), 0);
  //   }
  // };

  // HELPERS

  // RENDERS

  // RENDER: TextEditor
  return (
    <div className={classNames(styles.textEditor, {
        [styles.empty]: empty && !focussed,
        [styles.disabled]: disabled,
        [styles.focussed]: focussed,
        [styles.hidePlaceholder]: hidePlaceholder,
      })}>

      {/* BOX */}
      <div className={styles.box}
        onClick={focus}
      >
        {/* FORMATTING BAR */}
        <div className={styles.formattingBar}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={inlineStyle => {
              const newState = RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle,
              );
              setEditorState(newState);
            }}
          />
          <BlockStyleControls
            editorState={editorState}
            onToggle={blockType => {
              const newState = RichUtils.toggleBlockType(editorState, blockType);
              setEditorState(newState);
            }}
            // createLink={createLink}
          />
        </div>

        {/* EDITOR CONTAINER */}
        <div className={classNames(styles.editorContainer, {
            [styles.hidePlaceholder]: hidePlaceholder
          })}>

          <Editor
            ref={editor}
            readOnly={disabled}
            placeholder={placeholder}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={handleChange}
          />
        </div>
      </div>


      {/* BUTTONS */}
      {(onSave && !onChange) &&
      <div className={styles.buttons}>
        <Button size='S' looks='tertiary'
                onClick={cancel}>
          { translate('edp_button_cancel') || 'Abbrechen' }
        </Button>
        <Button size='S' looks='primary'
                onClick={save}>
          { translate('gen_settings_change_password_btn') || 'Speichern' }
        </Button>
      </div>
      }

    </div>
  );
};

function StyleButton({onToggle, active, label, style}) {

  return (
    <span
      className={classNames(styles.styleButton, {
        [styles.active]: active
      })}
      onMouseDown={e => {
        e.preventDefault();
        onToggle(style);
      }}>
      {label}
    </span>
  );
}

const BLOCK_TYPES = [
  {label: <ListUnordered/>, style: 'unordered-list-item'},
  {label: <ListOrdered/>, style: 'ordered-list-item'},
];

function BlockStyleControls({editorState, onToggle, createLink}) {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className={styles.controls}>
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
      {/* <StyleButton
        label='Link'
        onToggle={(e) => {
          // e.preventDefault();
          createLink();
        }}
      /> */}
    </div>
  );
}

const INLINE_STYLES = [
  {label: <Bold />, style: 'BOLD'},
  {label: <Italic />, style: 'ITALIC'},
  {label: <Underline />, style: 'UNDERLINE'},
];

function InlineStyleControls({editorState, onToggle}) {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className={styles.controls}>
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}

// function findLinkEntities(contentBlock, callback, contentState) {
//   contentBlock.findEntityRanges(
//     (character) => {
//       const entityKey = character.getEntity();
//       return (
//         entityKey !== null &&
//         contentState.getEntity(entityKey).getType() === 'LINK'
//       );
//     },
//     callback
//   );
// }

// const Link = (props) => {
//   const {url} = props.contentState.getEntity(props.entityKey).getData();
//   return (
//     <a className={styles.link}
//       href={url} target='_blank'
//       rel='noopener noreferrer'
//     >
//       {props.children}
//     </a>
//   );
// };

export default TextEditor;
