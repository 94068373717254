import React from 'react';

import {
  AdminUserGroup,
  BasicUserGroup,
  CandidateUserGroup,
  LeaderUserGroup,
  CandidateCompleteProfile,
  CompleteProfile,
  Info,
  Legal,
  MyProfile,
  OrganisationSettings,
  Overview,
  ResetPassword,
  ResetPasswordRequest,
  Settings,
  SignUp,
  Support,
  Survey, UserIndividualSettings,
  UserManagement,
  Login,
  LogoutSuccessForm,
  ExternalProfile,
  Debug,
  ProfileDeleted,
  WellBeingMeasurement,
  NonexistentInstance,
  Maintenance,
} from './pages';


export const routesConfigFramework = {

  show: {
    forFlavor: 'all',
    forUserGroup: 'all',
  },

  routes: [
    // FRAMEWORK ROUTES: NO LOGIN REQUIRED
    {
      path: '/login',
      pageType: 'standalone',
      PageComponent: () => <Login />,
      show: {
        needsLogout: true,
        forFlavor: ['bluquist', 'balancedYou'],
      },
      tools: {
        browserTitleKey: 'login_button_login'
      }
    },
    {
      path: '/logged-out',
      pageType: 'standalone',
      PageComponent: () => <LogoutSuccessForm />,
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'pp_button_logout'
      }
    },
    {
      path: '/new-account',
      pageType: 'standalone',
      PageComponent: () => <SignUp />,
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'signup_button_signup'
      }
    },
    {
      path: '/recovery-password/:token',
      pageType: 'standalone',
      PageComponent: () => <ResetPassword />,
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'resetpw_title'
      }
    },
    {
      path: '/recovery-password',
      pageType: 'standalone',
      PageComponent: () => <ResetPasswordRequest />,
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'resetpw_title'
      }
    },
    {
      path: '/profile-deleted',
      pageType: 'standalone',
      PageComponent: () => <ProfileDeleted />,
      show: {
        needsLogout: true,
      }
    },
    {
      path: '/debug',
      pageType: 'modal',
      PageComponent: () => <Debug />,
      show: {
        needsLogin: false
      }
    },
    {
      path: '/info',
      pageType: 'modal',
      PageComponent: () => <Info />,
      show: {
        needsLogin: false
      }
    },
    {
      path: '/wb-measurement',
      pageType: 'modal',
      PageComponent: () => <WellBeingMeasurement />,
      show: {
        needsLogin: false
      }
    },
    {
      path: '/nonexistent-instance',
      pageType: 'standalone',
      pageWrapper: { compensateHeader: true },
      PageComponent: () => <NonexistentInstance />,
      show: {
        needsLogin: false,
      },
    },
    {
      path: '/maintenance',
      pageType: 'standalone',
      pageWrapper: { compensateHeader: true },
      PageComponent: () => <Maintenance />,
      show: {
        needsLogin: false,
      },
    },


    // SHARING
    {
      path: '/share/:token',
      pageType: 'standalone',
      PageComponent: () => <ExternalProfile />,
      show: {
        needsLogin: false,
        forFlavor: 'bluquist'
      }
    },

    // MAIN NAV ROUTES
    {
      path: '/',
      PageComponent: () => <Overview />,
      show: {
        forFlavor: ['bluquist', 'balancedYou']
      },
      pageWrapper: {
        autoMargins: false
      },
      inMainNav: {
        forceIndex: 0,
        labelKey: 'main_navigation_item_1'
      }
    },
    {
      path: '/my-profile',
      PageComponent: (props) => <MyProfile fullWidth={props.fullWidth} />,
      show: {
        forFlavor: 'bluquist'
      },
      inMainNav: {
        labelKey: 'main_navigation_item_6'
      }
    },
    {
      path: '/settings',
      PageComponent: () => <Settings />,
      pageWrapper: {
        background: 'white'
      },
      inMainNav: {
        labelKey: 'main_navigation_item_8'
      },
      tools: {
        browserTitleKey: 'main_navigation_item_8'
      },
      show: {
        forFlavor: ['bluquist', 'balancedYou']
      },
      subRoutes: [
        {
          path: '/settings',
          inSubNav: {
            labelKey: 'settings_subnav_general'
          },
        },
        {
          path: '/settings/users',
          PageComponent: () => <UserManagement />,
          show: {
            forUserGroup: 'admin'
          },
          inSubNav: {
            labelKey: 'settings_subnav_user_management'
          },
          tools: {
            browserTitleKey: 'main_navigation_item_8'
          }
        },
        {
          path: '/settings/user-groups/basic',
          PageComponent: () => <BasicUserGroup />,
          show: {
            forUserGroup: ['leader', 'admin'],
            forFlavor: 'all',
          },
          asBreadcrumb: {
            labelKey: 'settings_subnav_usergroups_management'
          },
          tools: {
            browserTitleKey: 'main_navigation_item_8'
          },
          subRoutes: [
            {
              path: '/settings/user-groups/basic',
              inSubNav: {
                labelKey: 'settings_subnav_usergroups_basic'
              },
            },
            {
              path: '/settings/user-groups/candidate',
              PageComponent: () => <CandidateUserGroup />,
              show: {
                forFlavor: 'bluquist'
              },
              inSubNav: {
                labelKey: 'candidates_nav'
              },
              tools: {
                browserTitleKey: 'main_navigation_item_8'
              }
            },
            {
              path: '/settings/user-groups/leader',
              PageComponent: () => <LeaderUserGroup />,
              show: {
                forFlavor: 'bluquist'
              },
              inSubNav: {
                labelKey: 'settings_subnav_usergroups_leader'
              },
              tools: {
                browserTitleKey: 'main_navigation_item_8'
              }
            },
            {
              path: '/settings/user-groups/admin',
              PageComponent: () => <AdminUserGroup />,
              inSubNav: {
                labelKey: 'settings_subnav_usergroups_admin'
              },
              tools: {
                browserTitleKey: 'main_navigation_item_8'
              }
            },
          ]
        },
        {
          path: '/settings/organisation',
          PageComponent: () => <OrganisationSettings />,
          show: {
            forUserGroup: 'admin'
          },
          inSubNav: {
            labelKey: 'settings_subnav_organisation'
          },
          tools: {
            browserTitleKey: 'main_navigation_item_8'
          }
        },
        {
          path: '/settings/users/:userId',
          PageComponent: () => <UserIndividualSettings />,
          show: {
            forUserGroup: 'admin'
          },
          asBreadcrumb: {},
          tools: {
            browserTitleKey: 'main_navigation_item_8'
          },
          subRoutes: []
        }
      ]
    },

    // SUPPORT
    {
      path: '/support',
      pageType: 'modal',
      PageComponent: () => <Support />,
      show: {
        forFlavor: ['bluquist', 'balancedYou']
      },
      inMainNav: {
        labelKey: 'main_navigation_item_sec_1',
        linkType: 'small'
      },
    },
    {
      path: '/legal/:routerSubPage',
      pageType: 'modal',
      pageWrapper: {
        instantBackground: true // prevent flash of MainLayout when
        // navigating from standanlone (e.g. login) to modal page (e.g. legal)
      },
      PageComponent: () => <Legal />,
      show: {
        needsLogin: false
      },
      inMainNav: {
        labelKey: 'main_navigation_item_sec_2',
        linkType: 'small',
        mainNavPath: '/legal/data-protection-privacy'
      },
    },


    // FRAMEWORK ROUTES: LOGIN REQUIRED
    {
      path: '/complete-profile',
      pageType: 'modal',
      PageComponent: () => <CompleteProfile />,
      show: {
        forFlavor: ['bluquist', 'balancedYou'],
        forUserGroup: ['employee', 'leader', 'admin'],
        needsProfileCompleted: false,
      },

    },
    {
      path: '/complete-profile',
      pageType: 'modal',
      PageComponent: () => <CandidateCompleteProfile />,
      show: {
        forFlavor: ['bluquist', 'balancedYou'],
        forUserGroup: 'candidate',
        needsProfileCompleted: false,
      }
    },
    {
      path: '/survey',
      pageType: 'modal',
      PageComponent: () => <Survey />,
      tools: {
        browserTitleKey: 'survey_page_header_title'
      }
    },
  ]

};
