import { useState, useEffect, useRef, useCallback } from 'react';
import * as api from 'api';
import axios from 'axios';

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay]
  );

  return debouncedValue;
};


// https://usehooks.com/useWindowSize/
export const useWindowWidth = () => {
  // Initialize state with undefined width so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [ windowWidth, setWindowWidth ] = useState(undefined);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // orientationchange: iOS not resizing properly on iPad in Home Screen App
    // possible related: https://hackernoon.com/onresize-event-broken-in-mobile-safari-d8469027bf4d
    window.addEventListener('orientationchange', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () =>{
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount

  return windowWidth;
};

// https://usehooks.com/useWindowSize/
export const useWindowHeight = () => {
  // Initialize state with undefined width so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [ windowHeight, setWindowHeight ] = useState(undefined);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowHeight(window.innerHeight);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // orientationchange: iOS not resizing properly on iPad in Home Screen App
    // possible related: https://hackernoon.com/onresize-event-broken-in-mobile-safari-d8469027bf4d
    window.addEventListener('orientationchange', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () =>{
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount

  return windowHeight;
};


export const breakpoints = {
  Xs: { bpName: 'Xs', bpWidth: 759, isXs: true, gridCellCount: 4 },
  S: { bpName: 'S', bpWidth: 959, isS: true, gridCellCount: 8 },
  M: { bpName: 'M', bpWidth: 1279, isM: true, gridCellCount: 12 },
  L: { bpName: 'L', bpWidth: 1439, isL: true, gridCellCount: 12 },
  Xl: { bpName: 'Xl', bpWidth: Infinity, isXl: true, gridCellCount: 12 },
};


export const useBreakpoint = () => {

  const breakpointsArray = [
    breakpoints.Xs,
    breakpoints.S,
    breakpoints.M,
    breakpoints.L,
    breakpoints.Xl,
  ];

  // set largest breakpoint initially
  const [ breakpoint, setBreakpoint ] = useState({
    ...breakpointsArray[breakpointsArray.length - 1],
    bps: breakpoints
  });


  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      const newBreakpoint = breakpointsArray.find((breakpoint) => {
        return windowWidth <= breakpoint.bpWidth;
      });

      if (newBreakpoint.bpName !== breakpoint.bpName) {
        // console.log(newBreakpoint);
        setBreakpoint({
          ...newBreakpoint,
          scalingUp: newBreakpoint.bpWidth > breakpoint.bpWidth,
          scalingDown: newBreakpoint.bpWidth < breakpoint.bpWidth,
          prevBp: { ...breakpoints[breakpoint.bpName] },
          bps: breakpoints
        });
      }
    };

    // determine initial resize
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return breakpoint;
};



// resets a value to its initialValue after [delay]
export const useAutoReset = (initialValue, delay = 150) => {

  // set currentValue to initialValue
  const [ currentValue, setCurrentValue ] = useState(initialValue);
  // console.log('set initally', value);

  useEffect(() => {

    // set timeout when currentValue changes
    if (currentValue !== initialValue) {
      // console.log('set dynamically', currentValue);

      // currentValue is reset to initialValue after timeout
      const handler = setTimeout(() => {
        // console.log('reset to', initialValue);
        setCurrentValue(initialValue);
      }, delay);

      // clearTimeout when useEffect is called again
      return () => {
        clearTimeout(handler);
      };
    }
  }, [currentValue, initialValue, delay]);

  // expose the currentValue and its setter function
  return [
    currentValue,
    setCurrentValue
  ];
};

export const useThrottle = (value, time = 150) => {
  const [ throttledValue, setThrottledValue ] = useState(value);
  const [ ready, setReady ] = useState(true);

  useEffect(() => {
    if (ready) {
      setThrottledValue(value);
      setReady(false);
      setTimeout(() => {
        setReady(true);
      }, time);
    }

  }, [value, time, ready]);

  return throttledValue;
};


/**
 * For text fields triggering several searches in rapid succession (eg. slow typing).
 * It prevents storing data returned in previous fetches by canceling pending calls.
 *
 * @param {String} entrypoint The entrypoint to call
 * @param {Function} [mapResults] Callback to map results automatically before returning them
 * @param {Object} [extraParams] Other call parameters (aside from 'search')
 * @returns {[Boolean, Array, Function]} A tuple of 3 items containing (in order):
 *    - the loading state for the call
 *    - the fetch results (processed by mapResults cb)
 *    - setState function for search string
 */
export const useLatestCall = (
  entrypoint,
  mapResults = res => res,
  extraParams = {},
) => {
  const [ searchString, setSearchString ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ results, setResults ] = useState();
  const mapResultsCb = useCallback(mapResults, []);
  // Stringify extra search params to ease useEffect comparison
  const jsonExtraParams = JSON.stringify(extraParams);
  // Stores the cancel token referring to the previous call
  // https://github.com/axios/axios#canceltoken-deprecated
  const source = useRef(null);

  useEffect(() => {
    // Cancel previous call if still pending
    if (source.current) {
      source.current.cancel();
    }

    // Reset state for empty strings (no search performed)
    if (!searchString) {
      setResults();
      setLoading(false);
      return;
    }

    setLoading(true);

    // Set cancel token for current call
    source.current = axios.CancelToken.source();

    // Fetch data
    api.get(
      entrypoint,
      { ...JSON.parse(jsonExtraParams), search: searchString },
      undefined,
      { cancelToken: source.current.token },
    )
    .then(({ problem, ok, data }) => {
      // 'problem' is set to 'CANCEL_ERROR' for calls aborted via CancelToken.
      // In any other case, the search has been completed, successfully or not
      // (that is, it was not interrupted by subsequent searches).
      if (problem !== 'CANCEL_ERROR') {
        source.current = null;
        setLoading(false);
      }

      if (ok) {
        setResults(mapResultsCb(data));
      }
    })
    .catch(() => {
      source.current = null;
      setLoading(false);
    });
  }, [ entrypoint, mapResultsCb, jsonExtraParams, searchString ]);

  return [ loading, results, setSearchString ];
}
