import * as fromActionTypes from 'features/+admin/store/actionTypes/instanceOverview';

const initialState = {
  industries: null,
  instance: null,
  instanceError: null,
  instanceRegistrationDomains: [],
  instanceRegistrationDomainsError: null,
  instanceFeatures: [],
  instanceFeaturesError: null,
  instances: null,
  noDataAtAll: false,
  instancesTotalCount: 0,
  customerInstances: null,
  customerInstancesTotalCount: 0,
  demoInstances: null,
  demoInstancesTotalCount: 0,
  createInstanceProcessing: false,
  createInstanceSuccess: false,
  createInstanceError: null,
  userStatistics: null,
  instanceProcessing: false,
  instanceUpdateSuccess: false,

  rebuildCacheError: null,
  rebuildCacheSuccess: false,

  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.GET_INDUSTRIES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_INDUSTRIES_FULFILLED: {
      const { industries } = action.payload;

      return {
        ...state,
        industries,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_INDUSTRIES_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.INIT_CREATE_INSTANCE: {
      return {
        ...state,
        createInstanceProcessing: false,
        createInstanceSuccess: false,
        createInstanceError: null
      };
    }
    case fromActionTypes.CREATE_INSTANCE: {
      return {
        ...state,
        createInstanceProcessing: true
      };
    }
    case fromActionTypes.CREATE_INSTANCE_FULFILLED: {
      return {
        ...state,
        createInstanceProcessing: false,
        createInstanceSuccess: true,
        createInstanceError: null
      };
    }
    case fromActionTypes.CREATE_INSTANCE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        createInstanceProcessing: false,
        createInstanceSuccess: false,
        createInstanceError: error
      };
    }
    case fromActionTypes.INIT_GET_INSTANCES: {
      return {
        ...state,
        customerInstances: null,
        customerInstancesTotalCount: 0,
        demoInstances: null,
        demoInstancesTotalCount: 0,
        loading: false
      };
    }
    case fromActionTypes.GET_INSTANCES: {
      const noDataAtAll = false;
      return {
        ...state,
        noDataAtAll,
        loading: true
      };
    }
    case fromActionTypes.GET_INSTANCES_FULFILLED: {
      const { instances, instanceType, infiniteLoad, totalCount } = action.payload;

      if (instanceType === 'customer') {
        let newInstances = instances;

        if (infiniteLoad &&
          (state.customerInstances && Array.isArray(state.customerInstances)) &&
          (instances && Array.isArray(instances))
        ) {
          newInstances = [...state.customerInstances, ...instances]
        }

        return {
          ...state,
          customerInstances: newInstances,
          customerInstancesTotalCount: totalCount,
          loading: false,
          error: null
        };
      } else {
        let newInstances = instances;

        if (infiniteLoad &&
          (state.demoInstances && Array.isArray(state.demoInstances)) &&
          (instances && Array.isArray(instances))
        ) {
          newInstances = [...state.demoInstances, ...instances]
        }

        return {
          ...state,
          demoInstances: newInstances,
          demoInstancesTotalCount: totalCount,
          loading: false,
          error: null
        };
      }
    }
    case fromActionTypes.GET_INSTANCES_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.INIT_GET_INSTANCE: {
      return {
        ...state,
        instance: null,
        loading: false,
        instanceProcessing: null,
        instanceUpdateSuccess: false,
        instanceError: null
      };
    }
    case fromActionTypes.GET_INSTANCE: {
      return {
        ...state,
        instanceProcessing: true
      };
    }
    case fromActionTypes.GET_INSTANCE_FULFILLED: {
      const { instance } = action.payload;
      return {
        ...state,
        instance,
        instanceProcessing: false,
        error: null
      };
    }
    case fromActionTypes.GET_INSTANCE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        instanceProcessing: false,
        error
      };
    }
    case fromActionTypes.INIT_GET_INSTANCE_REG_DOMAINS: {
      return {
        ...state,
        instanceRegistrationDomains: [],
        loading: false,
        instanceRegistrationDomainsError: null
      };
    }
    case fromActionTypes.GET_INSTANCE_REG_DOMAINS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_INSTANCE_REG_DOMAINS_FULFILLED: {
      const { registrationDomains } = action.payload;
      return {
        ...state,
        instanceRegistrationDomains: registrationDomains,
        loading: false,
        instanceRegistrationDomainsError: null
      };
    }
    case fromActionTypes.GET_INSTANCE_REG_DOMAINS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        instanceRegistrationDomainsError: error
      };
    }
    case fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS_FULFILLED: {
      return {
        ...state,
        loading: false,
        instanceRegistrationDomainsError: null
      };
    }
    case fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        instanceRegistrationDomainsError: error
      };
    }
    case fromActionTypes.GET_USER_STATISTICS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_USER_STATISTICS_FULFILLED: {
      const { statistics } = action.payload;
      return {
        ...state,
        loading: false,
        userStatistics: statistics,
        error: null
      };
    }
    case fromActionTypes.GET_USER_STATISTICS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.INIT_REBUILD_CACHE: {
      return {
        ...state,
        loading: false,
        rebuildCacheSuccess: false,
        rebuildCacheError: null
      };
    }
    case fromActionTypes.REBUILD_CACHE: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.REBUILD_CACHE_FULFILLED: {
      return {
        ...state,
        loading: false,
        rebuildCacheSuccess: true,
        rebuildCacheError: null
      };
    }
    case fromActionTypes.REBUILD_CACHE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        rebuildCacheSuccess: false,
        rebuildCacheError: error
      };
    }
    default: {
      return state;
    }
  }
};

