import * as actionType from 'store/actions';

const initialState = {
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_CAREER_LEVELS:
      return {
        ...state
      };
    case actionType.GET_CAREER_LEVELS_FULFILLED:
      return {
        ...state,
        ...action.payload
      };
    case actionType.GET_CAREER_LEVELS_REJECTED:
      return {
        ...state,
        error:action.payload
      };
    default:
      return state;
  }
};
