import React from 'react';
import styles from './Login.module.scss';

import classNames from 'classnames';

import PoweredBy from 'ui/basic/micro-ui/PoweredBy';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'store/actions';
import { selectIsCustomLogoActive } from 'store/selectors/configuration';

import LoginForm from '../../components/LoginForm';


const Login = () => {
  const dispatch = useDispatch();

  const error = useSelector(state => state.login && state.login.error);
  const isCustomLogoActive = useSelector(selectIsCustomLogoActive);

  const handleLogin = (email, password) => {
    dispatch(login({mail: email, password}));
  };

  return (
    <div className={styles.login}>
      <div className={classNames(styles.container)}>

        { isCustomLogoActive && (
          <div className={styles.poweredBy}>
            <PoweredBy />
          </div>
        )}

        <LoginForm
          error={error && error.error && error.error.errorMessage}
          onSubmit={handleLogin}
        />
      </div>
    </div>
  );
};

export default Login;
