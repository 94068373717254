import * as fromActionTypes from 'store/actionTypes/resetPassword';

export const initRequestResetPassword = () => {
  return {
    type: fromActionTypes.INIT_RESET_PASSWORD_REQUEST,
    payload: { }
  };
};

export const requestResetPassword = (mail, companyId) => {
  return {
    type: fromActionTypes.RESET_PASSWORD_REQUEST,
    payload: { mail, companyId }
  };
};

export const resetPasswordRequestFulFilled = () => {
  return {
    type: fromActionTypes.RESET_PASSWORD_REQUEST_FULFILLED
  };
};

export const resetPasswordRequestRejected = ({ error }) => {
  return {
    type: fromActionTypes.RESET_PASSWORD_REQUEST_REJECTED,
    payload: { error }
  };
};

export const resetPassword = (password, token) => {
  return {
    type: fromActionTypes.RESET_PASSWORD,
    payload: { password, token }
  };
};

export const resetPasswordFulFilled = () => {
  return {
    type: fromActionTypes.RESET_PASSWORD_FULFILLED
  };
};

export const resetPasswordRejected = ({ error }) => {
  return {
    type: fromActionTypes.RESET_PASSWORD_REJECTED,
    payload: { error }
  };
};
