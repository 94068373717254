import React, {useCallback, useEffect, useState} from 'react';
import styles from './ExternalProfile.module.scss';

import * as api from 'api';

import {useHistory} from 'react-router';

import { useTranslate } from 'utils/translator';
import {COMPANY_ID} from 'utils/configuration';

import UserAssessmentsResults from './UserAssessmentsResults/UserAssessmentsResults';
import CandidateExternalProfile from './CandidateExternalProfile';
import {Button, InputPassword} from 'ui/basic';

/**
 * all API requests triggered here use 'shareToken' instead of a basic token
 *
 * there are few requests for fetching assessments info which get triggered implicitly in results components (big5, key comp, leadership comp)
 * and where the request header gets generated in api/index.js
 *
 * for that case another piece of code is added in api/index.js: for external profile requests use always 'shareToken';
 */

const ExternalProfile = () => {
  const translate = useTranslate();
  const history = useHistory();

  const [token, setToken] = useState();
  const [linkExpired, setLinkExpired] = useState();

  const [password, setPassword] = useState();
  const [passwordProtected, setPasswordProtected] = useState();

  const [user, setUser] = useState();
  const [results, setResults] = useState();

  const [loginProcessing, setLoginProcessing] = useState();
  const [loginError, setLoginError] = useState();

  let shareToken;
  const split = history.location.pathname.split('/share/');
  if (split && split.length === 2) {
    shareToken = split[1];
  }

  const getUserInfo = useCallback((token) => {
    api.get('/core/user/info', {}, {Authorization: `Bearer ${token}`})
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          setUser({
            ...data,
            name: (data.firstName || '') + ' ' + (data.lastName || '')
          });
        } else {
          if (status === 404) {
            setLinkExpired(true);
          }

          setToken(null);
          if (localStorage.getItem(`shareToken${shareToken}`)) {
            localStorage.removeItem(`shareToken${shareToken}`);
          }
        }
      })
      .catch(error => console.error(error.message));
  }, [shareToken]);

  const getUserProfile = useCallback((token) => {
    api.get('/core/user/profile', {}, {Authorization: `Bearer ${token}`})
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          setResults(data.profile);
        } else {
          if (status === 404) {
            setLinkExpired(true);
          }

          setToken(null);
          if (localStorage.getItem(`shareToken${shareToken}`)) {
            localStorage.removeItem(`shareToken${shareToken}`);
          }
        }
      })
      .catch(error => console.error(error.message));
  }, [shareToken]);

  useEffect(() => {
    let externalProfileToken;
    if (shareToken) {
      externalProfileToken = localStorage.getItem(`shareToken${shareToken}`);
    }

    // if there is a token in local storage -> use this token for fetching the user info/profile
    if (externalProfileToken) {
      setToken(externalProfileToken);
      setPasswordProtected(false);

      getUserInfo(externalProfileToken);
      getUserProfile(externalProfileToken);
      return;
    }

    // some external links are not password protected.
    // for that case first try to login without a password. If it succeeds fetch user info and user profile
    api.post('/core/user/login', {
      shareToken: shareToken,
      company: COMPANY_ID || 'dummyinc'
    })
      .then(({ok, status, data}) => {
        if (status === 403 && data.error && data.error.errorCode === 1209) {
          setPasswordProtected(false);
          setLinkExpired(true);
          return;
        }

        if (ok && status === 200) {
          setPasswordProtected(false);
          setToken(data.token);
          localStorage.setItem(`shareToken${shareToken}`, data.token);

          getUserInfo(data.token);
          getUserProfile(data.token);
        } else {
          setPasswordProtected(true);
        }
      })
      .catch(error => {
        setLoginProcessing(false);
        console.error(error.message);
      })
  }, [shareToken, getUserInfo, getUserProfile]);

  if (passwordProtected === undefined) {
    return null;
  }

  return (
    <>
      {!token &&
      <div className={styles.externalProfile}>
        <div className={styles.loginContainer}>
          {linkExpired &&
          <>
            <div className={styles.mTitle}>
              {translate('share_profile_link_expired_title') || 'Der Link ist leider abgelaufen.'}
            </div>
            <div className={styles.copy}>
              {translate('share_profile_link_expired_copy') ||
              'Bitte wende dich an die Person, die den Link mit dir geteilt hat, um einen neuen Link zu erhalten.'
              }
            </div>
            <Button size={'L'} onClick={() => history.push('/')}>
              {translate('share_profile_link_expired_btn') || 'Zur Anmeldung'}
            </Button>
          </>
          }

          {!linkExpired &&
          <>
            <div className={styles.mTitle}>
              {translate('') || 'Willkommen bei bluquist'}
            </div>
            <div className={styles.copyStrong}>
              {translate('share_profile_login_copy') || 'Bitte gib das Passwort ein um den angeforderten Link aufzurufen.'}
            </div>

            <form onSubmit={event => {
              event.preventDefault();

              setLoginProcessing(true);

              api.post('/core/user/login', {
                shareToken: shareToken,
                company: COMPANY_ID || 'dummyinc',
                password
              })
                .then(({ok, status, data}) => {
                  setLoginProcessing(false);

                  if (status === 403 && data.error && data.error.errorCode === 1209) {
                    setLinkExpired(true);
                    return;
                  }

                  if (ok && status === 200) {
                    setToken(data.token);
                    localStorage.setItem(`shareToken${shareToken}`, data.token);

                    getUserInfo(data.token);
                    getUserProfile(data.token);
                  } else {
                    setLoginError(data.error.errorMessage);
                  }
                })
                .catch(error => {
                  setLoginProcessing(false);
                  console.error(error.message);
                })
            }}>
              <div className={styles.password}>
                <InputPassword
                  placeHolder={translate('share_profile_login_password_placeholder') || 'Passwort eingeben'}
                  error={loginError}
                  onInputChanged={(value, correct) => setPassword(value)}
                />
              </div>

              <Button
                size={'L'}
                disabled={!password || loginProcessing}
                type={'submit'}
              >{translate('continue_lbl') || 'Weiter'}</Button>
            </form>
          </>
          }

        </div>
      </div>
      }

      {/*if user is not a candidate*/}
      {(token && user && (!user.candidateData || Object.keys(user.candidateData).length === 0)) &&
        <div className={styles.employeeExternalProfile}>
          <UserAssessmentsResults
            user={user}
            token={token}
            results={results && results.filter(resultItem => resultItem && resultItem.result)}
          />
        </div>
      }

      {/*if user is a candidate*/}
      {(token && user && (user.candidateData && Object.keys(user.candidateData).length > 0)) &&
      <CandidateExternalProfile
        id={user.id}
        token={token}
        results={results && results.filter(resultItem => resultItem && resultItem.result)}
      />
      }
    </>
  )
};

export default ExternalProfile;
