import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import Big5AssessmentEnd from '../intermissions/Big5AssessmentEnd';
import { Breathe, breatheText } from '../intermissions/Breathe';

// UTILS
import { translate } from 'utils/translator/translator';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

// CONFIG

// OTHER COMPONENTS

export const errorAddPagesConfig = {
  id: 'big5',
  title: 'Big 5',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 30 * 1000,
  storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  modalHelpContentTranslationKey: 'big5_assessment_help_info_content',
  loadingPage: {
    // INTRO with disabled next button
    render: (state) => (
      <AssessmentIntro
        assessmentType={ASSESSMENT_TYPES.BIG5}
      />
    )
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType={ASSESSMENT_TYPES.BIG5}
          onClickNext={() => next()}
        />
      )
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.BIG5}
          onClickNext={() => next()}
        />
      )
    },
    // BREATHE
    {
      insertAtIndex: 98, // interstitial is shown at 80% completion
      hideOnBackNavigation: true,
      render: (next, prev, state) => (
        <Breathe
          text={translate('assessment_interstitial') || breatheText}
          onSkip={next}
        />
      )
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => {
        const { finishable, handleFinish, errorEnd } = extras;
        return (
          <Big5AssessmentEnd
            finishable={finishable}
            handleFinish={handleFinish}
            errorEnd={errorEnd}
          />
        )

      }
    }
  ]
};
