// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './QuestionPicture.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import UploadImage  from 'ui/basic/forms/UploadImage';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionPicture
const QuestionPicture = (props) => {
  // PROPS
  const {
    // answer = {},
    // onAnswer = () => {}
    extras = { setLoading: () => {}, setTitle: () => {} }
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: QuestionPicture
  return (
    <div className={classNames(styles.questionPicture)}>
      <UploadImage
        text={translate('edp_link_edit_profile_picture') || 'Profilbild hinzufügen'}
        handleWithoutStore={true}
        handleWithoutStoreSetLoading={(loading) => extras.setLoading(loading)}
      />
    </div>
  );
};

export default QuestionPicture;
