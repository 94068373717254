export const LOADING = 'LOADING';
export const LOADING_FULFILLED = 'LOADING_FULFILLED';

export const loading=()=> {
  return {
    type: LOADING,
    payload: true
  }
};

export const loadingFulFilled=()=> {
  return {
    type: LOADING_FULFILLED,
    payload: false
  }
};
