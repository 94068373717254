import sanitize from 'insane';

import { LANGUAGES as LANG } from 'utils/configuration/const/languages';

// PROCESS ENV vars
export const REACT_APP_AUTHORIZATION_HEADER = process.env.REACT_APP_AUTHORIZATION_HEADER;


// DYNAMIC ENV VARS
// via Post Build Configuration and .env file
export const getPostBuildConfig = () => {
  return JSON.parse(window.__PRELOADED_STATE__ || '{}', true, 2);
}

// getDynamicEnvVar
// tries to retrieve the env var dynamically from window.__PRELOADED_STATE__
// otherwise falls back to retrieving it from process.env
export const getDynamicEnvVar = (identifier, fallbackValue) => {
  const config = getPostBuildConfig();
  let value = process.env[identifier];
  if (config && config[identifier]) {
    value = config[identifier];
  }
  const sanitizedValue = sanitize(value);
  return sanitizedValue || fallbackValue || null;
};

export const REACT_APP_API_BASE_URL = getDynamicEnvVar('REACT_APP_API_BASE_URL');

// ADMIN FRONTEND
export const IS_ADMIN_FRONTEND = getDynamicEnvVar('REACT_APP_IS_ADMIN_FRONTEND');
// export const IS_ADMIN_FRONTEND = 'ENABLE';
export const ADMIN_FRONTEND_LINKOUT = getDynamicEnvVar('REACT_APP_ADMIN_FRONTEND_LINKOUT');

export const CONFIGURATION_OPTIONS = {
  BLUQUIST: 'BLUQUIST',
  BALANCED_YOU: 'BALANCED_YOU'
};

export const CONFIGURATION_SUPPORTED_LANGUAGES = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: [LANG.DE, LANG.EN],
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: [LANG.DE, LANG.EN],
  [CONFIGURATION_OPTIONS.COACH_HUB]: [LANG.DE, LANG.EN, LANG.FR],
};

export const CONFIGURATION = getDynamicEnvVar('REACT_APP_CONFIGURATION', CONFIGURATION_OPTIONS.BLUQUIST);
// export const CONFIGURATION = CONFIGURATION_OPTIONS.BALANCED_YOU;

export const SUB_CONFIGURATION_OPTIONS = {
  MINDFULNESS_JOURNEY: 'MINDFULNESS_JOURNEY',
};

export const SUB_CONFIGURATION = getDynamicEnvVar('REACT_APP_SUB_CONFIGURATION');
// export const SUB_CONFIGURATION = SUB_CONFIGURATION_OPTIONS.MINDFULNESS_JOURNEY;

export const COMPANY_ID_FALLBACK = getDynamicEnvVar('REACT_APP_COMPANY_ID_FALLBACK');
export const SUBDOMAIN_FOR_DEFAULT_COMPANY_ID = getDynamicEnvVar('REACT_APP_SUBDOMAIN_FOR_DEFAULT_COMPANY_ID');

// tracking
export const TRACKING_ENABLE = getDynamicEnvVar('REACT_APP_TRACKING_ENABLE');
export const TRACKING_SITE_ID = getDynamicEnvVar('REACT_APP_TRACKING_SITE_ID');

// translation disable
export const ALLOW_TRANSLATION_DISABLE = getDynamicEnvVar('REACT_APP_ALLOW_TRANSLATION_DISABLE');

// maintenance
export const IS_MAINTENANCE_MODE = getDynamicEnvVar('REACT_APP_IS_MAINTENANCE_MODE') === 'ENABLE';

// DOMAIN VARS
// return COMPANY ID, retrieved from domain, it's the most left subdomain that is not www or undefined when it matches SUBDOMAIN_FOR_DEFAULT_COMPANY_ID
export const getCompanyId = (hostname, companyIdForDefault, companyIdFallback) => {
  let companyId; // default to undefined

  const hostNameCleaned = hostname.replace('www.', '');
  if (hostNameCleaned.includes('.')) {  // avoid using localhost'
    companyId = hostNameCleaned.split('.')[0];
  }

  if (!isNaN(companyId)) { // prevent an ip being interpreted as compnayId
    companyId = undefined;
  }

  if(companyIdForDefault && companyId === companyIdForDefault) {
    companyId = undefined;
  }

  if (companyId === undefined && companyIdFallback) {
    companyId = companyIdFallback;
  }
  return companyId;
};

export const COMPANY_ID = getCompanyId(window.location.hostname, SUBDOMAIN_FOR_DEFAULT_COMPANY_ID, COMPANY_ID_FALLBACK);

