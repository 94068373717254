import * as fromActionTypes from 'store/actionTypes/organization';

const initialState = {
  score: null,
  loading: false,
  results: null,
  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.GET_MIND_JOURNEY_SCORE: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_MIND_JOURNEY_SCORE_FULFILLED: {
      const { score } = action.payload;
      return {
        ...state,
        score,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_MIND_JOURNEY_SCORE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.GET_STATISTIC: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_STATISTIC_FULFILLED: {
      const { statistic } = action.payload;
      return {
        ...state,
        statistic,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_STATISTIC_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.GET_MIND_JOURNEY_RESULTS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_MIND_JOURNEY_RESULTS_FULFILLED: {
      const { results } = action.payload;
      return {
        ...state,
        results ,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_MIND_JOURNEY_RESULTS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    default: {
      return state;
    }
  }
};
