import React, {lazy} from 'react';

const BalancedYouMain = lazy(() => import('./pages/BalancedYouMain'  /* webpackChunkName: "balancedYou" */));

export const routesConfigBalancedYou = {
  show: {
    forFlavor: 'balancedYou',
    forUserGroup: 'all',
  },
  routes: [
    {
      path: '/my-profile',
      PageComponent: () => <BalancedYouMain />,
      LazyPageComponent: 'MyBalancedYouProfile',
      inMainNav: {
        labelKey: 'main_navigation_item_6'
      }
    },
    {
      path: '/my-organization',
      PageComponent: () => <BalancedYouMain />,
      LazyPageComponent: 'MyOrganization',
      show: {
        forUserGroup: 'admin'
      },
      inMainNav: {
        labelKey: 'main_navigation_item_9'
      }
    },
    {
      path: '/assessments/balanced-you',
      pageType: 'modal',
      PageComponent: () => <BalancedYouMain />,
      LazyPageComponent: 'BalancedYouAssessmentPage',
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
    {
      path: '/user/invite',
      pageType: 'modal',
      PageComponent: () => <BalancedYouMain />,
      LazyPageComponent: 'UserInvitation',
      show: {
        forCapabilities: { leader: [ 'employeesInvite', 'candidatesInvite' ] }
      }
    }
  ]
};
