import React, {useState} from 'react';
import styles from './ResetPasswordForm.module.scss';
import { matchPasswords } from 'utils/validator';

import { useTranslate } from 'utils/translator';

import { Button, InputPassword, Link } from 'ui/basic';


const ResetPasswordForm = ({ error, onResetPassword }) => {
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const translate = useTranslate();

  const noMatchHint = password &&
    (translate('signup_form_password_error_hint_notmatching') ||
      'Eingegebene Passworte sind nicht ähnlich');

  const submitButtonDisabled =
    !password ||
    !passwordIsValid ||
    !matchPasswords(password, confirmedPassword);

  const handleUserInputReady = (element) => {
    element.focus();
  };

  return (
    <div className={styles.resetPasswordForm}>
      <div className={styles.title}>
        {translate('resetpwset_title') || 'Wähle dein neues Passwort.'}
      </div>
      <div className={styles.subTitle}>
        {translate('resetpwset_copy') ||
          'Gib ein neues Passwort ein und bestätige die Eingabe.'}
      </div>

      <form onSubmit={(event) => {
        event.preventDefault();
        onResetPassword(password);
      }}>
        <InputPassword
          placeHolder={translate('resetpwset_form_password_label') || 'Passwort'}
          value={password}
          showCriteria={true}
          onInitialized={handleUserInputReady}
          onInputChanged={(value, correct) => {
            setPasswordIsValid(correct);
            setPassword(value);
          }}
        />
        <InputPassword
          placeHolder={
            translate('resetpwset_form_password_repeat_label') ||
            'Passwort wiederholen'
          }
          value={confirmedPassword}
          className={styles.passwordInput}
          hint={matchPasswords(password, confirmedPassword) || noMatchHint}
          error={error}
          onChange={(currentInput, value) => setConfirmedPassword(value)}
          onInitialized={handleUserInputReady}
        />
        <div className={styles.bottom}>
          <Link to={'/'}>{'Login'}</Link>
          <Button
            disabled={submitButtonDisabled}
            type={'submit'}
          >
            {translate('cp_button_complete') || 'Fertigstellen'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
