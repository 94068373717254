export const getYears = () => {
  const years = [];
  const yearToday = new Date().getFullYear();

  for (let i=1970; i<=yearToday; i++) {
    years.push(i);
  }

  return years;
};
