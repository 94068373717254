import React, {useEffect, useState} from 'react';
import styles from './LoginForm.module.scss';

// 3RD PARTY
import {useHistory} from 'react-router';

// OTHER COMPONENTS
import {Button, InputNext, InputPassword, Link} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { validateEmail } from 'utils/validator';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';


// CONFIGURATION & DATA
const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_'
};

const EMAIL_WRONG_FORMAT_ERROR = 'login_form_email_error_hint_format';

const LoginForm = (props) => {
  const { error, onSubmit } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState('');
  const [inputError, setInputError] = useState();

  useEffect(() => {
    let email = localStorage.getItem('mail');

    if (history.location.search) {
      email = history.location.search.split('?email=')[1];
    }

    setEmail(email || '')
  }, [history.location.search]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    if (!trimmedEmail || !trimmedPassword) {
      return;
    }

    if (!validateEmail(trimmedEmail)) {
      setInputError(translate(EMAIL_WRONG_FORMAT_ERROR));
    } else {
      if (onSubmit) {
        onSubmit(trimmedEmail, trimmedPassword);
      }
    }
  };

  const handleInputPassword = (password) => {
    setPassword(password);
  };

  const title = translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}login_title`);
  const subTitle = translate('login_copy');
  const emailLabel = translate('login_form_email_label');
  const passwordLabel = translate('login_form_password_label');
  const buttonCaption = translate('login_button_login');
  const linkResetPassword = translate('login_link_reset_password');
  const linkCreateAccount = translate('login_link_create_account');

  return (
    <div className={styles.loginForm}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>

      <form onSubmit={handleSubmit}>
        <div className={styles.inputs}>
          <InputNext
            label={emailLabel}
            value={email}
            autofocus
            errorMessage={inputError || error}
            onChange={value => {
              setInputError(null);
              setEmail(value);
            }}
          />

          <InputPassword
            type={'password'}
            placeHolder={passwordLabel}
            className={styles.passwordInput}
            name="password"
            value={password}
            onInputChanged={(password) =>
              handleInputPassword(password)
            }
          />
        </div>
        <div className={styles.bottom}>
          <div className={styles.links}>
            <Link to={'/recovery-password'}>{linkResetPassword}</Link>
            <Link to={'/new-account'}>{linkCreateAccount}</Link>
          </div>

          <Button disabled={!email || !password} type={'submit'}>
            {buttonCaption}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
