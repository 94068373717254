// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useReducer, useEffect, useCallback } from 'react';
import styles from './AssessmentNext.module.scss';

// TRANSLATIONS
import { getTranslationIds } from './AssessmentNext.translations';

// COMPONENT REDUCER & LOGIC
import {
  init,
  reducer,
  initialState,
} from './AssessmentNext.reducer';
import {
  validateQuestions,
  getQuestionRenderInfo,
  copyAssessmentConfig,
  createStateWithPages,
  storageController
} from './AssessmentNext.logic';

// ASSETS
import { ReactComponent as ArrowUp } from 'assets/icons/icn_arrow_up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/icn_arrow_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentHeader } from './components/AssessmentHeader';
import { ProgressBar, Modal, BluCSSTransition, Toast, Button } from 'ui/basic';


// UTILS
import { eventBus } from 'architecture/eventBus';
import { useTranslate } from 'utils/translator';
import { msToNumber } from 'utils/styleTools';
import { markdown } from 'utils/textTools';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';

// STORE

// CONFIG & DATA
import { GLOBAL_CONFIG } from './AssessmentNext.config';

const CONFIG = {
  progressDelayFactor: 0.6,
  welcomeBackDelay: 450,
  inactivityTimeoutWarningFactor: 2/3,
};


// COMPONENT: AssessmentNext
const AssessmentNext = (props) => {

  // PROPS
  const {
    // mandatory props
    type, // assessment type
    userId, // for persistence identification
    configOverride, // override of assessmentConfig, mandatory but can be empty object
    questions, // when unset shows loading page
    showCopyright,

    onAnswer = () => {},
    onCancel = () => {},
    onAllAnswers = () => {},
    onFinish = () => {},
    // onError = () => {},
  } = props;

  const translate = useTranslate();

  // PROPS UPDATES, INITIALIZATION
  // LOADING
  const [ loading, setLoading ] = useState(false);
  const [ loadingPage, setLoadingPage ] = useState('');

  // error handling via eventBus
  const setErrorFromBus = (event) => {
    const message = typeof(event.detail?.message) === 'string'
      ? event.detail.message
      : JSON.stringify(event.detail, null, 2) || 'General Bus Error';
    setErrorMessage(message);
  };
  useEffect(() => {
    eventBus.addListener('assessmentNext.error', setErrorFromBus);
    return () => {
      eventBus.removeListener('assessmentNext.error', setErrorFromBus);
    }
  }, [])

  // modal welcome back
  const [ modalWelcomeBackShow, setModalWelcomeBackShow ] = useState();
  const [ modalWelcomeBackShown, setModalWelcomeBackShown ] = useState();

  useEffect(() => {
    // show loading bar and possible loading page until everything is available
    if (!type || !userId || !questions || !configOverride) {
      setLoading(true);
      // show loading page if available and if it hasn't been set yet
      if (type && !loadingPage) {
        const assessmentConfig = copyAssessmentConfig(type);
        if (assessmentConfig) {
          const { loadingPage: configLoadingPage } = assessmentConfig;
          if (configLoadingPage && typeof configLoadingPage.render === 'function') {
            // call render function of loading page and pass in config
            setLoadingPage(configLoadingPage.render(assessmentConfig));
          }
        }
      }

      return;
    }
    setLoading(false);
    setLoadingPage(false);

    // wait with initialisation until loadingPage and loading is over
    if (loadingPage || loading) {
      return;
    }

    // validate questions: can all questions be rendered?
    const [ questionsValid, error ] = validateQuestions(questions);
    if (!questionsValid) {
      setErrorMessage((
        <>
          Empty Questions Array or Array containing faulty question(s):
          <br/>
          { JSON.stringify(error, null, 2) }
        </>
      ));
      return;
    }

    // set initialState based on storageState or config
    let initialState;
    let assessmentConfig = copyAssessmentConfig(type);
    // validate assessmentConfig
    if (assessmentConfig.error) {
      setErrorMessage((
        <>
          Error with assessmentConfig:
          <br/>
          { assessmentConfig.error }
        </>
      ));
      return;
    }

    // configOverride
    assessmentConfig = Object.assign(assessmentConfig, configOverride);

    // continue from storage
    storageController.init(type, userId);

    if (!assessmentConfig.ignoreShortCache) {
      initialState = storageController.loadValidState(assessmentConfig, questions);
    }

    // show welcome back modal, if assessment was already started previously and if the assessment supports multi session
    let modalWelcomeBackTimer;
    if (initialState && !modalWelcomeBackShown) {
      modalWelcomeBackTimer = setTimeout(() => {
        setModalWelcomeBackShow(true);
      }, CONFIG.welcomeBackDelay);
    }

    // initialise from props
    if (!initialState) {
      const stateWithPages = createStateWithPages(assessmentConfig, questions, assessmentConfig.intermissions);
      initialState = stateWithPages;
    }

    // userId
    initialState = Object.assign(initialState, { userId });


    dispatchLocal({ type: 'reset' });
    dispatchLocal({ type: 'override', payload: initialState });
    dispatchLocal({
      type: 'start',
      payload: Date.now()
    });
    // console.log('props updated', type, assessmentConfig, questions);

    // continue from backend
    if (initialState.progress > 0 && initialState.prevAnswers && initialState.prevAnswers.length > 0 &&
      assessmentConfig.canContinueLater === true // prevent balanced you from interpreting previous answers
      // upon retaking assessment
    ) {
      // show welcome back modal
      if (!modalWelcomeBackShown) {
        modalWelcomeBackTimer = setTimeout(() => {
          setModalWelcomeBackShow(true);
        }, CONFIG.welcomeBackDelay);
      }

      dispatchLocal({
        type: 'overrideAnswers',
        payload: { prevAnswers: initialState.prevAnswers }
      });
    }

    // cleanup when there's a new call
    // so reducer persistence won't save new states with old keys
    return () => {
      if (storageController.isInitialised()) {
        storageController.reset();
      }
      clearTimeout(modalWelcomeBackTimer);
    }

  }, [type, userId, questions, configOverride, loadingPage, loading, modalWelcomeBackShown]);


  // REDUCER STATE
  const [ state, dispatchLocal ] = useReducer(reducer, initialState, init);



  // REDUCER STATE PERSISTENCE
  useEffect(() => {
    // storageController.saveState(state);
    const saved = storageController.saveState(state);
    console.log(`${saved ? 'saved' : 'local'} state`, state);
  }, [state]);


  // STATE: ON ALL ANSWERS
  const [ finishable, setFinishable ] = useState(false);
  const [ loadingDuring, setLoadingDuring ] = useState(false);
  const [ errorEnd, setErrorEnd ] = useState();

  const handleEnd = useCallback(() => {
    setLoadingDuring(true);
    onAllAnswers(state.answers, () => {
      allowFinish();
    });
  }, [onAllAnswers, state.answers]);

  const allowFinish = () => {
    // allow finish and unset loading
    setFinishable(true);
    setLoadingDuring(false);
    setErrorEnd();
  };

  useEffect(() => {
    if (!state.clickBlock) {
      // are we on last page?
      if (state.questionIndex === state.pages.length - 1) {
        // are all questions answered?
        if (state.isEnd) {
          !state.manualEnd && handleEnd();
        }
        else {
          setErrorEnd(state.error);
        }
      }
      // reset UI when navigating away from last page
      else {
        setFinishable(false);
        setLoading(false);
        setErrorEnd();
      }
    }
  }, [state, onAllAnswers, handleEnd]);






  // COMPONENT/UI STATE and REFS
  // page buildup/teardown
  const [ hide, setHide ] = useState(false);

  // modals
  // modal hurry
  const [ modalHurryShow, setModalHurryShow ] = useState(false);
  const [ modalHurryWasShown, setModalHurryWasShown ] = useState(false);

  // modal hurry
  useEffect(() => {
    const page = state.pages[state.questionIndex];
    if ( // show only when: assessment has started, page is a question
      state.started === true // assessment has started
      && page && !page.isIntermission // page is not an intermission
      && !modalHurryWasShown // modal reminder hasn't been shown already
    ) {
      const delay = page.modalHurryDelay || state.modalHurryDelay || GLOBAL_CONFIG.modalHurryDelay;
      const timerId = setTimeout(() => {
        setModalHurryShow(true);
      }, delay);
      return () => {
        clearTimeout(timerId);
      }
    }
  }, [state.started, modalHurryWasShown, state.questionIndex, state.pages, state.modalHurryDelay]);

  // modal cancel
  const [ modalCancelShow, setModalCancelShow ] = useState(false);

  // modal help
  const [ modalHelpHeader, setModalHelpHeader ] = useState();
  const [ modalHelpContent, setModalHelpContent ] = useState();
  const [ modalHelpShow, setModalHelpShow ] = useState();

  // modal inactivity
  const [ modalInactivityShow, setModalInactivityShow ] = useState(false);
  const [ modalInactivityWarningShow, setModalInactivityWarningShow ] = useState(false);
  const [ modalInactivityHash, setModalInactivityHash ] = useState('');

  const [copyrightModalVisible, setCopyrightModalVisible] = useState();

  // modal inactivity:
  // the modal is activated only when the first question is answered
  // (so users have enough time for registration, guidance & intro pages)
  // the timeout restarts with every question answered
  // that means users can't linger indefinitely on intermissions
  // once the assessment has started
  useEffect(() => {
    if (!state.inactivityTimeout) {
      return;
    }
    let hash = '';
    Object.keys(state.answers).forEach((key) => {
      const value = state.answers[key];
      if (value !== undefined) {
        hash += `${key}${value}`;
      }
    });
    if (hash.length && hash !== modalInactivityHash) {
      setModalInactivityHash(hash);
      // console.log('answers changed', hash);
    }
  }, [state.inactivityTimeout, state.answers, modalInactivityHash]);
  // modal inactivity: set timeouts for inactivity modals
  useEffect(() => {
    let timerIdWarning, timerIdTimeout;

    if (modalInactivityHash.length) {
      // console.log('modalInactivity set timeouts');
      timerIdWarning = setTimeout(() => {
        setModalInactivityWarningShow(true);
      }, state.inactivityTimeout * CONFIG.inactivityTimeoutWarningFactor);
      timerIdTimeout = setTimeout(() => {
        setModalInactivityShow(true);
      }, state.inactivityTimeout);
    }

    return () => {
      clearTimeout(timerIdWarning);
      clearTimeout(timerIdTimeout);
    }
  }, [state.inactivityTimeout, modalInactivityHash]);

  // toast message
  const [ toastMessageWasShown, setToastMessageWasShown ] = useState(false);
  const [ toastMessageShow, setToastMessageShow ] = useState(false);
  useEffect(() => {
    if (!toastMessageWasShown
      && state.allowBackNavigationOnlyOncePerQuestion && state.hasNavigatedBack
    ) {
      setToastMessageShow(true);
      setToastMessageWasShown(true);
    }
  }, [
    toastMessageWasShown,
    state.allowBackNavigationOnlyOncePerQuestion, state.hasNavigatedBack
  ]);

  // error message
  const [ errorMessage, setErrorMessage ] = useState('');

  // alert when navigating away
  // show warning to user before navigating away
  const handleBeforeUnload = event => {
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = '';
  };

  // before unload mesage
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  // scrolling
  const [ canScroll, setCanScroll ] = useState(false);
  // has no effect, left in here for better scroll management
  const [ animating, setAnimating ] = useState(false);

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, []);

  // page & animation handling
  const [ hideBackButton, setHideBackButton ] = useState(true);
  const [ hideForwardButton, setHideForwardButton ] = useState(true);
  const [ pageAnimationsDirection, setPageAnimationsDirection ] = useState('forwards'); // 'forwards' || 'backwards'
  const [ page1, setPage1 ] = useState();
  const [ page1Animation, setPage1Animation ] = useState('begin');
  const [ page1PreAnimation, setPage1PreAnimation ] = useState(false);
  const [ page2, setPage2 ] = useState();
  const [ page2Animation, setPage2Animation ] = useState('begin');
  const [ page2PreAnimation, setPage2PreAnimation ] = useState(false);


  // TRANSLATIONS
  const translationIds = getTranslationIds(type);


  // PROGRESS
  const [ progress, setProgress ] = useState(0);
  const [ hideProgress, setHideProgress ] = useState(false);

  // CLICKBLOCK
  useEffect(() => {
    // set clickBlock and don't reset when any modal is active
    if (
      modalHurryShow || modalCancelShow
      || modalInactivityShow || modalInactivityWarningShow
      || modalHelpShow || modalWelcomeBackShow
      || copyrightModalVisible
    ) {
      dispatchLocal({ type: 'setClickBlock' });
      return;
    }

    if(!state.clickBlock) return;

    const timerId = setTimeout(() => {
      dispatchLocal({ type: 'unsetClickBlock' });
    }, GLOBAL_CONFIG.clickBlockDuration);

    return () => {
      clearTimeout(timerId);
    };
  }, [state.clickBlock, modalHurryShow, modalCancelShow, modalInactivityShow,
      modalInactivityWarningShow, modalHelpShow, modalWelcomeBackShow, copyrightModalVisible]);


  // ANIMATIONS
  const [ animationTimes ] = useState({ // useState() so new calc between renders
    // forwards
    pageForwardsInTotalDuration: msToNumber(styles.pageForwardsInDuration) + msToNumber(styles.pageForwardsInDelay),
    pageForwardsInDuration: msToNumber(styles.pageForwardsInDuration),
    pageForwardsInDelay: msToNumber(styles.pageForwardsInDelay),
    pageForwardsOutDuration: msToNumber(styles.pageForwardsOutDuration),
    // backwards
    pageBackwardsInTotalDuration: msToNumber(styles.pageBackwardsInDuration) + msToNumber(styles.pageBackwardsInDelay),
    pageBackwardsInDuration: msToNumber(styles.pageBackwardsInDuration),
    pageBackwardsInDelay: msToNumber(styles.pageBackwardsInDelay),
    pageBackwardsOutDuration: msToNumber(styles.pageBackwardsOutDuration),
  });
  useEffect(() => {
    const page1Out = state.animationCount % 2;

    const currentPage = state.pages[state.questionIndex] || {};

    // determine scrolling
    if (currentPage.isIntermission) {
      // intermissions have own scrolling, esp. with fixed footer
      setCanScroll(false);
      setAnimating(true);
    }
    else {
      setCanScroll(true);
      setAnimating(true);
    }

    // determine animation type & content
    if (page1Out) {
      setPage1Animation('out');
      setPage2Animation('in');
      setPage2(currentPage);
    }
    else {
      setPage1Animation('in');
      setPage1(currentPage);
      setPage2Animation('out');
    }

    // determine animation direction & animationOutDuration for reset
    let animationsDirection;
    let animationOutDuration;
    let progressDelay;
    if (state.questionIndex >= state.lastQuestionIndex) { // >= so it's forwards on start
      animationsDirection = 'forwards';
      animationOutDuration = animationTimes.pageForwardsOutDuration;
      progressDelay = animationTimes.pageForwardsInTotalDuration;
    }
    else {
      animationsDirection = 'backwards';
      animationOutDuration = animationTimes.pageBackwardsOutDuration;
      progressDelay = animationTimes.pageBackwardsInTotalDuration;
    }
    setPageAnimationsDirection(animationsDirection);

    // check: show buttons?
    let hideBackButton = !state.allowBackNavigation
      || state.questionIndex === 0
      || (currentPage.isIntermission && animationsDirection === 'forwards')
      || (state.skippedQuestionsMode && state.questionIndex <= state.skippedQuestionsModeStartIndex)
      || state.highestQuestionIndex - state.allowBackNavigation === state.questionIndex
      || (state.allowBackNavigationOnlyOncePerQuestion && state.hasNavigatedBack)
    ;
    hideBackButton = currentPage.showBackArrow
      ? false
      : hideBackButton;
    setHideBackButton(hideBackButton);
    let hideForwardButton = state.allowForwardNavigation
      ? currentPage.isIntermission || state.skippedQuestionsMode
      : state.answers[currentPage.id] === undefined || !state.allowBackNavigation;
    setHideForwardButton(hideForwardButton);

    // reset content & animating after animation is done so going back
    // rerenders component and doesn't show end state of last animation
    const resetTimerId = setTimeout(() => {
      if (page1Out) {
        setPage1();
      }
      else {
        setPage2();
      }
    }, animationOutDuration);
    setTimeout(() => {
      setAnimating(false);
    }, 500);


    // progress
    // apply progress update after pageIn animation is done or later when hiding
    const showProgress = !currentPage.isIntermission
      || currentPage.showProgressBar
      || (currentPage.isIntermission && currentPage.countAsProgress);
    progressDelay = showProgress
      ? progressDelay * CONFIG.progressDelayFactor
      : progressDelay * 2;
    const progressTimerId = setTimeout(() => {
      setProgress(state.progress);
    }, progressDelay);
    // hide / show progress with a separate delay based on direction
    const progressHideDelay = !showProgress
      ? 0
      : progressDelay;
    const progressHideTimerId = setTimeout(() => {
      setHideProgress(!showProgress);
    }, progressHideDelay);

    // clearTimeout on
    return () => {
      clearTimeout(resetTimerId);
      clearTimeout(progressTimerId);
      clearTimeout(progressHideTimerId);
    };

  }, [
    state.questionIndex,
    state.lastQuestionIndex,
    state.highestQuestionIndex,
    state.animationCount,
    state.progress,
    state.pages,
    state.allowBackNavigation,
    state.allowBackNavigationOnlyOncePerQuestion,
    state.hasNavigatedBack,
    state.allowForwardNavigation,
    state.skippedQuestionsMode,
    state.skippedQuestionsModeStartIndex,
    state.answers,
    animationTimes
  ]);

  const handlePreAnimation = () => {
    // determining page before state change happens
    const page1Out = (state.animationCount + 1) % 2;

    page1Out && setPage1PreAnimation(true);
    !page1Out && setPage2PreAnimation(true);

    setTimeout(() => {
      page1Out && setPage1PreAnimation(false);
      !page1Out && setPage2PreAnimation(false);
    }, Number(styles.animationDurationMs) + animationTimes.pageForwardsOutDuration + 200 /* buffer for ios */); // from QuestionBubbles
  };


  // STORE HOOKS


  // METHODS
  const close = (callback = () => {}) => {
    setHide(true);

    const duration = Number(styles.animationDurationLongMs) * 2;
    setTimeout(() => {
      callback();
    }, duration);
  };
  const cleanup = () => {
    storageController.removeState();
  };

  const addPages = ({ pages, insertAtIndex, replace }) => {
    const [ questionsValid, error ] =  validateQuestions(pages);
    if (questionsValid) {
      dispatchLocal({ type: 'addPages', payload: {
        pages, insertAtIndex, replace
      }});
    }
    else {
      setErrorMessage(
        <>
          addPages: Empty Array or containing faulty question(s):
          <br/>
          { JSON.stringify(error, null, 2) }
        </>
      );
    }
  };



  // EVENT HANDLES
  const handleCancel = () => {
    setModalCancelShow(false);
    close(onCancel);
  };
  const handleFinish = (afterClose = () => {}) => {
    cleanup();
    close(() => {
      onFinish();
      afterClose();
    });
  };
  const handleHeaderAction = () => {
    if (finishable) {
      handleFinish();
    }
    else {
      setModalCancelShow(true);
    }
  };
  const handleAnswer = useCallback((answer, questionAnimationDelay = 0) => {
    const question = state.pages[state.questionIndex];
    const {
      isIntermission = false,
      id: questionId,
      stageNumber,
    } = question;

    if (state.clickBlock && !isIntermission) {
      // NineLevelsStage might execute faster as clickblock
      // therefore we're ignoring clickblock here so NineLevelsStage can
      // call addPages local reducer action.
      return;
    }

    // get previous answer if it's available
    answer = answer === undefined
      ? state.answers[questionId]
      : answer;
    const questionIndex = state.questionIndex;
    const time = Date.now();
    const animationTime = pageAnimationsDirection === 'forwards'
    || pageAnimationsDirection === 'begin'
    ? animationTimes.pageForwardsInTotalDuration + questionAnimationDelay
    : animationTimes.pageBackwardsInTotalDuration;

    dispatchLocal({ type: 'next', payload: {
      answer,
      time,
      animationTime
    }});
    onAnswer({
      questionId,
      answer,
      questionIndex,
      stageNumber,
      time: {
        totalTime: time - state.lastTime,
        animationTime
      }
    });
  }, [state, onAnswer, pageAnimationsDirection, animationTimes]);


  const goBack = useCallback(() => {
    dispatchLocal({ type: 'prev', payload: Date.now() });
  }, []);
  const goForward = useCallback(() => {
    const currentPage = state.pages[state.questionIndex];
    const currentAnswer = state.answers[currentPage.id];
    handleAnswer(currentAnswer);
  }, [state, handleAnswer]);



  // KEYBOARD CONTROLS
  const handleKey = useCallback((event) => {
    const key = event.key;

    switch(key) {
      case 'Escape': {
        setModalCancelShow(true);
        return;
      }
      case 'ArrowUp':
      case 'ArrowLeft':
      case 'd':
      case 'k': {
        goBack();
        return;
      }
      case 'ArrowDown':
      case 'ArrowRight':
      case 'u':
      case 'j':
      case 'Enter': {
        const currentPage = state.pages[state.questionIndex];
        if (!currentPage) {
          return;
        }
        if (currentPage.preventKeyboardNext) {
          return;
        }
        goForward();
        return;
      }
      default: {
        return;
      }
    }
  }, [goForward, goBack, state]);

  useEffect(() => {
    window.addEventListener('keyup', handleKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
    }
  }, [handleKey]);




  // HELPERS


  // RENDERS
  const renderPage = (page) => {
    if (!page) return;

    // explanatory
    const isExplanatory = page && page.explanatory;

    // intermission
    const isIntermission = page && page.isIntermission && page.render;

    // question
    const isQuestion = page && page.id && !page.isIntermission;
    let QuestionComponent, questionRange;
    if (isQuestion || isExplanatory) {
      // can safely getQuestionRenderInfo here because questions were validated on init
      [ QuestionComponent, questionRange ] = getQuestionRenderInfo(page);
    }

    // console.log('page', page);

    const selectedAnswer = page && page.id && state.answers[page.id] !== undefined
      ? state.answers[page.id]
      : undefined ;

    // console.log('answer', selectedAnswer);

    return (
      <>
        {/* ERROR PAGE */}
        { errorMessage && (
          <>
            <h4>Technical Error</h4>
            <br/>
            <br/>
            { errorMessage }
            <br/>
            <br/>
            <br/>
            <Button looks='secondary' size='M'
              onClick={handleCancel}>
              { translate('assessment_abort_confirm') }
            </Button>
          </>
        )}

        {/* INTERMISSION */}
        { !errorMessage && isIntermission && (
          page.render(handleAnswer, goBack, state, {
            finishable,
            handleFinish,
            allowFinish,
            errorEnd,
            addPages,
            handleEnd: state.manualEnd && handleEnd,
            loadingDuring,
            setLoadingDuring,
          })
        )}

        {/* QUESTION */}
        { !errorMessage && (isQuestion  || isExplanatory) && (
          <QuestionComponent
            question={page}
            range={questionRange}
            selectedValue={selectedAnswer}
            clickBlock={state.clickBlock}
            onAnswer={handleAnswer}
            onAnimation={handlePreAnimation}
            onHelp={() => {
              setModalHelpHeader(translate('assessment_help_info_title') || '💡 Hilfe bei der Auswahl');
              setModalHelpContent(translate(state.modalHelpContentTranslationKey) || markdown(state.modalHelpContent));
              setModalHelpShow(true);
            }}
          />
        )}
      </>
    );
  };


  // RENDER: AssessmentNext
  return (
    <div className={classNames(styles.assessmentNext, {
        [styles.hide]: hide
      })}>

      {/* OVERLAY */}
      <div className={classNames(styles.overlay, {
        [styles.hide]: hide
      })}>

        {/* HEADER */}
        <div className={classNames(styles.header, {
          [styles.hide]: hide
        })}>
          <AssessmentHeader
            title={translate(translationIds.headerTitle) || state.title}
            actionLabel={finishable ?
              (translate('assessment_complete') || 'Finish') :
              (translate('assessment_abort') || 'Cancel')
            }
            onAction={handleHeaderAction}
          />
        </div>

        {/* PROGRESSBAR */}
        <div className={classNames(styles.progressBarContainer, {
          // hide on hide & on intermissions that don't countAsProgress
          [styles.hide]: hide,
          [styles.hideInBetween]: hideProgress && !loading
        })}>
          <div className={styles.progressBar}>
            <ProgressBar progress={progress} loading={loading || loadingDuring} />
          </div>
        </div>

        {/* ASSESSMENT CONTENT */}
        <div className={classNames(styles.assessmentContent, {
          [styles.hide]: hide,
          [styles.canScroll]: canScroll && !loadingPage,
          [styles.animating]: animating
        })}>


          {/* PAGE 1 */}
          <div className={classNames(styles.page,
            styles[pageAnimationsDirection],
            styles[page1Animation],
          )}>

            {/* BACK ARROW CONTAINER */}
            <div className={classNames(styles.backArrowContainer, {
              [styles.hideButton]: hideBackButton,
              [styles.hideImmediately]: hideBackButton && page1 && page1.isIntermission,
              [styles.preAnimation]: !hideBackButton && page1PreAnimation
            })}>
              <div className={styles.arrow}
                onClick={goBack}>
                <ArrowUp/>
              </div>
            </div>

            {/* PAGE CONTENT */}
            <div className={styles.pageContent}>

              {/* LOADING PAGE */}
              <BluCSSTransition in={!!loadingPage} classNames={{ ...styles }}>
                <>
                  {loadingPage}
                </>
              </BluCSSTransition>

              {/* RENDER PAGE1 */}
              { !loadingPage && (
                <>
                  { renderPage(page1) }

                  {/* FORWARD ARROW CONTAINER */}
                  <div className={classNames(styles.forwardArrowContainer, {
                    [styles.hideButton]: hideForwardButton,
                    [styles.hideImmediately]: hideForwardButton && page1 && page1.isIntermission,
                    [styles.preAnimation]: !hideForwardButton && page1PreAnimation
                  })}>
                    <div className={styles.arrow}
                      onClick={goForward}>
                      <ArrowDown/>
                    </div>
                  </div>

                  {/*COPYRIGHT*/}
                  {(!loading && page1 && (page1.showCopyright || (showCopyright && !page1.isIntermission))) &&
                  <div className={styles.copyright}>
                    <span onClick={() => setCopyrightModalVisible(true)}>
                      {translate(`${type}_ass_copyrightlink`, ['{{year}}', new Date().getFullYear()]) ||
                      '© IDS Publishing Corporation. All rights reserved.'}
                    </span>
                  </div>
                  }
                </>
              )}


            </div>
          </div>

          {/* PAGE 2 */}
          <div className={classNames(styles.page,
            styles[pageAnimationsDirection],
            styles[page2Animation],
          )}>

            {/* BACK ARROW CONTAINER */}
            <div className={classNames(styles.backArrowContainer, {
              [styles.hideButton]: hideBackButton,
              [styles.hideImmediately]: hideBackButton && page2 && page2.isIntermission,
              [styles.preAnimation]: !hideBackButton && page2PreAnimation
            })}>
              <div className={styles.arrow}
                onClick={goBack}>
                <ArrowUp/>
              </div>
            </div>

            {/* PAGE CONTENT */}
            <div className={styles.pageContent}>

              {/* RENDER PAGE2 */}
              { renderPage(page2) }

              {/* FORWARD ARROW CONTAINER */}
              <div className={classNames(styles.forwardArrowContainer, {
                [styles.hideButton]: hideForwardButton,
                [styles.hideImmediately]: hideForwardButton && page2 && page2.isIntermission,
                [styles.preAnimation]: !hideForwardButton && page2PreAnimation
              })}>
                <div className={styles.arrow}
                  onClick={goForward}>
                  <ArrowDown/>
                </div>
              </div>

              {/*COPYRIGHT*/}
              {(!loading && page2 && (page2.showCopyright || (showCopyright && !page2.isIntermission))) &&
              <div className={styles.copyright}>
                <span onClick={() => setCopyrightModalVisible(true)}>
                  {translate(`${type}_ass_copyrightlink`, ['{{year}}', new Date().getFullYear()]) ||
                  '© IDS Publishing Corporation. All rights reserved.'}
                </span>
              </div>
              }

            </div>
          </div>

        </div>

      </div>



      {/* MODALS */}

      {/* HURRY MODAL */}
      { (modalHurryShow && !modalInactivityWarningShow && !modalInactivityShow) && (
        <Modal
          header={translate('big5_ass_hintmodal_inactivity_title') || '⏱ Don\'t think too long.'}
          secondaryButtonTitle={translate('okay_lbl')}
          onConfirm={() => {
            setModalHurryShow(false);
            setModalHurryWasShown(true);
          }}
          onClose={() => {
            setModalHurryShow(false);
            setModalHurryWasShown(true);
          }}
        >
          {translate('big5_ass_hintmodal_inactivity_description') ||
          'Don\'t overthink it. Spontaneous answers are usually the right ones.'}
        </Modal>
      )}

      {/* CANCEL MODAL */}
      { modalCancelShow && (
        <Modal
          header={translate('assessment_abort_title') || 'Are you sure you want to abort?'}
          redButtonTitle={translate('assessment_abort_confirm') || 'Abort assessment'}
          secondaryButtonTitle={translate('assessment_abort_cancel') || 'Resume assessment'}
          onConfirm={handleCancel}
          onClose={() => setModalCancelShow(false)}
        >
          { state.canContinueLater === undefined && (
            translate(translationIds.abortDescription)
          )}

          { state.canContinueLater === true && (
            translate('assessment_abort_description_progress_saved')
          )}

          { state.canContinueLater === false && (
            translate('assessment_abort_description_progress_lost')
          )}
        </Modal>
      )}

      {/* HELP MODAL */}
      { (modalHelpShow) && (
        <Modal
          header={modalHelpHeader}
          secondaryButtonTitle={translate('close_lbl') || 'Schließen'}
          onClose={() => setModalHelpShow(false)}
        >
          {modalHelpContent}
        </Modal>
      )}

      {/* WELCOME BACK MODAL */}
      { (modalWelcomeBackShow) && (
        <Modal
          header={translate('assessment_welcome_back_title') || '👋 Willkommen zurück'}
          secondaryButtonTitle={translate('continue_lbl') || 'Weiter'}
          onClose={() => {
            setModalWelcomeBackShow(false);
            setModalWelcomeBackShown(true);
          }}
        >
          {translate('assessment_welcome_back_content') || 'Du kannst das Assessment an der Stelle fortsetzen an der du aufgehört hast.'}
        </Modal>
      )}

      {/* MODAL INACTIVITY */}
      { (modalInactivityShow) && (
        <Modal
          header={translate('assessment_inactivity_title') || '🙁 Du warst zu lange inaktiv'}
          secondaryButtonTitle={translate('assessment_inactivity_restart_button') || 'Erneut starten'}
          onClose={() => {
            setModalInactivityShow(false);
            setModalInactivityWarningShow(false);
            setModalHurryShow(false);
            setModalHurryWasShown(true);
            handleCancel();
          }}
        >
          {translate('assessment_inactivity_content') || 'Leider muss dein Assessment zurückgesetzt werden. Bitte starte das Assessment erneut und führe es ohne lange Unterbrechungen durch.'}
        </Modal>
      )}

      {/* MODAL INACTIVITY WARNING */}
      { (modalInactivityWarningShow && !modalInactivityShow) && (
        <Modal
          header={translate('assessment_inactivity_warning_title') || 'Warte nicht zu lange!'}
          secondaryButtonTitle={translate('continue_lbl') || 'Weiter'}
          onClose={() => {
            setModalInactivityWarningShow(false);
          }}
        >
          {translate('assessment_inactivity_warning_content') || 'Wenn du längere Zeit keine Fragen beantwortest, wird das Assessment neu gestartet.'}
        </Modal>
      )}

      {/*COPYRIGHT MODAL*/}
      {copyrightModalVisible &&
      <Modal
        header={translate(`${type}_ass_info_title_copyright`) || 'Copyright header'}
        secondaryButtonTitle={'OK'}
        onClose={() => setCopyrightModalVisible(false)}
      >
        {translate(`${type}_ass_info_description_copyright`) || 'Copyright content'}
      </Modal>
      }

      { toastMessageShow && (
        <Toast
          headline={translate('assessment_toast_message_title')}
          message={translate('assessment_toast_message_descr')}
          onClose={() => setToastMessageShow(false)}
        />
      )}

    </div>
  );
};

export default AssessmentNext;
