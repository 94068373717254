import React from 'react';
import styles from './Loading.module.scss';

// 3RD PARTY
import classNames from 'classnames';

const Spinner = () => {

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 2400 2400"
    >
      <g id="layer101" fill="#f2f2f2" stroke="none">
        <path d="M965 2380
          c-489 -100 -859 -478 -950 -969 -20 -108 -20 -314 0 -422 78 -421 369 -770 767 -918
          l53 -20 -28 20
          c-99 74 -62 232 60 255
          l35 7 -80 37 c-252 119 -434 334 -509 600 -25 93 -25 367 0 460 75 267 258 482 511 600 136 64 189 75 376 75 187 0 240 -11 376 -75 253 -118 436 -333 511 -600 25 -93 25 -367 0 -460 -75 -267 -256 -480 -511 -601 -79 -37 -80 -38 -43 -39 51 0 90 -24 116 -70 32 -58 25 -118 -22 -169 l-22 -23 25 7
          c14 4 63 27 110 50 339 171 575 487 645 864 20 108 20 314 0 422 -93 499 -475 881 -974 974 -115 21 -330 19 -446 -5z" />
      </g>
      <g id="layer102" fill="#2d72fe" stroke="none">
        <path d="M1435 308 c-42 -11 -120 -21 -193 -25 -104 -5 -142 -2 -241 17 -130 25 -156 23 -202 -18 -58 -52 -53 -147 10 -203 31 -29 108 -50 248 -70 159 -23 447 11 530 62 75 47 97 139 46 200 -28 33 -79 60 -110 58 -10 -1 -49 -10 -88 -21z" />
      </g>
    </svg>
  );
};

const Loading = (props) => {

  // PROPS
  const {
    visible,
    opaque
  } = props;

  return (
    <>
      {visible && (
        <div className={classNames(styles.modal, {
          [styles.opaque]: opaque
        })}>
          <div className={styles.modalBackground}>
            <div className={styles.loading}>
              <div className={styles.container}>
                <div className={styles.spinner}>
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
Loading.defaultProps = {
  visible: false
};
export default Loading;
