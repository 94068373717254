import React, {useEffect} from 'react';
import styles from './SignUp.module.scss';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {getRegistrationDomains, signUp} from 'store/actions';

import { useTranslate } from 'utils/translator';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';

import SignUpSuccessForm from '../../components/SignUpSuccessForm';
import SignUpForm from '../../components/SignUpForm';
import PoweredBy from 'ui/basic/micro-ui/PoweredBy';

const CONFIGURATION_TITLES = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: 'signup_title',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_signup__email_sent_title'
};

const SignUp = () => {

  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const emailSentSuccessfully = useSelector(state => state.signUp && state.signUp.registrationEmailSentSuccessfully);
  const error = useSelector(state => state.signUp && state.signUp.error);

  useEffect(() => {
    dispatch(getRegistrationDomains());
  }, [dispatch]);

  const handleSignUp = (signUpDto) => {
    dispatch(signUp(signUpDto));
  };

  return (
    <div className={styles.signUp}>
      <div className={classNames(styles.container, styles[CONFIGURATION.toLowerCase()])}>
        {CONFIGURATION !== CONFIGURATION_OPTIONS.BLUQUIST &&
        <div className={styles.poweredBy}>
          <PoweredBy />
        </div>
        }

        {(emailSentSuccessfully && !history.location.search.includes('invitationId')) ?
          <SignUpSuccessForm
            title={translate(CONFIGURATION_TITLES[CONFIGURATION]) || 'Email sent'}
            description={translate('by_signup__email_sent_descr') || 'Email successfully sent.'}
            buttonTitle={translate('by_signup__email_sent_btn') || 'Get started'}
            onButtonClick={() => history.push('/')}
          /> :
          <SignUpForm
            error={error && error.errorMessage}
            onSignUp={handleSignUp}
          />
        }
      </div>
    </div>
  );
};

export default SignUp;
