import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

export const getCompetencyName = (assessmentType, questionIndex) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return `keycomp_${questionIndex}`;
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return `lscomp_${questionIndex}`;
    default:
      return '';
  }
};

export const getCompetencyDescription = (assessmentType, questionIndex) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return `keycomp_descr_${questionIndex}`;
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return `lscomp_descr_${questionIndex}`;
    default:
      return '';
  }
};

export const getTranslationIds = (assessmentType) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return {
        guidanceQuestion: 'keycomp_ass_guidance_question',
        hintLeft: 'keycomp_ass_hint_left',
        hintRight: 'keycomp_ass_hint_right'
      };
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return {
        guidanceQuestion: 'lscomp_ass_guidance_question',
        hintLeft: 'lscomp_ass_hint_left',
        hintRight: 'lscomp_ass_hint_right'
      };
    default:
      return {};
  }
};
