import * as localStorage from 'utils/localStorage';
const COMMA = ',';
const POINT = '.';
const DEFAULT_LANGUAGE = 'de_DE';

export const setDecimalSeperator = (float, digitsNumber = 1) => {
  if (!float) {
    return float;
  }

  let floatStr;
  if (typeof float !== 'string') {
    floatStr = Number(Math.round(float +`e${digitsNumber}`)+`e-${digitsNumber}`).toFixed(digitsNumber).toString();
  } else {
    floatStr = float;
  }
  const language = localStorage.getItem('selectedLanguage') || DEFAULT_LANGUAGE;
  switch (language) {
    case 'de_DE':
      return floatStr.replace(POINT, COMMA);
    case 'en_US':
      return floatStr.replace(COMMA, POINT);
    default:
      return floatStr.replace(POINT, COMMA);
  }
};
