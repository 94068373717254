export const GET_ASSESSMENT_RESULT = 'GET_ASSESSMENT_RESULT';
export const GET_ASSESSMENT_RESULT_PENDING = 'GET_ASSESSMENT_RESULT_PENDING';
export const GET_ASSESSMENT_RESULT_FULFILLED = 'GET_ASSESSMENT_RESULT_FULFILLED';
export const GET_ASSESSMENT_RESULT_REJECTED = 'GET_ASSESSMENT_RESULT_REJECTED';

export const GET_PREV_ASSESSMENT_RESULT = 'GET_PREV_ASSESSMENT_RESULT';
export const GET_PREV_ASSESSMENT_RESULT_FULFILLED = 'GET_PREV_ASSESSMENT_RESULT_FULFILLED';
export const GET_PREV_ASSESSMENT_RESULT_REJECTED = 'GET_PREV_ASSESSMENT_RESULT_REJECTED';

export const getAssessmentResult = (id) => {
  return {
    type: GET_ASSESSMENT_RESULT,
    payload: id
  };
};

export const getAssessmentResultFulfilled = ({id, data}) => {
  return {
    type: GET_ASSESSMENT_RESULT_FULFILLED,
    payload: {id, data}
  };
};
export const getAssessmentResultRejected = ({ id, error }) => {
  return {
    type: GET_ASSESSMENT_RESULT_REJECTED,
    payload: { id, error }
  };
};

//GET PREVIOUS ASSESSMENT RESULTS
export const getPrevAssessmentResult = (id, history, prevState) => {
  return {
    type: GET_PREV_ASSESSMENT_RESULT,
    payload: {id, history, prevState}
  };
};

export const getPrevAssessmentResultFulfilled = ({id, data, prevState}) => {
  return {
    type: GET_PREV_ASSESSMENT_RESULT_FULFILLED,
    payload: {id, data, prevState}
  };
}

export const getPrevAssessmentResultRejected = ({ error }) => {
  return {
    type: GET_PREV_ASSESSMENT_RESULT_REJECTED,
    payload: { error }
  };
};
