// CONFIG
import {
  REACT_APP_API_BASE_URL,
} from 'utils/configuration';

const Config = {
  errorQueue: {
    throwErrorAfter: 150, // ms
  }
};


export const apiNext = {
  token: '',
  setToken: function (token) {
    this.token = token;
  },
  getUrlBase: function  () {
    let baseUrl = REACT_APP_API_BASE_URL;
    if (baseUrl[baseUrl.length - 1] === '/') {
      // remove any trailing slash
      baseUrl = baseUrl.slice(0, -1);
    }
    return baseUrl;
  },
  getUrlFull: function (url = '', getParams = {}) {
    // add leading slash if not present
    if (url[0] !== '/') url = `/${url}`;


    // get full url
    const fullUrl = this.getUrlBase() + url;

    // merge params from url and getParams object
    const fullUrlObj = new URL(fullUrl);
    fullUrlObj.search = '';
    const paramsString = new URLSearchParams([
      ...Array.from(fullUrlObj.searchParams.entries()),
      ...Object.entries(getParams)
    ]).toString();

    const fullUrlWithParams = paramsString
      ? `${fullUrl}?${paramsString}`
      : fullUrl;

    return fullUrlWithParams;
  },
  getHeaderDefaults: function () {
    return {
      'Content-Type': 'application/json',
      Authorization: this.token ? `Bearer ${this.token}` : undefined,
    };
  },
  getFetchParams: function ({
    method = 'GET',
    url = '',
    body, // usually an object, but allow undefined for GET
    getParams = {},
    extraHeaders = {},
    extraOptions = {}
  }) {
    // full url with get params
    const urlFull = this.getUrlFull(url, getParams);

    // headers & default headers
    const headers = {
      ...this.getHeaderDefaults(),
      ...extraHeaders
    };

    // fetchOptions
    const fetchOptions = {
      method,
      headers,
      body: JSON.stringify(body),
      ...extraOptions
    };

    // const requestId
    const now = Date.now();
    const requestId = `${urlFull}||${now}`;

    return { urlFull, fetchOptions, requestId };
  },
  call: function ({
    method = 'GET',
    url = '',
    body, // usually an object, but allow undefined for GET
    getParams = {},
    extraHeaders = {},
    extraOptions = {},
  }) {

    const { urlFull, fetchOptions, requestId } = this.getFetchParams({
      method,
      url,
      body,
      getParams,
      extraHeaders,
      extraOptions,
    });

    return fetch(urlFull, fetchOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else { // throw for all responses outside status 2XX
        const error = new Error(`${urlFull} HTTP status ${response.status}`);
        // add some context
        error.response = response;
        error.url = urlFull;
        error.requestId = requestId;
        throw error;
      }
    })
    .catch(error => { // throw for all non-HTTP errors
      if (!error.requestId) {
        // add some context
        error.url = urlFull;
        error.requestId = requestId;
      }

      // add to queue (which adds timer and resolve())
      error = errorQueue.add(error);

      // throw error
      throw error;
    });
    // TBD: do some globalised error handling here.
  },
  get: function (url = '', getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      url,
      getParams,
      extraHeaders,
      extraOptions
    })
  },
  post: function (url = '', body = {}, getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      method: 'POST',
      url,
      body,
      getParams,
      extraHeaders,
      extraOptions
    })
  },
  put: function (url = '', body = {}, getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      method: 'PUT',
      url,
      body,
      getParams,
      extraHeaders,
      extraOptions
    })
  },
  patch: function (url = '', body = {}, getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      method: 'PATCH',
      url,
      body,
      getParams,
      extraHeaders,
      extraOptions
    })
  },
  delete: function (url = '', getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      method: 'DELETE',
      url,
      getParams,
      extraHeaders,
      extraOptions
    })
  },

};

export default apiNext;

export const errorQueue = {
  queue: {

  },
  add: function (error) {
    if (!error.requestId) {
      console.warn(JSON.stringify(error, null, 2));
      throw new Error(`apiNext.errorQueue.add can't add error without requestId: ${error}`)
    };
    // TODO: theoretically two requests with the same url could happen at the exact same time (Date.now())
    // then their requestId would be the same.

    // create timer for handleUnhandledError
    const timer = setTimeout(() => {
      this.handleUnhandledError(error);
    }, Config.errorQueue.throwErrorAfter);

    // add timer
    error.timer = timer;

    // add resolve
    error.resolve = () => {
      errorQueue.resolve(error);
    };

    // add error to queue
    this.queue[error.requestId] = error;

    // return updated error
    return error;
  },
  resolve: function (error) {
    if (!error.requestId) {
      console.warn(JSON.stringify(error, null, 2));
      throw new Error(`apiNext.errorQueue.resolve can't resolve error without requestId: ${error}`)
    };

    // clear timeout
    if (error.timer) clearTimeout(error.timer);

    // remove from queue
    delete this.queue[error.requestId];
  },
  handleUnhandledError: function (error) {
    // TODO: global error handling with Modal, Toast Message, or smth. similar
    // allowing user to report the error, discuss with UX/BE.
    console.log('unhandled error');
    console.log(error);
    console.log(JSON.stringify(error, null, 2));
    this.resolve(error)
    alert('unhandled error');
  },
};
