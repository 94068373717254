import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './InfoModal.module.scss';
import classNames from 'classnames';

import Button from 'ui/basic/forms/Button';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';

const InfoModal = (props) => {
  const {
    className,
    header,
    description,
    buttonTitle,
    buttonCentered, // move button to center
    controlOverflow,
    hint,
    onClick
  } = props;
  const [isClosing, setIsClosing] = useState(false);

  const modalRef = useRef();
  const ref = useRef();

  const infoModalClassNames = classNames(
    styles.infoModal,
    className,
    {[styles.isClosing]: isClosing}
  );

  const handleClick = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      if (onClick) {
        onClick();
      }
    }, 250);
  }, [onClick]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      handleClick();
    }
  }, [handleClick]);

  useEffect(() => {
    if (controlOverflow) {
      disableScrollingOnBody();
    }
    return () => {
      if (controlOverflow) {
        enableScrollingOnBody();
      }
    }
  }, [controlOverflow]);

  useEffect(() => {
    const modal = modalRef.current;

    modal.addEventListener('keydown', handleKeyDown);
    modal.focus();

    return () => {
      modal.removeEventListener('keydown', handleKeyDown);
    }
  }, [handleKeyDown])

  const modalClassNames = classNames(
    styles.modal,
    {[styles.isClosing]: isClosing}
  );

  return (
    <div
      ref={ref}
      className={infoModalClassNames}
      onClick={(event) => {
        if (ref.current === event.target) {
          handleClick();
        }
      }}>
      <div className={modalClassNames} ref={modalRef} tabIndex={'0'}>
        <div className={styles.header}>{header}</div>
        <div className={styles.body}>{description}</div>

        {buttonTitle &&
        <div className={classNames(styles.footer, {
          [styles.centered]: buttonCentered
        })}>
          <Button
            size={'S'}
            looks={'secondary'}
            onClick={handleClick}
          >
            {buttonTitle}
          </Button>

        </div>
        }
        {hint && <div className={styles.hint}>{hint}</div>}
      </div>
    </div>
  );
};

InfoModal.defaultProps = {
  controlOverflow: true
};

export default InfoModal;
