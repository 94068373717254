import React, {memo} from 'react';
import styles from './RmpResult.module.scss';

import { useTranslate } from 'utils/translator';

import {ReactComponent as InfoBlue} from 'assets/icons/icn_info_blue.svg';
import {RmpBipolarDiagram} from 'ui/basic';

const RMP_FIRST_MARKER_POSITION = 0.84;


const RmpResultItem = memo((props) => {
  const {width, dimension, dimensionId, score, range, exportView, onInfoClick} = props;

  const translate = useTranslate();

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        {translate(`rmp_results_label_${dimensionId}`) || dimension}

        {!exportView && <InfoBlue onClick={onInfoClick} />}
      </div>
      <RmpBipolarDiagram
        width={width}
        //rmp assessment values are in range of [-2...2]
        //+2 because svg doesn't accept negative values
        score={score + 2}
        range={range && [range[0] + 2, range[1] + 2]}
      />
      <div className={styles.titles}>
        <div className={score < -RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
          {translate('rmp_results_scale_min') || 'gering'}
        </div>
        <div className={score > RMP_FIRST_MARKER_POSITION ? styles.blue: null}>
          {translate('rmp_results_scale_max') || 'stark'}
        </div>
      </div>
    </div>
  )
});

export default RmpResultItem;
