import React, { useEffect, useState, useRef, memo } from 'react';
import styles from './InputPassword.module.scss';

import classnames from 'classnames';

import { useTranslate } from 'utils/translator';

import {Icons} from 'assets/icons';
import {InputNext, Icon} from 'ui/basic';

const CRITERIA = [
  'pwrequirement_form_pwhint_uppercase',
  'pwrequirement_form_pwhint_lowercase',
  'pwrequirement_form_pwhint_number',
  'pwrequirement_form_pwhint_specialchar',
  'pwrequirement_form_pwhint_length'
];

const DEFAULT_CRITERIA = [
  '1 letter uppercase',
  '1 letter lowercase',
  '1 number',
  '1 special character',
  'At least 8 character'
];

const initialCheckResults = [false, false, false, false, false];

const InputPassword = memo((props) => {
  const {
    value = '',
    placeHolder = '',
    hint = '',
    error = '',
    disabled,
    showCriteria,
    hideCriteriaOnBlur = true,
    onFocus
  } = props;

  const translate = useTranslate();

  const criteria = CRITERIA.map((criterium, index) => {
    return translate(criterium) || DEFAULT_CRITERIA[index];
  });
  const [inputValue, setInputValue] = useState(props.value ? props.value : '');
  const cleanUp = () => {};
  const [inputCheckResults, setInputCheckResult] = useState(
    initialCheckResults
  );
  const [isFocused, setFocus] = useState(false);
  const checkInput = (value) => {
    let results = [...inputCheckResults];
    for (let i = 0; i < inputCheckResults.length; i++) {
      switch (i) {
        case 0:
          // Check on uppercase
          results[i] = /[A-Z]/.test(value);
          break;
        case 1:
          // Check on lowercase
          results[i] = /[a-z]/.test(value);
          break;
        case 2:
          // Check on number
          results[i] = /[0-9]/.test(value);
          break;
        case 3:
          // Check on special char
          const specialReg = new RegExp(
            '^(?=.*[!@#$%^&*"\\[\\]\\{\\}<>/\\(\\)=\\\\\\-_´+`~\\:;,\\.€\\|])'
          );
          results[i] = specialReg.test(value);
          break;
        case 4:
          // Check on length
          results[i] = value.length >= 8;
          break;
        default:
      }
    }
    const correct = results.indexOf(false) === -1;
    if (props.onInputChanged) {
      props.onInputChanged(value, correct);
    }
    setInputCheckResult([...results]);
  };
  const handleChange = (value) => {
    checkInput(value);
    if (props.onChange) {
      props.onChange(ref.current, value);
    }
  };

  const initialize = () => {
    if (ref && ref.current)
      if (props.onInitialized) {
        props.onInitialized(ref.current);
      }
    setInputValue(value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const componentDidMount = () => {
    initialize();
    return cleanUp;
  };
  useEffect(componentDidMount, []);

  const classNamesCriteria = classnames(styles.criteria);

  const ref = useRef();

  const createCriteria = () => {
    return criteria.map((criterium, index) => {
      const correct = inputCheckResults[index]
      const classNamesCriterium = classnames(styles.criterium, {
        [styles.correct]: correct
      });
      return (
        <div className={classNamesCriterium} key={'N' + index}>
          {criterium}{correct?<div className={styles.icon}><Icon  icon={Icons.CheckSmall}/></div>:null}
        </div>
      );
    });
  };
  return (
    <div className={classnames(styles.inputPassword, props.className)}>
      <InputNext
        type={'password'}
        disabled={disabled}
        value={inputValue}
        label={placeHolder}
        hint={hint}
        errorMessage={error}
        onChange={handleChange}
        onFocus={(event) => {
          setFocus(true);
          if(onFocus){
            onFocus(event)
          }
        }}
        onBlur={() => {
          if (hideCriteriaOnBlur) {
            setFocus(false);
          }
        }}
        onKeyUp={(event) => {
          if (event.key !== 'Enter') event.stopPropagation();
          // preventive measure so form elements in Dialogs / Assessments etc.
          // don't trigger page navigations
        }}
      />

      {(showCriteria && isFocused) &&
      <div className={classNamesCriteria}>{createCriteria()}</div>
      }
    </div>
  );
});

export default InputPassword;
