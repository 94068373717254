// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';
import apiNext from 'apiNext';

// STORE NEXT
import { useDispatch } from 'react-redux';
import { login, setFeatures, setAssessments, setCapabilities, setInitialisedExtended, setLoadingExternal, setStateExtras } from 'features/framework/storeNext/configurationSlice'


// CONFIG & DATA
// const Config = {};

// COMPONENT: LoggedIn
export const LoggedIn = (props) => {
  // PROPS
  const {
    loading,
    tokenId, tokenExpiration,
    userId, userGroup,
    profileCompleted,
    userFirstName, userLastName,
    features,
    assessments,
    capabilities,
    stateExtras,
  } = props;


  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('LOADING EXTRANAL', loading);
    dispatch(setLoadingExternal(loading));
  }, [dispatch, loading])

  useEffect(() => {
    if (tokenId && tokenExpiration && userId && userGroup) {
      console.log('LOGIN', window.location.href)
      dispatch(login({
        tokenId, tokenExpiration,
        userId, userGroup, userFirstName, userLastName,
        profileCompleted
      }));
      apiNext.setToken(tokenId);
    }
  }, [dispatch, tokenId, tokenExpiration, userId, userGroup, profileCompleted,
    userFirstName, userLastName
  ])

  useEffect(() => {
    if (features && assessments && capabilities) {
      console.log('EXTENDED', window.location.href);
      dispatch(setFeatures(features));
      dispatch(setAssessments(assessments));
      dispatch(setCapabilities(capabilities));
      dispatch(setInitialisedExtended(true));
    }
  }, [dispatch, features, assessments, capabilities])

  // STORE: STATE EXTRAS
  useEffect(() => {
    console.log('SET STATE EXTRAS', window.location.href)
    dispatch(setStateExtras(stateExtras));
  }, [dispatch, stateExtras]);


  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: LoggedIn
  return (
    <>
    </>
  );
};

export default LoggedIn;
