import React, {memo, useState} from 'react';
import styles from './CollapsiblePanel.module.scss';
import classNames from 'classnames';
import {ReactComponent as ArrowDown} from 'assets/icons/icn_arrow_down.svg';

const CollapsiblePanel = memo((props) => {
  const {
    header,
    children,
    showTopBorder,
    headerClassName,
    expanded,
    isExpandable = true,

    onMouseDown = () => {},
    onMouseUp = () => {},

    onExpand
  } = props;
  const [isExpanded, setIsExpanded] = useState(expanded);

  const collapsiblePanelClassNames = classNames(
    styles.collapsiblePanel,
    {[styles.expanded]: isExpanded}
  );

  const headerClassNames = classNames(
    styles.header,
    headerClassName,
    {[styles.expanded]: isExpanded},
    {[styles.showBorder]: showTopBorder}
  );

  const bodyClassNames = classNames(
    styles.body,
    {[styles.bodyExpanded]: isExpanded}
  );

  return (
    <div className={collapsiblePanelClassNames}>
      <div
        className={headerClassNames}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onClick={() => {
          setIsExpanded(!isExpanded);

          if (onExpand) {
            setTimeout(() => {
              onExpand();
            }, 300)
          }
        }}
      >
        {header}

        {isExpandable &&
        <div className={styles.arrow}><ArrowDown className={styles.arrow}/></div>
        }
      </div>
      {isExpanded &&
      <div className={bodyClassNames}>
        {children}
      </div>}
    </div>
  )
});

CollapsiblePanel.defaultProps = {
  showTopBorder: true,
  expanded: false
};

export default CollapsiblePanel;
