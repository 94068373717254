import * as fromActions from '../actions/filterListEntities';

import { LIMIT } from 'utils/configuration/const/pagination';

export const initialStateForSingleEntity = {
  // ui
  loading: false,
  firstLoad: true,
  showLastDataWhileLoading: false,
  errors: null,

  // results
  listItems: null, // []
  pageCount: 0,
  noDataAtAll: false, // when there is no data set for this entity at all
  noDataDuringCalculation: false, // data is being recalculated (e.g. list matching with roles)

  // dynamic configuration
  dynamicConfig: {
    apiEndpointReplaceValue: '',
    resetRoutingPathReplaceValue: '',
  },

  // search params
  type: '', // 'customers' or 'demo'
  toggleTypes: [],
  toggleIndex: 0,
  searchString: '',
  sortBy: '', // 'firstname', 'lastname', 'usergroup'
  sortDirection: 'asc', // 'desc'
  pageIndex: 0,
  pageSize: LIMIT, // gonna stay static for now
};

export const Config = {
  // adding a new entity requies:
  // 1) an apiEndpoint, an initialState configuration, etc. for it in Config here
  // 2) adding it to the initialState object below for the filterListEntities main reducer
  // 3) adding the reset saga watcher
  employees: {
    apiEndpoint: 'core/company/users',
    apiQueryParameters: { expand: 'user' },
    apiListItemsField: 'users',
    resetRoutingPath: '/employees',
    initialState: {
      ...initialStateForSingleEntity,
      sortBy: 'user.lastName'
    }
  },
  roles: {
    apiEndpoint: 'rolemapping/roles',
    apiQueryParameters: { expand: 'referenceProfile' },
    apiListItemsField: 'roles',
    resetRoutingPath: '/roles',
    initialState: {
      ...initialStateForSingleEntity,
      sortBy: 'name',
    }
  },
  roleMatching: {
    apiEndpoint: 'rolemapping/roles/{id}/matches',
    apiEndpointReplace: '{id}',
    apiQueryParameters: { expand: 'user' },
    apiQueryParametersTypeField: 'limitUserRole',
    apiListItemsField: 'matches',
    resetRoutingPath: '/roles/{id}/staffing',
    resetRoutingPathReplace: '{id}',
    initialState: {
      ...initialStateForSingleEntity,
      type: 'other',
      toggleTypes: ['other', 'same'],
      sortBy: 'score',
      sortDirection: 'desc',
    }
  },
  teams: {
    apiEndpoint: 'core/company/teams',
    apiQueryParameters: { details: 'positions' },
    apiListItemsField: 'teams',
    resetRoutingPath: '/teams',
    initialState: {
      ...initialStateForSingleEntity,
      sortBy: 'name',
    }
  },
  candidates: {
    apiEndpoint: 'recruiting/candidates/list',
    apiQueryParameters: {expand: ['user', 'jobs']},
    apiListItemsField: 'users',
    resetRoutingPath: '/candidates',
    initialState: {
      ...initialStateForSingleEntity,
      sortBy: 'user.lastName'
    }
  },
  vacancies: {
    apiEndpoint: 'recruiting/jobs',
    apiQueryParameters: {expand: 'role'},
    apiListItemsField: 'jobs',
    resetRoutingPath: '/vacancies',
    initialState: {
      ...initialStateForSingleEntity,
      sortBy: 'name'
    }
  },
  users: {
    apiEndpoint: 'core/company/users',
    apiQueryParameters: { expand: 'user', includeCandidates: true, includeDeactivated: true},
    apiListItemsField: 'users',
    resetRoutingPath: '/settings/users',
    initialState: {
      ...initialStateForSingleEntity,
      sortBy: 'user.lastName'
    }
  },
  adminUsers: {
    apiEndpoint: 'admin/users/all',
    apiListItemsField: 'users',
    resetRoutingPath: '/admin/users',
    initialState: {
      ...initialStateForSingleEntity,
      type: 'customers', // 'customers' or 'demo'
      toggleTypes: ['customers', 'demo'],
      sortBy: 'user.lastName',
    }
  }
};

export const initialState = {
  employees: { ...Config.employees.initialState },
  roles: { ...Config.roles.initialState },
  roleMatching: { ...Config.roleMatching.initialState },
  teams: { ...Config.teams.initialState },
  candidates: { ...Config.candidates.initialState },
  vacancies: { ...Config.vacancies.initialState},
  users: { ...Config.users.initialState },

  // admin
  adminUsers: { ...Config.adminUsers.initialState },
};


export const reducer = (state = initialState, action) => {
  const { entity } = action.payload || {};
  const entityState = state[entity];

  if (!entityState) return state;

  switch (action.type) {
    case fromActions.RESET: {
      return {
        ...state,
        [entity]: { ...Config[entity].initialState }
      }
    }
    case fromActions.TOGGLE_TYPE: {
      const { toggleTypes } = entityState;
      const toggleIndex = entityState.toggleIndex === 1 ? 0 : 1;
      const loading = true;
      const showLastDataWhileLoading = false;
      const type = toggleTypes[toggleIndex];
      const errors = null;

      const pageIndex = 0;
      const pageCount = 0;

      return {
        ...state,
        [entity]: {
          ...entityState,
          // ui
          loading,
          showLastDataWhileLoading,
          //search params
          type,
          toggleIndex,
          errors,
          pageIndex,
          pageCount,
        }
      };
    }
    case fromActions.GET_LIST_ITEMS: {

      const {
        dynamicConfig = entityState.dynamicConfig,
        showLastDataWhileLoading = false,
      } = action.payload;

      // reset
      const loading = true;
      const noDataAtAll = false;
      const noDataDuringCalculation = false;
      const errors = null;

      return {
        ...state,
        [entity]: {
          ...entityState,
          // ui
          loading,
          showLastDataWhileLoading,
          // results
          noDataAtAll,
          noDataDuringCalculation,
          errors,
          // dynamic config
          dynamicConfig,
        }
      };
    }
    case fromActions.GET_LIST_ITEMS_SUCCESS: {
      const { listItems, numberOfEntries, noDataAtAll, noDataDuringCalculation } = action.payload;
      const { pageSize } = entityState;

      const loading = false;
      const showLastDataWhileLoading = false;
      const firstLoad = false;
      const pageCount = getPageCount({
        numberOfEntries,
        pageSize,
      });

      return {
        ...state,
        [entity]: {
          ...entityState,
          loading,
          showLastDataWhileLoading,
          firstLoad,
          listItems,
          noDataAtAll,
          noDataDuringCalculation,
          pageCount,
        }
      };
    }
    case fromActions.GET_LIST_ITEMS_FAIL: {
      const loading = false;
      const showLastDataWhileLoading = false;
      const { errors } = action.payload;

      return {
        ...state,
        [entity]: {
          ...entityState,
          loading,
          showLastDataWhileLoading,
          errors
        }
      };
    }
    case fromActions.SET_SEARCH_STRING: {
      const { searchString } = action.payload;
      const loading = true;
      const showLastDataWhileLoading = false;
      const errors = null;
      const pageIndex = 0;
      const pageCount = 0;

      return {
        ...state,
        [entity]: {
          ...entityState,
          searchString,
          loading,
          showLastDataWhileLoading,
          errors,
          pageIndex,
          pageCount
        }
      };
    }
    case fromActions.SET_SORT: {
      const sortBy = action.payload.sortBy || entityState.sortBy;
      const sortDirection = action.payload.sortDirection || entityState.sortDirection;
      const extraSearchParams = action.payload.extraSearchParams;
      const loading = true;
      const showLastDataWhileLoading = false;
      const errors = null;
      const pageIndex = 0;
      return {
        ...state,
        [entity]: {
          ...entityState,
          loading,
          showLastDataWhileLoading,
          sortBy,
          sortDirection,
          extraSearchParams,
          errors,
          pageIndex,
        }
      };
    }
    case fromActions.SET_PAGE_INDEX: {
      const { pageIndex } = action.payload;
      const loading = true;
      const showLastDataWhileLoading = false;
      const errors = null;
      return {
        ...state,
        [entity]: {
          ...entityState,
          loading,
          showLastDataWhileLoading,
          pageIndex,
          errors
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;


export const getPageCount = (state) => {
  return Math.ceil(state.numberOfEntries / state.pageSize);
};
