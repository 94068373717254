// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './RoleMatchSummary.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {Card, Link, ProgressCircle} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: RoleMatchSummary
const RoleMatchSummary = (props) => {
  // PROPS
  const {
    children,
    matchingText,
    matchingPercentage = 0,
    links = [],
    showTopLabel = true,
    onLinkClick = () => {}
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: RoleMatchSummary
  return (
    <Card>
      <div className={styles.roleMatchSummary}>
        <div className={styles.firstBlock}>
          <div className={styles.left}>
            {showTopLabel &&
            <div className={classNames('blu-typeLabel', styles.marginBottomXs)}>{translate('employee_role_fit') || 'Rollen-Fit'}</div>
            }

            <div className='blu-typeLabel'>
              {matchingText}
            </div>

            {links.map((link, index) => {
              if (!link.clickable) {
                return <span key={index} className={styles.notLink}>{link.label}</span>
              }
              return (
                <Link
                  key={index}
                  type='anchor'
                  onClick={() => {
                    if (!link.clickable) {
                      return;
                    }
                    onLinkClick(link.id);
                  }}
                >
                  {link.label}
                </Link>
              )
            })
            }
          </div>

          <div className={styles.progress}>
            <ProgressCircle
              progress={matchingPercentage}
              size='L'
              yellowDiff={true}
              showProgress={true}
            />
          </div>
        </div>

        <div className='marginTopS'>
          {children}
        </div>

      </div>
    </Card>
  );
};

export default RoleMatchSummary;
