import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';
import {post} from 'api';

// UTILS
import { COMPANY_ID } from 'utils/configuration';
import { translate } from 'utils/translator/translator';

function* login({loginDto}) {
  //totp, authToken is for global administrators (for 2FA)
  const {mail, password, authTokenRequired, totp, authToken} = loginDto;

  try {
    yield put(action.loading());

    let loginResponse;

    if (totp && authToken) {
      loginResponse = yield call(post, '/core/user/login', {totp, authToken}, {}, { withCredentials: true });
    } else {
      loginResponse = yield call(post, '/core/user/login', {mail, password, company: COMPANY_ID}, {}, { withCredentials: true });
    }

    const { ok, status, data } = loginResponse;

    if (ok && status === 200) {

      if (data.token && !authTokenRequired) {
        // for global admins
        if (totp && authToken) {
          if (data.systemRole && data.systemRole === 'GLOBAL_ADMIN') {
            yield put(action.loginFulfilled(data));
          } else {
            yield put(action.loginRejected({error: {errorMessage: translate('admin_login_no_admin_role_error_msg')}}));
          }
          return;
        }

        // for all other users but global admins
        yield put(action.loginFulfilled(data));
        return;
      }

      if (data.token && (authTokenRequired && !data.authToken)) {
        yield put(action.loginRejected({error: {
          errorMessage: translate('admin_login_not_allowed_msg')
        }}));
        return;
      }

      if (data.authToken) {
        yield put(action.authTokenFetched(data));
      }
    } else {
      yield put(action.loginRejected(data));
    }
  } catch (error) {
    yield put(action.loginRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}
export default function* watchLoginRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.LOGIN);
      yield call(login, payload);
    } catch (error) {
      yield put(action.loginRejected(error));
    }
  }
}
