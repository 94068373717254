import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/support';
import * as fromActions from 'store/actions/support';
import {post} from 'api';

function* requestSupport({payload}) {
  try {
    const { status, ok, data } = yield call(post, '/core/platform/support', payload.supportDto);
    if (ok && status === 200) {
      yield put(fromActions.requestSupportFulfilled());
    } else {
      yield put(fromActions.requestSupportRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.requestSupportFulfilled({ error }));
  }
}

export function* watchRequestSupport() {
  yield takeEvery(fromActionTypes.REQUEST_SUPPORT, requestSupport);
}
