export const GET_INDUSTRIES = 'GET_INDUSTRIES';
export const GET_INDUSTRIES_FULFILLED = 'GET_INDUSTRIES_FULFILLED';
export const GET_INDUSTRIES_REJECTED = 'GET_INDUSTRIES_REJECTED';

export const INIT_CREATE_INSTANCE = 'INIT_CREATE_INSTANCE';
export const CREATE_INSTANCE = 'CREATE_INSTANCE';
export const CREATE_INSTANCE_FULFILLED = 'CREATE_INSTANCE_FULFILLED';
export const CREATE_INSTANCE_REJECTED = 'CREATE_INSTANCE_REJECTED';

export const INIT_GET_INSTANCES = 'INIT_GET_INSTANCES';
export const GET_INSTANCES = 'GET_INSTANCES';
export const GET_INSTANCES_FULFILLED = 'GET_INSTANCES_FULFILLED';
export const GET_INSTANCES_REJECTED = 'GET_INSTANCES_REJECTED';

export const INIT_GET_INSTANCE = 'INIT_GET_INSTANCE';
export const GET_INSTANCE = 'GET_INSTANCE';
export const GET_INSTANCE_FULFILLED = 'GET_INSTANCE_FULFILLED';
export const GET_INSTANCE_REJECTED = 'GET_INSTANCE_REJECTED';

export const INIT_GET_INSTANCE_REG_DOMAINS = 'INIT_GET_INSTANCE_REG_DOMAINS';
export const GET_INSTANCE_REG_DOMAINS = 'GET_INSTANCE_REG_DOMAINS';
export const GET_INSTANCE_REG_DOMAINS_FULFILLED = 'GET_INSTANCE_REG_DOMAINS_FULFILLED';
export const GET_INSTANCE_REG_DOMAINS_REJECTED = 'GET_INSTANCE_REG_DOMAINS_REJECTED';

export const UPDATE_INSTANCE_REG_DOMAINS = 'UPDATE_INSTANCE_REG_DOMAINS';
export const UPDATE_INSTANCE_REG_DOMAINS_FULFILLED = 'UPDATE_INSTANCE_REG_DOMAINS_FULFILLED';
export const UPDATE_INSTANCE_REG_DOMAINS_REJECTED = 'UPDATE_INSTANCE_REG_DOMAINS_REJECTED';

export const GET_USER_STATISTICS = 'GET_USER_STATISTICS';
export const GET_USER_STATISTICS_FULFILLED = 'GET_USER_STATISTICS_FULFILLED';
export const GET_USER_STATISTICS_REJECTED = 'GET_USER_STATISTICS_REJECTED';

export const INIT_REBUILD_CACHE = 'INIT_REBUILD_CACHE';
export const REBUILD_CACHE = 'REBUILD_CACHE';
export const REBUILD_CACHE_FULFILLED = 'REBUILD_CACHE_FULFILLED';
export const REBUILD_CACHE_REJECTED = 'REBUILD_CACHE_REJECTED';
