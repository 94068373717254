import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import styles from './TeamCompetenciesResult.module.scss';

import { useTranslate } from 'utils/translator';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';
import {normalizeText} from 'utils/normalizeText';
import {useWindowWidth, useDebounce, useBreakpoint} from 'utils/hooks';

import CompetenciesResultSkeleton from 'ui/molecules/CompetenciesResult/CompetenciesResultSkeleton';
import {getResultInfoDescription, getResultInfoTitle, getTranslationIds} from 'ui/molecules/CompetenciesResult/translationIds';
import {Card, InfoTitle, Modal, PillGroup} from 'ui/basic';
import SteppedScaleResultItem from '../SteppedScaleResultItem';

import {LayoutContext} from 'features/framework/components/MainLayout/MainLayout';

const DEFAULT_INFO_TITLE = 'Leadership Competencies';
const DEFAULT_INFO_DESCRIPTION =
  'Due to the constantly changing requirements in today\'s world,' +
  'the role of the manager also changes. Managers are therefore faced' +
  'with the challenge of continuously developing their skills and adapting' +
  'their leadership style to the respective context.' +
  'The selection of suitable managers in the company is also of great importance,' +
  'since the satisfaction and loyalty of your own employees can thus be significantly' +
  'influenced (positively and negatively).';


const TeamCompetenciesResult = memo((props) => {
  const {
    assessmentType = ASSESSMENT_TYPES.KEY_COMPETENCIES,
    profile,
    referenceProfile,
    showAverage = true
  } = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const translationIds = getTranslationIds(assessmentType);

  const [rowsNode, setRowsNode] = useState();
  const [rowsWidth, setRowsWidth] = useState();

  const [infoPageIsVisible, setInfoPageIsVisible] = useState(false);
  const [infoPageTitle, setInfoPageTitle] = useState();
  const [infoPageDescription, setInfoPageDescription] = useState();
  const [infoPageButtonTitle, setInfoPageButtonTitle] = useState();

  const [currentDimension, setCurrentDimension] = useState();
  const [assessmentDimensionResults, setAssessmentDimensionResults] = useState();

  const rowsRef = useCallback(node => {
    if (node) {
      setRowsNode(node);
    }
  }, []);

  // EXTERNAL EFFECTS
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);
  const { mainNavIsOpenDebounced } = useContext(LayoutContext) || {};

  useEffect(() => {
    if (rowsNode) {
      setTimeout(() => {
        const smallScreenWidth = rowsNode.clientWidth;
        const largeScreenWidth = rowsNode.clientWidth / 2;

        if (bp.isXs || bp.isS) {
          setRowsWidth(smallScreenWidth);
          return;
        }

        setRowsWidth(largeScreenWidth);
      }, 500);
    }
  }, [debouncedWindowWidth, rowsNode, mainNavIsOpenDebounced, bp]);

  useEffect(() => {
    if (!profile) {
      return;
    }

    setCurrentDimension({id: profile.results[0].id, name: profile.results[0].name})
  }, [profile, assessmentType]);

  useEffect(() => {
    if (!profile || !currentDimension) {
      return;
    }

    setAssessmentDimensionResults(profile.results.find(r => r.id === currentDimension.id));
  }, [profile, currentDimension, assessmentType]);

  const [data, setData] = useState();
  useEffect(() => {
    if (!profile || !profile.results || !currentDimension) {
      return;
    }

    const thisDimension = profile.results.find(r => r.id === currentDimension.id)
    if (!thisDimension) {
      return;
    }

    const mappedData = thisDimension.values.map(valueItem => {
      let dimensionReferenceValue;

      if (referenceProfile) {
        if (referenceProfile.results.find(r => r.dimensionId === thisDimension.id)) {
          dimensionReferenceValue = referenceProfile.results.find(r => r.dimensionId === thisDimension.id).minValue;
        } else {
          dimensionReferenceValue = referenceProfile.results.reduce((total, value) => total + value, 0) / referenceProfile.results.length
        }
      }

      return {
        id: thisDimension.id,
        name: thisDimension.name,
        result: valueItem.value,
        referenceValue: dimensionReferenceValue,
        user: {
          id: valueItem.user.id,
          name: valueItem.user.name
        },
        subDimensions: thisDimension.subDimensions.map(subDimension => {
          let subDimensionResult;
          let subDimensionUser = subDimension.values.find(vItem => (vItem.user?.id || vItem.user) === valueItem.user.id);
          if (subDimensionUser) {
            subDimensionResult = subDimensionUser.value;
          }

          let subDimensionReferenceValue;
          if (referenceProfile && referenceProfile.results) {
            subDimensionReferenceValue = referenceProfile.results.find(r => r.dimensionId === subDimension.id);
          }

          return {
            id: subDimension.id,
            name: subDimension.name,
            result: subDimensionResult,
            referenceValue: subDimensionReferenceValue && subDimensionReferenceValue.minValue
          }
        })
      }
    })

    setData(mappedData);
  }, [profile, currentDimension, referenceProfile]);

  if (!data || data.length === 0) {
    return <CompetenciesResultSkeleton />
  }

  return (
    <Card hasNoPaddings>
      <div className={styles.teamCompetenciesResult}>
        <div className={styles.mainHeader}>
          <InfoTitle
            title={translate(translationIds.resultTitle) || normalizeText(assessmentType)}
            className={styles.infoTitle}
            onClick={() => {
              setInfoPageTitle(translate(translationIds.resultInfoTitle) || DEFAULT_INFO_TITLE);
              setInfoPageDescription(translate(translationIds.resultInfoDescription) || DEFAULT_INFO_DESCRIPTION);
              setInfoPageButtonTitle(translate(translationIds.resultInfoButton) || translate('okay_lbl'));
              setInfoPageIsVisible(true);
            }}
          />

          <div
            className={styles.dimensions}
            style={{ maxWidth: `${(rowsWidth || 0) * 2}px` }}
          >
            <PillGroup
              pillItems={profile.results.map(rs => ({id: rs.id, label: rs.name}))}
              onPillClick={(pillItem) => setCurrentDimension(pillItem)}
            />
          </div>
        </div>

        <div className={styles.rowsParent}>
          <div className={styles.rows} ref={rowsRef}>
            {data.map((dimensionResult, dimensionIndex) => {
              return (
                <SteppedScaleResultItem
                  key={`dimension-${dimensionIndex}`}
                  data={dimensionResult}
                  onInfoClick={(facetIndex) => {
                    // const assessment = teamProfiles.find(teamProfile => teamProfile.assessment === assessmentType);
                    const dimensionIndex = profile.results.findIndex(r => r.id === currentDimension.id);

                    setInfoPageTitle(translate(getResultInfoTitle(assessmentType, dimensionIndex + 1, facetIndex)) || DEFAULT_INFO_TITLE);
                    setInfoPageDescription(translate(getResultInfoDescription(assessmentType, dimensionIndex + 1, facetIndex)) || DEFAULT_INFO_DESCRIPTION);
                    setInfoPageButtonTitle(translate('okay_lbl'));
                    setInfoPageIsVisible(true);
                  }}
                />
              )
            })}
          </div>
        </div>

        {showAverage &&
        <div className={styles.bottomText}>
          {translate('team_profile_average_title') || 'Teamdurchschnitt'}
          <span>{assessmentDimensionResults ? ` ${assessmentDimensionResults.average}` : ' 0'}</span>
        </div>
        }
      </div>

      {infoPageIsVisible &&
      <Modal
        header={infoPageTitle}
        secondaryButtonTitle={infoPageButtonTitle}
        onClose={() => setInfoPageIsVisible(false)}
      >
        {infoPageDescription}
      </Modal>
      }
    </Card>
  )
});

export default TeamCompetenciesResult;
