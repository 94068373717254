import * as fromActionTypes from 'store/actionTypes/admin';

export const initAdminState = () => {
  return {
    type: fromActionTypes.INIT_STATE,
    payload: {}
  };
};

export const initAddCompanyUsers = () => {
  return {
    type: fromActionTypes.INIT_ADD_COMPANY_USERS,
    payload: {}
  };
};

export const addCompanyUsers = (addCompanyUsersDto) => {
  return {
    type: fromActionTypes.ADD_COMPANY_USERS,
    payload: {addCompanyUsersDto}
  };
};

export const addCompanyUsersFulfilled = () => {
  return {
    type: fromActionTypes.ADD_COMPANY_USERS_FULFILLED,
    payload: {}
  };
};

export const addCompanyUsersRejected = ({error}) => {
  return {
    type: fromActionTypes.ADD_COMPANY_USERS_REJECTED,
    payload: {error}
  };
};

export const editCompanyUserRole = (userId, role, companyId) => {
  return {
    type: fromActionTypes.EDIT_COMPANY_USER_ROLE,
    payload: {userId, role, companyId}
  };
};

export const editCompanyUserFulfilled = () => {
  return {
    type: fromActionTypes.EDIT_COMPANY_USER_ROLE_FULFILLED,
    payload: {}
  };
};

export const editCompanyUserRejected = ({ error }) => {
  return {
    type: fromActionTypes.EDIT_COMPANY_USER_ROLE_REJECTED,
    payload: { error }
  };
};

// DELETE COMPANY USER
export const initDeleteCompanyUser = () => {
  return {
    type: fromActionTypes.INIT_DELETE_COMPANY_USER,
    payload: {}
  };
};

export const deleteCompanyUser = (userId, companyId, userName) => {
  return {
    type: fromActionTypes.DELETE_COMPANY_USER,
    payload: {userId, companyId, userName}
  };
};

export const deleteCompanyUserFulfilled = ({deletedUserId, deletedUserName}) => {
  return {
    type: fromActionTypes.DELETE_COMPANY_USER_FULFILLED,
    payload: {deletedUserId, deletedUserName}
  };
};

export const deleteCompanyUserRejected = ({ error }) => {
  return {
    type: fromActionTypes.DELETE_COMPANY_USER_REJECTED,
    payload: { error }
  };
};

// REMOVE COMPANY USER
export const initRemoveCompanyUser = () => {
  return {
    type: fromActionTypes.INIT_REMOVE_COMPANY_USER,
    payload: {}
  };
};

export const removeCompanyUser = (userId, companyId, userName) => {
  return {
    type: fromActionTypes.REMOVE_COMPANY_USER,
    payload: {userId, companyId, userName}
  };
};

export const removeCompanyUserFulfilled = ({removedUserId, removedUserName}) => {
  return {
    type: fromActionTypes.REMOVE_COMPANY_USER_FULFILLED,
    payload: {removedUserId, removedUserName}
  };
};

export const removeCompanyUserRejected = ({ error }) => {
  return {
    type: fromActionTypes.REMOVE_COMPANY_USER_REJECTED,
    payload: { error }
  };
};
