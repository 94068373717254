import React, { useEffect, useState } from 'react';

import styles from 'styles-imports/shared.scss';

// transitions
import fadeTransitions from './fade.module.scss';

// 3RD PARTY
import { CSSTransition } from 'react-transition-group';

/**
 * BluCSSTransition
 * extends the functionality of react-transition-group's CSSTransition
 *  - it wires the global animationDurationMs from _animations.scss to the transition
 *  - it applies mountOnEnter, unMountOnExit per default
 * @param {*} props
 */

const BluCSSTransition = (props) => {
  const { classNames, timeout, type = 'fade' } = props;

  const [ classNamesInternal, setClassNamesInternal ] = useState();

  useEffect(() => {
    let classNames;

    switch (type) {
      case 'fade': {
        classNames = { ...fadeTransitions };
        break;
      }
      default: {

      }
    }

    setClassNamesInternal(classNames)
  }, [type]);

  return (
    <CSSTransition
      in={props.in}
      classNames={classNames || classNamesInternal}
      timeout={isNaN(timeout) ? Number(styles.animationDurationMs) : timeout}
      mountOnEnter
      unmountOnExit>
        {props.children}
    </CSSTransition>
  );
};

export default BluCSSTransition;
