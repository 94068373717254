// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import styles from './NineLevelsRegistration.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentPageWithFooter } from '../AssessmentPageWithFooter';
import { DropDown, InputNext, Checkbox, Modal } from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { LANGUAGES } from 'utils/configuration/const/languages';
import { getTranslationIds } from '../../AssessmentNext.translations';


// CONFIG & DATA
import { nineLevelsConfig } from '../../config';
const CONFIG = {
  minAge: 12,
  maxAge: 99,
};
const LEGAL_TEXT_IDS = {
  DATA_PROTECTION: 'dataprotection',
  DATA_PROCESSING: 'dataprocessing',
  COPYRIGHT_AGREEMENT: 'copyrightagreement'
};


// COMPONENT: NineLevelsRegistration
const NineLevelsRegistration = (props) => {
  // PROPS
  const {
    // all mandatory
    assessmentDetails,
    extras,
    clickBlock,
    onClickNext = () => {}
  } = props;

  const translate = useTranslate();

  const { currentLanguage, registrationSchema } = assessmentDetails;
  const { loadingDuring, setLoadingDuring, addPages } = extras;

  const translationIds = getTranslationIds('nineLevels');
  const assessmentType = '9levels';

  // COMPONENT/UI STATE and REFS

  // form
  const [ language, setLanguage ] = useState(currentLanguage === LANGUAGES.EN
    ? registrationSchema.language.options.find(option => option.value === 'EN')
    : registrationSchema.language.options.find(option => option.value === 'DE')
  );
  const [ gender, setGender ] = useState();
  const [ age, setAge ] = useState();
  const [ ageErrorMessage, setAgeErrorMessage ] = useState();
  const [ country, setCountry ] = useState();
  const [ branch, setBranch ] = useState();

  const [ myFunction, setMyFunction ] = useState();
  const [ careerLevel, setCareerLevel ] = useState();
  const [ companySize, setCompanySize ] = useState();
  const [ inCompanySince, setInCompanySince ] = useState();

  const [ personalDataChecked, setPersonalDataChecked ] = useState();

  // ui
  const [ currentYear ] = useState(new Date().getFullYear());
  const [ modalVisible, setModalVisible ] = useState(false);
  const [ modalHeader, setModalHeader ] = useState('');
  const [ modalContent, setModalContent ] = useState('');

  // request
  const [ request, setRequest ] = useState();

  // SPECIAL HOOKS


  // METHODS
  const isFormValid = useCallback(() => {
    return language && gender && age && country && personalDataChecked;
  }, [language, gender, age, country, personalDataChecked]);
  const loadQuestions = useCallback((assessmentDetails) => {
    // make sure there is only one question request
    if (request) {
      return request;
    }

    setLoadingDuring(true);
    const questionRequest = api.get('/core/assessments/9levels/1/questions')
    .then(({ ok, status, data }) => {
      console.log('set loading false');
      setTimeout(() => {
        setLoadingDuring(false);
      }, 300); // wait till page change animation has completed


      if (ok && status === 200) {
        const stage1Questions = data.questions
        .map((question, index) => {
          const questionType = `${question.type}-${question.representation}`;
          return {
            stageNumber: 1,
            questionType,
            ...question,
            id: question.id || `${questionType}-stage1-${index}`
          };
        });

        // if questions array is empty that means stage has already been completed
        if (stage1Questions && stage1Questions.length === 0) {
          // call next three times to skip guidance and breathe animation too
          onClickNext();
          return;
        }

        // add questions

        // placeholder count for remaining stages
        const placeholderCount = assessmentDetails.stages.slice(1)
        .reduce((acc, current) => acc + current.length, 0);

        // construct placeholder for remaining stages
        // so progress is calculated across all stages from beginning
        const placeholderQuestions = new Array(placeholderCount).fill(placeholderCount)
        .map(() => ({
          isIntermission: true,
          countAsProgress: true,
          isPlaceholder: true,
          render: () => {},
        }));

        // intermissions
        const intermissions = [ ...(nineLevelsConfig.intermissions || [])];
        // get breathe intermission
        const breathe = intermissions[3];
        breathe.isIntermission = true;
        // get stage2 intermission
        const stage2 = intermissions[4];
        stage2.isIntermission = true;
        const questionsWithPlaceholders = [
          ...stage1Questions,
          // breathe should bet at 80%
          breathe,
          stage2,
          ...placeholderQuestions,
        ];

        console.log(stage1Questions);
        console.log(breathe);
        console.log(placeholderQuestions);
        console.log(questionsWithPlaceholders);

        addPages({
          pages: questionsWithPlaceholders,
          insertAtIndex: 3,
          replace: true
        });

        onClickNext();
      }
    });

    setRequest(questionRequest);
    return questionRequest;

  }, [setLoadingDuring, onClickNext, addPages, request]);


  // HOOK: ALREADY REGISTERED
  useEffect(() => {
    if (!assessmentDetails || clickBlock) {
      return;
    }
    // check: already registered
    if (assessmentDetails.registered) {
      console.log('registered: loadQuestions');
      loadQuestions(assessmentDetails);
    }
  }, [assessmentDetails, loadQuestions, clickBlock]);

  // EVENT HANDLES
  const handleRegister = useCallback(() => {
    setLoadingDuring(true);

    if (!isFormValid()) {
      setLoadingDuring(false);
      return;
    }

    const requestData = {
      // required
      language: language.value,
      gender: gender.value,
      yob: currentYear - Number(age), // 9levels needs yob
      region: country.value,
      // optional
      position: myFunction,
      career: careerLevel,
      industry: branch,
      company_size: companySize ? Number(companySize) : undefined,
      company_years: inCompanySince ? Number(inCompanySince) : undefined,
    };

    console.log(requestData);

    api.post('/core/assessments/9levels/register', requestData)
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        return loadQuestions(assessmentDetails);
      }
    })
    .catch((error) => {
      console.log(error);
      setLoadingDuring(false);
    })
  }, [
    isFormValid, language, gender, currentYear, age, country, myFunction,
    careerLevel, branch, companySize, inCompanySince, assessmentDetails,
    loadQuestions, setLoadingDuring
  ]);
  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // NUMBER CONTROLS
    if (event.key === 'Enter') {
      event.preventDefault();
      handleRegister();
    }
  }, [handleRegister]);
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  // HELPERS

  const PERSONAL_DATA_PROCESSING =
    translate('rmp_ass_form_cm_label_dataprocessing') ||
    'Ich stimme der {{link.Verarbeitung meiner persönlichen Daten}} zu Nutzungszwecken zu.';
  const getParsedText = (text, id) => {
  const joinedText = [];
  const part1 = text.split(/{{link./);
  if (part1.length > 1) {
    joinedText.push(part1[0]);
    joinedText.push(
      <span
        key={id}
        className='blu-link'
        onClick={() => {
          setModalHeader(translate(`${assessmentType}_ass_form_info_title_${id}`) || 'Header');
          setModalContent(translate(`${assessmentType}_ass_form_info_description_${id}`) || 'Content');
          setModalVisible(true);
        }}
      >
        {part1[1].split('}}')[0]}
      </span>
    );
    joinedText.push(part1[1].split('}}')[1]);
  } else {
    joinedText.push(text);
  }
  return joinedText;
};


  // RENDERS

  // RENDER: NineLevelsRegistration
  return (
    <div className={classNames(styles.nineLevelsRegistration, {
      [styles.hidden]: loadingDuring
    })}>

      <AssessmentPageWithFooter
        copyrightShort={translate('9levels_ass_copyrightlink') || 'Copyright Statement'}
        copyrightDescriptionHeader={translate('9levels_ass_copyrightlink') || 'Copyright Header'}
        copyrightDescriptionContent={translate('9levels_ass_info_description_copyright') || 'Copyright Content'}
        nextButtonText={translate(translationIds.introContinue) || 'Weiter'}
        onClickNext={isFormValid() && !loadingDuring && handleRegister}
      >

        {/* TITLE */}
        <div className={classNames(styles.title, 'blu-typeXxs')}>
          {translate('rmp_ass_form_title_info') || 'Bitte vervollständige deine Informationen.'}
        </div>

        {/* LANGUAGE */}
        <div className={styles.twoColumns}>
          <div className={styles.column}>
            {translate('rmp_ass_form_label_choose_lang') || 'Assessmentsprache wählen'}

            {/* DROPDOWN */}
            <div className={styles.language}>
              <DropDown
                placeholder={translate('rmp_ass_form_language_label') || 'Choose language'}
                options={registrationSchema.language.options}
                optionLabel='name'
                optionValue='value'
                selectedOption={language}
                onChange={(option) => setLanguage(option)}
              />
            </div>
          </div>
        </div>

        {/* STATISTICS */}
        <div className={styles.statistics}>
          <div className='blu-typeLabel'>
            {translate('rmp_ass_form_subtitle_statistical_info') || 'Statistische Informationen'}
          </div>
          <div className={styles.paragraph}>
            {translate('rmp_ass_form_description_statistical_info') ||
            'Die nachfolgenden Informationen werden zur Auswertung deines Assessments benötigt.'
            }
          </div>

          {/* FORM */}
          <div className={styles.twoColumns}>

            {/* LEFT COLUMN */}
            <div className={styles.column}>
              {/* GENDER */}
              <div className={styles.formElement}>
                <DropDown
                  placeholder={translate('rmp_ass_form_gender_placeholder') || 'Geschlecht*'}
                  options={registrationSchema.gender.options}
                  optionLabel='name'
                  optionValue='value'
                  selectedOption={gender}
                  onChange={(option) => setGender(option)}
                />
              </div>
              {/* AGE */}
              <div className={styles.formElement}>
                <InputNext
                  type='number'
                  label={translate('assessment_registration_age_placeholder') || 'Alter*'}
                  value={age}
                  errorMessage={ageErrorMessage}
                  onChange={(value) => {
                    if (value > CONFIG.maxAge || value < CONFIG.minAge) {
                      setAge();
                      setAgeErrorMessage(translate('ass_registration_invalid_age_msg') || 'Please provide your real age');
                    } else {
                      setAge(value);
                      setAgeErrorMessage();
                    }
                  }}
                />
              </div>
              {/* RESIDENCY */}
              <div className={styles.formElement}>
                <DropDown
                  placeholder={translate('rmp_ass_form_country_placeholder') || 'Wohnsitz*'}
                  options={registrationSchema.region.options}
                  optionLabel='name'
                  optionValue='value'
                  selectedOption={country}
                  onChange={(option) => setCountry(option)}
                />
              </div>
              {/* INDUSTRY */}
              <div className={styles.formElement}>
                <InputNext
                  label={translate('rmp_ass_form_branches_placeholder') || 'Branche'}
                  value={branch}
                  onChange={(value) => setBranch(value)}
                />
              </div>
            </div>

            {/* RIGHT COLUMN */}
            <div className={styles.column}>
              {/* FUNCTION */}
              <div className={styles.formElement}>
                <InputNext
                  label={translate('rmp_ass_form_function_placeholder') || 'Funktion'}
                  value={myFunction}
                  onChange={(value) => setMyFunction(value)}
                />
              </div>
              {/* CAREER LEVEL */}
              <div className={styles.formElement}>
                <InputNext
                  label={translate('rmp_ass_form_careerlevel_placeholder') || 'Karrierestufe'}
                  value={careerLevel}
                  onChange={(value) => setCareerLevel(value)}
                />
              </div>
              {/* SIZE OF COMPANY */}
              <div className={styles.formElement}>
                <InputNext
                  type='number'
                  label={translate('rmp_ass_form_companysize_placeholder') || 'Größe des Unternehmens'}
                  value={companySize}
                  onChange={(value) => setCompanySize(value)}
                />
              </div>
              {/* YEARS IN COMPANY */}
              <div className={styles.formElement}>
                <InputNext
                  type='number'
                  label={translate('rmp_ass_form_companyyears_placeholder') || 'Jahre im Unternehmen'}
                  value={inCompanySince}
                  onChange={(value) => setInCompanySince(value)}
                />
              </div>

            </div>
          </div>

          {translate('rmp_ass_form_hint_required_info') || '* Pflichtfelder'}

          {/* LEGAL */}
          <div className={styles.legal}>
            <Checkbox
              name={getParsedText(PERSONAL_DATA_PROCESSING, LEGAL_TEXT_IDS.DATA_PROCESSING)}
              onChange={value => setPersonalDataChecked(value)}
            />
          </div>

          {/* MODAL */}
          {modalVisible && (
            <Modal
              header={modalHeader}
              secondaryButtonTitle={translate('okay_lbl')}
              onClose={() => setModalVisible(false)}
            >
              {modalContent}
            </Modal>
          )}
        </div>
      </AssessmentPageWithFooter>
    </div>
  );
};

export default NineLevelsRegistration;
