import * as fromActionTypes from 'features/+admin/store/actionTypes/planOverview';

const initialState = {
  plans: [],
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.GET_PLANS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_PLANS_FULFILLED: {
      const { plans } = action.payload;

      return {
        ...state,
        plans,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_PLANS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    default: {
      return state;
    }
  }
};

