import React, { memo } from 'react';
import * as css from 'styles-imports/shared.scss';

const GREY_CIRCLE_DIAMETER = 3;

const LineRangeDiagram = memo((props) => {
  const {range, width, min, max} = props;
  const innerWidth = width - GREY_CIRCLE_DIAMETER;
  const viewBox =`0 0 ${width} 15`;
  const step = (innerWidth / (max - 1));
  let start = 0;
  let [rangeFrom, rangeTo] = range;
  let circles = [];

  const getCircles = () => {
    for (let i=min; i<=max; i++) {
      circles.push(
        <rect key={`index-${i}`} x={start} y="6" width={GREY_CIRCLE_DIAMETER} height="3" rx="1.5" fill={css.colorGrey3}/>
      );
      start += step;
    }
    return circles;
  };

  return (
    <svg width={width} height="15" viewBox={viewBox}>
      <rect
        x={(rangeFrom - 1) * step} //-1 to start from 0
        width={(rangeTo - rangeFrom) * step + GREY_CIRCLE_DIAMETER}
        height="15"
        rx="8"
        fill={css.colorPrimary3}
      />
      <rect x={start} y='7' width={innerWidth} height="1" fill={css.colorGrey3}/>
      {getCircles()}
    </svg>
  );
});

LineRangeDiagram.defaultProps = {
  width: 250,
  range: [1, 5],
  min: 1,
  max: 10
};

export default LineRangeDiagram;
