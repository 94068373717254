import React, {useState, useCallback, useEffect, useRef} from 'react';
import styles from './WellbeingDiagram.module.scss';

/* OTHER COMPONENTS */
import { Scrollable } from 'ui/basic/containers/Scrollable';

/* 3RD PARTY */
import {useSelector} from 'react-redux';
import * as moment from 'moment';
import classNames from 'classnames';
import 'moment/locale/de';

/* ASSETS */
import HappyImage from 'assets/well-being/very-happy.svg';
import SadImage from 'assets/well-being/very-sad.svg';

// UTILS
import { useDebounce, useWindowWidth } from 'utils/hooks';
import {WELL_BEING_MODES} from 'utils/configuration/const/well-being';
import {LANGUAGES} from 'utils/configuration/const/languages';


/* CONFIG */
const CHART_HEIGHT = 180;
const CELL_HEIGHT = 34;
const VALUE_RANGE = 5;
const CELLS_PER_PAGE = 6;
const SMALL_CIRCLE_R = 2.5;
const BIG_CIRCLE_R = 22;


const WellbeingDiagram = (props) => {
  const { points, dates, mode, infoModalIsOpen, onClick, onPaginationAvailabilities } = props;

  // STATE, REFS
  const [ selectedDate, setSelectedDate ] = useState();

  // dom state
  const [ clientWidth, setClientWidth ] = useState();

  // config
  const [ xOffset, setXOffset ] = useState(0);

  // chart state
  const [ cellWidth, setCellWidth ] = useState();
  const [ chartWidth, setChartWidth ] = useState();
  const [ pageCount, setPageCount ] = useState(0);

  // scrollable state
  const scrollableRef = useRef(null);

  // update clientWidth on svg node change
  const [ svgNode, setSvgNode ] = useState();
  const componentRef = useCallback(node => {
    if (node) {
      setSvgNode(node);
    }
  }, []);

  // EFFECT HOOKS
  // update clientWidth on windowWidth change
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);
  useEffect(() => {
    if (svgNode) {
      updateDOMDerivedVars(svgNode);
    }
    // eslint-disable-next-line
  }, [debouncedWindowWidth, svgNode, points, dates]);


  // STORE
  const currentLanguage = useSelector(
    state => state.localisation && state.localisation.currentLanguage
  );

  // HELPERS
  const updateDOMDerivedVars = (node) => {

    const clientWidth = node.clientWidth;
    setClientWidth(clientWidth);
    const cellWidth = clientWidth / CELLS_PER_PAGE;
    setCellWidth(cellWidth);
    const pageCount = Math.max(
      Math.ceil((cellWidth * points.length - 1) / clientWidth),
      1
    );
    setPageCount(pageCount);
    const chartWidth = Math.ceil(pageCount * clientWidth);
    setChartWidth(chartWidth);
    const xOffset = cellWidth / 2;
    setXOffset(xOffset);

    // console.log(`[WellbeingDiagram] Dom vars: clientWidth ${clientWidth}, cellWidth ${cellWidth}, chartWidth ${chartWidth}, pageCount ${pageCount}`);
  };
  const getDateText = (date) => {
    if (mode === WELL_BEING_MODES.MONTHLY) {
      const m = moment(date);
      m.locale(currentLanguage === LANGUAGES.DE ? 'de' : 'en');

      return m.format('MMM');
    }

    if (mode === WELL_BEING_MODES.WEEKLY) {
      return date;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const d = date;
    d.setHours(0, 0, 0, 0);

    if (today.getTime() === d.getTime()) {
      // return translate('wellbeing_ind_result_today') || 'heute';
      return 'today';
    }
    //+1 because getMonth starts with 0;
    return `${date.getDate()}.${date.getMonth() + 1}.`;
  };

  // RENDERS
  // VERTICAL LINES
  const renderVerticalLines = () => {

    return new Array(pageCount * CELLS_PER_PAGE).fill(1)
    .map((item, index) => {
      const x = xOffset + cellWidth * index;
      const y1 = 0;
      const y2 = CHART_HEIGHT;
      return (
        <line
          key={`verticalLine ${index}`}
          className={styles.verticalLine}
          x1={x}
          y1={y1}
          x2={x}
          y2={y2}
        />
      );
    });
  };

  // HORIZONTAL LINES
  const renderHorizontalLines = () => {

    return new Array(VALUE_RANGE + 1).fill(1)
    .map((item, index) => {

      const x1 = 0;
      const x2 = clientWidth * pageCount;
      const y = CELL_HEIGHT * index;
      return (
        <line key={`horizontalLine ${index}`}
          className={styles.horizontalLine}
          x1={x1}
          y1={y}
          x2={x2}
          y2={y}
        />
      );
    })
  };

  // POLYGON BLANK
  const renderPolygonBlank = () => {
    const height = CELL_HEIGHT * VALUE_RANGE;
    const width = cellWidth / 2;
    return (
      <polygon className={styles.polygonBlank}
        points={`0 0 ${width} 0 ${width} ${height} 0 ${height}`}/>
    )
  };

  // POLYGON BLUE
  const renderPolygon = () => {
    const polygonPoints = [];

    points.map((point, index) => {
      const x = xOffset + index * cellWidth;
      const y = (CELL_HEIGHT * (VALUE_RANGE - point));

      polygonPoints.push(x);
      polygonPoints.push(y);
      return [x, y]; // we don't actually do anything with it
    });

    // CLOSING POLYGON POINTS
    polygonPoints.push(xOffset + (points.length - 1) * cellWidth);
    polygonPoints.push(CELL_HEIGHT * VALUE_RANGE);

    polygonPoints.push(polygonPoints[0]);
    polygonPoints.push(CELL_HEIGHT * VALUE_RANGE);

    return (
      <polygon className={styles.polygon}
        points={polygonPoints.join(' ')}
      />
    )
  };



  // DATA LINES
  const renderDataLines = () => {

    return points.map((point, index) => {

      const x1 = xOffset + cellWidth * index;
      const y1 = (CELL_HEIGHT * (VALUE_RANGE - point));
      const x2 = xOffset + cellWidth * (index + 1);
      const y2 = CELL_HEIGHT * (VALUE_RANGE - points[index + 1]);

      // console.log('x1, clientWidth, index', x1, clientWidth, index)

      return (
        (index < (points.length - 1)) && // no line for last point
          <line key={`valueLine ${index}`}
            className={styles.valueLine}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
          />
      );
    })
  }

  // DATA POINTS
  const renderDataPoints = () => {

    return points.map((point, index) => {
      const y = (CELL_HEIGHT * (VALUE_RANGE - point));
      const x = xOffset + index * cellWidth;

      return (
        <g key={`point ${index}`}>
          <circle
            key={`bigCircle ${index}`}
            cx={x}
            cy={y}
            r={BIG_CIRCLE_R}
            className={classNames(styles.bigCircle, {[styles.active]: infoModalIsOpen && selectedDate === dates[index]})}
            onClick={() => {
              setSelectedDate(dates[index]);
              onClick && onClick(dates[index], point, index);
            }}
          />
          <circle
            key={`smallCircle ${index}`}
            cx={x}
            cy={y}
            r={SMALL_CIRCLE_R}
            className={classNames(styles.smallCircle, {[styles.active]: infoModalIsOpen && selectedDate === dates[index]})}
            onClick={() => {
              setSelectedDate(dates[index]);
              onClick && onClick(dates[index], point, index);
            }}
          />
        </g>
      );

    });
  }

  // LABELS
  const renderLabels = () => {

    const labels = new Array(pageCount * CELLS_PER_PAGE).fill(1)
    .map((point, index) => {
      const date = dates[index];
      return (
        <div key={`text ${index}`} style={{ width: cellWidth }}>
          {(date && getDateText(dates[index]))}
        </div>
      );
    });

    return (
      <div className={styles.labels}>
        {labels}
      </div>
    );
  }

  // RENDER
  return (
    <div ref={componentRef} className={styles.wellbeingDiagram}>

      {/* don't render anything before we have clientWidth */}
      {clientWidth &&
        <>
          <Scrollable
            ref={scrollableRef}
            chartMargins={cellWidth / 2}
            startRight
            pagination drag
            showPaginationBubbles
            showPagerButtons
            refresh={pageCount}
            reset={mode}
            onPaginationAvailabilities={onPaginationAvailabilities}
            >

            {/* CHARTS */}
            <div> {/* scrollable works better with only one child */}
              <svg className={styles.chart}
                width={chartWidth} height={CHART_HEIGHT}>
                {renderPolygonBlank()}
                {renderPolygon()}
                {renderHorizontalLines()}
                {renderVerticalLines()}
                {renderDataLines()}
                {renderDataPoints()}
              </svg>

              {/* LABELS */}
              {renderLabels()}
            </div>
          </Scrollable>
          <svg className={styles.chartFrame}>
            {/* SMILEYS */}
            <image x={cellWidth / 4 - 10} y="31" width="20" height="20" xlinkHref={HappyImage} />
            <image x={cellWidth / 4 - 10} y={CHART_HEIGHT - 12} width="20" height="20" xlinkHref={SadImage} />
          </svg>
        </>
      }
    </div>
  )
};

export default WellbeingDiagram;
