import * as fromActionTypes from 'store/actionTypes/assessments';

export const INIT_SAVE_ASSESSMENT_ANSWERS = 'INIT_SAVE_ASSESSMENT_ANSWERS';
export const SAVE_ASSESSMENT_ANSWERS = 'SAVE_ASSESSMENT_ANSWERS';
export const SAVE_ASSESSMENT_ANSWERS_PENDING = 'SAVE_ASSESSMENT_ANSWERS_PENDING';
export const SAVE_ASSESSMENT_ANSWERS_FULFILLED = 'SAVE_ASSESSMENT_ANSWERS_FULFILLED';
export const SAVE_ASSESSMENT_ANSWERS_REJECTED = 'SAVE_ASSESSMENT_ANSWERS_REJECTED';

export const initSaveAssessmentAnswers = () => {
  return {
    type: INIT_SAVE_ASSESSMENT_ANSWERS,
    payload: {}
  };
};

export const saveAssessmentAnswers = ({assessmentId, stage, answers}) => {
  return {
    type: SAVE_ASSESSMENT_ANSWERS,
    payload: {assessmentId, stage, answers}
  };
};

export const saveAssessmentAnswersFulfilled = (result) => {
  return {
    type: SAVE_ASSESSMENT_ANSWERS_FULFILLED,
    payload: result
  };
};
export const saveAssessmentAnswersRejected = ({ error }) => {
  return {
    type: SAVE_ASSESSMENT_ANSWERS_REJECTED,
    payload: { error }
  };
};

export const saveAssessmentAnswer = ({assessmentId, stage, answer}) => {
  return {
    type: fromActionTypes.SAVE_ASSESSMENT_ANSWER,
    payload: {assessmentId, stage, answer}
  };
};

export const saveAssessmentAnswerFulfilled = (result) => {
  return {
    type: fromActionTypes.SAVE_ASSESSMENT_ANSWER_FULFILLED,
    payload: result
  };
};
export const saveAssessmentAnswerRejected = ({ error }) => {
  return {
    type: fromActionTypes.SAVE_ASSESSMENT_ANSWER_REJECTED,
    payload: { error }
  };
};
