// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
// import styles from './CustomAssessmentPage.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import { AssessmentNext } from 'ui/molecules/AssessmentNext';


// UTILS
import {
  initialiseAssessment,
  handleAssessmentAnswer,
} from 'utils/assessment';


// STORE
import {useDispatch, useSelector} from 'react-redux';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import {listAssessments} from 'store/actions';

// CONFIG & DATA
// const Config = {};

// COMPONENT: CustomAssessmentPage
const CustomAssessmentPage = (props) => {
  // PROPS
  // const { children } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  // STORE
  const userId = useSelector(fromCurrentUserSelectors.getCurrentUserId);

  // GET ASSESSMENT DATA
  const [ loadingAssessment, setLoadingAssessment ] = useState(false);
  const [ assessmentId, setAssessmentId ] = useState();
  const [ questions, setQuestions ] = useState();
  const [ configOverride, setConfigOverride ] = useState();

  // get assessment
  useEffect(() => {
    const assessmentId = params.customAssessmentId;

    if (!userId || !assessmentId || loadingAssessment) {
      return;
    }

    setAssessmentId(assessmentId);
    setLoadingAssessment(true);

    initialiseAssessment({
      userId,
      assessmentId,
      handleExit: () => {
        history.push('/');
      }
    })
    .then(({ questions, assessment, prevAnswers }) => {

      setConfigOverride({

        customAssessmentId: assessment.id,
        title: assessment.title,
        // description: assessment.description,
        description: assessment.info_texts.long_description,

        allowBackNavigation: assessment.free_navigation,
        // canContinueLater: true,
        // modalHurryDelay: 5 * 1000,
        canContinueLater: assessment.multi_session,
        modalHurryDelay: assessment.time_to_reminder * 1000,

        // modalHelpContent: '[blu-markdown] **modal help content**',
        // guidanceContent: '[blu-markdown] **guidance content**',
        modalHelpContent: assessment.info_texts.question_help,
        guidanceContent: assessment.info_texts.guidance_text,

        progress: assessment.progress,
        prevAnswers,

      });

      // activate answer options
      questions.forEach(question => { question.useAnswerOptions = true });
      // questions.forEach(question => { question.useAnswerOptions = true; question.answerOptions = [ { value: 1, label: 'big ass 1' }, { value: 2, label: 'big ass 2' },  { value: 3, label: 'big ass 3' }, { value: 4, label: 'big ass 4' }] })

      setQuestions(questions);

    });

  }, [params, history, loadingAssessment, userId]);

  // METHODS


  // HANDLES
  const handleCancel = () => {
    dispatch(listAssessments());

    history.goBack();
  };

  const handleFinish = () => {
    history.push('/my-profile');
  };

  const handleAnswer = (answer) => {
    handleAssessmentAnswer(answer, assessmentId);
  };

  const handleAllAnswers = (answers, setFinishable, setErrorEnd) => {
    setFinishable(true);
    dispatch(listAssessments());
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: CustomAssessmentPage
  return (
    <AssessmentNext
      type='custom'
      questions={questions}
      userId={userId}
      configOverride={configOverride}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer}
      onAllAnswers={handleAllAnswers}
    />
  );
};

export default CustomAssessmentPage;
