import {
  INIT_UPDATE_USER_SETTINGS,
  UPDATE_COMPANY_SETTINGS,
  UPDATE_COMPANY_SETTINGS_FULFILLED,
  UPDATE_COMPANY_SETTINGS_REJECTED,
  UPDATE_REGISTRATION_DOMAINS,
  UPDATE_REGISTRATION_DOMAINS_FULFILLED,
  UPDATE_REGISTRATION_DOMAINS_REJECTED,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_FULFILLED,
  UPDATE_SETTINGS_REJECTED,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_FULFILLED,
  UPDATE_USER_SETTINGS_REJECTED
} from 'store/actionTypes/settings';

export const updateSettings = (settingsDto) => {
  return {
    type: UPDATE_SETTINGS,
    payload: settingsDto
  };
};

export const updateSettingsFulfilled = (data) => {
  return {
    type: UPDATE_SETTINGS_FULFILLED,
    payload: data
  }
};

export const updateSettingsRejected = ({ error }) => {
  return {
    type: UPDATE_SETTINGS_REJECTED,
    payload: { error }
  };
};

// UPDATE REGISTRATION DOMAINS
export const updateRegistrationDomains = (domains) => {
  return {
    type: UPDATE_REGISTRATION_DOMAINS,
    payload: {domains}
  };
};

export const updateRegistrationDomainsFulfilled = () => {
  return {
    type: UPDATE_REGISTRATION_DOMAINS_FULFILLED,
    payload: {}
  }
};

export const updateRegistrationDomainsRejected = ({ error }) => {
  return {
    type: UPDATE_REGISTRATION_DOMAINS_REJECTED,
    payload: { error }
  };
};

// UPDATE USER SETTINGS
export const initUpdateUserSettings = () => {
  return {
    type: INIT_UPDATE_USER_SETTINGS,
    payload: {}
  };
};


export const updateUserSettings = (userSettingsDto, showToast = true) => ({
  type: UPDATE_USER_SETTINGS,
  payload: {
    userSettingsDto,
    showToast,
  },
});

export const updateUserSettingsFulfilled = (languageChanged) => {
  return {
    type: UPDATE_USER_SETTINGS_FULFILLED,
    payload: {languageChanged}
  }
};

export const updateUserSettingsRejected = ({ error }) => {
  return {
    type: UPDATE_USER_SETTINGS_REJECTED,
    payload: { error }
  };
};

// UPDATE COMPANY SETTINGS
export const updateCompanySettings = (companySettingsDto, companyId) => {
  return {
    type: UPDATE_COMPANY_SETTINGS,
    payload: {companySettingsDto, companyId}
  };
};

export const updateCompanySettingsFulfilled = () => {
  return {
    type: UPDATE_COMPANY_SETTINGS_FULFILLED,
    payload: {}
  }
};

export const updateCompanySettingsRejected = ({ error }) => {
  return {
    type: UPDATE_COMPANY_SETTINGS_REJECTED,
    payload: { error }
  };
};
