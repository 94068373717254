// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useCallback } from 'react';
import styles from './QuestionLocation.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext, DropDownSearchable } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import {useSelector} from 'react-redux';
import * as fromSelectors from 'store/selectors/staticValues';

// CONFIG & DATA
const preSelectedValue = 'de';

// COMPONENT: QuestionLocation
const QuestionLocation = (props) => {

  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const checkAnswers = useCallback(({ country, city }) => {

    const isValid = !!country;
    const answer = { country, city, isValid };

    // console.log('newAnswer', answer);

    onAnswer(answer);
  }, [onAnswer]);

  // COMPONENT/UI STATE and REFS
  const countries = useSelector(fromSelectors.selectCountries);

  const [ country, setCountry ] = useState(preSelectedValue);
  const [ city, setCity ] = useState('');
  useEffect(() => {
    if (!answer.country) {
      checkAnswers({ country: preSelectedValue });
    }
    else {
      setCountry(answer.country);
    }
  }, [answer.country, checkAnswers]);
  useEffect(() => {
    setCity(answer.city);
  }, [answer.city]);
  // useEffect(() => {
  //   console.log('answer', answer);
  // }, [answer]);

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleCountry = (country) => {
    setCountry(country);
    checkAnswers({country, city});
  };

  const handleCity = (city) => {
    setCity(city);
    checkAnswers({ country, city });
  };

  // HELPERS

  // RENDERS

  // RENDER: QuestionLocation
  return (
    <div className={classNames(styles.questionLocation)}>

      {/* FORM */}
      <div className={styles.formRow}>
        <DropDownSearchable
          size={size}
          placeholder={translate('please_select_lbl') || 'Bitte wählen'}
          filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
          activeValue={answer.country || preSelectedValue}
          options={countries}
          onChange={(option) => {
            handleCountry(option?.value ?? '');
          }}
        />
      </div>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={answer.city}
          label={translate('cp_city_placeholder') || 'Stadt (optional)'}
          onChange={handleCity}>
        </InputNext>
      </div>

    </div>
  );
};

export default QuestionLocation;
