import * as action from 'store/actions';
import * as fromStaticValueActions from 'store/actions/staticValues';
import * as localStorage from 'utils/localStorage';
import { call, put, take } from 'redux-saga/effects';

function* changeLanguage(payload) {
  try {
    const { language, staticValuesToRefetch = ''} = payload;

    localStorage.setItem('selectedLanguage', language);
    yield put(action.getTranslations(language));
    yield put(action.changeLanguageFulfilled(language));

    if (staticValuesToRefetch.includes('careerLevels')) {
      yield put(fromStaticValueActions.getCareerLevels());
    }

    if (staticValuesToRefetch.includes('jobFamilies')) {
      yield put(fromStaticValueActions.getJobFamilies());
    }

    if (staticValuesToRefetch === 'all') {
      yield put(fromStaticValueActions.getCountries());
      yield put(fromStaticValueActions.getEducation());
      yield put(fromStaticValueActions.getIndustries());
      yield put(fromStaticValueActions.getJobFamilies());
    }
  } catch (error) {
    yield put(action.changeLanguageRejected(error));
  }
}

export default function* watchChangeLanguageRequest(){
  while (true) {
    try {
      const { payload } =yield take(action.CHANGE_LANGUAGE);
      yield call(changeLanguage,payload);
    } catch (error) {
      yield put(action.changeLanguageRejected(error));
    }
  }
}
