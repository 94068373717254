import React, {useState} from 'react';
import styles from './TeamWorkPrefResult.module.scss';

import * as api from 'api';

import { useTranslate } from 'utils/translator';

import {InfoTitle, Card, Modal, TeamDiagramBipolarNext} from 'ui/basic';

const DEFAULT_INFO_TITLE = 'Work Preferences';
const DEFAULT_INFO_DESCRIPTION =
  'In addition to personality, individual preferences play a particularly important role' +
  'in the question of what drives people in their thoughts and actions.' +
  'In a professional context, preferences influence our motivation to pursue certain goals' +
  'and make decisions. If our everyday working life matches our professional preferences,' +
  'we tend to feel better and are more motivated.';


const TeamWorkPrefResult = (props) => {
  // PROPS
  const {profile, referenceProfile} = props;

  const translate = useTranslate();

  const [showInfoModal, setShowInfoModal] = useState();

  // METHODS
  const getDimensionsData = () => {
    if (!profile) {
      return [];
    }

    const workPrefDimensions = [];

    profile.results.forEach(workPrefResult => {
      let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results.find(r => r.dimensionId === workPrefResult.id);
        if (thisResult) {
          range = [thisResult.minValue, thisResult.maxValue];
        }
      }

      workPrefDimensions.push({
        id: workPrefResult.id,
        label: workPrefResult.name,
        labelLeft: translate(`workpreferences_results_min_dim${workPrefResult.id.split('wp_')[1]}`) || 'Min value label',
        labelRight: translate(`workpreferences_results_max_dim${workPrefResult.id.split('wp_')[1]}`) || 'Min value label',
        range,
        data: workPrefResult.values.map(userValue => {
          const {user, value} = userValue;

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            value
          }
        })
      });
    })

    return workPrefDimensions;
  };

  return (
    <Card>
      <InfoTitle
        title={translate('workpreferences_results_title') || 'Work Preferences'}
        onClick={() => setShowInfoModal(true)}
      />

      <div className={styles.diagram}>
        <TeamDiagramBipolarNext
          valueRange={[-5, 5]}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: ` || 'Referenzprofil:')}
        />
      </div>

      {showInfoModal &&
      <Modal
        header={translate('workpreferences_results_info_title') || DEFAULT_INFO_TITLE}
        secondaryButtonTitle={translate('okay_lbl')}
        onClose={() => setShowInfoModal(false)}
      >
        {translate('workpreferences_results_info_description') || DEFAULT_INFO_DESCRIPTION}
      </Modal>
      }
    </Card>
  )
};

export default TeamWorkPrefResult;
