import { call, put, takeEvery } from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/assessments';
import * as fromActions from 'store/actions/assessments';
import {post} from 'api';

function* registerAssessment(dto) {
  const {assessmentId, newData} = dto.payload.registrationDto;
  try {
    const { status, ok, data } = yield call(post, `/core/assessments/${assessmentId}/register`, newData);

    if (ok && status === 200) {
      yield put(fromActions.registerAssessmentFulfilled());
    }
    else {
      yield put(fromActions.registerAssessmentRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.registerAssessmentFulfilled({ error }));
  }
}

export function* watchRegisterAssessment() {
  yield takeEvery(fromActionTypes.REGISTER_ASSESSMENT, registerAssessment);
}
