import React from 'react';
import styles from './LogoutSuccessForm.module.scss';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useTranslate } from 'utils/translator';
import {markdown} from 'utils/textTools';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';
import {Button, PoweredBy} from 'ui/basic';

const LogoutSuccessForm = () => {
  const translate = useTranslate();
  const history = useHistory();

  return (
    <div className={styles.logoutSuccessForm}>
      <div className={classNames(styles.container, styles[CONFIGURATION.toLowerCase()])}>
        {CONFIGURATION !== CONFIGURATION_OPTIONS.BLUQUIST &&
        <div className={styles.poweredBy}>
          <PoweredBy />
        </div>
        }

        <span className={styles.text}>
           {markdown(translate('logout_title') || 'Du wurdest abgemeldet.')}
         </span>
        <Button
          looks={'secondary'}
          onClick={() => history.push('/')}
        >
          {translate('logout_button_login') || 'Erneut anmelden'}
        </Button>
      </div>
    </div>
  )
};

export default LogoutSuccessForm;
