import { get } from 'api';
import * as action from 'store/actions';
import {call, put, takeEvery} from 'redux-saga/effects';
import {isValid} from 'utils/numbers';

export function* getAssessmentResult({payload}) {
  const id = payload;

  try {
    const {status,ok,data} = yield call(get, `/core/assessments/${id}/result`);

    if (ok && status === 200) {
      yield put(action.getAssessmentResultFulfilled({id, data}));
    } else {
      let {error} = data
      yield put(action.getAssessmentResultRejected({id,error}));
    }
  } catch (error) {
    yield put(action.getAssessmentResultRejected({id,error}));
  }
}

function* getPrevAssessmentResult({payload}) {
  const {id, history = 0, prevState} = payload;

  try {
    //if only previous state values are needed
    if (!isValid(history) && isValid(prevState)) {
      const {status, ok, data} = yield call(get, `/core/assessments/${id}/result?history=${prevState}`);
      if (ok && status === 200) {
        yield put(action.getPrevAssessmentResultFulfilled({id, data, prevState}));
      } else {
        yield put(action.getPrevAssessmentResultRejected({id, error: data.error}));
      }

      return;
    }

    const {status, ok, data} = yield call(get, `/core/assessments/${id}/result?history=${history}`);
    if (ok && status === 200) {
      yield put(action.getPrevAssessmentResultFulfilled({id, data}));

      if (isValid(prevState)) {
        const {status, ok, data} = yield call(get, `/core/assessments/${id}/result?history=${prevState}`);
        if (ok && status === 200) {
          yield put(action.getPrevAssessmentResultFulfilled({id, data, prevState}));
        }
      }
    } else {
      yield put(action.getPrevAssessmentResultRejected({id, error: data.error}));
    }
  } catch (error) {
    yield put(action.getPrevAssessmentResultRejected({id, error}));
  }
}

export function* watchgetAssessmentResultRequest() {
  yield takeEvery(action.GET_ASSESSMENT_RESULT, getAssessmentResult);
}

export function* watchGetPrevAssessmentResultRequest() {
  yield takeEvery(action.GET_PREV_ASSESSMENT_RESULT, getPrevAssessmentResult);
}

