import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'features/+admin/store/actionTypes/instanceOverview';
import * as fromActions from 'features/+admin/store/actions';

import {get, post} from 'api';
import * as api from 'api';
import {all} from '@redux-saga/core/effects';

import {LIMIT} from 'utils/configuration/const/pagination';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';
import {isValid, toFixed} from 'utils/numbers';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

function* getIndustries() {
  try {
    const { status, ok, data } = yield call(get, 'core/config/company/industries');

    if (ok && status === 200) {
      yield put(fromActions.getIndustriesFulfilled({industries: data.content}));
    } else {
      yield put(fromActions.getIndustriesRejected({error: data}));
    }
  } catch(error) {
    yield put(fromActions.getIndustriesRejected({error}));
  }
}

function* createInstance({payload}) {
  try {
    let sloppyAppId = CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ? 'by' : 'ui';
    //for balanced-you pre environment use 'msj'
    if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU && window.location.href.includes('pre.')) {
      sloppyAppId = 'mfj';
    }

    const { externalDataProcessingContext } = payload.createInstanceDto;
    delete payload.createInstanceDto.externalDataProcessingContext; // otherwise backend gives 500

    // create instance
    const { status, ok, data } = yield call(api.put, 'admin/companies', {
      ...payload.createInstanceDto,
      sloppy_app_id: sloppyAppId
    });

    // set externalDataProcessingContext
    let okSettings;
    if (ok && externalDataProcessingContext) {
      const response = yield call(api.put, 'core/company/settings', {
        externalDataProcessingContext
      }, {
        // set company via header because we are in admin context
         'x-admin-company': data.id
      });
      okSettings = response.ok;
    }

    if (ok && okSettings && status === 200) {
      yield put(fromActions.createInstanceFulfilled());
    } else {
      yield put(fromActions.createInstanceRejected({error: data.error}));
    }
  } catch(error) {
    yield put(fromActions.createInstanceRejected({error}));
  }
}

function* getInstances({payload}) {
  try {
    let limit = LIMIT, offset, sort, sortOrder, search, instanceType, fetchStatistics, infiniteLoad;

    if (payload.requestDto) {
      const requestDto = payload.requestDto;
      limit = requestDto.limit || LIMIT;
      offset = requestDto.offset;
      sort = requestDto.sort;
      sortOrder = requestDto.sortOrder;
      search = requestDto.search;
      instanceType = requestDto.instanceType;
      fetchStatistics = requestDto.fetchStatistics;
      infiniteLoad = requestDto.infiniteLoad
    }

    const { status, ok, data, headers } = yield call(get, 'admin/companies', {
      limit,
      offset,
      sort,
      sortOrder,
      search,
      type: instanceType
    });

    if (ok && status === 200) {
      //fetch user statistics for each company if needed
      if (fetchStatistics) {
        const companies = [...data.companies];
        const companyStatisticsResponses = yield all(
          companies.map(company => call(
            get,
            `admin/statistics/company/${company.id}`,
            {},
            {'x-admin-company': company.id}))
        );

        const companyPlanResponses = yield all(
          companies.map(company => call(get, 'core/company/billing/plan', {}, {'x-admin-company': company.id}))
        );

        companyStatisticsResponses
          .filter(response => response.ok && response.status === 200)
          .forEach(response => {
            let thisCompany = companies.find(company => company.id === response.config.url.split('admin/statistics/company/')[1]);

            if (!thisCompany) {
              return;
            }

            const {membersTotal, usersNewlyRegistered, engagementRate, engagementRatePrev} = response.data;

            thisCompany.membersTotal = membersTotal || 0;
            thisCompany.usersNewlyRegistered = usersNewlyRegistered || 0;
            thisCompany.engagementRate = engagementRate ? engagementRate * 100 : 0;
            thisCompany.engagementRatePrev = engagementRatePrev ? engagementRatePrev * 100 : 0;
          });

        companyPlanResponses
          .filter(response => response.ok && response.status === 200)
          .forEach(response => {
            let thisCompany = companies.find(company => company.id === response.config.headers['x-admin-company']);

            if (!thisCompany) {
              return;
            }

            thisCompany.planId = response.data.id;
            thisCompany.planName = response.data.name;
          });

        yield put(fromActions.getInstancesFulfilled({
          instances: companies.map(company => ({
            id: company.id,
            name: company.name,
            createdAt: company.created,
            planId: company.planId,
            planName: company.planName,
            industryName: company.industryName,
            membersTotal: company.membersTotal || 0,
            usersNewlyRegistered: company.usersNewlyRegistered || 0,
            engagementRate: isValid(company.engagementRate) ? `${toFixed(company.engagementRate, 2)} %` : '0 %',
            engagementRatePrev: isValid(company.engagementRate) && isValid(company.engagementRatePrev) ?
              `${company.engagementRate - company.engagementRatePrev > 0 ? '+' : ''}
               ${toFixed(company.engagementRate - company.engagementRatePrev, 2)} %`
              : '0 %'
          })),
          instanceType,
          infiniteLoad,
          noDataAtAll: !search && (!companies || !companies.length),
          totalCount: (headers && headers['x-total-result-count']) ? headers['x-total-result-count'] : undefined
        }));
      }
      else {
        yield put(fromActions.getInstancesFulfilled({
          instances: data.companies.map(company => ({
            id: company.id,
            name: company.name,
            branch: company.industryName || '',
            createdAt: company.created
          })),
          instanceType,
          totalCount: (headers && headers['x-total-result-count']) ? headers['x-total-result-count'] : undefined
        }));
      }
    } else {
      yield put(fromActions.getInstancesRejected({error: data.error}));
    }
  } catch(error) {
    yield put(fromActions.getInstancesRejected({error}));
  }
}

function* getInstance({payload}) {
  try {
    const instanceId = payload.instanceId;

    //fetching instance info
    const instanceInfoResponse = yield call(get, `admin/companies/${instanceId}`, {}, {'x-admin-company': instanceId});

    if (instanceInfoResponse.ok && instanceInfoResponse.status === 200) {
      //fetching instance statistics
      const statisticResponse =  yield call(
        get,
        `admin/statistics/company/${instanceId}`,
        {},
        {'x-admin-company': instanceId});

      //fetching instance assessments statistics
      //currently it is only needed for balanced-you configuration
      let assessmentsStatisticsResponse;
      if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU) {
        assessmentsStatisticsResponse =  yield call(get, `admin/statistics/company/${instanceId}/assessments`);
      }

      let membersTotal, usersNewlyRegistered, signupCompletionRate, usersActive, usersActivePrev, engagementRate,
        engagementRatePrev, wellbeingAdoptionRate, wellbeingAdoptionRatePrev,
        byAssessmentTotal = 0, byAssessmentThisMonth = 0;

      // mapping of instance statistics
      if (statisticResponse.ok && statisticResponse.status === 200) {
        const statisticsResponseData = statisticResponse.data;

        membersTotal = statisticsResponseData.membersTotal;
        usersNewlyRegistered = statisticsResponseData.usersNewlyRegistered;
        signupCompletionRate = statisticsResponseData.signupCompletionRate ? statisticsResponseData.signupCompletionRate * 100 : 0;
        usersActive = statisticsResponseData.usersActive;
        usersActivePrev = statisticsResponseData.usersActivePrev;
        engagementRate = statisticsResponseData.engagementRate ? statisticsResponseData.engagementRate * 100 : 0;
        engagementRatePrev = statisticsResponseData.engagementRatePrev ? statisticsResponseData.engagementRatePrev * 100 : 0;
        wellbeingAdoptionRate = statisticsResponseData.wellbeingAdoptionRate ? statisticsResponseData.wellbeingAdoptionRate * 100 : 0;
        wellbeingAdoptionRatePrev = statisticsResponseData.wellbeingAdoptionRatePrev
          ? statisticsResponseData.wellbeingAdoptionRatePrev * 100 :
          0;
      }

      // mapping of instance assessments statistics
      if (assessmentsStatisticsResponse && assessmentsStatisticsResponse.status === 200) {
        const assessmentStatisticsData = assessmentsStatisticsResponse.data;
        const by = assessmentStatisticsData.assessments && assessmentStatisticsData.assessments[ASSESSMENT_TYPES.MINDFULNESS_JOURNEY];

        byAssessmentTotal = by ? by.usersCompleted : 0;
        byAssessmentThisMonth = by ? by.usersCompletedThisMonth : 0
      }

      yield put(fromActions.getInstanceFulfilled({instance: {...instanceInfoResponse.data,
          registered: `${membersTotal || 0} / ${usersNewlyRegistered || 0}`,
          signupCompletionRate: `${signupCompletionRate ? toFixed(signupCompletionRate, 2) : 0} %`,
          monthlyActive: `${usersActive || 0}`,
          monthlyActivePrev: (isValid(usersActive) && isValid(usersActivePrev)) ?
            `${usersActive - usersActivePrev > 0 ? '+' : ''}${usersActive - usersActivePrev}` :
            0,
          engagementRate: `${engagementRate ? toFixed(engagementRate, 2) : 0} %`,
          engagementRatePrev: (isValid(engagementRate) && isValid(engagementRatePrev)) ?
            `${engagementRate - engagementRatePrev > 0 ? '+' : ''}${toFixed(engagementRate - engagementRatePrev, 2)} %` :
            '0 %',
          wellBeingAdoptionRate: `${wellbeingAdoptionRate ? toFixed(wellbeingAdoptionRate, 2) : 0} %`,
          wellBeingAdoptionRatePrev: (isValid(wellbeingAdoptionRate) && isValid(wellbeingAdoptionRatePrev)) ?
            `${wellbeingAdoptionRate - wellbeingAdoptionRatePrev > 0 ? '+' : ''}
            ${toFixed(wellbeingAdoptionRate - wellbeingAdoptionRatePrev, 2)} %` :
            '0 %',
          byAssessment: `${byAssessmentTotal} / ${byAssessmentThisMonth}`
        }}));
    } else {
      yield put(fromActions.getInstanceRejected({error: instanceInfoResponse.data.error}));
    }
  } catch(error) {
    yield put(fromActions.getInstanceRejected({error}));
  }
}

function* getInstanceRegistrationDomains({payload}) {
  try {
    const instanceId = payload.instanceId;

    const {ok, status, data} = yield call(
      get,
      'core/company/settings',
      {},
      {'x-admin-company': instanceId}
      );

    if (ok && status === 200) {
      yield put(fromActions.getInstanceRegistrationDomainsFulfilled({registrationDomains: data.openRegistrationDomains}));
    } else {
      yield put(fromActions.getInstanceRegistrationDomainsRejected({error: data.error}));
    }
  } catch(error) {
    yield put(fromActions.getInstanceRegistrationDomainsRejected({error}));
  }
}

function* updateInstanceRegistrationDomains({payload}) {
  try {
    const {instanceId, domains} = payload;

    const {ok, status, data} = yield call(
      api.put,
      'core/company/settings',
      {openRegistrationDomains: domains},
      {'x-admin-company': instanceId}
    );

    if (ok && status === 200) {
      yield put(fromActions.updateInstanceRegistrationDomainsFulfilled());
      yield put(fromActions.getInstanceRegistrationDomains(instanceId));
    } else {
      yield put(fromActions.updateInstanceRegistrationDomainsRejected({error: data.error}));
    }
  } catch(error) {
    yield put(fromActions.updateInstanceRegistrationDomainsRejected({error}));
  }
}

function* getUserStatistics() {
  try {
    const statisticsResponse = yield call(get, 'admin/statistics/user');

    if (statisticsResponse.ok && statisticsResponse.status === 200) {
      const statisticsData = {...statisticsResponse.data};
      let assessmentsQuotaData;

      //request for fetching assessments quota (9levels, RMP)
      const assessmentsQuotaResponse = yield call(get, 'admin/assessments/quota');
      if (assessmentsQuotaResponse.ok && assessmentsQuotaResponse.status === 200) {
        assessmentsQuotaData = assessmentsQuotaResponse.data;
      }

      const cusInstances = (statisticsData.companiesByType) ? statisticsData.companiesByType.customer : 0;
      const lastMonthCusInstances = (statisticsData.companiesCreatedByType) ? statisticsData.companiesCreatedByType.customer : 0;

      const demoInstances = (statisticsData.companiesByType) ? statisticsData.companiesByType.demo : 0;
      const lastMonthDemoInstances = (statisticsData.companiesCreatedByType) ? statisticsData.companiesCreatedByType.demo : 0;

      yield put(fromActions.getUserStatisticsFulfilled({statistics: {
          signupCompletionRate: statisticsData.signupCompletionRate ?
            `${toFixed(statisticsData.signupCompletionRate * 100,2)} %`
            : '0 %',
          usersAvg: statisticsData.usersAvg,
          usersActive: statisticsData.usersActive,
          usersActivePrev: (
            `${statisticsData.usersActive - statisticsData.usersActivePrev > 0 ? '+' : ''} ${statisticsData.usersActive - statisticsData.usersActivePrev}`
          ),
          cusInstances: `${cusInstances || 0} / ${lastMonthCusInstances || 0}`,
          demoInstances: `${demoInstances || 0} / ${lastMonthDemoInstances || 0}`,
          engagementRate: statisticsData.engagementRate ?
            `${toFixed(statisticsData.engagementRate * 100, 2)} %` :
            '0 %',
          engagementRatePrev: (isValid(statisticsData.engagementRate) && isValid(statisticsData.engagementRatePrev)) ?
            `${statisticsData.engagementRate - statisticsData.engagementRatePrev > 0 ? '+' : ''}
             ${toFixed((statisticsData.engagementRate * 100) - (statisticsData.engagementRatePrev * 100), 2)} %` :
            '0 %',
          nineLevelsQuota: assessmentsQuotaData ? assessmentsQuotaData['9levels'] : undefined,
          rmpQuota: assessmentsQuotaData ? assessmentsQuotaData.rmp : undefined
        }}));

    } else {
      yield put(fromActions.getUserStatisticsRejected({error: statisticsResponse.data.error}));
    }
  } catch(error) {
    yield put(fromActions.getUserStatisticsRejected({error}));
  }
}

function* rebuildCache({payload}) {
  try {
    const companyId = payload.companyId;

    const { status, ok, data } = yield call(
      post,
      'admin/general/rebuildcache',
      {companyId},
      {'x-admin-company': companyId}
      );

    if (ok && status === 200) {
      yield put(fromActions.rebuildCacheFulfilled());
    } else {
      yield put(fromActions.rebuildCacheRejected({error: data.error}));
    }
  } catch(error) {
    yield put(fromActions.rebuildCacheRejected({error}));
  }
}


export function* watchGetIndustries() {
  yield takeEvery(fromActionTypes.GET_INDUSTRIES, getIndustries);
}

export function* watchCreateInstance() {
  yield takeEvery(fromActionTypes.CREATE_INSTANCE, createInstance);
}

export function* watchGetInstances() {
  yield takeEvery(fromActionTypes.GET_INSTANCES, getInstances);
}

export function* watchGetInstance() {
  yield takeEvery(fromActionTypes.GET_INSTANCE, getInstance);
}

export function* watchGetInstanceRegistrationDomains() {
  yield takeEvery(fromActionTypes.GET_INSTANCE_REG_DOMAINS, getInstanceRegistrationDomains);
}

export function* watchUpdateInstanceRegistrationDomains() {
  yield takeEvery(fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS, updateInstanceRegistrationDomains);
}

export function* watchGetUserStatistics() {
  yield takeEvery(fromActionTypes.GET_USER_STATISTICS, getUserStatistics);
}

export function* watchRebuildCache() {
  yield takeEvery(fromActionTypes.REBUILD_CACHE, rebuildCache);
}
