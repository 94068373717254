import * as actions from 'store/actions';

const initialState = {
  success: false,
  processing: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CURRENT_USER:
      return {
        ...state,
        processing: true
      };
    case actions.GET_USER_PROFILE_PICTURE:
      return {
        ...state,
        processing: true
      };
    case actions.GET_CURRENT_USER_REJECTED:
      return {
        ...state,
        processing: false,
        ...action.payload
      };
    case actions.GET_USER_PROFILE_PICTURE_REJECTED:
    case actions.UPLOAD_USER_PROFILE_PICTURE_REJECTED:
      return {
        ...state,
        ...action.payload
      };
    case actions.GET_CURRENT_USER_FULFILLED:
      return {
        ...state,
        processing: false,
        ...action.payload
      };
    case actions.GET_USER_PROFILE_PICTURE_FULFILLED:
      return {
        ...state,
        ...action.payload
      };
    case actions.UPDATE_CURRENT_USER:
      return {
        ...state,
        processing: true
      };
    case actions.UPDATE_CURRENT_USER_FULFILLED:
      return {
        ...state,
        success: true,
        processing: false
      };
    case actions.UPDATE_CURRENT_USER_RESET:
      return {
        ...state,
        success: false
      };
    default:
      return state;
  }
};
