// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './TeamCliftonResult.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Card, PillGroup, ImgCircle } from 'ui/basic';

// UTILS
import {useTranslate} from 'utils/translator';

// STORE

// CONFIG & DATA
const Config = {
  MAX_VALUE: 10
};


// COMPONENT: TeamCliftonResult
const TeamCliftonResult = (props) => {
  // PROPS
  const {dimensions = []} = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // DIMENSIONS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ dimensionsInternal, setDimensionsInternal ] = useState([]);
  useEffect(() => {
    let mappedDimensions = [];

    if (!dimensions || dimensions.length === 0) {
      return;
    }

    mappedDimensions = dimensions.map(dimension => {
      const strengths = dimension.subDimensions
        .filter(subDimension => {
          return subDimension?.values.some(value => value.globalRank <= Config.MAX_VALUE);
        })
        .map(subDimension => ({
          strength: subDimension.name,
          label: translate(subDimension.id),
          employees: subDimension.values
            .filter(subDimensioValue => subDimensioValue.globalRank <= Config.MAX_VALUE)
            .sort((subDimensionA, subDimensionB) => subDimensionA.globalRank - subDimensionB.globalRank > 0 ? 1 :
              (subDimensionA.globalRank - subDimensionB.globalRank < 0 ? -1 : 0))
            .map(subDimensionValue => ({
              id: subDimensionValue.user && subDimensionValue.user.id,
              name: subDimensionValue.user && subDimensionValue.user.name,
              rank: subDimensionValue.globalRank
            }))
      }))

      const missingStrengths = dimension.subDimensions
        .filter(subDimension => {
          return subDimension?.values.every(value => value.globalRank > Config.MAX_VALUE);
        })
        .map(subDimension => ({
          strength: subDimension.id,
          label: translate(subDimension.id)
        }))

      return {
        dimension: dimension.id,
        label: translate(dimension.id),
        strengths,
        missingStrengths
      }
    })

    setDimensionsInternal(mappedDimensions)
    setActiveDimension(mappedDimensions[0])
  }, [dimensions, translate]);

  // ACTIVE INDEX: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ activeDimension, setActiveDimension ] = useState();
  const handlePillClick = (pillItem, index) => {
    setActiveDimension(pillItem);
  };

  // RENDER: TeamCliftonResult
  return (
    <div className={classNames(styles.teamCliftonResult)}>
      <Card hasBreakout='S'>
        <div className={styles.title}>
          {translate('clifton_ass_title')}
        </div>
        <div className={styles.pills}>
          <PillGroup pillItems={dimensionsInternal}
            fadeWhite
            onPillClick={handlePillClick}
          />
        </div>
        <div className={styles.strengths}>
          { activeDimension && (
            <>
              { activeDimension.strengths?.map((strength) => {
                let activeDimensionName;

                const splitArray = activeDimension.dimension.split('_');
                if (splitArray && splitArray.length >= 3) {
                  activeDimensionName = splitArray[2];
                }

                return (
                  <div key={strength.strength} className={styles.strength}>
                    <div className={classNames(styles.label, styles[activeDimensionName])}>
                      { strength.label }
                    </div>
                    { strength.employees.map((employee) => (
                      <div key={employee.id} className={styles.employee}>
                        <div className={styles.avatar}>
                          <ImgCircle size='Xs' fullLabel={employee.name} />
                        </div>
                        <div className={styles.name}>
                          { employee.name }
                        </div>
                        <div className={styles.rank}>
                          { employee.rank }
                        </div>
                      </div>
                    ))}
                  </div>
                )
              })}
              <div className={styles.missingStrengths}>
                { !activeDimension.missingStrengths?.length && (
                  translate('clifton_all_strengths_represented_title', [
                    '{{dimension}}', activeDimension.label
                  ])
                )}
                { activeDimension.missingStrengths?.length > 0 && (
                  <>
                    {translate('clifton_underrepresented_strengths_title', [
                      '{{dimension}}', activeDimension.label
                    ])}
                    <ul className={styles.list}>
                      { activeDimension.missingStrengths.map((strength) => (
                        <li key={strength.strength}>
                          { strength.label }
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default TeamCliftonResult;
