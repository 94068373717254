import React, {useCallback, useEffect, useState} from 'react';
import styles from './SteppedScaleResultItem.module.scss';

import * as api from 'api';

import classNames from 'classnames';

import {SegmentedLineDiagramNext, InfoTitle, CollapsiblePanel, ImgCircle} from 'ui/basic';

import {useDebounce, useWindowWidth} from 'utils/hooks';
import {setDecimalSeperator} from 'utils/userpreference';
import {isValid} from 'utils/numbers';


// COMPONENT: SteppedScaleResultItem
const SteppedScaleResultItem = (props) => {
  // PROPS
  const {
    data,
    segmentsCount,
    isExpandable = true,
    onInfoClick = () => {}
  } = props;

  const [mouseButtonPressed, setMouseButtonPressed] = useState();

  const [width, setWidth] = useState();
  const [bodyWidth, setBodyWidth] = useState();
  const [ node, setNode ] = useState(0);

  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 600);

  const diagramParentRef = useCallback(newNode => {
    if ( newNode !== node && newNode !== null ) {
      setNode(newNode);
    }
  }, [node]);

  useEffect(() => {
    if (debouncedWindowWidth) {
      const width = node.getBoundingClientRect().width;
      setWidth(width);
      setBodyWidth(width - 32);
    }
  }, [node, debouncedWindowWidth]);


  // DIMENSION REFERENCE VALUE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [referenceValueInternal, setReferenceValueInternal] = useState();
  useEffect(() => {
    if (!data) {
      return;
    }

    if (isValid(data.referenceValue)) {
      setReferenceValueInternal(data.referenceValue);
    } else {
      // if each sub dimension has a reference value, total reference value can be calculated
      if (!data.subDimensions || !data.subDimensions.every(subDimension => isValid(subDimension.referenceValue))) {
        return;
      }

      const subDimensionsReferenceValues = data.subDimensions.map(subDimension => subDimension.referenceValue);
      const totalReferenceValue = subDimensionsReferenceValues.reduce((total, value) => total + value, 0) /subDimensionsReferenceValues.length;

      if (isValid(totalReferenceValue)) {
        setReferenceValueInternal(Number(totalReferenceValue).toFixed(2));
      }
    }
  }, [data]);

  // DIMENSION RESULT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [resultInternal, setResultInternal] = useState();
  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.result) {
      setResultInternal(data.result);
    } else {
      // if each sub dimension has a result, total result can be calculated
      if (!data.subDimensions || !data.subDimensions.every(subDimension => isValid(subDimension.result))) {
        return;
      }

      const subDimensionsResults = data.subDimensions.map(subDimension => subDimension.result);
      const totalResult = subDimensionsResults.reduce((total, value) => total + value, 0) /subDimensionsResults.length;

      setResultInternal(Number(totalResult).toFixed(2));
    }
  }, [data]);


  const renderHeader = () => (
    <div className={styles.header}>
      <div className={styles.left} ref={diagramParentRef}>
        <SegmentedLineDiagramNext
          width={width}
          score={resultInternal}
          segmentsCount={segmentsCount}
          referenceValue={referenceValueInternal}
        />
      </div>
      <div className={styles.right}>
        <span className={classNames(
          styles.points,
          {[styles.big]: referenceValueInternal && resultInternal && !data.user}
        )}
        >
          {(referenceValueInternal && resultInternal) && (
            data.user ?
              <span>{setDecimalSeperator(resultInternal)}</span> :
              <span>{`${setDecimalSeperator(resultInternal)} / ${setDecimalSeperator(referenceValueInternal)}`}</span>
          )}

          {(resultInternal && !referenceValueInternal) &&
          <span>{setDecimalSeperator(resultInternal)}</span>
          }

          {(referenceValueInternal && !resultInternal) &&
          <span>{setDecimalSeperator(referenceValueInternal)}</span>
          }
        </span>

        {data.user ?
          <div className={styles.user}>
            <ImgCircle
              size={'Xs'}
              src={api.getUserImageUrl(data.user.id)}
              label1={data.user.name?.split(' ')[0]}
              label2={data.user.name?.split(' ')[1]}
            />
            <span className={styles.name}>{data.user.name}</span>
          </div> :
          <span className={styles.name}>{data.name}</span>}
      </div>
    </div>
  );

  const renderHeaderWithNoSubDimensions = () => (
    <div
      className={styles.headerWithNoSubDimensions}
      onClick={() => onInfoClick(-1, data)}
    >
      <div className={styles.left} ref={diagramParentRef}>
        <SegmentedLineDiagramNext
          width={width}
          score={resultInternal}
          segmentsCount={segmentsCount}
          referenceValue={referenceValueInternal}
        />
      </div>
      <div className={styles.right}>
        {data.user ?
          <div className={styles.user}>
            <ImgCircle
              size={'Xs'}
              src={api.getUserImageUrl(data.user.id)}
              label1={data.user.name.split(' ')[0]}
              label2={data.user.name.split(' ')[1]}
            />
            <span className={styles.name}>{data.user.name}</span>
          </div> :
          <InfoTitle
            title={data.name}
            type={'regular'}
            className={styles.infoTitle}
            fullWidth
            onClick={() => onInfoClick(-1, data)}
          />
        }
      </div>
    </div>
  );


  return (
    <div className={styles.steppedScaleResultItem}>
      {/*NO SUB DIMENSIONS*/}
      {(!data.subDimensions || data.subDimensions.length === 0) &&
      renderHeaderWithNoSubDimensions()
      }

      {/*WITH SUB DIMENSIONS*/}
      {(data.subDimensions && data.subDimensions.length > 0) &&
      <CollapsiblePanel
        header={renderHeader()}
        headerClassName={classNames(
          styles.collapsibleHeader,
          {[styles.active]: mouseButtonPressed}
        )}
        isExpandable={isExpandable}
        showTopBorder={false}
        onMouseDown={() => setMouseButtonPressed(true)}
        onMouseUp={() => setMouseButtonPressed(false)}
      >
        <div className={styles.body}>
          {data.subDimensions.map((subDimension, subDimensionIndex) => (
            <div
              key={`facet-${subDimensionIndex}`}
              tabIndex='0'
              className={styles.subItemRow}
              onClick={() => onInfoClick(subDimensionIndex + 1, subDimension)}
            >
              <SegmentedLineDiagramNext
                width={bodyWidth}
                score={subDimension.result}
                segmentsCount={segmentsCount}
                referenceValue={subDimension.referenceValue}
              />
              <div className={styles.right}>
                <InfoTitle
                  className={styles.infoTitle}
                  title={subDimension.name}
                  fullWidth
                  type={'regular'}
                  onClick={() => onInfoClick(subDimensionIndex + 1, subDimension)}
                />
              </div>
            </div>
          ))}
        </div>
      </CollapsiblePanel>
      }

    </div>
  );
};

export default SteppedScaleResultItem;
