import { translate } from 'utils/translator/translator';
import {REGISTRATION_STATUSES} from 'utils/configuration/const/registration-statuses';
import {PLATFORM_ROLES} from 'utils/configuration/const/roles';
import {setDecimalSeperator} from './userpreference';
import {timestampToDate} from './dateTools';
import {CAREER_LEVELS_BY_ID} from './configuration/const/career-level';
import {CURRENCY_IDS, CURRENCY_SYMBOLS} from './configuration/const/recruiting';

const DEFAULT_ASSESSMENTS_COUNT = 6;

const PERSONALITY_SCORE_KEY = 1;
const PREFERENCES_SCORE_KEY = 3;
const COMPETENCIES_SCORE_KEY = 4;

export const getMappedEmployees = (employees) => {
  return employees.map(employee => {
    const user = employee.user;
    const id = user.id || employee.id;

    let statusPercentage = 0;
    let statusLabel = 'table_status_invited';

    const name = (!user.firstName || !user.lastName) ?
      (employee.name || user.mail) :
      [user.firstName, user.lastName].join(' ');

    const userGroup = employee.role ?
      (translate(`platform_roles_${employee.role.split('_CUSTOM')[0].toLowerCase()}`) || employee.role.toLowerCase()) :
      '';

    if (user.profileCompletion && user.profileCompletion.completed > 0) {
      statusPercentage = Math.round(
        (user.profileCompletion.completed * 100) / (user.profileCompletion.available || DEFAULT_ASSESSMENTS_COUNT)
      );
      statusLabel = statusPercentage === 100 ?
        'table_status_assessments_completed' :
        'table_status_assessments';

    } else {
      if (user.registrationStatus === REGISTRATION_STATUSES.REGISTERED) {
        statusPercentage = 10;
        statusLabel = 'table_status_registered';
      }
    }

    let careerLevel;
    if (user.companyData && user.companyData.careerLevel) {
      const cl = CAREER_LEVELS_BY_ID[user.companyData.careerLevel];
      if (cl) {
        careerLevel = translate(cl.translationKey) || cl.translationFallback;
      }
    }

    const mappedUser = {
      id,
      firstName: user.firstName,
      lastName: user.lastName,
      //if employee's name is equal to any of user group names, instead of name email will be shown
      name: Object.values(PLATFORM_ROLES).includes(name) ? user.mail : name,
      email: user.mail,
      company: user.company,
      companyId: user.companyId,
      position: (user.companyData && user.companyData.profession) ? user.companyData.profession : '',
      careerLevel,
      since: (user.companyData && user.companyData.companyEntryDate) ? timestampToDate(user.companyData.companyEntryDate) : '',
      userGroup: userGroup,
      lastActive: user.lastActive,
      status: {
        percentage: statusPercentage,
        label: statusLabel
      },
      userPicture: user.userPicture,
      orgRoles: user.organizationalRoles,
      profileCompletion: user.profileCompletion,
      roles: user.roles,
      registrationStatus: user.registrationStatus,
      disabled: user.disabled
    }

    //layer scores. Used on role staffing page.
    if (employee.layerScores) {
      mappedUser['personalityScore'] = setDecimalSeperator(employee.layerScores[PERSONALITY_SCORE_KEY]);
      mappedUser['preferencesScore'] = setDecimalSeperator(employee.layerScores[PREFERENCES_SCORE_KEY]);
      mappedUser['competenciesScore'] = setDecimalSeperator(employee.layerScores[COMPETENCIES_SCORE_KEY]);

      mappedUser['totalScore'] = setDecimalSeperator(employee.score);
    }

    return mappedUser;
  })
}

export const getMappedCandidate = (candidate) => {
  const candidateData = candidate.candidateData;

  const name = (!candidate.firstName || !candidate.lastName) ?
    (candidate.name || candidate.mail) :
    [candidate.firstName, candidate.lastName].join(' ');

  const profileCreated = !!candidate.firstName && !!candidate.lastName;
  const position = (candidateData && candidateData.currentPosition) ?
    candidateData.currentPosition :
    (profileCreated ? 'Career starter' : '');

  const industryExperienceMapped = (candidateData && candidateData.industryExperience) ?
    candidateData.industryExperience.map(experienceItem => (
      `${experienceItem.industry_name} - ${translate(`${experienceItem.experience}_label`)}`
    )) : [];

  let country = (candidateData && candidateData.country) ? (translate(`config_country_${candidateData.country}`)) : '';
  let residence = candidateData && (
    (country && candidateData.city) ?
      `${country}, ${candidateData.city}` :
      (country || candidateData.city)
  );

  let residenceWithNationality = residence;
  if (candidateData && candidateData.nationality && candidateData.nationality.length > 0 && residence) {
    residenceWithNationality += ` / ${translate(`config_nationality_${candidateData.nationality[0]}`)}`;
  }

  const positionAndJobFamily = candidateData && (
    (position && candidateData.currentJobFamily && candidateData.currentJobFamily.name) ?
      `${position} / ${candidateData.currentJobFamily.name}` :
      (position || (candidateData.currentJobFamily && candidateData.currentJobFamily.name && candidateData.currentJobFamily.name))
  );

  let gender;
  if (candidateData && candidateData.gender) {
    gender = translate(`gender_${candidateData.gender}`);
  }

  let education;
  if (candidateData && candidateData.education) {
    education = translate(candidateData.education);
  }

  let jobRequirements = [];
  if (candidateData && candidateData.remotePreferred) {
    jobRequirements.push(translate('remote_job_possible') || 'Remote-Arbeit möglich');
  }
  if (candidateData && candidateData.desiredWeeklyHours) {
    jobRequirements.push(translate('desired_weekly_hours', ['{{hours}}', candidateData.desiredWeeklyHours]) ||
      `Gewünschte Wochenarbeitszeit: ${candidateData.desiredWeeklyHours} h`);
  }
  if (candidateData && candidateData.desiredSalary) {
    const currencySymbol = (candidateData.currency && CURRENCY_SYMBOLS[candidateData.currency]) ?
      CURRENCY_SYMBOLS[candidateData.currency] :
      CURRENCY_SYMBOLS[CURRENCY_IDS.EURO];

    jobRequirements.push(`${translate('desired_salary')}: ${candidateData.desiredSalary} ${currencySymbol}` ||
      `Gehaltswunsch p.a.: ${candidateData.desiredSalary} ${currencySymbol}`);
  }

  if (candidateData && candidateData.additionalRequirements) {
    jobRequirements = [...jobRequirements, ...candidateData.additionalRequirements];
  }

  let motivation = [];
  if (candidateData && candidateData.motivation) {
    motivation = candidateData.motivation.map(m => {
      if (m === 'culture' || m === 'opportunities' || m === 'salary') {
        return translate(`cp_motivation_${m}_question`) || m;
      } else {
        return m;
      }
    })
  }

  let age;
  if (candidateData && candidateData.yearOfBirth) {
    age = new Date().getFullYear() - candidateData.yearOfBirth;
  }

  let careerLevel;
  if (candidateData && candidateData.careerLevel) {
    const careerLevelObj = CAREER_LEVELS_BY_ID[candidateData.careerLevel];
    careerLevel = translate(`${careerLevelObj.translationKey}`) || careerLevelObj.translationFallback;
  }

  const desiredSalaryString = (candidateData && candidateData.desiredSalary) ?
    `${candidateData.desiredSalary} ${candidateData.currency || ''}` : '';

  return {
    ...candidate,
    name,
    position,
    profileCreated,
    candidateData: {
      ...candidateData,
      careerLevel,
      age,
      gender,
      education,
      residence,
      positionAndJobFamily,
      residenceWithNationality,
      industryExperienceMapped: industryExperienceMapped.length > 1 ? industryExperienceMapped : industryExperienceMapped[0],
      jobRequirements,
      motivation,
      desiredSalaryString
    }
  }
}
