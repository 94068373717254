import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './RmpResult.module.scss';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { initSubmitContentFeedback, submitContentFeedback } from 'store/actions';

// ASSETS
import {IconsSvg} from 'assets/icons';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { isValid } from 'utils/numbers';
import { useBreakpoint, useDebounce, useWindowWidth } from 'utils/hooks';
import { setDecimalSeperator } from 'utils/userpreference';
import {
  getAverageResults,
  getStrongResults,
  getWeakResults, resultIsStrong, resultIsWeak, RMP_FIRST_MARKER_POSITION
} from 'utils/rmp';

import RmpResultItem from './RmpResultItem';
import RmpResultSkeleton from './RmpResultSkeleton';
import RmpResultTable from './RmpResultTable';
import { InfoTitle, Button, Modal, RmpBipolarDiagram, Callout } from 'ui/basic';
import { RmpReport } from 'ui/molecules';

import { LayoutContext } from 'features/framework/components/MainLayout/MainLayout'


const DEFAULT_COPYRIGHT_TITLE = '© IDS Publishing Corporation. All rights reserved.';
const DEFAULT_COPYRIGHT_DESCRIPTION =
  'All uses of this content are covered by this Copyright Notice,' +
  'which may not be removed or altered. No modifications of the content are permitted, ' +
  'including any translations into another language. No rights are granted for use of any portion ' +
  'of this content without the express, written permission obtained in advance from IDS Publishing Corporation,' +
  ' including creation of any derivative work for puoblication such as documents that quote, cite,' +
  ' or are based upon this content , whether for research puposes or otherwise.';

const DEFAULT_INFO_TITLE = 'RMP';
const DEFAULT_INFO_DESCRIPTION =
  'In addition to personality, individual preferences play a particularly important role' +
  'in the question of what drives people in their thoughts and actions.' +
  'In a professional context, preferences influence our motivation to pursue certain goals' +
  'and make decisions. If our everyday working life matches our professional preferences,' +
  'we tend to feel better and are more motivated.';


const RmpResult = memo((props) => {
  const {
    results = [],
    openReport,
    showReportButton = true,
    footerText,
    noDataText,
    showPendingState,
    requestFeedback = true,
    showExportIcon,
    exportView,
    onExport = () => {}
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 700);

  const { mainNavIsOpenDebounced } = useContext(LayoutContext) || {};

  const [ diagramWidth, setDiagramWidth ] = useState(0);
  const [ rowsNode, setRowsNode ] = useState();
  const ref = useCallback(node => {
    if (node) {
      setRowsNode(node);
    }
  }, []);

  useEffect(() => {
    if (rowsNode) {
      setDiagramWidth(rowsNode.clientWidth);
    }
  }, [ debouncedWindowWidth, mainNavIsOpenDebounced, rowsNode ]);


  const DownloadIcon = IconsSvg.DownloadSvg;

  const [currentDimensionIndex, setCurrentDimensionIndex] = useState();

  const [showModal, setShowModal] = useState();
  const [modalHeader, setModalHeader] = useState();
  const [modalSubHeader, setModalSubHeader] = useState();
  const [modalScrollable, setModalScrollable] = useState();
  const [modalContent, setModalContent] = useState();
  const [modalPrimaryButtonTitle, setModalPrimaryButtonTitle] = useState();
  const [showModalFeedback, setShowModalFeedBack] = useState();

  const [reportPageVisible, setReportPageVisible] = useState(false);

  const showRange = results && results[0] && Array.isArray(results[0].range);
  const showResult = results && results[0] && isValid(results[0].result);

  const rmp = useSelector(state => {
    return (
      state.assessments &&
      state.assessments[ASSESSMENT_TYPES.RMP]
    )
  });

  const feedbackSubmittedSuccessfully = useSelector(state => state.survey && state.survey.success);

  const strongResults = getStrongResults(results);
  const weakResults = getWeakResults(results);
  const averageResults = getAverageResults(results);

  const getSubHeader = (dimensionName, dimensionValue) => {
    if (resultIsStrong(dimensionValue)) {
      return translate('rmp_dimension_strong__short_descr',
        ['{{dimension}}', dimensionName]) ||
        `Das Merkmal ${dimensionName} ist hoch ausgeprägt.`;
    } else if (resultIsWeak(dimensionValue)) {
      return translate('rmp_dimension_weak__short_descr',
        ['{{dimension}}', dimensionName]) ||
        `Das Merkmal ${dimensionName} ist gering ausgeprägt.`;
    } else {
      return translate('rmp_dimension_average__short_descr',
        ['{{dimension}}', dimensionName]) ||
        `Das Merkmal ${dimensionName} ist durchschnittlich ausgeprägt.`;
    }
  };

  const getCalloutText = (value, dimensionId) => {
    if (resultIsStrong(value)) {
      return translate(`rmp_report_${dimensionId}_strong_details`);
    } else if (resultIsWeak(value)) {
      return translate(`rmp_report_${dimensionId}_weak_details`);
    }
    return translate(`rmp_report_${dimensionId}_average_details`);
  };

  const getResults = () => {
    if (showRange) {
      return results;
    }

    return results.sort((item1, item2) => {
      // sort descending
      if (Math.abs(item2.result) > Math.abs(item1.result)) {
        return 1;
      }
      if (Math.abs(item2.result) < Math.abs(item1.result)) {
        return -1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (openReport) {
      setReportPageVisible(true);
    }
  }, [openReport]);

  if (!results && (rmp.resultsPending === undefined)) {
    return (
      <div className={styles.rmpResult}>
        <RmpResultSkeleton/>
      </div>
    );
  }

  return (
    <div className={classNames(styles.rmpResult)}>
      <div className={styles.container}>
        <div className={styles.mainHeader}>
          {exportView ?
            <span className='blu-typeLabel'>{translate('rmp_results_title') || 'Reiss Motivation Profile®'}</span> :
            <InfoTitle
              title={translate('rmp_results_title') || 'Reiss Motivation Profile®'}
              onClick={() => {
                setModalHeader(translate('rmp_results_info_title') || DEFAULT_INFO_TITLE);
                setModalSubHeader(null);
                setModalScrollable(false);
                setModalPrimaryButtonTitle(null);
                setShowModalFeedBack(false);
                setModalContent(translate('rmp_results_info_description') || DEFAULT_INFO_DESCRIPTION);

                setShowModal(true);
              }}
            />
          }

          {(!bp.isXs && !exportView && showExportIcon && !rmp.resultsPending && !noDataText) &&
          <DownloadIcon onClick={onExport}/>
          }
        </div>

        {noDataText && <div className={styles.noDataText}>{noDataText}</div>}

        {showPendingState && rmp.resultsPending &&
        <div className={styles.pendingText}>
          {translate('rmp_results_pending_copy') ||
          'Die Ergebnisse werden berechnet und stehen in Kürze zur Verfügung.'
          }
        </div>
        }

        {!noDataText &&
        <>
          <div className={styles.rows} ref={ref}>
            {getResults()
              .filter(resultItem => isValid(resultItem.result) || resultItem.range)
              .map((resultItem, index) => {
                return (
                  <RmpResultItem
                    key={`dimension-${index}`}
                    width={diagramWidth}
                    exportView={exportView}
                    dimensionId={resultItem.id}
                    dimension={resultItem.name}
                    score={resultItem.result}
                    range={resultItem.range}
                    onInfoClick={() => {
                      if (!showReportButton) {
                        setModalHeader(translate(`rmp_results_details_title_${resultItem.id}`) || DEFAULT_INFO_TITLE);
                        setModalContent(
                          translate(`rmp_results_details_description_${resultItem.id}`) || DEFAULT_INFO_DESCRIPTION
                        );

                        setShowModal(true);
                        return;
                      }

                      setCurrentDimensionIndex(index);

                      setModalHeader(translate(`rmp_results_details_title_${resultItem.id}`) || DEFAULT_INFO_TITLE);
                      setModalSubHeader(getSubHeader(translate(`rmp_results_label_${resultItem.id}`) ||
                        resultItem.name, resultItem.result));
                      setModalScrollable(true);
                      setModalPrimaryButtonTitle(translate('go_to_report_lbl') || 'Zum Report');
                      setShowModalFeedBack(true);

                      setModalContent(
                        <div className={styles.infoModalResult}>
                          <div className={styles.reportDiagram}>
                            <div className={styles.header}>
                              <span>{translate('big5_report_facet_result') || 'Ergebnis'}</span>
                              <span>{setDecimalSeperator(resultItem.result, 2)}</span>
                            </div>

                            <RmpBipolarDiagram
                              width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
                              //+2 to avoid negative values as they're not supported in svg
                              //range values for rmp: [-2, 2]
                              score={resultItem.result + 2}
                              showNumbers
                            />

                            <div className={styles.footer}>
                              <span className={resultItem.result < -RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
                                {translate('rmp_results_scale_min') || 'gering'}
                              </span>
                              <span className={resultItem.result > RMP_FIRST_MARKER_POSITION ? styles.blue: null}>
                                {translate('rmp_results_scale_max') || 'stark'}
                              </span>
                            </div>
                          </div>

                          <div className={styles.callout}>
                            <Callout trianglePosition={'center'}>
                              {getCalloutText(resultItem.result, resultItem.id)}
                            </Callout>
                          </div>

                          <RmpResultTable
                            dimensionId={resultItem.id}
                            score={resultItem.result}
                          />
                        </div>
                      );

                      setShowModal(true);
                    }}
                  />
                )
              })}
          </div>

          {(showResult && !showRange && showReportButton && !exportView) &&
          <Button
            size={'S'}
            looks='secondary'
            onClick={() => setReportPageVisible(true)}
          >
            {translate('rmp_results_button_showreport') || 'Report ansehen'}
          </Button>
          }

          {footerText && <div className={styles.footerText}>{footerText}</div>}

          <div className={styles.footerText}>
            <span onClick={() => {
              setModalHeader(translate('rmp_results_info_title_copyright') || DEFAULT_COPYRIGHT_TITLE);
              setModalSubHeader(null);
              setModalScrollable(false);
              setModalPrimaryButtonTitle(null);
              setShowModalFeedBack(false);
              setModalContent(translate('rmp_results_info_description_copyright') || DEFAULT_COPYRIGHT_DESCRIPTION);

              setShowModal(true);
            }}>
              {translate('rmp_results_copyrightlink') || DEFAULT_COPYRIGHT_TITLE}
            </span>
          </div>
        </>
        }
      </div>

      {showModal &&
      <Modal
        header={modalHeader}
        subHeader={modalSubHeader}
        primaryButtonTitle={modalPrimaryButtonTitle}
        secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'scrhließen'}
        onClose={() => {
          dispatch(initSubmitContentFeedback());
          setShowModal(false);
        }}
        onConfirm={modalScrollable ?
          (() => {
            setShowModal(false);
            setReportPageVisible(true);
          }) :
          (() => setShowModal(false))
        }
        feedbackSubmitted={feedbackSubmittedSuccessfully}
        onFeedbackSubmit={(requestFeedback && showModalFeedback) ?
          ((feedback) => {
            dispatch(submitContentFeedback({
              contentId: results[currentDimensionIndex].id,
              contentType: 'recommendation',
              helpful: feedback === 'Yes'
            }))
          }) :
          null
        }
      >
        {modalContent}
      </Modal>
      }

      { reportPageVisible && createPortal(
        <RmpReport
          strongResults={strongResults}
          weakResults={weakResults}
          averageResults={averageResults}
          onClose={() => setReportPageVisible(false)}
        />,
        document.body,
      )}
    </div>
  )
});

export default RmpResult;
