import React from 'react';
import styles from './Callout.module.scss';
import classNames from 'classnames';


const Callout = (props) => {
  const {trianglePosition = 'center', triangleOnTop = true, triangleOnBottom,  children} = props;

  return (
    <div className={styles.callout}>
      {triangleOnTop &&
      <div className={classNames(styles.triangleOnTop, styles[trianglePosition])}></div>
      }

      <div className={styles.text}>
        {children}
      </div>

      {triangleOnBottom &&
      <div className={classNames(styles.triangleOnBottom, styles[trianglePosition])}></div>
      }
    </div>
  )
};

export default Callout;
