import * as fromActions from './candidate.actions';

const initialState = {
  baseInfo: {
    loading: false,
    // id
    // avatar image
    // name
    // documents & links
    // ..
  },
  summary: {
    loading: false,
    // ..
  },
  assessments: {
    loading: false,
    // ..
  },
  roleFit: {
    loading: false,
    // ..
  }
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromActions.RESET: {
      return {
        ...state,
        baseInfo: {loading: false},
        assessments: {loading: false}
      };
    }
    case fromActions.GET_CANDIDATE: {
      const showLoading = action.payload.showLoading;

      return {
        ...state,
        baseInfo: {...state.baseInfo, loading: showLoading, }
      };
    }
    case fromActions.GET_CANDIDATE_SUCCESS: {
      return {
        ...state,
        baseInfo: {loading: false, ...action.payload.data}
      };
    }
    case fromActions.GET_CANDIDATE_FAIL: {
      return {
        ...state,
        baseInfo: {loading: false, error: action.payload.error}
      };
    }
    case fromActions.GET_CANDIDATE_ASSESSMENTS: {
      const showLoading = action.payload.showLoading;

      return {
        ...state,
        assessments: {...state.assessments, loading: showLoading}
      };
    }
    case fromActions.GET_CANDIDATE_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        assessments: {loading: false, results: action.payload.data}
      };
    }
    case fromActions.GET_CANDIDATE_ASSESSMENTS_FAIL: {
      return {
        ...state,
        assessments: {loading: false, error: action.payload.error}
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
