import React from 'react';

import { Breathe } from '../intermissions/Breathe';

// OTHER COMPONENTS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import NineLevelsRegistration from '../intermissions/NineLevelsRegistration';
import NineLevelsStage from '../intermissions/NineLevelsStage';
import NineLevelsEnd from '../intermissions/NineLevelsEnd';


// UTILS
import { translate } from 'utils/translator/translator';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';


const breatheText = `[blu-markdown]3x tief durchatmen.
Du hast es gleich geschafft!`;

export const nineLevelsConfig = {
  id: '9Levels',
  title: '9 Levels',

  // config
  // storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  // storageValidityWindow: 0,
  storageValidityByConfigVersion: true,
  configVersion: '1.0',

  // navigation
  allowBackNavigation: false,

  // ui
  modalHelpContentTranslationKey: 'ninelevels_assessment_help_info_content',

  loadingPage: {
    render: (state) => (
      <AssessmentIntro
        assessmentType={ASSESSMENT_TYPES.NINE_LEVELS}
      />
    )
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType={ASSESSMENT_TYPES.NINE_LEVELS}
          onClickNext={() => next()}
        />
      )
    },
    // REGISTRATION
    {
      insertAtIndex: 1,
      showProgressBar: true,
      preventKeyboardNext: true,
      render: (next, prev, state, extras) => {
        return (
          <NineLevelsRegistration
            // extraAssesmentInfo passed in from NineLevelsAssessmentPage
            assessmentDetails={state.assessmentDetails}
            extras={extras}
            clickBlock={state.clickBlock}
            onClickNext={() => { next() }}
          />
        );
      }
    },
    // GUIDANCE
    {
      insertAtIndex: 2,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.NINE_LEVELS}
          onClickNext={() => next()}
        />
      )
    },
    // BREATHE
    {
      insertAtIndex: 3,
      hideOnBackNavigation: true,
      render: (next, prev, state) => (
        <Breathe
          text={translate('assessment_interstitial') || breatheText}
          onSkip={next}
        />
      )
    },
    // STAGE 2
    {
      insertAtIndex: 4,
      showProgressBar: true,
      hideOnBackNavigation: true,
      preventKeyboardNext: true,
      render: (next, prev, state, extras) => (
        <NineLevelsStage
          answers={state.answers}
          state={state}
          extras={extras}
          nextStageNumber={2}
          onNext={() => {
            next();
          }}
        />
      )
    },
    // STAGE 3
    {
      insertAtIndex: 5,
      showProgressBar: true,
      hideOnBackNavigation: true,
      preventKeyboardNext: true,
      render: (next, prev, state, extras) => (
        <NineLevelsStage
          answers={state.answers}
          state={state}
          extras={extras}
          nextStageNumber={3}
          onNext={() => {
            next();
          }}
        />
      )
    },
    // STAGE 4
    {
      insertAtIndex: 6,
      showProgressBar: true,
      hideOnBackNavigation: true,
      preventKeyboardNext: true,
      render: (next, prev, state, extras) => (
        <NineLevelsStage
          answers={state.answers}
          state={state}
          extras={extras}
          nextStageNumber={4}
          onNext={() => {
            next();
          }}
        />
      )
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => (
        <NineLevelsEnd
          state={state}
          extras={extras}
        />
      )
    }
  ]
};
