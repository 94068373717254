import React from 'react';
import styles from './MindfulnessJourneyScore.module.scss';

import {ReactComponent as BlueVector} from 'assets/balanced-you/blue-vector.svg';
import {ReactComponent as GrayVector} from 'assets/balanced-you/gray-vector.svg';

import { useTranslate } from 'utils/translator';

const ROTATION_DEGREE_STEP = 12;
const ONE_SIDE_ITEMS_NUMBER = 5;


const MindfulnessJourneyScore = (props) => {
  const {value, prevMonthValue, title} = props;

  const translate = useTranslate();

  let blueVectorDeg = ((value - ONE_SIDE_ITEMS_NUMBER - 1) * ROTATION_DEGREE_STEP) + ((value - ONE_SIDE_ITEMS_NUMBER) + 3);
  let grayVectorDeg = ((prevMonthValue - ONE_SIDE_ITEMS_NUMBER - 1) * ROTATION_DEGREE_STEP) + ((prevMonthValue - ONE_SIDE_ITEMS_NUMBER) + 3);

  return (
    <div className={styles.mindfulnessJourneyScore}>
      <div className={styles.diagram}>
        <svg width="171" height="106">
          <path d="M10 70 C 47 0, 124 0, 165, 70" stroke="#E3E3E3" strokeWidth="14" strokeDasharray={'17 3'} fill={'none'}/>
        </svg>

        {prevMonthValue && <GrayVector className={styles.vector} style={{transform: `rotate(${grayVectorDeg}deg)`}} />}
        {value && <BlueVector className={styles.vector} style={{transform: `rotate(${blueVectorDeg}deg)`}} />}
      </div>

      <div className={styles.title}>
        {title}: <span>{` ${value || (translate('mind_journey_score_no_value') || 'At least 3 completed assessments needed')}`}</span>
      </div>

      <div className={styles.legend}>
        {value &&
        <div className={styles.blue}>
          <div className={styles.rect}></div>
          <span>{translate('mind_journey_score_current') || 'current'}</span>
        </div>
        }

        {prevMonthValue &&
        <div className={styles.gray}>
          <div className={styles.rect}></div>
          <span>{translate('mind_journey_score_prev_state') || 'previous state'}</span>
        </div>
        }
      </div>
    </div>
  )
};

MindfulnessJourneyScore.defaultProps = {
  title: 'Mindfulness Journey Score'
};

export default MindfulnessJourneyScore;
