import * as fromActionTypes from 'store/actionTypes/wellBeing';

export const initSubmitWellBeingIndex = () => {
  return {
    type: fromActionTypes.INIT_SUBMIT_WELLBEING_INDEX,
    payload: {}
  };
};

export const submitWellBeingIndex = (dto) => {
  return {
    type: fromActionTypes.SUBMIT_WELLBEING_INDEX,
    payload: {dto}
  };
};

export const submitWellBeingIndexFulfilled = () => {
  return {
    type: fromActionTypes.SUBMIT_WELLBEING_INDEX_FULFILLED,
    payload: {}
  };
};

export const submitWellBeingIndexRejected = ({ error }) => {
  return {
    type: fromActionTypes.SUBMIT_WELLBEING_INDEX_REJECTED,
    payload: { error }
  };
};

//GET WELL-BEING HISTORY OF INDIVIDUALS
export const getWellBeingHistory = () => {
  return {
    type: fromActionTypes.GET_WELLBEING_HISTORY,
    payload: {}
  };
};

export const getWellBeingHistoryFulfilled = ({wellBeingHistory}) => {
  return {
    type: fromActionTypes.GET_WELLBEING_HISTORY_FULFILLED,
    payload: {wellBeingHistory}
  };
};

export const getWellBeingHistoryRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_WELLBEING_HISTORY_REJECTED,
    payload: {error}
  };
};

//GET WELL-BEING INDEX HISTORY OF INDIVIDUALS
export const getWellBeingIndexHistory = (mode) => {
  return {
    type: fromActionTypes.GET_WELLBEING_INDEX_HISTORY,
    payload: {mode}
  };
};

export const getWellBeingIndexHistoryFulfilled = ({wellBeingHistory}) => {
  return {
    type: fromActionTypes.GET_WELLBEING_INDEX_HISTORY_FULFILLED,
    payload: {wellBeingHistory}
  };
};

export const getWellBeingIndexHistoryRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_WELLBEING_INDEX_HISTORY_REJECTED,
    payload: {error}
  };
};

//GET WELL-BEING INDEX OF INDIVIDUALS
export const getWellBeingIndex = () => {
  return {
    type: fromActionTypes.GET_WELLBEING_INDEX,
    payload: {}
  };
};

export const getWellBeingIndexFulfilled = ({wellBeing}) => {
  return {
    type: fromActionTypes.GET_WELLBEING_INDEX_FULFILLED,
    payload: {wellBeing}
  };
};

export const getWellBeingIndexRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_WELLBEING_INDEX_REJECTED,
    payload: { error }
  };
};

//GET WELL-BEING INDEX OF ORGANIZATION
export const getOrgWellBeingIndex = () => {
  return {
    type: fromActionTypes.GET_ORG_WELLBEING_INDEX,
    payload: {}
  };
};

export const getOrgWellBeingIndexFulfilled = ({wellbeing, users, notEnoughData }) => {
  return {
    type: fromActionTypes.GET_ORG_WELLBEING_INDEX_FULFILLED,
    payload: {wellbeing, users, notEnoughData}
  };
};

export const getOrgWellBeingIndexRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_ORG_WELLBEING_INDEX_REJECTED,
    payload: {error}
  };
};

//GET WELL-BEING INDEX HISTORY OF ORGANIZATION
export const initGetOrgWellBeingHistory = () => {
  return {
    type: fromActionTypes.INIT_GET_ORG_WELLBEING_HISTORY,
    payload: {}
  };
};

export const getOrgWellBeingHistory = (mode) => {
  return {
    type: fromActionTypes.GET_ORG_WELLBEING_HISTORY,
    payload: {mode}
  };
};

export const getOrgWellBeingHistoryFulfilled = ({wellBeingHistory}) => {
  return {
    type: fromActionTypes.GET_ORG_WELLBEING_HISTORY_FULFILLED,
    payload: {wellBeingHistory}
  };
};

export const getOrgWellBeingHistoryRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_ORG_WELLBEING_HISTORY_REJECTED,
    payload: { error }
  };
};


