
// ROUTE CONFIGS
import { routesConfigAdmin } from 'features/+admin/routeNext';
import { routesConfigCore } from 'features/+core/routeNext';
import { routesConfigBalancedYou } from 'features/+balancedYou/routeNext';
import { routesConfigEmployees } from 'features/+employees/routeNext';
import { routesConfigRoles } from 'features/+roles/routeNext';
import { routesConfigTeams } from 'features/+teams/routeNext';
import { routesConfigCandidates } from 'features/+candidates/routeNext';
import { routesConfigStatic } from 'features/+static/routeNext';
import { routesConfigFramework } from 'features/framework/routeNext';

// Order is important here as the routes
// are created and put in the dom and main nav based on this sequence
// rearrangements can be made with inMainNav.forceIndex
export const routesConfigs = [

  // ADMIN
  routesConfigAdmin,

  // CORE
  routesConfigCore,

  // BALANCED YOU (before framework etc so we can override pages)
  routesConfigBalancedYou,

  // EMPLOYES
  routesConfigEmployees,

  // ROLES
  routesConfigRoles,

  // TEAMS
  routesConfigTeams,

  // CANDIDATES
  routesConfigCandidates,

  // STATIC
  routesConfigStatic,

  // FRAMEWORK
  routesConfigFramework,

];

export const getAllRoutes = () => {
  const routes = routesConfigs.flatMap((config) => {
    return config.routes;
  });

  return routes;
};
