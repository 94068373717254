import React, { useState, useEffect, useRef } from 'react';
// component
import styles from './DropDownSubtle.module.scss';
// Components
import { SelectedIndicator, BluCSSTransition} from 'ui/basic';
// Project
import { handleRipple } from 'utils/ripple';
import { ReactComponent as ArrowDown } from 'assets/icons/icn_arrow_down.svg';
// 3rd party
import classNames from 'classnames';


const DropDownSubtle = (props) => {
  // props
  const { className, options = [], activeIndex = 0, onChange, children } = props;

  const [flyoutIsOpen, setFlyoutIsOpen] = useState(false);
  const [activeValue, setActiveValue] = useState((options && options[activeIndex] && options[activeIndex].label) || '');
  const [indicatorPosition, setIndicatorPosition] = useState(null);

  // refs
  const activeMenuItem = useRef(null);

  // effect to set current indicator position
  useEffect(() => {
    // console.log('Dropdown', activeIndex);
    setActiveValue(options[activeIndex] && options[activeIndex].label);
    if (activeMenuItem.current && flyoutIsOpen) {
      setIndicatorPosition(activeMenuItem.current.offsetTop);
    }
  }, [options, activeIndex, flyoutIsOpen]);

  return (
    <div
      tabIndex={'0'}
      className={classNames(styles.container, styles[className])}
      onBlur={() => setFlyoutIsOpen(false)}>
      <div
        className={classNames(styles.dropDownSubtle, {
          [styles.open]: flyoutIsOpen
        })}
        onClick={() => setFlyoutIsOpen(!flyoutIsOpen)}
      >
        <span>{activeValue}</span>
        <ArrowDown />
      </div>
      <BluCSSTransition in={flyoutIsOpen} classNames={{ ...styles }}>
        <div className={styles.flyout}>
          <div className={styles.title}>

            {/* children */}
            {children}

          </div>
          {indicatorPosition !== null && (
            // render only with first real indicator position
            // so first appearance of indicator is immediate and not transitioned
            <SelectedIndicator position={indicatorPosition} />
          )}
          {options.map((option, index) => {
            const menuItemProps = {
              key: index,
              className: classNames(styles.menuItem, {
                [styles.active]: activeIndex === index
              }),
              onClick: event => {
                handleRipple(event, styles.colorPrimary3);
                onChange(index);
                window.setTimeout(() => {
                  setFlyoutIsOpen(false);
                }, styles.animationDurationLongMs);
              }
            };
            // determine activeMenu item and then set it as ref
            if (activeIndex === index) {
              menuItemProps.ref = activeMenuItem;
            }

            return (
              <div {...menuItemProps}>
                <span>{option.label}</span>
              </div>
            );
          })}
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default DropDownSubtle;
