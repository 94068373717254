export const TOGGLE_TYPE = '[FILTER LIST ENTITIES] TOGGLE_TYPE';
export const toggleType = ({ entity }) => {
  return {
    type: TOGGLE_TYPE,
    payload: { entity }
  };
};

export const RESET = '[FILTER LIST ENTITIES] RESET';
export const reset = ({ entity }) => {
  return {
    type: RESET,
    payload: { entity }
  };
};

export const GET_LIST_ITEMS = '[FILTER LIST ENTITIES] GET_LIST_ITEMS';
export const getListItems = ({ entity, dynamicConfig, showLastDataWhileLoading }) => {
  return {
    type: GET_LIST_ITEMS,
    payload: { entity, dynamicConfig, showLastDataWhileLoading }
  };
};

export const GET_LIST_ITEMS_SUCCESS = '[FILTER LIST ENTITIES] GET_LIST_ITEMS_SUCCESS';
export const getListItemsSuccess = ({ entity, listItems, numberOfEntries, noDataAtAll, noDataDuringCalculation}) => {
  return {
    type: GET_LIST_ITEMS_SUCCESS,
    payload: { entity, listItems, numberOfEntries, noDataAtAll, noDataDuringCalculation }
  };
};

export const GET_LIST_ITEMS_FAIL = '[FILTER LIST ENTITIES] GET_LIST_ITEMS_FAIL';
export const getListItemsFail = ({ entity, error }) => {
  return {
    type: GET_LIST_ITEMS_FAIL,
    payload: { entity, error }
  };
};

export const SET_SEARCH_STRING = '[FILTER LIST ENTITIES] SET_SEARCH_STRING';
export const setSearchString = ({ entity , searchString }) => {
  return {
    type: SET_SEARCH_STRING,
    payload: { entity, searchString }
  };
};

export const SET_SORT = '[FILTER LIST ENTITIES] SET_SORT';
export const setSort = ({ entity, sortBy, sortDirection, extraSearchParams }) => {
  return {
    type: SET_SORT,
    payload: { entity, sortBy, sortDirection, extraSearchParams }
  };
};

export const SET_PAGE_INDEX = '[FILTER LIST ENTITIES] SET_PAGE_INDEX';
export const setPageIndex = ({ entity, pageIndex }) => {
  return {
    type: SET_PAGE_INDEX,
    payload: { entity, pageIndex }
  };
};
