// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ModalWrapper.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: ModalWrapper
const ModalWrapper = (props) => {

  // PROPS
  const {
    children,
    noAnimate = false, // disable slide up animation
    instantBackground = false, // immediately show white background without fade in
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: ModalWrapper
  return (
    <div className={classNames(styles.modalWrapper, {
      [styles.instantBackground]: instantBackground,
      [styles.animate]: !noAnimate
    })}>
      <div className={classNames(styles.content)}>
        { children }
      </div>
    </div>
  );
};

export default ModalWrapper;
