import { useContext } from 'react';

// STORE
import store from 'store';
import { getShowTranslationKeys } from 'store/selectors/debug';

// UTILS
import { TranslateContext } from './translator.context';
import { markdown } from 'utils/textTools';
import { SUB_CONFIGURATION, SUB_CONFIGURATION_OPTIONS, ALLOW_TRANSLATION_DISABLE } from 'utils/configuration';


/**
 * hook to provide translate function (context set up in main)
 * @returns translate function (id, replacements) with current translations from store
 */
export const useTranslate = () => {
  const { translate } = useContext(TranslateContext);

  return translate;
};


export const getTranslate = (translations, showTranslationKeys) => {
  return (id, replacements) => {
    return getTranslation({
      translations,
      showTranslationKeys,
      id,
      replacements
    })
  };
};
const getTranslation = ({ translations, showTranslationKeys, id, replacements }) => {
  if (!translations) return '';

  let translation;
  //translation from copybook strings
  translation = ALLOW_TRANSLATION_DISABLE && showTranslationKeys
    ? id
    : translations[id] || id || '';

  translation = replace(translation, replacements);

  // BALANCED_YOU replacement
  if (SUB_CONFIGURATION === SUB_CONFIGURATION_OPTIONS.MINDFULNESS_JOURNEY) {
    const find = 'Balanced You';
    const replaceWith = 'Mindfulness Journey';
    const regex = new RegExp(find, 'g');
    translation = translation.replace(regex, replaceWith);
  }

  // transform to markdown if it contains [blu-markdown]
  return markdown(translation);
};


/**
 * direct use deprecated: depends on other rerenders when store changes happen
 * @param {*} id
 * @param {*} replacements
 * @returns
 */
export const translate = (id, replacements = []) => {
  const state = store.getState();
  const translations = state && state.localisation && state.localisation.translations;
  const showTranslationKeys = getShowTranslationKeys(state);

  return getTranslation({ translations, showTranslationKeys, id, replacements });
};

export const replace = (translation = '', replacements = []) => {
  //replacement
  if (replacements.length > 0 && replacements.length % 2 === 0) {
    for (let i = 0; i <= (replacements.length / 2); i+=2) {
      const regex = new RegExp(replacements[i], 'g');
      translation = translation.replace(regex, replacements[i + 1]);
    }
  }
  return translation;
};
