import * as fromActions from './garmin.actions';

const initialState = {
  loading: false,
  accountConnected: null,
  userScore: null,
  userScorePending: false,
  userHistory: null,
  orgScore: null,
  orgScorePending: false,
  orgHistory: null
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromActions.GET_USER_SCORE: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_USER_SCORE_SUCCESS: {
      let userScore;
      let userScorePending = false;

      const data = action.payload.data;
      if (data) {
        if ((!data.bodyBattery && !data.sleep && !data.stress) || (!data.bodyBattery.score && !data.sleep.score && !data.stress.score)) {
          userScorePending = true;
        } else {
          userScore = data;
        }
      }

      return {
        ...state,
        loading: false,
        userScore,
        userScorePending
      };
    }
    case fromActions.GET_USER_SCORE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case fromActions.GET_CONNECTION_STATUS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_CONNECTION_STATUS_SUCCESS: {
      const data = action.payload.data;

      return {
        ...state,
        loading: false,
        accountConnected: data.connected
      };
    }
    case fromActions.GET_CONNECTION_STATUS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case fromActions.GET_USER_HISTORY: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_USER_HISTORY_SUCCESS: {
      let userHistory;
      const data = action.payload.data;
      if (data &&
        ((data.bodyBattery && data.bodyBattery.length) || (data.sleep && data.sleep.length) || (data.stress && data.stress.length))
      ) {
        userHistory = data;
      }

      return {
        ...state,
        loading: false,
        userHistory
      };
    }
    case fromActions.GET_USER_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        userHistory: null
      };
    }
    case fromActions.GET_ORG_SCORE: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_ORG_SCORE_SUCCESS: {
      let orgScore;
      let orgScorePending = false;

      const data = action.payload.data;
      if (data) {
        if ((!data.bodyBattery && !data.sleep && !data.stress) ||
          (!data.bodyBattery.score && !data.sleep.score && !data.stress.score)
        ) {
          orgScorePending = true;
        } else {
          orgScore = data;
        }
      }

      return {
        ...state,
        loading: false,
        orgScore,
        orgScorePending
      };
    }
    case fromActions.GET_ORG_SCORE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case fromActions.GET_ORG_HISTORY: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_ORG_HISTORY_SUCCESS: {
      let orgHistory;
      const data = action.payload.data;
      if (data &&
        ((data.bodyBattery && data.bodyBattery.length) || (data.sleep && data.sleep.length) || (data.stress && data.stress.length))
      ) {
        orgHistory = data;
      }

      return {
        ...state,
        loading: false,
        orgHistory
      };
    }
    case fromActions.GET_ORG_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        orgHistory: null
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
