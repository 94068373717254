import React, {useState} from 'react';
import styles from './SegmentedChooser.module.scss';
import classNames from 'classnames';


const SegmentedChooser = (props) => {
  const {size, value, segmentsCount, onChange} = props;

  let valueInternal;
  if (Number.isInteger(value)) {
    valueInternal = value;
  } else {
    valueInternal = value < 1 ? 1 : Math.floor(value);
  }

  const [clickedCellNumber, setClickedCellNumber] = useState(valueInternal);
  const [hoveredCellNumber, setHoveredCellNumber] = useState();

  const getCells = () => {
    const cells = [];
    for (let i=1; i<=segmentsCount; i++) {
      const cell =
        <div
          key={`competencies-cell-${i}`}
          className={classNames(
            styles.cell,
            {[styles.blue]: clickedCellNumber && clickedCellNumber >= i},
            {[styles.hellBlue]: (!clickedCellNumber || i > clickedCellNumber) && (hoveredCellNumber && hoveredCellNumber >= i)},
            {[styles.grey]: !clickedCellNumber || !hoveredCellNumber}
          )}
          onClick={() => {
            setClickedCellNumber(i);

            if (onChange) {
              onChange(i);
            }
          }}
          onMouseOver={() => setHoveredCellNumber(i)}
          onMouseOut={() => setHoveredCellNumber(null)}
        >
        </div>;
      cells.push(cell);
    }
    return cells;
  };

  return (
    <div className={classNames(styles.segmentedChooser, styles[`size${size}`])}>
      {getCells()}
    </div>
  )
};

SegmentedChooser.defaultProps = {
  size: 'L',
  segmentsCount: 5
}

export default SegmentedChooser;
