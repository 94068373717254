import React, {memo, useEffect, useRef, useState} from 'react';
import styles from './Checkbox.module.scss';
import classnames from 'classnames';

const Checkbox = memo((props) => {
  const {isError, disabled, checked, name, onChange} = props;

  const checkmarkLabelRef = useRef();
  const checkmarkRef = useRef();

  const [isChecked, setIsChecked] = useState(checked);

  const classNames = classnames(
    styles.checkbox,
    {[styles.error]: isError},
    {[styles.disabled]: disabled},
    {[styles.checked]: isChecked});

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleKey = (event) => {
    const key = event.key;

    switch (key) {
      case ' ': {
        event.stopPropagation();
        if (disabled) return;
        setIsChecked(!isChecked);
        onChange(!isChecked)
        return;
      }
      default: {
        return;
      }
    }
  };

  return (
    <label tabIndex={0}
      className={classNames}
      onClick={(event) => {
        if (disabled || (event.target !== checkmarkLabelRef.current && event.target !== checkmarkRef.current)) {
          return;
        }
        setIsChecked(!isChecked);
        onChange(!isChecked);
      }}
      onKeyUp={handleKey}
    >
      <span className={styles.name} ref={checkmarkLabelRef}>{name}</span>
      <span className={styles.checkmark} ref={checkmarkRef}></span>
    </label>
  );
});

export default Checkbox;
