export const INIT_REGISTER_ASSESSMENT = 'INIT_REGISTER_ASSESSMENT';
export const REGISTER_ASSESSMENT = 'REGISTER_ASSESSMENT';
export const REGISTER_ASSESSMENT_FULFILLED = 'REGISTER_ASSESSMENT_FULFILLED';
export const REGISTER_ASSESSMENT_REJECTED = 'REGISTER_ASSESSMENT_REJECTED';

export const INIT_ASSESSMENTS_ERROR = 'INIT_ASSESSMENTS_ERROR';

export const SAVE_ASSESSMENT_ANSWER = 'SAVE_ASSESSMENT_ANSWER';
export const SAVE_ASSESSMENT_ANSWER_FULFILLED = 'SAVE_ASSESSMENT_ANSWER_FULFILLED';
export const SAVE_ASSESSMENT_ANSWER_REJECTED = 'SAVE_ASSESSMENT_ANSWER_REJECTED';
