import React, {memo, useState} from 'react';
import styles from './PotentialResult.module.scss';


// ASSETS
import {IconsSvg} from 'assets/icons';

// UTILS
import { useTranslate } from 'utils/translator';
import PotentialResultItem from './PotentialResultItem';
import PotentialResultSkeleton from './PotentialResultSkeleton';
import {InfoTitle, Modal, LineDiagram, Callout} from 'ui/basic';
import {initSubmitContentFeedback, submitContentFeedback} from 'store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {useBreakpoint} from 'utils/hooks';
import { setDecimalSeperator } from 'utils/userpreference';


const DEFAULT_INFO_TITLE = 'Optimism';
const DEFAULT_INFO_DESCRIPTION =
  'Optimism is a mental attitude reflecting a belief or' +
  'hope that the outcome of some specific endeavor,' +
  'or outcomes in general, will be positive, favorable,' +
  'and desirable';

const LOW = [1, 3.9]
const MEDIUM = [4, 6.9];


const PotentialResult = memo((props) => {
  const {
    results,
    footerText,
    noDataText,
    showCallout,
    showDimensionReport = true,
    requestFeedback = true,
    showExportIcon,
    exportView,
    onExport = () => {}
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  const [currentDimensionId, setCurrentDimensionId] = useState();

  const [showModal, setShowModal] = useState();
  const [modalHeader, setModalHeader] = useState();
  const [modalSubHeader, setModalSubHeader] = useState();
  const [modalContent, setModalContent] = useState();
  const [showModalFeedback, setShowModalFeedBack] = useState();

  const feedbackSubmittedSuccessfully = useSelector(state => state.survey && state.survey.success);

  const DownloadIcon = IconsSvg.DownloadSvg;

  const getSubHeader = (dimensionName, dimensionValue) => {
    if (dimensionValue >= LOW[0] && dimensionValue <= LOW[1]) {
      return translate('potential_dimension_low__short_descr',
        ['{{dimension}}', dimensionName]) ||
        `Das Merkmal ${dimensionName} ist gering ausgeprägt.`;
    }

    if (dimensionValue >= MEDIUM[0] && dimensionValue <= MEDIUM[1]) {
      return translate('potential_dimension_medium__short_descr',
        ['{{dimension}}', dimensionName]) ||
        `Das Merkmal ${dimensionName} ist mittel ausgeprägt.`;
    }

    return translate('potential_dimension_high__short_descr',
      ['{{dimension}}', dimensionName]) ||
      `Das Merkmal ${dimensionName} ist hoch ausgeprägt.`;
  }

  const getCalloutContent = (dimensionId, score) => {
    if (score >= LOW[0] && score <= LOW[1]) {
      return translate(`employee_result_low__${dimensionId}`) || `The ${dimensionId} is low`;
    }

    if (score >= MEDIUM[0] && score <= MEDIUM[1]) {
      return translate(`employee_result_medium__${dimensionId}`) || `The ${dimensionId} is medium`;
    }

    return translate(`employee_result_high__${dimensionId}`) || `The ${dimensionId} is high`;
  }

  const getPersonalRecommendation = (dimensionId, score) => {
    if (score >= LOW[0] && score <= LOW[1]) {
      return translate(`${dimensionId}_low_result_recommendation`);
    }

    if (score >= MEDIUM[0] && score <= MEDIUM[1]) {
      return translate(`${dimensionId}_medium_result_recommendation`);
    }

    return translate(`${dimensionId}_high_result_recommendation`)
  }

  if (!results) {
    return (
      <div className={styles.potentialResult}>
        <PotentialResultSkeleton/>
      </div>
    );
  }


  return (
    <div className={styles.potentialResult}>
      <div id={'container-id'} className={styles.container}>
        <div className={styles.mainHeader}>
          {exportView ?
            <span className='blu-typeLabel'>{translate('potential_results_title') || 'Potential'}</span> :
            <InfoTitle
              title={translate('potential_results_title') || 'Potential'}
              onClick={() => {
                setModalHeader(translate('potential_results_details_title') || DEFAULT_INFO_TITLE);
                setModalSubHeader(null);
                setModalContent(translate('potential_results_details_description') || DEFAULT_INFO_DESCRIPTION);
                setShowModalFeedBack(false);

                setShowModal(true);
              }}
            />
          }

          {(!bp.isXs && !exportView && showExportIcon) &&
          <DownloadIcon onClick={onExport}/>
          }
        </div>

        {noDataText && <div className={styles.noDataText}>{noDataText}</div>}

        {!noDataText &&
          <>
            <div className={styles.rows}>
              {results.map((result, index) => {
                return (
                  <PotentialResultItem
                    key={`${result.name}-${index}`}
                    index={index}
                    label={result.name}
                    result={result.result}
                    range={result.range}
                    exportView={exportView}
                    onInfoClick={() => {
                      if (!showDimensionReport) {
                        setModalHeader(translate(`potential_results_details_title_dim${index+1}`) || DEFAULT_INFO_TITLE);
                        setModalContent(translate(`potential_results_details_description_dim${index+1}`) || DEFAULT_INFO_DESCRIPTION);

                        setShowModal(true);
                        return;
                      }

                      setCurrentDimensionId(result.id);

                      setModalHeader(translate(`potential_results_details_title_dim${index+1}`) || DEFAULT_INFO_TITLE);
                      setModalSubHeader(getSubHeader(result.name, result.result));
                      setShowModalFeedBack(true);

                      setModalContent(
                        <div className={styles.infoModalResult}>
                          <div className={styles.reportDiagram}>
                            <div className={styles.header}>
                              <span>{translate('big5_report_facet_result') || 'Ergebnis'}</span>
                              <span>{setDecimalSeperator(result.result)}</span>
                            </div>

                            <LineDiagram
                              score={result.result}
                              width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
                            />

                            <div className={styles.footer}>
                              <span>{translate('big5_report_resut_hint1') || 'gering ausgeprägt'}</span>
                              <span>{translate('big5_report_resut_hint2') || 'stark ausgeprägt'}</span>
                            </div>
                          </div>

                          <div className={styles.copy}>
                            {translate(`potential_results_details_description_dim${index+1}`) || DEFAULT_INFO_DESCRIPTION}
                          </div>

                          {showCallout &&
                          <div className={styles.callout}>
                            <Callout>
                              {getCalloutContent(result.id, result.result)}

                              {/*personal recommendation text should be shown only for potential results on my profile page*/}
                              {window.location.href.includes('my-profile') &&
                              <div className={styles.copyStrong}>{getPersonalRecommendation(result.id, result.result)}</div>
                              }
                            </Callout>
                          </div>
                          }

                        </div>
                      );

                      setShowModal(true);
                    }}
                  />
                )
              })}
            </div>

          {footerText &&
            <div className={styles.footerText}>{footerText}</div>
          }
          </>
        }

        {showModal &&
        <Modal
          header={modalHeader}
          subHeader={modalSubHeader}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          feedbackSubmitted={feedbackSubmittedSuccessfully}
          onFeedbackSubmit={(requestFeedback && showModalFeedback) ?
            ((feedback) => {
              dispatch(submitContentFeedback({
                contentId: currentDimensionId,
                contentType: 'recommendation',
                helpful: feedback === 'Yes'
              }))
            }) :
            null
          }
          onClose={() => {
            dispatch(initSubmitContentFeedback());
            setShowModal(false);
          }}
          onConfirm={() => {
            dispatch(initSubmitContentFeedback());
            setShowModal(false);
          }}
        >
          {modalContent}
        </Modal>
        }
      </div>
    </div>
  )
});

export default PotentialResult;
