// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';

// ASSETS

// 3RD PARTY
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';

// OTHER COMPONENTS
import AssessmentNext from 'ui/molecules/AssessmentNext';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';
import {
  assessmentShouldCancel,
  handleAssessmentAnswer,
} from 'utils/assessment';


// STORE
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import {listAssessments} from 'store/actions';

// CONFIG & DATA

// COMPONENT: NineLevelsAssessmentPage
const NineLevelsAssessmentPage = (props) => {
  // PROPS

  // COMPONENT/UI STATE and REFS
  const assessmentId = ASSESSMENT_TYPES.NINE_LEVELS;
  const [ questions, setQuestions ] = useState();
  const [ userId, setUserId ] = useState();
  const [ configOverride, setConfigOverride ] = useState();

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  // STORE HOOKS
  const user = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const currentLanguage = useSelector(state => state.localisation && state.localisation.currentLanguage);

  // EFFECT HOOKS
  useEffect(() => {
    if (!currentLanguage || !user || !user.id) {
      return;
    }

    // TODO: deactivate through organisational setting
    let promise;

    // call cancel before getting assessment if we aren't in a valid storage state window
    const shouldCancel = assessmentShouldCancel('nineLevels', user.id);
    if (!shouldCancel) {
      promise = api.get('/core/assessments/9levels');
    }
    else {
      promise = api.post('core/assessments/9levels/cancel')
      .then(() => {
        return api.get('/core/assessments/9levels');
      });
    }

    // get assessment
    promise.then(({ status, ok, data }) => {

      // check: 'userAvailable' property for leader and admins
      // assessment can be deactivated in user settings
      if (
        [
          MAPPED_PLATFORM_ROLES.COMPANY_LEADER,
          MAPPED_PLATFORM_ROLES.COMPANY_ADMINISTRATOR
        ].includes(user.role)
        && !data.userAvailable
      ) {
        history.goBack();
      }

      // check: already completed
      if (data.progress === 1.0) {
        history.push('/my-profile?openReportFor=9levels')
      }

      setQuestions([]); // questions populated by Registration Component
      setUserId(user.id);
      setConfigOverride({
        // for registration page & stages
        assessmentDetails: {
          currentLanguage,
          ...data
        }
      });


    });
  }, [history, currentLanguage, user, translate]);

  // METHODS

  // EVENT HANDLES
  const handleCancel = () => {
    history.goBack();
  };

  const handleFinish = () => {
    dispatch(listAssessments());
    history.push('/my-profile?openReportFor=9levels');
  };

  const handleAnswer = (answer) => {
    handleAssessmentAnswer(answer, assessmentId);
  };

  const handleAllAnswers = (answers, setFinishable, setErrorEnd) => {
    // Note: answers are send to backend in NineLevelsEnd due to UX requirements
    console.log('handleAllAnswers', answers);
    // api
    // .post(`/core/assessments/9levels/1/answers`, {
    //   answers: Object.keys(answers).map(questionId => ({
    //     question: questionId,
    //     content: answers[questionId]
    //   }))
    // })
    // .then(() => {
    //   setFinishable(true);
    // })
    // .catch((error) => {
    //   console.error(error.message);
    //   setErrorEnd(error.message);
    // });
  };

  // HELPERS

  // RENDERS

  return (
    <AssessmentNext
      type='nineLevels'
      questions={questions}
      userId={userId}
      configOverride={configOverride}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer}
      onAllAnswers={handleAllAnswers}
    />
  );
};

export default NineLevelsAssessmentPage;
