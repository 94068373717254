import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/organization';
import * as fromActions from 'store/actions/organization';
import {get} from 'api';

function* getMindJourneyScore() {
  try {
    const { status, ok, data } = yield call(get, '/balancedyou/company/score');

    if (ok && status === 200) {
      yield put(fromActions.getMindJourneyScoreFulfilled({ score: data }));
    } else {
      yield put(fromActions.getMindJourneyScoreRejected({ error: data }));
    }
  } catch(error) {
    yield put(fromActions.getMindJourneyScoreRejected({ error }));
  }
}

function* getStatistic() {
  try {
    const { status, ok, data } = yield call(get, '/balancedyou/company/stats');

    if (ok && status === 200) {
      yield put(fromActions.getStatisticFulfilled({ statistic: data }));
    } else {
      yield put(fromActions.getStatisticRejected({ error: data }));
    }
  } catch(error) {
    yield put(fromActions.getStatisticRejected({ error }));
  }
}

function* getMindJourneyResults() {
  try {
    const { status, ok, data } = yield call(get, '/balancedyou/company/results');

    if (ok && status === 200) {
      yield put(fromActions.getMindJourneyResultsFulfilled({ results: data.results }));
    } else {
      yield put(fromActions.getMindJourneyResultsRejected({ error: data }));
    }
  } catch(error) {
    yield put(fromActions.getMindJourneyResultsRejected({ error }));
  }
}

export function* watchGetMindJourneyScore() {
  yield takeEvery(fromActionTypes.GET_MIND_JOURNEY_SCORE, getMindJourneyScore);
}

export function* watchGetStatistic() {
  yield takeEvery(fromActionTypes.GET_STATISTIC, getStatistic);
}

export function* watchGetMindJourneyResults() {
  yield takeEvery(fromActionTypes.GET_MIND_JOURNEY_RESULTS, getMindJourneyResults);
}
