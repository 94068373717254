// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './QuestionNationality.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDownSearchable } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import {useSelector} from 'react-redux';
import * as fromSelectors from 'store/selectors/staticValues';

// CONFIG & DATA
// const Config = {};


// COMPONENT: QuestionNationality
const QuestionNationality = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
   } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const nationalities = useSelector(fromSelectors.selectNationalities);

  // EVENT HANDLES
  const handleNationality = (nationality) => {
    onAnswer({ value: nationality, name: 'nationality', isValid: !!nationality });
  };

  // RENDER: QuestionNationality
  return (
    <div className={classNames(styles.questionNationality)}>

      {/* FORM */}
      <div className={styles.formRow}>
        <DropDownSearchable
          size={size}
          placeholder={translate('please_select_lbl') || 'Bitte wählen'}
          filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
          activeValue={answer.value || ''}
          options={nationalities}
          onChange={(option) => {
            handleNationality(option?.value ?? '');
          }}
        />
      </div>

    </div>
  );
};

export default QuestionNationality;
