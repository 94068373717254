import { createSelector } from '@reduxjs/toolkit';
import bqLogo from 'assets/bq_brandmark.svg';
import byLogo from 'assets/by_brandmark.svg';
import { CAPABILITIES_SWITCHES } from 'utils/configuration/const/capabilities';
import { CONFIGURATION, CONFIGURATION_OPTIONS } from 'utils/configuration';

export const selectConfiguration = (state) => state.configuration;

export const selectConfigurationInitialised = createSelector(
  selectConfiguration,
  (configuration) => configuration.initialised
)

export const selectConfigurationLoading = createSelector(
  selectConfiguration,
  (configuration) => configuration.loading
)

// DEPLOYMENT
export const selectDeployment = (state) => {
  const configuration = selectConfiguration(state);
  return configuration.deployment;
};

export const selectIsAdminFrontend = (state) => {
  const deployment = selectDeployment(state);
  return deployment.isAdminFrontend;
};

// COMPANY
export const selectCompany = (state) => {
  const configuration = selectConfiguration(state);
  return configuration.company;
};

export const selectCompanyHasExternalLegalAgreements = (state) => {
  const company = selectCompany(state);
  return company.hasExternalLegalAgreements;
};

export const selectCompanyCustomLogoUrl = (state) => {
  const company = selectCompany(state);
  return company.customLogoUrl;
};


const hasFeature = (featureId, features) => {
  return features.find(feature => feature.id === featureId && feature.active);
};

// FEATURES
const selectFeatures = createSelector(
  selectConfiguration,
  (configuration) => configuration.features
)
export const selectFeaturesNext = createSelector(
  selectFeatures,
  (storeFeatures) => {
    if (!storeFeatures.length) return;

    const features = {
      employees: true, // always true
      candidates: false,
      roles: false,
      teams: false,
      wellbeing: false,
      balancedYou: false,
    };

    if (hasFeature('MODULE_RECRUITING', storeFeatures)) {
      features.candidates = true;
    }
    if (hasFeature('MODULE_ROLEMAPPING', storeFeatures)) {
      features.roles = true;
    }
    if (hasFeature('MODULE_TEAMMAPPING', storeFeatures)) {
      features.teams = true;
    }
    if (hasFeature('MODULE_WELLBEING', storeFeatures)) {
      features.wellbeing = true;
    }
    if (hasFeature('MODULE_BALANCEDYOU', storeFeatures)) {
      features.balancedYou = true;
    }

    return features;
  }
)

const hasAssessment = (assessmentId, assessments) => {
  return assessments.find((assessment) => assessment.id === assessmentId);
};

export const selectAssessmentsNext = createSelector(
  selectFeatures,
  (storeFeatures) => {

    if (!storeFeatures.length) return;

    const assessments = {
      balancedYou: false,
      big5: false,
      keyComp: false,
      leadComp: false,
      potential: false,
      workPref: false,
      nineLevels: false,
      rmp: false,
    };

    // add all clear names from api
    const storeAssessments = storeFeatures
    .filter(feature => feature.category === 'assessment' && feature.active)
    .map((assessment) => {
      assessments[assessment.id] = true;
      return assessment;
    })


    // add simpler names for frontend
    if (hasAssessment('ASSESSMENT_BALANCED-YOU', storeAssessments)) {
      assessments.balancedYou = true;
    }
    if (hasAssessment('ASSESSMENT_BIG5', storeAssessments)) {
      assessments.big5 = true;
    }
    if (hasAssessment('ASSESSMENT_KEY-COMP', storeAssessments)) {
      assessments.keyComp = true;
    }
    if (hasAssessment('ASSESSMENT_LEAD-COMP', storeAssessments)) {
      assessments.leadComp = true;
    }
    if (hasAssessment('ASSESSMENT_POTENTIAL', storeAssessments)) {
      assessments.potential = true;
    }
    if (hasAssessment('ASSESSMENT_WORK-PREFERENCE', storeAssessments)) {
      assessments.workPref = true;
    }
    if (hasAssessment('ASSESSMENT_9LEVELS', storeAssessments)) {
      assessments.nineLevels = true;
    }
    if (hasAssessment('ASSESSMENT_RMP', storeAssessments)) {
      assessments.rmp = true;
    }

    return assessments;

  }
)

export const selectSwitches = createSelector(
  selectConfiguration,
  (configuration) => configuration.switches
);

const selectUserGroup = createSelector(
  selectConfiguration,
  (configuration) => configuration.userGroup
)
const selectUserSubGroup = createSelector(
  selectConfiguration,
  (configuration) => configuration.userSubGroup
)

export const selectCapabilitiesNext = createSelector(
  selectSwitches,
  selectUserGroup,
  selectUserSubGroup,
  (switches, userGroup, userSubGroup) => {
    const capabilitiesNext = {};

    if (userGroup === 'admin') {
      CAPABILITIES_SWITCHES.forEach((cs) => {
        capabilitiesNext[cs] = true;
      });
    }

    if (userGroup === 'leader') {
      CAPABILITIES_SWITCHES.forEach((cs) => {
        capabilitiesNext[cs] = Boolean(switches.find(({ id }) => id === cs)?.status);
      });
    }

    // Allow team leaders to view employees/teams pages.
    // Backend will take care of filtering results by team leadership.
    capabilitiesNext.employeesViewOriginal = capabilitiesNext.employeesView;
    capabilitiesNext.teamsViewOriginal = capabilitiesNext.teamsView;
    if (userSubGroup === 'teamLeader') {
      capabilitiesNext.employeesView = true;
      capabilitiesNext.teamsView = true;
    }

    return capabilitiesNext;
  }
)

// COMBINED
export const selectLogo = (state) => {
  const customLogoUrl = selectCompanyCustomLogoUrl(state);
  const isAdminFrontend = selectIsAdminFrontend(state);
  const defaultLogo = CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ? byLogo : bqLogo;

  // return custom logo if available
  let logo = customLogoUrl || defaultLogo;
  // always bqLogo when admin frontend
  if (isAdminFrontend) {
    logo = bqLogo;
  }

  return logo;
};

export const selectIsCustomLogoActive = (state) => {
  const logo = selectLogo(state);
  const customLogoUrl = selectCompanyCustomLogoUrl(state);

  return logo === customLogoUrl;
};
