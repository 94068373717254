import React, { memo } from 'react';
import * as css from 'styles-imports/shared.scss'
import styles from '../RmpBipolarDiagram/RmpBipolarDiagram.module.scss';
import {isValid} from 'utils/numbers';

const MIN_DISTANCE = 0.1;
const HIGHEST_VALUE = 5;

const BipolarDiagram = memo((props) => {
  const {score, range, width = 0} = props;
  const viewBox =`0 0 ${width} 15`;

  const step = (width / 10);
  const center = width / 2;
  const markerPosition = (score === 0) ? 0 : (score === 10 ? (step * score) - 15 : (step * score) - 7.5);

  let progressX;
  let progressWidth;

  if ((step * score) < center) {
    progressX = step * score;
    progressWidth = center - (step * score);
  } else {
    progressX = center;
    progressWidth = (step * score) - center;
  }


  let range1;
  let range2;
  if (range && range.length > 0) {
    range1 = range[0];
    range2 = range[1];

    if (range1 === range2) {
      if (range2 + MIN_DISTANCE > HIGHEST_VALUE) {
        range1 -= MIN_DISTANCE;
      } else {
        range2 += MIN_DISTANCE;
      }
    }
  }

  return (
    <svg width={width} height="15" viewBox={viewBox}>
      {/*PROGRESS*/}
      {typeof score === 'number' &&
      <rect x={progressX} width={isValid(progressWidth) ? progressWidth : 0} height="15" fill={css.colorPrimary4}/>
      }

      {/*RANGE*/}
      {(range && range.length > 0) &&
      <rect
        x={range1 * step}
        width={isValid((range2 - range1) * step) ? (range2 - range1) * step : 0}
        height="15" rx="7.5"
        fill={styles.colorPrimary3}
      />
      }

      {/*LINE*/}
      <rect x="0" y="7" width={isValid(width) ? width : 0} height="1" fill={css.colorGrey3}/>

      {/*VERTICAL LINE ON CENTER*/}
      <rect x={center} width={1} height={15} fill={css.colorGrey3}/>

      {/*RESULT MARKER*/}
      {typeof score === 'number' &&
      <rect x={markerPosition} width="15" height="15" rx="7.5" fill={css.colorPrimary1}/>
      }
    </svg>
  );
});

BipolarDiagram.defaultProps = {
  width: 256
};

export default  BipolarDiagram;
