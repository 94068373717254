// REACT, STYLE, STORIES & COMPONENT
import React, {useState} from 'react';
import styles from './ListNextItem.module.scss';

// ASSETS
import {ReactComponent as ArrowRight} from 'assets/icons/icn_arrow_right.svg';
import {ReactComponent as Options} from 'assets/icons/icn_options.svg';
import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {ListNextCell} from '../ListNextCell';
import {ImgCircle, BluCSSTransition, Button} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import {handleRipple} from 'utils/ripple';
import {useBreakpoint} from 'utils/hooks';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: ListNextItem
const ListNextItem = (props) => {
  // PROPS
  const {
    item,

    disabled,
    layout = 'layout3Cells', // or 'layout5Cells'
    layout5CellsCompactMode,
    showAvatar = true,
    showArrow = true,

    options = [],
    onOptionClick = () => {},

    showMore = false,
    moreContent,

    onItemClick = () => {},
    hideEmptyItemIdentifierSublabel,
    hasTooltips,
    cellLastXsBehavior,
    // default (undefined):
    //    - moves the last cell up to itemIdentifier on Xs
    // 'addCellLastXs':
    //    - adds extra .cellLastXs and moves
    //      that up to itemIdentifier
    // all:
    //    - if no label is set: centers last cell value horizontally
    //      and aligns it to right for .cell3, .cell5 and .cellLastXs

  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const bp = useBreakpoint();

  // COMPONENT/UI STATE and REFS
  const [optionsFlyoutVisible, setOptionsFlyoutVisible] = useState();
  const [moreInfoVisible, setMoreInfoVisible] = useState();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: ListNextItem
  return (
    <div className={classNames(styles.listNextItem)}>
      <div className={classNames(
        styles.listItem,
        styles[layout],
        {
          [styles.layout5CellsCompactMode]: layout5CellsCompactMode,
          [styles.showArrow]: showArrow,
          [styles.showAvatar]: showAvatar,
          [styles.showOptions]: options.length > 0,
          [styles.showMoreContent]: showMore,
          [styles.disabled]: disabled,
          [styles.hasCellLastXs]: cellLastXsBehavior,
          [styles.hasTooltips]: hasTooltips,
        })}
           onClick={(event) => {
             if ((event.target.id && event.target.id.includes('cellOptions')) ||
               (event.target.parentElement && event.target.parentElement.id.includes('cellOptions')) ||
               (event.target.id && event.target.id.includes('menuItem'))
             ) {
               return;
             }

             onItemClick();
           }}
      >

        {/* AVATAR */}
        { showAvatar && (
          <div className={styles.cellAvatar}>
            <ImgCircle
              className='darker'
              src={item.avatarImage}
              label1={item.avatarFallback && item.avatarFallback[0]}
              label2={item.avatarFallback && item.avatarFallback[1]}
              size='S'
            />
          </div>
        )}

        {/* ITEM IDENTIFIER */}
        <div className={styles.cellItemIdentifier}>
          <ListNextCell
            identifier={item.itemIdentifierLabel}
            value={item.itemIdentifierSublabel}
            hideEmptyValue={hideEmptyItemIdentifierSublabel}
          />
        </div>

        {/* CELL1 */}
        <div className={styles.cell1}>
          <ListNextCell
            label={item.cell1Label}
            value={item.cell1Value}
            tooltip={item.cell1Tooltip}
            tooltipHighlight={item.cell1TooltipHighlight}
          />
        </div>

        {/* CELL2 */}
        <div className={styles.cell2}>
          <ListNextCell
            label={item.cell2Label}
            value={item.cell2Value}
            tooltip={item.cell2Tooltip}
            tooltipHighlight={item.cell2TooltipHighlight}
          />
        </div>

        {/* CELL3 */}
        { (item.cell3Label || item.cell3Value) && (
          <div className={styles.cell3}>
            <ListNextCell
              label={item.cell3Label}
              hideEmptyLabel={true}
              value={item.cell3Value}
              tooltip={item.cell3Tooltip}
              tooltipHighlight={item.cell3TooltipHighlight}
            />
          </div>
        )}

        { layout === 'layout4Cells' && (
          // CELL4
          <div className={styles.cell4}>
            <ListNextCell
              label={item.cell4Label}
              value={item.cell4Value}
              tooltip={item.cell4Tooltip}
              tooltipHighlight={item.cell4TooltipHighlight}
            />
          </div>
        )}

        { layout === 'layout5Cells' && (
          <>
            {/* CELL4 */}
            <div className={styles.cell4}>
              <ListNextCell
                label={item.cell4Label}
                value={item.cell4Value}
                tooltip={item.cell4Tooltip}
                tooltipHighlight={item.cell4TooltipHighlight}
              />
            </div>

            {/* CELL5 */}
            <div className={styles.cell5}>
              <ListNextCell
                label={item.cell5Label}
                hideEmptyLabel={true}
                value={item.cell5Value}
                tooltip={item.cell5Tooltip}
                tooltipHighlight={item.cell5TooltipHighlight}
              />
            </div>
          </>
        )}


        {/* LAST CELL XS */}
        { cellLastXsBehavior && (bp.isXs || (bp.isS && layout5CellsCompactMode)) && (
          <div className={styles.cellLastXs}>
            <ListNextCell
              label={item.cellLastXsLabel}
              hideEmptyLabel={true}
              value={item.cellLastXsValue}
            />
          </div>
        )}

        {/* ARROW */}
        { showArrow && (
          <div className={styles.cellArrow}>
            <ArrowRight />
          </div>
        )}

        {/*OPTIONS*/}
        { (options.length > 0 && !showArrow) && (
          <div
            className={styles.cellOptionsParent}
            tabIndex='0'
            onBlur={() => setOptionsFlyoutVisible(false)}
          >
            <div
              id={`cellOptions${item.id}`}
              className={styles.cellOptions}
              onClick={(event) => {
                event.preventDefault();

                setOptionsFlyoutVisible(!optionsFlyoutVisible);
              }}
            >
              <Options />
            </div>

            <BluCSSTransition in={optionsFlyoutVisible} classNames={{ ...styles }}>
              <div className={styles.flyout}>
                {options.map((option, index) => {
                  const menuItemProps = {
                    key: index,
                    id: `menuItem${index}`,
                    className: styles.menuItem,
                    onClick: event => {
                      handleRipple(event, styles.colorPrimary3);
                      window.setTimeout(() => {
                        event.preventDefault();

                        onOptionClick(option.value, item.id);
                        setOptionsFlyoutVisible(false);
                      }, styles.animationDurationLongMs);
                    }
                  };

                  return (
                    <div {...menuItemProps}>
                      <span>{option.label}</span>
                    </div>
                  );
                })}
              </div>
            </BluCSSTransition>

          </div>
        )}

      </div>

      {/*BUTTON*/}
      {(showMore && moreContent) &&
      <div className={styles.showMore}>
        {!moreInfoVisible &&
        <Button
          size={'S'}
          looks={'tertiary'}
          trailingIcon={IconsSvg.ArrowDown}
          onClick={() => setMoreInfoVisible(true)}
        >
          {translate('show_more')}
        </Button>
        }

        {moreInfoVisible &&
        <>
          {moreContent}
          <Button
            size={'S'}
            looks={'tertiary'}
            trailingIcon={IconsSvg.ArrowUp}
            onClick={() => setMoreInfoVisible(false)}
          >
            {translate('show_less')}
          </Button>
        </>
        }
      </div>
      }
    </div>
  );
};

export default ListNextItem;
