export const GET_CONFIGURATION = '[CONFIGURATION] GET CONFIGURATION';
export const getConfiguration = () => {
  return {
    type: GET_CONFIGURATION,
    payload: {}
  };
};

export const GET_CONFIGURATION_SUCCESS = '[CONFIGURATION] GET CONFIGURATION SUCCESS';
export const getConfigurationSuccess = (configuration) => {
  return {
    type: GET_CONFIGURATION_SUCCESS,
    payload: configuration
  };
};

export const GET_CONFIGURATION_ERROR = '[CONFIGURATION] GET CONFIGURATION ERROR';
export const getConfigurationError = ({ error }) => {
  return {
    type: GET_CONFIGURATION_ERROR,
    payload: { error }
  };
};

export const SET_CONFIGURATION_CUSTOM_LOGO_URL  = '[CONFIGURATION] SET CONFIGURATION CUSTOM LOGO URL';
export const setConfigurationCustomLogoUrl = (customLogoUrl) => {
  return {
    type: SET_CONFIGURATION_CUSTOM_LOGO_URL,
    payload: {customLogoUrl}
  };
};

export const GET_CONFIGURATION_EXTENDED = '[CONFIGURATION] GET CONFIGURATION EXTENDED';
export const getConfigurationExtended = (payload) => {
  return {
    type: GET_CONFIGURATION_EXTENDED,
    payload: payload
  };
};

export const GET_CONFIGURATION_EXTENDED_SUCCESS = '[CONFIGURATION] GET CONFIGURATION EXTENDED SUCCESS';
export const getConfigurationExtendedSuccess = (configuration) => {
  return {
    type: GET_CONFIGURATION_EXTENDED_SUCCESS,
    payload: configuration
  };
};

export const GET_CONFIGURATION_EXTENDED_ERROR = '[CONFIGURATION] GET CONFIGURATION EXTENDED ERROR';
export const getConfigurationExtendedError = ({ error }) => {
  return {
    type: GET_CONFIGURATION_EXTENDED_ERROR,
    payload: { error }
  };
};
