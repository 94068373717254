
// CONFIG & DATA
export const initialState = {
  // config
  id: '',
  userId: '',
  title: '',

  // error handling
  error: null,

  // navigation
  allowBackNavigation: true,
  allowBackNavigationOnlyOncePerQuestion: false,
  hasNavigatedBack: false,
  highestQuestionIndex: 0,

  // allowForwardNavigation
  allowForwardNavigation: false,
  skippedQuestionsPage: null,
  skippedQuestionsMode: false,  // prevents forward navigation and navigation back
                                // further than skippedQuestionsModeStartIndex
  skippedQuestionsModeStartIndex: 0,
  skippedQuestionsPagesOriginal: null,

  // loadingPage
  loadingPage: null,

  // intermissions: [], // passed in via config but not used in reducer

  // assessment state
  questionIndex: 0,
  lastQuestionIndex: 0,
  isEnd: false,
  started: false,
  animationCount: 0,

  // pages, questions, answers
  pages: [],
  answers: {},

  // progress
  progress: 0, // 0 - 100
  progressPagesTotalCount: 0,

  // timing
  clickBlock: false,
  lastTime: 0,
  questionTimes: [],
};

export const init = (overrideState = {}) => {
  return {
    ...initialState,
    ...overrideState,
  };
};

const getProgressPages = (pages) => {
  const progressPages = pages.filter((page) => {
    return !page.isIntermission || page.countAsProgress
  });

  return progressPages;
};

const getProgressPagesCount = (pages) => {
  const progressPages = getProgressPages(pages);
  const progressPagesTotalCount = progressPages.length;
  return progressPagesTotalCount;
};

export const getProgressByIndex = (pages, questionIndex) => {
  const pagesPassed = pages.slice(0, questionIndex + 1);
  const pagesPassedCount = getProgressPagesCount(pagesPassed);

  const pagesTotalCount = getProgressPagesCount(pages)
    || 1 // prevent division by 0;


  const progress = 100 * pagesPassedCount / pagesTotalCount;
  return progress;
};

export const getProgressByAnswers = (pages, answers) => {

  // answersProgressCount
  const answersArray = [];
  Object.keys(answers).forEach((answerKey) => {
    if (answers[answerKey] !== undefined) {
      answersArray.push(answers[answerKey]);
    }
  });
  const currentProgressAnswersCount = answersArray.length;

  // answersTotalCount
  const questionsMap = {};
  pages.forEach((page) => {
    if (!page.isIntermission || page.countAsProgress) {
      questionsMap[page.id] = true;
    }
  });
  const answersTotalCount = Object.keys(questionsMap).length
    || 1 // prevent division by 0;

  // console.log('progressByAnswers', currentProgressAnswersCount, answersTotalCount);

  const progress = 100 * currentProgressAnswersCount / answersTotalCount;
  return progress;
};


export const getQuestionsFromState = (state) => {
  const questionsPages = state.pages.filter(page => !page.isIntermission);

  const questionsMap = {};
  questionsPages.forEach((questionPage) => {
    questionsMap[questionPage.id] = questionPage;
  });
  const uniqueQuestionsPages = Object.keys(questionsMap).map((questionsKey) => {
    return questionsMap[questionsKey];
  });

  return uniqueQuestionsPages;
};


export const areAllQuestionsAnswered = (state) => {
  const questions = getQuestionsFromState(state);
  const answers = state.answers;
  const answerKeys = Object.keys(answers);
  const answerKeysAnswered = answerKeys.filter((key) => answers[key] !== undefined);

  const unansweredQuestions = questions.filter((question) => answers[question.id] === undefined);

  return [
    answerKeysAnswered.length === questions.length,
    unansweredQuestions,
    answers,
  ];
};

// add unanswered questions to originalPages
// puts last page at the end of new pages with unanswered questions
// adds skippedQuestionsPage
export const addUnansweredQuestions = (originalPages, unansweredQuestions, skippedQuestionsPage) => {

  const pages = [...originalPages];
  pages.splice(pages.length - 1, 0, { isIntermission: true, showBackArrow: true, ...skippedQuestionsPage }, ...unansweredQuestions,);
  return pages;
};


export const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return {
        ...initialState
      };
    }
    case 'override': {
      const overrideState = action.payload;
      return {
        ...initialState,
        ...overrideState,
      };
    }
    case 'setClickBlock': {
      const clickBlock = true;
      return {
        ...state,
        clickBlock
      };
    }
    case 'unsetClickBlock': {
      const clickBlock = false;
      return {
        ...state,
        clickBlock
      };
    }
    case 'start': {
      const lastTime = action.payload || Date.now();
      const started = true;
      const progressPagesTotalCount = getProgressPagesCount(state.pages);
      const clickBlock = true;

      return {
        ...state,
        progressPagesTotalCount,
        lastTime,
        started,
        clickBlock
      };
    }
    case 'next': {
      const { answer, time, animationTime } = action.payload || {};
      const lastTime = time;

      // check: prevent answers during clickBlock
      if (state.clickBlock) {
        return state;
      }

      // check: boundaries, if at boundary => prevent action
      if (state.questionIndex >= state.pages.length - 1) {
        return state;
      }
      // check: boundaries, if going to boundary => set isEnd
      let isEnd = false;
      if (state.questionIndex + 1 === state.pages.length - 1) {
        isEnd = true;
      }


      // check: no answer for question? => prevent action
      const currentPage = state.pages[state.questionIndex];
      const currentPageId = currentPage.id;
      const isIntermission = currentPage.isIntermission;
      const cachedAnswer = state.answers[currentPageId];
      if (currentPageId
        && !isIntermission
        && answer === undefined
        && cachedAnswer === undefined
        && (!state.allowForwardNavigation || state.skippedQuestionsMode)
      ) {
        return state;
      }

      // check: skip nextPage if it has hideOnBackNavigation and hideOnBackNavigationWasSkipped
      let nextIndex = state.questionIndex + 1;
      let nextPage = state.pages[nextIndex];
      if (nextPage.hideOnBackNavigation && nextPage.hideOnBackNavigationWasSkipped && state.pages[state.questionIndex + 2]) {
        nextIndex = state.questionIndex + 2;
      }

      // let nextIndex = state.questionIndex + 1;
      // update question indices
      const questionIndex = nextIndex;
      const lastQuestionIndex = state.questionIndex;
      let highestQuestionIndex = questionIndex;
      if (state.highestQuestionIndex > questionIndex) {
        highestQuestionIndex = state.highestQuestionIndex;
      }
      let hasNavigatedBack = state.hasNavigatedBack;
      if (questionIndex > state.highestQuestionIndex) {
        hasNavigatedBack = false;
      }

      // track answers
      const newAnswer = answer !== undefined
        ? answer
        : cachedAnswer;
      const answers = currentPageId && !isIntermission
        ? { ...state.answers, [currentPageId]: newAnswer }
        : { ...state.answers };

      // track progress
      let progress;
      if (!state.allowForwardNavigation) {
        progress = getProgressByIndex(state.pages, questionIndex - 1);
      }
      else {
        progress = getProgressByAnswers(state.pages, answers);
      }

      // track time of question
      const questionTimes = [...state.questionTimes]; // copy
      let questionTime = [ ...(questionTimes[lastQuestionIndex] || []) ];
      const totalTime = time - state.lastTime;
      questionTime.push({
        totalTime,
        animationTime,
        answer
      });
      questionTimes[lastQuestionIndex] = questionTime;

      // isEnd: check unanswered questions
      let pages = state.pages;
      let skippedQuestionsMode = state.skippedQuestionsMode;
      let skippedQuestionsModeStartIndex = state.skippedQuestionsModeStartIndex;
      let skippedQuestionsPagesOriginal = state.skippedQuestionsPagesOriginal;
      let error = state.error;
      if (isEnd || (state.skippedQuestionsPagesOriginal && (questionIndex === state.skippedQuestionsPagesOriginal.length - 1))) {
        isEnd = false; //  set isEnd only when allQuestionsAreAnswered
        const [ allAnswered, unansweredQuestions ] = areAllQuestionsAnswered({
          ...state,
          answers
        });
        // console.log('reducer allAnswered', allAnswered, unansweredQuestions);
        // console.log('reducer answers', state.answers);
        if (allAnswered) {
          isEnd = true;
          // restore pages & show end page, when all questions are answered but we were on skippedQuestionsMode before
          if (state.skippedQuestionsPagesOriginal && questionIndex === state.skippedQuestionsPagesOriginal.length - 1) {
            pages = state.skippedQuestionsPagesOriginal;
          }
        }
        else if (state.allowForwardNavigation) {
          skippedQuestionsMode = true;
          skippedQuestionsModeStartIndex = state.questionIndex + 1;
          skippedQuestionsPagesOriginal = skippedQuestionsPagesOriginal || pages;
          pages = addUnansweredQuestions(
            skippedQuestionsPagesOriginal,
            unansweredQuestions,
            state.skippedQuestionsPage,
          );
        }
        else {
          error = { message: 'not all answers' };
        }
      }

      return {
        ...state,
        // error handling
        error,
        // navigation
        hasNavigatedBack,
        skippedQuestionsMode,
        skippedQuestionsModeStartIndex,
        skippedQuestionsPagesOriginal,
        // indices
        questionIndex,
        lastQuestionIndex,
        highestQuestionIndex,
        isEnd,
        animationCount: state.animationCount + 1,
        // pages
        pages,
        // answers
        answers,
        // progress
        progress,
        // timing
        clickBlock: true,
        lastTime,
        questionTimes,
      };
    }
    case 'prev': {
      const lastTime = action.payload || Date.now();

      // prevent navigation during clickBlock
      if (state.clickBlock) {
        return state;
      }

      // prevent navigation: allowBackNavigation disabled
      if (!state.allowBackNavigation) {
        return state;
      }

      // prevent navigation: allowBackNavigation is a number
      let hasNavigatedBack = false;
      if (typeof(state.allowBackNavigation) === 'number') {
        if (state.questionIndex - 1 < state.highestQuestionIndex - state.allowBackNavigation) {
          return state;
        }
        if (state.questionIndex - 1 === state.highestQuestionIndex - state.allowBackNavigation) {
          hasNavigatedBack = true;
        }
        if (state.allowBackNavigationOnlyOncePerQuestion && state.hasNavigatedBack) {
          return state;
        }
      }

      // prevent navigation: boundaries
      if (state.questionIndex <= 0) {
        return state;
      }

      // go back, skip intermission that have hideOnBackNavigation
      const nextPage = state.pages[state.questionIndex - 1];
      const questionIndex = nextPage.isIntermission && nextPage.hideOnBackNavigation
        ? state.questionIndex - 2
        : state.questionIndex - 1;


      // disable skippedQuestionsMode
      let skippedQuestionsMode = state.skippedQuestionsMode;
      if (state.allowForwardNavigation && skippedQuestionsMode) {
        if (questionIndex < state.skippedQuestionsPagesOriginal.length - 1) {
          skippedQuestionsMode = false;

        }
        else {
          skippedQuestionsMode = true;
        }
      }

      if (nextPage.isIntermission && nextPage.hideOnBackNavigation) {
        nextPage.hideOnBackNavigationWasSkipped = true;
      }

      const lastQuestionIndex = state.questionIndex;

      // track progress
      let progress;
      if (!state.allowForwardNavigation) {
        progress = getProgressByIndex(state.pages, questionIndex - 1);
      }
      else {
        progress = getProgressByAnswers(state.pages, state.answers);
      }

      return {
        ...state,
        // navigation
        hasNavigatedBack,
        skippedQuestionsMode,
        // indices
        questionIndex,
        lastQuestionIndex,
        isEnd: false,
        animationCount: state.animationCount + 1,
        // progress
        progress,
        // time
        lastTime,
      };
    }
    case 'addPages': {
      let { insertAtIndex, pages, replace } = action.payload;

      // check insertAtIndex
      if (isNaN(insertAtIndex)) {
        return state;
      }

      // check: prevent adding at position smaller/equal questionIndex
      if (insertAtIndex <= state.questionIndex) {
        return state;
      }

      let newPages = [...state.pages];
      let lastPage;
      if (replace) {
        lastPage = newPages[newPages.length - 1];
        newPages = newPages.slice(0, insertAtIndex);
      }
      newPages.splice(insertAtIndex, 0, ...pages);

      // keep last page
      if (lastPage) {
        newPages.push(lastPage);
      }

      // track progress
      let progress;
      if (!state.allowForwardNavigation) {
        progress = getProgressByIndex(newPages, state.questionIndex);
      }
      else {
        progress = getProgressByAnswers(newPages, state.answers);
      }

      return {
        ...state,
        pages: newPages,
        progress
      };
    }
    // overrides answers and moves to the latest unanswered question
    case 'overrideAnswers': {
      const { prevAnswers } = action.payload;

      // create answers override object
      const answers = {};
      prevAnswers.forEach((prevAnswer) => {
        answers[prevAnswer.questionId] = prevAnswer.answer;
      });
      let shouldContinue = true;
      let newQuestionIndex = state.questionIndex;
      do {
        shouldContinue = false;
        const page = state.pages[newQuestionIndex];

        if (page.isIntermission || answers[page.id] !== undefined) {
          shouldContinue = true;
          newQuestionIndex++;
        }
      } while (shouldContinue);

      // track progress
      let progress;
      const pages = state.pages;
      if (!state.allowForwardNavigation) {
        progress = getProgressByIndex(pages, newQuestionIndex);
      }
      else {
        progress = getProgressByAnswers(pages, answers);
      }

      return {
        ...state,
        questionIndex: newQuestionIndex,
        progress,
        answers,
      };
    }
    default: {
      throw new Error(`action.type ${action.type} not handled`);
    }
  }
};

// const getProgress = (({ pages, index, answers, allowForwardNavigation }) => {
//   if (!allowForwardNavigation) {
//     return getProgressByIndex(pages, index);
//   }
//   else {
//     return getProgressByAnswers(pages, answers);
//   }
// });
