import * as fromActionTypes from 'store/actionTypes/support';

export const initRequestSupport = () => {
  return {
    type: fromActionTypes.INIT_REQUEST_SUPPORT,
    payload: {}
  };
};

export const requestSupport = (supportDto) => {
  return {
    type: fromActionTypes.REQUEST_SUPPORT,
    payload: {supportDto}
  };
};

export const requestSupportFulfilled = () => {
  return {
    type: fromActionTypes.REQUEST_SUPPORT_FULFILLED,
    payload: {}
  };
};

export const requestSupportRejected = ({ error }) => {
  return {
    type: fromActionTypes.REQUEST_SUPPORT_REJECTED,
    payload: { error }
  };
};
