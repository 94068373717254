// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useRef, Suspense } from 'react';
import styles from './MainLayout.module.scss';
import transitions from './transitions.module.scss';
import xsTransitions from './xsTransitions.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import MainNav from './MainNav';
import MainHeader from './MainHeader';
import PageWrapper from './PageWrapper';
import ModalWrapper from './ModalWrapper';
import NestedWrapper from './NestedWrapper';
import StandaloneWrapper from './StandaloneWrapper';

import { Footer } from './Footer'

import SurveyFloatingMenu from 'ui/basic/macro-ui/SurveyFloatingMenu';

// UTILS
// import { useTranslate } from 'utils/translator';
import { useDebounce, useBreakpoint } from 'utils/hooks';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';
import { IS_MAINTENANCE_MODE } from 'utils/configuration';

// STORE
import store from 'store';

// STORE NEXT
import { Provider, useSelector, useDispatch } from 'react-redux';
import storeNext from 'features/framework/utils/storeNext';
// configurationSlice
import {
  selectInstanceIsAdminFrontend,
  selectIsLoggedIn,
  selectInstanceIsNonexistent,
  selectUserProfileCompleted,
  selectRoutesWithStateExtras,
  selectLoadingExternal
} from 'features/framework/storeNext/configurationSlice'
import { setSubNavIsOpen as setSubNavIsOpenStore } from 'features/framework/storeNext/layoutSlice';
import ModalsAndPanels from './ModalsAndPanels';
// store next debug tools
// import { Counter } from 'features/framework/components/Counter';
// import { Configuration } from 'features/framework/components/Configuration';
// import { ConfigurationInside } from 'features/framework/components/ConfigurationInside';

// CONFIG & DATA
// const Config = {};

// CONTEXT
export const LayoutContext = React.createContext();

// COMPONENT: MainLayout
const MainLayout = (props) => {
  // PROPS

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();


  // STORE: CONFIGURATION
  const loading = useSelector(selectLoadingExternal);
  // const initialisedExtended = useSelector(selectInitialisedExtended);
  const isAdminFrontend = useSelector(selectInstanceIsAdminFrontend);
  useEffect(() => {
    console.log('ADMIN', isAdminFrontend, window.location.href)
  }, [isAdminFrontend]);
  const userIsLoggedIn = useSelector(selectIsLoggedIn);
  const isNonexistent = useSelector(selectInstanceIsNonexistent);
  
  // Redirect towards error pages on mount
  useEffect(() => {
    if (IS_MAINTENANCE_MODE) {
      history.push('/maintenance');
      return;
    }
    if (isNonexistent) {
      history.push('/nonexistent-instance');
    }
  }, [ history, isNonexistent ]);
  useEffect(() => {
    console.log('USER IS LOGGED IN ', userIsLoggedIn, window.location.href);
  },[userIsLoggedIn])
  useEffect(() => {
    console.log('LOADING', loading, window.location.href)
  }, [loading]);
  const [ showRoutes, setShowRoutes ] = useState();
  const [ redirectsDone, setRedirectsDone ] = useState();
  const userProfileCompleted = useSelector(selectUserProfileCompleted);
  useEffect(() => {
    console.log('userProfileCompleted', userProfileCompleted);
  }, [userProfileCompleted]);
  useEffect(() => {
    if (!loading) {
      setShowRoutes(true)
    }
    else {
      setShowRoutes(false);
    }
    if (!redirectsDone && !loading) {
      console.log('CUSTOM REDIRECTS', window.location.href, location.pathname)
      console.log('CUSTOM REDIRECTS', userIsLoggedIn, userProfileCompleted, isAdminFrontend)
      setRedirectsDone(true);

      // if (userIsLoggedIn) {

      //   if (userProfileCompleted) {

      //     // from login to main page
      //     if (location.pathname.includes('login')) {
      //       if (isAdminFrontend) {
      //         history.push('/admin/overview')
      //       }
      //       else {
      //         history.push('/')
      //       }
      //     }

      //     // from / to /admin/overview
      //     if (isAdminFrontend && location.pathname === '/') {
      //       history.push('/admin/overview')
      //     }

      //     // legal
      //     if (location.pathname.includes === '/legal') {
      //       history.push('legal/data-protection-privacy')
      //     }

      //     // MISSING: <Redirect from='/*' to='/' />
      //   }
      //   // userProfile not completed
      //   else {
      //     history.push('/complete-profile')
      //   }
      // }
      // // not logged in
      // else {
      //   if (location.pathname === '/') {
      //     history.push('/login')
      //   }
      //   // MISSING <Redirect from='/*' to='/login' />
      // }
    }
  }, [location, history, redirectsDone, loading, userIsLoggedIn, userProfileCompleted, isAdminFrontend])


  // ROUTING
  // configurationSlice
  // NOTE: think this causes a double render, should revert to selectRoutes asap
  const routes = useSelector(selectRoutesWithStateExtras);
  useEffect(() => {
    console.log('routes', routes);
  }, [routes]);

  // scroll to top on route changes
  const nestedPageEl = useRef(null);
  useEffect(() => {
    const unListen = history.listen(() => {
      if (nestedPageEl) {
        nestedPageEl.current.scrollTo(0, 0);
      }
    });
    return () => {
      unListen();
    }
  }, [history]);

  // RESPONSIVE
  const bp = useBreakpoint();

  // MAIN NAV: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ mainNavIsOpen, setMainNavIsOpen ] = useState(true);
  const mainNavIsOpenDebounced = useDebounce(mainNavIsOpen, 600);
  const [ fullMode/*, setFullMode */] = useState(false);
  // some motion graphics & prevent scrolling / close menu via scroll for mobile
  useEffect(() => {
    // let timeout;
    if (bp.isXs) {
      if (mainNavIsOpen) {
        // timeout = setTimeout(() => {
        //   setFullMode(!mainNavIsOpen);
        // }, 200);
        disableScrollingOnBody();
      }
      else {
        // setFullMode(!mainNavIsOpen);
        enableScrollingOnBody();
      }
      return () => {
        // clearTimeout(timeout);
      }
    }
    else {
      // setFullMode(mainNavIsOpen);
    }
  }, [mainNavIsOpen, bp]);
  // on bp change => automatic opening and closing of main nav
  useEffect(() => {
    if (bp.isXs || bp.isS || bp.isM) {
      setMainNavIsOpen(false);
    }
    else {
      setMainNavIsOpen(true);
    }
  }, [bp])
  const [ mainNavTransitions, setMainNavTransitions ] = useState({ ...transitions });
  useEffect(() => {
    setMainNavTransitions(bp.isXs ? { ...xsTransitions } : { ...transitions });
  }, [bp.isXs])

  // SUBNAV
  const [ subNavIsOpen, setSubNavIsOpen ] = useState(false);
  useEffect(() => {
    dispatch(setSubNavIsOpenStore(subNavIsOpen));
    console.log('subNavIsOpen', subNavIsOpen);
  }, [subNavIsOpen, dispatch]);

  // MODALS & PANELS
  const [ profileIsOpen, setProfileIsOpen ] = useState(false);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: MainLayout
  return (
    // LAYOUT CONTEXT TO AVOID PROP DRILLING
    <LayoutContext.Provider value={{
      mainNavIsOpen: mainNavIsOpen,
      mainNavIsOpenDebounced: mainNavIsOpenDebounced
    }}>
      <div className={classNames(styles.mainLayout)}>

        {/* MainNav */}
        <nav className={classNames(styles.mainNav, {
          [styles.closed]: !mainNavIsOpen,
        })}
              style={{ ...mainNavTransitions }}
        >
          <Provider store={store}>
            <MainNav mainNavRoutes={routes.mainNavRoutes}
                      onClose={() => { setMainNavIsOpen(false) }} />
          </Provider>
        </nav>

        {/* Main */}
        <main className={classNames(styles.main, {
          [styles.fullWidth]: !mainNavIsOpen,
          [styles.subNavIsOpen]: subNavIsOpen
        })}>

          {/* Header */}
          <header className={styles.header}>
            <MainHeader
              showBurgerMenu={fullMode || !mainNavIsOpen}
              onOpenProfile={() => setProfileIsOpen(true)}
              onOpenMainNav={(open) => setMainNavIsOpen(open === false ? false: open)}
              onSubNav={(open) => setSubNavIsOpen(open)}
            />
          </header>

          {/* Page */}
          <section ref={nestedPageEl} className={styles.page}>
            {/* fallback while lazy modules are loaded */}
            <Suspense fallback={<></>}>

              {/* Master Switch Pre Initalisation */}
              {/* prevent redirects before config initialisation */}
              {/* { !initialisedExtended && ( */}
              { !showRoutes && (
                <Switch>
                  { routes.allRoutes.map(({path}) => (
                    <React.Fragment key={path}>{path}</React.Fragment>
                  ))}
                </Switch>
              )}

              {/* Master Switch */}
              {/* { initialisedExtended && ( */}
              { showRoutes && (
                <Switch>

                  {/* Standalone Routes */}
                  { routes.standaloneRoutes.map((route) => {
                    const { path, PageComponent, useStoreNext} = route;
                    return (
                      <Route key={path} path={path}>
                        <PageWrapper route={route}>
                          <StandaloneWrapper>
                            <Provider store={useStoreNext ? storeNext : store}>
                              <PageComponent />
                            </Provider>
                          </StandaloneWrapper>
                        </PageWrapper>
                      </Route>
                    );
                  })}

                  {/* Modal Routes */}
                  { routes.modalRoutes.map((route) => {
                    const { path, PageComponent, useStoreNext } = route;

                    return (
                      <Route key={path} path={path}>
                        <PageWrapper route={route}>
                          <ModalWrapper noAnimate={route.pageWrapper?.noAnimate}
                            instantBackground={route.pageWrapper?.instantBackground}>
                            <Provider store={useStoreNext ? storeNext : store}>
                              <PageComponent />
                            </Provider>
                          </ModalWrapper>
                        </PageWrapper>
                      </Route>
                    )
                  })}

                  {/* Nested Routes */}
                  { routes.nestedRoutes.map((route) => {
                    const { path, PageComponent, subRoutes, useStoreNext } = route;

                    const routes = [];

                    // route
                    routes.push((
                      <Route exact={true} key={path} path={path}>
                        <div className={styles.floatingMenu}>
                          <SurveyFloatingMenu />
                        </div>
                        <div className={classNames(styles.nestedPage, {
                          [styles.white]: route.pageWrapper?.background === 'white',
                        })}>

                          <div className={styles.pageWrapper}>
                            <PageWrapper route={route}>
                              <NestedWrapper autoMargins={route.pageWrapper?.autoMargins}>
                                <Provider store={useStoreNext ? storeNext : store}>
                                  <PageComponent />
                                </Provider>
                              </NestedWrapper>
                            </PageWrapper>
                          </div>
                          <div className={styles.footer}>
                            <Footer />
                          </div>

                          {/* store next debug */}
                          {/* <br/>
                        <br/>
                        <h6>StoreNext</h6>
                        <Provider store={storeNext}>
                          <Provider store={storeNext}>
                            <Configuration />
                            <Counter/>
                          </Provider>
                          <Counter>
                            <Provider store={store}>
                              <ConfigurationInside/>
                            </Provider>
                          </Counter>
                          <Counter/>
                          <Counter/>
                        </Provider> */}

                        </div>
                      </Route>
                    ))

                    // subRoutes
                    if (subRoutes?.length) {
                      subRoutes.forEach((subRoute) => {
                        const { path, PageComponent, subRoutes: subSubRoutes, useStoreNext } = subRoute;
                        // PageComponent exists?
                        // subRoutes can just be a inSubNav placeholder for their parent route
                        if (!PageComponent) return;
                        routes.push((
                          <Route exact={true} key={path} path={path}>
                            <div className={styles.floatingMenu}>
                              <SurveyFloatingMenu />
                            </div>
                            <div className={classNames(styles.nestedPage, {
                              [styles.white]: route.pageWrapper?.background === 'white',
                            })}>

                              <div className={styles.pageWrapper}>
                                <PageWrapper route={subRoute}>
                                  <NestedWrapper autoMargins={subRoute.pageWrapper?.autoMargins}>
                                    <Provider store={useStoreNext ? storeNext : store}>
                                      <PageComponent />
                                    </Provider>
                                  </NestedWrapper>
                                </PageWrapper>
                              </div>
                              <div className={styles.footer}>
                                <Footer />
                              </div>
                            </div>
                          </Route>
                        ));

                        // subSubRoutes
                        if (subSubRoutes?.length) {
                          subSubRoutes.forEach((subSubRoute) => {
                            const { path, PageComponent, useStoreNext } = subSubRoute;
                            // PageComponent exists?
                            // subRoutes can just be a inSubNav placeholder for their parent route
                            if (!PageComponent) return;
                            routes.push((
                              <Route exact={true} key={path} path={path}>
                                <div className={styles.floatingMenu}>
                                  <SurveyFloatingMenu />
                                </div>
                                <div className={classNames(styles.nestedPage, {
                                  [styles.white]: route.pageWrapper?.background === 'white',
                                })}>
                                  <div className={styles.pageWrapper}>
                                    <PageWrapper route={subSubRoute}>
                                      <NestedWrapper autoMargins={subSubRoute.pageWrapper?.autoMargins}>
                                        <Provider store={useStoreNext ? storeNext : store}>
                                          <PageComponent />
                                        </Provider>
                                      </NestedWrapper>
                                    </PageWrapper>
                                  </div>
                                  <div className={styles.footer}>
                                    <Footer />
                                  </div>
                                </div>
                              </Route>
                            ));
                          })
                        }
                      });
                    }

                    return routes;
                  })}


                  {/* REDIRECTS */}

                  <>
                    {/* not logged in */}
                    { !userIsLoggedIn && (
                      <>
                        <Redirect from='/' to='/login' />
                        <Redirect from='/*' to='/login' />
                      </>
                    )}
                    {/* logged in */}
                    { userIsLoggedIn && (
                      <>
                        {/* profile completed */}
                        { userProfileCompleted && (
                          <>

                            { !isAdminFrontend && (
                              <Redirect from='/login' to='/' />
                            )}

                            {/* legal */}
                            <Redirect from='/legal' to='/legal/data-protection-privacy'/>
                            {/* catch all */}
                            <Redirect from='/*' to='/' />
                          </>
                        )}
                        { !userProfileCompleted && (
                          <Redirect from='/*' to='/complete-profile' />
                        )}
                      </>
                    )}
                  </>


                </Switch>
              )}
              </Suspense>
          </section>
        </main>

        {/* MODALS AND PANELS */}
        <Provider store={store}>
          <ModalsAndPanels
            profileIsOpen={profileIsOpen}
            onProfileClose={() => setProfileIsOpen(false)}
          />
        </Provider>

      </div>
    </LayoutContext.Provider>
  );
};

export default MainLayout;
