import React, {useEffect, useState} from 'react';
import styles from './Survey.module.scss';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';

import { useTranslate } from 'utils/translator';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';

import {ReactComponent as SurveyImage} from 'assets/keyvisuals/survey.svg';
import {initSubmitFeedback, submitFeedback} from 'store/actions';
import {Button, InputNext, TextArea} from 'ui/basic';

const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_'
};

const COMPONENT_TYPES = {
  INPUT: 'INPUT',
  TEXTAREA: 'TEXTAREA',
  CIRCLES: 'CIRCLES'
};

const QUESTIONS = [
  {
    id: 1,
    question: 'Wie wahrscheinlich ist es, dass du bluquist einem Geschäftspartner oder Kollegen empfehlen würdest?',
    type: COMPONENT_TYPES.CIRCLES
  },
  {
    id: 2,
    question: 'Was hat dir am besten gefallen?',
    type: COMPONENT_TYPES.TEXTAREA
  },
  {
    id: 3,
    question: 'Was sollten wir verbessern?',
    type: COMPONENT_TYPES.TEXTAREA
  },
  {
    id: 4,
    question: 'Beschreibe bluquist in einem Satz?',
    type: COMPONENT_TYPES.INPUT
  },
  {
    id: 5,
    question: 'Was fehlt dir um bluquist regelmäßig zu nutzen?',
    type: COMPONENT_TYPES.TEXTAREA
  },
  {
    id: 6,
    question: 'Was möchtest du uns sonst noch mitteilen?',
    type: COMPONENT_TYPES.TEXTAREA
  }
];

const Survey = () => {

  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [answers, setAnswers] = useState([]);

  const feedbackSubmittedSuccessfully = useSelector(state => state.survey.success);

  const getCircles = () => {
    const circles = [];

    for (let i=1; i<=10; i++) {
      circles.push(
        <div className={styles.circleBlock} key={`circle-${i}`}>
          <div
            className={classNames(styles.circle, {[styles.active]: i === answers[0]})}
            onClick={() => {
              answers[0] = i;
              //updating state
              setAnswers(answers.concat([]));
            }}
          ></div>
          <div className={styles.number}>{i}</div>
        </div>
      );
    }
    return circles;
  };

  const finalPage =
    <div className={styles.finalPage}>
      <div className={styles.emoji}>
        <span role='img' aria-label='emoji'>🙏</span>
      </div>
      <div className={styles.finalPageTitle}>
        {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_final_page_descr`) ||
        'Vielen Dank für dein Feedback. Du bist großartig.'}
      </div>
      <Button
        size='M'
        onClick={() => history.push('/')}
      >
        {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_final_page_btn`) || 'Zurück zu BLUQUIST'}
      </Button>
    </div>

  const getQuestionComponent = (question) => {
    switch (question.type) {
      case COMPONENT_TYPES.TEXTAREA:
        return (
          <TextArea
            label={translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_page_question_placeholder`) || 'Deine Antwort'}
            onChange={(value) => {
              answers[question.id - 1] = value;
              // updating state
              setAnswers(answers.concat([]));
            }}
          />
        )
      case COMPONENT_TYPES.INPUT:
        return (
          <InputNext
            label={translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_page_question_placeholder`) || 'Deine Antwort'}
            onChange={value => {
              answers[question.id - 1] = value;
              // updating state
              setAnswers(answers.concat([]));
            }}
          />
        )
      case COMPONENT_TYPES.CIRCLES:
        return (
          <div className={styles.circles}>
            {getCircles()}
          </div>
        )
      default:
        return <div></div>
    }
  };

  useEffect(() => {
    if (feedbackSubmittedSuccessfully) {
      setFeedbackSubmitted(true);
    }
  }, [feedbackSubmittedSuccessfully]);

  useEffect(() => {
    return () => {
      dispatch(initSubmitFeedback());
    }
  }, [dispatch]);

  return (
    <div className={styles.survey}>
      {/*HEADER*/}
      <div className={styles.header}>
        <span>{translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_page_header_title`) || 'Dein Feedback'}</span>
        <div
          className={styles.close}
          onClick={() => history.goBack()}
        >
          {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_page_header_cancel`) || 'abbrechen'}
        </div>
      </div>

      {/*CONTENT*/}
      <div className={styles.content}>
        <div className={styles.gridContent}>
          <div className={styles.main}>
            {feedbackSubmitted ?
              finalPage :
              <>
                <div className={styles.surveyImage}>
                  <SurveyImage/>
                </div>
                <div className={styles.title}>
                  {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_page_title`) || 'Dein Feedback ist uns wichtig!'}
                </div>
                <div className={styles.description}>
                  {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_page_description`) ||
                    'Als Freund und Partner von bluquist ist uns deine Meinung ganz besonders wichtig.' +
                  'Wir freuen uns auf dein ehrliches Feedback, um bluquist zielgerichtet verbessern zu können!'
                  }
                </div>

                {QUESTIONS.map((questionItem, index) => (
                  <div className={styles.block} key={`question-block${index}`}>
                    <div className={styles.blockTitle}>
                      {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_page_question${questionItem.id}`) || 'Was hat dir am besten gefallen?'}
                    </div>
                    {getQuestionComponent(questionItem)}
                  </div>
                ))}

                <Button
                  size='M'
                  disabled={answers.length < QUESTIONS.length}
                  onClick={() => {
                    dispatch(submitFeedback({
                      recommendationProbability: answers[0],
                      bestInBluquist: answers[1],
                      improvements: answers[2],
                      inOneSentence: answers[3],
                      missingThing: answers[4],
                      other: answers[5]
                    }))
                  }}
                >
                  {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}survey_page_submit_btn`) || 'Absenden'}
                </Button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default Survey;
