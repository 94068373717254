import apisauce from 'apisauce';
import * as localStorage from 'utils/localStorage';

// CONFIG
import {
  REACT_APP_API_BASE_URL,
  REACT_APP_AUTHORIZATION_HEADER,
  COMPANY_ID
} from 'utils/configuration';

// CONSTS
export const MIN_SEARCH_LENGTH = 3; // characters
export const ITEMS_PER_PAGE = 10;
export const DEBOUNCE_TIME = 200; // ms
export const SESSION_DURATION = 15 * 60 * 1000; // 15 minuten


// HEADERS
const DEFAULT_HEADERS = {
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json'
};

// CUSTOM HEADERS
let CUSTOM_HEADERS = {};
export const addCustomHeaders = (headers) => {
  CUSTOM_HEADERS = {
    ...CUSTOM_HEADERS,
    ...headers
  };
};

const createUploadHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    Authorization: `${REACT_APP_AUTHORIZATION_HEADER} ${token}`,
    'Content-Type': 'multipart/form-data',
    ...CUSTOM_HEADERS
  };
};

const createHeaders = ({headerItems, useShareToken}) => {
  let token = localStorage.getItem('token');

  /**
   * for external profiles 'shareToken' needs to be used always instead of a basic token.
   *
   * background:
   * <ExternalProfile/> component includes result components for displaying the assessments results
   * <Big5Result/>, <CompetenciesResult/> dispatch the action 'getAssessment' where the request header gets generated here in api/index.js
   *
   * thus if external profile route is active AND 'useShareToken' is equal to true, shareToken is need to be used instead of a basic token
   * 'useShareToken' value is set based on request url. If request url includes the string 'assessments', value need to be set to true
   */
  if (useShareToken && window.location.href.includes('/share/')) {
    const shareToken = window.location.pathname.split('/share/')[1];
    if (shareToken && localStorage.getItem(`shareToken${shareToken}`)) {
      token = localStorage.getItem(`shareToken${shareToken}`);
    }
  }

  const header = {
    Authorization: `${REACT_APP_AUTHORIZATION_HEADER} ${token}`,
    'Accept-Language': `${localStorage.getItem('selectedLanguage') || 'en_US'}, *`,
    ...headerItems,
    ...CUSTOM_HEADERS
  }

  return header;
};



// APISAUCE
const api = apisauce.create({
  // base URL is read from the 'constructor'
  baseURL: REACT_APP_API_BASE_URL,
  headers: DEFAULT_HEADERS,
  // default: 15 second timeout...
  // timeout: 20000,
});

const apiLocal = apisauce.create({
  baseURL: '',
  headers: DEFAULT_HEADERS,
  // 15 second timeout...
  // timeout: 20000,
});

const handleSessionExpiredError = () => {
  localStorage.removeItem('token');
  window.location.href = '/logged-out';
};


// LOCAL REQUESTS
export const getLocal = async (url, params = {}) => {
  const headers = createHeaders({});
  const response = await apiLocal.get(url, params, {
    headers: headers
  });

  //1102 - session expired error
  if (!response.ok && (response.data.error && response.data.error.errorCode === 1102)) {
    handleSessionExpiredError();
    return;
  }

  return response;
};

// BASE URL
export const getBaseUrl = () => {
  let baseUrl = REACT_APP_API_BASE_URL;
  if (baseUrl[baseUrl.length - 1] === '/') {
    baseUrl = baseUrl.slice(0, -1);
  }
  return baseUrl;
};


// IMAGE REQUESTS
export const getCustomLogoUrl = (companyId = COMPANY_ID) => {
  return `${getBaseUrl()}/core/company/${companyId}/logo`;
};
export const getUserImageUrl = (userId, format = 'thumbnail') => {
  return `${getBaseUrl()}/core/static/image/user/${userId}/${format}.jpg`;
};

// @depcreated in favour of getuserImageUrl
export const get_base64 = async (url, params = {}, headerItems = {}) => {
  const headers = { ...createHeaders({ headerItems }) };
  headers['Accept'] = 'application/base64';
  const response = await api.get(url, params, {
    headers: headers
  });

  //1102 - session expired error
  if (!response.ok && (response.data && response.data.error && response.data.error.errorCode === 1102)) {
    handleSessionExpiredError();
    return;
  }

  return response;
};



// GET REQUEST
export const get = async (url, params = {}, headerItems = {}, axiosConfig = {}) => {
  const headers = createHeaders({
    headerItems,
    // useShareToken is used for requests triggered in <ExternalProfile/> component
    useShareToken: url.includes('assessments')
  });

  const response = await api.get(url, params, {
    headers: headers,
    ...axiosConfig,
  });

  //1102 - session expired error
  if (!response.ok &&
    (response.data && response.data.error && response.data.error.errorCode === 1102) && window.location.pathname.includes('wb-measurement')) {
    localStorage.removeItem('token');
    return response;
  }

  //1102 - session expired error
  if (!response.ok && (response.data && response.data.error && response.data.error.errorCode === 1102)) {
    handleSessionExpiredError();
    return;
  }

  return response;
};


// POST REQUEST
export const post = async (url, data = {}, headerItems = {}, config = {}) => {
  const headers = createHeaders({headerItems});
  const response = await api.post(url, data, {
    headers: headers,
    ...config
  });

  //1102 - session expired error
  if (!response.ok && response.data?.error?.errorCode === 1102) {
    handleSessionExpiredError();
    return;
  }

  return response;
};


// PUT REQUEST
export const put = async (url, data = {}, headerItems = {}) => {
  const headers = createHeaders({headerItems});
  const response = await api.put(url, data, {
    headers: headers
  });

  //1102 - session expired error
  if (!response.ok && (response.data.error && response.data.error.errorCode === 1102)) {
    handleSessionExpiredError();
    return;
  }

  return response;
};

// PATCH REQUEST
export const patch = async (url, data = {}, headerItems = {}) => {
  const headers = createHeaders({headerItems});
  const response = await api.patch(url, data, {
    headers: headers
  });

  //1102 - session expired error
  if (!response.ok && (response.data.error && response.data.error.errorCode === 1102)) {
    handleSessionExpiredError();
    return;
  }

  return response;
};


// POST BINARY REQUEST
export const post_binary = async (url, data = {}) => {
  const headers = createUploadHeaders();
  const response = await api.post(url, data, {
    headers: headers
  });

  //1102 - session expired error
  if (!response.ok && (response.data.error && response.data.error.errorCode === 1102)) {
    handleSessionExpiredError();
    return;
  }

  return response;
};


// PUT BINARY REQUEST
export const put_binary = async (url, data = {}) => {
  const headers = createUploadHeaders();
  const response = await api.put(url, data, {
    headers: headers
  });

  //1102 - session expired error
  if (!response.ok && (response.data.error && response.data.error.errorCode === 1102)) {
    handleSessionExpiredError();
    return;
  }

  return response;
};


// DELETE REQUEST
export const _delete = async (url, params = {}, headerItems = {}) => {
  const headers = createHeaders({headerItems});
  const response = await api.delete(url, params,{
    headers: headers
  });

  //1102 - session expired error
  if (!response.ok && (response.data.error && response.data.error.errorCode === 1102)) {
    handleSessionExpiredError();
    return;
  }

  return response;
};
