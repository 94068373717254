import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/profile';
import * as fromActions from 'store/actions/profile';
import {ASSESSMENT_TYPES, NINE_LEVELS_ORDER} from 'utils/configuration/const/assessment-types';
import {get} from 'api';

function* getProfile({payload}) {
  try {
    const {userId} = payload;
    const {status, ok, data} = yield call(get, '/core/user/profile', {id: userId});

    if (ok && status === 200 && data.profile) {
      const nineLevelsAssessment = data.profile.find(p => p.assessment === ASSESSMENT_TYPES.NINE_LEVELS);

      //change structure of 9levels assessment result to have negative and positive values for each level;
      if (nineLevelsAssessment && nineLevelsAssessment.result) {
        let mappedResults = [];
        const signatureValues = [];
        const nineLevelsAssessmentResults = nineLevelsAssessment.result.results;

        nineLevelsAssessmentResults.forEach(assResult => {
          //replace cyan with turquoise. 9levels returns cyan whereas on front-end 'turquoise' is used;
          const assessmentResultId = assResult.id.replace('cyan', 'turquoise');
          const assessmentResultName = assResult.name.replace('cyan', 'turquoise');

          const obj = {id: assessmentResultId, name: assessmentResultName};

          if (assResult.id.includes('highvalues')) {
            signatureValues.push(assResult);
            return;
          }

          if (assResult.id.includes('res')) {
            return;
          }

          const positiveResult = assResult.result;
          const negativeResult = nineLevelsAssessmentResults.find(r => r.id === assResult.id.split('_').join('_res_')).result;

          obj.result = [-(Math.abs(Math.ceil(negativeResult))), Math.ceil(positiveResult)];

          mappedResults[NINE_LEVELS_ORDER[assessmentResultId.split('levels_')[1].toLowerCase()]] = obj;
        });

        nineLevelsAssessment.result.results = mappedResults;
        nineLevelsAssessment.result.signatureValues = signatureValues;
      }
      yield put(fromActions.getProfileFulfilled({profile: {userId, assessmentResults: data.profile}}));
    } else {
      yield put(fromActions.getProfileRejected({error: data}));
    }
  } catch (error) {
    yield put(fromActions.getProfileRejected({error}));
  }
}

export function* watchGetProfile() {
  yield takeEvery(fromActionTypes.GET_PROFILE, getProfile);
}
