export const LOGIN = 'LOGIN';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const AUTH_TOKEN_FETCHED = 'AUTH_TOKEN_FETCHED';

export const login = (loginDto) => {
  return {
    type: LOGIN,
    payload: { loginDto }
  };
};

export const loginFulfilled = ({ token }) => {
  return {
    type: LOGIN_FULFILLED,
    payload: { token }
  };
};

export const loginRejected = ({ error }) => {
  return {
    type: LOGIN_REJECTED,
    payload: { error }
  };
};

export const authTokenFetched = ({ authToken }) => {
  return {
    type: AUTH_TOKEN_FETCHED,
    payload: { authToken }
  };
};
