import REGEXES from 'utils/configuration/const/regexes';

export const validateEmail = (email) => {
  return REGEXES.EMAIL.test(email)
};

export const matchPasswords = (password1, password2) => {
  if (!password1 || !password2) {
    return false;
  }
  return password1 === password2;
};
