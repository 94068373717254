// REACT, STYLE, STORIES & COMPONENT
import React, {useCallback, useEffect, useState} from 'react';
import styles from './CandidateExternalSummary.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import ProfileSummary from 'features/+candidates/pages/CandidateSummary/ProfileSummary';
import {
  CollapsibleNext,
  TableCard,
  DetailsPageSkeleton,
  Link, PillGroup, RoleMatchSummary
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import {useBreakpoint} from 'utils/hooks';
import {getMappedVacancyData} from 'features/+candidates/store/vacancy/vacancy.utils';
import {setDecimalSeperator} from 'utils/userpreference';
import {ASSESSMENT_TITLES} from 'utils/configuration/const/assessment-types';

// STORE
import * as api from 'api';
import {useDispatch, useSelector} from 'react-redux';
import {listAssessments} from 'store/actions';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';

// CONFIG & DATA
// const Config = {};


const CANDIDATE_INFO = [
  {value: 'yearOfBirth', labelKey: 'year_of_birth', labelFallback: 'Alter', lookInCandidateData: true},
  {value: 'gender', labelKey: 'rmp_ass_form_gender_label', labelFallback: 'Geschlecht', lookInCandidateData: true},
  {value: 'residence', labelKey: 'residence_and_nationality', labelFallback: 'Wohnhaft in / Nationalität', lookInCandidateData: true},
  {value: 'degree', labelKey: 'highest_school_degree', labelFallback: 'Höchster Schulabschluss', lookInCandidateData: true},
  {value: 'careerLevel', labelKey: 'current_career_level', labelFallback: 'Akt. Karrierestufe', lookInCandidateData: true},
  {value: 'positionAndJobFamily', labelKey: 'position_and_jobFamily', labelFallback: 'Aktuelle Position / Job-Familie', lookInCandidateData: true},
  {value: 'industryExperienceMapped', labelKey: 'industry_experience', labelFallback: 'Branchenerfahrung', lookInCandidateData: true},
  {value: 'motivation', labelKey: 'motivation', labelFallback: 'Motivation', lookInCandidateData: true},
  {value: 'jobRequirements', labelKey: 'job_requirements', labelFallback: 'Job-Anforderungen', lookInCandidateData: true},
  {value: 'startTermin', labelKey: 'start_termin', labelFallback: 'Start-Termin', lookInCandidateData: true},
];

// COMPONENT: CandidateExternalSummary
const CandidateExternalSummary = (props) => {
  // PROPS
  const {candidate, token} = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();
  const {isXs} = useBreakpoint();

  // const [documents, setDocuments] = useState([]);
  // const [links, setLinks] = useState([]);

  // VACANCY: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [vacancies, setVacancies] = useState([]);

  const [vacancy, setVacancy] = useState();

  const [tableCardColumn1Items, setTableCardColumn1Items] = useState([]);
  const [tableCardColumn2Data, setTableCardColumn2Data] = useState();

  const setVacancyData = useCallback((vacancy) => {
    const mappedVacancyData = getMappedVacancyData(vacancy);
    setVacancy(mappedVacancyData);

    setTableCardColumn1Items([
      {
        value: mappedVacancyData.metaData && mappedVacancyData.metaData.roleCareerLevel,
        label: translate('role_careerlevel') || 'Karrierestufe der Rolle'
      },
      {value: mappedVacancyData.name, label: translate('role_description') || 'Rollenbezeichnung'},
      {value: mappedVacancyData.metaData.salaryRange, label: translate('salary_range') || 'Gehaltsspanne'},
      {value: mappedVacancyData.metaData.utilizationString, label: translate('workload') || 'Auslastung'},
      {value: mappedVacancyData.metaData.startDateString, label: translate('start_termin') || 'Start-Termin'},
      {value: mappedVacancyData.metaData.location, label: translate('location') || 'Standort'}
    ]);

    if (candidate.profileCreated) {
      setTableCardColumn2Data({
        title: translate('candidate_information_lbl') || 'Kandidat:innen-Angaben',
        items: [
          {
            value: candidate.candidateData.careerLevel,
            label: translate('current_careerlevel_long') || 'Aktuelle Karrierestufe'
          },
          {value: candidate.candidateData.currentPosition, label: translate('current_position') || 'Aktuelle Position'},
          {value: candidate.candidateData.desiredSalaryString, label: translate('desired_salary') || 'Gehaltswunsch p.a.'},
          {value: candidate.candidateData.desiredWeeklyHours, label: translate('desired_workload') || 'Auslastungswunsch'},
          {value: candidate.candidateData.earliestStart, label: translate('start_termin') || 'Start-Termin'},
          {value: candidate.candidateData.residence, label: translate('residence') || 'Wohnort'}
        ]
      });
    }
  }, [candidate, translate]);

  // ASSESSMENTS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessmentsWithNoClifton);
  useEffect(() => {
    dispatch(listAssessments());
  }, [dispatch]);

  useEffect(() => {
    if (!candidate.id) {
      return;
    }

    setVacancies(candidate.jobs || []);

    if (candidate.jobs && candidate.jobs.length > 0) {
      setVacancyData(candidate.jobs[0]);
    } else {
      setVacancy(null);
    }
  }, [candidate, setVacancyData]);

  // ROLE MATCHING: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [userMatchingDetails, setUserMatchingDetails] = useState();

  const getMatchTitle = useCallback((score) => {
    if (score >= 90) {
      return translate('employee_role_very_high_matching', ['{{username}}', candidate.name, '{{role}}', '']) ||
        `${candidate.name} hat eine sehr hohe Übereinstimmung für diese Rolle.`;
    }
    if (score < 90 && score >= 80) {
      return translate('employee_role_high_matching', ['{{username}}', candidate.name, '{{role}}', '']) ||
        `${candidate.name} hat eine hohe Übereinstimmung für diese Rolle.`;
    }
    if (score < 80 && score >= 40) {
      return translate('employee_role_medium_matching', ['{{username}}', candidate.name, '{{role}}', '']) ||
        `${candidate.name} hat eine mittlere Übereinstimmung für diese Rolle.`;
    }
    return translate('employee_matching_low', ['{{username}}', candidate.name, '{{role}}', '']) ||
      `${candidate.name} hat eine niedrige Übereinstimmung für diese Rolle.`;
  }, [candidate, translate]);

  useEffect(() => {
    if (!vacancy || !vacancy.roleId) {
      return;
    }

    api.get(`/recruiting/jobs/${vacancy.id}/matches`,
      {expand: 'score'},
      {Authorization: `Bearer ${token}`}
    )
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          const candidateMatchDetails = data.matches.find(m => m.user === candidate.id);
          if (candidateMatchDetails) {
            setUserMatchingDetails({
              ...candidateMatchDetails,
              matchingText: getMatchTitle(candidateMatchDetails.score)
            });
          }
        }
      })
      .catch(error => console.error(error.message))

  }, [candidate, vacancy, getMatchTitle, token]);

  const getRoleMatchSummaryLinks = () => {
    if (!userMatchingDetails || !assessmentsFromStore) {
      return;
    }

    const clickableLinks =[];
    const nonClickableLinks = [];

    assessmentsFromStore
      .forEach(assessment => {
        const thisAssessment = userMatchingDetails.assessments.find(a => a.assessmentId === assessment.id);

        if (thisAssessment) {
          clickableLinks.push({
            id: thisAssessment.assessmentId,
            label: `${translate(ASSESSMENT_TITLES[thisAssessment.assessmentId]) || assessment.title} - ${setDecimalSeperator(thisAssessment.score)} %`,
            clickable: true
          });
        } else {
          nonClickableLinks.push({
            id: assessment.id,
            label: `${translate(ASSESSMENT_TITLES[assessment.id]) || assessment.title}`,
            clickable: false
          });
        }
      })

    return clickableLinks.concat(nonClickableLinks);
  }

  // RENDER: DetailsPageSkeleton
  if (!candidate) {
    return <div className={styles.candidateExternalSummary}>
      <div className={styles.gridContainer}>
        <DetailsPageSkeleton/>
      </div>
    </div>
  }

  // RENDER: CandidateExternalSummary
  return (
    <div className={classNames(styles.candidateExternalSummary)}>
      <div className={styles.gridContainer}>
        {/*LEFT CONTENT*/}
        <div className={styles.leftContent}>
          {(candidate && candidate.firstName) ?
            <ProfileSummary
              candidate={candidate}
              showControls={false}
            /> :
            <div>
              <div className='blu-typeXxs'>{translate('candidate_profile') || 'Kandidat:innen-Profil'}</div>
              <div className='blu-typeCopy' style={{marginTop: '4px'}}>
                {candidate.candidateData ? candidate.candidateData.currentPosition : ''}
              </div>
            </div>
          }

          {/*CANDIDATE INFO*/}
          {(candidate && (candidate.firstName || candidate.candidateData)) &&
          <div className={styles.candidateInfo}>
            <CollapsibleNext
              header={(
                <span className='blu-typeLabel'>
                  {translate('candidate_info_lbl') || 'Kandidat:innen-Info'}
                </span>
              )}
              headerClassName={styles.header}
              withBorders
              isOpenOverride={!isXs}
            >
              <>
                {CANDIDATE_INFO
                  .filter(candidateInfo => {
                    // if profile is anonymized
                    if (!candidate.firstName && ['yearOfBirth', 'gender'].includes(candidateInfo.value)) {
                      return false;
                    }

                    return true;
                  })
                  .map((candidateInfo, index) => {
                  let value;
                  if (candidateInfo.lookInCandidateData) {
                    value = candidate.candidateData[candidateInfo.value];
                  } else {
                    value = candidate[candidateInfo.value];
                  }

                  return (
                    <div className={styles.listItem} key={index}>
                      <div className='blu-typeLabel'>
                        {translate(candidateInfo.labelKey) || candidateInfo.labelFallback}
                      </div>
                      <div className={styles.value}>
                        {!value && '-'}

                        {value && (
                          Array.isArray(value) ? <ul>{value.map((v, index) => <li key={index}>{v}</li>)}</ul> : value
                        )}
                      </div>
                    </div>
                  )
                  }
                )}
              </>
            </CollapsibleNext>
          </div>
          }

          {/*ATTACHMENTS*/}
          {/*<div className={styles.attachments}>*/}
          {/*  {documents.length === 0 && links.length === 0 &&*/}
          {/*    <Card hasPaddingsSmall>*/}
          {/*      <div className={styles.label}>Dokumente & Links</div>*/}
          {/*      <div className={styles.copy}>Es wurden noch keine Dokumente oder Links hinzugefügt.</div>*/}
          {/*      <Button size={'S'} looks={'secondary'}>Hinzufügen / verwalten</Button>*/}
          {/*    </Card>*/}
          {/*  }*/}

          {/*  {(documents.length > 0 || links.length > 0) &&*/}
          {/*  <CollapsibleNext*/}
          {/*    header={<span className={styles.label}>Dokumente & Links</span>}*/}
          {/*    headerClassName={styles.header}*/}
          {/*    withBorders*/}
          {/*    isOpenOverride={!isXs}*/}
          {/*  ></CollapsibleNext>*/}
          {/*  }*/}
          {/*</div>*/}

        </div>

        {/*RIGHT CONTENT*/}
        <div className={styles.rightContent}>
          {candidate.deactivated &&
          <div className={styles.deactivated}>
            <div className='blu-typeXxs'>
              {translate('user_settings_deactivated_user_long') || 'Dieses Benutzerprofil ist deaktiviert'}
            </div>
            <div className={classNames('blu-typeCopyStrong', 'marginTopXs')}>
              {translate('user_settings_deactivated_user_description') ||
              'Benutzerprofile können durch Administratoren innerhalb der Nutzerverwaltung aktiviert werden.'
              }
            </div>
          </div>
          }

          {!candidate.deactivated &&
          <>
            {/*IF PROFILE IS NOT YET CREATED*/}
            {(!candidate.profileCreated) &&
            <div className={styles.profileCompletionPending}>
              <div className='blu-typeXxs'>
                {translate('no_user_profile_header') || 'Profilerstellung ausstehend.'}
              </div>
              <div className={classNames('blu-typeCopyStrong', 'marginTopXs')}>
                {translate('no_user_profile_copy') || 'Die Kandidat:in hat noch kein Nutzerprofil erstellt.'}
              </div>
            </div>
            }

            {/*IF NO VACANCY ASSIGNED*/}
            {candidate.profileCreated && vacancies.length === 0 &&
            <div className={styles.profileCompletionPending}>
              <div className='blu-typeXxs'>
                {translate('no_user_vacancy_header', [
                  '{{username}}', candidate.firstName || candidate.name
                ]) || `${candidate.firstName || candidate.name} wurde bisher zu keiner Vakanz zugeordnet.`
                }
              </div>
              <div className={classNames('blu-typeCopyStrong', 'marginTopXs')}>
                {translate('no_user_vacancy_copy') || 'Füge die Kandidat:in zu einer Vakanz hinzu, um Informationen zum Matching für die Vakanz zu erhalten.'}
              </div>
            </div>
            }

            {vacancies.length > 0 &&
            <>
              <div className='blu-typeXxs'>
                {translate('candidate_summary') || 'Zusammenfassung'}
              </div>

              {vacancies.length > 1 &&
              <PillGroup
                pillItems={vacancies.map(vacancy => {
                  let vacancyName = vacancy.name;
                  if (vacancyName.length > 30) {
                    vacancyName = `${vacancyName.slice(0, 26)} ...`;
                  }

                  return {
                    id: vacancy.id,
                    label: vacancyName
                  }
                })}
                activeIndex={vacancy ? vacancies.map(v => v.id).findIndex(v => v === vacancy.id) : 0}
                looks={'primary'}
                onPillClick={pillItem => {
                  const thisVacancy = candidate.jobs.find(job => job.id === pillItem.id);
                  if (thisVacancy) {
                    setVacancyData(thisVacancy);
                  }
                }}
              />
              }

              {vacancy &&
              <TableCard
                title={translate('candidate_for_lbl') || 'Kandidat/in für'}
                subHeaderChildren={<div className={styles.vacancyTableSubHeader}>
                  {/*LABELS*/}
                  <div className={styles.labels}>
                    <div className={styles.label}>{vacancy.name}</div>

                    {(vacancy.metaData.employmentType || vacancy.metaData.team) &&
                    <div className={styles.subLabels}>
                      {vacancy.metaData.employmentType &&
                      <span>{`${vacancy.metaData.employmentType}${vacancy.team ? ', ' : ''}`}</span>
                      }
                      {vacancy.team && <Link to={`/teams/${vacancy.team.id}/profile`} openInNewTab>
                        {vacancy.team.name}
                      </Link>
                      }
                    </div>
                    }

                  </div>

                  {/*CONTROLS*/}
                  {/*<div className={styles.controls}>*/}
                  {/*  <Button*/}
                  {/*    size='S'*/}
                  {/*    looks={'secondary'}*/}
                  {/*    onClick={() => window.open(`/vacancies/${vacancy.id}/summary`)}*/}
                  {/*  >*/}
                  {/*    {translate('show_vacancy_btn') || 'Vakanz anzeigen'}*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                </div>
                }
                column1Data={{
                  title: translate('vacancy_data_lbl') || 'Vakanz-Daten',
                  items: tableCardColumn1Items
                }}
                column2Data={tableCardColumn2Data}
              />
              }

              {/*ROLE MATCHING SUMMARY*/}
              {(vacancy && vacancy.roleId && candidate.profileCreated) &&
              <RoleMatchSummary
                matchingText={userMatchingDetails && userMatchingDetails.matchingText}
                matchingPercentage={userMatchingDetails && userMatchingDetails.score}
                links={getRoleMatchSummaryLinks()}
              >
              </RoleMatchSummary>
              }

            </>
            }
          </>
          }

        </div>
      </div>

    </div>
  );
};

export default CandidateExternalSummary;
