import React from 'react';
import styles from './NineLevelsResult.module.scss';

const DIAGRAM_HEIGHT = 15;
const UNIT = 200; //from -100 to 100

const BIG_EXTRA_SPACE = 7.5;
const SMALL_EXTRA_SPACE = 2.5;

const MIN_DISTANCE = 1;
const HIGHEST_VALUE = 100;

const NineLevelsDiagram = (props) => {
  const {results, range, width, color} = props;

  const step = (width / UNIT);

  let result1;
  let result2;

  let leftPathStart;
  let leftPathWidth;

  let rightPathStart;
  let rightPathWidth;

  let rightPathHor;
  let leftPathHor;

  let extraSpace = (range && results) ? SMALL_EXTRA_SPACE : BIG_EXTRA_SPACE;

  if (results && results.length > 0) {
    result1 = results[0];
    result2 = results[1];

    leftPathStart = (width/2) - (Math.abs((width/2) * result1 / (UNIT / 2)));
    leftPathWidth = Math.abs((width/2) * result1 / (UNIT / 2)) - 1; //-1 is the half of vertical line width

    rightPathStart = (width / 2) + 1; //+1 is the half of vertical line width
    rightPathWidth = (width/2) * result2 / (UNIT / 2);

    rightPathHor = (rightPathWidth - (extraSpace + 1) < 0) ? 0 : (rightPathWidth - (extraSpace + 1)); //1 - half ov vertical line
    leftPathHor = (leftPathWidth - extraSpace < 0) ? 0 : (leftPathWidth - extraSpace) ;
  }

  let range1;
  let range2;
  if (range && range.length > 0) {
    range1 = range[0];
    range2 = range[1];

    if (range1 === range2) {
      if (range2 + MIN_DISTANCE > HIGHEST_VALUE) {
        range1 -= MIN_DISTANCE;
      } else {
        range2 += MIN_DISTANCE;
      }
    }
  }

  return (
    <svg width={width} height={DIAGRAM_HEIGHT}>
      {/*RANGE*/}
      {(range && range.length > 0) &&
      <rect
        x={range1 < 0 ?
          Math.abs((step * Math.abs(range1)) - (step * (UNIT / 2))) :
          (step * Math.abs(range1)) + (step * (UNIT / 2))
        }
        width={(range2 - range1) * step}
        height="15" rx="7.5"
        fill={styles.colorPrimary3}
      />
      }

      {/*MAIN HORIZONTAL LINE*/}
      <line x1={0} y1={DIAGRAM_HEIGHT / 2} x2={width} y2={DIAGRAM_HEIGHT / 2} stroke={styles.colorGrey3} strokeWidth={1}/>

      {/*VERTICAL LINE ON CENTER*/}
      <line x1={width/2} y1={0} x2={width/2} y2={DIAGRAM_HEIGHT} stroke={'#fff'} strokeWidth={2}/>

      {/*SMALL DIAGRAM FOR RESULTS*/}
      {(range && results) &&
      <>
        <path
          d={`M${rightPathStart},5 h${rightPathHor} a2.5,2.5 0 0 1 2.5,2.5 v0 a2.5,2.5 0 0 1 -2.5,2.5 h-${rightPathHor} v-0 z`}
          fill={styles[`color${color.substring(0,1).toUpperCase() + color.substring(1, color.length)}1`]}
        />

        <path
          d={`M${leftPathStart},5 h${leftPathWidth} v5 h-${leftPathHor} a2.5,2.5 0 0 1 -2.5,-2.5 v-0 a2.5,2.5 0 0 1 2.5,-2.5 z`}
          fill={styles[`color${color.substring(0,1).toUpperCase() + color.substring(1, color.length)}2`]}
        />
      </>
      }

      {/*BIG DIAGRAM FOR RESULTS*/}
      {!range &&
        <>
          <path
            d={`M${rightPathStart},0 h${rightPathHor} a7.5,7.5 0 0 1 7.5,7.5 v0 a7.5,7.5 0 0 1 -7.5,7.5 h-${rightPathHor} v-0 z`}
            fill={styles[`color${color.substring(0,1).toUpperCase() + color.substring(1, color.length)}1`]}
          />

          <path
            d={`M${leftPathStart},0 h${leftPathWidth} v15 h-${leftPathHor} a7.5,7.5 0 0 1 -7.5,-7.5 v-0 a7.5,7.5 0 0 1 7.5,-7.5 z`}
            fill={styles[`color${color.substring(0,1).toUpperCase() + color.substring(1, color.length)}2`]}
          />
        </>
      }
    </svg>
  );
};

NineLevelsDiagram.defaultProps = {
  width: 250
};

export default NineLevelsDiagram;
