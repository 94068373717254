// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useCallback } from 'react';
import styles from './QuestionBipolarSlider.module.scss';

// ASSETS
import {ReactComponent as CursorUp} from 'assets/icons/icn_cursor_up.svg';
import {ReactComponent as CursorDown} from 'assets/icons/icn_cursor_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {Button, SliderBipolar } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import {breakpoints, useBreakpoint} from 'utils/hooks';


// STORE

// CONFIG & DATA
const CONFIG = {
  answerDelay: 200,
  defaultAnswer: 0,
};

const DEFAULT_HINT = 'Use {{key arrow up / key arrow down}} on your keyboard to switch between questions.';

// COMPONENT: QuestionBipolarSlider
const QuestionBipolarSlider = (props) => {
  // PROPS
  const {
    question = {},
    range = {
      answerFrom: -5,
      answerStep: 1,
      answerTo: 5,
    },
    selectedValue,
    clickBlock,
    onAnswer = () => {},
    onAnimation = () => {},
    onHelp = () => {}
  } = props;

  // COMPONENT/UI STATE and REFS
  const questionIndex = question.id.split('work-preference_0_')[1];

  const translate = useTranslate();
  const bp = useBreakpoint();

  const [value, setValue] = useState();
  const [answered, setAnswered] = useState(false);
  useEffect(() => {
    if (!answered && !isNaN(selectedValue)) { // don't update the value anymore when answered
      setValue(selectedValue);
      setAnswer(selectedValue);
    }
  }, [selectedValue, answered]);
  const [answer, setAnswer] = useState(CONFIG.defaultAnswer);

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS
  const handleAnswer = useCallback((answer) => {

    if (clickBlock) return;
    if (answered) return;
    if (isNaN(answer)) return;

    setAnswered(true);
    onAnimation();
    setTimeout(() => {
      onAnswer(answer, CONFIG.answerDelay);
    }, CONFIG.answerDelay);
  }, [clickBlock, answered, onAnimation, onAnswer]);

  // EVENT HANDLES

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // ENTER TO CONFIRM
    if (event.key === 'Enter') {
      handleAnswer(answer);
    }
  }, [answer, handleAnswer]);
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  // HELPERS

  // RENDERS

  const renderHint = () => {
    const hint = [];

    const translation = translate('assessment_bipolar_slider__hint') || DEFAULT_HINT;

    if (translation) {
      translation
        .split('{{key arrow up / key arrow down}}')
        .forEach((s, index) => {
          if (index === 0) {
            hint.push(
              <div className={styles.line} key={`H${index}`}>
                <span>{s}</span>
                <CursorUp />
                <CursorDown />
              </div>
            )
          } else {
            hint.push(<span key={`H${index}`}>{s}</span>)
          }
        });
    }

    return hint;
  }


  // RENDER: QuestionBipolarSlider
  if (!question) {
    return null;
  }
  return (
    <div className={classNames(styles.questionBipolarSlider, {
      [styles.closing]: answered
    })}>
      <div className={styles.container}>
        <div className={styles.questionContainer}>

          {/* TITLE */}
          <div className={styles.title}>
            {translate('wp_ass_guidance_question') || 'Was ist deine Arbeitspräferenz im folgenden Bereich:'}
          </div>

          {/* QUESTIONS */}
          <div className={styles.questions}>
            <div className={styles.question}>
              {question.question || 'Risikobereitschaft'}
            </div>
            <div
              className={styles.sliderRoot}
            >
              <SliderBipolar
                value={value}
                from={range.answerFrom}
                to={range.answerTo}
                step={range.answerStep}
                onChange={(value) => {
                  setAnswer(value);
                }}
              />
              <div className={styles.options}>
                <div
                  className={classNames(styles.option, {
                    [styles.active]: answer < 0
                  })}
                >
                  {translate(`wp_${questionIndex}_pole_left`) || 'Ich bewege mich lieber auf der sicheren Seite'}
                </div>
                <div
                  className={classNames(styles.option, {
                    [styles.active]: answer > 0
                  })}
                >
                  {translate(`wp_${questionIndex}_pole_right`) || 'Ich gehe gerne Risiken ein'}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.controls}>
            <Button
              disabled={isNaN(answer)}
              onClick={() => {
                handleAnswer(answer);
              }}
            >
              {translate('wp_ass_selection_button_next') || 'Weiter'}
            </Button>
          </div>

          {/*HELP BUTTON*/}
          <div className={styles.helpButton}>
            <Button
              size={'S'}
              looks={'tertiary'}
              onClick={() => onHelp('bipolar_slider')}
            >
              {translate('assessment_help_button') || 'Hilfe bei der Auswahl'}
            </Button>
          </div>

          {/*HINT*/}
          {bp.bpWidth > breakpoints.S.bpWidth &&
          <div className={styles.hint}>
            <div className={styles.line}>{renderHint()}</div>
          </div>
          }

        </div>
      </div>
    </div>
  );
};

export default QuestionBipolarSlider;
