import {isValid} from './numbers';

export const RMP_FIRST_MARKER_POSITION = 0.84;
export const RMP_THIRD_MARKER_POSITION = 2;

export const resultIsStrong = (result) => {
  return (result && result > RMP_FIRST_MARKER_POSITION && result <= RMP_THIRD_MARKER_POSITION);
};

export const resultIsAverage = (result) => {
  return (isValid(result) && result >= -RMP_FIRST_MARKER_POSITION && result <= RMP_FIRST_MARKER_POSITION);
};

export const resultIsWeak = (result) => {
  return (result && result < -RMP_FIRST_MARKER_POSITION && result >= -RMP_THIRD_MARKER_POSITION);
};

//strong results
export const getStrongResults = (results = []) => {
  return results
    .filter(resultItem => resultIsStrong(resultItem.result))
    .sort((itemA, itemB) => {
      //sort descending
      if (itemA.result > itemB.result) {
        return -1;
      }
      if (itemA.result < itemB.result) {
        return 1;
      }
      return 0;
    });
}

//weak results
export const getWeakResults = (results = []) => {
  return results
    .filter(resultItem => resultIsWeak(resultItem.result))
    .sort((itemA, itemB) => {
      //sort ascending
      if (itemA.result > itemB.result) {
        return 1;
      }
      if (itemA.result < itemB.result) {
        return -1;
      }
      return 0;
    });
}

//average results
export const getAverageResults = (results = []) => {
  const filteredAverageMotives = results.filter(resultItem => resultIsAverage(resultItem.result));
  const positiveAverageMotives = filteredAverageMotives
    .filter(motive => motive.result >= 0)
    .sort((itemA, itemB) => {
      //sort descending
      if (itemA.result > itemB.result) {
        return -1;
      }
      if (itemA.result < itemB.result) {
        return 1;
      }
      return 0;
    });

  const negativeAverageMotives = filteredAverageMotives
    .filter(motive => motive.result < 0)
    .sort((itemA, itemB) => {
      //sort ascending
      if (itemA.result > itemB.result) {
        return 1;
      }
      if (itemA.result < itemB.result) {
        return -1;
      }
      return 0;
    });

  return positiveAverageMotives.concat(negativeAverageMotives);
}
