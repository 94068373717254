import React from 'react';

// OTHER COMPONENTS
import ProgressCircle from 'ui/basic/micro-ui/ProgressCircle';

// UTILS
import * as api from 'api';
import { highlight, emptyDash, easyDateMY, percentWithDecimal } from 'utils/textTools';
import { timestampToFullDate } from 'utils/dateTools';
import { translate } from 'utils/translator/translator';

// CONFIGURATION
import { REGISTRATION_STATUSES } from 'utils/configuration/const/registration-statuses';
import {REFERENCE_PROFILE_TYPES} from 'utils/configuration/const/reference-profile';
import {CAREER_LEVELS_BY_ID} from 'utils/configuration/const/career-level';


// CONSTS
const FALLBACK_ASSESSMENT_COUNT = 6;
const LAYER_ID_PERSONALITY = 1;
const LAYER_ID_PREFERENCES = 2;
const LAYER_ID_POTENTIAL = 3;
const LAYER_ID_COMPETENCIES = 4;




// FUNCTION MAPPERS
const userNameMapper = (user) => {
  const { firstName, lastName, mail } = user;

  return !firstName && !lastName ? mail : `${firstName || '-'} ${lastName || '-'}`;
};

const userAvatarFallbackMapper = (user) => {
  const { firstName, lastName } = user;

  return `${firstName ? firstName[0].toUpperCase() : '-'}${lastName ? lastName[0].toUpperCase() : '-'}`
};


const roleMapper = (role) => {
  const translationKey = `platform_roles_${role.split('_CUSTOM')[0].toLowerCase()}`;

  const fallback = role
    .split('_')
    .map(item => item.toLowerCase())
    .filter(item => item !== 'company')
    .map(item => `${item[0].toUpperCase()}${item.slice(1)}`)
    .join(' ');

  return role
    ? translate(translationKey)
      || fallback
    : '-';
};

const lastActiveMapper = (lastActive) => {
  return lastActive
    ? timestampToFullDate(lastActive)
    : '-';
};





// FUNCTION DISPLAYS
const userStatusDisplay = (user, filterString = '', bp = {}) => {
  const { profileCompletion, registrationStatus } = user;
  const { completed, available } = profileCompletion || {};


  let progress = 0;
  let progressLabel = translate('table_status_invited') || 'Eingeladen';
  if (completed) {
    progress = completed / (available || FALLBACK_ASSESSMENT_COUNT) * 100;
    progressLabel =  progress >= 100
      ? translate('table_status_assessments_completed') || 'Assessed'
      : translate('table_status_assessments') || 'Assessment';
  }
  else if (registrationStatus === REGISTRATION_STATUSES.REGISTERED) {
    progress = 10;
    progressLabel = translate('table_status_registered') || 'Profil erstellt';
  }

  const cellLabel = bp.isXs
    ? ''
    : translate('table_status_column_label') || 'Status';
  const cellValue = (
    <>
      <ProgressCircle progress={progress} size='S' />
      { !bp.isXs && highlight(progressLabel, filterString) }
    </>
  );

  const lastCellXs = (
    <ProgressCircle progress={progress} size='S' />
  );

  return {
    progress,
    progressLabel,
    cellLabel,
    cellValue,
    lastCellXs
  };
};


// FUNCTION DISPLAYS
const roleStatusDisplay = (referenceProfile = {}, filterString = '', bp = {}) => {
  let progress;
  let progressLabel;

  if (referenceProfile.type === REFERENCE_PROFILE_TYPES.MANUAL) {
    progress = 100;
    progressLabel = '';
  } else {
    const overallProgress = (referenceProfile.progress && referenceProfile.progress.overall) ?
      referenceProfile.progress.overall :
      0;
    if (overallProgress === 100) {
      progress = 100;
      progressLabel = '';
    } else if (overallProgress === 0) {
      progress = 0;
      progressLabel = translate('table_role_status_created') || 'Angelegt';
    } else {
      progress = overallProgress;
      progressLabel = translate('table_role_status_in_progress') || 'In Bearbeitung';
    }
  }

  const cellLabel = (bp.isXs) ? '' : translate('table_status_column_label') || 'Status';
  const cellValue = (
    <>
      <ProgressCircle progress={progress} size='S' />
      { !bp.isXs && progressLabel && highlight(progressLabel, filterString) }
    </>
  );

  const lastCellXs = <ProgressCircle progress={progress} size='S' />

  return {
    progress,
    progressLabel,
    cellLabel,
    cellValue,
    lastCellXs
  };
};

const teamStatusDisplay = (team = {}, filterString = '', bp = {}) => {
  let progress;
  let progressLabel;

  if (!team.positions || team.positions.length === 0) {
    if (team.users && team.users.length > 0) {
      progress = 100;
      progressLabel = '';
    } else {
      progress = 0;
      progressLabel = translate('team_created_status') || 'Team erstellt';
    }
  } else {
    // don't count roles we have no *valid* user for !
    const filledRoles = team.positions.filter(positionItem => {
      let filter = false;
      if (positionItem.user) {
        const userId = positionItem.user;
        const user = team.users.find(user => user.user === userId);
        if(user) {
          filter = true;
        }
      }
      return filter;
    });

    if (team.positions.length === filledRoles.length) {
      progress = 100;
      progressLabel = '';
    } else {
      progress = filledRoles.length * 100 / team.positions.length;
      progressLabel = translate('team_unoccupied_roles_status',
        ['{{roles}}', team.positions.length - filledRoles.length]) ||
        '5 Rollen unbesetzt';
    }
  }

  const cellLabel = (bp.isXs) ? '' : translate('table_status_column_label') || 'Status';
  const cellValue = (
    <>
      <ProgressCircle progress={progress} size='S' />
      { !bp.isXs && progressLabel && highlight(progressLabel, filterString) }
    </>
  );

  const lastCellXs = <ProgressCircle progress={progress} size='S' />

  return {
    progress,
    progressLabel,
    cellLabel,
    cellValue,
    lastCellXs
  };
};

// VACANCY STATUS
const vacancyStatusDisplay = (vacancy, filterString = '', bp = {}) => {
  const {status} = vacancy;


  let progress = 80;
  let progressLabel = translate('vacancy_status_open') || 'Offen';
  let archived;

  if (status === 'staffed') {
    progress = 100;
    progressLabel = translate('vacancy_status_staffed') || 'Besetzt';
  }
  else if (status === 'archived') {
    progress = 100;
    progressLabel = translate('vacancy_status_archived') || 'Archiviert';
    archived = true;
  }

  const cellLabel = bp.isXs
    ? ''
    : translate('table_status_column_label') || 'Status';
  const cellValue = (
    <>
      <ProgressCircle progress={progress} size='S' looks={archived && 'gray'} />
      { !bp.isXs && highlight(progressLabel, filterString) }
    </>
  );

  const lastCellXs = (
    <ProgressCircle progress={progress} size='S' />
  );

  return {
    progress,
    progressLabel,
    cellLabel,
    cellValue,
    lastCellXs
  };
};

// EMPLOYEES
export const employeesData = [
  {
    'id': '2342e8cd-362d-471c-a999-eead094ef9dd',
    'name': 'COMPANY_USER',
    'role': 'COMPANY_USER',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': null,
        'companyEntryDate': null,
        'profession': null
      },
      'firstName': null,
      'id': '5fa16e702e382db99adb9ce1',
      'lastActive': null,
      'lastName': null,
      'mail': 'carolin@bluquist.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 0,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'invited'
    }
  },
  {
    'id': 'c4a20990-d3cf-40bf-9309-5e52e670503b',
    'name': 'COMPANY_USER',
    'role': 'COMPANY_USER',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': null,
        'companyEntryDate': null,
        'profession': null
      },
      'firstName': null,
      'id': '5fa16e702e382db99adb9ce8',
      'lastActive': null,
      'lastName': null,
      'mail': 'katrin@bluquist.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 0,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'invited'
    }
  },
  {
    'id': '0151a3d6-c03e-4e4b-8b06-92e4156372bf',
    'name': 'Company Administrator',
    'role': 'COMPANY_ADMINISTRATOR',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': 'Professional',
        'companyEntryDate': 1598911200,
        'profession': 'Administrator'
      },
      'firstName': 'bluquist',
      'id': '5f6b8691817786e384aa1e3c',
      'lastActive': 1602573400,
      'lastName': 'Admin',
      'mail': 'operations@blueexcellence.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 0,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'registered'
    }
  },
  {
    'id': '0feb9db5-6468-4cae-a6b2-0c2bc186c290',
    'name': 'COMPANY_ADMINISTRATOR',
    'role': 'COMPANY_ADMINISTRATOR',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': 'Executive',
        'companyEntryDate': 1506808800,
        'profession': 'Head of Potential Engineering'
      },
      'firstName': 'Simon',
      'id': '5f6ae7f68289aa709efc5a6e',
      'lastActive': 1609752302,
      'lastName': 'Brumm',
      'mail': 'simon@bluquist.com',
      'organizationalRoles': [
        '8b6abdaf-ae1b-40f2-8c84-54ff223970f1'
      ],
      'profileCompletion': {
        'available': 7,
        'completed': 7,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'registered'
    }
  },
  {
    'id': '10435385-cc66-4081-871b-461001367903',
    'name': 'COMPANY_USER',
    'role': 'COMPANY_USER',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': 'Professional',
        'companyEntryDate': null,
        'profession': 'Sales Management'
      },
      'firstName': 'Dimi',
      'id': '5fad49f90a4664c56d64dee0',
      'lastActive': 1610552862,
      'lastName': 'Chatzipanagiotou',
      'mail': 'dimi@bluquist.com',
      'organizationalRoles': [
        '61d09802-cb22-4ee4-b79d-1f48a4bac50a'
      ],
      'profileCompletion': {
        'available': 7,
        'completed': 3,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'registered'
    }
  },
  {
    'id': 'bde925a5-3fef-4868-b863-986e4fac0078',
    'name': 'COMPANY_ADMINISTRATOR',
    'role': 'COMPANY_ADMINISTRATOR',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': 'Executive',
        'companyEntryDate': 1538344800,
        'profession': 'Managing Director'
      },
      'firstName': 'Johannes',
      'id': '5f6b88cab4d849c9dbaa85dc',
      'lastActive': 1610032519,
      'lastName': 'Ehrhardt',
      'mail': 'johannes@bluquist.com',
      'organizationalRoles': [
        '44129ccf-28d0-4ea9-b388-ff8aa10d79e2'
      ],
      'profileCompletion': {
        'available': 7,
        'completed': 5,
        'started': 0
      },
      'registeredDate': 1606928790,
      'registrationStatus': 'registered'
    }
  },
  {
    'id': '8e2b5292-1726-4148-8429-15df8ce184b1',
    'name': 'COMPANY_ADMINISTRATOR',
    'role': 'COMPANY_ADMINISTRATOR',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': 'Executive',
        'companyEntryDate': 1546297200,
        'profession': 'CLO'
      },
      'firstName': 'Brunello',
      'id': '5fb22bd00a4664c56d64e482',
      'lastActive': 1610543273,
      'lastName': 'Gianella',
      'mail': 'brunello@bluquist.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 5,
        'started': 0
      },
      'registeredDate': 1608123843,
      'registrationStatus': 'registered'
    }
  },
  {
    'id': '00d9747b-fc9b-40ff-901d-e387cc450bbc',
    'name': 'COMPANY_ADMINISTRATOR',
    'role': 'COMPANY_ADMINISTRATOR',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': 'Senior',
        'companyEntryDate': 1551394800,
        'profession': 'Software Engineer'
      },
      'firstName': 'Jonathan',
      'id': '5fa040342e382db99adb94be',
      'lastActive': 1610554891,
      'lastName': 'Grupp',
      'mail': 'jonathan.grupp@gmail.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 7,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'registered'
    }
  },
  {
    'id': '49d403ac-4695-47c6-9781-cc879e53c8eb',
    'name': 'COMPANY_ADMINISTRATOR',
    'role': 'COMPANY_ADMINISTRATOR',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': null,
        'companyEntryDate': 1604185200,
        'profession': null
      },
      'firstName': 'Klara ',
      'id': '5fabeb1d8f76c63e0bf71615',
      'lastActive': null,
      'lastName': 'Köhle ',
      'mail': 'klara@bluquist.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 0,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'registered'
    }
  },
  {
    'id': '84bbfe13-bcd6-450e-8b5b-729537bf75a6',
    'name': 'COMPANY_ADMINISTRATOR',
    'role': 'COMPANY_ADMINISTRATOR',
    'user': {
      'company': 'BlueExcellence',
      'companyData': {
        'careerLevel': 'Professional',
        'companyEntryDate': 1577815200,
        'profession': 'Front-End Developer'
      },
      'firstName': 'Begaiym',
      'id': '5f842599d78ae2499358dbf4',
      'lastActive': 1608804288,
      'lastName': 'Omorova',
      'mail': 'begaiym.kadyrbekovna@gmail.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 2,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'registered'
    }
  }
];


// TODO: actual implementation for "avatarImage"
export const mapEmployeesData = (employeesData, filterString = '', bp = {}) => {

  return employeesData.map(({ id, user }) => {
    const { id: userId, companyData } = user;
    const { careerLevel, companyEntryDate, profession } = companyData;

    const avatarImage = api.getUserImageUrl(userId);
    const avatarFallback = userAvatarFallbackMapper(user);
    const itemIdentifierLabel = highlight(userNameMapper(user), filterString);
    const itemIdentifierSublabel = highlight(emptyDash(profession), filterString);
    const cell1Label = translate('table_careerlevel_column_label') || 'Karrierestufe';
    const cell1Value = highlight(emptyDash(careerLevel), filterString);
    const cell2Label = translate('table_since_column_label') || 'Im Untern. seit';
    const cell2Value = highlight(easyDateMY(companyEntryDate * 1000), filterString);
    const {
      cellLabel: cell3Label,
      cellValue: cell3Value,
      lastCellXs
    } = userStatusDisplay(user, filterString, bp);

    return {
      id,
      avatarImage,
      avatarFallback,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell1Label,
      cell1Value,
      cell2Label,
      cell2Value,
      cell3Label,
      cell3Value,
      lastCellXs,
    };
  });
};






// ROLES
export const rolesData = [
  {
    'careerLevel':'Senior',
    'category':null,
    'description':'Responsive for art and visual leadership in our organisation as well as in projects. Leadership role with high impact on customer satisfaction and brand awareness. Most likely this role also features team management responsibilities and is in charge of education and visual excellence within our less experiences roles and positions. ',
    'id':'d17a57e3-9c5d-466f-ba8a-9dc5c75de453',
    'name':'Art Director',
    'referenceProfile':'4b272c37-4602-46c1-a39c-9cf2d75fffc7',
    'users':125
  },
  {
    'careerLevel':'Executive',
    'category':null,
    'description':'Assure growth and profitability.\nResolve issues on financial, people and legal side.\nImprove processes.\nNegotiate deals.',
    'id':'5a3d45cf-0d75-4a63-b770-54dd8aec2b23',
    'name':'Chief Growth Officer',
    'referenceProfile':'6793f922-86b8-4571-adc3-f7f4493612fc',
    'users':15
  },
  {
    'careerLevel':'Executive',
    'category':null,
    'description':'Der Chief Learning Officer ist verantwortlich f\u00fcr die Identifikation, Entwicklung und Umsetzung wissenschaftlicher Methoden, Unterrichtsstrategien und Lerntechnologien bei der Konzeption, Implementierung und Evaluierung von Aktivit\u00e4ten zur beruflichen & pers\u00f6nlichen Entwicklung.',
    'id':'d4ea689d-31d5-4acc-8266-9dabfda61539',
    'name':'Chief Learning Officer',
    'referenceProfile':'d49e14ad-dd1d-477e-beb4-39126bfd46ff',
    'users':12
  },
  {
    'careerLevel':'Professional',
    'category':null,
    'description':'Ein Customer Success Manager ist f\u00fcr die Entwicklung von nachhaltigen Kundenbeziehungen verantwortlich, die die Kundenbindung und -loyalit\u00e4t f\u00f6rdern. Ihre Aufgabe besteht darin, eng mit den Kunden zusammenzuarbeiten, um sicherzustellen, dass sie mit unseren Produkten & Services, zufrieden sind, und um Bereiche, in denen sie unzufrieden sind, zu verbessern.',
    'id':'b394c1f7-5e3f-4850-833b-2286ae791653',
    'name':'Customer Success Manager',
    'referenceProfile':'84ac203d-e2e9-4ebb-9d6d-38304183f5e2',
    'users':0
  },
  {
    'careerLevel':'Executive',
    'category':null,
    'description':'The head of product is enabling the success of all products by aligning the product strategy to business and market needs. The HOP ensures that products are built to serve customer needs and solve their problems.',
    'id':'03e73006-6caa-435d-9248-ed1d64456b70',
    'name':'Head of Product',
    'referenceProfile':'27b0d84a-5b92-4f27-b93c-c812c70f80f7',
    'users':1
  },
  {
    'careerLevel':'Senior',
    'category':null,
    'description':'Online-Marketing-Spezialisten sind verantwortlich f\u00fcr die Entwicklung, Umsetzung und das Managen von Marketingma\u00dfnahmen, die f\u00fcr unser Unternehmen und unsere Produkte & Services werben. Kernziele sind die Steigerung des Markenbewusstseins im digitalen Kontext, sowie die Steigerung  unseres Web-Traffics und vor allem die Gewinnung von Leads und Kunden.',
    'id':'dae9f469-c127-4782-b5f5-5a65c42efb4c',
    'name':'Online Marketing Specialist',
    'referenceProfile':'2ec0a753-6f7b-4166-8b2b-65c5e5b17658',
    'users':5
  },
  {
    'careerLevel':'Senior',
    'category':null,
    'description':'- Psychology lead\n- Moderation of psychology team, partners, forum\n- Concept creations\n- Testing from psychological perspective\n- Potential engineering (customized client solutions)\n- Sales opportunities development and review\n- Content creation for social media\n- Academic research projects',
    'id':'8b6abdaf-ae1b-40f2-8c84-54ff223970f1',
    'name':'Potential Engineer',
    'referenceProfile':'d4f54e48-1acf-4bce-a773-8726c1a16dae',
    'users':7
  },
  {
    'careerLevel':'Senior',
    'category':null,
    'description':'Product Designer begleiten unsere Produktentwicklung \u00fcber den gesamten Prozess. Sie sind daf\u00fcr verantwortlich, Kundenprobleme zu identifizieren und elegante L\u00f6sungen zu entwerfen. \nDie F\u00e4higkeiten, \u00fcber die ein Produkt Designer verf\u00fcgen muss, reichen von Human-centered- Design \u00fcber Business- bzw. Marktverst\u00e4ndnis bis hinzu Technologieexpertise. Zu den Kernt\u00e4tigkeiten z\u00e4hlen User Research, Prototyping, Visualisierung, Validierung und Produktion von L\u00f6sungen in Zusammenarbeit mit den anderen Mitgliedern des Produktteams.\n\n\u00dcbersetzt mit www.DeepL.com/Translator (kostenlose Version)',
    'id':'689d94b9-261a-4adc-96ba-cda1c04c74c7',
    'name':'Product Designer',
    'referenceProfile':'58e33985-2770-48a6-8dd8-cabb72013da5',
    'users':0
  },
  {
    'careerLevel':'Senior',
    'category':null,
    'description':'We have with Johannes an outstanding best door openers in the team. \nThis role takes care about closing deals.',
    'id':'61d09802-cb22-4ee4-b79d-1f48a4bac50a',
    'name':'Sales - Deal Closer',
    'referenceProfile':'12ac6693-5614-456d-9cf9-a95bc1818f52',
    'users':1
  },
  {
    'careerLevel':'Senior',
    'category':null,
    'description':'K\u00fcmmert sich um gesamtheitliche  Verantwortung f\u00fcr Deal Pipeline. Hat wissen in den wichtigstens Sales Tools darunter Pipedrive, Office365 und Co. Hat ein starkes Netzwerk und kennt sich in klassischen und neuartigen Organisationsformen und deren Probleme aus. Hohe Affinit\u00e4t zu Kommunikation, Technologie, Zahlen und Wirtschaftsprozessen. ',
    'id':'44129ccf-28d0-4ea9-b388-ff8aa10d79e2',
    'name':'Sales Lead',
    'referenceProfile':'e720c097-c8a5-45ba-8ef7-f35d1f473fa3',
    'users':23
  }
];

export const mapRolesData = (rolesData, filterString = '', bp = {}) => {
  return rolesData.map(item => {
    const { id, name, careerLevel, users, referenceProfile} = item;

    const itemIdentifierLabel = highlight(name, filterString);
    const cell1Label = translate('table_careerlevel_column_label') || 'Career Level';
    const cell1Value = highlight(careerLevel, filterString);
    const cell2Label = translate('table_employeecount_column_label') || 'User Count';
    const cell2Value = highlight(users, filterString);

    const {
      cellLabel: cell3Label,
      cellValue: cell3Value,
      lastCellXs
    } = roleStatusDisplay(referenceProfile, filterString, bp);

    return {
      id,
      itemIdentifierLabel,
      cell1Label,
      cell1Value,
      cell2Label,
      cell2Value,
      cell3Label,
      cell3Value,
      lastCellXs
    };
  });
};







// STAFFING

export const staffingData = [
  {
    'assessments':[

    ],
    'layerScores':{

    },
    'score':0,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':null,
        'companyEntryDate':null,
        'profession':null
      },
      'firstName':null,
      'id':'5fa16e702e382db99adb9ce1',
      'lastActive':null,
      'lastName':null,
      'mail':'carolin@bluquist.com',
      'organizationalRoles':[

      ],
      'registrationStatus':'invited'
    }
  },
  {
    'assessments':[

    ],
    'layerScores':{

    },
    'score':0,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':null,
        'companyEntryDate':null,
        'profession':null
      },
      'firstName':null,
      'id':'5fa16e702e382db99adb9ce8',
      'lastActive':null,
      'lastName':null,
      'mail':'katrin@bluquist.com',
      'organizationalRoles':[

      ],
      'registrationStatus':'invited'
    }
  },
  {
    'assessments':[

    ],
    'layerScores':{

    },
    'score':0,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Professional',
        'companyEntryDate':1598911200,
        'profession':'Administrator'
      },
      'firstName':'bluquist',
      'id':'5f6b8691817786e384aa1e3c',
      'lastActive':1602573400,
      'lastName':'Admin',
      'mail':'operations@blueexcellence.com',
      'organizationalRoles':[

      ],
      'registrationStatus':'registered'
    }
  },
  {
    'assessments':[
      {
        'assessmentId':'big5',
        'score':89.37
      },
      {
        'assessmentId':'potential',
        'score':91.88
      },
      {
        'assessmentId':'key-comp',
        'score':79.63
      },
      {
        'assessmentId':'work-preference',
        'score':92.31
      },
      {
        'assessmentId':'lead-comp',
        'score':81.32
      },
      {
        'assessmentId':'9levels',
        'score':100.00
      },
      {
        'assessmentId':'rmp',
        'score':100.00
      }
    ],
    'layerScores':{
      '1':96.46,
      '3':92.09,
      '4':80.48
    },
    'score':87.92,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Executive',
        'companyEntryDate':1506808800,
        'profession':'Head of Potential Engineering'
      },
      'firstName':'Simon',
      'id':'5f6ae7f68289aa709efc5a6e',
      'lastActive':1609752302,
      'lastName':'Brumm',
      'mail':'simon@bluquist.com',
      'organizationalRoles':[
        '8b6abdaf-ae1b-40f2-8c84-54ff223970f1'
      ],
      'registrationStatus':'registered'
    }
  },
  {
    'assessments':[
      {
        'assessmentId':'big5',
        'score':93.91
      },
      {
        'assessmentId':'work-preference',
        'score':80.77
      },
      {
        'assessmentId':'rmp',
        'score':100.00
      }
    ],
    'layerScores':{
      '1':96.95,
      '3':80.77
    },
    'score':92.91,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Professional',
        'companyEntryDate':null,
        'profession':'Sales Management'
      },
      'firstName':'Dimi',
      'id':'5fad49f90a4664c56d64dee0',
      'lastActive':1610556225,
      'lastName':'Chatzipanagiotou',
      'mail':'dimi@bluquist.com',
      'organizationalRoles':[
        '61d09802-cb22-4ee4-b79d-1f48a4bac50a'
      ],
      'registrationStatus':'registered'
    }
  },
  {
    'assessments':[
      {
        'assessmentId':'work-preference',
        'score':66.67
      },
      {
        'assessmentId':'potential',
        'score':100.00
      },
      {
        'assessmentId':'lead-comp',
        'score':97.70
      },
      {
        'assessmentId':'key-comp',
        'score':91.98
      },
      {
        'assessmentId':'big5',
        'score':92.87
      }
    ],
    'layerScores':{
      '1':92.87, // PER
      '2':85.33, // P+W
      '3':83.33, // POT
      '4':94.84, // COM
      // "5" // Wellbeing
    },
    'score':92.66,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Executive',
        'companyEntryDate':1538344800,
        'profession':'Managing Director'
      },
      'firstName':'Johannes',
      'id':'5f6b88cab4d849c9dbaa85dc',
      'lastActive':1610032519,
      'lastName':'Ehrhardt',
      'mail':'johannes@bluquist.com',
      'organizationalRoles':[
        '44129ccf-28d0-4ea9-b388-ff8aa10d79e2'
      ],
      'registrationStatus':'registered'
    }
  },
  {
    'assessments':[
      {
        'assessmentId':'big5',
        'score':87.25
      },
      {
        'assessmentId':'potential',
        'score':100.00
      },
      {
        'assessmentId':'key-comp',
        'score':94.29
      },
      {
        'assessmentId':'lead-comp',
        'score':94.25
      },
      {
        'assessmentId':'work-preference',
        'score':46.15
      }
    ],
    'layerScores':{
      '1':87.25,
      '3':73.08,
      '4':94.27
    },
    'score':88.99,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Executive',
        'companyEntryDate':1546297200,
        'profession':'CLO'
      },
      'firstName':'Brunello',
      'id':'5fb22bd00a4664c56d64e482',
      'lastActive':1610543273,
      'lastName':'Gianella',
      'mail':'brunello@bluquist.com',
      'organizationalRoles':[

      ],
      'registrationStatus':'registered'
    }
  },
  {
    'assessments':[
      {
        'assessmentId':'potential',
        'score':97.50
      },
      {
        'assessmentId':'work-preference',
        'score':94.87
      },
      {
        'assessmentId':'lead-comp',
        'score':90.52
      },
      {
        'assessmentId':'key-comp',
        'score':88.89
      },
      {
        'assessmentId':'big5',
        'score':95.14
      },
      {
        'assessmentId':'9levels',
        'score':100.00
      },
      {
        'assessmentId':'rmp',
        'score':100.00
      }
    ],
    'layerScores':{
      '1':98.38,
      '3':96.19,
      '4':89.70,
    },
    'score':93.77,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Senior',
        'companyEntryDate':1551394800,
        'profession':'Software Engineer'
      },
      'firstName':'Jonathan',
      'id':'5fa040342e382db99adb94be',
      'lastActive':1610649554,
      'lastName':'Grupp',
      'mail':'jonathan.grupp@gmail.com',
      'organizationalRoles':[

      ],
      'registrationStatus':'registered'
    }
  },
  {
    'assessments':[

    ],
    'layerScores':{

    },
    'score':0,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':null,
        'companyEntryDate':1604185200,
        'profession':null
      },
      'firstName':'Klara ',
      'id':'5fabeb1d8f76c63e0bf71615',
      'lastActive':null,
      'lastName':'K\u00f6hle ',
      'mail':'klara@bluquist.com',
      'organizationalRoles':[

      ],
      'registrationStatus':'registered'
    }
  },
  {
    'assessments':[
      {
        'assessmentId':'potential',
        'score':93.12
      },
      {
        'assessmentId':'9levels',
        'score':100.00
      }
    ],
    'layerScores':{
      '1':100.00,
      '3':93.12
    },
    'score':98.28,
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Professional',
        'companyEntryDate':1577815200,
        'profession':'Front-End Developer'
      },
      'firstName':'Begaiym',
      'id':'5f842599d78ae2499358dbf4',
      'lastActive':1608804288,
      'lastName':'Omorova',
      'mail':'begaiym.kadyrbekovna@gmail.com',
      'organizationalRoles':[

      ],
      'registrationStatus':'registered'
    }
  }
];

export const mapStaffingData = (staffingData, filterString = '', bp = {}) => {
  return staffingData.map(({ layerScores, score, user }) => {
    const { id, companyData } = user;

    let profession;
    if (companyData) {
      profession = companyData.profession;
    }

    let competencyScore = percentWithDecimal(layerScores[LAYER_ID_COMPETENCIES]);
    let personalityScore = percentWithDecimal(layerScores[LAYER_ID_PERSONALITY]);
    let preferenceScore = percentWithDecimal(layerScores[LAYER_ID_PREFERENCES]);
    let potentialScore = percentWithDecimal(layerScores[LAYER_ID_POTENTIAL]);
    let totalScore = percentWithDecimal(score);

    const match = translate('table_match_label');

    let avatarImage;
    if (!user.isAnonymous) {
      avatarImage = api.getUserImageUrl(id);
    }

    const avatarFallback = userAvatarFallbackMapper(user);
    const itemIdentifierLabel = highlight(userNameMapper(user), filterString);;
    const itemIdentifierSublabel = highlight(emptyDash(profession), filterString);

    const cell1Label = translate('table_competencies_column_label');
    const cell1Value = highlight(competencyScore, filterString);
    const cell1Tooltip = translate('table_competencies_highlight_label');
    const cell1TooltipHighlight = `${competencyScore} ${match}`;

    const cell2Label = translate('table_personality_column_label');
    const cell2Value = highlight(personalityScore, filterString);
    const cell2Tooltip = translate('table_personality_highlight_label');
    const cell2TooltipHighlight = `${personalityScore} ${match}`;

    const cell3Label = translate('table_preferences_column_label');
    const cell3Value = highlight(preferenceScore, filterString);
    const cell3Tooltip = translate('table_preferences_highlight_label');
    const cell3TooltipHighlight = `${preferenceScore} ${match}`;

    const cell4Label = translate('table_potential_column_label');
    const cell4Value = highlight(potentialScore, filterString);
    const cell4Tooltip = translate('table_potential_highlight_label');
    const cell4TooltipHighlight = `${potentialScore} ${match}`;

    const cell5Label = translate('table_totalscore_column_label');
    const cell5Value = (
      <>
        { !bp.isXs
          ? <ProgressCircle progress={score} size='S' yellowDiff={score > 0} />
          : ''
        }
        <span className='blu-colorPrimary1'>
          {highlight(totalScore, filterString)}
        </span>
      </>
    );
    const cell5Tooltip = cell5Label;
    const cell5TooltipHighlight = `${totalScore} ${match}`;

    const cellLastXsLabel = '';
    const cellLastXsValue = <ProgressCircle progress={score} size='S' yellowDiff={score > 0} />;

    return {
      id,
      avatarImage,
      avatarFallback,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell1Label,
      cell1Value,
      cell1Tooltip,
      cell1TooltipHighlight,
      cell2Label,
      cell2Value,
      cell2Tooltip,
      cell2TooltipHighlight,
      cell3Label,
      cell3Value,
      cell3Tooltip,
      cell3TooltipHighlight,
      cell4Label,
      cell4Value,
      cell4Tooltip,
      cell4TooltipHighlight,
      cell5Label,
      cell5Value,
      cell5Tooltip,
      cell5TooltipHighlight,
      cellLastXsLabel,
      cellLastXsValue
    };
  });
};


export const teamsData = [
  {
    'group':'52470eb8-0faf-473c-a111-f33debc85238',
    'id':'4d650b91-0c9e-4671-8f19-eb9141978371',
    'metaData':{

    },
    'name':'BQ Product',
    'type':'Internal Function',
    'users':[
      {
        'id':'98371bad-6452-408a-8f64-8b1ef63d9238',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85d5'
      },
      {
        'id':'2b67a3a4-d0e2-4878-a3a5-32c546fe58eb',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85ce'
      },
      {
        'id':'433c49e7-f407-4c1b-ba06-d61aa467ee49',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      },
      {
        'id':'3c30059b-535f-4f31-9b6b-5412faab6907',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b6462817786e384aa1e30'
      },
      {
        'id':'4c2e527e-bd85-4b2c-b9d5-b9f63ebd15e7',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6ae7f68289aa709efc5a6e'
      },
      {
        'id':'319364e9-6325-4fe1-998b-e7c38f80c748',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85e3'
      },
      {
        'id':'8daf2ca7-025b-4e9f-b43e-5b1a08200631',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5fa040342e382db99adb94be'
      },
      {
        'id':'adea22c9-728f-46b7-88e6-20fd82023b96',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f842599d78ae2499358dbf4'
      },
      {
        'id':'0f9f5966-8633-47be-946f-ab92f98391cc',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5fa040342e382db99adb94b7'
      }
    ]
  },
  {
    'group':'52470eb8-0faf-473c-a111-f33debc85238',
    'id':'a6c31f9c-7900-47e3-b642-4e149568986a',
    'metaData':{

    },
    'name':'Co-Founders',
    'type':'Project Team',
    'users':[
      {
        'id':'276d6a14-3557-4ce3-98f2-2f5dafe646c0',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85e3'
      },
      {
        'id':'42cd4da6-99c0-4ece-86a0-f093ae7adbde',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6ae7f68289aa709efc5a6e'
      },
      {
        'id':'9e048dd4-045a-4bdc-b0f2-65368a3bf152',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      },
      {
        'id':'1c1aefef-d319-4fc6-af69-ebc8c5e58552',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85ce'
      }
    ]
  },
  {
    'group':'52470eb8-0faf-473c-a111-f33debc85238',
    'id':'7a77db39-a850-4046-9ec0-9d08ea5ebce6',
    'metaData':{

    },
    'name':'Marketing & Sales',
    'type':'Internal Function',
    'users':[
      {
        'id':'a3cd66a9-0c2d-4388-b240-91c55eefbec8',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85ce'
      },
      {
        'id':'79690682-b48b-4ade-ad05-b8044bba5b76',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      }
    ]
  },
  {
    'group':'52470eb8-0faf-473c-a111-f33debc85238',
    'id':'4d650b91-0c9e-4671-8f19-eb9141978371',
    'metaData':{

    },
    'name':'BQ Product',
    'type':'Internal Function',
    'users':[
      {
        'id':'98371bad-6452-408a-8f64-8b1ef63d9238',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85d5'
      },
      {
        'id':'2b67a3a4-d0e2-4878-a3a5-32c546fe58eb',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85ce'
      },
      {
        'id':'433c49e7-f407-4c1b-ba06-d61aa467ee49',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      },
      {
        'id':'3c30059b-535f-4f31-9b6b-5412faab6907',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b6462817786e384aa1e30'
      },
      {
        'id':'4c2e527e-bd85-4b2c-b9d5-b9f63ebd15e7',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6ae7f68289aa709efc5a6e'
      },
      {
        'id':'319364e9-6325-4fe1-998b-e7c38f80c748',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85e3'
      },
      {
        'id':'8daf2ca7-025b-4e9f-b43e-5b1a08200631',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5fa040342e382db99adb94be'
      },
      {
        'id':'adea22c9-728f-46b7-88e6-20fd82023b96',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f842599d78ae2499358dbf4'
      },
      {
        'id':'0f9f5966-8633-47be-946f-ab92f98391cc',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5fa040342e382db99adb94b7'
      }
    ]
  },
  {
    'group':'52470eb8-0faf-473c-a111-f33debc85238',
    'id':'a6c31f9c-7900-47e3-b642-4e149568986a',
    'metaData':{

    },
    'name':'Co-Founders',
    'type':'Project Team',
    'users':[
      {
        'id':'276d6a14-3557-4ce3-98f2-2f5dafe646c0',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85e3'
      },
      {
        'id':'42cd4da6-99c0-4ece-86a0-f093ae7adbde',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6ae7f68289aa709efc5a6e'
      },
      {
        'id':'9e048dd4-045a-4bdc-b0f2-65368a3bf152',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      },
      {
        'id':'1c1aefef-d319-4fc6-af69-ebc8c5e58552',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85ce'
      }
    ]
  },
  {
    'group':'52470eb8-0faf-473c-a111-f33debc85238',
    'id':'7a77db39-a850-4046-9ec0-9d08ea5ebce6',
    'metaData':{

    },
    'name':'Human Resources',
    'type':'Internal Function',
    'users':[
      {
        'id':'a3cd66a9-0c2d-4388-b240-91c55eefbec8',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85ce'
      },
      {
        'id':'79690682-b48b-4ade-ad05-b8044bba5b76',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      },
      {
        'id':'79690682-b48b-4ade-ad05-b8044bba5b76',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      }
    ]
  },
  {
    'group':'52470eb8-0faf-473c-a111-f33debc85238',
    'id':'7a77db39-a850-4046-9ec0-9d08ea5ebce6',
    'metaData':{

    },
    'name':'Engineering',
    'type':'Internal Function',
    'users':[
      {
        'id':'a3cd66a9-0c2d-4388-b240-91c55eefbec8',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85ce'
      },
      {
        'id':'79690682-b48b-4ade-ad05-b8044bba5b76',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      },
      {
        'id':'79690682-b48b-4ade-ad05-b8044bba5b76',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      },
      {
        'id':'79690682-b48b-4ade-ad05-b8044bba5b76',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6b88cab4d849c9dbaa85dc'
      }
    ]
  },
  {
    'group':'52470eb8-0faf-473c-a111-f33debc85238',
    'id':'2de1f52f-55e8-44bb-9b1d-3f3e2054fe47',
    'metaData':{

    },
    'name':'Psy Team',
    'type':'Internal Function',
    'users':[
      {
        'id':'2c44977a-b15f-4a06-93c5-687174c3a9a0',
        'name':'TEAM_MEMBER',
        'role':'TEAM_MEMBER',
        'user':'5f6ae7f68289aa709efc5a6e'
      }
    ],
    'roles': [
      {}, {}, {}
    ],
    'status': {
      'percentage': 80,
      'label': '6 Rollen besetzt'
    }
  }
];

export const mapTeamsData = (teamsData, filterString = '', bp = {}) => {
  return teamsData.map(item => {
    const { id, name, type = '', positions = [], users} = item;

    const itemIdentifierLabel = highlight(name, filterString);
    const itemIdentifierSublabel = highlight(type, filterString);

    const cell1Label = translate('table_roles_count_column_label') || 'Role count';
    const cell1Value = highlight(positions.length, filterString);
    const cell2Label = translate('table_employees_count_column_label') || 'Team members';
    const cell2Value = highlight(users.length, filterString);
    const {
      cellLabel: cell3Label,
      cellValue: cell3Value,
      lastCellXs
    } = teamStatusDisplay(item, filterString, bp);

    return {
      id,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell1Label,
      cell1Value,
      cell2Label,
      cell2Value,
      cell3Label,
      cell3Value,
      lastCellXs
    };
  });
};

export const candidatesData = [
  {
    'id': '485ba4c5-f918-46f3-832c-333e62d4d831',
    'name': 'RECRUITING_CANDIDATE',
    'role': 'RECRUITING_CANDIDATE',
    'user': {
      'candidateData': {
        'age': 28,
        'careerLevel': 'Professional',
        'city': 'Bishkek',
        'country': 'Kyrgyzstan',
        'currentJobFamily': 'IT',
        'currentPosition': 'Front-End Developer',
        'desiredSalary': 100000,
        'desiredWeeklyHours': 40,
        'gender': 'w',
        'industryExperience': [
          {
            'experience': '1.5 years',
            'industry': 'Bluquist'
          },
          {
            'experience': '3.5 years',
            'industry': 'Zensoft'
          }
        ],
        'motivation': [
          'Motivation 1',
          'Motivation 2',
          'Motivation 3'
        ],
        'remotePreferred': true
      },
      'company': 'Dummy Inc.',
      'companyData': {
        'careerLevel': null,
        'companyEntryDate': null,
        'profession': ''
      },
      'disabled': false,
      'firstName': 'Begaiym',
      'id': '60fa54a84025c8d6ef1220f3',
      'inactive': false,
      'lastActive': 1628584845,
      'lastName': 'Omorova',
      'mail': 'beg.omorova+71@gmail.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 5,
        'completed': 1,
        'started': 0
      },
      'registeredDate': 1628241862,
      'registrationStatus': 'registered'
    }
  },
  {
    'id': '43d6f366-41d2-47c0-80ff-44113a8480fc',
    'name': 'RECRUITING_CANDIDATE',
    'role': 'RECRUITING_CANDIDATE',
    'user': {
      'candidateData': null,
      'company': 'Dummy Inc.',
      'companyData': {
        'careerLevel': null,
        'companyEntryDate': null,
        'profession': null
      },
      'disabled': false,
      'firstName': null,
      'id': '6119fbb44d6e8202902981f4',
      'inactive': false,
      'lastActive': null,
      'lastName': null,
      'mail': 'beg.omorova+74@gmail.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 0,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'invited'
    }
  },
  {
    'id': 'bdd08ed8-68de-4e43-a9aa-ef9202945660',
    'name': 'RECRUITING_CANDIDATE',
    'role': 'RECRUITING_CANDIDATE',
    'user': {
      'candidateData': null,
      'company': 'Dummy Inc.',
      'companyData': {
        'careerLevel': null,
        'companyEntryDate': null,
        'profession': null
      },
      'disabled': false,
      'firstName': null,
      'id': '6119fbe54d6e82029029820c',
      'inactive': false,
      'lastActive': null,
      'lastName': null,
      'mail': 'beg.omorova+75@gmail.com',
      'organizationalRoles': [],
      'profileCompletion': {
        'available': 7,
        'completed': 0,
        'started': 0
      },
      'registeredDate': null,
      'registrationStatus': 'invited'
    }
  }
];

// CANDIDATES
export const mapCandidatesData = (candidatesData, filterString = '', bp = {}) => {
  return candidatesData.map((candidate) => {
    const {user, jobs} = candidate;

    const { id, candidateData } = user;
    const { careerLevel, currentPosition } = candidateData || {}; /*can be null*/

    let careerLevelString = '-';
    if (careerLevel) {
      const careerLevelObj = CAREER_LEVELS_BY_ID[careerLevel];
      careerLevelString = careerLevelObj &&
        (translate(`${careerLevelObj.translationKey}`) || careerLevelObj.translationFallback);
    }

    let vacancy;
    if (jobs && jobs.length === 1) {
      vacancy = jobs[0].name;
    } else if (jobs && jobs.length > 1) {
      vacancy = `${jobs[0].name} + ${jobs.length - 1} ${translate('profile_more') || 'more'}`;
    }

    let avatarImage;
    if (!user.isAnonymous) {
      avatarImage = api.getUserImageUrl(id);
    }

    const avatarFallback = userAvatarFallbackMapper(user);
    const itemIdentifierLabel = highlight(userNameMapper(user), filterString);
    const itemIdentifierSublabel = vacancy;
    const cell1Label = translate('candidates_list_current_carrer_level') || 'Career Level';
    const cell1Value = highlight(emptyDash(careerLevelString), filterString);
    const cell2Label = translate('candidates_list_current_position') || 'Current Position';
    const cell2Value = highlight(emptyDash(currentPosition), filterString);
    const {
      cellLabel: cell3Label,
      cellValue: cell3Value,
      lastCellXs
    } = userStatusDisplay(user, filterString, bp);

    return {
      id,
      avatarImage,
      avatarFallback,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell1Label,
      cell1Value,
      cell2Label,
      cell2Value,
      cell3Label,
      cell3Value,
      lastCellXs,
    };
  });
};

// VACANCIES
export const mapVacanciesData = (vacanciesData, filterString = '', bp = {}) => {

  return vacanciesData.map(vacancy => {
    const {id, name, candidates, metaData, role} = vacancy;
    const {locationCity, locationCountry, startDate} = metaData;

    const itemIdentifierLabel = highlight(name, filterString);

    let cityAndCountry;
    if (locationCity && locationCountry) {
      cityAndCountry = `${locationCity} / ${translate(`config_country_${locationCountry}`)}`;
    } else {
      cityAndCountry = locationCity || translate(`config_country_${locationCountry}`);
    }

    let careerLevel = '-';
    if (role && role.careerLevel) {
      const careerLevelObj = CAREER_LEVELS_BY_ID[role.careerLevel];
      careerLevel = careerLevelObj &&
        (translate(`${careerLevelObj.translationKey}`) || careerLevelObj.translationFallback);
    }

    const itemIdentifierSublabel = highlight(emptyDash(careerLevel), filterString);
    const cell1Label = translate('vacancies_candidates_column') || translate('Kandidat.');
    const cell1Value = highlight(emptyDash(candidates.length), filterString);
    const cell2Label = translate('vacancies_city_country_column') || 'Stadt/Staat';
    const cell2Value = highlight(emptyDash(cityAndCountry), filterString);
    const cell3Label = translate('vacancies_start_column') || 'Start';
    const cell3Value = startDate ? timestampToFullDate(startDate) : '-';

    const {
      cellLabel: cell4Label,
      cellValue: cell4Value,
      lastCellXs
    } = vacancyStatusDisplay(vacancy, filterString, bp);

    return {
      id,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell1Label,
      cell1Value,
      cell2Label,
      cell2Value,
      cell3Label,
      cell3Value,
      cell4Label,
      cell4Value,
      lastCellXs
    };
  });
};


// USERS

export const usersData = [
  {
    'id':'2342e8cd-362d-471c-a999-eead094ef9dd',
    'name':'COMPANY_USER',
    'role':'COMPANY_USER',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':null,
        'companyEntryDate':null,
        'profession':null
      },
      'firstName':null,
      'id':'5fa16e702e382db99adb9ce1',
      'lastActive':null,
      'lastName':null,
      'mail':'carolin@bluquist.com',
      'organizationalRoles':[

      ],
      'profileCompletion':{
        'available':7,
        'completed':0,
        'started':0
      },
      'registeredDate':null,
      'registrationStatus':'invited'
    }
  },
  {
    'id':'c4a20990-d3cf-40bf-9309-5e52e670503b',
    'name':'COMPANY_USER',
    'role':'COMPANY_USER',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':null,
        'companyEntryDate':null,
        'profession':null
      },
      'firstName':null,
      'id':'5fa16e702e382db99adb9ce8',
      'lastActive':null,
      'lastName':null,
      'mail':'katrin@bluquist.com',
      'organizationalRoles':[

      ],
      'profileCompletion':{
        'available':7,
        'completed':0,
        'started':0
      },
      'registeredDate':null,
      'registrationStatus':'invited'
    }
  },
  {
    'id':'0151a3d6-c03e-4e4b-8b06-92e4156372bf',
    'name':'Company Administrator',
    'role':'COMPANY_ADMINISTRATOR',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Professional',
        'companyEntryDate':1598911200,
        'profession':'Administrator'
      },
      'firstName':'bluquist',
      'id':'5f6b8691817786e384aa1e3c',
      'lastActive':1602573400,
      'lastName':'Admin',
      'mail':'operations@blueexcellence.com',
      'organizationalRoles':[

      ],
      'profileCompletion':{
        'available':7,
        'completed':0,
        'started':0
      },
      'registeredDate':null,
      'registrationStatus':'registered'
    }
  },
  {
    'id':'0feb9db5-6468-4cae-a6b2-0c2bc186c290',
    'name':'COMPANY_ADMINISTRATOR',
    'role':'COMPANY_ADMINISTRATOR',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Executive',
        'companyEntryDate':1506808800,
        'profession':'Head of Potential Engineering'
      },
      'firstName':'Simon',
      'id':'5f6ae7f68289aa709efc5a6e',
      'lastActive':1610990571,
      'lastName':'Brumm',
      'mail':'simon@bluquist.com',
      'organizationalRoles':[
        '8b6abdaf-ae1b-40f2-8c84-54ff223970f1'
      ],
      'profileCompletion':{
        'available':7,
        'completed':7,
        'started':0
      },
      'registeredDate':null,
      'registrationStatus':'registered'
    }
  },
  {
    'id':'10435385-cc66-4081-871b-461001367903',
    'name':'COMPANY_USER',
    'role':'COMPANY_USER',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Professional',
        'companyEntryDate':null,
        'profession':'Sales Management'
      },
      'firstName':'Dimi',
      'id':'5fad49f90a4664c56d64dee0',
      'lastActive':1610556225,
      'lastName':'Chatzipanagiotou',
      'mail':'dimi@bluquist.com',
      'organizationalRoles':[
        '61d09802-cb22-4ee4-b79d-1f48a4bac50a'
      ],
      'profileCompletion':{
        'available':7,
        'completed':3,
        'started':0
      },
      'registeredDate':null,
      'registrationStatus':'registered'
    }
  },
  {
    'id':'bde925a5-3fef-4868-b863-986e4fac0078',
    'name':'COMPANY_ADMINISTRATOR',
    'role':'COMPANY_ADMINISTRATOR',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Executive',
        'companyEntryDate':1538344800,
        'profession':'Managing Director'
      },
      'firstName':'Johannes',
      'id':'5f6b88cab4d849c9dbaa85dc',
      'lastActive':1611074752,
      'lastName':'Ehrhardt',
      'mail':'johannes@bluquist.com',
      'organizationalRoles':[
        '44129ccf-28d0-4ea9-b388-ff8aa10d79e2'
      ],
      'profileCompletion':{
        'available':7,
        'completed':6,
        'started':0
      },
      'registeredDate':1606928790,
      'registrationStatus':'registered'
    }
  },
  {
    'id':'8e2b5292-1726-4148-8429-15df8ce184b1',
    'name':'COMPANY_ADMINISTRATOR',
    'role':'COMPANY_ADMINISTRATOR',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Executive',
        'companyEntryDate':1546297200,
        'profession':'CLO'
      },
      'firstName':'Brunello',
      'id':'5fb22bd00a4664c56d64e482',
      'lastActive':1610543273,
      'lastName':'Gianella',
      'mail':'brunello@bluquist.com',
      'organizationalRoles':[

      ],
      'profileCompletion':{
        'available':7,
        'completed':5,
        'started':0
      },
      'registeredDate':1608123843,
      'registrationStatus':'registered'
    }
  },
  {
    'id':'00d9747b-fc9b-40ff-901d-e387cc450bbc',
    'name':'COMPANY_ADMINISTRATOR',
    'role':'COMPANY_ADMINISTRATOR',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Senior',
        'companyEntryDate':1551394800,
        'profession':'Software Engineer'
      },
      'firstName':'Jonathan',
      'id':'5fa040342e382db99adb94be',
      'lastActive':1611313863,
      'lastName':'Grupp',
      'mail':'jonathan.grupp@gmail.com',
      'organizationalRoles':[

      ],
      'profileCompletion':{
        'available':7,
        'completed':7,
        'started':0
      },
      'registeredDate':null,
      'registrationStatus':'registered'
    }
  },
  {
    'id':'49d403ac-4695-47c6-9781-cc879e53c8eb',
    'name':'COMPANY_ADMINISTRATOR',
    'role':'COMPANY_ADMINISTRATOR',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':null,
        'companyEntryDate':1604185200,
        'profession':null
      },
      'firstName':'Klara ',
      'id':'5fabeb1d8f76c63e0bf71615',
      'lastActive':null,
      'lastName':'K\u00f6hle ',
      'mail':'klara@bluquist.com',
      'organizationalRoles':[

      ],
      'profileCompletion':{
        'available':7,
        'completed':0,
        'started':0
      },
      'registeredDate':null,
      'registrationStatus':'registered'
    }
  },
  {
    'id':'84bbfe13-bcd6-450e-8b5b-729537bf75a6',
    'name':'COMPANY_ADMINISTRATOR',
    'role':'COMPANY_ADMINISTRATOR',
    'user':{
      'company':'BlueExcellence',
      'companyData':{
        'careerLevel':'Professional',
        'companyEntryDate':1577815200,
        'profession':'Front-End Developer'
      },
      'firstName':'Begaiym',
      'id':'5f842599d78ae2499358dbf4',
      'lastActive':1610971378,
      'lastName':'Omorova',
      'mail':'begaiym.kadyrbekovna@gmail.com',
      'organizationalRoles':[

      ],
      'profileCompletion':{
        'available':7,
        'completed':2,
        'started':0
      },
      'registeredDate':null,
      'registrationStatus':'registered'
    }
  }
];

export const mapUsersData = (usersData, filterString = '', bp = {}) => {
  return usersData.map((item) => {
    const { role, user } = item;

    const { id, companyData, lastActive } = user;
    const { profession } = companyData;


    const avatarImage = api.getUserImageUrl(id);
    const avatarFallback = userAvatarFallbackMapper(user);
    const itemIdentifierLabel = highlight(userNameMapper(user), filterString);
    const itemIdentifierSublabel = highlight(emptyDash(profession), filterString);
    const cell1Label = translate('table_lastactive_column_label') || 'Last active';
    const cell1Value = highlight(lastActiveMapper(lastActive), filterString);
    const cell2Label = translate('table_usergroup_column_label') || 'User Group';
    const cell2Value = highlight(roleMapper(role), filterString);
    const {
      cellLabel: cell3Label,
      cellValue: cell3Value,
      lastCellXs
    } = userStatusDisplay(user, filterString, bp);


    return {
      id,
      avatarImage,
      avatarFallback,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell1Label,
      cell1Value,
      cell2Label,
      cell2Value,
      cell3Label,
      cell3Value,
      lastCellXs,
    };
  });

};

export const usersAdminData = [
  {
    'firstName':'Thomas',
    'id':'5f2fab7b05a12e1ee553379c',
    'lastActive':1600011440,
    'lastName':'Zuchtriegel',
    'mail':'tzuchtriegel@gmail.com'
  },
  {
    'firstName':'Admin User',
    'id':'5e62805bdb9f55a3c2c1ae81',
    'lastActive':1600070800,
    'lastName':'RMP Test',
    'mail':'user8@dummy.bluquist.com'
  },
  {
    'firstName':'dummy f',
    'id':'5e62805bdb9f55a3c2c1ae82',
    'lastActive':1600071873,
    'lastName':'dummy l',
    'mail':'user9@dummy.bluquist.com'
  },
  {
    'firstName':'Magda',
    'id':'5e62805bdb9f55a3c2c1ae84',
    'lastActive':1600256166,
    'lastName':'Dziewit ',
    'mail':'user11@dummy.bluquist.com'
  },
  {
    'firstName':'Jens',
    'id':'5eb33123652e5c3593ed80ff',
    'lastActive':1600259733,
    'lastName':'Schl\u00fcter',
    'mail':'jensschlueter@gmx.de'
  },
  {
    'firstName':'User 28',
    'id':'5e62805bdb9f55a3c2c1ae95',
    'lastActive':1600265079,
    'lastName':'Surname',
    'mail':'user28@dummy.bluquist.com'
  },
  {
    'firstName':'Hey',
    'id':'5e62805bdb9f55a3c2c1aea8',
    'lastActive':1600437292,
    'lastName':'There',
    'mail':'user47@dummy.bluquist.com'
  },
  {
    'firstName':'Ann-Kristin',
    'id':'5e62805bdb9f55a3c2c1ae83',
    'lastActive':1600625656,
    'lastName':'Graumann',
    'mail':'user10@dummy.bluquist.com'
  },
  {
    'firstName':'Johannes ',
    'id':'5e62805bdb9f55a3c2c1ae80',
    'lastActive':1600671467,
    'lastName':'Ehrhardt ',
    'mail':'user7@dummy.bluquist.com'
  },
  {
    'firstName':'Nils',
    'id':'5e62805bdb9f55a3c2c1ae85',
    'lastActive':1600703008,
    'lastName':'K\u00f6rber',
    'mail':'user12@dummy.bluquist.com'
  }
];

export const mapUsersAdminData = (usersAdminData, filterString = '', bp = {}) => {

  return usersAdminData.map((item) => {
    const { id, lastActive, companies = [] } = item;

    const itemIdentifierLabel = highlight(userNameMapper(item), filterString);

    const companyName = companies[0] && companies[0].name;
    let subLabel = companyName;
    if (companies.length > 1) {
      subLabel = `${companyName} + ${companies.length - 1} ${translate('profile_more')}`;
    }
    const itemIdentifierSublabel = highlight(subLabel, filterString);

    const cell1Label = '';
    const cell1Value = '';
    const cell2Label = '';
    const cell2Value = '';
    const cell3Label = translate('table_lastactive_column_label') || 'Last active';
    const cell3Value = highlight(lastActiveMapper(lastActive), filterString);

    return {
      id,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell1Label,
      cell1Value,
      cell2Label,
      cell2Value,
      cell3Label,
      cell3Value
    };
  });
};


export const instancesData = [
  {id: 0, name: 'Customer instance 1', branch: 'Consumer Goods', engr: '32 %', nps: 5.1, prof: 274},
  {id: 1, name: 'Customer instance 2', branch: 'Retail', engr: '32 %', nps: 8.3, prof: 584},
  {id: 2, name: 'Customer instance 3', branch: 'Medical Appliances', engr: '32 %', nps: 3.9, prof: 981},
  {id: 3, name: 'Customer instance 4', branch: 'Electronics', engr: '32 %', nps: 4.1, prof: 143},
  {id: 4, name: 'Customer instance 5', branch: 'Fashion', engr: '32 %', nps: 2.7, prof: 543}
];

export const mapInstancesData = (instancesData, filterString = '', bp = {}) => {
  return instancesData.map((item) => {
    const { id, name, industryName, engagementRate, nps, membersTotal } = item;

    const itemIdentifierLabel = highlight(emptyDash(name), filterString);
    const itemIdentifierSublabel = highlight(emptyDash(industryName), filterString);
    const cell3Label = 'ENGR';
    const cell3Value = highlight(emptyDash(engagementRate), filterString);
    const cell4Label = 'NPS';
    const cell4Value = highlight(emptyDash(nps), filterString);
    const cell5Label = 'PROF';
    const cell5Value = highlight(emptyDash(membersTotal), filterString);

    return {
      id,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell3Label,
      cell3Value,
      cell4Label,
      cell4Value,
      cell5Label,
      cell5Value
    };
  });
};

export const contentPerformanceData = [
  {id: 0, contentId: 'Content A', identifier: 'A content', totalHelpful: 0, totalNotHelpful: 5, totalFeedBack: 5},
  {id: 1, contentId: 'Content B', identifier: 'Some B content', totalHelpful: 231, totalNotHelpful: 5, totalFeedBack: 848},
  {id: 2, contentId: 'Content C', identifier: 'C content doing well', totalHelpful: 15, totalNotHelpful: 0, totalFeedBack: 231},
  {id: 3, contentId: 'Content D', identifier: 'D content', totalHelpful: 289, totalNotHelpful: 132, totalFeedBack: 483},
  {id: 4, contentId: 'Content E', identifier: 'Some E content', totalHelpful: 87, totalNotHelpful: 87, totalFeedBack: 976}
];

export const mapContentPerformanceData = (contentPerformanceData, filterString = '', bp = {}) => {

  return contentPerformanceData.map((item) => {
    const { contentId, identifier, totalHelpful, totalNotHelpful, totalFeedback } = item;

    const itemIdentifierLabel = highlight(emptyDash(contentId), filterString);
    const itemIdentifierSublabel = highlight(emptyDash(identifier), filterString);
    const cell1Label = translate('table_helpful_column_label') || 'Helpful';
    const cell1Value = highlight(totalHelpful, filterString);
    const cell2Label = translate('table_not_helpful_column_label') || 'Not helpful';
    const cell2Value = highlight(totalNotHelpful, filterString);
    const cell3Label = translate('table_total_views_column_label') || 'Total Views';
    const cell3Value = highlight(totalFeedback, filterString);

    return {
      id: contentId,
      itemIdentifierLabel,
      itemIdentifierSublabel,
      cell1Label,
      cell1Value,
      cell2Label,
      cell2Value,
      cell3Label,
      cell3Value,
    };
  });
};
