import * as actions from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/signUp';

import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './store/reducers';
import rootSaga from './store/sagas';

import * as localStorage from 'utils/localStorage';
import {isValid} from 'utils/numbers';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';
import {LANGUAGES} from 'utils/configuration/const/languages';

// CONFIG
const Config = {
  enableLogger: false
};

const sagaMiddleware = createSagaMiddleware();

const logger = store => next => action => {
  if (process.env.NODE_ENV !== 'production' && Config.enableLogger) {
    console.group('Logger');
    console.warn(action.type);
    console.groupEnd();
    return next(action);
  }
  return next(action);
};
const updateAssessments = store => next => action => {
  let state = store.getState();
  const assessments = { ...state.assessments };
  let assessment, data, id;

  switch (action.type) {
    case actions.GET_ASSESSMENT_RESULT_REJECTED:
      const error = action.payload.error;
      const assessmentId = action.payload.id;

      assessments[assessmentId] = {...assessments[assessmentId], resultsRequestProcessed: true}

      if (error.errorCode === 1406) {
        assessments[assessmentId] = {...assessments[assessmentId], resultsPending: true}
      }

      action.payload = {...assessments};
      break;
    case actions.GET_ASSESSMENT_QUESTIONS_REJECTED:
    case actions.GET_ASSESSMENT_REJECTED:
    case actions.LIST_ASSESSMENTS_REJECTED:
      console.error('rejected', action.payload.error);
      id = action.payload.id;
      if (id) {
        assessment = { ...assessments[id] };
        assessment.error = action.payload.error;
        assessments[id] = { ...assessment };
        action.payload = assessments;
      }
      break;
    case actions.GET_ASSESSMENT_ANSWERS_FULFILLED:
      id = action.payload.id;
      data = action.payload.data;
      assessments[id] = { ...assessments[id], ...data };
      action.payload = { ...assessments };
      break;
    case actions.GET_ASSESSMENT_RESULT_FULFILLED:
      id = action.payload.id;
      data = action.payload.data;

      assessments[id] = {
        ...assessments[id],
        ...data,
        resultsPending: false,
        resultsRequestProcessed: true
      };
      action.payload = { ...assessments };
      break;
    case actions.GET_PREV_ASSESSMENT_RESULT_FULFILLED:
      id = action.payload.id;
      data = action.payload.data;

      if (isValid(action.payload.prevState)) {
        assessments[id] = {
          ...assessments[id],
          prevStates: [...assessments[id].prevStates, {...data}]
        };
      } else {
        assessments[id] = {
          ...assessments[id],
          prevStates: [{...data}]
        };
      }

      action.payload = { ...assessments };
      break;
    case actions.GET_PREV_ASSESSMENT_RESULT_REJECTED:
      if (action.payload.error.errorCode === 1001) {
        assessments[ASSESSMENT_TYPES.MINDFULNESS_JOURNEY] = {...assessments[ASSESSMENT_TYPES.MINDFULNESS_JOURNEY]};
      }
      action.payload = { ...assessments };
      break;
    case actions.INIT_GET_ASSESSMENT_QUESTIONS:
      id = action.payload.assessmentId;

      assessments[id] = { ...assessments[id], questions: undefined};
      action.payload = { ...assessments };
      break;
    case actions.GET_ASSESSMENT_QUESTIONS_FULFILLED:
      id = action.payload.id;
      data = action.payload.data;
      assessments[id] = { ...assessments[id], ...data };
      assessments.getQuestionsProcessing = false;
      action.payload = { ...assessments };
      break;
    case actions.GET_ASSESSMENT_FULFILLED:
      id = action.payload.id;
      data = action.payload.data;
      assessments[id] = { ...assessments[id], ...data };
      action.payload = { ...assessments };
      break;
    case actions.LIST_ASSESSMENTS_FULFILLED:
      const assessmentsInternal = {};

      if (
        action.payload.data &&
        action.payload.data.assessments &&
        action.payload.data.assessments.length > 0
      ) {
        action.payload.data.assessments.forEach((assessment) => {
          const id = assessment.id;
          assessmentsInternal[id] = { ...assessments[id], ...assessment };
        });

        action.payload = { ...assessmentsInternal };
      }
      break;

    default:
      return next(action);
  }
  return next(action);
};
const updateUser = store => next => action => {
  let state = store.getState();
  let currentUser = { ...state.currentUser };

  switch (action.type) {
    case actions.GET_CURRENT_USER_REJECTED:
      console.log('GET_CURRENT_USER_REJECTED');
      localStorage.removeItem('token');
      currentUser.error = action.payload.error;
      currentUser.processing = false;
      action.payload = currentUser;
      break;
    case actions.UPDATE_CURRENT_USER_REJECTED:
      console.log('UPDATE_CURRENT_USER_REJECTED');
      currentUser.error = action.payload.error;
      currentUser.processing = false;
      action.payload = currentUser;
      break;
    case actions.GET_USER_PROFILE_PICTURE_REJECTED:
      console.log('GET_USER_PROFILE_PICTURE_REJECTED');
      currentUser.processing = false;
      currentUser.error = action.payload.error;
      action.payload = currentUser;
      break;
    case actions.UPLOAD_USER_PROFILE_PICTURE_REJECTED:
      console.log('UPLOAD_USER_PROFILE_PICTURE_REJECTED');
      currentUser.processing = false;
      currentUser.error = action.payload.error;
      action.payload = currentUser;
      break;
    case actions.GET_CURRENT_USER_FULFILLED:
      console.log('GET_CURRENT_USER_FULFILLED', window.location.href);
      let mail = action.payload ? action.payload.mail : '';
      localStorage.setItem('mail', mail);
      break;
    case actions.GET_USER_PROFILE_PICTURE_FULFILLED:
      console.log('GET_USER_PROFILE_PICTURE_FULFILLED', window.location.href);
      currentUser.picture = action.payload;
      currentUser.processing = false;
      action.payload = currentUser;
      break;
    case actions.UPDATE_CURRENT_USER_FULFILLED:
      console.log('UPDATE_CURRENT_USER_FULFILLED');
      currentUser = { ...action.payload, ...currentUser };
      currentUser.processing = false;
      action.payload = currentUser;
      break;
    default:
      return next(action);
  }
  return next(action);
};
const token = store => next => action => {
  switch (action.type) {
    case actions.LOGOUT:
    case actions.LOGOUT_FULFILLED:
    case actions.LOGOUT_REJECTED:
    case actions.LOGIN_REJECTED:
      localStorage.removeItem('token');
      break;
    case actions.LOGIN_FULFILLED:
    case fromActionTypes.SIGN_UP_FULFILLED:
      localStorage.setItem('token', action.payload.token);
      break;
    case actions.GET_ASSESSMENT_FULFILLED:
      break;

    default:
      return next(action);
  }

  return next(action);
};
const middlewares = [
  sagaMiddleware,
  logger,
  token,
  updateAssessments,
  updateUser
];

let enhancer = applyMiddleware(...middlewares);

if (process.env.NODE_ENV === 'development') {
  enhancer = composeWithDevTools({})(applyMiddleware(...middlewares));
}

// FETCHING THE BROWSER LANGUAGE
let browserLanguage = window.navigator.language;
let browserLanguageShort = browserLanguage.slice(0, 2);
let browserLanguageFinal = LANGUAGES.EN;

if (browserLanguageShort && (browserLanguageShort === 'en' || browserLanguageShort === 'de')) {
  browserLanguageFinal = LANGUAGES[browserLanguageShort.toUpperCase()];
}

let currentLanguage = browserLanguageFinal || LANGUAGES.EN;
const selectedLanguage = localStorage.getItem('selectedLanguage');
if (!selectedLanguage) {
  localStorage.setItem('selectedLanguage', currentLanguage);
} else {
  currentLanguage = selectedLanguage;
}

const localisation = { currentLanguage: currentLanguage };
const assessments = {};
const initialState = { localisation, assessments };

const store = createStore(reducers, initialState, enhancer);
sagaMiddleware.run(rootSaga);

export default store;
