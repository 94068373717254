// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './MainHeader.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

// OTHER COMPONENTS
import { BluCSSTransition } from 'ui/basic';
import ProfileCircle from './ProfileCircle';
import QuickAccess from './QuickAccess';
import { Logo } from '../Logo';
import { Scrollable } from 'ui/basic';

// STORE OLD
import store from 'store';

// STORE NEXT
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  selectRouteInfo,
  selectBreadcrumb, setBreadcrumb as setBreadcrumbStore
} from 'features/framework/storeNext/routeSlice';

// UTILS
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { alsoHighlightFor } from 'features/framework/utils/routeNext';

// CONFIG & DATA
// const Config = {};
const Burger = IconsSvg.Burger;
const CloseBig = IconsSvg.CloseBig;
const ArrowRight = IconsSvg.ArrowRight;

// COMPONENT: MainHeader
const MainHeader = (props) => {
  // PROPS
  const {
    showBurgerMenu,
    onOpenMainNav = () => {},
    onOpenProfile = () => {},
    onSubNav = (open) => {}
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const bp = useBreakpoint();
  const dispatch = useDispatch();
  const location = useLocation();

  // CUSTOM LOGO
  // CUSTOM LOGO: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  // const logo = useSelector(selectLogo);

  // ROUTEINFO
  const routeInfo = useSelector(selectRouteInfo);
  useEffect(() => {
    console.log('MainHeader.routeInfo', routeInfo);
    const { currentRoute, params, parentRoute, grandParentRoute } = routeInfo;

    const titleRoute = grandParentRoute || parentRoute || currentRoute || {};
    setTitleRoute(titleRoute);

    let subNavRoot = {};
    if (currentRoute.subRoutes) {
      subNavRoot = currentRoute;
    }
    else if (parentRoute && parentRoute.subRoutes) {
      subNavRoot = parentRoute;
    }
    else if (grandParentRoute && grandParentRoute.subRoutes) {
      subNavRoot = grandParentRoute;
    }

    // breadcrumb
    if (subNavRoot.asBreadcrumb) {
      setBreadcrumbRoute({
        ...subNavRoot.asBreadcrumb,
        link: subNavRoot.path
      });
    }
    else {
      setBreadcrumbRoute();
    }

    // subNavRoutes
    let subNavIsOpen = false;
    let subNavRoutes = [];
    if (subNavRoot && subNavRoot.subRoutes && subNavRoot.subRoutes.length) {
      const newSubNavRoutes = subNavRoot.subRoutes.filter((subRoute) => {
        return subRoute.inSubNav;
      });

      if (newSubNavRoutes.length > 1) {
        subNavIsOpen = true;
        subNavRoutes = subNavRoutes.concat(newSubNavRoutes);
      }
    }

    // subNavRoutes: add pathResolved: replace params
    let paramKeys = Object.keys(params);
    if (paramKeys.length) {
      subNavRoutes = subNavRoutes.map((subNavRoute) => {
        const newSubNavRoute = { ...subNavRoute, pathResolved: subNavRoute.path };
        paramKeys.forEach((paramKey) => {
          const paramValue = params[paramKey];
          newSubNavRoute.pathResolved = newSubNavRoute.pathResolved.replace(`:${paramKey}`, paramValue)
        })
        return newSubNavRoute
      });
    }

    // alsoHighlightFor
    const activeSubNavIndex = subNavRoutes.findIndex((subNavRoute) => alsoHighlightFor(location, subNavRoute));
    setActiveSubNavIndex(activeSubNavIndex);

    console.log('subNavRoutes', subNavRoutes);
    // dispatch(setSubNavIsOpenStore(subNavIsOpen))
    onSubNav(subNavIsOpen);
    setSubNavIsOpen(subNavIsOpen);
    setSubNavRoutes(subNavRoutes);

  }, [dispatch, location, routeInfo, onSubNav]);
  // ROUTEINFO: showsubnav
  // const subNavIsOpen = useSelector(selectSubNavIsOpen);
  const [ subNavIsOpen, setSubNavIsOpen ] = useState(false);
  const [ subNavRoutes, setSubNavRoutes ] = useState([]);
  const [ activeSubNavIndex, setActiveSubNavIndex ] = useState(-1);
  // ROUTEINFO: title
  const [ titleRoute, setTitleRoute ] = useState({});
  const getHeaderTitle = () => {
    if (titleRoute.inMainNav) {
      const { label, labelKey } = titleRoute.inMainNav;
      return (
        <Link to={titleRoute.path || '/'}>
          {label || translate(labelKey)}
        </Link>
      );
    }
  };
  const getSubNavTitle = (subNavRoute) => {
    const { label, labelKey } = subNavRoute.inSubNav;
    return label || translate(labelKey);
  };

  // BREADCRUMB
  const breadcrumbStore = useSelector(selectBreadcrumb);
  const [ breadcrumbRoute, setBreadcrumbRoute ] = useState();
  const [ breadcrumb, setBreadcrumb ] = useState({});
  useEffect(() => {
    if (!location || !dispatch) return;
    if (!breadcrumbStore.label && breadcrumbRoute) {
      setBreadcrumb(breadcrumbRoute);
    }
    else if (!breadcrumbRoute) {
      setBreadcrumb({});
    }

    if (breadcrumbStore.label) {
      let removeBreadcrumb = true;
      if (location.pathname.toLowerCase().includes(breadcrumbStore.routesMustInclude)) {
        removeBreadcrumb = false;
      }

      if (removeBreadcrumb) {
        dispatch(setBreadcrumbStore({}))
      }
      else {
        setBreadcrumb(breadcrumbStore);
      }
    }

  }, [dispatch, location, breadcrumbStore, breadcrumbRoute]);

  const getBreadcrumb = () => (
    <BluCSSTransition in={breadcrumb.label || breadcrumb.labelKey ? true : false}>
      <span className={styles.breadcrumb}>
        <span className={styles.breadcrumbArrow}><ArrowRight /></span>
        { breadcrumb.link && (
          <Link to={breadcrumb.link}>
            { translate(breadcrumb.labelKey) || breadcrumb.label }
          </Link>
        )}
        { !breadcrumb.link && (translate(breadcrumb.labelKey) || breadcrumb.label) }
      </span>
    </BluCSSTransition>
  );

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: MainHeader
  return (
    <div className={classNames(styles.mainHeader, {
      [styles.subNavIsOpen]: subNavIsOpen
    })}>

      {/* FIRST LEVEL */}
      <div className={styles.firstLevel}>
        {/* LEFT: LARGE SCREENS*/}
        { !bp.isXs && (
          <div className={styles.left}>

            {/* BURGER */}
            <div className={classNames(styles.burger, {
                [styles.hidden]: !showBurgerMenu
              })}
              onClick={onOpenMainNav}
            >
              <Burger />
            </div>

            {/* TITLE & BREADCRUMB */}
            <div className={styles.title}>
              { getHeaderTitle() }
              { getBreadcrumb() }
            </div>

          </div>
        )}
        {/* LEFT: XS */}
        { bp.isXs && (
          <div className={styles.left} onClick={() => onOpenMainNav(false)}>

            {/* LOGO */}
            {/* logo link */}
            <Logo link='/' />

          </div>
        )}


        {/* RIGHT */}
        <div className={styles.right}>

          {/* QUICK ACCESS: LARGE SCREENS */}
          { !bp.isXs && (
            <div className={classNames(styles.quickAccess)}>
              <QuickAccess />
            </div>
          )}

          {/* PROFILE */}
          <div className={classNames(styles.profile, {
              [styles.hidden]: bp.isXs && !showBurgerMenu
            })}
            onClick={onOpenProfile}
          >
            <Provider store={store}>
              <ProfileCircle />
            </Provider>
          </div>

          {/* BURGER & MENU CLOSE: XS */}
          { bp.isXs && (
            <>
              <div className={classNames(styles.burger, {
                  [styles.hidden]: !showBurgerMenu
                })}
                onClick={onOpenMainNav}
              >
                <Burger />
              </div>
              <div className={classNames(styles.menuClose, {
              [styles.hidden]: !bp.isXs || showBurgerMenu
                })}
                onClick={() => onOpenMainNav(false)}
              >
                <CloseBig />
              </div>
            </>
          )}
        </div>
      </div>


      {/* SECOND LEVEL */}
      { (bp.isXs || subNavIsOpen) && (

        <div className={classNames(styles.secondLevel, {
          [styles.subNavIsOpen]: subNavIsOpen
        })}>

          {/* TITLE & BREADCRUMB: XS  */}
          { bp.isXs && (
            <div className={styles.title}>
              { getHeaderTitle() }
              { getBreadcrumb() }
            </div>
          )}

          {/* QUICK ACCESS: XS */}
          { bp.isXs && !subNavIsOpen && (
            <div className={classNames(styles.quickAccess)}>
              <QuickAccess />
            </div>
          )}

          {/* SUBNAV */}
          { subNavIsOpen && (
            <div className={styles.subNav}>
              <Scrollable scroll scrollOnClick noMargins>
                { subNavRoutes.map((subNavRoute, subNavRouteIndex) => (
                  <Link key={subNavRoute.path}
                    to={subNavRoute.pathResolved || subNavRoute.path}
                    className={classNames(styles.subNavItem, {
                      [styles.active]: subNavRoute.path === routeInfo.currentRoute.path || subNavRouteIndex === activeSubNavIndex
                    })}
                  >
                    { getSubNavTitle(subNavRoute) }
                    <div className={styles.border}></div>
                  </Link>
                ))}
              </Scrollable>
            </div>
          )}
        </div>
      )}

    </div>
  );
};

export default MainHeader;
