export const isValid = (number) => {
  if (number === null || number === undefined) {
    return false;
  }

  return !Number.isNaN(Number(number))
};

export const isNumber = (value) => {
  return Object.prototype.toString.call(value) === '[object Number]';
}

export const toFixed = (number, digits = 2) => {
  if (Number.isInteger(number)) {
    return number;
  }

  return number.toFixed(digits);
};
