import {ASSESSMENT_TYPES, DISPLAY_ONLY_RESULT_ASSESSMENTS} from "utils/configuration/const/assessment-types";


export const selectAssessments = store => {
  const assessmentsFromStore = store.assessments;

  if (!assessmentsFromStore || Object.keys(assessmentsFromStore).length === 0) {
    return [];
  }

  const unorderedCoreAssessments = [];
  const orderedCoreAssessments = [];
  const customAssessments = [];

  // separate core and custom assessments into 2 different arrays
  Object.keys(assessmentsFromStore).forEach(assessmentKey => {
    if (assessmentsFromStore[assessmentKey] && Object.prototype.toString.call(assessmentsFromStore[assessmentKey]) === '[object Object]') {
      if (Object.values(ASSESSMENT_TYPES).includes(assessmentKey)) {
        unorderedCoreAssessments.push(assessmentsFromStore[assessmentKey]);
      } else {
        customAssessments.push(assessmentsFromStore[assessmentKey]);
      }
    }
  })

  // order core assessments
  Object.values(ASSESSMENT_TYPES).forEach(assessmentId => {
    const thisAssessment = unorderedCoreAssessments.find(a => a.id === assessmentId);
    if (thisAssessment && !orderedCoreAssessments.find(a => a.id === assessmentId)) {
      orderedCoreAssessments.push(thisAssessment);
    }
  })

  return orderedCoreAssessments.concat(customAssessments);
};

export const selectAssessmentsWithNoClifton = store => {
  return selectAssessments(store).filter(assessment => assessment && assessment.render_results &&
    !DISPLAY_ONLY_RESULT_ASSESSMENTS.includes(assessment.render_results));
};
