import React, {useEffect} from 'react';
import styles from './TeamUsersList.module.scss';

import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';

import { useTranslate } from 'utils/translator';
import {PLATFORM_ROLES} from 'utils/configuration/const/roles';
import {REGISTRATION_STATUSES} from 'utils/configuration/const/registration-statuses';
import {useBreakpoint} from 'utils/hooks';

import * as api from 'api';

import {
  deleteUser,
  initDeleteUser,
  initUpdateUserRole,
  showToast,
  updateUserRole
} from 'store/actions';
import * as fromTeamOverviewSelectors from 'store/selectors/teamOverview';
import {selectCapabilitiesNext} from 'store/selectors/configuration';

import {ReactComponent as Star} from 'assets/icons/icn_star.svg';
import {ImgCircle, OptionsMenu, CollapsiblePanel} from 'ui/basic';


const OPTION_TYPES = {
  SHOW_PROFILE: 'showProfile',
  SET_AS_TEAM_LEAD: 'setAsTeamLead',
  REMOVE_TEAM_LEAD_STATUS: 'removeTeamLeadStatus',
  DELETE_FROM_TEAM: 'deleteFromTeam'
};

const TeamUsersList = (props) => {
  const {users, onUserClick} = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const params = useParams();
  const bp = useBreakpoint();

  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const teamUserDeletedSuccessfully = useSelector(fromTeamOverviewSelectors.getDeleteTeamUserSuccess);
  const userRoleUpdatedSuccessfully = useSelector(fromTeamOverviewSelectors.getUpdateUserRoleSuccess);
  const updatedUserRole = useSelector(fromTeamOverviewSelectors.getUpdateUserRole);

  const getOptions = (user) => {
    const options = [
      {value: OPTION_TYPES.DELETE_FROM_TEAM, label: translate('team_users_option3') || 'Aus Team entfernen'}
    ];

    if (user && user.registrationStatus !== REGISTRATION_STATUSES.INVITED) {
      if (!user.isTeamLead) {
        options.unshift({value: OPTION_TYPES.SET_AS_TEAM_LEAD, label: translate('team_users_option2') || 'Als Team-Lead festlegen'});
      }

      if (user.isTeamLead) {
        options.unshift({
          value: OPTION_TYPES.REMOVE_TEAM_LEAD_STATUS,
          label: translate('team_users_option4') || 'Team-Lead Status entziehen'
        });
      }

      if (capabilitiesNext.employeesView) {
        options.unshift({value: OPTION_TYPES.SHOW_PROFILE, label: translate('team_users_option1') || 'Profil anzeigen (neuer Tab)'});
      }

    }

    return options;
  };

  useEffect(() => {
    if (teamUserDeletedSuccessfully) {
      const toastTitle = translate('team_user_removed__toast_title') || 'User is removed from the team';
      const toastContent = translate('team_user_removed__toast_desc') || 'User is successfully removed from the team.';

      dispatch(showToast(toastTitle, toastContent));

      //set to initial values, thus the toast will be displayed only once
      dispatch(initDeleteUser());
    }
  }, [dispatch, teamUserDeletedSuccessfully, translate]);

  useEffect(() => {
    if (userRoleUpdatedSuccessfully) {
      let toastTitle = translate('team_user_set_as_teamlead__toast_title') || 'User is set as team-lead';
      let toastContent = translate('team_user_set_as_teamlead__toast_descr') || 'User is successfully set as team-lead';

      if (updatedUserRole !== PLATFORM_ROLES.TEAM_LEADER) {
        toastTitle = translate('team_user_remove_teamlead_status__toast_title');
        toastContent = translate('team_user_remove_teamlead_status__toast_descr');
      }

      dispatch(showToast(toastTitle, toastContent));

      //set to initial values, thus the toast will be displayed only once
      dispatch(initUpdateUserRole());
    }
  }, [dispatch, userRoleUpdatedSuccessfully, updatedUserRole, translate]);

  return (
    <div className={styles.teamUsersList}>
      <CollapsiblePanel
        header={<div className={styles.header}>{translate('team_profile__team_members') || 'Team-Mitglieder'}</div>}
        showTopBorder={false}
        expanded={!bp.isXs}
      >
        <>
          {users
            .sort((item1, item2) => {
              const item1LastName = item1.name.split(' ')[1];
              const item2LastName = item2.name.split(' ')[1];

              if (item1LastName > item2LastName) {
                return 1;
              }
              if (item1LastName < item2LastName) {
                return -1;
              }
              return 0;
            })
            .map((user, index) => {
              let name = user.mail;
              if (user.name) {
                name = user.name;
              }

              return (
                <div
                  key={`employee-${index}`}
                  className={styles.employee}
                  onClick={event => {
                    const optionsMenuElement = document.getElementById(`optionsMenu${index}`);
                    if (optionsMenuElement.contains(event.target) || optionsMenuElement === event.target) {
                      return;
                    }

                    // handleRipple(event, styles.colorPrimary3);

                    setTimeout(() => {
                      if (onUserClick) {
                        onUserClick(user);
                      }
                    })
                  }}
                >
                  <div className={styles.imgCircle}>
                    <ImgCircle
                      size={'S'}
                      src={api.getUserImageUrl(user.id)}
                      label1={name.split(' ')[0]}
                      label2={name.split(' ')[1]}
                    />
                  </div>

                  <div className={styles.namePosition}>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.position}>{user.orgRole || user.position}</div>
                  </div>

                  {/* STAR */}
                  {user.isTeamLead && (
                    <div className={styles.star}>
                      <Star/>
                    </div>
                  )}

                  {capabilitiesNext.teamsCreate &&
                  <div id={`optionsMenu${index}`} className={styles.optionsMenu}>
                    <OptionsMenu
                      withBackgroundDark
                      options={getOptions(user)}
                      onClick={value => {
                        switch (value) {
                          case OPTION_TYPES.SHOW_PROFILE:
                            window.open(`/employees/${user.id}/profile`, '_blank');
                            break;
                          case OPTION_TYPES.SET_AS_TEAM_LEAD:
                            dispatch(updateUserRole({
                              teamId: params.teamId,
                              userId: user.id,
                              role: PLATFORM_ROLES.TEAM_LEADER
                            }));
                            break;
                          case OPTION_TYPES.REMOVE_TEAM_LEAD_STATUS:
                            dispatch(updateUserRole({
                              teamId: params.teamId,
                              userId: user.id,
                              role: PLATFORM_ROLES.TEAM_MEMBER
                            }));
                            break;
                          case OPTION_TYPES.DELETE_FROM_TEAM:
                            dispatch(deleteUser({teamId: params.teamId, userId: user.id}));
                            break;
                          default:
                        }
                      }}
                    />
                  </div>
                  }

                </div>
              )
          })}
        </>
      </CollapsiblePanel>
    </div>
  )
};

export default TeamUsersList;
