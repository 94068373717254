// REACT, STYLE, STORIES & COMPONENT
import React, {useCallback, useEffect, useState} from 'react';
import styles from './CandidateExternalRoleFit.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import {Comment} from 'ui/basic';
import ProfileSummary from 'features/+candidates/pages/CandidateSummary/ProfileSummary';
import {
  CollapsibleNext,
  DetailsPageSkeleton,
  PillGroup,
  RoleMatchSummary,
  TextEditor
} from 'ui/basic';

import {
  Big5Result,
  CompetenciesResult,
  NineLevelsResult,
  PotentialResult,
  RmpResult,
  WorkPreferencesResult,
  SkillsResult
} from "ui/molecules";

// UTILS
import { useTranslate } from 'utils/translator';
import {setDecimalSeperator} from 'utils/userpreference';
import {isValid} from 'utils/numbers';
import {getAssessmentRange} from 'utils/roles';
import {getMappedNineLevelsResults} from 'utils/assessment';
import {
  ASSESSMENT_TITLES,
  ASSESSMENT_TYPES
} from 'utils/configuration/const/assessment-types';
import { scrollIntoView } from 'utils/scrolling';

// STORE
import * as api from 'api';
import {useSelector} from 'react-redux';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';

// CONFIG & DATA
// const Config = {};


// COMPONENT: CandidateExternalRoleFit
const CandidateExternalRoleFit = (props) => {
  // PROPS
  const {candidate, token, results} = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // ROLES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDER
  const [role, setRole] = useState();
  const [roleFetchProcessing, setRoleFetchProcessing] = useState();

  const setRoleData = useCallback((role) => {
    //for manual roles profile values will be stored in 'generated property' (for consistency with auto roles)
    if (role && role.referenceProfile && role.referenceProfile.profile && Array.isArray(role.referenceProfile.profile)) {
      const profile = [...role.referenceProfile.profile];
      role.referenceProfile.profile = {generated: profile};
    }

    setRole(role);
  }, []);

  // ASSESSMENTS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);

  // USER PROFILE (USER ASSESSMENTS RESULTS): STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    if (candidate.jobs) {
      setRoleFetchProcessing(true);
      api.get('recruiting/candidates/roles', {}, {Authorization: `Bearer ${token}`})
        .then(({ok, status, data}) => {
          setRoleFetchProcessing(false);

          if (ok && status === 200) {
            setRoles(data.roles);
            setRoleData(data.roles[0]);
          }
        })
    }
  }, [candidate, setRoleData, token]);

  // ROLE MATCHING DETAILS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [matchingDetails, setMatchingDetails] = useState();

  const getMatchTitle = useCallback((score) => {
    if (score >= 90) {
      return translate('employee_role_very_high_matching', [
        '{{username}}', candidate.name, '{{role}}', (role.name)
      ]) || `${candidate.name} hat eine sehr hohe Übereinstimmung für diese Rolle.`;
    }
    if (score < 90 && score >= 80) {
      return translate('employee_role_high_matching', [
        '{{username}}', candidate.name, '{{role}}', (role.name)
      ]) || `${candidate.name} hat eine hohe Übereinstimmung für diese Rolle.`;
    }
    if (score < 80 && score >= 40) {
      return translate('employee_role_medium_matching',
        ['{{username}}', candidate.name, '{{role}}', (role.name)
        ]) || `${candidate.name} hat eine mittlere Übereinstimmung für diese Rolle.`;
    }
    return translate('employee_matching_low', [
      '{{username}}', candidate.name, '{{role}}', (role.name)
    ]) || `${candidate.name} hat eine niedrige Übereinstimmung für diese Rolle.`;
  }, [candidate, role, translate]);

  useEffect(() => {
    if (!candidate.jobs || candidate.jobs.length === 0 || !role) {
      return;
    }

    const vacancy = candidate.jobs.find(job => job.roleId === role.id);

    if (!vacancy) {
      return;
    }

    api.get(`/recruiting/jobs/${vacancy.id}/matches`,
      {expand: 'score'},
      {Authorization: `Bearer ${token}`}
    )
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          const candidateMatchDetails = data.matches.find(m => m.user === candidate.id);
          if (candidateMatchDetails) {
            setMatchingDetails({
              ...candidateMatchDetails,
              matchingText: getMatchTitle(candidateMatchDetails.score)
            });
          }
        }
      })
      .catch(error => console.error(error.message))

  }, [role, candidate, getMatchTitle, token]);

  const getRoleMatchSummaryLinks = () => {
    if (!matchingDetails || !assessmentsFromStore) {
      return;
    }

    const clickableLinks =[];
    const nonClickableLinks = [];

    assessmentsFromStore
      .forEach(assessment => {
        const thisAssessment = matchingDetails.assessments.find(a => a.assessmentId === assessment.id);

        if (thisAssessment) {
          clickableLinks.push({
            id: thisAssessment.assessmentId,
            label: `${translate(ASSESSMENT_TITLES[thisAssessment.assessmentId]) || assessment.title} - ${setDecimalSeperator(thisAssessment.score)} %`,
            clickable: true
          });
        } else {
          nonClickableLinks.push({
            id: assessment.id,
            label: `${translate(ASSESSMENT_TITLES[assessment.id]) || assessment.title}`,
            clickable: false
          });
        }
      })

    return clickableLinks.concat(nonClickableLinks);
  }

  // ASSESSMENTS RESULTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [assessmentsResults, setAssessmentsResults] = useState([]);
  useEffect(() => {
    if (!role || !results) {
      return;
    }

    const assessments = [];

    assessmentsFromStore.forEach((assessment, index) => {
      if (results && results.length > 0) {
        const thisAssessment = results.find(p => p.assessment === assessment.id);

        let assessmentAvailableInRoleRefProfile = false;
        const roleProfile = role.referenceProfile.profile;
        let currentItem;
        let generatedItem;

        if (roleProfile && roleProfile.current) {
          currentItem = roleProfile.current.find(assessmentItem => assessmentItem.assessmentId === assessment.id);
        }

        if (roleProfile && roleProfile.generated) {
          generatedItem = roleProfile.generated.find(assessmentItem => assessmentItem.assessmentId === assessment.id);
        }

        if ((currentItem && currentItem.results && currentItem.results.length > 0) ||
          (generatedItem && generatedItem.results && generatedItem.results.length > 0)) {
          assessmentAvailableInRoleRefProfile = true;
        }

        if (thisAssessment && thisAssessment.result && assessmentAvailableInRoleRefProfile) {
          assessments.push({
            id: assessment.id,
            name: translate(ASSESSMENT_TITLES[assessment.id] || assessment.title),
            results: thisAssessment.result.results
          });
        }
      }
    });

    setAssessmentsResults(assessments);
  }, [role, results, assessmentsFromStore, translate]);

  const getMappedAssessmentResults = (assessment) => {
    let assessmentResults = [];
    let start = 0;

    const thisAssessmentFromStore = assessmentsFromStore && assessmentsFromStore.find(a => a.id === assessment.id);
    const resultSchema = (thisAssessmentFromStore && thisAssessmentFromStore.resultSchema) || [];

    if (!role) {
      return assessmentResults;
    }

    let referenceProfile;
    if (role && role.referenceProfile && role.referenceProfile.profile) {
      const userProfile = role.referenceProfile.profile;
      //manually created roles do not have current/generated values
      //it is always array of assessments stored right in 'profile' value
      if (Array.isArray(userProfile)) {
        referenceProfile = userProfile.find(p => p.assessmentId === assessment.id);
      }
      //values in 'current' value have more priority than 'generated'
      else if (userProfile.current && userProfile.current.length > 0) {
        referenceProfile = userProfile.current.find(p => p.assessmentId === assessment.id);
      }
      else if (userProfile.generated && userProfile.generated.length > 0 && !referenceProfile) {
        referenceProfile = userProfile.generated.find(p => p.assessmentId === assessment.id);
      }
    }

    //filter out result values which are not valid (null, undefined, NaN)
    let assessmentValidResults = assessment.results.filter(ar => isValid(ar.result) || Array.isArray(ar.result));
    if (assessment.id === ASSESSMENT_TYPES.NINE_LEVELS) {
      assessmentValidResults = getMappedNineLevelsResults(assessment.results);
    }

    if (referenceProfile && referenceProfile.results.length &&
      (assessmentValidResults.length > referenceProfile.results.length)
    ) {
      if (assessment.id === ASSESSMENT_TYPES.RMP) {
        // romance is a dimension that is not used
        assessmentResults = assessmentValidResults.filter(assessmentResult => assessmentResult.id !== 'romance');
      } else {
        resultSchema.forEach(rs => {
          assessmentResults.push(assessmentValidResults.slice(start, (start + rs.subDimensions.length)))
          //results includes subDimensions + dimensions
          //+1 because after sub dimensions values there is one more value for total result;
          start += (rs.subDimensions.length + 1);
        });
        assessmentResults = assessmentResults.flatMap(res => res)
      }
    } else {
      assessmentResults = [...assessmentValidResults];
    }

    return assessmentResults.map((resultItem, index) => {
      let range;

      if (referenceProfile && referenceProfile.results) {
        const refProfileResult = referenceProfile.results.find(item => {
          let dimensionId = item.dimensionId;
          if (assessment.id === ASSESSMENT_TYPES.NINE_LEVELS && dimensionId.includes('cyan')) {
            dimensionId = dimensionId.replace('cyan', 'turquoise');
          }
          return dimensionId === resultItem.id;
        });

        if (refProfileResult) {
          range = getAssessmentRange(assessment.id, refProfileResult.minValue, refProfileResult.maxValue)
        }
      }

      return {
        id: resultItem.id,
        name: resultItem.name,
        result: resultItem.result,
        range
      }
    })
  }

  // RENDER ROLE LOADING SKELETON
  const renderRoleLoadingSkeleton = () => {
    const rows = [];

    for (let i=0; i<11; i++) {
      rows.push(
        <div key={i} className={styles.contentBlock}>
          <div className={styles.row}></div>
          <div className={styles.row}></div>
        </div>
      );
    }

    return (
      <div className={styles.roleLoadingSkeleton}>
        <div className={styles.top}></div>
        {rows}
      </div>
    )
  }

  // RENDER: DetailsPageSkeleton
  if (!candidate) {
    return <div className={styles.candidateExternalRoleFit}>
      <div className={styles.gridContainer}>
        <DetailsPageSkeleton/>
      </div>
    </div>
  }

  // RENDER: CandidateExternalSummary
  return (
    <div className={classNames(styles.candidateExternalRoleFit)}>
      <div className={styles.gridContainer}>
        {/*LEFT CONTENT*/}
        <div className={styles.leftContent}>
          {(candidate && candidate.firstName) ?
            <ProfileSummary
              candidate={candidate}
              showControls={false}
            /> :
            <div>
              <div className={styles.xxsTitle}>{translate('candidate_profile') || 'Kandidat:innen-Profil'}</div>
              <div className={styles.hint} style={{marginTop: '4px'}}>
                {candidate.candidateData ? candidate.candidateData.currentPosition : ''}
              </div>
            </div>
          }
        </div>

        {/*RIGHT CONTENT*/}
        <div className={styles.rightContent}>
          <div className={styles.xxsTitle}>Rollen-Fit</div>

          {(candidate.jobs && candidate.jobs.length > 0) &&
          <PillGroup
            pillItems={roles.map(role => ({id: role.id, label: role.name}))}
            activeIndex={0}
            looks={'primary'}
            onPillClick={pillItem => {
              const thisRole = roles.find(r => r.id === pillItem.id);
              if (thisRole) {
                setRoleData(thisRole)
              }
            }}
          />
          }

          {roleFetchProcessing && renderRoleLoadingSkeleton()}

          {/*ROLE DESCRIPTION + ROLE DETAILS*/}
          {(!roleFetchProcessing && role) &&
          <CollapsibleNext
            header={(
              <div className={styles.label}>
                {translate('employee_role_details') || 'Rollendetails'}
              </div>
            )}
            size={'L'}
            withBorders
          >
            <div className={styles.collapsibleContent}>
              { role?.description && (
                <div>
                  <TextEditor content={role.description} disabled />
                </div>
              )}

              { role?.details && (
                <div>
                  <TextEditor content={role.details} disabled />
                </div>
              )}
            </div>
          </CollapsibleNext>
          }

          {/*MATCH SUMMARY*/}
          {role &&
          <RoleMatchSummary
            matchingText={matchingDetails && matchingDetails.matchingText}
            matchingPercentage={matchingDetails && matchingDetails.score}
            links={getRoleMatchSummaryLinks()}
            onLinkClick={scrollIntoView}
          >
          </RoleMatchSummary>
          }

          {/*ASSESSMENTS RESULTS + RANGES*/}
          {assessmentsResults.map((assessment, index) => {
            switch (assessment.id) {
              case ASSESSMENT_TYPES.BIG5:
                return (
                  <div id={assessment.id} key={index}>
                    <Big5Result showReportButton={false} results={getMappedAssessmentResults(assessment)} />
                  </div>
                )
              case ASSESSMENT_TYPES.POTENTIAL:
                return (
                  <div id={assessment.id} key={index}>
                    <PotentialResult results={getMappedAssessmentResults(assessment)} showDimensionReport={false} />
                  </div>
                )
              case ASSESSMENT_TYPES.KEY_COMPETENCIES:
                return (
                  <div id={assessment.id} key={index}>
                    <CompetenciesResult
                      assessmentType={ASSESSMENT_TYPES.KEY_COMPETENCIES}
                      results={getMappedAssessmentResults(assessment)}
                    />
                  </div>
                )
              case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
                return (
                  <div id={assessment.id} key={index}>
                    <CompetenciesResult
                      assessmentType={ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES}
                      results={getMappedAssessmentResults(assessment)}
                    />
                  </div>
                )
              case ASSESSMENT_TYPES.WORK_PREFERENCES:
                return (
                  <div id={assessment.id} key={index}>
                    <WorkPreferencesResult results={getMappedAssessmentResults(assessment)}/>
                  </div>
                )
              case ASSESSMENT_TYPES.RMP:
                return (
                  <div id={assessment.id} key={index}>
                    <RmpResult results={getMappedAssessmentResults(assessment)} showReportButton={false} />
                  </div>
                )
              case ASSESSMENT_TYPES.NINE_LEVELS:
                return (
                  <div id={assessment.id} key={index}>
                    <NineLevelsResult results={getMappedAssessmentResults(assessment)} showReportButton={false} />
                  </div>
                )
              default:
                return (
                  <div id={assessment.id} key={index}>
                    <SkillsResult assessmentType={assessment.id} results={getMappedAssessmentResults(assessment)}/>
                  </div>
                )
            }
          })}

        </div>
      </div>

    </div>
  );
};

export default CandidateExternalRoleFit;
