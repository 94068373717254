export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_LANGUAGES_PENDING = 'GET_LANGUAGES_PENDING';
export const GET_LANGUAGES_FULFILLED = 'GET_LANGUAGES_FULFILLED';
export const GET_LANGUAGES_REJECTED = 'GET_LANGUAGES_REJECTED';

export const getLanguages = () => {
  return {
    type: GET_LANGUAGES,
    payload: null
  };
};

export const getLanguagesFulfilled = (languages) => {
  return {
    type: GET_LANGUAGES_FULFILLED,
    payload: languages
  };
};
export const getLanguagesRejected = ({ error }) => {
  return {
    type: GET_LANGUAGES_REJECTED,
    payload: { error }
  };
};

