// REACT, STYLE & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './FilterListNext.module.scss';

// ASSETS
import { ReactComponent as TitleImage } from 'assets/keyvisuals/grow_keyvisual.svg';
import {IconsSvg} from 'assets/icons';

// OTHER COMPONENTS
import {
  Button,
  InputNext,
  DropDownSubtle,
  BigToggle,
  Pagination,
} from 'ui/basic';

import ListNext from '../ListNext/ListNext';
import {
  mapEmployeesData,
  mapStaffingData,
  mapRolesData,
  mapTeamsData,
  mapCandidatesData,
  mapUsersData,
  mapUsersAdminData,
  mapContentPerformanceData,
  mapInstancesData, mapVacanciesData,
} from '../ListNext/ListNext.data';

import FilterListSkeleton from './FilterListSkeleton';
import { ReactComponent as SkeletonXl } from './skeletons/skeleton_xl.svg';
import { ReactComponent as SkeletonL } from './skeletons/skeleton_l.svg';
import { ReactComponent as SkeletonM } from './skeletons/skeleton_m.svg';
import { ReactComponent as SkeletonS } from './skeletons/skeleton_s.svg';
import { ReactComponent as SkeletonXs } from './skeletons/skeleton_xs.svg';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { useDebounce, useBreakpoint } from 'utils/hooks';

// CONFIG & DATA
import { MIN_SEARCH_LENGTH } from 'api';

// COMPONENT: FilterListNext
const FilterListNext = (props) => {
  // PROPS
  const {
    // type
    listType = 'employees',

    // data
    listItems = [],

    // layout
    loading = false,
    showLastDataWhileLoading = false,
    useFullLoading = false, // also read as isInitialized, won't show any data as long as this if false
    showEmptyPage = false, // show the page saying that there is not data at all
      // (not to confuse with the text that says that there's no data for a search)
    hasFullHeight = false,

    // click
    onItemClick = () => {},
    disableItem = (item) => { return false; },

    // search
    searchPlaceholder = '',
    activeSearchString = '',
    onSearch = () => {},

    // pagination
    activePageCount = 0,
    activePageIndex = 0,
    onPage = () => {},

    // sort
    activeSortIndex = 0,
    sortOptions = [],
    onSort = () => {},

    // toggle
    activeToggleIndex = 0,
    toggleOptions = [],
    onToggle = () => {},

    // headline
    headline = '',
    altHeadline = '',

    // controls
    buttonText = '',
    onButtonClick = () => {},
    buttonSecondaryText = '',
    onButtonSecondaryClick = () => {},

    // empty page
    emptyTitle = '',
    emptyText = '',
    emptyFilterTitle = '',
    emptyFilterText = '',

  } = props;

  const translate = useTranslate();

  // FILTER STRING
  const [ internalSearchString, setInternalSearchString ] = useState(activeSearchString);
  useEffect(() => { // update search string
    setInternalSearchString(activeSearchString);
  }, [activeSearchString]);
  const [ activeDebouncedSearchString, setActiveDebouncedSearchString ] = useState('');
  const debouncedSearchString = useDebounce(internalSearchString, 300);
  useEffect(() => {
    // prevent unnecessary or invalid onSearch calls
    if (
      debouncedSearchString !== activeDebouncedSearchString
      && (
        debouncedSearchString.length >= MIN_SEARCH_LENGTH
        // allow reset
        || debouncedSearchString === ''
      )
    ) {
      onSearch(debouncedSearchString);
      setActiveDebouncedSearchString(debouncedSearchString);
    }
  }, [activeDebouncedSearchString, debouncedSearchString, onSearch]);

  // SORT INDEX
  const [ internalSortIndex, setInternalSortIndex ] = useState();
  useEffect(() => {
    setInternalSortIndex(activeSortIndex);
  }, [sortOptions, activeSortIndex]);
  // console.log(initialSortIndex, initialSortIndex >= 0 ? initialSortIndex : 0);

  // TOGGLE INDEX
  const [ internalToggleIndex, setInternalToggleIndex ] = useState();
  useEffect(() => {
    setInternalToggleIndex(activeToggleIndex);
  }, [ toggleOptions, activeToggleIndex ]);


  // BREAKPOINTS
  const bp = useBreakpoint();

  // LIST PROPS
  const [ listProps, setListProps ] = useState(null);
  useEffect(() => {

    let listProps = {
      onItemClick: (index, disabled) => {
        const item = listItems[index];
        !disabled && onItemClick(item);
      },
      disableItem: (index) => {
        const item = listItems[index];
        return disableItem(item);
      },
    };

    if (listType === 'employees') {
      Object.assign(listProps, {
        listItems: mapEmployeesData(listItems, internalSearchString, bp)
      });
    }
    else if (listType === 'roles') {
      Object.assign(listProps, {
        listItems: mapRolesData(listItems, internalSearchString, bp),
        showAvatar: false,
        hideEmptyItemIdentifierSublabel: true,
      });
    }
    else if (listType === 'staffing') {
      Object.assign(listProps, {
        listItems: mapStaffingData(listItems, internalSearchString, bp),
        layout: 'layout5Cells',
        hasTooltips: true,
        cellLastXsBehavior: 'addCellLastXs',
      });
    }
    else if (listType === 'teams') {
      Object.assign(listProps, {
        listItems: mapTeamsData(listItems, internalSearchString, bp),
        showAvatar: false,
      });
    }
    else if (listType === 'candidates') {
      Object.assign(listProps, {
        listItems: mapCandidatesData(listItems, internalSearchString, bp),
      })
    }
    else if (listType === 'vacancies') {
      Object.assign(listProps, {
        listItems: mapVacanciesData(listItems, internalSearchString, bp),
        layout: 'layout4Cells',
        showAvatar: false
      })
    }
    else if (listType === 'users') {
      Object.assign(listProps, {
        listItems: mapUsersData(listItems, internalSearchString, bp),
      });
    }
    else if (listType === 'usersAdmin') {
      Object.assign(listProps, {
        listItems: mapUsersAdminData(listItems, internalSearchString, bp),
        showAvatar: false,
      });
    }
    else if (listType === 'instances') {
      Object.assign(listProps, {
        listItems: mapInstancesData(listItems, internalSearchString, bp),
        layout: 'layout5Cells',
        showAvatar: false,
      });
    }
    else if (listType === 'contentPerformance') {
      Object.assign(listProps, {
        listItems: mapContentPerformanceData(listItems, internalSearchString, bp),
        showAvatar: false,
        showArrow: false
      });
    }
    else {
      throw Error(`Unknown listType ${listType}`);
    }

    // console.log('listProps', listProps);

    setListProps(listProps);
  }, [listItems, listType, bp, internalSearchString, disableItem, onItemClick]);


  // HELPERS, HANDLES, METHODS
  const showLoading = () => {
    return (loading && !showLastDataWhileLoading)
      || (loading && showLastDataWhileLoading && !listItems.length);
  };

  // RENDER FilterListNext
  return (
    <div className={`${styles.filterList}`}>

      {/* INITIAL LOAD */}
      { useFullLoading && loading && (
        <FilterListSkeleton />
      )}

      {/* EMPTY PAGE */}
      { showEmptyPage && (
        <>
          {/* TitleImage */}
          <TitleImage className={styles.emptyTitleImage} />
          {/* .emptyContent */}
          <div className={styles.emptyContent}>
            <div className={styles.titleText}>
              { emptyTitle }
            </div>
            {/* .emptyText */}
            <div className={styles.emptyText}>
              { emptyText }
            </div>
            {/* Button */}
            {buttonText &&
            <div>
              <Button
                looks={'primary'}
                leadingIcon={IconsSvg.Plus}
                size={'S'}
                onClick={onButtonClick}>
                { buttonText }
              </Button>
            </div>
            }
          </div>
        </>
      )}


      {/* LIST & CONTROLS */}
      { !useFullLoading && !showEmptyPage && (
        <div className={styles.content}>

          {/* titleText */}
          <div className={styles.titleText}>
            { headline }
          </div>

          {/* controls */}
          <div className={classNames(styles.controls, {[styles.withToggle]: toggleOptions.length > 0})}>

            {/* TOGGLE */}
            { toggleOptions.length > 0 && (
              <div className={styles.toggleContainer}>
                <BigToggle
                  activeIndex={internalToggleIndex}
                  options={toggleOptions}
                  onToggle={onToggle}
                />
              </div>
            )}

            {/* OTHER CONTROLS */}
              <>
                {/* HAS FILTER  */}
                { searchPlaceholder && (
                  <InputNext
                    className='noBorder'
                    autofocus
                    placeholder={searchPlaceholder}
                    value={internalSearchString}
                    icon='SearchBig'
                    onChange={(newSearchString) => {
                      if (newSearchString !== internalSearchString) {
                        setInternalSearchString(newSearchString);
                      }
                    }}
                  />
                )}

                {/* HAS NO FILTER - XXS TITLE INSTEAD */}
                { !searchPlaceholder && (
                  <div className={styles.xxsTitle}>
                    {altHeadline || headline}
                  </div>
                )}

                {/* BUTTON */}
                { buttonText && (
                  <div>
                    <Button
                      looks={'primary'}
                      leadingIcon={IconsSvg.Plus}
                      size='S'
                      onClick={onButtonClick}>
                      { buttonText }
                    </Button>
                  </div>
                )}
                {/* SECONDARY BUTTON */}
                { buttonSecondaryText && (
                  <div className={styles.secondaryButton}>
                    <Button
                      looks={'secondary'}
                      size='S'
                      onClick={onButtonSecondaryClick}
                    >
                      {buttonSecondaryText}
                    </Button>
                  </div>
                )}
              </>

            {/* SORT DROPDOWN */}
            <DropDownSubtle
              className='alignRight'
              options={sortOptions}
              activeIndex={internalSortIndex}
              onChange={(newIndex) => {
                if (newIndex !== internalSortIndex) {
                  setInternalSortIndex(newIndex);
                  onSort && onSort(newIndex);
                }
              }}
            >
              {translate('table_sort_by') || 'Sortieren nach'}
            </DropDownSubtle>
          </div>

          {/* list container */}
          <div className={styles.listContainer}>

            {/* LOADING */}
            { showLoading() && (
              <div className={styles.skeleton}>
                { bp.isXl && (
                  <SkeletonXl />
                )}
                { bp.isL && (
                  <SkeletonL />
                )}
                { bp.isM && (
                  <SkeletonM />
                )}
                { (bp.isS || bp.isXs) && (
                  <SkeletonS />
                )}
                { bp.isXs && (
                  <SkeletonXs />
                )}
              </div>
            )}

            {/* NOT LOADING */}
            { !showLoading() && (
              <>
                {/* LIST */}
                { listItems && listItems.length !== 0 && (
                  <div className={classNames(styles.list, {
                    [styles.hasFullHeight]: hasFullHeight
                  })}>
                    <ListNext {...listProps} />
                  </div>
                )}
                {/* EMPTY LIST */}
                { listItems && listItems.length === 0 && (
                  <div className={styles.emptyListResult}>
                    <div className={styles.headline}>
                      { emptyFilterTitle }
                    </div>
                    <div className={styles.text}>
                      { emptyFilterText }
                    </div>
                  </div>
                )}
              </>
            )}

            {/* PAGINATION */}
            { activePageCount > 1 && (
              <div className={styles.pagination}>
                <Pagination
                  pageCount={activePageCount}
                  pagePosition={activePageIndex}
                  showPagerButtons showNumbers
                  disabled={isNaN(activePageCount)}
                  onPagePositionUpdate={(newPageIndex) => {
                    if(activePageIndex !== newPageIndex) {
                      onPage(newPageIndex);
                    }
                  }}
                />
              </div>
            )}

          </div>

        </div>
      )}

    </div>
  );
}

export default FilterListNext;
