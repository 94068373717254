// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './ModalsAndPanels.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import WellBeingModal from '../../WellBeingModal';
import {
  ProfilePanel,
  EditProfilePanel,
  EditCandidateProfilePanel,
  Toast,
  Modal,
} from 'ui/basic';


// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import {MAPPED_PLATFORM_ROLES} from 'utils/configuration/const/roles';
import {WELL_BEING_MODES} from 'utils/configuration/const/well-being';


// STORE OLD
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentUser,
  initAddCompanyUsers,
  hideToast,
  getWellBeingIndexHistory,
} from 'store/actions';
import * as fromWellBeingSelectors from 'store/selectors/wellBeing';


// CONFIG & DATA
// const Config = {};

// COMPONENT: ModalsAndPanels
const ModalsAndPanels = (props) => {
  // PROPS
  const {
    profileIsOpen,
    onProfileClose,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();

  // CURRENT USER
  const currentUser = useSelector(state => state.currentUser);

  // WELLBEING MODAL
  const [showWellBeingModal, setShowWellBeingModal] = useState(false);
  const wellBeingHistory = useSelector(fromWellBeingSelectors.getWellBeingHistory);
  useEffect(() => {
    if (currentUser.canAccessWellBeing && currentUser.firstName) {
      dispatch(getWellBeingIndexHistory(WELL_BEING_MODES.DAILY));
    }
  }, [dispatch, currentUser.canAccessWellBeing, currentUser.firstName]);
  useEffect(() => {
    if (wellBeingHistory) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      //searching for well-being index which was submitted today
      const wellBeingIndexToday = wellBeingHistory.find(history => {
        const historyDate = history.date;
        historyDate.setHours(0, 0, 0, 0);

        return today.getTime() === historyDate.getTime();
      });

      //getting well-being index date from local storage;
      let wellBeingIndexShownToday;

      if (localStorage.getItem('wellBeingIndexData')) {
        const wellBeingIndexData = JSON.parse(localStorage.getItem('wellBeingIndexData'));
        const currentUserWellBeingIndexData = wellBeingIndexData.find(d => d.email === currentUser.mail);

        if (currentUserWellBeingIndexData) {
          const wellBeingIndexDate = new Date(currentUserWellBeingIndexData.date);
          wellBeingIndexDate.setHours(0, 0, 0, 0);

          wellBeingIndexShownToday = (
            wellBeingIndexDate &&
            wellBeingIndexDate.getTime() === today.getTime()
          );
        }
      }
      //show well-being modal if no data in local storage and no data was fetched from back-end;
      if (currentUser.canAccessWellBeing && !wellBeingIndexToday && !wellBeingIndexShownToday) {
        setShowWellBeingModal(true);
      }
    }
  }, [wellBeingHistory, currentUser.mail, currentUser.canAccessWellBeing]);

  // PROFILE PANEL
  const [showProfilePanel, setShowProfilePanel] = useState(false);
  const [showEditProfilePanel, setShowEditProfilePanel] = useState(false);
  useEffect(() => {
    setShowProfilePanel(profileIsOpen);
  }, [profileIsOpen]);

  // PROFILE PANEL - UPDATE USER
  const [updateUserConfirmationModalVisible, setUpdateUserConfirmationModalVisible] = useState();
  const [updateUserData, setUpdateUserData] = useState();
  const [updateUserProcessing, setUpdateUserProcessing] = useState();
  const updateUser = () => {
    setUpdateUserProcessing(true);
    api.post('core/user/update', updateUserData)
      .then(({ok, status}) => {
        setUpdateUserProcessing(false);
        if (ok && status === 200) {
          dispatch(getCurrentUser());
          setUpdateUserConfirmationModalVisible(false);
          setShowEditProfilePanel(false);
        }
      })
      .catch(error => {
        console.error(error.message);
        setUpdateUserProcessing(false);
      })
  }


  // TOAST
  const toastVisible = useSelector(state => state.toast.isVisible);
  const toastShouldTranslate = useSelector(state => state.toast.shouldTranslate);
  let toastTitle = useSelector(state => state.toast.title);
  let toastContent = useSelector(state => state.toast.content);
  if (toastShouldTranslate) {
    toastTitle = translate(toastTitle);
    toastContent = translate(toastContent);
  }

  // EMPLOYEES INVITED MESSAGE
  const [employeesInvitedMsgVisible, setEmployeesInvitedMsgVisible] = useState(false);
  const addCompanyUsersSuccess = useSelector(state => state.admin.addCompanyUsersSuccess);
  useEffect(() => {
    if (addCompanyUsersSuccess) {
      setEmployeesInvitedMsgVisible(true);
    }
  }, [addCompanyUsersSuccess]);


  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: ModalsAndPanels
  return (
    <div className={classNames(styles.modalsAndPanels)}>

      {showWellBeingModal &&
       <WellBeingModal onClose={() => setShowWellBeingModal(false)}/>
      }

       {showProfilePanel &&
       <ProfilePanel
         onClose={() => {
           setShowProfilePanel(false)
           onProfileClose();
         }}
         onEditProfile={() => {
           setShowProfilePanel(false);
           onProfileClose();
           setShowEditProfilePanel(true);
         }}
       />
       }

      {showEditProfilePanel && (
        currentUser.role === MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE ?
          <EditCandidateProfilePanel
            showConfirmModal={data => {
              setUpdateUserData(data);
              setUpdateUserConfirmationModalVisible(true);
            }}
            onClose={() => setShowEditProfilePanel(false)}
          /> :
          <EditProfilePanel onClose={() => setShowEditProfilePanel(false)} />
      )}

       {toastVisible &&
       <Toast onClose={() => dispatch(hideToast()) }>
         <>
           <div className={styles.toastTitle}>{toastTitle}</div>
           <div className={styles.toastDescription}>{toastContent}</div>
         </>
       </Toast>
       }

       {employeesInvitedMsgVisible &&
       <Toast onClose={() => {
         setEmployeesInvitedMsgVisible(false)
         dispatch(initAddCompanyUsers());
       }}>
         <>
           <div className={styles.toastTitle}>
             {translate('invite_emp_success_title') || 'Employees invited successfully'}
           </div>
           <div className={styles.toastDescription}>
             {translate('invite_emp_success_descr') || 'Employees invited successfully'}
           </div>
         </>
       </Toast>
       }

      {/*UPDATE USER CONFIRMATION MODAL*/}
      {updateUserConfirmationModalVisible &&
      <Modal
        header={translate('unsaved_changes_modal_header') || 'Du hast ungespeicherte Änderungen'}
        redButtonTitle={translate('unsaved_changes_modal_discard_btn') || 'Änderungen verwerfen'}
        redButtonDisabled={updateUserProcessing}
        secondaryButtonTitle={translate('unsaved_changes_modal_save_btn') || 'Änderungen speichern'}
        secondaryButtonDisabled={updateUserProcessing}
        closeOnCancel={false}
        onConfirm={() => {
          setUpdateUserConfirmationModalVisible(false);
          setShowEditProfilePanel(false);
        }}
        onClose={(closeButtonClicked) => {
          if (!closeButtonClicked) {
            setUpdateUserConfirmationModalVisible(false);
          } else {
            updateUser();
          }
        }}
      >
        {translate('unsaved_changes_modal_copy') || 'Wie möchtest du fortfahren?'}
      </Modal>
      }
    </div>
  );
};

export default ModalsAndPanels;
