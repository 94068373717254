// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionCareerLevel.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {AssessmentRadioButtonGroup, Button, Modal} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import {useSelector} from 'react-redux';
import * as fromSelectors from 'store/selectors/staticValues';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionCareerLevel
const QuestionCareerLevel = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
    setRenderOutside = () => {},
   } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const careerLevelOptions = useSelector(fromSelectors.selectCareerLevelOptions);
  const [ careerLevel, setCareerLevel ] = useState('');
  useEffect(() => {
    setCareerLevel(answer.value);
  }, [answer.value]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleCareerLevel = (careerLevel) => {
    setCareerLevel(careerLevel);
    onAnswer({ value: careerLevel, name: 'careerLevel', isValid: !!careerLevel });
  };

  const helpModal = <Modal
    header={translate('assessment_help_info_title') || '💡 Hilfe bei der Auswahl'}
    secondaryButtonTitle={translate('close_lbl') || 'Schließen'}
    onClose={() => setRenderOutside()}
  >
    {translate('cp_careerlevel_help_modal_content') || 'Wähle deine aktuelle Karrierestufe. Orientiere dich dabei an den folgenden Beschreibungen:'}
  </Modal>;

  // RENDER: QuestionCareerLevel
  return (
    <div className={classNames(styles.questionCareerLevel)}>

      {/* FORM */}
      <div className={classNames(styles.formRow, styles.radioButtonGroupContainer)}>
        <div className={styles.assessmentRadioButtonGroup}>
          <AssessmentRadioButtonGroup
            name={'radio-button-group'}
            selectedValue={answer.value}
            items={careerLevelOptions}
            deselectable
            onChange={(value) => {
              console.log(value, careerLevel);
              if (value !== careerLevel) {
                console.log('set', value);
                handleCareerLevel(value);
              }
            }}
          />
        </div>
      </div>

      <div className={classNames(styles.formRow, styles.center)}>
        <Button
          looks='tertiary'
          onClick={() => setRenderOutside(helpModal)}
        >
          {translate('assessment_help_button') || 'Hilfe bei der Auswahl'}
        </Button>
      </div>

    </div>
  );
};

export default QuestionCareerLevel;
