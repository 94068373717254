import React, {useCallback, useEffect, useState} from 'react';
import styles from './NineLevelsReport.module.scss';

import { useTranslate } from 'utils/translator';
import {useDebounce, useWindowWidth} from 'utils/hooks';
import {NINE_LEVELS_ORDER} from 'utils/configuration/const/assessment-types';
import {isValid} from 'utils/numbers';
import {getResistanceText} from 'utils/nineLevels';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';


import {Icons} from 'assets/icons';
import {ReactComponent as InfoBlue} from 'assets/icons/icn_info_blue.svg';

import {SIGNATURE_VALUES} from './signatureValues';
import {Icon, Link, Callout, CollapsibleNext, Modal} from 'ui/basic';
import NineLevelsDiagram from 'ui/molecules/NineLevelsResult/NineLevelsDiagram';
import {NineLevelsResult} from 'ui/molecules';


const CONTENTS = [
  {id: 'general-info', name: '9levels_report_subtitle_general_info', altName: 'Allgemeine Informationen'},
  {id: 'classify-results', name: '9levels_report_subtitle_classify_results', altName: 'Die Ergebnisse richtig einordnen'},
  {id: 'quick-results', name: '9levels_report_subtitle_results_ataglance', altName: 'Die Ergebnisse auf einen Blick'},
  {id: 'detailed-results', name: '9levels_report_subtitle_results_detailed', altName: 'Die Ergebnisse im Detail'},
  {id: 'other-details', name: '9levels_report_subtitle_other_details', altName: 'Weitere Hintergründe '}
];

const DIMENSION_BLOCKS = [
  {
    label: '9levels_report__level__signature_values', altLabel: 'Kennzeichnende Werte',
    description: '9levels_report_*_signature_values', altDescription: 'Kennzeichnende Werte'
  },
  {
    label: '9levels_report__level__characteristics', altLabel: 'Charaktermerkmale',
    description: '9levels_report_*_characteristics', altDescription: 'Charaktermerkmale'
  },
  {
    label: '9levels_report__level__groups_strengths', altLabel: 'Stärken in Gruppen und Organisationen',
    description: '9levels_report_*_group_strengths', altDescription: 'Stärken in Gruppen und Organisationen'},
  {
    label: '9levels_report__level__ideal_work_env', altLabel: 'Ideales Arbeitsumfeld',
    description: '9levels_report_*_ideal_work_env', altDescription: 'Ideales Arbeitsumfeld'
  },
  {
    label: '9levels_report__level__leadership', altLabel: 'Führung und Machtdistanz',
    description: '9levels_report_*_leadership', altDescription: 'Führung und Machtdistanz'
  },
  {
    label: '9levels_report__level__org_forms', altLabel: 'Organisationsformen',
    description: '9levels_report_*_org_forms', altDescription: 'Organisationsformen'
  }
];

const GENERAL_INFO_DESCRIPTION =
  'Unsere individuellen Werte beeinflussen unser Denken, Fühlen und Handeln. So wirken unsere Werte in das Umfeld hinein,' +
  ' in dem wir uns bewegen. ​Das Wissen um diese Wertesysteme ermöglicht uns, Passung und Entwicklung zu anderen Menschen,' +
  ' Teams und Organisationen zu verstehen.';

const RESEARCH_DEFAULT_TEXT =
  `Das Modell der 9 Levels basiert auf den Erkenntnissen von Professor Clare W. Graves und seiner über 20-jährigen Forschungsarbeit.
   Clare W. Graves (1914 –1986) war Professor für Psychologie am Union College in New York (USA). Sein Engagement galt nicht nur der Forschung,
   sondern er war auch jahrelang als Berater in Wirtschaftsunternehmen, Kliniken und Bildungsinstituten tätig.
   Den eigentlichen Impuls zu seinen Forschungen gab ein Student, als dieser Graves fragte, wer denn nun von all den vielen Theoretikern
   (Maslow, Freud, Jung, Rogers, Watson etc.) Recht habe.
   Graves kombinierte in seiner Theorie und Forschung vier verschiedene wissenschaftliche Disziplinen:
    - Biologie und Neurobiologie
    - Psychologie und die Theorie der Persönlichkeitstypen
    - Soziologie und Anthropologie
    - Systemtheorie`;

const STRONG_VALUE = 75;

const WE_AND_I_DIAGRAM_HEIGHT = 15;

const I_LEVELS = ['levels_red', 'levels_orange', 'levels_yellow'];
const WE_LEVELS = ['levels_purple', 'levels_blue', 'levels_green', 'levels_turquoise'];


const NineLevelsReport = (props) => {
  const {results, signatureValues, onClose} = props;

  const translate = useTranslate();

  const [mainNode, setMainNode] = useState();
  const [mainNodeWidth, setMainNodeWidth] = useState();
  const [infoPageVisible, setInfoPageVisible] = useState();
  const [infoPageTitle, setInfoPageTitle] = useState();
  const [infoPageDescription, setInfoPageDescription] = useState();

  const getIValue = () => {
    const generalSum = results
      .map(resultItem => resultItem.result[1])
      .reduce((result, current) => result + current);

    const sum = results
      .filter(resultItem => I_LEVELS.includes(resultItem.id))
      .map(resultItem => resultItem.result[1])
      .reduce((result, current) => result + current);

    let result = 0;
    if (isValid(generalSum) && generalSum !== 0) {
      result = sum * 100 / generalSum;
    }

    return Math.round(result);
  };

  const getWeValue = () => {
    const generalSum = results
      .map(resultItem => resultItem.result[1])
      .reduce((result, current) => result + current);

    const sum = results
      .filter(resultItem => WE_LEVELS.includes(resultItem.id))
      .map(resultItem => resultItem.result[1])
      .reduce((result, current) => result + current);

    let result = 0;
    if (isValid(generalSum) && generalSum !== 0) {
      result = sum * 100 / generalSum;
    }

    return Math.round(result);
  };

  const getHighestResistance = () => {
    if (!results) {
      return {name: ''};
    }

    //resistance values sorted ascending -> -100, -99, -88, etc.
    const sortedResultValues = [...results].sort((item1, item2) => item1.result[0] - item2.result[0]);
    let highestResistance = sortedResultValues[0];

    for(let i=1; i<sortedResultValues.length; i++) {
      const highResistanceOrder = NINE_LEVELS_ORDER[highestResistance.id.split('levels_')[1].toLowerCase()];
      const potentialHighResistanceOrder = NINE_LEVELS_ORDER[sortedResultValues[i].id.split('levels_')[1].toLowerCase()];

      if (sortedResultValues[i].result[0] === highestResistance.result[0] && (potentialHighResistanceOrder > highResistanceOrder)) {
        highestResistance = sortedResultValues[i];
      }
    }

    return highestResistance ||  {name: ''};
  };

  const getStrongestLevels = () => {
    let localResults = [...results];

    const highValues = localResults
      .sort((itemA, itemB) => itemB.result[1] - itemA.result[1])
      .filter(resultItem => resultItem.result[1] && resultItem.result[1] >= STRONG_VALUE);

    //if there are no values >= 75, 2 highest values should be shown even if they are less than 75
    if (!highValues || (highValues && highValues.length === 0)) {
      return localResults
        .sort((itemA, itemB) => itemB.result[1] - itemA.result[1])
        .slice(0, 2);
    }

    //if there is only 1 value >= 75, one more value should be shown even if it is less than 75
    if (highValues && highValues.length === 1) {
      const localResult = localResults.filter(resultItem => resultItem.result[1] && resultItem.result[1] < STRONG_VALUE)
        .sort((itemA, itemB) => itemB.result[1] - itemA.result[1])
        .slice(0, 1);

      return [...highValues, ...localResult];
    }

    return highValues;
  };

  const getStrongestLevel = () => {
    const strongestLevels = getStrongestLevels();
    let strongestLevel = strongestLevels[0];

    for(let i=1; i<strongestLevels.length; i++) {
      const strongestLevelOrder = NINE_LEVELS_ORDER[strongestLevel.id.split('levels_')[1].toLowerCase()];
      const potentialStrongestLevelOrder = NINE_LEVELS_ORDER[strongestLevels[i].id.split('levels_')[1].toLowerCase()];

      if (strongestLevels[i].result[1] === strongestLevel.result[1] && (potentialStrongestLevelOrder > strongestLevelOrder)) {
        strongestLevel = strongestLevels[i];
      }
    }

    return strongestLevel ||  {name: ''};
  };

  const getHighestSignatureValues = () => {
    if (!signatureValues || signatureValues.length === 0) {
      return [];
    }

    return signatureValues.find(resultItem => resultItem.id === 'levels_highvalues').result.split(',');
  };

  const getOtherSignatureValues = () => {
    const strongestLevel = getStrongestLevel();

    if (!strongestLevel || !strongestLevel.id || !SIGNATURE_VALUES[strongestLevel.id]) {
      return [];
    }

    return SIGNATURE_VALUES[strongestLevel.id].filter(signatureValue => !getHighestSignatureValues().includes(signatureValue));
  };

  const getHighestResSignatureValues = () => {
    if (!signatureValues || signatureValues.length === 0) {
      return [];
    }

    return signatureValues.find(resultItem => resultItem.id === 'levels_res_highvalues').result.split(',');
  };

  const getOtherResSignatureValues = () => {
    const resistanceValue = getHighestResistance();

    if (!resistanceValue || !resistanceValue.id || !SIGNATURE_VALUES[resistanceValue.id]) {
      return [];
    }

    return SIGNATURE_VALUES[resistanceValue.id].filter(signatureValue => !getHighestResSignatureValues().includes(signatureValue));
  };

  const getWeAndIExplainer = () => {
    if (getIValue() > 55) {
      return '9levels_report__we_and_i__stronger_i';
    } else if ((getIValue() >= 45 && getIValue() <= 55) && (getWeValue() >= 45 && getWeValue() <= 55)) {
      return '9levels_report__we_and_i__equal';
    } else {
      return '9levels_report__we_and_i__stronger_we';
    }
  };

  const mainRef = useCallback(node => {
    if (node) {
      setMainNode(node);
    }
  }, []);

  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);
  useEffect(() => {
    if (mainNode) {
      setMainNodeWidth(mainNode.clientWidth);
    }
  }, [debouncedWindowWidth, mainNode]);

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, []);

  return (
    <div className={styles.nineLevelsReport}>
      {/*HEADER*/}
      <div className={styles.header}>
        <span>{translate('9levels_report_header_title') || '9 Levels - Report'}</span>
        <div
          className={styles.close}
          onClick={onClose}
        >
          <Icon icon={Icons.CloseBig}/>
        </div>
      </div>
      {/*BODY*/}
      <div className={styles.bodyContainer} id='body'>
        <div className={styles.body}>
          <div className={styles.main} ref={mainRef}>
            <div className={styles.mTitle}>{translate('9levels_report_title') || '9 Levels - Report'}</div>
            <div className={styles.contents}>
              {CONTENTS.map((content, index) => (
                <Link
                  key={`content-${index}`}
                  type={'anchor'}
                  onClick={() => {
                    const offsetTop = document.getElementById(content.id).offsetTop;
                    document.getElementById('body').scrollTo({
                      top: offsetTop - 60, //60 - height of header
                      behavior: 'smooth'
                    })
                  }}
                >
                  {translate(content.name) || content.altName}
                </Link>
              ))}
            </div>
            {/*GENERAL INFO*/}
            <div className={styles.generalInfo} id={'general-info'}>
              <div className={styles.sTitle}>
                {translate('9levels_report_subtitle_general_info') || 'Allgemeine Informationen'}
              </div>
              <div className={styles.description}>
                {translate('9levels_report_copy_general_info') || GENERAL_INFO_DESCRIPTION}
              </div>
            </div>

            {/*CLASSIFY RESULTS*/}
            <div className={styles.classifyResults} id={'classify-results'}>
              <div className={styles.sTitle}>
                {translate('9levels_report_subtitle_classify_results') || 'Die Ergebnisse richtig einordnen'}
              </div>
              <div className={styles.callout}>
                <Callout trianglePosition={'left'}>
                  {translate('9levels_report_callout_classify_results') || GENERAL_INFO_DESCRIPTION}
                </Callout>
              </div>
              <div className={styles.description}>
                {translate('9levels_report_copy_classify_results') || GENERAL_INFO_DESCRIPTION}
              </div>
            </div>

            {/*RESULTS AT A GLANCE*/}
            <div className={styles.quickResults} id={'quick-results'}>
              <div className={styles.sTitle}>
                {translate('9levels_report_subtitle_results_ataglance') || 'Die Ergebnisse auf einen Blick'}
              </div>
              <div className={styles.diagram}>
                <NineLevelsResult results={results} forReport={true} showReportButton={false} />
              </div>
              <Callout>
                {translate('9levels_report_callout_results_ataglance') ||
                  'Die Werte im rechten Chart-Teil sind deine Ausprägungen der Levels. Im linken Teil sind deine Widerstandswerte.'
                }
              </Callout>

              {/* sub block 1 */}
              <div className={styles.subBlock1}>
                <div className={styles.xsTitle}>
                  {translate('9levels_report_results_ataglance_subblock1') || 'Level mit der höchsten Ausprägung'}
                </div>
                {getStrongestLevels()
                  .map(resultItem => (
                    <Link
                      key={resultItem.id}
                      type={'anchor'}
                      onClick={() => {
                        const offsetTop = document.getElementById(resultItem.id).offsetTop;
                        document.getElementById('body').scrollTo({
                          top: offsetTop - 60, //60 - height of header
                          behavior: 'smooth'
                        })
                      }}
                    >
                      {translate(`levels_results_label_${resultItem.id.split('_').pop()}`) || resultItem.name}
                      <span>{` (${resultItem.result[1]}/100)`}</span>
                    </Link>
                  ))
                }
              </div>

              {/* sub block 2 */}
              <div className={styles.subBlock2}>
                <div className={styles.xsTitle}>
                  {translate('9levels_report_results_ataglance_subblock2') || 'Deine wichtigsten Werte für dein stärkstes Level:'}
                </div>
                <div className={styles.description}>
                  {getHighestSignatureValues()
                    .map((result, index) => (
                      <span key={index}>{`${index + 1}. ${translate(`9levels_report_${result.trim()}`) || result}`}</span>
                    ))
                  }

                  <div className={styles.subDescription}>
                    {translate('9levels_report_results_ataglance_subblock2_descr', [
                      '{{levels}}',
                      getOtherSignatureValues().map(value => translate(`9levels_report_${value}`)).join(', ')
                    ]) || 'Zur Auswahl standen außerdem noch: Individualität, Systemische Integration'
                    }
                  </div>
                </div>
              </div>

              {/* sub block 3 */}
              <div className={styles.subBlock3}>
                <div className={styles.xsTitle}>
                  {translate('9levels_report_results_ataglance_subblock3') || 'Stärkster Widerstand'}
                </div>
                <div className={styles.description}>
                  {translate('9levels_report_results_ataglance_subblock3_descr', ['{{level}}', getHighestResistance().name]) || (
                    `Du hast den höchsten Widerstand gegenüber Level ${getHighestResistance().name}.`
                  )}

                  <div className={styles.subDescription}>
                    {getHighestResSignatureValues()
                      .map((result, index) => (
                        <span key={index}>{`${index + 1}. ${translate(`9levels_report_${result.trim()}`) || result}`}</span>
                      ))
                    }
                  </div>

                  <div className={styles.subDescription}>
                    {translate('9levels_report_results_ataglance_subblock2_descr', [
                      '{{levels}}',
                      getOtherResSignatureValues().map(value => translate(`9levels_report_${value}`)).join(', ')
                    ]) ||
                    ('Zur Auswahl standen außerdem noch: Respekt von Tabus, Gehorsam, Schutz, Opferbereitschaft  ')
                  }
                  </div>
                </div>
              </div>

              {/* sub block 4 */}
              <div className={styles.subBlock4}>
                <div className={styles.xsTitle}>
                  {translate('9levels_report_we_and_i') || 'Wir & Ich'}
                </div>
                <div className={styles.description}>
                  <div className={styles.subDescription}>
                    {translate(getWeAndIExplainer()) || 'Die Messung ergibt, dass du einen höheren ICH-Bezug aufweist.' +
                    ' Die Levels ROT, ORANGE, GELB sind stärker Ich-orientiert, während die anderen Level Wir-orientiert sind.'}
                  </div>
                </div>
                <div className={styles.diagramBlock}>
                  <div className={styles.diagramHeader}>
                    {translate('9levels_report__we_and_i__diagram_title') || 'Wir Bezug & Ich Bezug'}
                  </div>
                  <svg width={mainNodeWidth} height={WE_AND_I_DIAGRAM_HEIGHT}>
                    <rect x={0} width={mainNodeWidth} height={WE_AND_I_DIAGRAM_HEIGHT} rx={WE_AND_I_DIAGRAM_HEIGHT / 2} fill={styles.colorGrey4} />
                    <rect
                      x={(getIValue() && mainNodeWidth) && getIValue() * Number(mainNodeWidth)  / 100}
                      width={6}
                      height={WE_AND_I_DIAGRAM_HEIGHT}
                      fill={styles.colorPrimary1}
                    />
                  </svg>
                  <div className={styles.diagramFooter}>
                    <span>
                      {translate('9levels_report__we_and_i__diagram_footer_left') || 'Wir Bezug:'}
                      {` ${getWeValue()}%`}
                    </span>
                    <span>
                      {translate('9levels_report__we_and_i__diagram_footer_right') || 'Ich Bezug:'}
                      {` ${getIValue()}%`}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/*DETAILED RESULTS*/}
            <div className={styles.detailedResults} id={'detailed-results'}>
              <div className={styles.sTitle}>
                {translate('9levels_report_subtitle_results_detailed') || 'Die Ergebnisse im Detail'}
              </div>
              <div className={styles.collapsiblePanels}>
                {[...results]
                  .sort((item1, item2) => item2.result[1] - item1.result[1])
                  .map((resultItem, index) => {
                    return (
                      <CollapsibleNext
                        id={resultItem.id} key={index}
                        header={translate(`levels_results_label_${resultItem.id.split('_')[1]}`) || resultItem.name.toUpperCase()}
                        hasBoldHeader
                      >
                        <div className={styles.collapsiblePanelBody}>
                          <div className={styles.description}>
                            {translate(`9levels_report_${resultItem.id.split('_').pop()}_descr`) ||
                            'Der Mensch im Level TÜRKIS sieht sich als Teil eines sozialen Systems, als Mitglied einer Gemeinschaft, eines Clans, ' +
                            'eines Tribes, mit dem Patriarchen, dem Häuptling, als Anführer.'}
                          </div>
                          <div className={styles.resultScale}>
                            <div className={styles.title}>
                              {translate('9levels_report__level__results_scale') || 'Ergebnis-Skala'}
                            </div>

                            <div className={styles.diagram}>
                              <div className={styles.diagramHeader}>
                                {translate(`levels_results_label_${resultItem.id.split('_').pop()}`) || resultItem.name}
                                <InfoBlue onClick={() => {
                                  setInfoPageTitle(translate(`${resultItem.id}_info_title`) || 'Detailed result title');
                                  setInfoPageDescription(translate(`${resultItem.id}_info_description`) || 'Detailed result description');
                                  setInfoPageVisible(true);
                                }} />
                              </div>

                              <NineLevelsDiagram
                                width={Number(mainNodeWidth) - 48} //48(16 + 32) = left/right paddings
                                results={resultItem.result}
                                color={resultItem.id.split('levels_')[1]}
                              />

                              <div className={styles.footer}>
                                <span>{resultItem.result[0]}</span>
                                <span>{resultItem.result[1]}</span>
                              </div>
                            </div>
                          </div>

                          <div className={styles.blocks}>
                            {DIMENSION_BLOCKS.map((block, index) => (
                              <div className={styles.block} key={index}>
                                <div className={styles.label}>
                                  {translate(block.label) || block.altLabel}
                                </div>
                                <div className={styles.description}>
                                  {translate(block.description.replace('*', resultItem.id.split('_').pop())) ||
                                  block.altDescription
                                  }
                                </div>
                              </div>
                            ))}

                            <div className={styles.block}>
                              <div className={styles.label}>
                                {translate('9levels_report__level__resistance') || 'Widerstand'}
                              </div>
                              <div className={styles.description}>
                                {translate(getResistanceText(resultItem.result[0]), [
                                  '{{level}}', translate(`levels_results_label_${resultItem.id.split('_').pop()}`),
                                  '{{points}}', resultItem.result[0]
                                ]) ||
                                'Dein Widerstand gegenüber PURPUR ist mit 41 Punkten mittel.'}
                              </div>
                            </div>
                          </div>

                        </div>
                      </CollapsibleNext>
                    )
                  })
                }
              </div>
            </div>

            {/*OTHER DETAILS*/}
            <div className={styles.otherDetails} id={'other-details'}>
              <div className={styles.sTitle}>{translate('9levels_report_subtitle_other_details') || 'Weitere Hintergründe (opened state)'}</div>
              <div className={styles.collapsiblePanels}>
                <CollapsibleNext
                  header={translate('9levels_report_graves_research') || 'Graves Forschung'}
                >
                  {translate('9levels_report_graves_research_descr') || RESEARCH_DEFAULT_TEXT}
                </CollapsibleNext>
                <CollapsibleNext
                  header={translate('9levels_report_application') || 'Anwendung'}
                >
                  {translate('9levels_report_application_descr') || RESEARCH_DEFAULT_TEXT}
                </CollapsibleNext>
              </div>
            </div>
          </div>
        </div>
      </div>

      {infoPageVisible &&
        <Modal
          header={infoPageTitle}
          secondaryButtonTitle={translate('okay_lbl')}
          controlScrollbar={false}
          onClose={() => setInfoPageVisible(false)}
        >
          {infoPageDescription}
        </Modal>
      }
    </div>
  )
};

export default NineLevelsReport;
