import React, {useEffect, useState} from 'react';
import styles from './Toast.module.scss';
import {ReactComponent as CloseIcon} from 'assets/icons/icn_close_small.svg';
import BluCSSTransition from 'ui/basic/containers/BluCSSTransition';

export const VISIBILITY_TIME = 10000; //milliseconds


const Toast = (props) => {
  const {
    children,
    visibilityTime = VISIBILITY_TIME,
    headline,
    message,
    onClose = () => {},
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false);
    }, visibilityTime);

    setTimeout(() => {
      onClose();
    }, visibilityTime + Number(styles.animationDurationMs));
  }, [onClose, visibilityTime]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <BluCSSTransition in={isVisible} classNames={{...styles}}>
      <div className={styles.toast}>
        <CloseIcon onClick={() => {
          setIsVisible(false);
          setTimeout(() => {
            onClose();
          }, Number(styles.animationDurationMs))
        }}/>
        <div className={styles.content}>
          {/* HEADLINE & MESSAGE */}
          { (headline || message) && (
            <>
              <div className={styles.headline}>
                { headline }
              </div>
              <div className={styles.message}>
                { message }
              </div>
            </>
          )}

          {/* CHILDREN */}
          {children}

        </div>
      </div>
    </BluCSSTransition>
  )
};

export default Toast;
