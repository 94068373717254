import React, {memo, useEffect, useState} from 'react';
import styles from './AssessmentRadioButton.module.scss';
import classnames from 'classnames';


const AssessmentRadioButton = memo((props) => {
  const {
    isError,
    disabled,
    checked,
    number,
    value,
    title,
    onChange,
    onDeselect,
    showAnimation
  } = props;
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    if (checked) {
      setTimeout(() => {
        setStartAnimation(showAnimation);
      }, 500)
    } else {
      setStartAnimation(showAnimation);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnimation]);

  const classNames = classnames(
    styles.assessmentRadioButton,
    {[styles.error]: isError},
    {[styles.disabled]: disabled},
    {[styles.checked]: checked},
    {[styles.animation]: startAnimation}
  );

  const handleItem = () => {
    if (checked && onDeselect) {
      onDeselect();
      if (onChange) {
        onChange();
      }
    }
    if (!checked && onChange) {
      onChange(value);
    }
  };

  const handleKey = (event) => {
    const key = event.key;

    switch (key) {
      case ' ': {
        event.stopPropagation();
        handleItem();
        return;
      }
      default: {
        return;
      }
    }
  };

  return (
    <label tabIndex={0}
      className={classNames}
      onClick={handleItem}
      onKeyUp={handleKey}
      >
      <div className={styles.name}>
        {number && <span className={styles.number}>{number}</span>}
        {title}
      </div>
      <span className={styles.checkmark}></span>
    </label>
  );
});

AssessmentRadioButton.defaultProps = {
  disabled: false,
  name: '',
  title: ''
};

export default AssessmentRadioButton;
