import React from 'react';

// INTERMISSIONS
import { QuestionIntro } from '../components/profile-completion/QuestionIntro';
import { QuestionName } from '../components/profile-completion/QuestionName';
import { QuestionLocation } from '../components/profile-completion/QuestionLocation';
import { QuestionNationality } from '../components/profile-completion/QuestionNationality';
import { QuestionGender } from '../components/profile-completion/QuestionGender';
import { QuestionAge } from '../components/profile-completion/QuestionAge';
import { QuestionDegree } from '../components/profile-completion/QuestionDegree';
import { QuestionCareerLevel } from '../components/profile-completion/QuestionCareerLevel';
import { QuestionPosition } from '../components/profile-completion/QuestionPosition';
import { QuestionJobFamily } from '../components/profile-completion/QuestionJobFamily';
import { QuestionIndustry } from '../components/profile-completion/QuestionIndustry';
import { QuestionAspects } from '../components/profile-completion/QuestionAspects';
import { QuestionRequirements } from '../components/profile-completion/QuestionRequirements';
import { QuestionStartDate } from '../components/profile-completion/QuestionStartDate';
import { QuestionPicture } from '../components/profile-completion/QuestionPicture';
import { QuestionPolicy } from '../components/profile-completion/QuestionPolicy';

// OTHER COMPONENTS

// Find career level question and check whether the user is a career starter (value: 1)
const isOptionalJobQuestion = (state = {}) => {
  let { answers = {} } = state;
  answers = Object.values(answers);

  return Boolean(answers.find((el) => el?.name === 'careerLevel' && el?.value === 1));
};

export const candidateProfileCompletionConfig = {
  id: 'candidate',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 0,
  pages: [
    // INTRO
    {
      isIntermission: true,
      title: (
        <>
          Hallo,
          <br/>
          schön dass du da bist.
          Bevor wir loslegen müssen wir dich ein wenig besser Kennenlernen.
        </>
      ),
      render: (next, prev, state, answer, handleClosePrompt, setNewAnswer, setModalCancelShow) => (
        <QuestionIntro
          answer={answer}
          setModalCancelShow={setModalCancelShow}
          onNext={next}
        />
      )
    },
    // NAME
    {
      noPrev: true,
      title: 'cp_name_question',
      Component: QuestionName
    },
    // LOCATION
    {
      title: 'cp_residence_question',
      Component: QuestionLocation
    },
    // NATIONALITY
    {
      isOptional: true,
      title: 'cp_nationality_question',
      callout: true,
      Component: QuestionNationality,
    },
    // GENDER
    {
      isOptional: true,
      title: 'cp_gender_question',
      callout: true,
      Component: QuestionGender,
    },
    // AGE
    {
      isOptional: true,
      title: 'cp_age_question',
      callout: true,
      Component: QuestionAge,
    },
    // DEGREE
    {
      title: 'cp_school_degree_question',
      callout: true,
      Component: QuestionDegree,
    },
    // CAREER LEVEL
    {
      title: 'cp_careerlevel_question',
      Component: QuestionCareerLevel
    },
    // POSITION
    {
      isOptional: isOptionalJobQuestion,
      title: 'cp_current_position_question',
      titleOptional: 'cp_current_position_question_optional',
      Component: QuestionPosition,
    },
    // JOB FAMILY
    {
      isOptional: isOptionalJobQuestion,
      title: 'cp_job_family_question',
      titleOptional: 'cp_job_family_question_optional',
      Component: QuestionJobFamily,
    },
    // INDUSTRY
    {
      isOptional: isOptionalJobQuestion,
      title: 'cp_industry_experience_question',
      titleOptional: 'cp_industry_experience_question_optional',
      Component: QuestionIndustry,
    },
    // REQUIREMENTS
    {
      title: 'cp_job_requirements_question',
      Component: QuestionRequirements,
    },
    // MOTIVATION
    {
      isOptional: true,
      title: 'cp_motivation_question',
      Component: QuestionAspects,
    },
    // START DATE
    {
      title: 'cp_start_question',
      Component: QuestionStartDate
    },
    // PICTURE
    {
      isOptional: true,
      title: 'cp_image_question',
      Component: QuestionPicture
    },
    // CONFIRM POLICY
    {
      title: 'cp_policy',
      titleForHasExternalLegalAgreements: 'cp_policy_external',
      Component: QuestionPolicy
    },
    // END
    {
      isIntermission: true,
      showProgressBar: true,
      render: (next, prev, state, answer, handleClosePrompt, finishable, handleFinish, errorEnd) => {
        return (
          <>
            {/*/!* ERROR END *!/*/}
            { errorEnd }
          </>
        );
      }
    }
  ]
};
