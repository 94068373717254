// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './CandidateUserGroup.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: CandidateUserGroup
const CandidateUserGroup = () => {
  // PROPS

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: CandidateUserGroup
  return (
    <div className={classNames(styles.candidateUserGroup)}>
      <div className={styles.content}>
        <div className='blu-typeXxs'>
          {translate('candidates_nav') || 'Kandidat:innen'}
        </div>

        <div className={classNames('blu-typeCopy', 'marginTopXs')}>
          {translate('candidate_usergroup_copy')}
        </div>
      </div>
    </div>
  );
};

export default CandidateUserGroup;
