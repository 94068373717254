export const INIT_SUBMIT_WELLBEING_INDEX = 'INIT_SUBMIT_WELLBEING_INDEX';
export const SUBMIT_WELLBEING_INDEX = 'SUBMIT_WELLBEING_INDEX';
export const SUBMIT_WELLBEING_INDEX_FULFILLED = 'SUBMIT_WELLBEING_INDEX_FULFILLED';
export const SUBMIT_WELLBEING_INDEX_REJECTED = 'SUBMIT_WELLBEING_INDEX_REJECTED';

export const GET_WELLBEING_HISTORY = 'GET_WELLBEING_HISTORY';
export const GET_WELLBEING_HISTORY_FULFILLED = 'GET_WELLBEING_HISTORY_FULFILLED';
export const GET_WELLBEING_HISTORY_REJECTED = 'GET_WELLBEING_HISTORY_REJECTED';

export const GET_WELLBEING_INDEX_HISTORY = 'GET_WELLBEING_INDEX_HISTORY';
export const GET_WELLBEING_INDEX_HISTORY_FULFILLED = 'GET_WELLBEING_INDEX_HISTORY_FULFILLED';
export const GET_WELLBEING_INDEX_HISTORY_REJECTED = 'GET_WELLBEING_INDEX_HISTORY_REJECTED';

export const GET_WELLBEING_INDEX = 'GET_WELLBEING_INDEX';
export const GET_WELLBEING_INDEX_FULFILLED = 'GET_WELLBEING_INDEX_FULFILLED';
export const GET_WELLBEING_INDEX_REJECTED = 'GET_WELLBEING_INDEX_REJECTED';

export const GET_ORG_WELLBEING_INDEX = 'GET_ORG_WELLBEING_INDEX';
export const GET_ORG_WELLBEING_INDEX_FULFILLED = 'GET_ORG_WELLBEING_INDEX_FULFILLED';
export const GET_ORG_WELLBEING_INDEX_REJECTED = 'GET_ORG_WELLBEING_INDEX_REJECTED';

export const INIT_GET_ORG_WELLBEING_HISTORY = 'INIT_GET_ORG_WELLBEING_HISTORY';
export const GET_ORG_WELLBEING_HISTORY = 'GET_ORG_WELLBEING_HISTORY';
export const GET_ORG_WELLBEING_HISTORY_FULFILLED = 'GET_ORG_WELLBEING_HISTORY_FULFILLED';
export const GET_ORG_WELLBEING_HISTORY_REJECTED = 'GET_ORG_WELLBEING_HISTORY_REJECTED';
