import React, {useState} from 'react';
import styles from './RmpImport.module.scss';

import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';

import {getAssessmentResult, listAssessments} from 'store/actions';

import * as api from 'api';

import { useTranslate } from 'utils/translator';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

import {InputNext, InputPassword, Link, Modal} from 'ui/basic';


const RmpImport = (props) => {
  const {onClose} = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const [rmpEmail, setRmpEmail] = useState();
  const [rmpPassword, setRmpPassword] = useState();
  const [rmpImportErrorMessage, setRmpImportErrorMessage] = useState();
  const [rmpImportProcessing, setRmpImportProcessing] = useState();
  const [rmpImportModalVisible, setRmpImportModalVisible] = useState(true);
  const [rmpImportSuccessModalVisible, setRmpImportSuccessModalVisible] = useState();

  const handleRmpReport = () => {
    setRmpImportProcessing(true);

    api.post(`/core/assessments/${ASSESSMENT_TYPES.RMP}/import`, {
      mail: rmpEmail,
      password: rmpPassword
    }).then(({ok, status, data}) => {
      setRmpImportProcessing(false);

      if (ok && status === 200) {
        setRmpImportModalVisible(false);
        setRmpImportSuccessModalVisible(true);

        dispatch(listAssessments());
        dispatch(getAssessmentResult(ASSESSMENT_TYPES.RMP));
      } else {
        let errorCode = 0;
        if (data.error && data.error.errorCode) {
          errorCode = data.error.errorCode;
        }

        switch (errorCode) {
          case 1407:
            setRmpImportErrorMessage(translate('rmp_import_invalid_credentials_error_msg'));
            break;
          case 1408:
            setRmpImportErrorMessage(translate('rmp_import_no_profile_error_msg'));
            break;
          default:
            setRmpImportErrorMessage(data.error ? data.error.errorMessage : '');
        }
      }
    }).catch(error => {
      setRmpImportProcessing(false);
      console.error(error.message);
    });
  };


  return (
    <div className={styles.rmpImport}>
      {rmpImportSuccessModalVisible &&
      <Modal
        header={translate('rmp_import_success_header') || 'Dein RMP-Profil wurde erfolgreich importiert.'}
        primaryButtonTitle={translate('assessment_view_results') || 'Ergebnisse ansehen'}
        onClose={onClose}
        onConfirm={() => {
          history.push('/my-profile');
          onClose();
        }}
      >
        {translate('rmp_import_success_copy') || 'Die Ergebnisse stehen nun in deinem Profil zur Verfügung'}
      </Modal>
      }

      {rmpImportModalVisible &&
      <Modal
        header={translate('rmp_import_header') || 'Importiere dein bestehendes Reiss Motivation Profile®'}
        primaryButtonTitle={translate('profile_import_lbl') || 'Profil importieren'}
        primaryButtonDisabled={!rmpEmail || !rmpPassword || rmpImportProcessing}
        secondaryButtonTitle={translate('cancel_lbl') || 'Abbrechen'}
        buttonsPosition={'column'}
        closeOnConfirm={false}
        onConfirm={handleRmpReport}
        onClose={onClose}
      >
        <div className={styles.content}>
          {translate('rmp_import_copy1') ||
          'Du kannst dein bestehenden Reiss Motivation Profile® importieren indem du dich via BLUQUIST bei RMP online anmeldest.' +
          ' Deine Daten werden nach erfolgreicher Anmeldung automatisch importiert.'
          }

          <div className={styles.loginData}>
            <div className={styles.label}>
              {translate('rmp_import_label') || 'Melde dich mit deinen RMP-Online Zugangsdaten an.'}
            </div>
            <div className={styles.copy}>
              {translate('rmp_import_copy2', ['{{link}}', '/support']) ||
              'Solltest du dein Passwort vergessen haben, kannst du es auf der RMP-Plattform zurücksetzen. Solltest du, trotz korrekter Zugangsdaten,' +
              'Probleme beim Zugriff auf dein RMP-Profil haben, hilft dir unser Support gerne weiter.'
              }
            </div>
            <div className={styles.inputs}>
              <InputNext
                label={translate('login_form_email_label') || 'E-Mail-Adresse'}
                errorMessage={rmpImportErrorMessage}
                onChange={value => setRmpEmail(value)}
              />
              <InputPassword
                placeHolder={translate('login_form_password_label') || 'Passwort'}
                onInputChanged={(value, correct) => setRmpPassword(value)}
              />
            </div>
          </div>

          <div className={styles.forgotPassword}>
            <Link to={'https://platform-auth.rmp-online.com/recovery'} openInNewTab>
              {translate('rmp_import_forgot_password_label') || 'Ich habe mein Passwort vergessen'}
            </Link>
          </div>

        </div>
      </Modal>
      }
    </div>
  )
};

export default RmpImport;
