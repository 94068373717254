import React, {useEffect, useState} from 'react';
import styles from './TeamRmpResult.module.scss';

import * as api from 'api'

import { useTranslate } from 'utils/translator';
import {useBreakpoint} from 'utils/hooks';
import { setDecimalSeperator } from 'utils/userpreference';
import {
  resultIsStrong,
  resultIsWeak,
  RMP_FIRST_MARKER_POSITION
} from 'utils/rmp';

import CompetenciesResultSkeleton from 'ui/molecules/CompetenciesResult/CompetenciesResultSkeleton';
import RmpResultTable from 'ui/molecules/RmpResult/RmpResultTable';
import {Callout, Card, ImgCircle, InfoTitle, Modal, RmpBipolarDiagram, TeamDiagramBipolarNext} from 'ui/basic';

const DEFAULT_INFO_TITLE = 'RMP';
const DEFAULT_INFO_DESCRIPTION =
  'In addition to personality, individual preferences play a particularly important role' +
  'in the question of what drives people in their thoughts and actions.' +
  'In a professional context, preferences influence our motivation to pursue certain goals' +
  'and make decisions. If our everyday working life matches our professional preferences,' +
  'we tend to feel better and are more motivated.';

const DEFAULT_COPYRIGHT_TITLE = '© IDS Publishing Corporation. All rights reserved.';
const DEFAULT_COPYRIGHT_DESCRIPTION =
  'All uses of this content are covered by this Copyright Notice,' +
  'which may not be removed or altered. No modifications of the content are permitted, ' +
  'including any translations into another language. No rights are granted for use of any portion ' +
  'of this content without the express, written permission obtained in advance from IDS Publishing Corporation,' +
  ' including creation of any derivative work for puoblication such as documents that quote, cite,' +
  ' or are based upon this content , whether for research puposes or otherwise.';


const TeamRmpResult = (props) => {
  // PROPS
  const {profile, referenceProfile} =  props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const [showModal, setShowModal] = useState();
  const [modalHeader, setModalHeader] = useState();
  const [modalHeaderPosition, setModalHeaderPosition] = useState();
  const [modalSubHeader, setModalSubHeader] = useState();
  const [modalPreHeader, setModalPreHeader] = useState();
  const [modalContent, setModalContent] = useState();

  const [currentDimension, setCurrentDimension] = useState({id: 'wp_1', name: 'test dimension'});
  const [assessmentDimensionResults, setAssessmentDimensionResults] = useState();

  // METHODS
  const getDimensionsData = () => {
    if (!profile) {
      return [];
    }

    const rmpDimensions = [];

    profile.results.forEach(rmpResult => { let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results.find(r => r.dimensionId === rmpResult.id);
        if (thisResult) {
          range = [thisResult.minValue, thisResult.maxValue];
        }
      }

      rmpDimensions.push({
        id: rmpResult.id,
        label: rmpResult.name,
        range,
        data: rmpResult.values.map(userValue => {
          const {user, value} = userValue;

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            value
          }
        })
      });
    })

    return rmpDimensions;
  };

  const getSubHeader = (value, userName, dimensionName) => {
    if (resultIsStrong(value)) {
      return translate('rmp_dimension_strong',
        ['{{dimension}}', dimensionName, '{{username}}', userName]) ||
        `Das Merkmal ${dimensionName} ist bei ${userName} hoch ausgeprägt.`;
    } else if (resultIsWeak(value)) {
      return translate('rmp_dimension_weak',
        ['{{dimension}}', dimensionName, '{{username}}', userName]) ||
        `Das Merkmal ${dimensionName} ist bei ${userName} gering ausgeprägt.`;
    } else {
      return translate('rmp_dimension_average',
        ['{{dimension}}', dimensionName, '{{username}}', userName]) ||
        `Das Merkmal ${dimensionName} ist bei ${userName} durchschnittlich ausgeprägt.`;
    }
  };

  const getCalloutText = (value, dimensionId) => {
    if (resultIsStrong(value)) {
      return translate(`rmp_report_${dimensionId}_strong_details`);
    } else if (resultIsWeak(value)) {
      return translate(`rmp_report_${dimensionId}_weak_details`);
    }
    return translate(`rmp_report_${dimensionId}_average_details`);
  };

  useEffect(() => {
    if (!profile) {
      return;
    }

    setCurrentDimension({id: profile.results[0].id, name: profile.results[0].name})
  }, [profile]);

  useEffect(() => {
    if (!profile) {
      return;
    }

    setAssessmentDimensionResults(profile.results.find(r => r.id === currentDimension.id));
  }, [profile, currentDimension]);

  if (!assessmentDimensionResults) {
    return <CompetenciesResultSkeleton />;
  }

  return (
    <Card>
      <InfoTitle
        title={translate('rmp_results_title') || 'Reiss Motivation Profile®'}
        onClick={() => {
          setModalHeader(translate('rmp_results_info_title') || DEFAULT_INFO_TITLE);
          setModalHeaderPosition('left');
          setModalSubHeader(null);
          setModalPreHeader(null);
          setModalContent(translate('rmp_results_info_description') || DEFAULT_INFO_DESCRIPTION);

          setShowModal(true);
        }}
      />

      <div className={styles.diagram}>
        <TeamDiagramBipolarNext
          labelLeft={translate('rmp_report_chart_tl_l') || 'Gering'}
          labelCenter={translate('rmp_report_chart_tl_c') || 'Durchschnitt'}
          labelRight={translate('rmp_report_chart_tl_r') || 'Stark'}
          grid={[-1.7, -0.84, 0.84, 1.7]}
          valueRange={[-2, 2]}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: ` || 'Referenzprofil:')}
          onItemClick={(item) => {
            const userName = item.label;
            const userImage = item.img;
            const value = item.value;
            const dimensionId = item.dimensionId;
            const dimensionName = item.dimensionName;

            setModalHeader(userName);
            setModalHeaderPosition('center');
            setModalSubHeader(getSubHeader(value, userName, dimensionName));
            setModalPreHeader(
              <ImgCircle
                src={userImage}
                size={'M'}
                label1={userName.split(' ')[0]}
                label2={userName.split(' ')[1]}
              />
            );

            setModalContent(
              <div className={styles.infoModalResult}>
                <div className={styles.reportDiagram}>
                  <div className={styles.header}>
                    <span>{translate('big5_report_facet_result') || 'Ergebnis'}</span>
                    <span>{setDecimalSeperator(value, 2)}</span>
                  </div>

                  <RmpBipolarDiagram
                    width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
                    //+2 to avoid negative values as they're not supported in svg
                    //range values for rmp: [-2, 2]
                    score={value + 2}
                    showNumbers
                  />

                  <div className={styles.footer}>
                      <span className={value < -RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
                        {translate('rmp_results_scale_min') || 'gering'}
                      </span>
                    <span className={value > RMP_FIRST_MARKER_POSITION ? styles.blue: null}>
                        {translate('rmp_results_scale_max') || 'stark'}
                      </span>
                  </div>
                </div>

                <div className={styles.callout}>
                  <Callout trianglePosition={'center'}>
                    {getCalloutText(value, dimensionId)}
                  </Callout>
                </div>

                <RmpResultTable
                  dimensionId={dimensionId}
                  score={value}
                />
              </div>
            );

            setShowModal(true);
          }}
        />
      </div>

      <div className={styles.copyright}>
        <span onClick={() => {
          setModalHeader(translate('rmp_results_info_title_copyright') || DEFAULT_COPYRIGHT_TITLE);
          setModalSubHeader(null);
          setModalPreHeader(null);
          setModalContent(translate('rmp_results_info_description_copyright') || DEFAULT_COPYRIGHT_DESCRIPTION);

          setShowModal(true);
        }}>
          {translate('rmp_results_copyrightlink') || '© IDS Publishing Corporation.'}
        </span>
      </div>

      {showModal &&
      <Modal
        header={modalHeader}
        headerPosition={modalHeaderPosition}
        subHeader={modalSubHeader}
        subHeaderPosition={'center'}
        preHeader={modalPreHeader}
        secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
        onClose={() => setShowModal(false)}
        onConfirm={() => setShowModal(false)}
      >
        {modalContent}
      </Modal>
      }
    </Card>
  )
};

export default TeamRmpResult;
