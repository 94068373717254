// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './TeamDiagram9LevelsNext.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { PillGroup, ImgCircle } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { numberWithForcedDecimals } from 'utils/numberFormatting';
import { useAutoReset } from 'utils/hooks';

// STORE

// CONFIG & DATA

// COMPONENT: TeamDiagram9LevelsNext
const TeamDiagram9LevelsNext = (props) => {

  // PROPS
  const {
    // ranges
    showRanges = false,
    labelRange = '',
    // average
    showAverage = true,
    labelLeftAverage = '',
    labelRightAverage = '',
    // all
    labelLeft = '',
    labelRight = '',
    dimensions = [],
    onItemClick = () => {},
   } = props;

   const translate = useTranslate();

  // COMPONENT/UI STATE and REFS

  // buildup
  const [ isBuildUp, setIsBuildUp ] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBuildUp(false);
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // animating
  const [ animating, setAnimating ] = useAutoReset(false, 300);

  // activeDimension & activeDimensionIndex
  const [ activeDimension, setActiveDimension ] = useState({ data: [] });
  const [ activeDimensionIndex, setActiveDimensionIndex ] = useState(0);
  useEffect(() => {
    const index = activeDimensionIndex >= dimensions.length - 1
      ? dimensions.length - 1
      : activeDimensionIndex;

    setActiveDimensionIndex(index);
    setActiveDimension(dimensions[index]);
    setAnimating(true);
  }, [dimensions, activeDimensionIndex, setAnimating]);


  // leftAverage & update
  const [ leftAverage, setLeftAverage ] = useState(0);
  useEffect(() => {
    const leftAverage = calcAverage(activeDimension.data.map(item => item.leftValue));
    setLeftAverage(leftAverage);
  }, [activeDimension]);

  // rightAverage & update
  const [ rightAverage, setRightAverage ] = useState(0);
  useEffect(() => {
    const rightAverage = calcAverage(activeDimension.data.map(item => item.rightValue));
    setRightAverage(rightAverage);
  }, [activeDimension]);

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS
  const calcAverage = (data) => {
    return data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / data.length;
  };

  // EVENT HANDLES
  const handlePillClick = (item, index) => {
    setActiveDimensionIndex(index);
  };
  const handleItemClick = (item, index) => {
    onItemClick(item, index);
  };

  // HELPERS

  // RENDERS

  // RENDER: TeamDiagram9LevelsNext
  return (
    <div className={classNames(styles.teamDiagram9LevelsNext, {
      [styles[activeDimension.color]]: activeDimension
    })}>

      {/* PILLGROUP WITH DIMENSIONS */}
      <PillGroup
        pillItems={dimensions.map(({ label }) => {return { label };})}
        onPillClick={handlePillClick}
      />

      {/* LABELS */}
      <div className={classNames(styles.labels)}>
        <div className={styles.labelLeft}>{ labelLeft }</div>
        <div className={styles.labelRight}>{ labelRight }</div>
      </div>

      {/* GRAPH & DIMENSIONS & GRID & AVERAGE */}
      <div className={classNames(styles.graph,
        { [styles.animating]: animating }
      )}>
        { activeDimension.data.map((item, index) => (

          // ITEM
          <div key={index}
            className={classNames(styles.itemContainer)}
            onClick={() => {
              handleItemClick({
                ...item,
                dimensionId: activeDimension.id,
                dimensionName: activeDimension.label,
                dimensionColor: activeDimension.color
                }, index
              );
            }}>
            <div className={styles.item}>

              {/* MARKER */}
              <div className={styles.marker}>
                <div className={styles.content}>
                  <ImgCircle className='noBorder noBackground'
                    size='Xs' src={item.img}
                    fullLabel={item.label} />
                </div>
              </div>

              {/* NAME */}
              <div className={styles.name}> { item.label } </div>

              {/* BARS */}
              <div className={styles.bars}>
                <div className={styles.barContainerLeft}>
                  <div className={styles.bar}
                    style={{ width: `${isBuildUp ? 0 : item.leftValue}%`}}>
                  </div>
                </div>
                <div className={styles.barContainerRight}>
                  <div className={styles.bar}
                    style={{ width: `${isBuildUp ? 0 : item.rightValue}%`}}>
                  </div>
                </div>
              </div>

            </div>
          </div>

        ))}

        {/* GRID & AVERAGE & RANGE */}
        <div className={styles.grid}>
          { (!showRanges && showAverage) && <div className={styles.average} style={{ marginLeft: `${50 - leftAverage / 2}%`}}></div> }
          <div className={styles.center}></div>
          { (!showRanges && showAverage) && <div className={styles.average} style={{ marginLeft: `${50 + rightAverage / 2}%`}}></div> }
          {/* RANGE */}
          { showRanges && activeDimension.range && (
            <div className={styles.rangeContainer}>
              <div className={styles.range}
                style={{
                  width: `${(activeDimension.range[1] - activeDimension.range[0]) / 2}%`,
                  marginLeft: `${50 + activeDimension.range[0] / 2}%`,
                }}>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* LABELS */}

      {/* AVERAGES */}
      { !showRanges && (showAverage && labelLeftAverage && labelRightAverage) && (
        <div className={classNames(styles.labels, styles.grey1)}>
          <div className={styles.labelLeft}>
            { labelLeftAverage }
            <span className={styles.highlight}>
              { numberWithForcedDecimals(leftAverage, { decimals: 0 }) }
            </span>
          </div>
          <div className={styles.labelRight}>
            { labelRightAverage }
            <span className={styles.highlight}>
              { numberWithForcedDecimals(rightAverage, { decimals: 0 }) }
            </span>
          </div>
        </div>
      )}

      {/* RANGE */}
      { showRanges && activeDimension.range && (
        <div className={classNames(styles.labels, styles.grey1)}>
          {/* copyright */}
          <div></div>
          {/* range */}
          <div className={styles.rangeLabel}>
            <span className={styles.rangeLegend}></span>
            { labelRange }
            <span className={styles.highlight}>
              { numberWithForcedDecimals(activeDimension.range[0], { decimals: 0}) } { translate('component_team_diagrams_next_range_to') || 'bis' } { numberWithForcedDecimals(activeDimension.range[1], { decimals: 0}) }
            </span>
          </div>
        </div>
      )}

    </div>
  );
};

export default TeamDiagram9LevelsNext;
