import React, {useEffect} from 'react'
import styles from './AdminUserGroup.module.scss';

import { useTranslate } from 'utils/translator';

const DESCRIPTIONS = [
  'Für Mitarbeiter mit administrativen Rechten',
  'Voller Zugriff auf alle Produktfunktionen',
  'Zugriff auf alle Produkteinstellungen (Nutzerverwaltung, Organisation, Design)'
];

const AdminUserGroup = () => {

  const translate = useTranslate();

  useEffect(() => {
    document.title = `bluquist - ${translate('main_navigation_item_8')}`;
  }, [translate]);

  return (
    <div className={styles.adminUserGroup}>
      <div className={styles.main}>
        <div className={styles.title}>
          {translate('usergroup_settings_admin') || 'Admin'}
        </div>
        <div className={styles.description}>
          {DESCRIPTIONS.map((description, index) => (
            <div className={styles.descRow} key={`description-${index}`}>
              <span>-</span>
              <span>{translate(`usergroup_settings_admin_descr${index + 1}`) || description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
};

export default AdminUserGroup;
