import {call, put, takeLatest} from "redux-saga/effects";
import * as fromActions from './garmin.actions';

// api
import * as api from 'api';

import moment from "moment";

function* getUserScore() {
  const { status, ok, data } = yield call(api.get, 'core/integrations/garmin/score/user');

  if (ok && status === 200) {
    yield put(fromActions.getUserScoreSuccess({data}));
  } else {
    yield put(fromActions.getUserScoreFail({error: data.error}));
  }
}

export function* watchGetUserScore() {
  yield takeLatest(fromActions.GET_USER_SCORE, getUserScore);
}


function* getConnectionStatus() {
  const { status, ok, data } = yield call(api.get, 'core/integrations/garmin/connect/status');

  if (ok && status === 200) {
    yield put(fromActions.getConnectionStatusSuccess({data}));
  } else {
    yield put(fromActions.getConnectionStatusFail({error: data.error}));
  }
}

export function* watchGetConnectionStatus() {
  yield takeLatest(fromActions.GET_CONNECTION_STATUS, getConnectionStatus);
}


function* getUserHistory() {
  const dateFrom = moment().subtract(1, 'year');

  const { status, ok, data } = yield call(api.get, 'core/integrations/garmin/history/user', {
    dateFrom: Math.ceil(dateFrom.valueOf() / 1000),
    sort: 'date',
    sortOrder: 'desc'
  });

  if (ok && status === 200) {
    yield put(fromActions.getUserHistorySuccess({data}));
  } else {
    yield put(fromActions.getUserHistoryFail({error: data.error}));
  }
}

export function* watchGetUserHistory() {
  yield takeLatest(fromActions.GET_USER_HISTORY, getUserHistory);
}


function* getOrgScore() {
  const { status, ok, data } = yield call(api.get, 'core/integrations/garmin/score/company');

  if (ok && status === 200) {
    yield put(fromActions.getOrgScoreSuccess({data}));
  } else {
    yield put(fromActions.getOrgScoreFail({error: data.error}));
  }
}

export function* watchGetOrgScore() {
  yield takeLatest(fromActions.GET_ORG_SCORE, getOrgScore);
}

function* getOrgHistory() {
  const dateFrom = moment().subtract(1, 'year');

  const { status, ok, data } = yield call(api.get, 'core/integrations/garmin/history/company', {
    dateFrom: Math.ceil(dateFrom.valueOf() / 1000),
    mode: 'weekly',
    sort: 'date',
    sortOrder: 'desc'
  });

  if (ok && status === 200) {
    yield put(fromActions.getOrgHistorySuccess({data}));
  } else {
    yield put(fromActions.getOrgHistoryFail({error: data.error}));
  }
}

export function* watchGetOrgHistory() {
  yield takeLatest(fromActions.GET_ORG_HISTORY, getOrgHistory);
}
