// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useEffect, useCallback,
  forwardRef, useImperativeHandle,
} from 'react';
import styles from './Pagination.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classnames from 'classnames';

// OTHER COMPONENTS
import { ButtonCircular } from 'ui/basic/forms/ButtonCircular';

// UTILS
import { pxToNumber } from 'utils/styleTools';

// CONFIG & DATA

// COMPONENT: Pagination
const Pagination = (props, ref) => {
  // PROPS
  const {
    showPaginationBubbles,
    showPagerButtons, showNumbers, extraMargins, disabled,
    pageCount, pagePosition,
    onPagePositionUpdate,
   } = props;

  // COMPONENT/UI STATE and REFS
  const [ paginationLeftAvailable, setPaginationLeftAvailable ] = useState(true);
  const [ paginationRightAvailable, setPaginationRightAvailable ] = useState(true);

  // STORE HOOKS

  // EFFECT HOOKS
  const pagePositionUpdate = useCallback((newPagePosition) => {
    // console.log('pagePositionUpdate', newPagePosition);
    let paginationLeftAvailable = true;
    let paginationRightAvailable = true;

    if (newPagePosition <= 0) {
      newPagePosition = 0;
      paginationLeftAvailable = false;
    }

    if (newPagePosition >= pageCount - 1) {
      newPagePosition = pageCount - 1;
      paginationRightAvailable = false;
    }
    setPaginationLeftAvailable(paginationLeftAvailable);
    setPaginationRightAvailable(paginationRightAvailable);

    onPagePositionUpdate(newPagePosition, paginationLeftAvailable, paginationRightAvailable);

  }, [pageCount, onPagePositionUpdate]);

  // update pageAvailabilities based on pagePosition (& pageCount via pagePositionUpdate function)
  // pagePositionUpdate(pagePosition);
  useEffect(() => {
    // console.log('effect:pagePosition', pagePosition);
    pagePositionUpdate(pagePosition);
  }, [pagePosition, pagePositionUpdate]);

  // OTHER HOOKS
  useImperativeHandle(ref, () => ({
    pagePositionUpdate,
  }));

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS
  const renderPaginationBubbles = () => {
    const bubbles = [];

    for (let i = 0; i < pageCount; i++) {
      const indexDistance = Math.abs(pagePosition - i);
      bubbles.push(
        <div key={i}
          className={classnames({
            [styles.bubbleBox]: !showNumbers,
            [styles.numberBox]: showNumbers
          })}
          onClick={() => pagePositionUpdate(i)}>

            {/* bubbles with overflow */}
            <div className={classnames({
              [styles.bubble]: !showNumbers,
              [styles.number]: showNumbers,
              [styles.active]: pagePosition === i,
              [styles.medium]: showNumbers
                ? indexDistance === 1
                : indexDistance <= 3 && indexDistance >= 1,
              [styles.small]: showNumbers
              ? indexDistance === 2
              : indexDistance === 4,
              [styles.tiny]: showNumbers
              ? indexDistance === 3
              : indexDistance === 5,
            })}> {showNumbers && i + 1}</div>
          </div>
        );
    }

    let bubbleWidth = showNumbers ? styles.numberWidth : styles.bubbleWidth;
    const scrollOffset = /* start offset + dynamic offset */
    pxToNumber(styles.bubbleContainerWidth) / 2 - pxToNumber(bubbleWidth) / 2
    - pagePosition * pxToNumber(bubbleWidth);

    return (
      <div className={styles.bubblesContainer}>
        {/* scroll container for bubble overflow */}
        <div className={classnames(styles.bubblesScroll, {
            [styles.hidden]: pageCount <= 1 || disabled
          })}
          style={{ transform: `translateX(${scrollOffset}px)` }}>
          { bubbles }
        </div>
      </div>
    );
  };

  // RENDER: Pagination
  return (
    <div className={`${styles.pagination}`}>

      {/* PAGER BUTTON LEFT */}
      {showPagerButtons &&
        <div className={styles.pagerButtonLeft}
          style={{ paddingLeft: `${extraMargins - 40 /2 /* ButtonCircular / 2*/}px`}}>
            <ButtonCircular
              icon='ArrowLeft'
              onClick={() => { pagePositionUpdate(pagePosition - 1)}}
              disabled={!paginationLeftAvailable} />
        </div>
      }

      {/* BUBBLES */}
      { (showPaginationBubbles || showNumbers) && (
        <div className={styles.bubbles}>
          { renderPaginationBubbles() }
        </div>
      )}

      {/* PAGER BUTTON RIGHT */}
      {showPagerButtons &&
        <div className={styles.pagerButtonRight}
          style={{ paddingRight: `${extraMargins - 40 /2 /* ButtonCircular / 2*/}px`}}>
            <ButtonCircular
              icon='ArrowRight'
              onClick={() => { pagePositionUpdate(pagePosition + 1)}}
              disabled={!paginationRightAvailable} />
        </div>
      }

    </div>
  );
};

export default forwardRef(Pagination);
