import React, {useEffect, useRef, useState} from 'react';
import styles from './Overview.module.scss';

import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import * as moment from 'moment';
import classNames from 'classnames';

import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import * as fromWellBeingSelectors from 'store/selectors/wellBeing';
import {selectCapabilitiesNext, selectFeaturesNext} from 'store/selectors/configuration';
import {
  getAssessment,
  getAssessmentResult, getMindJourneyScore,
  getOrgWellBeingIndex, getStatistic,
  listAssessments
} from 'store/actions';

// ASSETS
import GrowKeyVisual from 'assets/keyvisuals/grow_keyvisual_new.svg';
import InviteEmployeeImage from 'assets/keyvisuals/invite-employees.svg';
import ViewEmployeesProfileImage from 'assets/keyvisuals/view-employees-profile.svg';
import CreateRoleImage from 'assets/keyvisuals/create-role.svg';
import CreateTeamImage from 'assets/keyvisuals/create_team.svg';
import {ReactComponent as ArrowRight} from 'assets/icons/icn_arrow_right.svg';
import {ReactComponent as ArrowLeft} from 'assets/icons/icn_arrow_left.svg';
import {IconsSvg} from 'assets/icons';

import {hasStoredAnswers} from 'ui/molecules/AssessmentNext/AssessmentNext.logic';
import {ASSESSMENT_NEXT_TYPES} from 'ui/molecules/AssessmentNext/AssessmentNext.config';


// OTHER COMPONENTS
import RmpImport from './RmpImport';
import AssessmentTeaserNextSkeleton from 'ui/basic/containers/AssessmentTeaserNext/AssessmentTeaserNextSkeleton';
import {
  Button,
  MyProfileTeaser,
  Scrollable, AssessmentTeaserNext, ButtonCircular,
  Modal
} from 'ui/basic';

import {
  OrgWellBeingResult,
  DialogSequence,
  MindfulnessJourneyScore
} from 'ui/molecules';


// UTILS
import { useTranslate } from 'utils/translator';
import {isAssessmentCompleted} from 'utils/assessment';
import {ASSESSMENT_TYPES, MIND_JOURNEY_MIN_ASSESSMENT_PERIOD} from 'utils/configuration/const/assessment-types';
import {MAPPED_PLATFORM_ROLES} from 'utils/configuration/const/roles';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';
import {MAIL_TYPES} from 'utils/configuration/const/mailTypes';
import {useBreakpoint} from 'utils/hooks';
import { setDecimalSeperator } from 'utils/userpreference';

// STORE
import * as api from 'api';
import * as fromGarminSelectors from 'features/+garmin/store/garmin.selectors';
import * as fromGarminActions from 'features/+garmin/store/garmin.actions';
import { hasAnyCapability } from 'features/framework/utils/routeNext';

// DATA
const DEFAULT_HERO_STAGE_TITLE = 'Willkommen bei bluquist, {{username}}.';
const DEFAULT_FIRST_ASS = 'Starte mit einem der folgenden Assessments um dich selbst und bluquist besser kennzulernen.';
const DEFAULT_NEXT_ASS = 'Starte dein nächstes Assessment oder schau dir dein Profil an.';
const DEFAULT_VIEW_PROFILE = 'Schau dir dein Profil an.';

const ACTIONS = [
  {
    title: 'home_actions_invite_employees_title',
    altTitle: 'Mitarbeiter einladen',
    description: 'home_actions_invite_employees_description',
    altDescription: 'Lade Mitarbeiter ein um ihre Stärken zu ermitteln',
    image: InviteEmployeeImage,
    link: '/user/invite',
    forCapabilities: ['employeesInvite', 'candidatesInvite'],
  },
  {
    title: 'home_actions_view_employees_profile_title',
    altTitle: 'Mitarbeiterprofile ansehen',
    description:'home_actions_view_employees_profile_title',
    altDescription: 'Schau dir Profile deiner Mitarbeiter an und entdecke ihre Stärken',
    image: ViewEmployeesProfileImage,
    link: '/employees',
    forCapabilities: 'employeesView',
  },
  {
    title: 'home_actions_create_role_title',
    altTitle: 'Rolle erstellen',
    description: 'home_actions_create_role_description',
    altDescription: 'Erstelle eine Rolle und besetze sie mit passenden Mitarbeitern',
    image: CreateRoleImage,
    link: '/roles/new',
    forCapabilities: 'rolesCreate',
    featureKey: 'roles'
  },
  {
    title: 'home_actions_create_team_title',
    altTitle: 'Team erstellen',
    description: 'home_actions_create_team_description',
    altDescription: 'Erstelle ein Team und besetze es mit passenden Rollen und  Mitarbeitern',
    image: CreateTeamImage,
    link: '/teams/new',
    forCapabilities: 'teamsCreate',
    featureKey: 'teams'
  }
];

const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'mind_journey__'
};


// COMPONENT: OVERVIEW
const Overview = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const bp = useBreakpoint();

  const scrollableRef = useRef();
  const actionsRef = useRef();
  const actionRef = useRef();

  let actionIsDown = false;
  let actionStartX;

  const [actionScrollLeft, setActionScrollLeft] = useState(0);

  // TEASERS
  const [teasers, setTeasers] = useState([]);
  const [ teasersPageLeftAvailable, setTeasersPageLeftAvailable ] = useState(false);
  const [ teasersPageRightAvailable, setTeasersPageRightAvailable ] = useState(false);

  // ONBOARDING
  const [ showOnboarding, setShowOnboarding ] = useState(false);
  const [ onboardingType, setOnboardingType ] = useState();
  const [ onboardingConfig, setOnboardingConfig ] = useState();
  const [ learnMoreModal, setLearnMoreModal ] = useState();


  // RMP IMPORT
  const [rmpImportModalVisible, setRmpImportModalVisible] = useState();

  //SELECTORS
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const role = useSelector(state => (state.currentUser && state.currentUser.role) && state.currentUser.role);

  const currentLanguage = useSelector(state => state.localisation?.currentLanguage);

  const assessments = useSelector(state => state.assessments);

  const capabilitiesNext = useSelector(selectCapabilitiesNext);
  const featuresNext = useSelector(selectFeaturesNext);

  const orgWellBeingIndex = useSelector(fromWellBeingSelectors.getOrgWellBeingIndex);

  //BALANCED YOU SELECTORS
  const orgScore = useSelector(state => state.organization.score);
  const orgStatistic = useSelector(state => state.organization.statistic);
  const mindJourneyAssessment = assessments[ASSESSMENT_TYPES.MINDFULNESS_JOURNEY];
  const assessmentCompletionDate = (mindJourneyAssessment && mindJourneyAssessment.date) && mindJourneyAssessment.date;
  const daysAfterCompletion = assessmentCompletionDate &&
    moment.duration(moment().diff(moment(assessmentCompletionDate * 1000))).asDays();

  const showActionTeasers = CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST &&
    (capabilitiesNext.employeesInvite || capabilitiesNext.rolesCreate || capabilitiesNext.teamsCreate);

  const showCurrentDevelopment = (CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST && me.canAccessWellBeing && role === MAPPED_PLATFORM_ROLES.COMPANY_ADMINISTRATOR) ||
    CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU;

  // USER REDIRECTION
  const mailType = localStorage.getItem('mailType');
  const assessmentId = localStorage.getItem('assessmentId');
  // GO TO ASSESSMENT PAGE
  if (mailType && mailType === MAIL_TYPES.ASSESSMENT_REMINDER && assessmentId) {
    localStorage.removeItem('mailType');
    localStorage.removeItem('assessmentId');
    history.push(`/assessments/${assessmentId}`);
  }
  // GO TO MY PROFILE PAGE
  if (mailType && mailType === MAIL_TYPES.RMP_REPORT) {
    history.push('/my-profile');
  }
  // GO TO SETTINGS PAGE
  if (mailType === MAIL_TYPES.NOTIFICATION_SETTINGS) {
    localStorage.removeItem('mailType');
    history.push('/settings');
  }

  // GARMIN CONNECTION STATUS
  const garminAccountConnected = useSelector(fromGarminSelectors.selectGarminConnectionStatus);
  useEffect(() => {
    if (garminAccountConnected === null && me.canAccessGarmin) {
      dispatch(fromGarminActions.getConnectionStatus());
    }
  }, [dispatch, me.canAccessGarmin, garminAccountConnected])


  const [orgWellbeingIndexRequested, setOrgWellbeingIndexRequested] = useState();
  useEffect(() => {
    if (orgWellbeingIndexRequested) {
      return;
    }

    if (showCurrentDevelopment && !orgWellBeingIndex) {
      dispatch(getOrgWellBeingIndex());
    }

    setOrgWellbeingIndexRequested(true);
  }, [dispatch, showCurrentDevelopment, orgWellBeingIndex, orgWellbeingIndexRequested]);


  const [mindJourneyResultsRequested, setMindJourneyResultsRequested] = useState();
  useEffect(() => {
    if (mindJourneyResultsRequested) {
      return;
    }

    if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU && (!mindJourneyAssessment || !mindJourneyAssessment.results)) {
      dispatch(getAssessmentResult(ASSESSMENT_TYPES.MINDFULNESS_JOURNEY));
    }
    setMindJourneyResultsRequested(true);
  }, [dispatch, mindJourneyAssessment, mindJourneyResultsRequested]);

  useEffect(() => {
    if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU && (!orgScore || Object.keys(orgScore).length === 0)) {
      dispatch(getMindJourneyScore());
    }
  }, [dispatch, orgScore]);

  useEffect(() => {
    if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU && (!orgStatistic || Object.keys(orgStatistic).length === 0)) {
      dispatch(getStatistic());
    }
  }, [dispatch, orgStatistic]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessmentsWithNoClifton);

  const [ assessmentsListRequested, setAssessmentsListRequested ] = useState();
  useEffect(() => {
    if (assessmentsListRequested) {
      return;
    }

    setAssessmentsListRequested(true);
    dispatch(listAssessments());
  }, [ dispatch, assessmentsListRequested ]);

  const [byAssessmentIsLocked, setByAssessmentIsLocked] = useState();
  useEffect(() => {
    if (CONFIGURATION !== CONFIGURATION_OPTIONS.BALANCED_YOU || byAssessmentIsLocked !== undefined) {
      return;
    }

    api.get(`/core/assessments/${ASSESSMENT_TYPES.BALANCED_YOU}/1/locked`)
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          setByAssessmentIsLocked(data.locked);
        }
      })
  }, [byAssessmentIsLocked]);

  const [byAssessmentResultRequested, setByAssessmentResultRequested] = useState();
  useEffect(() => {
    if (CONFIGURATION !== CONFIGURATION_OPTIONS.BALANCED_YOU || byAssessmentResultRequested) {
      return;
    }

    if (mindJourneyAssessment && !mindJourneyAssessment.results && !byAssessmentResultRequested) {
      dispatch(getAssessmentResult(ASSESSMENT_TYPES.BALANCED_YOU));

      setByAssessmentResultRequested(true);
    }
  }, [dispatch, mindJourneyAssessment, byAssessmentResultRequested]);

  const [assessmentRequested, setAssessmentRequested] = useState();
  useEffect(() => {
    if (assessmentRequested || !assessmentsFromStore?.length) {
      return;
    }

    assessmentsFromStore.forEach(assessment => {
      // if assessment is a custom assessment
      if (!Object.values(ASSESSMENT_TYPES).includes(assessment.id) && (
        !assessment.resultSchema || assessment.resultSchemaLanguage !== currentLanguage
      )) {
        dispatch(getAssessment(assessment.id));
      }
    });

    setAssessmentRequested(true);
  }, [
    dispatch,
    assessmentRequested,
    assessmentsFromStore,
    currentLanguage
  ]);

  useEffect(() => {
    if (!assessmentsFromStore || assessmentsFromStore.length === 0) {
      return;
    }

    const teasersInternal = [];

    const filteredTeasers = assessmentsFromStore
      .filter(assessment => {
        return (assessment.userAvailable === undefined || assessment.userAvailable) &&
          assessment.stages && (
            (assessment.id !== ASSESSMENT_TYPES.MINDFULNESS_JOURNEY && !isAssessmentCompleted(assessment)) || (
              mindJourneyAssessment && !mindJourneyAssessment.results?.length && mindJourneyAssessment.resultsRequestProcessed && byAssessmentIsLocked === false
            ))
      })

    filteredTeasers
      .forEach(assessment => {
        const assessmentName = ASSESSMENT_NEXT_TYPES[assessment.id] || assessment.id;

        const storedAnswers = hasStoredAnswers(assessmentName, me.id);

        let assessmentStarted;
        if (assessment.stages && assessment.id !== ASSESSMENT_TYPES.BALANCED_YOU) {
          assessmentStarted = assessment.stages.some(stage => stage.questionsAnswered > 0 && stage.questionsAnswered < stage.length);
        }

        // if multi session is enabled for assessment and there are answered questions
        if (assessment.multi_session && (assessmentStarted || storedAnswers)) {
          teasersInternal.unshift({
            assessmentType: assessment.id,
            assessmentTitle: assessment.title,
            assessmentDescription: assessment.infoTexts && assessment.infoTexts.shortDescription,
            assessmentScope: assessment.infoTexts && assessment.infoTexts.requiredTime,
            assessmentDetails: assessment?.infoTexts?.longDescription,
            assessmentStarted
          });

          return;
        }

        teasersInternal.push({
          assessmentType: assessment.id,
          assessmentTitle: assessment.title,
          assessmentDescription: assessment.infoTexts && assessment.infoTexts.shortDescription,
          assessmentScope: assessment.infoTexts && assessment.infoTexts.requiredTime,
          assessmentDetails: assessment?.infoTexts?.longDescription,
          assessmentStarted: false
        });
      });

    if (me.canAccessGarmin && garminAccountConnected === false) {
      //add Garmin Connect teaser to the beginning of all teasers
      teasersInternal.push({
        assessmentType: 'garmin',
        assessmentTitle: translate('garmin_connect'),
        assessmentDescription: translate('garmin_connect_copy'),
        forStart: false,
        forConnect: true
      });
    }

    setTeasers(teasersInternal);
  }, [
    translate,
    me.id,
    me.canAccessGarmin,
    assessments,
    assessmentsFromStore,
    mindJourneyAssessment,
    garminAccountConnected,
    byAssessmentIsLocked
  ]);

  // Count all assessments that have been completed
  const getCompletedAssessmentsLength = () => Object.values(assessments)
  .filter(Boolean)
  .filter(({ userAvailable }) => userAvailable !== false)
  .filter(({ stages }) => stages?.every((stage) => stage.length === stage.questionsAnswered))
  .length;

  const assessmentsLoaded = () => {
    return assessmentsFromStore && assessmentsFromStore.length > 0;
  }

  const getTeasersDescription = () => {
    if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU) {
      if (daysAfterCompletion >= MIND_JOURNEY_MIN_ASSESSMENT_PERIOD) {
        return translate('mind_journey__ind_profile_description') ||
          'Start your next Assessment, check your profile or start targeted development.'
      }
      return null;
    }

    if (getCompletedAssessmentsLength() === 0) {
      return translate('home_assessment_teasers_first_ass') || DEFAULT_FIRST_ASS;
    }
    if (assessmentsFromStore && assessmentsFromStore.length === getCompletedAssessmentsLength()) {
      return translate('home_assessment_teasers_view_profile') || DEFAULT_VIEW_PROFILE;
    }
    return translate('home_assessment_teasers_next_ass') || DEFAULT_NEXT_ASS;
  };

  const getActionScrollWidth = () => {
    const action = actionRef.current;
    if (!action) {
      return 0;
    }
    if (bp.isS) {
      //3 action teasers, 32- margin between them
      return (action.clientWidth * 3) + 32;
    }
    //4 assessment teasers, 48 - margin between them
    return (action.clientWidth * 4) + 48;
  };

  const actionPagingRightClassNames = classNames(
    styles.right,
    {[styles.active]: actionsRef.current ? (actionScrollLeft + getActionScrollWidth()) < actionsRef.current.clientWidth : false}
  );

  const actionPagingLeftClassNames = classNames(
    styles.left,
    {[styles.active]: actionScrollLeft > 0}
  );

  const actionsPagerIsVisible = () => {
    return bp.isS;
  };

  // ACTION HANDLERS
  const handleOnboardingStart = () => {
    if (me.role === MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE) {
      setShowOnboarding(true);
      setOnboardingType('candidateOnboarding');
      setOnboardingConfig({
        inject: {
          firstName: me.firstName,
          teasers: (
            <div className={styles.onboardingTeasersContainer}>
              <div className={styles.onboardingTeasersNav}>
                <ButtonCircular
                  icon={'ArrowLeft'}
                  looks={'primary'}
                  // disabled={!teasersPageLeftAvailable}
                  // can't dynamically update these without rerendering the assessment atm
                  onClick={() => { scrollableRef.current.pageLeft()}}
                />
              </div>
              <div className={styles.onboardingTeasers}>
                { renderTeasers(
                  (modal) => { setLearnMoreModal(modal)},
                  () => { setLearnMoreModal() }
                ) }
              </div>
              <div className={styles.onboardingTeasersNav}>
                <ButtonCircular
                  icon={'ArrowRight'}
                  looks={'primary'}
                  // disabled={!teasersPageRightAvailable}
                  // can't dynamically update these without rerendering the assessment atm
                  onClick={() => { scrollableRef.current.pageRight()}}
                />
              </div>
            </div>
          )
        }
      });
    }
    else {
      history.push('/introduction')
    }
  };
  const handleOnboardingClose = () => {
    setShowOnboarding(false);
    setOnboardingType('');
    setOnboardingConfig();
    setLearnMoreModal();
  };
  const handleActionPagingLeft = () => {
    actionsRef.current.scroll({
      left: actionScrollLeft - getActionScrollWidth(),
      behavior: 'smooth'
    });

    setActionScrollLeft((actionScrollLeft - getActionScrollWidth()));
  };

  const handleActionPagingRight = () => {
    actionsRef.current.scroll({
      left: getActionScrollWidth(),
      behavior: 'smooth'
    });

    setActionScrollLeft(getActionScrollWidth());
  };

  const handlePaginationAvailabilities = ([pageLeftAvailable, pageRightAvailable, pagePosition]) => {
    setTeasersPageLeftAvailable(pageLeftAvailable);
    setTeasersPageRightAvailable(pageRightAvailable);
  };

  // GARMIN CONNECT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [garminConnectModalVisible, setGarminConnectModalVisible] = useState();

  const [garminLearnMoreModalVisible, setGarminLearnMoreModalVisible] = useState();
  const [garminLearnMoreModalScreen, setGarminLearnMoreModalScreen] = useState(1);
  const [garminLearnMoreModalTitle, setGarminLearnMoreModalTitle] = useState();
  const [garminLearnMoreModalCopy, setGarminLearnMoreModalCopy] = useState();

  const [garminConnectProcessing, setGarminConnectProcessing] = useState();
  const handleGarminConnect = () => {
    setGarminConnectProcessing(true);
    api.post(`core/integrations/garmin/connect?redirect=${window.location.origin}/my-profile`)
      .then(({ok, status, headers}) => {
        if (status === 303) {
          const location = headers['x-location'];
          if (location) {
            window.location.href = location;
          }
        }
        setGarminConnectProcessing(false);
      })
      .catch(error => {
        console.error(error.message);
        setGarminConnectProcessing(false);
      })
  }

  // RENDERS: Teasers
  const renderTeasers = (onModal, onModalClose) => (
    <Scrollable
      ref={scrollableRef}
      pagination
      drag
      pageMargin={16}
      refresh={teasers}
      onPaginationAvailabilities={handlePaginationAvailabilities}
    >
      <>

        {/*ASSESSMENT TEASERS*/}
        { teasers.map((teaser, index) => (
          <div
            key={index}
            className={styles.teaser}
          >
            <AssessmentTeaserNext
              fullWidth
              assessmentType={teaser.assessmentType}
              title={teaser.assessmentTitle}
              description={teaser.assessmentDescription}
              scope={teaser.assessmentScope}
              details={teaser.assessmentDetails}
              forStart={teaser.forStart}
              forContinue={teaser.assessmentStarted}
              forImport={[ASSESSMENT_TYPES.RMP].includes(teaser.assessmentType)}
              forConnect={teaser.forConnect}
              onClick={() => {
                if (teaser.forConnect) {
                  setGarminConnectModalVisible(true);
                } else {
                  history.push(`/assessments/${teaser.assessmentType}`);
                }
              }}
              onImport={() => setRmpImportModalVisible(true)}
              onLearnMore={teaser.assessmentType === 'garmin' && (() => {
                setGarminLearnMoreModalTitle(translate('garmin_connect'));
                setGarminLearnMoreModalCopy(translate('garmin_connect_learn_more_copy'));
                setGarminLearnMoreModalVisible(true);
              })}
              { ...{ onModal, onModalClose }}
            />
          </div>
        ))}

        {/*MY PROFILE TEASER*/}
        {getCompletedAssessmentsLength() > 0 &&
        <div className={styles.teaser} style={{minWidth: '100%'}}>
          <MyProfileTeaser
            user={me}
            buttonTitle={CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ?
              (translate('mind_journey__ind_profile_teaser_btn') || 'My Profile') :
              (translate('myprofile_teaser_btn') || 'Zum Profil')
            }
          />
        </div>
        }

      </>
    </Scrollable>
  );


  // RENDER: Overview
  return (
    <div className={styles.overview}>
      <div
        className={styles.welcome}
        style={{backgroundImage: `url(${GrowKeyVisual})`}}
      >
        <div
          className={styles.welcomeAltImage}
          style={{backgroundImage: `url(${GrowKeyVisual})`}}
        ></div>
        <div className={styles.content}>
          <div className={styles.title}>
            {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}home_hero_stage_title`, ['{{username}}', me.firstName || '']) ||
            DEFAULT_HERO_STAGE_TITLE.replace('{{username}}', me.firstName)}
          </div>
          <div className={styles.description}>
            {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}home_hero_stage_description`) ||
              'bluquist hilft dir dabei, dich und deine Mitarbeiter gezielt weiterzuentwickeln.'
            }
          </div>

          {CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST &&
          <Button
            leadingIcon={IconsSvg.Play}
            onClick={handleOnboardingStart}
          >
            {translate('home_hero_stage_btn') || 'Einführung ansehen'}
          </Button>
          }
        </div>
      </div>

      {/*TEASERS*/}
      <div className={styles.teasers}>
        {CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST &&
          <div className={styles.title}>
            {translate('home_assessment_teasers_title') || 'Dein Profil - Erkenne deine Stärken und Präferenzen.'}
          </div>
        }

        {CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU &&
          <div className={styles.titleWithScrollable}>
            {translate('mind_journey__ind_profile_title') || 'Your Mindfulness Journey profile'}

            {(assessmentsLoaded() && teasers.length > 0) &&
              <div className={styles.scrollingButtons}>
                <ButtonCircular
                  icon={'ArrowLeft'}
                  looks={'primary'}
                  disabled={!teasersPageLeftAvailable}
                  onClick={() => { scrollableRef.current.pageLeft()}}
                />
                <ButtonCircular
                  icon={'ArrowRight'}
                  looks={'primary'}
                  disabled={!teasersPageRightAvailable}
                  onClick={() => { scrollableRef.current.pageRight()}}
                />
              </div>
            }
          </div>
        }

        {CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU && daysAfterCompletion >= MIND_JOURNEY_MIN_ASSESSMENT_PERIOD &&
          <div className={styles.descriptionBlock}>
            <div className={styles.description}>
              {translate('mind_journey__ind_profile_description') ||
              'Start your next Assessment, check your profile or start targeted development.'}
            </div>
          </div>
        }

        {CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST &&
        <div className={styles.descriptionBlock}>
          <div className={styles.description}>
            {getTeasersDescription()}
          </div>

          {(assessmentsLoaded() && teasers.length > 0) &&
          <div className={styles.scrollingButtons}>
            <ButtonCircular
              icon={'ArrowLeft'}
              looks={'primary'}
              disabled={!teasersPageLeftAvailable}
              onClick={() => { scrollableRef.current.pageLeft()}}
            />
            <ButtonCircular
              icon={'ArrowRight'}
              looks={'primary'}
              disabled={!teasersPageRightAvailable}
              onClick={() => { scrollableRef.current.pageRight()}}
            />
          </div>
          }
        </div>
        }

        <div className={styles.subTeasers}>
          { !assessmentsLoaded() && (
            <div style={{marginTop: styles.spaceS}}>
              <AssessmentTeaserNextSkeleton />
            </div>
          )}

          {/* if there are no uncompleted assessments */}
          { (assessmentsLoaded() && teasers.length === 0 && getCompletedAssessmentsLength() > 0) && (
            <div className={styles.myProfileTeaser} style={{minWidth: '100%'}}>
              <MyProfileTeaser
                user={me}
                buttonTitle={CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ?
                  (translate('mind_journey__ind_profile_teaser_btn') || 'My Profile') :
                  (translate('myprofile_teaser_btn') || 'Zum Profil')
                }
              />
            </div>
          )}

          {/* if uncompleted assessments available */}
          {(assessmentsLoaded() && teasers.length > 0) &&
            renderTeasers()
          }
        </div>
      </div>

      { showCurrentDevelopment && (
        <div className={classNames(styles.orgDevelopmentItems, {[styles.wellBeingHidden]: !me.canAccessWellBeing})}>
          <div className={styles.title}>
            {translate(`${CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ? 'by_' : ''}home_org_development_title`) ||
              'Deine Organisation - Verfolge die aktuelle Entwicklung'}
          </div>

          {CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU &&
            <div className={styles.description}>
              {translate('by_home_org_development_descr') ||
                'Check the current status of your organisational wellbeing and balance.'
              }
            </div>
          }

          {me.canAccessWellBeing &&
            <div className={styles.wellbeing}>
              <OrgWellBeingResult />
            </div>
          }

          {CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU &&
            <div className={styles.orgMindJourneyScore}>
              <div className={styles.upperBlock}>
                <div className={styles.xxsTitle}>
                  {translate('mind_journey__org_profile_title') || 'Your organization'}
                </div>
                <div className={styles.copyDescription}>
                  {translate('mind_journey__org_profile_description') ||
                    'See the Mindfulness Journey Score for your organisation at a glance.'
                  }
                </div>

                <div className={styles.blocks}>
                  <div className={styles.smallBlock}>
                    <div className={styles.title}>
                      {translate('mind_journey__org_part_employees_lbl') || 'Participating employees'}
                    </div>
                    <div className={styles.copyDescription}>{orgStatistic && orgStatistic.total}</div>
                  </div>

                  <div className={styles.smallBlock}>
                    <div className={styles.title}>
                      {translate('mind_journey__org_compl_assessments_lbl') || 'Completed assessments'}
                    </div>
                    <div className={styles.copyDescription}>{orgStatistic && orgStatistic.completed}</div>
                  </div>
                </div>
              </div>

              <div className={styles.mindfulnessJourneyScore}>
                <MindfulnessJourneyScore
                  title={translate('mind_journey_score_org') || 'Organisational Mindfulness Journey Score'}
                  value={orgScore && setDecimalSeperator(orgScore.current, 2)}
                  prevMonthValue={orgScore && setDecimalSeperator(orgScore.previous, 2)}
                />
              </div>
            </div>
          }

        </div>
      )}

      {showActionTeasers &&
      <div className={styles.other}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>
            {translate('home_actions_title') || 'Was möchtest du als nächstes tun?'}
          </div>
          {actionsPagerIsVisible() &&
          <div className={styles.pagingButtons}>
            <div
              className={actionPagingLeftClassNames}
              onClick={handleActionPagingLeft}
            >
              <ArrowLeft/>
            </div>
            <div
              className={actionPagingRightClassNames}
              onClick={handleActionPagingRight}
            >
              <ArrowRight/>
            </div>
          </div>
          }
        </div>
        <div
          className={styles.actions}
          ref={actionsRef}
          onMouseDown={(event) => {
            actionIsDown = true;
            actionStartX = event.pageX - actionsRef.current.offsetLeft;
            setActionScrollLeft(actionsRef.current.scrollLeft);
          }}
          onMouseLeave={(event) => {
            actionIsDown = false;
          }}
          onMouseUp={(event) => {
            actionIsDown = false;
          }}
          onMouseMove={(event) => {
            if(!actionIsDown) return;
            event.preventDefault();
            const x = event.pageX - actionsRef.current.offsetLeft;
            const walk = (x - actionStartX) * 3; //scroll-fast
            actionsRef.current.scrollLeft = actionScrollLeft - walk;
          }}
        >
          { ACTIONS
            .filter(({ forCapabilities }) => hasAnyCapability(capabilitiesNext, forCapabilities))
            .filter(({ featureKey }) => !featureKey || featuresNext[featureKey])
            .map((action, index) => (
              <div
                key={`action-${index}`}
                tabIndex={'0'}
                ref={actionRef}
                className={styles.action}
                onClick={() => history.push(action.link)}
              >
                <div className={styles.imageBlock}>
                  <img
                    alt={action.className}
                    src={action.image}
                  />
                </div>
                <div className={styles.text}>
                  <div className={styles.title}>{translate(action.title) || action.altTitle}</div>
                  <div className={styles.description}>
                    {translate(action.description) || action.altDescription}
                  </div>
                </div>
              </div>
            ))}
          <div style={{minWidth: '60px'}}></div>
        </div>
      </div>
      }

      {/*RMP IMPORT*/}
      {rmpImportModalVisible && <RmpImport onClose={() => setRmpImportModalVisible(false)} />}

      {/* LEARN MORE MODAL */}
      {learnMoreModal && (
        learnMoreModal
      )}

      {/*GARMIN CONNECT MODAL*/}
      {garminConnectModalVisible &&
        <Modal
          header={translate('garmin_connect_modal_long_title')}
          secondaryButtonTitle={translate('cancel_lbl')}
          primaryButtonTitle={translate('continue_lbl')}
          primaryButtonDisabled={garminConnectProcessing}
          closeOnConfirm={false}
          onClose={() => setGarminConnectModalVisible(false)}
          onConfirm={() => handleGarminConnect()}
        >
          {translate('garmin_connect_modal_long_copy')}
        </Modal>
      }

      {/*GARMIN CONNECT LEARN MORE MODAL*/}
      {garminLearnMoreModalVisible &&
        <Modal
          header={garminLearnMoreModalTitle}
          secondaryButtonTitle={translate('close_lbl')}
          primaryButtonTitle={translate('continue_lbl')}
          primaryButtonDisabled={garminConnectProcessing}
          closeOnConfirm={false}
          onClose={() => {
            setGarminLearnMoreModalScreen(1);
            setGarminLearnMoreModalVisible(false);
          }}
          onConfirm={() => {
            if (garminLearnMoreModalScreen === 2) {
              handleGarminConnect();
            } else {
              setGarminLearnMoreModalTitle(translate('garmin_connect_modal_long_title'));
              setGarminLearnMoreModalCopy(translate('garmin_connect_modal_long_copy'));
              setGarminLearnMoreModalScreen(2);
            }
          }}
        >
          {garminLearnMoreModalCopy}
        </Modal>
      }

      {/* ONBOARDING */}
      { showOnboarding && (
        <DialogSequence
          type={onboardingType}
          userId={me.id}
          configOverride={onboardingConfig}
          smallHeader={true}

          onCancel={handleOnboardingClose}
        />
      )}
    </div>
  );
};

export default Overview;
