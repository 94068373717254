import React, {useState} from 'react';
import styles from './ResetPasswordRequest.module.scss';

import classNames from 'classnames';

import {useDispatch, useSelector} from 'react-redux';
import {requestResetPassword} from 'store/actions';

import {COMPANY_ID, CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';

import PoweredBy from 'ui/basic/micro-ui/PoweredBy';
import ResetPasswordRequestForm from '../../components/ResetPasswordRequestForm';
import ResetPasswordRequestSuccessForm from '../../components/ResetPasswordRequestSuccessForm';


const ResetPasswordRequest = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const error = useSelector(state => state.resetPasswordRequest && state.resetPasswordRequest.error);
  const resetPasswordRequestSuccessful = useSelector(state => state.resetPasswordRequest.status === 'valid');

  const handleResetPassword = (email) => {
    setEmail(email);
    dispatch(requestResetPassword(email, COMPANY_ID))
  };

  return (
    <div className={styles.resetPasswordRequest}>
      <div className={classNames(styles.container, styles[CONFIGURATION.toLowerCase()])}>
        {CONFIGURATION !== CONFIGURATION_OPTIONS.BLUQUIST &&
        <div className={styles.poweredBy}>
          <PoweredBy />
        </div>
        }

        {resetPasswordRequestSuccessful ?
          <ResetPasswordRequestSuccessForm email={email}/> :
          <ResetPasswordRequestForm
            error={error && error.error && error.error.errorMessage}
            onResetPassword={handleResetPassword}
          />
        }
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
