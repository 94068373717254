import React, { memo, useEffect, useState } from 'react';
import AssessmentRadioButton from '../AssessmentRadioButton';


const AssessmentRadioButtonGroup = memo((props) => {

  const { deselectable } = props;
  const [selectedValue, setSelectedValue] = useState(props.selectedValue);

  const handleKeyPress = (event) => {
    if (props.allowedKeys.includes(event.key)) {
      setSelectedValue(event.key);
      if (props.onChange) {
        props.onChange(event.key);
      }
    }
  };

  useEffect(() => {
    setSelectedValue(props.selectedValue);
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.items.map((item, index) => {
    return (
      <AssessmentRadioButton
        key={`assessment-radio-button-${index}`}
        name={props.name}
        title={item.label}
        style={{ marginBottom: '17px' }}
        checked={selectedValue === item.value}
        showAnimation={props.showAnimation}
        value={item.value}
        number={props.showNumbers && index + 1}
        onDeselect={!deselectable ? undefined : () => {
          setSelectedValue();
          if (props.onChange) {
            props.onChange();
          }
        }}
        onChange={(value) => {
          setSelectedValue(value);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
      />
    );
  });
});

AssessmentRadioButtonGroup.defaultProps = {
  showNumbers: false,
  allowedKeys: ['1', '2', '3', '4', '5', '6']
};

export default AssessmentRadioButtonGroup;
