// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useRef, useState} from 'react';
import styles from './GarminIndividualReport.module.scss';

// ASSETS
import {Icons} from 'assets/icons';
import {ReactComponent as BodyBatteryKeyVisual} from 'assets/balanced-you/garmin/keyvisuals/body-battery.svg';

// 3RD PARTY
import classNames from 'classnames';
import moment from "moment";

// OTHER COMPONENTS
import {Icon, Scrollable, CollapsibleNext} from 'ui/basic';
import GarminIcon from '../../basic/GarminIcon';
import GarminTrend from '../../basic/GarminTrend';
import Recommendations from './Recommendations';

// UTILS
import {useBreakpoint} from 'utils/hooks';
import {useTranslate} from 'utils/translator';
import {disableScrollingOnBody, enableScrollingOnBody} from 'utils/scrolling';

// STORE
import {useDispatch, useSelector} from 'react-redux';
import * as fromGarminSelectors from 'features/+garmin/store/garmin.selectors';
import * as fromGarminActions from 'features/+garmin/store/garmin.actions';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';


const STATISTICS_NUMBER_PER_PAGE = 5;

// CONFIG & DATA
// const Config = {};


// COMPONENT: GarminIndividualReport
const GarminIndividualReport = (props) => {
  // PROPS
  const { userScore, onClose } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const bp = useBreakpoint();
  const translate = useTranslate();
  const noSleepHintRef = useRef();

  const bodyBatteryClass = userScore && userScore.bodyBattery && userScore.bodyBattery.score && userScore.bodyBattery.score.class;
  const sleepClass = userScore && userScore.sleep && userScore.sleep.score && userScore.sleep.score.class;
  const stressClass = userScore && userScore.stress && userScore.stress.score && userScore.stress.score.class;

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);

  // GARMIN HISTORY: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const garminUserHistory = useSelector(fromGarminSelectors.selectGarminUserHistory);
  useEffect(() => {
    if (!garminUserHistory) {
      dispatch(fromGarminActions.getUserHistory());
    }
  }, [dispatch, garminUserHistory])

  const [mappedUserHistory, setMappedUserHistory] = useState();
  useEffect(() => {
    if (garminUserHistory && !mappedUserHistory) {
      const bodyBatteryHistory = garminUserHistory.bodyBattery;
      const sleepHistory = garminUserHistory.sleep;
      const stressHistory = garminUserHistory.stress;

      const internalMappedHistory = {};

      // body battery mapping
      bodyBatteryHistory.forEach(historyItem => {
        if (!historyItem || !historyItem.score.timestamp) {
          return;
        }

        const timestamp = historyItem.score.timestamp;

        if (!internalMappedHistory[timestamp]) {
          internalMappedHistory[timestamp] = {bodyBatteryClass: historyItem.score.class}
          return;
        }

        if (!internalMappedHistory[timestamp].bodyBatteryClass) {
          internalMappedHistory[timestamp].bodyBatteryClass = historyItem.score.class;
        }
      })

      // sleep mapping
      sleepHistory.forEach(historyItem => {
        if (!historyItem || !historyItem.score.timestamp) {
          return;
        }

        const timestamp = historyItem.score.timestamp;

        if (!internalMappedHistory[timestamp]) {
          internalMappedHistory[timestamp] = {sleepClass: historyItem.score.class}
          return;
        }

        if (!internalMappedHistory[timestamp].sleepClass) {
          internalMappedHistory[timestamp].sleepClass = historyItem.score.class;
        }
      })

      // stress mapping
      stressHistory.forEach(historyItem => {
        if (!historyItem || !historyItem.score.timestamp) {
          return;
        }

        const timestamp = historyItem.score.timestamp;

        if (!internalMappedHistory[timestamp]) {
          internalMappedHistory[timestamp] = {stressClass: historyItem.score.class}
          return;
        }

        if (!internalMappedHistory[timestamp].stressClass) {
          internalMappedHistory[timestamp].stressClass = historyItem.score.class;
        }
      })

      setMappedUserHistory(internalMappedHistory);
    }
  }, [garminUserHistory, mappedUserHistory]);

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, [bp]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  useEffect(() => {
    const noSleepHintElement = noSleepHintRef.current;
    let links = noSleepHintElement && noSleepHintElement.getElementsByTagName('a');

    if (!links || !links[0] || (links[0] && links[0]['target'])) {
      return;
    }

    links[0]['target'] = '_blank';
  }, []);

  // RENDER: Insight Box
  const renderInsightBox = () => {
    return (
      <>
        <span className={'blu-typeLabel'}>{translate(`body_battery_insight_${bodyBatteryClass}`)}</span>
        <div className={classNames('blu-typeLabel', 'marginTopXxs')}>{translate('garmin_insights_label')}</div>
        <div className={classNames('blu-typeCopy', 'marginTopXxs')}>
          {sleepClass && <div>{translate(`sleep_insight_${sleepClass}_bullets`)}</div>}
          {stressClass && <div>{translate(`stress_insight_${stressClass}_bullets`)}</div>}
        </div>
      </>
    )
  }


  // RENDER: GarminIndividualReport
  return (
    <div className={classNames(styles.garminIndividualReport)}>
      {/*HEADER*/}
      <div className={styles.header}>
        <span>{translate('body_battery_trend')}</span>
        <div
          className={styles.close}
          onClick={onClose}
        >
          <Icon icon={Icons.CloseBig}/>
        </div>
      </div>

      {/* SCROLLABLE CONTENT */}
      <div className={styles.scrollableContent}>
        <div className={styles.gridContent}>
          {/*CONTENT 1*/}
          <div className={styles.mainContentSmall}>
            {/*GENERAL SECTION*/}
            <section className={styles.general}>
              <div className={styles.text}>
                <div className='blu-typeS'>
                  {translate('garmin_ind_report_compact_header_title')}
                </div>
                <div className={classNames('blu-typeCopy', 'marginTopXs')}>
                  {translate('garmin_ind_report_compact_header_copy')}
                </div>
              </div>

              <BodyBatteryKeyVisual />
            </section>
          </div>

          {/*CONTENT 2*/}
          <div className={styles.mainContentLarge}>
            {/*TREND*/}
            <section className={styles.trend}>
              <GarminIcon
                looks='body-battery'
                size={(bp.isS || bp.isXs) ? 'M' : 'L'}
                scoreClass={bodyBatteryClass}
              />

              <div className={styles.left}>
                <span className={'blu-typeXxs'}>
                  {translate(`body_battery_${bodyBatteryClass}_title`)}
                </span>
                <div className={classNames('blu-typeCopy', 'marginTopXxs')}>
                  {translate(`body_battery_${bodyBatteryClass}_copy_long`)}
                </div>

                {!sleepClass &&
                  <div className={classNames('blu-typeHint', 'marginTopXxs')} ref={noSleepHintRef}>
                    {translate('sleep_no_value_hint')}
                  </div>
                }
              </div>
              <div className={styles.right}>
                <span className={'blu-typeXxs'}>{translate('garmin_insights_title')}</span>

                {(userScore.bodyBattery.trend && userScore.bodyBattery.trend.weekly && userScore.bodyBattery.trend.monthly) &&
                  <div className={styles.trends}>
                    <GarminTrend
                      label={translate('garmin_week_trend')}
                      direction={userScore.bodyBattery.trend.weekly ? userScore.bodyBattery.trend.weekly.direction : undefined}
                    />

                    <GarminTrend
                      label={translate('garmin_month_trend')}
                      direction={userScore.bodyBattery.trend.monthly ? userScore.bodyBattery.trend.monthly.direction : undefined}
                    />
                  </div>
                }

                <div className={classNames(
                  styles.insight,
                  {[styles.blue]: bodyBatteryClass === 'good' || bodyBatteryClass === 'medium-good'},
                  {[styles.red]: bodyBatteryClass === 'bad' || bodyBatteryClass === 'medium-bad'}
                )}>
                  {renderInsightBox()}
                </div>

              </div>
            </section>
          </div>

          {(garminUserHistory && mappedUserHistory) &&
            <div className={styles.mainContentSmall}>
              <div className={styles.statistics}>
                <span className={'blu-typeXxs'}>{translate('body_battery_statistics_title')}</span>
                <div className={styles.table}>
                  {/*TABLE HEADER*/}
                  <div className={styles.tHeader}>
                    <div className={styles.tHeaderItem}>{translate('date')}</div>
                    <div className={styles.tHeaderItem}>{translate('body_battery')}</div>
                    <div className={styles.tHeaderItem}>{translate('sleep_quality')}</div>
                    <div className={styles.tHeaderItem}>{translate('stress_tag')}</div>
                  </div>

                  <Scrollable
                    pagination drag
                    showPaginationNumbers
                    showPagerButtons
                    pageMargin={16}
                  >
                    {new Array(Math.ceil(Object.keys(mappedUserHistory).length / STATISTICS_NUMBER_PER_PAGE)).fill(1)
                      .map((value, index) => {
                        return (
                          <div className={styles.tRows} key={index}>
                            {Object.keys(mappedUserHistory)
                              // sort the dates descending
                              .sort((a, b) => b > a ? 1 : (b < a ? -1 : 0))
                              .slice(index * STATISTICS_NUMBER_PER_PAGE, (index * STATISTICS_NUMBER_PER_PAGE) + 5)
                              .map((date, index) => {
                                const formattedDate = moment.utc(date * 1000).format('DD.MM.YYYY');
                                return (
                                  <div className={styles.tRow} key={index}>
                                    <span className={'blu-typeCopy'}>{formattedDate}</span>
                                    <GarminIcon looks='body-battery' size='S' scoreClass={mappedUserHistory[date].bodyBatteryClass} />
                                    <GarminIcon looks='sleep' size='S' scoreClass={mappedUserHistory[date].sleepClass} />
                                    <GarminIcon looks='stress' size='S' scoreClass={mappedUserHistory[date].stressClass} />
                                  </div>
                                )
                              })}
                          </div>
                        )
                      })}
                  </Scrollable>
                </div>
              </div>
            </div>
          }

          <div className={styles.recommendationsMainContent}>
            <Recommendations
              userId={me.id}
              sleepClass={sleepClass}
              stressClass={stressClass}
              timestamp={userScore.bodyBattery.score && userScore.bodyBattery.score.timestamp}
            />

            {/*LEGAL*/}
            <section className={styles.legal}>
              <CollapsibleNext
                header={<span className='blu-typeLabel'>{translate('body_battery_data_collection_goals_title')}</span>}
                withBorders
              >{translate('body_battery_data_collection_goals_copy')}</CollapsibleNext>

              <CollapsibleNext
                header={<span className='blu-typeLabel'>{translate('body_battery_measurement_calculation_title')}</span>}
                withBorders
              >{translate('body_battery_measurement_calculation_copy')}</CollapsibleNext>

              <CollapsibleNext
                header={<span className='blu-typeLabel'>{translate('body_battery_data_protection_title')}</span>}
                withBorders
              >{translate('body_battery_data_protection_copy')}</CollapsibleNext>
            </section>
          </div>

        </div>
      </div>

    </div>
  );
};

export default GarminIndividualReport;
