import React, { useState, useEffect } from 'react';

// 3RD PARTY
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';

// OTHER COMPONENTS
import AssessmentNext from 'ui/molecules/AssessmentNext';

// UTILS
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';
import {
  initialiseAssessment,
  handleAssessmentAnswer
} from 'utils/assessment';

// STORE
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';


const Big5AssessmentPage = () => {

  // SPECIAL HOOKS
  const history = useHistory();

  // STORE
  const userId = useSelector(fromCurrentUserSelectors.getCurrentUserId);

  // GET ASSESSMENT DATA
  const assessmentId = ASSESSMENT_TYPES.BIG5;
  const [ loadingAssessment, setLoadingAssessment ] = useState(false);
  const [ questions, setQuestions ] = useState();
  const [ configOverride, setConfigOverride ] = useState();

  // initialise asssessment
  useEffect(() => {

    if (!userId || !assessmentId || loadingAssessment) {
      return;
    }

    setLoadingAssessment(true);

    initialiseAssessment({
      userId,
      assessmentId,
      handleExit: () => {
        history.push('/');
      }
    })
    .then(({ questions, assessment, prevAnswers }) => {

      setConfigOverride({
        title: assessment.title,
        description: assessment.description,

        allowBackNavigation: assessment.free_navigation,
        canContinueLater: assessment.multi_session,
        // modalHurryDelay: 5 * 1000,
        // modalHurryDelay: assessment.time_to_reminder * 1000,

        // // modalHelpContent: '[blu-markdown] **modal help content**',
        // // guidanceContent: '[blu-markdown] **guidance content**',
        // modalHelpContent: assessment.info_texts.question_help,
        // guidanceContent: assessment.info_texts.guidance_text,

        progress: assessment.progress,
        prevAnswers
      });

      setQuestions(questions);
    });
  }, [loadingAssessment, history, userId, assessmentId, questions]);

  // METHODS

  // HANDLES
  const handleCancel = () => {
    history.goBack();
  };

  const handleFinish = () => {
    history.push('/my-profile');
  };

  const handleAnswer = (answer) => {
    handleAssessmentAnswer(answer, assessmentId);
  };

  const handleAllAnswers = (answers, setFinishable, setErrorEnd) => {
    setFinishable(true);
  };

  // RENDER: Big5AssessmentPage
  return (
    <AssessmentNext
      type={'big5'}
      questions={questions}
      userId={userId}
      configOverride={configOverride}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer} // session prolongation
      onAllAnswers={handleAllAnswers}
    />
  )
};

export default Big5AssessmentPage;
