// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
// import styles from './BreadcrumbInside.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb, selectBreadcrumb } from 'features/framework/storeNext/routeSlice';


// CONFIG & DATA
// const Config = {};

// COMPONENT: BreadcrumbInside
const BreadcrumbInside = (props) => {
  // PROPS
  const { label, routesMustInclude, link } = props;

  // BREADCRUMB
  const dispatch = useDispatch();
  const breadcrumb = useSelector(selectBreadcrumb);

  const getBreadcrumbHash = ({ label, routesMustInclude, link}) => {
    return label + routesMustInclude + link;
  }


  useEffect(() => {
    // only dispatch if we have a label and
    // breadcrumb is different from current in store
    if (label && getBreadcrumbHash({ label, routesMustInclude, link }) !== getBreadcrumbHash(breadcrumb)) {
      console.log('dispatch', label, routesMustInclude, link);
      dispatch(setBreadcrumb({
        label,
        routesMustInclude,
        link
      }))
    }
  }, [dispatch, label, routesMustInclude, link, breadcrumb])

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: BreadcrumbInside
  return (
    <></>
  );
};

export default BreadcrumbInside;
