import React, {useEffect, useState} from 'react';
import styles from './SkillsResult.module.scss';

import {useDispatch, useSelector} from 'react-redux';

// ASSETS
import {IconsSvg} from 'assets/icons';

// UTILS
import { useTranslate } from 'utils/translator';
import {useBreakpoint} from 'utils/hooks';

import {getAssessment} from 'store/actions';

import {InfoTitle, Modal} from 'ui/basic';
import SkillsResultSkeleton from './SkillsResultSkeleton';
import SteppedScaleResultItem from '../SteppedScaleResultItem';


const SkillsResult = (props) => {
  const {
    assessmentType,
    results,
    noDataText,
    footerText,
    showExportIcon,
    exportView,
    onExport = () => {}
  } = props;

  const dispatch = useDispatch();
  const bp = useBreakpoint();
  const translate = useTranslate();

  const DownloadIcon = IconsSvg.DownloadSvg;

  const [infoPageIsVisible, setInfoPageIsVisible] = useState(false);
  const [infoPageTitle, setInfoPageTitle] = useState();
  const [infoPageDescription, setInfoPageDescription] = useState();

  const assessment = useSelector(state => state.assessments && state.assessments[assessmentType])

  const [data, setData] = useState([]);
  useEffect(() => {
    if (!assessment || !assessment.resultSchema || !results) {
      return;
    }

    const mappedData = assessment.resultSchema.map(resultSchemaItem => {
      const dimensionResultItem = results.find(resultItem => resultItem.id === resultSchemaItem.id);

      return {
        id: resultSchemaItem.id,
        name: resultSchemaItem.name,
        result: dimensionResultItem && dimensionResultItem.result,
        referenceValue: dimensionResultItem && dimensionResultItem.range && dimensionResultItem.range[0],
        description: resultSchemaItem.description,
        subDimensions: resultSchemaItem.subDimensions?.map(subDimension => {
          const subDimensionResultItem = results.find(resultItem => resultItem.id === subDimension.id);
          return {
            id: subDimension.id,
            name: subDimension.name,
            description: subDimension.description,
            result: subDimensionResultItem && subDimensionResultItem.result,
            referenceValue: subDimensionResultItem && subDimensionResultItem.range && subDimensionResultItem.range[0]
          }
        })
      }
    })

    setData(mappedData);
  }, [assessment, results]);

  useEffect(() => {
    if (!assessment || (assessment && !assessment.resultSchema)) {
      dispatch(getAssessment(assessmentType));
    }
  }, [dispatch, assessmentType, assessment]);


  if (!data || data.length === 0 || !assessment.resultSchema) {
    return (
      <div className={styles.skillsResult}>
        <SkillsResultSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.skillsResult}>
      <div id={'container-id'} className={styles.container}>
        <div className={styles.mainHeader}>
          {exportView ?
            <span className='blu-typeLabel'>{assessment.title}</span> :
            <InfoTitle
              title={assessment.title}
              className={styles.infoTitle}
              onClick={() => {
                setInfoPageTitle(assessment.title);
                setInfoPageDescription(assessment.description);
                setInfoPageIsVisible(true);
              }}
            />
          }

          {(!bp.isXs && !exportView && showExportIcon) &&
          <DownloadIcon onClick={onExport}/>
          }
        </div>

        {noDataText && <div className={styles.noDataText}>{noDataText}</div>}

        {!noDataText &&
          <>
            <div className={styles.rows}>
              {data.map((dimension, index) => (
                <SteppedScaleResultItem
                  key={`dimension-${index}`}
                  data={dimension}
                  segmentsCount={assessment && assessment.renderReferenceMax}
                  isExpandable={!exportView}
                  onInfoClick={(subDimensionIndex, subDimension) => {
                    setInfoPageTitle(subDimension && subDimension.name);
                    setInfoPageDescription(subDimension && (subDimension.description || subDimension.name));
                    setInfoPageIsVisible(true);
                  }}
                />
              ))}
            </div>

            {footerText && <div className={styles.footerText}>{footerText}</div>}
          </>
        }

        {infoPageIsVisible &&
        <Modal
          header={infoPageTitle}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          onConfirm={() => setInfoPageIsVisible(false)}
          onClose={() => setInfoPageIsVisible(false)}
        >
          {infoPageDescription}
        </Modal>
        }
      </div>
    </div>
  )
};

export default SkillsResult;
