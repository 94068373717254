// import React from 'react';

// INTERMISSIONS
import { Start } from '../components/onboarding/candidates/Start';
import { Strengths } from '../components/onboarding/candidates/Strengths';
import { Job } from '../components/onboarding/candidates/Job';
import { Potential } from '../components/onboarding/candidates/Potential';
import { Data } from '../components/onboarding/candidates/Data';
import { StartAssessments } from '../components/onboarding/candidates/StartAssessments';

// OTHER COMPONENTS

export const candidateOnboardingConfig = {
  id: 'candidateOnboarding',
  title: 'introduction_header_title',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 0,
  pages: [
    // START
    {
      isIntermission: true,
      showControls: true,
      noPrev: true,
      Component: Start
    },
    // STRENGTHS
    {
      isIntermission: true,
      showControls: true,
      Component: Strengths
    },
    // JOB
    {
      isIntermission: true,
      showControls: true,
      Component: Job
    },
    // POTENTIAL
    {
      isIntermission: true,
      showControls: true,
      Component: Potential,
    },
    // DATA
    {
      isIntermission: true,
      showControls: true,
      Component: Data,
    },
    // START ASSESSMENTS
    {
      isIntermission: true,
      showControls: true,
      noNext: true,
      Component: StartAssessments,
    },
  ]
};
