import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/teamOverview';
import * as fromActions from 'store/actions/teamOverview';
import * as fromAdminActions from 'store/actions/admin';
import {all} from '@redux-saga/core/effects';
import {get, _delete, post} from 'api';
import * as api from 'api';
import {LIMIT} from 'utils/configuration/const/pagination';
import {PLATFORM_ROLES} from 'utils/configuration/const/roles';

function* getTeams({payload}) {
  try {
    let limit = LIMIT, offset, sort, sortOrder, search;

    if (payload.requestDto) {
      const requestDto = payload.requestDto;
      limit = requestDto.limit ? requestDto.limit : LIMIT;
      offset = requestDto.offset;
      sort = requestDto.sort;
      sortOrder = requestDto.sortOrder;
      search = requestDto.search;
    }

    const { status, ok, data, headers } = yield call(get, 'core/company/teams', {
      limit,
      offset,
      sort,
      sortOrder,
      search
    });

    if (ok && status === 200) {
      yield put(fromActions.getTeamsFulfilled({
        teams: data.teams.map(team => ({
          id: team.id,
          name: team.name,
          type: team.type || '-',
          rolesCount: 11,
          employeesCount: team.users.length,
          status: {
            percentage: 80,
            label: '6 Rollen besetzt'
          }
        })
      ),
        totalCount: (headers && headers['x-total-result-count']) ? headers['x-total-result-count'] : 10
      }));
    }
    else {
      yield put(fromActions.getTeamsRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getTeamsRejected({ error }));
  }
}

function* getTeamUsers({payload}) {
  try {
    const {teamId} = payload;

    const params = new URLSearchParams();
    params.append('expand', 'user');
    params.append('expand', 'organizationalRole');

    const { status, ok, data } = yield call(get, `core/company/teams/${teamId}/users`, params);

    if (ok && status === 200) {
      yield put(fromActions.getTeamUsersFulfilled({teamUsers: data.users.map(teamUser => {
        const {user} = teamUser;

        let name = user.mail;
        if (user.firstName) {
          name = user.firstName + ' ' + user.lastName;
        }

        return {
          id: user.id,
          name,
          email: user.mail,
          orgRole: teamUser.organizationalRole ? teamUser.organizationalRole.name : '',
          position: user.companyData.profession,
          userGroup: teamUser.role,
          isTeamLead: [PLATFORM_ROLES.TEAM_LEADER].includes(teamUser.role),
          registrationStatus: user.registrationStatus,
          userPicture: user.userPicture
        }
      })
      }));
    }
    else {
      yield put(fromActions.getTeamUsersRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getTeamUsersRejected({ error }));
  }
}

function* getTeam({payload}) {
  try {
    const {teamId} = payload;

    // fetch the team
    const { status, ok, data } = yield call(get, `core/company/teams/${teamId}`, {expand: 'positions'});
    let team = {...data};

    if (ok && status === 200) {
      const params = new URLSearchParams();
      params.append('expand', 'user');
      params.append('expand', 'organizationalRole');

      // fetch team users with detailed data
      const { status, ok, data } = yield call(get, `core/company/teams/${teamId}/users`, params);

      if (ok && status === 200) {
        team.users = data.users.map(teamUser => {
          const {user} = teamUser;

          let name = user.mail;
          if (user.firstName) {
            name = user.firstName + ' ' + user.lastName;
          }

          return {
            id: user.id,
            name,
            email: user.mail,
            orgRole: teamUser.organizationalRole ? teamUser.organizationalRole.name : '',
            position: user.companyData.profession,
            userGroup: teamUser.role,
            isTeamLead: [PLATFORM_ROLES.TEAM_LEADER].includes(teamUser.role),
            registrationStatus: user.registrationStatus,
            userPicture: user.userPicture
          }
        });
      }

      yield put(fromActions.getTeamFulfilled({team}));
    }
    else {
      yield put(fromActions.getTeamRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getTeamRejected({ error }));
  }
}

function* getTeamTypes() {
  try {
    const { status, ok, data } = yield call(get, 'core/config/team/teamTypes');

    if (ok && status === 200) {
      yield put(fromActions.getTeamTypesFulfilled({teamTypes: data.content}));
    }
    else {
      yield put(fromActions.getTeamTypesRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getTeamTypesRejected({ error }));
  }
}

function* addUsers({payload}) {
  try {
    const {teamId, mails = [], users = []} = payload.addUsersDto;

    let addUsersViaMailsResponse;
    if (mails.length > 0) {
      addUsersViaMailsResponse = yield call(api.put, `/core/company/teams/${teamId}/users`, {mails})
    }

    let addUsersViaIdsResponse;
    if (users.length > 0) {
      addUsersViaIdsResponse = yield call(api.put, `/core/company/teams/${teamId}/users`, {users})
    }

    // check both response statuses
    if (mails.length > 0 && users.length > 0) {
      if (addUsersViaMailsResponse.ok && addUsersViaIdsResponse.ok) {
        yield put(fromActions.addUsersFulfilled());
        yield put(fromAdminActions.addCompanyUsersFulfilled());
        yield put(fromActions.getTeam(teamId));
      } else {
        yield put(fromActions.addUsersRejected({ error: addUsersViaIdsResponse.data }));
      }
    }

    // check response status of addUsersViaMailsResponse
    if (mails.length > 0 && users.length === 0) {
      if (addUsersViaMailsResponse.ok) {
        yield put(fromActions.addUsersFulfilled());
        yield put(fromAdminActions.addCompanyUsersFulfilled());
        yield put(fromActions.getTeam(teamId));
      } else {
        yield put(fromActions.addUsersRejected({ error: addUsersViaIdsResponse.data }));
      }
    }

    // check response of addUsersViaIdsResponse
    if (users.length > 0 && mails.length === 0) {
      if (addUsersViaIdsResponse.ok) {
        yield put(fromActions.addUsersFulfilled());
        yield put(fromActions.getTeam(teamId));
      } else {
        yield put(fromActions.addUsersRejected({ error: addUsersViaIdsResponse.data }));
      }
    }
  }
  catch(error) {
    yield put(fromActions.addUsersRejected({ error }));
  }
}

function* deleteUser({payload}) {
  try {
    const {teamId, userId} = payload;

    const { status, ok, data } = yield call(_delete, `/core/company/teams/${teamId}/users/${userId}`);

    if (ok && status === 200) {
      yield put(fromActions.getTeam(teamId));
      yield put(fromActions.getPositions(teamId));
      yield put(fromActions.getTeamProfiles(teamId));
      yield put(fromActions.deleteUserFulfilled());
    }
    else {
      yield put(fromActions.deleteUserRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.deleteUserRejected({ error }));
  }
}

function* addPositions({payload}) {
  try {
    const {teamId, roles} = payload;

    yield all(roles.map(role => call(api.put, `/teammapping/teams/${teamId}/positions`, {role})));

    yield put(fromActions.addPositionsFulfilled());
    yield put(fromActions.getTeam(teamId));
    yield put(fromActions.getPositions(teamId));
  } catch (error) {
    yield put(fromActions.addPositionsRejected({error}));
  }
}

function* getPositions({payload}) {
  try {
    const teamId = payload.teamId;

    const { status, ok, data } = yield call(get, `/teammapping/teams/${teamId}/positions`, {expand: 'role'});

    if (ok && status === 200) {
      yield put(fromActions.getPositionsFulfilled({positions: data.positions}));
    } else {
      yield put(fromActions.getPositionsRejected({error: data}));
    }
  }
  catch(error) {
    yield put(fromActions.getPositionsRejected({ error }));
  }
}

function* deletePosition({payload}) {
  try {
    const {teamId, positionId} = payload.deletePositionDto;

    const { status, ok, data } = yield call(_delete, `teammapping/teams/${teamId}/positions/${positionId}`);

    if (ok && status === 200) {
      yield put(fromActions.deletePositionFulfilled());
      yield put(fromActions.getPositions(teamId));
    } else {
      yield put(fromActions.deletePositionRejected({error: data}));
    }
  }
  catch(error) {
    yield put(fromActions.deletePositionRejected({ error }));
  }
}

function* fillPosition({payload}) {
  try {
    const {teamId, positionId, userId, orgRoleId} = payload.fillPositionDto;

    // if org role is not yet assigned to the user, before filling the position it should be assigned first
    let addOrgRoleResponse;
    if (orgRoleId) {
      addOrgRoleResponse= yield call(post, `/rolemapping/users/${userId}`, {
        role: orgRoleId
      });
    }

    if ((orgRoleId && addOrgRoleResponse.ok) || !orgRoleId) {
      const {status, ok, data} = yield call(api.put, `/teammapping/teams/${teamId}/positions/${positionId}`, {
        user: userId,
        platformRole: PLATFORM_ROLES.TEAM_MEMBER
      });

      if (ok && status === 200) {
        yield put(fromActions.fillPositionFulfilled());
        yield put(fromActions.getTeam(teamId));
        yield put(fromActions.getPositions(teamId));
      } else {
        yield put(fromActions.fillPositionRejected({error: data.error}));
      }
    } else {
      yield put(fromActions.fillPositionRejected({error: addOrgRoleResponse.data.error}));
    }
  }
  catch(error) {
    yield put(fromActions.fillPositionRejected({ error }));
  }
}

function* getTeamProfiles({payload}) {
  try {
    const {teamId} = payload;

    const { status, ok, data } = yield call(get, `teammapping/teams/${teamId}/profile/individual`);
    const teamProfiles = data.profiles;

    if (ok && status === 200) {
      //fetch team users
      const { data } = yield call(get, `core/company/teams/${teamId}/users`, {expand: 'user'});
      const teamUsers = data.users;

      //TODO: remove this code block after back-end starts returning user with more detailed data
      teamProfiles.forEach(profile => {
        profile.results.forEach(r => {
          //setting user data for dimension values
          r.values.forEach(value => {
            const u = teamUsers.find(teamUser => teamUser.user.id === value.user);
            if (u) {
              value.user = {
                id: u.user.id,
                name: u.user.firstName ? `${u.user.firstName} ${u.user.lastName}` : u.user.mail
              }
            }
          })

          //setting user data for sub dimension values
          r.subDimensions.forEach(subDimension => {
            subDimension.values.forEach(value => {
              const u = teamUsers.find(teamUser => teamUser.user.id === value.user);
              if (u) {
                value.user = {
                  id: u.user.id,
                  name: u.user.firstName ? `${u.user.firstName} ${u.user.lastName}` : u.user.mail
                }
              }
            })
          })

        })
      })

      yield put(fromActions.getTeamProfilesFulfilled({teamProfiles: teamProfiles}));
    } else {
      yield put(fromActions.getTeamProfilesRejected({error: data}));
    }
  }
  catch(error) {
    yield put(fromActions.getTeamProfilesRejected({ error }));
  }
}

function* updateTeam({payload}) {
  try {
    const {id, name, type, details, usersToAdd = [], usersToRemove = []} = payload.updateTeamDto;

    const { status, ok, data } = yield call(post, `core/company/teams/${id}`, {
      name,
      type,
      details
    });

    if (ok && status === 200) {
      if (usersToAdd.length > 0 || usersToRemove.length > 0) {
        yield call(post, `core/company/teams/${id}/users`, {
          add: usersToAdd.length > 0 ? usersToAdd : undefined,
          remove: usersToRemove.length > 0 ? usersToRemove : undefined
        });
      }

      yield put(fromActions.updateTeamFulfilled());
    } else {
      yield put(fromActions.updateTeamRejected({error: data.error}));
    }
  } catch(error) {
    yield put(fromActions.updateTeamRejected({ error }));
  }
}

function* updateUserRole({payload}) {
  try {
    const {teamId, userId, role} = payload.updateUserRoleDto;

    const { status, ok, data } = yield call(post, `core/company/teams/${teamId}/users/${userId}`, {role});

    if (ok && status === 200) {
      yield put(fromActions.updateUserRoleFulfilled({updatedUserRole: role}));
      yield put(fromActions.getTeamUsers(teamId));
    } else {
      yield put(fromActions.updateUserRoleRejected({error: data.error}));
    }
  } catch(error) {
    yield put(fromActions.updateUserRoleRejected({ error }));
  }
}

export function* watchGetTeams() {
  yield takeEvery(fromActionTypes.GET_TEAMS, getTeams);
}

export function* watchGetTeam() {
  yield takeEvery(fromActionTypes.GET_TEAM, getTeam);
}

export function* watchGetTeamUsers() {
  yield takeEvery(fromActionTypes.GET_TEAM_USERS, getTeamUsers);
}

export function* watchGetTeamTypes() {
  yield takeEvery(fromActionTypes.GET_TEAM_TYPES, getTeamTypes);
}

export function* watchAddUsers() {
  yield takeEvery(fromActionTypes.ADD_USERS, addUsers);
}

export function* watchDeleteUser() {
  yield takeEvery(fromActionTypes.DELETE_USER, deleteUser);
}

export function* watchAddPositions() {
  yield takeEvery(fromActionTypes.ADD_POSITIONS, addPositions);
}

export function* watchGetPositions() {
  yield takeEvery(fromActionTypes.GET_POSITIONS, getPositions);
}

export function* watchDeletePosition() {
  yield takeEvery(fromActionTypes.DELETE_POSITION, deletePosition);
}

export function* watchFillPosition() {
  yield takeEvery(fromActionTypes.FILL_POSITION, fillPosition);
}

export function* watchGetTeamProfiles() {
  yield takeEvery(fromActionTypes.GET_TEAM_PROFILES, getTeamProfiles);
}

export function* watchUpdateTeam() {
  yield takeEvery(fromActionTypes.UPDATE_TEAM, updateTeam);
}

export function* watchUpdateUserRole() {
  yield takeEvery(fromActionTypes.UPDATE_USER_ROLE, updateUserRole);
}
