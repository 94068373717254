// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './MultiSelect.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext } from 'ui/basic';
import BluCSSTransition from 'ui/basic/containers/BluCSSTransition';
import DropDownSearchable from '../DropDownSearchable';

// UTILS
import { useTranslate } from 'utils/translator';
import { capitalise } from 'utils/textTools';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: MultiSelect
const MultiSelect = (props) => {
  // PROPS
  const {
    size = '', // M (default), L, Responsive
    options = [],
    onChange = (selectedOptions) => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const [ optionsInternal, setOptionsInternal ] = useState([]);
  useEffect(() => {
    setOptionsInternal([...options]);
  }, [options])


  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const selectIndex = (index) => {
    const newOptions = [ ...optionsInternal ];
    const currentOption = newOptions[index];
    const wasActive = currentOption.selected;

    // copy indices and remove current index

    // add it again and then sort if it was not active before
    if (wasActive) {
      delete currentOption.selected;
    }
    else {
      currentOption.selected = true;
    }

    setOptionsInternal(newOptions);

    const selectedOptions = newOptions.filter((option) => option.selected);
    onChange(selectedOptions, newOptions);
  };

  const setOptionValue = (index, value = '') => {
    const newOptions = [ ...optionsInternal ];
    const currentOption = newOptions[index];

    currentOption.value = value;

    setOptionsInternal(newOptions);

    const selectedOptions = newOptions.filter((option) => option.selected);
    onChange(selectedOptions, newOptions);
  };

  const handleKey = (event, index) => {
    const key = event.key;

    switch(key) {
      case 'Enter':
      case ' ': {
        event.stopPropagation();
        selectIndex(index);
        return;
      }
      default: {
        return;
      }
    }
  };

  // RENDER: MultiSelect
  return (
    <div className={classNames(styles.multiSelect, {
      [styles[`size${capitalise(size)}`]]: size
    })}>

      <div className={styles.options}>
        { options.map((option, index) => (
          <div key={index} tabIndex={0}
            className={classNames(styles.option, {
              [styles.active]: option.selected,
            })}
            onClick={() => {
              selectIndex(index);
            }}
            onKeyUp={(event) => {
              handleKey(event, index);
            }}
          >

            {/* INPUT */}
            { option.isInput && (
              <>
                <div className={styles.label}> { translate(option.label) || option.label } </div>
                <BluCSSTransition in={option.selected} classNames={{ ...styles }}>
                  <div className={styles.inputContainer}>
                    <InputNext
                      size={size}
                      label={translate(option.inputLabel) || option.inputLabel}
                      type={option.inputType}
                      value={option.value || ''}
                      onChange={(value) => setOptionValue(index, value)}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </div>
                </BluCSSTransition>
              </>
            )}

            {/* DROPDOWN */}
            { option.isDropDown && (
              <>
                <div className={styles.label}> { translate(option.label) || option.label } </div>
                <BluCSSTransition in={option.selected} classNames={{ ...styles }}>
                  <div className={styles.inputContainer}>
                    <DropDownSearchable
                      size={size}
                      placeholder={translate(option.dropDownPlaceholder) || option.dropDownPlaceholder}
                      filterPlaceholder={translate(option.dropDownFilterPlaceholder) || option.dropDownFilterPlaceholder}
                      activeValue={option.value || ''}
                      options={option.isDropDown}
                      onChange={(dropDownOption) => setOptionValue(index, dropDownOption?.value)}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </div>
                </BluCSSTransition>
              </>
            )}

            {/* CUSTOM */}
            { option.isCustom && (
              <>
                <div className={styles.label}> { translate(option.label) || option.label } </div>
                <BluCSSTransition in={option.selected} classNames={{ ...styles }}>
                  <div className={styles.inputContainer}>
                  { option.isCustom(option.value, (value) => { setOptionValue(index, value)}) }
                  </div>
                </BluCSSTransition>
              </>
            )}

            {/* DEFAULT */}
            { !option.isInput && !option.isDropDown && !option.isCustom && (
              <>
                { translate(option.label) || option.label }
              </>
            )}
          </div>
        ))}
      </div>

    </div>
  );
};

export default MultiSelect;
