import React from 'react';
import * as css from 'styles-imports/shared.scss';

const GAP_WIDTH = 4;
const HEIGHT = 20;
const SEGMENT_HEIGHT = 4;

const SegmentedLineDiagramNext = (props) => {
  const {
    width = 304,
    segmentsCount = 5,
    score,
    referenceValue
  } = props;

  const margins = (segmentsCount - 1) * GAP_WIDTH;
  const innerWidth = width - margins;

  const segmentWidth = innerWidth / segmentsCount;
  let start = 0;
  let rest = score;

  const rectangles = [];
  const filledRectangles = [];

  const renderRectangles = (yPos) => {
    for (let i=1; i<=segmentsCount; i++) {
      rectangles.push(
        <rect
          key={`index-${i}`}
          x={start}
          y={yPos}
          width={segmentWidth}
          height={SEGMENT_HEIGHT}
          rx='2'
          fill={css.colorGrey3}
        />
      );

      start = start + (segmentWidth + GAP_WIDTH);
    }

    return rectangles;
  };

  const renderReferenceLine = () => {
    let start = (segmentWidth * referenceValue) + ((referenceValue - 1) * GAP_WIDTH);
    // change the start position to the left to avoid it from being hidden
    if (width - start < 3) {
      start -= (3 - (width - start));
    }

    return <rect x={start} y={-8} width={3} height={HEIGHT} fill={css.colorPrimary1}/>
  }

  const renderFilledRectangles = (yPos = 0) => {
    start = 0;

    for (let i=1; i<=segmentsCount; i++) {
      if (rest === 0) {
        return filledRectangles;
      }

      if (rest - 1 >= 0) {
        filledRectangles.push(
          <rect key={`index-${i}`} x={start} y={yPos} width={segmentWidth} height={SEGMENT_HEIGHT} rx="2" fill={css.colorPrimary1}/>
        );

        start = start + (segmentWidth + GAP_WIDTH);
        rest = rest - 1;
        continue;
      }

      if (rest - 1 < 0) {
        filledRectangles.push(
          <rect key={`index-${i}`} x={start} y={yPos} width={segmentWidth * rest} height={SEGMENT_HEIGHT} rx="2" fill={css.colorPrimary1}/>
        );

        return filledRectangles;
      }
    }

    return filledRectangles;
  };

  return (
    <svg
      viewBox={`0 0 ${width} ${SEGMENT_HEIGHT}`}
      width={width}
      height={HEIGHT}
    >
      {renderRectangles(0)}
      {renderFilledRectangles()}

      {referenceValue && renderReferenceLine()}
    </svg>
  )

};

export default SegmentedLineDiagramNext;
