export const DEFAULT_INFO = `Persönlichkeitsmerkmale sind zeitlich stabile Eigenschaften einer Person,
          durch die die Menschen sich unterscheiden. Sie bilden den Grundstein für unser Handeln,
          unsere Vorlieben und unsere Stärken und Schwächen​. Ob privat oder im Beruf –
          seine eigene Persönlichkeit oder die anderer Personen
          zu kennen ermöglicht das Verständnis bestimmter Verhaltensmuster​.`;

export const DEFAULT_DIMENSIONS = [
  'Agreeableness',
  'Conscientiousness',
  'Emotional stability',
  'Extraversion',
  'Openness to experience'
];

export const DEFAULT_FACETS = [
  [
    'Trust',
    'Straightforwardness',
    'Altruism',
    'Compliance',
    'Modesty',
    'Tender-mindedness'
  ],
  [
    'Competence',
    'Order',
    'Dutifulness',
    'Achievement Striving',
    'Self-discipline',
    'Deliberation'
  ],
  [
    'Anxiety',
    'Hostility',
    'Depression',
    'Self-consciousness',
    'Impulsiveness',
    'Vulnerability'
  ],
  [
    'Warmth',
    'Gregariousness',
    'Assertiveness',
    'Activity',
    'Excitement-Seeking',
    'Positive Emotions'
  ],
  ['Fantasy', 'Aesthetics', 'Feelings', 'Actions', 'Ideas', 'Values']
];

export const DEFAULT_CALLOUT_VERY_LOW =
  'Personen mit einem sehr niedrigen Maß an "Verträglichkeit" sind sehr bestrebt danach' +
  'ihre eigene Meinung durchzusetzen, auch wenn dies bedeutet sich auf längere Diskussionen' +
  'oder Streitigkeiten einlassen zu müssen. Dadurch wirken sie auf andere oft unnachgiebig' +
  'und kompromisslos. Für Positionen, in denen oft verhandelt werden muss,' +
  'kann dieses Verhalten von Vorteil sein. Zudem sind für diese Menschen Tätigkeitsbereiche sinnvoll,' +
  'in denen sie selbst entscheiden können und kaum Teamarbeit gefragt ist.';

export const DEFAULT_CALLOUT_LOW =
  'Personen mit einem niedrigen Maß an "Verträglichkeit" scheuen selten Diskussionen' +
  'und Konflikte wenn es darum geht die eigene Meinung durchzusetzen.' +
  ' Um sich von anderen Standpunkten überzeugen zu lassen, bedarf es einer rationalen Argumentation.' +
  ' Das Arbeiten im Team fällt ihnen schwerer und sie arbeiten lieber unabhängig.' +
  'Solange die Teamziele mit den eigenen übereinstimmen, sind sie aber durchaus bereit im Team mitzuarbeiten.';

export const DEFAULT_CALLOUT_MEDIUM =
  'Personen mit einem mittleren Maß an "Verträglichkeit" agieren sowohl kompromissbereit und' +
  ' kooperativ als auch durchsetzungsfähig, wenn es darum geht ihre eigene Meinung zu verdeutlichen.' +
  ' Es fällt ihnen nicht schwer sich in ein Team einzufügen, da sie eigene' +
  ' Ziele verfolgen aber trotzdem dem Team entgegenkommen. Um ihre Fähigkeiten optimal entfalten zu können,' +
  'werden diese Personen am besten in Positionen eingesetzt,' +
  'die Teamarbeit und eigenverantwortliches Handeln gleichermaßen erfordern.';

export const DEFAULT_CALLOUT_HIGH =
  'Personen mit einem hohen Maß an "Verträglichkeit" neigen  zu Nachgiebigkeit und' +
  'starker Anpassungsfähigkeit. Sie fühlen sich sehr wohl innerhalb von Teamaktivitäten' +
  'und agieren sehr hilfsbereit und kooperativ. Die persönlichen Bedürfnisse werden' +
  'in Gruppenaktivitäten häufig denen der Gruppe untergeordnet und angepasst.' +
  'Da sie Konfrontationen gerne aus dem Weg gehen, kann es leicht passieren,' +
  'dass sie ihre eigenen Ziele nicht durchsetzen können.';

export const DEFAULT_CALLOUT_VERY_HIGH =
  'Personen mit einem sehr hohen Maß an "Verträglichkeit" haben in der Regel ein stark' +
  'ausgeprägtes Harmoniebedürfnis und versuchen jegliche Konfrontation zu meiden' +
  'auch wenn dafür die eigenen Bedürfnisse und Interessen nicht zu tragen kommen.' +
  'Zudem zeichnen sie sich durch ihre meist sehr hohe Hilfsbereitschaft und' +
  ' Anpassungsfähigkeit aus. Sie arbeiten sehr gerne in Gruppen und unterstützen' +
  'ihre Teammitglieder tatkräftig. Tätigkeiten, in denen eine hohe Durchsetzungsfähigkeit' +
  'und ein gewisses Konfliktpotenzial vorhanden ist, sind eher ungeeignet für diese Personen.';
