import {LOADING, LOADING_FULFILLED} from '../actions';

export default (state = false, action) => {
  switch (action.type) {
    case LOADING:
      return action.payload;
    case LOADING_FULFILLED:
      return action.payload;
    default:
      return state;
  }
};
