// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './Debug.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';


// OTHER COMPONENTS
import { Button, Card, TextArea, Toast } from 'ui/basic';

// UTILS

// STORE
import { useDispatch } from 'react-redux';
import { setShowTranslationKeys } from 'store/actions/debug';

// CONFIG & DATA
import { ALLOW_TRANSLATION_DISABLE } from 'utils/configuration';

// COMPONENT: Debug
const Debug = (props) => {
  // PROPS
  // const { children } = props;

  // SEPCIAL HOOKS
  const history = useHistory();

  // LOCAL STORAGE
  const [ localStorageString, setLocalStorageString ] = useState('');
  const [ showToast, setShowToast ] = useState();
  const [ clipboardError, setClipboardError ] = useState();

  // effects
  useEffect(() => {
    readLocalStorage();
  }, []);

  // methods
  const readLocalStorage = () => {
    setLocalStorageString(JSON.stringify(localStorage, null, 2));
  };

  // event handles
  const handleCopy = () => {
    navigator.clipboard.writeText(
      JSON.stringify(localStorage, null, 2)
    )
    .then(() => {
      setShowToast(true);
    },
    (error) => {
      setShowToast(true);
      setClipboardError(error);
    })
  };
  const handleClear = () => {
    localStorage.clear();
    readLocalStorage();
  };


  // TRANSLATION KEYS TOGGLE
  // store
  const dispatch = useDispatch();

  // event handles
  const handleShowTranslationKeys = () => {
    history.push('/');
    dispatch(setShowTranslationKeys(true));

  }

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: Debug
  return (
    <div className={classNames(styles.debug)}>

      {/* CONTENT */}
      <div className={styles.content}>
        <Card>
          <h4>Debug</h4>
          <br/>
          <br/>


          {/* LOCAL STORAGE */}
          <h5>Local Storage</h5>
          <br/>
          <TextArea value={localStorageString} />

          {/* BUTTONS */}
          <div className={styles.buttons}>

            <Button size='S' onClick={handleCopy}> Copy </Button>

            <Button looks='tertiary' size='S' onClick={handleClear}> Clear </Button>

          </div>
          <br/>
          <br/>
          <br/>


          {/* TRANSLATIONS */}
          { ALLOW_TRANSLATION_DISABLE && (
            <>
              <h5>Translations</h5>
              <br/>
              <strong>Show translation keys</strong>
              <br/>
              <br/>
              <Button size='S' looks='secondary'
                onClick={handleShowTranslationKeys}>
                Show Translation Keys
              </Button>
            </>
          )}

        </Card>
      </div>

      {/* TOAST */}
      { showToast &&
        <Toast
          headline='Local Storage Info'
          message={ clipboardError || 'Copied to clipboard successfully'}
          onClose={() => {
          setShowToast(false);
          setClipboardError(undefined);
        }} />
      }
    </div>
  );
};

export default Debug;
