import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/survey';
import * as fromActions from 'store/actions/survey';
import {get, post} from 'api';

import {LIMIT} from 'utils/configuration/const/pagination';

function* submitFeedback({payload}) {
  try {
    const { status, ok, data } = yield call(post, '/core/platform/feedback', payload.feedbackDto);
    if (ok && status === 200) {
      yield put(fromActions.submitFeedbackFulfilled());
    }
    else {
      yield put(fromActions.submitFeedbackRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.submitFeedbackRejected({ error }));
  }
}

function* submitContentFeedback({payload}) {
  try {
    const { status, ok, data } = yield call(post, '/core/platform/feedback/content', payload.feedbackDto);
    if (ok && status === 200) {
      yield put(fromActions.submitContentFeedbackFulfilled());
    } else {
      yield put(fromActions.submitContentFeedbackRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.submitContentFeedbackRejected({ error }));
  }
}

function* getContentFeedback({payload}) {
  try {
    let limit = LIMIT, offset, sort, sortOrder, search;

    if (payload.requestDto) {
      const requestDto = payload.requestDto;
      limit = requestDto.limit ? requestDto.limit : LIMIT;
      offset = requestDto.offset;
      sort = requestDto.sort;
      sortOrder = requestDto.sortOrder;
      search = requestDto.search;
    }

    const { status, ok, data, headers } = yield call(get, 'admin/feedback/content', {
      limit,
      offset,
      sort,
      sortOrder,
      search
    });

    if (ok && status === 200) {
      yield put(fromActions.getContentFeedbackFulfilled({
        contentFeedback: data.contentFeedback,
        totalCount: (headers && headers['x-total-result-count']) ? headers['x-total-result-count'] : 0
      }));
    } else {
      yield put(fromActions.getContentFeedbackRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getContentFeedbackRejected({ error }));
  }
}

export function* watchSubmitFeedback() {
  yield takeEvery(fromActionTypes.SUBMIT_FEEDBACK, submitFeedback);
}

export function* watchSubmitHelpfulInfoFeedback() {
  yield takeEvery(fromActionTypes.SUBMIT_CONTENT_FEEDBACK, submitContentFeedback);
}

export function* watchGetContentFeedback() {
  yield takeEvery(fromActionTypes.GET_CONTENT_FEEDBACK, getContentFeedback);
}
