import { combineReducers } from 'redux'

// UTILS
import {CONFIGURATION, CONFIGURATION_OPTIONS, IS_ADMIN_FRONTEND} from 'utils/configuration';

// ACTIONS
import bqActions from './bqActions';

// ASSESSMENTS
import assessments from './assessments'

//ADMIN
import admin from './admin';

// CAREERLEVELS
import careerLevels from './careerLevels';

// CONFIGURATION
import configuration from './configuration';

// CURRENT USER
import currentUser from './currentUser'

// DEBUG
import debug from './debug';

// FILTER LIST ENITIES
import filterListEntities from './filterListEntities';

// LOADING
import loading from './loading'

// LOCALISATION
import localisation from './localisation'

// LOGIN & USER HANDLING
import login from './login'
import resetPassword from './resetPassword'
import resetPasswordRequest from './resetPasswordRequest'
import signUp from './signUp'

// ORGANIZATION
import organization from './organization'

// PAGES
import employeeOverview from './employeeOverview';
import settings from './settings';

//PROFILE
import profile from './profile';

//ROLES
import roles from './roles';

//ROUTING
import routing from './routing';

//STAFFING
import staffing from './staffing';

//STATIC
import staticValues from './staticValues';

//SUPPORT
import support from './support';

//SURVEY
import survey from './survey';

//TEAM OVERVIEW
import teamOverview from './teamOverview';

// CANDIDATE
import candidate from 'features/+candidates/store/candidate/candidate.reducer';

// VACANCY
import vacancy from 'features/+candidates/store/vacancy/vacancy.reducer';

//WELL-BEING
import wellBeing from './wellBeing';

// ??
import setMenuItem from './setMenuItem'

//TOAST
import toast from './toast';

// ADMIN REDUCERS
import planOverview from 'features/+admin/store/reducers/planOverview';
import instanceOverview from 'features/+admin/store/reducers/instanceOverview';

// GARMIN REDUCER
import garmin from 'features/+garmin/store/garmin.reducer';


const reducersToCombine = {
  // ACTIONS
  bqActions,

  // ASSESSMENTS
  assessments,

  //ADMIN
  admin,

  // CAREER LEVELS
  careerLevels,

  // CONFIGURATION
  configuration,

  // CURRENT USER
  currentUser,

  // DEBUG
  debug,

  // LOADING
  loading,

  // LOCALISATION
  localisation,

  // LOGIN & USER HANDLING
  login,
  resetPassword,
  resetPasswordRequest,
  signUp,

  //ORGANIZATION
  organization,

  // FILTER LIST ENTITIES
  filterListEntities,

  // EMPLOYEES
  employeeOverview,

  //PROFILE
  profile,

  //ROLES
  roles,

  // ROUTING
  routing,

  //STAFFING
  staffing,

  //STATIC
  staticValues,

  //SUPPORT
  support,

  //SURVEY
  survey,

  //TEAM OVERVIEW
  teamOverview,

  // CANDIDATE
  candidate,

  // VACANCY
  vacancy,

  //WELL-BEING
  wellBeing,

  // ??
  setMenuItem,
  settings,

  // TOAST
  toast
};

// ADD ADMIN REDUCERS if necessary
if (IS_ADMIN_FRONTEND) {
  Object.assign(reducersToCombine, {
    planOverview,
    instanceOverview,
  });
}

if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU) {
  Object.assign(reducersToCombine, {
    garmin
  })
}

const reducers = combineReducers(reducersToCombine);

export default reducers;
