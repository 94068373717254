export const INIT_SUBMIT_FEEDBACK = 'INIT_SUBMIT_FEEDBACK';
export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const SUBMIT_FEEDBACK_FULFILLED = 'SUBMIT_FEEDBACK_FULFILLED';
export const SUBMIT_FEEDBACK_REJECTED = 'SUBMIT_FEEDBACK_REJECTED';

export const INIT_SUBMIT_CONTENT_FEEDBACK = 'INIT_SUBMIT_CONTENT_FEEDBACK';
export const SUBMIT_CONTENT_FEEDBACK = 'SUBMIT_CONTENT_FEEDBACK';
export const SUBMIT_CONTENT_FEEDBACK_FULFILLED = 'SUBMIT_CONTENT_FEEDBACK_FULFILLED';
export const SUBMIT_CONTENT_FEEDBACK_REJECTED = 'SUBMIT_CONTENT_FEEDBACK_REJECTED';

export const GET_CONTENT_FEEDBACK = 'GET_CONTENT_FEEDBACK';
export const GET_CONTENT_FEEDBACK_FULFILLED = 'GET_CONTENT_FEEDBACK_FULFILLED';
export const GET_CONTENT_FEEDBACK_REJECTED = 'GET_CONTENT_FEEDBACK_REJECTED';

