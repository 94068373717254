import React, {useCallback, useEffect, useState} from 'react';
import styles from './WellBeingModal.module.scss';

import classNames from 'classnames';

import {useDispatch, useSelector} from 'react-redux';

import {submitWellBeingIndex, initSubmitWellBeingIndex} from 'store/actions';

import { useTranslate } from 'utils/translator';

import {ReactComponent as VerySad} from 'assets/well-being/very-sad.svg';
import {ReactComponent as Sad} from 'assets/well-being/sad.svg';
import {ReactComponent as Neutral} from 'assets/well-being/neutral.svg';
import {ReactComponent as Happy} from 'assets/well-being/happy.svg';
import {ReactComponent as VeryHappy} from 'assets/well-being/very-happy.svg';
import {ReactComponent as ArrowDown} from 'assets/icons/icn_arrow_down.svg';
import {ReactComponent as Check} from 'assets/icons/icn_check_big.svg';

import {Button, Modal, TextArea} from 'ui/basic';


const DEFAULT_EXPANDABLE_DESCRIPTION =
  'Die Daten zu deinem Wohlbefinden werden anonymisiert verarbeitet und erlauben keine Rückschlüsse auf deine individuellen Angaben.';

const EMOJIS = {
  VERY_SAD: 1,
  SAD: 2,
  NEUTRAL: 3,
  HAPPY: 4,
  VERY_HAPPY: 5
};


const WellBeingModal = (props) => {
  const {onClose} = props;

  const translate = useTranslate();
  const dispatch = useDispatch();

  const [bodyExpanded, setBodyExpanded] = useState(false);
  const [wellBeingIndex, setWellBeingIndex] = useState();
  const [personalNotice, setPersonalNotice] = useState();
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);

  const currentUser = useSelector(state => state.currentUser);
  const wellBeingIndexSubmitProcessing = useSelector(state => state.wellBeing.submitWellbeingIndexProcessing);
  const wellBeingIndexSubmittedSuccessfully = useSelector(state => state.wellBeing.success);

  const handleClose = useCallback(() => {
    //store in local storage email and date when well-being modal was closed;
    if (localStorage.getItem('wellBeingIndexData')) {
      const wellBeingIndexData = JSON.parse(localStorage.getItem('wellBeingIndexData'));
      const currentUserData = wellBeingIndexData.find(wb => wb.email === currentUser.mail);

      if (currentUserData) {
        currentUserData.date = new Date();
      } else {
        wellBeingIndexData.push({
          email: currentUser.mail,
          date: new Date()
        });
      }
      localStorage.setItem('wellBeingIndexData', JSON.stringify(wellBeingIndexData));
    } else {
      localStorage.setItem('wellBeingIndexData',
        JSON.stringify([{
          email: currentUser.mail,
          date: new Date()
        }])
      );
    }

    onClose();
  }, [currentUser.mail, onClose]);

  useEffect(() => {
    if (successMessageVisible) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [successMessageVisible, handleClose]);

  useEffect(() => {
    if (wellBeingIndexSubmittedSuccessfully) {
      setSuccessMessageVisible(true);
    }
    return () => {
      dispatch(initSubmitWellBeingIndex());
    }
  }, [dispatch, wellBeingIndexSubmittedSuccessfully]);

  if (successMessageVisible) {
    return (
      <Modal
        preHeader={<Check />}
        header={translate('wellbeing_modal_success_title') || 'Erfolgreich gesendet'}
        headerPosition={'center'}
        secondaryButtonTitle={translate('wellbeing_modal_success_btn') || 'Schließen'}
        showButtonProgressIndicator
        buttonsPosition={'center'}
        onClose={handleClose}
      >
        <div className={styles.wellbeingSuccessContent}>
          {translate('wellbeing_modal_success_description') || 'Hab’ einen guten Tag.'}
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      header={translate('wellbeing_modal_title', ['{{username}}', currentUser.firstName]) ||
      `Hallo ${currentUser.firstName}, wie geht es dir heute?`
      }
      hintPosition={'left'}
      hint={
        <div className={styles.wellBeingHint}>
          <div
            className={classNames(styles.collapsiblePanelTitle, {[styles.active]: bodyExpanded})}
            onClick={() => setBodyExpanded(!bodyExpanded)}
          >
            <span>{translate('wellbeing_modal_expandable_title') || 'Das passiert mit diesen Daten'}</span>
            <ArrowDown />
          </div>
          {bodyExpanded &&
          <div className={styles.collapsiblePanelBody}>
            {translate('wellbeing_modal_expandable_description') || DEFAULT_EXPANDABLE_DESCRIPTION}
          </div>
          }
        </div>
      }
      onClose={handleClose}
    >
     <div className={styles.wellBeingContent}>
       <div className={styles.emojis}>
         <VerySad
           className={classNames({[styles.active]: wellBeingIndex === EMOJIS.VERY_SAD })}
           onClick={() => setWellBeingIndex(EMOJIS.VERY_SAD)}
         />
         <Sad
           className={classNames({[styles.active]: wellBeingIndex === EMOJIS.SAD})}
           onClick={() => setWellBeingIndex(EMOJIS.SAD)}
         />
         <Neutral
           className={classNames({[styles.active]: wellBeingIndex === EMOJIS.NEUTRAL})}
           onClick={() => setWellBeingIndex(EMOJIS.NEUTRAL)}
         />
         <Happy
           className={classNames({[styles.active]: wellBeingIndex === EMOJIS.HAPPY})}
           onClick={() => setWellBeingIndex(EMOJIS.HAPPY)}
         />
         <VeryHappy
           className={classNames({[styles.active]: wellBeingIndex === EMOJIS.VERY_HAPPY})}
           onClick={() => setWellBeingIndex(EMOJIS.VERY_HAPPY)}
         />
       </div>

       {wellBeingIndex &&
       <div className={styles.personalNotice}>
              <span>
                {translate('wellbeing_modal_textarea_title') ||
                'Füge eine optionale Notiz hinzu (nur du kannst sie später einsehen)'
                }
              </span>
         <TextArea
           placeholder={translate('wellbeing_modal_textarea_title') || 'Was hat dein Wohlbefinden beeinflusst?'}
           onChange={(value) => setPersonalNotice(value)}
         />
         <Button
           size={'S'}
           disabled={wellBeingIndexSubmitProcessing}
           onClick={() => {
             dispatch(submitWellBeingIndex({
               wellBeingIndex,
               comment: personalNotice
             }));
           }}
         >
           {translate('wellbeing_modal_submit_btn') || 'absenden'}
         </Button>
       </div>
       }
     </div>
    </Modal>
  )
};

export default WellBeingModal;
