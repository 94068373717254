import * as fromRoutingSelectors from 'store/selectors/routing';
import { Config } from 'store/reducers/filterListEntities';

// FILTER LIST ENTITIES
export const selectFilterListEntities = (state) => {
  return state.filterListEntities;
};

// ENTITY STATES
export const selectStateByEntity = (state, entity) => {
  const entities = selectFilterListEntities(state);

  return entities[entity];
};


// SHOULD RESET
export const selectShouldResetByEntity = (state, entity) => {
  const entityConfig = Config[entity];
  const entityState = selectStateByEntity(state, entity);

  let resetRoutingPath = entityConfig.resetRoutingPath;
  const resetRoutingPathReplace = entityConfig.resetRoutingPathReplace;

  const { resetRoutingPathReplaceValue } = entityState.dynamicConfig;

  if (resetRoutingPathReplace && resetRoutingPathReplaceValue) {
    resetRoutingPath = resetRoutingPath.replace(resetRoutingPathReplace, resetRoutingPathReplaceValue);
  }

  const history = fromRoutingSelectors.selectRoutingHistory(state);
  const newRoute = history[history.length - 1];
  const lastRoute = history[history.length - 2];

  // console.log(resetRoutingPath, newRoute, lastRoute);

  // reset entityState when clicking on nav while on resetRoutingPath already
  if (newRoute && newRoute.location.pathname === resetRoutingPath
    && newRoute.fromMenu
    && lastRoute && lastRoute.location.pathname === resetRoutingPath
  ) {
    return {
      shouldReset: true,
      shouldGet: true
    };
  }

  // reset entityState when leaving resetRoutingPath
  if (
    newRoute && lastRoute
    && !newRoute.location.pathname.includes(resetRoutingPath)
    && lastRoute.location.pathname.includes(resetRoutingPath)
    ) {
    return {
      shouldReset: true,
    };
  }

  return {};
};

