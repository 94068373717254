import { get } from 'api';
import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';

// CONFIG & DATA
import { CONFIGURATION, CONFIGURATION_SUPPORTED_LANGUAGES } from 'utils/configuration';

function* getLanguages(language) {
  try {
    const {status,ok,data} = yield call(
      get,
      'core/language'
    );

    if (ok && status === 200) {
      const supportedLanguages = CONFIGURATION_SUPPORTED_LANGUAGES[CONFIGURATION]; 
      yield put(
        action.getLanguagesFulfilled({
          languages: data.languages.filter((el) => supportedLanguages.includes(el))
        })
      );
    } else {
      yield put(
        action.getLanguagesRejected(data )
      );
    }
  } catch (error) {
    console.warn('getLanguages error',error)
    yield put(action.getLanguagesRejected(error));
  }
}
export default function* watchGetLanguagesRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.GET_LANGUAGES);

      yield call(getLanguages, payload);
    } catch (error) {
      yield put(action.getLanguagesRejected(error));
    }
  }
}
/*
response={
  duration:2,
  problem:null,
  originalError:null,
  ok:true,
  status:200,
  headers:,
  config:,
  data:
}
*/
