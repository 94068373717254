import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';
import {get_base64} from '../../api';

function* getUserProfilePicture() {
  try {
   const {status,ok,data}  = yield call(
     get_base64,
     'core/user/picture'
   );

   const image = `data:image/jpg;base64,${data}`;

   if (ok && status === 200) {
    yield put(action.getUserProfilePictureFulfilled(image));
   }else{
      yield put(action.getUserProfilePictureRejected(image));
   }
  } catch (error) {
    yield put(action.getUserProfilePictureRejected(error));
  }
}
export default function* watchGetUserProfilePictureRequest() {
  while (true) {
    try {
     yield take(action.GET_USER_PROFILE_PICTURE);
      yield call(getUserProfilePicture);
    } catch (error) {
      yield put(action.getUserProfilePictureRejected(error));
    }
  }
}
