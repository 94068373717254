import React, {useState} from 'react';
import styles from './SegmentedSelection.module.scss';

import classNames from 'classnames';


const SegmentedSelection = (props) => {
  const {value, segmentsCount = 5, onSelect = () => {}} = props;

  let valueInternal;
  if (Number.isInteger(value)) {
    valueInternal = value;
  } else {
    valueInternal = value < 1 ? 1 : Math.floor(value);
  }

  const [clickedCellNumber, setClickedCellNumber] = useState(valueInternal);
  const [hoveredCellNumber, setHoveredCellNumber] = useState();

  const renderCells = () => {
    const cells = [];
    for (let i=1; i<=segmentsCount; i++) {
      const cell =
        <div
          key={`competencies-cell-${i}`}
          className={classNames(
            styles.cell,
            {[styles.blue]: clickedCellNumber && clickedCellNumber >= i},
            {[styles.hellBlue]: (!clickedCellNumber || i > clickedCellNumber) && (hoveredCellNumber && hoveredCellNumber >= i)},
            {[styles.grey]: !clickedCellNumber || !hoveredCellNumber}
          )}
          style={{width: `calc(100% / ${segmentsCount})`}}
          onClick={() => {
            setClickedCellNumber(i);
            onSelect(i);
          }}
          onMouseOver={() => setHoveredCellNumber(i)}
          onMouseOut={() => setHoveredCellNumber(null)}
        >
        </div>;
      cells.push(cell);
    }
    return cells;
  };

  return (
    <div className={styles.segmentedSelection}>
      {renderCells()}
    </div>
  )
};

export default SegmentedSelection;
