import React from 'react';
import styles from './ProgressCircle.module.scss';

import classNames from 'classnames';

import { IconsSvg } from 'assets/icons';

import WellBeingImage from 'assets/icons/icn_wbi.svg';

const CheckSmall = IconsSvg.CheckSmall;
const Block = IconsSvg.Block;

const ProgressCircle = (props) => {
  const {
    size,
    progress,
    showProgress,
    showWellBeingIcon,
    looks='blue',
    yellowDiff,
    loading
  } = props;

  return (
    <div className={classNames(styles.progressCircle, styles[looks], {
      [styles[`size${size}`]]: true,
      [styles.loading]: loading,
      [styles.yellowDiff]: yellowDiff
    })} data-progress={Math.round(progress)}>
      <div className={styles.circle}>
        <div className={`${styles.mask} ${styles.full}`}>
          <div className={styles.fill}></div>
        </div>
        <div className={`${styles.mask} ${styles.half}`}>
          <div className={styles.fill}></div>
          <div className={`${styles.fill} ${styles.fix}`}></div>
        </div>
      </div>
      <div className={styles.inset}>
        {showProgress && `${progress}%`}
        {showWellBeingIcon &&
        <img
          alt={'well-being'}
          src={WellBeingImage}
          className={styles.wellBeing}
        />}

        {(!showProgress && !showWellBeingIcon) &&
        <>
          {(progress === 100 || progress === '100') &&
          <CheckSmall/>
          }
          {progress === -1 && <Block/>}
        </>
        }
      </div>
    </div>
  )
}

export default ProgressCircle;
