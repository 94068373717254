import React from 'react';

import { routesConfigs } from './routeNext.config';

import {
  selectInstanceFlavor,
  selectFeatures,
  selectIsLoggedIn,
  selectUserGroup,
  selectCapabilities,
  selectUserProfileCompleted,
  selectPlanRestricted,
  selectPlanType,
} from 'features/framework/storeNext/configurationSlice';

// CONFIGURED ROUTES
export const getAllConfiguredRoutes = (state, stateExtras) => {

  const allConfigsRoutes = applyRouteConfigsFilter(routesConfigs, state, stateExtras)

  const allConfiguredRoutes = applyConfiguredRoutesFilter(allConfigsRoutes, state)

  return allConfiguredRoutes;
};

export default getAllConfiguredRoutes;


const applyRouteConfigsFilter = (routesConfigs, state, stateExtras) => {

  const filteredConfigsRoutes = routesConfigs.flatMap((config) => {
    return config.routes
    // filter by show property
    .filter((route) => {
      return filterByMergedShows(config, route, state, stateExtras);
    })
    .map((route) => {

      // subRoutes
      let subRoutes;
      if (route.subRoutes) {

        subRoutes = route.subRoutes
        .filter(subRoute => filterByMergedShows(route, subRoute, state, stateExtras))
        .map(subRoute => {
          let subSubRoutes;
          if (subRoute.subRoutes) {
            subSubRoutes = subRoute.subRoutes
            .filter(subSubRoute => filterByMergedShows(subRoute, subSubRoute, state, stateExtras))
          }
          return {
            ...subRoute,
            subRoutes: subSubRoutes
          }
        })
      }


      // merge tools
      let tools;

      const { tools: configTools = {} } = config;
      const { tools: routeTools } = route;

      if (!routeTools) {
        tools = configTools;
      }
      else {
        Object.keys(configTools).forEach((configProperty) => {
          const configValue = configTools[configProperty];

          if (typeof(routeTools[configProperty]) === undefined) {
            routeTools[configProperty] = configValue;
          }
        })
      }


      return {
        ...route,
        subRoutes,
        tools
      };
    })
    ;
  })


  return filteredConfigsRoutes
};



const filterByMergedShows = (parent, child, state, stateExtras) => {

  // if theres no child.show use parent.show
  if (child.show === undefined) {
    return applyRouteShowFilter(parent.show, state, stateExtras);
  }
  // if there's a child.show it merges the configs.
  // route config has precedence since it's more specific
  else {
    let newShow = {}; // empty object will result in default behavior, just like undefined

    // if there's no parent.show use child.show
    if (parent.show === undefined) {
      newShow = child.show;
    }
    else if (typeof(child.show) === 'boolean') {
      newShow = child.show
    }
    else if (typeof(parent.show) === 'boolean') {
      newShow = parent.show
    }
    else if (typeof(parent.show) === 'function' && typeof(child.show) === 'function') {
      newShow = child.show
    }
    else if (typeof(parent.show) === 'object' && typeof(child.show) === 'function') {
      newShow = { ...parent.show, ...{ show: child.show }};
    }
    // for all cases below child.show is an object implicitly
    else if (typeof(parent.show) === 'function') {
      newShow = { ...{ show: parent.show }, ...child.show};
    }
    else if (typeof(parent.show) === 'object') {
      newShow = { ...parent.show, ...child.show };
    }

    return applyRouteShowFilter(newShow, state, stateExtras);
  }
};


const applyRouteShowFilter = (show, state, stateExtras, child) => {
  const defaultFlavor = 'bluquist';
  const defaultUserGroup = ['employee', 'leader', 'admin'];

  let pass = true

  // when show is undefined => use defaults
  if (show === undefined) {
    const passLogin = selectIsLoggedIn(state);
    const passFlavor = isOrContainsString(defaultFlavor, selectInstanceFlavor(state))
    const passUserGroup = isOrContainsString(defaultUserGroup, selectUserGroup(state))

    pass = passLogin && passFlavor && passUserGroup;
  }
  // when show is a boolean => the boolean is the result
  else if (typeof(show) === 'boolean') {
    pass = show
  }
  // when show is a function => the result of show(state) is the result
  else if (typeof(show) === 'function') {
    pass = show(state, stateExtras)
  }
  // when show is an object => the result is the && of all options
  else if (typeof(show) === 'object') {
    let passPlan = true;
    let passLogin = true;
    let passProfileCompleted = true;
    let passFlavor = true;
    let passFeature = true;
    let passUserGroup = true;
    let passUserCapabilities = true
    let passShow = true;

    const isLogoutRoute = show.needsLogin === false || show.needsLogout === true;
    const isLoginRoute = show.needsLogin === undefined || show.needsLogin === true;

    // plan: if forPlan is given only pass if plan type matches
    if (show.forPlan) {
      passPlan = isOrContainsString(show.forPlan, selectPlanType(state));
    }
    // default: pass plan if not restricted
    // or needsLogout is true or needsLogin is false
    else {
      passPlan = !selectPlanRestricted(state)
        || isLogoutRoute;
    }

    // login
    if (show.needsLogin === false) {
      passLogin = true
    }
    else if (show.needsLogout === true) {
      passLogin = !selectIsLoggedIn(state)
    }
    else {
      passLogin = selectIsLoggedIn(state)
    }

    // profileComfpleted
    if (isLoginRoute && show.needsProfileCompleted === false) {
      passProfileCompleted = selectIsLoggedIn(state) && !selectUserProfileCompleted(state)
    }
    else if (isLoginRoute && show.needsProfileCompleted === 'ignore') {
      passProfileCompleted = selectIsLoggedIn(state)
    }
    else if (isLogoutRoute) {
      passProfileCompleted = true;
    }
    else {
      passProfileCompleted = selectUserProfileCompleted(state)
    }

    // forFlavor
    if (show.forFlavor) {
      // always pass for 'all'
      if (show.forFlavor === 'all') {
        passFlavor = true;
      }
      // pass for selected
      else {
        passFlavor = isOrContainsString(show.forFlavor, selectInstanceFlavor(state))
      }
    }
    // default to 'bluquist'
    else {
      if (isLogoutRoute) {
        passFlavor = true;
      }
      else {
        passFlavor = isOrContainsString(defaultFlavor, selectInstanceFlavor(state))
      }
    }

    // forFeature
    if (show.forFeature) {
      passFeature = hasFeature(selectFeatures(state), show.forFeature)
    }

    // forUserGroup
    if (show.forUserGroup) {
      // always pass for 'all'
      if (show.forUserGroup === 'all') {
        passUserGroup = true
      }
      // pass for selected
      else {
        passUserGroup = isOrContainsString(show.forUserGroup, selectUserGroup(state))
      }
    }
    // default
    else {
      if (isLogoutRoute) {
        passUserGroup = true;
      }
      else {
        passUserGroup = isOrContainsString(defaultUserGroup, selectUserGroup(state))
      }
    }

    // forCapabilities
    if (show.forCapabilities) {
      const userGroup = selectUserGroup(state);
      const userCapabilities = selectCapabilities(state);
      const requiredCapabilities = show.forCapabilities[userGroup];
      if (requiredCapabilities) {
        passUserCapabilities = hasAnyCapability(userCapabilities, requiredCapabilities);
      }
    }

    // show function inside show object: show: { show: (state) => {} }
    if (typeof(show.show) === 'function') {
      passShow = show.show(state, stateExtras);
    }

    // console.log('passPlan', passPlan, 'passLogin', passLogin);
    // console.log('passProfileCompleted', passProfileCompleted, 'passFlavor', passFlavor);
    pass = passPlan && passLogin
    && passProfileCompleted && passFlavor
    && passFeature && passUserGroup
    && passUserCapabilities && passShow;
  }

  // console.log('-----', child.path, pass);

  return pass;
};

const isOrContainsString = (value, desiredString) => {
  let pass = false;
  if (value === desiredString) {
    pass = true;
  }
  else if (value.length && value.includes(desiredString)) {
    pass = true;
  }
  return pass;
};

const hasFeature = (availableFeatures, forFeature) => {
  if (typeof(forFeature) === 'string') {
    forFeature = [forFeature];
  }

  if (Array.isArray(forFeature)) {
    for (let i = 0; i < forFeature.length; i++) {
      const find = availableFeatures[forFeature[i]];
      if (!find) {
        return false;
      }
    }
    return true;
  }
};

export const hasAnyCapability = (capabilities, requiredCapabilities) => {
  if (typeof requiredCapabilities === 'string') {
    return capabilities[requiredCapabilities];
  }

  return requiredCapabilities.some?.(rc => capabilities[rc]);
};

const applyConfiguredRoutesFilter = (routes, configuration) => {

  //

  return routes;
};


// GET PARENT ROUTE
export const getParentRoute = (route, configuration) => {

};



// MAIN NAV ROUTES
export const getConfiguredMainNavRoutes = (configuration) => {
  const allConfiguredRoutes = getAllConfiguredRoutes(configuration);

  const allConfiguredMainNavRoutes = applyMainNavRoutesFilter(allConfiguredRoutes);

  return allConfiguredMainNavRoutes;
};

export const applyMainNavRoutesFilter = (routes) => {
  const filteredMainNavRoutes = routes.filter(route => route.inMainNav);

  // sort main nav routes by forceIndex
  filteredMainNavRoutes
  .filter(route => {
    return !isNaN(route.inMainNav.forceIndex)
  })
  .forEach(route => {
    const { forceIndex } = route.inMainNav;
    const index = filteredMainNavRoutes.indexOf(route);

    filteredMainNavRoutes.splice(index, 1);
    filteredMainNavRoutes.splice(forceIndex, 0, route);
  });

  return filteredMainNavRoutes;
};


// STANDALONE ROUTES
export const getConfiguredStandaloneRoutes = (configuration) => {
  const allConfiguredRoutes = getAllConfiguredRoutes(configuration);

  const allConfiguredStandaloneRoutes = applyStandaloneRoutesFilter(allConfiguredRoutes);

  return allConfiguredStandaloneRoutes;
};

export const applyStandaloneRoutesFilter = (routes) => {
  const filteredIndependenRoutes = routes.filter(route => route.pageType === 'standalone');

  return filteredIndependenRoutes;
};



// MODAL ROUTES
export const getConfiguredModalRoutes = (configuration) => {
  const allConfiguredRoutes = getAllConfiguredRoutes(configuration);

  const allConfiguredModalRoutes = applyModalRoutesFilter(allConfiguredRoutes);

  return allConfiguredModalRoutes;
};

export const applyModalRoutesFilter = (routes) => {
  const filteredModalRoutes = routes.filter(route => route.pageType === 'modal');

  return filteredModalRoutes;
};


// NESTED ROUTES
export const applyNestedRoutesFilter = (routes) => {
  const filteredNestedRoutes = routes.filter(route => route.pageType === 'nested' || !route.pageType)

  return filteredNestedRoutes;
}



// TOOLS
export const alsoHighlightFor = (location, route) => {
  const { inMainNav, inSubNav } = route;
  if (!inMainNav && !inSubNav) return false;

  const nav = inMainNav || inSubNav;
  const alsoHighlightFor = nav.alsoHighlightFor;
  if (!alsoHighlightFor || (alsoHighlightFor && !alsoHighlightFor.length) ) {
    return false;
  }

  let highlight = false;
  highlight = alsoHighlightFor.find((highlightFor) => {
    if (highlightFor.startsWith('/')) {
      return location.pathname.startsWith(highlightFor);
    }
    else  {
      return location.pathname.includes(highlightFor);
    }
  })

  return highlight;
};

//RECURSIVE FUNCTION FOR FINDING THE LAZY COMPONENT
const findLazyComponentRecursive = (route, pathname, pages) => {
  let component = null;
  let i = 0;

  while (!component) {
    // route can be a routeConfig with .routes (first call)
    // or a route with .subRoutes (recursive calls)
    const thisSubRoute = route.subRoutes ? route.subRoutes[i] : (route.routes && route.routes[i]);

    if (!thisSubRoute) {
      break;
    }

    if (thisSubRoute.path === pathname) {
      const LazyPageComponent = pages[thisSubRoute.LazyPageComponent];

      if (LazyPageComponent) {
        component = <LazyPageComponent />;
        break;
      }
    } else if (thisSubRoute.subRoutes) {
      component = findLazyComponentRecursive(thisSubRoute, pathname, pages);
    }

    i++;
  }

  return component;
}

export const findLazyComponent = (routeConfig, match, pages) => {
  const component = findLazyComponentRecursive(routeConfig, match.path, pages);
  if (component) {
    return component;
  }

  return null;
}
