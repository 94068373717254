// REACT
import React from 'react';

// UTILS
import { SecureStorage } from 'utils/storage';

// CONFIGS
import * as assessmentConfigs from './config';
import { GLOBAL_CONFIG } from './AssessmentNext.config'


/**
 * validateQuestions:
 * validates if questions can be rendered
 * @param {Array} questions array of questions
 * @returns array: [true] or [false, faultyQuestion]
 */
export const validateQuestions = (questions) => {
  if (!questions) {
    return [false, { faultyQuestions: [], faultyIndices: []}];
  }

  let questionsValid = true,
      faultyQuestions = [],
      faultyIndices = [];
  questions.forEach((question, index) => {
    if (question.isIntermission && typeof question.render === 'function') {
      return;
    }
    try {
      getQuestionRenderInfo(question);
    }
    catch (err) {
      questionsValid = false;
      faultyQuestions.push(question);
      faultyIndices.push(index);
      // console.warn(err);
    }
  });

  const error = questionsValid ? undefined : {faultyQuestions, faultyIndices };

  return [questionsValid, error];
};

/**
 * getQuestionRenderInfo:
 * returns necessary render info for a question
 * throws error if question can't be rendered
 * @param {Object} question question object
 * @returns array with [ component[, [questionRange], [render]] ]
 */
export const getQuestionRenderInfo = (question) => {

  // first: check if explanatory question
  if (question.explanatory && question.description) {
    return [ GLOBAL_CONFIG.validQuestionTypes.explanatory.component ];
  }

  // if not explanatory, check valid question types
  const questionId = question.id;
  const questionTitle = question.question;
  let questionType = `${question.type}-${question.representation}`;

  const questionRangeType = `from${question.answerFrom}Step${question.answerStep}To${question.answerTo}`;

  // validate questionConfig existence
  const questionConfig = GLOBAL_CONFIG.validQuestionTypes[questionType];
  if (!questionConfig) {
    throw new Error(`no questionConfig for ${questionType}`);
  }

  // validate questionConfig.component
  if (!questionConfig.component || typeof questionConfig.component !== 'function') {
    throw new Error(`no component in questionConfig or component is no function for ${questionType}`);
  }

  // validate questionTitle
  if (!questionTitle && questionType !== 'sort-sort') {
    throw new Error(`no question(Title) for ${question}`);
  }

  // validate questionId
  if (!questionId) {
    throw new Error(`no question.id for ${question}`);
  }

  // validate questionRange
  let questionRange;
  // if config has validRangeTypes match them
  if (questionConfig.validRangeTypes) {
    questionRange = questionConfig.validRangeTypes[questionRangeType];
    if (!questionRange) {
      throw new Error(`couldn't find questionRange ${questionRangeType} for ${questionType}`);
    }
  }
  // return answerFrom, answerStep, answerTo as questionRange
  else {
    const { answerFrom, answerStep, answerTo } = question;
    questionRange = {
      answerFrom,
      answerStep,
      answerTo,
    };
    if (isNaN(answerFrom) || (isNaN(answerStep) && questionType !== 'sort-sort') || isNaN(answerTo)) {
      throw new Error(`couldn't determine questionRange ${questionRangeType} for ${questionType}`);
    }
  }

  // check if component can be rendered
  const QuestionComponent = questionConfig.component;
  const render = (
    <QuestionComponent
      question={question}
      range={questionRange}
    />
  );

  return [questionConfig.component, questionRange, render ];
};

/**
 * copyAssessmentConfig:
 * retrieves a copy of the assessmentConfig for type and validates it
 * @param {string} type assessmentType
 * @returns a copy of the config or { error: 'message' } when there are errors
 */
export const copyAssessmentConfig = (type) => {
  const assessmentConfig = assessmentConfigs[`${type}Config`];

  if (assessmentConfig) {
    // create copy (cannot be done via JSON.stringify because of functions and Infinity)
    // maybe include lodash for lodashClonedeep in the long run
    const copiedAssessmentConfig = Object.assign({}, assessmentConfig);
    copiedAssessmentConfig.intermissions = copiedAssessmentConfig.intermissions.map((intermission) => {
      return {...intermission}
    });
    const validation = validateAssessmentConfig(copiedAssessmentConfig);
    return Object.assign({}, copiedAssessmentConfig, validation);
  }
  else {
    return { error: `Invalid assessmentType: "${type}"`}
  }
};


/**
 * validateAssessmentConfig:
 *  - checks presence of id, title, intermissions[ {insertAtIndex: Infinity }]
 *  - checks skippedQuestionsPage when alloForwardNavigation is set
 * @param {Object} assessmentConfig assessment config to validate
 * @returns { error: 'message' } if there are errors or {} for no errors
 */
export const validateAssessmentConfig = (assessmentConfig) => {
  let errorMessage = '';
  if (!assessmentConfig.id) {
    errorMessage += 'assessmentConfig has no id';
  }
  if (!assessmentConfig.title) {
    errorMessage += ' assessmentConfig has no title';
  }
  if ( !assessmentConfig.intermissions
    || !assessmentConfig.intermissions
        .find((intermission) => intermission.insertAtIndex === Infinity)
  ) {
    errorMessage += ' assessmentConfig has no end page {insertAtIndex: Infinity}';
  }
  if (assessmentConfig.allowForwardNavigation
    && (!assessmentConfig.skippedQuestionsPage || typeof assessmentConfig.skippedQuestionsPage.render !== 'function')
  ) {
    errorMessage += ' assessmentConfig has allowForwardNavigation but no skippedQuestionsPage or no render function for skippedQuestionsPage';
  }

  if (errorMessage) {
    return { error: errorMessage };
  }
  else {
    return {};
  }

};

/**
 * createStateWithPages:
 * creates a state (based on config) with pages
 * based on questions and config.intermissions
 * or throws an error when there's no config or questions
 * @param {object} config assessmentConfig
 * @param {array} questions questions to add to the config
 * @returns a new state with pages
 */
export const createStateWithPages = (config, questions, intermissions) => {
  if (!config) {
    throw new Error('no valid config');
  }
  if (!questions) {
    throw new Error('missing questions array');
  }
  // copy questions & config
  let pages = [...questions];
  const newConfig = Object.assign({}, config);

  // intermissions
  if (intermissions && intermissions.length) {
    // add isIntermission to intermissions
    intermissions = intermissions.map(intermission => Object.assign({
      isIntermission: true
    }, intermission));

    // sort intermissions by insertAtIndex
    intermissions.sort((first, second) => {
      if (first.insertAtIndex > second.insertAtIndex) {
        return 1;
      }
      else if (second.insertAtIndex > first.insertAtIndex) {
        return -1;
      }
      else {
        throw new Error(`multiple intermissions with same insertAtIndex ${first.inserAtIndex}`);
      }
    });

    // add intermission at right places in pages
    intermissions.forEach(intermission => {
      insertPage(pages, intermission, intermission.insertAtIndex);
    });
  }

  newConfig.pages = pages;

  return newConfig;
};

/**
 * insertPage
 *  - inserts newPage at desiredInsertAtIndex to pages
 *  - added at the end if index is not smaller than pages.length
 * @param {array} pages array of pages to add
 * @param {object} newPage new page to add
 * @param {number} desiredInsertAtIndex desired index where newPage should be added
 * @returns pages with newPage added
 */
export const insertPage = (pages, newPage, desiredInsertAtIndex) => {
  const insertAtIndex = desiredInsertAtIndex < pages.length
    ? desiredInsertAtIndex // add at index
    : pages.length; // add at the end

  pages.splice(insertAtIndex, 0, newPage);

  return pages;
};

/**
 * validateDeflatedState:
 * validates a deflated state from the storage.
 * Checks:
 *  - if lastSaveTime is not older than storageValidityWindow
 *    (of config or if unset of GLOBAL_CONFIG)
 *  - if the intermission count and indices are equal
 *    (assumes they are sorted by their insertAtIndex property,
 *    which they are after state has been created with createStateWithPages)
 *  - if the questions count is the same
 *  - if all question ids can be found in config
 * @param {Object} deflatedState parsed state from secure storage which doesn't include intermission components
 * @param {Object} config assessment config to validate against
 * @param {Object} questions current assessment questions to validate against
 * @returns true or false
 */
export const validateDeflatedState = (deflatedState, config, questions, removeFirstIntermissions) => {
  const intermissions = [ ...getIntermissions(questions), ...config.intermissions];
  questions = [ ...getQuestions(questions) ];
  const deflatedIntermissions = getIntermissions(deflatedState.pages);
  const deflatedQuestions = getQuestions(deflatedState.pages);

  // storageValidityWindow
  if (!hasValidStorageValidityWindow(deflatedState, config)) {
    return false;
  }

  if (config.storageValidityByConfigVersion
    && deflatedState.configVersion === config.configVersion
  ) {
    return true;
  }

  // check lengths
  if (intermissions.length !== deflatedIntermissions.length) {
    return false;
  }
  if (questions.length !== deflatedQuestions.length) {
    return false;
  }

  // check question ids
  let questionIdsValid = true;
  const questionsMap = {};
  questions.forEach((question) => {
    questionsMap[question.id] = question;
  });
  deflatedQuestions.forEach((deflatedQuestion) => {
    const deflatedId = deflatedQuestion.id;
    const questionFromMap = questionsMap[deflatedId];
    if (!questionFromMap) {
      questionIdsValid = false;
    }
  });
  if (!questionIdsValid) {
    return false;
  }

  return true;
};

export const hasValidStorageValidityWindow = (deflatedState, config) => {
  const storageValidityWindow = !isNaN(config.storageValidityWindow)
    ? config.storageValidityWindow
    : GLOBAL_CONFIG.storageValidityWindow;
  const lastSaveTime = deflatedState.lastSaveTime || Date.now();

  if (Date.now() >= lastSaveTime + storageValidityWindow) {
    return false;
  }
  return true;
};

/**
 * inflateStorageState:
 * Adds non JSONable properties back to the intermissions of deflatedValidatedState
 * e.g. render functions, insertAtIndex: Infinity
 * also unsets any potential clickBlock
 * @param {*} deflatedValidatedState deflated state from storage, needs to be validated with validateDeflatedState before
 * @param {*} config config with intermissions to inflate state with
 * @param {*} questions questions with intermissions to inflate state with
 * @return returns a new inflatedState
 */
export const inflateStorageState = (deflatedValidatedState, config, questions) => {
  // Populate intermissions
  const inflatedIntermissions = getIntermissions(deflatedValidatedState.pages);
  const intermissions = [ ...getIntermissions(questions), ...config.intermissions ];
  inflatedIntermissions.forEach((intermission) => {
    const configIntermission = intermissions
    .find(el => el.insertAtIndex === intermission.insertAtIndex || (el.insertAtIndex === Infinity && intermission.insertAtIndex === null));
    Object.assign(intermission, configIntermission);
  });

  // Unset ClickBlock
  const inflatedState = Object.assign({}, deflatedValidatedState);
  inflatedState.clickBlock = false;

  return inflatedState;
};

const getQuestions = (pages = []) => pages.filter((page) => !page.isIntermission);
const getIntermissions = (pages = []) => pages.filter((page) => page.isIntermission && !page.isPlaceholder);

export const hasStoredAnswers = (type, userId) => {
  // init storage controller for type and userId
  storageController.init(type, userId);
  const storageState = storageController.loadState();

  // retrieve config for type
  const assessmentConfig = copyAssessmentConfig(type);

  let hasStoredAnswers = false;
  // has a config & state?
  if (assessmentConfig && storageState) {
    // valid window?
    if (hasValidStorageValidityWindow(storageState, assessmentConfig)) {

      // non-empty answers
      if (hasAnswers(storageState)) {
        hasStoredAnswers = true;
      }
    }
  }

  storageController.reset();
  return hasStoredAnswers;
};

export const hasAnswers = (state) => {
  const answers = state.answers || {};
  const answerKeys = Object.keys(answers);

  return answerKeys.length;
};

export const storageController = {
  secureStorage: null,
  init: function (type, userId) {
    const secureStorage = new SecureStorage(type, userId);

    this.secureStorage = secureStorage;
  },
  isInitialised: function () {
    const secureStorage = this.secureStorage;

    return secureStorage && secureStorage.isInitialised();
  },
  reset: function () {
    const secureStorage = this.secureStorage;

    secureStorage.reset();

    this.secureStorage = null;
  },
  /**
   * loads state from storage and returns it if it's valid
   * @param {Object} config
   * @param {Array} questions
   * @returns validState or undefined
   */
  loadValidState: function (config, questions) {
    const secureStorage = this.secureStorage;

    const storageState = secureStorage.load();

    let validState;

    if (storageState) {
      // validate storage state
      const isValid = validateDeflatedState(storageState, config, questions);
      // inflate if valid
      if (isValid) {
        const inflatedStorageState = inflateStorageState(storageState, config, questions);
        validState = inflatedStorageState;
      }
      // remove state from secureStorage if it's invalid
      else {
        secureStorage.remove();
      }
    }

    return validState;
  },
  loadState: function () {
    const secureStorage = this.secureStorage;
    return secureStorage.load();
  },
  /**
   * saves if:
   *  - storageController is initialised
   *  - clickBlock is not active
   *  - answers are not empty
   * @param {Object} state
   * @returns true or false
   */
  saveState: function (state) {
    const secureStorage = this.secureStorage;

    let saved = false;
    if (
      this.isInitialised()
      && !state.clickBlock
      && hasAnswers(state)
    ) {
      secureStorage.save(state);
      saved = true;
    }

    return saved;
  },
  removeState: function() {
    const secureStorage = this.secureStorage;
    secureStorage.remove();
  }

};
