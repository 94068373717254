// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import styles from './SliderBipolar.module.scss';

// ASSETS
import {ReactComponent as ArrowLeft} from 'assets/icons/icn_arrow_left.svg';
import {ReactComponent as ArrowRight} from 'assets/icons/icn_arrow_right.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useWindowWidth, useDebounce, useAutoReset, useThrottle } from 'utils/hooks';

// STORE

// CONFIG & DATA
const CONFIG = {
  dragThreshold: 12, // px
  dragFPS: 64,
};

/**
 * SliderBipolar:
 *  - layout
 *    - automatically takes full width
 *    - works responsively (layout and value ranges)
 *  - props
 *    - works well with ranges where `from` and `to` are equidistant to 0
 *    - works well with any `step` value
 *    - TODO: set defaultValue to centerValue for ranges not equidistant to 0
 *  - interaction
 *    - click for value (with transition)
 *    - double click for reset (with transition)
 *    - has sensbile bounds (can't be dragged outside component)
 * @param {*} props
 * @returns
 */

// COMPONENT: SliderBipolar
const SliderBipolar = (props) => {
  // PROPS
  const {
    value,
    from = -5,
    to = 5,
    step = 1,
    onChange = () => {}
  } = props;

  // UI
  const [ touched, setTouched ] = useState(false);
  const [ transitioning, setTransitioning ] = useAutoReset(false, Number(styles.animationDurationMs));


  // UPDATE CENTERVALUE based on props
  const [ centerValue, setCenterValue ] = useState(0);
  useEffect(() => {
    let centerValue = to - (to - from) / 2;
    setCenterValue(centerValue);
  }, [from, to]);

  // UPDATE EXTERNALVALUE & TOUCHED based on value prop
  const [ externalValue, setExternalValue ] = useState(0);
  useEffect(() => {
    if (!isNaN(value)) {
      setTouched(true);
      setExternalValue(value);
    }
  }, [value]);


  // KNOB POSTION & BAR WIDTH
  const [ knobTranslate, setKnobTranslate ] = useState(0);
  const [ barWidthLeft, setBarWidthLeft ] = useState(0);
  const [ barWidthRight, setBarWidthRight ] = useState(0);
  const setKnobPositionByValue = useCallback((newValue) => {
    let knobTranslate = 0;

    const valueDistanceFor50Percent = to - centerValue;
    const valueDistanceForValue = newValue - centerValue;

    const percentDistanceForValue = valueDistanceForValue / valueDistanceFor50Percent * 100 / 2;

    knobTranslate = percentDistanceForValue;

    setKnobTranslate(knobTranslate);

    if (knobTranslate >= 0) {
      setBarWidthLeft(0);
      setBarWidthRight(knobTranslate * 2);
    }
    else {
      setBarWidthLeft(-knobTranslate * 2);
      setBarWidthRight(0);
    }

    // console.log(`newValue: ${newValue}, centerValue: ${centerValue}, from: ${from}, to: ${to}, knobTranslate: ${knobTranslate}`);

  }, [centerValue, to]);

  // MOVE KNOB based on VALUES: newValue, centerValue, from, to
  useEffect(() => {
    setKnobPositionByValue(externalValue, centerValue, from, to);
  }, [externalValue, centerValue, from, to, setKnobPositionByValue]);


  // UPDATE PIXELWIDTH & NODE based on debouncedWindowWidth
  const [ pixelWidth, setPixelWidth ] = useState(0);
  const [ node, setNode ] = useState(0);
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 300);
  const refCb = useCallback(newNode => {
    if ( newNode !== node && newNode !== null ) {
      setNode(newNode);
    }
  }, [node]);
  useEffect(() => {
    if (debouncedWindowWidth) {  /* just for linter */
      const pixelWidth = node.getBoundingClientRect().width;
      setPixelWidth(pixelWidth);
      // console.log('pixelWidth', pixelWidth);
    }
  }, [node, debouncedWindowWidth]);


  // MOVE KNOB based on PIXELS
  const setKnobPositionByPixels = useCallback((xDistanceFromComponent) => {
    let newValue = 0;
    const valueWidth = to - from;
    newValue = xDistanceFromComponent / pixelWidth * valueWidth;
    newValue = newValue + from;
    // console.log('setKnobPosition newValue:', newValue);

    setInternalValue(newValue);
    setKnobPositionByValue(newValue);
    setTouched(true);
  }, [from, to, pixelWidth, setKnobPositionByValue]);


  // UPDATE INTERNALVALUE
  const [ internalValue, setInternalValue ] = useState(0);

  // UPDATE ROUNDEDVALUE AND PROPAGATE to parent via onChange
  const [ roundedValue, setRoundedValue ] = useState(0);
  useEffect(() => {

    let newRoundedValue = internalValue / step;
    newRoundedValue = Math.round(newRoundedValue) * step;
    // prevent rounding errors and 0.500000000003 type situations
    newRoundedValue = Number(parseFloat(newRoundedValue).toPrecision(2));

    if (newRoundedValue !== roundedValue) {
      setRoundedValue(newRoundedValue);
      onChange(newRoundedValue);
    }
  }, [internalValue, step, roundedValue, onChange]);


  // DRAGGING
  const [ dragging, setDragging ] = useState(false);
  const [ dragOrigin, setDragOrigin ] = useState(false);
  const [ componentOrigin, setComponentOrigin ] = useState(false);
  const [ dragPosition, setDragPosition ] = useState(false);
  const throttledDragPosition = useThrottle(dragPosition, 1000 / CONFIG.dragFPS);

  useEffect(() => {
    if (throttledDragPosition !== false) {
      // console.log('throttledPosition', throttledDragPosition);
      setKnobPositionByPixels(throttledDragPosition);
    }
  }, [throttledDragPosition, setKnobPositionByPixels]);

  const resetDragging = () => {
    setDragging(false);
    setDragOrigin(false);
    setComponentOrigin(false);
    setDragPosition(false);
  };

  const handleDragStart = (event) => {
    // console.log('drag start');

    const pageX = event.pageX || event.touches[0].pageX;
    const componentOrigin = node.getBoundingClientRect().x;
    const dragOrigin = pageX - componentOrigin;

    setDragOrigin(dragOrigin);
    setComponentOrigin(componentOrigin);
  };

  const handleDragMove = (event) => {
    if (!dragOrigin) return;
    const pageX = event.pageX || event.changedTouches[0].pageX;
    const dragPosition = pageX - componentOrigin;

    if (!dragging) {
      if (Math.abs(dragOrigin - dragPosition) > CONFIG.dragThreshold) {
        // console.log('set dragging true');
        setDragging(true);
      }
    }
    else {
      // console.log('dragging');
      // add sensible bounds
      if (dragPosition > 0 && dragPosition <= pixelWidth) {
        setDragPosition(dragPosition);
      }
    }
  };

  const handleDragEnd = (event) => {
    // console.log('dragend');
    // prevent click and reset
    // event.preventDefault();
    resetDragging();
  };



  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleClick = (event) => {
    const pageX = event.pageX;
    const componentX = node.getBoundingClientRect().x;
    const clickX = pageX - componentX;

    // change position with transition
    setTransitioning(true);
    setKnobPositionByPixels(clickX);
  };
  const handleDoubleClick = (event) => {
    // reset dragging
    resetDragging();
    // reset touched, change position with transition
    setTouched(false);
    setKnobPositionByValue(centerValue);
    setInternalValue(centerValue);
    setTransitioning(true);
  };
  const handleDoubleClickStop = (event) => {
    event.stopPropagation();
  };

  // HELPERS

  // RENDERS

  // RENDER: SliderBipolar
  return (
    <div ref={refCb} className={classNames(styles.sliderBipolar, {
      [styles.transitioning]: transitioning,
    })}
      onClick={handleClick}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}

      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
      >

      {/* LINE */}
      <div className={styles.lineLayer}>
        <div className={styles.line}></div>
      </div>

      {/* BARS */}
      <div className={styles.barsLayer}>
        <div className={styles.barContainerLeft}>
          <div className={styles.bar} style={{
            width: `${barWidthLeft}%`
          }}></div>
        </div>
        <div className={styles.barCenter}></div>
        <div className={styles.barContainerRight}>
          <div className={styles.bar} style={{
            width: `${barWidthRight}%`
          }}></div>
        </div>
      </div>

      {/* KNOB */}
      <div className={classNames(styles.knobLayer, {
          [styles.touched]: touched,
        })}
        style={{
          transform: `translateX(${knobTranslate}%)`
        }}>
        <div className={classNames(styles.knob)}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          onDoubleClick={handleDoubleClick}
          onClick={handleDoubleClickStop}
          >
          <div className={styles.knobBackground}>
            <div className={styles.knobArrows}>
              <ArrowLeft/>
              <ArrowRight/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderBipolar;
