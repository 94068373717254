export const getEmployeeOverview = store => store.employeeOverview;

export const getEmployeesTotalCount = store =>
  getEmployeeOverview(store) && getEmployeeOverview(store).totalCount;

export const getEmployees = store =>
  getEmployeeOverview(store) && getEmployeeOverview(store).employees;

export const getEmployeesLoading = store =>
  getEmployeeOverview(store) ? getEmployeeOverview(store).loading : false;

export const getEmplooyeesError = store =>
  getEmployeeOverview(store) ? getEmployeeOverview(store).error : null;

export const getEmployee = (store => getEmployeeOverview(store) && getEmployeeOverview(store).employee);

export const getEmployeeError = (store => getEmployeeOverview(store) && getEmployeeOverview(store).employeeError);

export const getEmployeeAdminProfile = (store) => {
  const employee = getEmployee(store);

  if (!employee) {
    return null;
  }

  const { id, name, position, orgRoles, roles, lastActive, company, companyId, email, disabled} = getEmployee(store);
  return {
    id,
    name,
    position,
    orgRoles,
    roles,
    lastActive,
    company,
    companyId,
    email,
    disabled
  };
};
