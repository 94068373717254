import React, { useState } from 'react';
import styles from './RmpAssessmentEnd.module.scss';

// STORE

// UTILS
import { useTranslate } from 'utils/translator';

// OTHER COMPONENTS
import Button from 'ui/basic/forms/Button';


const RmpAssessmentEnd = (props) => {
  const {
    finishable = false,
    handleEnd = () => {},
    errorEnd
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ submitButtonDisabled, setSubmitButtonDisabled ] = useState(false);

  return (
    <div className={styles.assessmentEnd}>
      { errorEnd }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role={'img'} aria-label={'applause'}>&#128588;</span>

          <div className={styles.sTitle}>
            { translate('rmp_final_page_title') || 'Du hast es fast geschafft.' }
          </div>

          <div className={styles.copy}>
            { translate('rmp_final_page_copy') || 'Bitte bestätige deine Angaben durch Klick auf den Button “Absenden”.' }
          </div>

          <Button
            size={'M'}
            looks={'secondary'}
            disabled={submitButtonDisabled && !finishable}
            onClick={() => {
              setSubmitButtonDisabled(true);
              handleEnd();
            }}
          >
            { translate('send_lbl') || 'Absenden' }
          </Button>
        </>
      )}
    </div>
  )
};

export default RmpAssessmentEnd;
