// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './SingleSelect.module.scss';

// ASSETS
import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};
const CheckMark = IconsSvg.CheckBig;

// COMPONENT: SingleSelect
const SingleSelect = (props) => {
  // PROPS
  const {
    value = '',
    disabled = false,
    options = [],
    onChange = (selectedValue, selectedIndex) => {},
   } = props;

  // SPECIAL HOOKS

  const [ valueInternal, setValueInternal ] = useState('');
  useEffect(() => {
    setValueInternal(value);
  }, [value]);

  useEffect(() => {
    const selectedIndex = options.findIndex((option) => option.value === valueInternal);
    if (selectedIndex > -1) {
      onChange(valueInternal, selectedIndex);
    }
  }, [valueInternal, options, onChange]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: SingleSelect
  return (
    <div className={classNames(styles.singleSelect)}>
      { options.map((option, index) => (
        <div key={index} className={classNames(styles.item, {
            [styles.active]: option.value === valueInternal
          })}
          onClick={() => {
            if (disabled) return;
            setValueInternal(option.value);
          }}
        >
          <div className={styles.label}>
            {option.label}
          </div>
          <div className={classNames(styles.checkmark, {
            [styles.show]: option.value === valueInternal
          })}>
            { <CheckMark/>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SingleSelect;
