import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './RmpReport.module.scss';

import {
  resultIsStrong,
  resultIsWeak,
  RMP_FIRST_MARKER_POSITION
} from 'utils/rmp';

import {Icons} from 'assets/icons';
import RmpDiagram from 'assets/assessments/rmp-diagram.svg';

import {Icon, Link, RmpBipolarDiagram, Modal} from 'ui/basic';
import RmpResultTable from '../RmpResultTable';

import { useTranslate } from 'utils/translator';
import {setDecimalSeperator} from 'utils/userpreference';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';
import {useBreakpoint} from 'utils/hooks';

const CONTENTS = [
  {id: 'general-info', name: 'rmp_report_subtitle_general_info', altName: 'Allgemeine Informationen'},
  {id: 'classify-results', name: 'rmp_report_subtitle_general_result_info', altName: 'Die Ergebnisse richtig einordnen'},
  {id: 'quick-results', name: 'rmp_report_subtitle_results_ataglance', altName: 'Die Ergebnisse auf einen Blick'},
  {id: 'detailed-results', name: 'rmp_report_subtitle_results_detailed', altName: 'Die Ergebnisse im Detail'}
];

const GENERAL_INFO_DESCRIPTION =
  'Das wissenschaftliche Fundament des Reiss Motivation Profile® bildet die Motivationsforschung' +
  'des amerikanischen Professors Steven Reiss. Durch zahlreiche Studien mit Tausenden von' +
  'Teilnehmern identifizierte er insgesamt 16 psychologische Bedürfnisse, die jeder Mensch in sich trägt.' +
  'Diese Lebensmotive müssen befriedigt werden, damit wir unser Leben als sinnvoll empfinden.' +
  'Sie werden durch unsere Gene und Erfahrungen in der Kindheit und frühen Jugend geprägt,' +
  ' bleiben nach bisherigen Erkenntnissen über unser Leben hinweg weitgehend konstant und bieten' +
  'uns damit eine dauerhafte Orientierung.';

const DEFAULT_DIMENSION_DESCRIPTION =
  'Das Lebensmotiv Neugier ist das Bedürfnis, den Dingen auf den Grund zu gehen.' +
  'Wie in der Mo-tivationstheorie von Professor Steven Reiss definiert, steckt hinter dem' +
  'Lebensmotiv Neugier das Bedürfnis nach intellektueller Aktivität - im Gegensatz zum Verlangen,' +
  'die eigene Umgebung zu erforschen (welches unter das Lebensmotiv Ruhe fällt).';

const DEFAULT_STRONG_CALLOUT =
  'Ihr Lebensmotiv Idealismus ist im Reiss Motivation Profile® stark ausgeprägt. ' +
  'Wahrscheinlich sind Sie ein Mensch, dem soziale Gerechtigkeit sehr wichtig ist ' +
  'und dem das Wohlergehen von Bedürftigen am Herzen liegt. Ein stark ausgeprägtes' +
  ' Lebensmotiv Idealismus zeigt sich in einem Verhalten, das von Mitgefühl, Großherzigkeit ' +
  'und Selbstlosigkeit geprägt ist. Ihnen ist es wahrscheinlich wichtig, anderen zu helfen. ' +
  'Vielleicht engagieren Sie sich für humanitäre Zwecke wie beispielsweise dafür, ' +
  'Krankheiten auszurotten oder Armut zu bekämpfen.';

const DEFAULT_AVERAGE_CALLOUT =
  'Ihr Lebensmotiv Idealismus ist im Reiss Motivation Profile® durchschnittlich ausgeprägt. ' +
  'Wahrscheinlich sind Sie ein Mensch, dem soziale Gerechtigkeit sehr wichtig ist ' +
  'und dem das Wohlergehen von Bedürftigen am Herzen liegt. Ein stark ausgeprägtes' +
  ' Lebensmotiv Idealismus zeigt sich in einem Verhalten, das von Mitgefühl, Großherzigkeit ' +
  'und Selbstlosigkeit geprägt ist. Ihnen ist es wahrscheinlich wichtig, anderen zu helfen. ' +
  'Vielleicht engagieren Sie sich für humanitäre Zwecke wie beispielsweise dafür, ' +
  'Krankheiten auszurotten oder Armut zu bekämpfen.';

const DEFAULT_WEAK_CALLOUT =
  'Ihr Lebensmotiv Idealismus ist im Reiss Motivation Profile® gering ausgeprägt. ' +
  'Wahrscheinlich sind Sie ein Mensch, dem soziale Gerechtigkeit sehr wichtig ist ' +
  'und dem das Wohlergehen von Bedürftigen am Herzen liegt. Ein stark ausgeprägtes' +
  ' Lebensmotiv Idealismus zeigt sich in einem Verhalten, das von Mitgefühl, Großherzigkeit ' +
  'und Selbstlosigkeit geprägt ist. Ihnen ist es wahrscheinlich wichtig, anderen zu helfen. ' +
  'Vielleicht engagieren Sie sich für humanitäre Zwecke wie beispielsweise dafür, ' +
  'Krankheiten auszurotten oder Armut zu bekämpfen.';

const DEFAULT_COPYRIGHT_TITLE = '© IDS Publishing Corporation. All rights reserved.';
const DEFAULT_COPYRIGHT_DESCRIPTION =
  'All uses of this content are covered by this Copyright Notice,' +
  'which may not be removed or altered. No modidfications of the content are permitted, ' +
  'including any translations into another language. No rights are granted for use of any portion ' +
  'of this content without the express, written permission obtained in advance from IDS Publishing Corporation,' +
  ' including creation of any derivative work for puoblication such as documents that quote, cite,' +
  ' or are based upon this content , whether for research puposes or otherwise.';


const RmpReport = (props) => {
  const {strongResults, weakResults, averageResults, onClose} = props;

  const ref = useRef();
  const translate = useTranslate();
  const bp = useBreakpoint();

  const [width, setWidth] = useState();
  const [copyrightDialogVisible, setCopyrightDialogVisible] = useState(false);

  const renderCalloutComponent = (score, dimensionId) => {
    if (resultIsStrong(score)) {
      return (
        <>
          <div className={styles.summary}>{translate(`rmp_report_${dimensionId}_strong_short_summ`) || 'Test Summary'}</div>
          <div className={styles.details}>
            {translate(`rmp_report_${dimensionId}_strong_details`) || DEFAULT_STRONG_CALLOUT}
          </div>
        </>
      )
    }

    if (resultIsWeak(score)) {
      return (
        <>
          <div className={styles.summary}>{translate(`rmp_report_${dimensionId}_weak_short_summ`) || 'Test Summary'}</div>
          <div className={styles.details}>
            {translate(`rmp_report_${dimensionId}_weak_details`) || DEFAULT_WEAK_CALLOUT}
          </div>
        </>
      )
    }

    return (
      <div className={styles.details}>
        {translate(`rmp_report_${dimensionId}_average_details`) || DEFAULT_AVERAGE_CALLOUT}
      </div>
    );
  };


  const handleResize = useCallback(() => {
    let width = 278;

    const container = ref.current;
    if (bp.isXs) {
      if (!container) {
        width = 278;
      }
      width = document.body.clientWidth - 32;
    }

    if (bp.isS) {
      if (!container) {
        width = 476;
      }
      width = container.clientWidth;
    }

    if (bp.isM || bp.isL || bp.isL) {
      if (!container) {
        width = 640;
      }
      width = container.clientWidth;
    }

    setWidth(width);
  }, [bp]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, []);

  return (
    <div className={styles.rmpReport}>
      <div className={styles.header}>
        <span>{translate('rmp_report_header_title') || 'RMP - Report'}</span>
        <div
          className={styles.close}
          onClick={onClose}
        >
          <Icon icon={Icons.CloseBig}/>
        </div>
      </div>
      <div className={styles.bodyContainer} id='body'>
        <div className={styles.body}>
          <div className={styles.main} ref={ref}>
            <div className={styles.mTitle}>{translate('rmp_report_title') || 'Reiss Motivation Profile® - Report'}</div>
            <div className={styles.contents}>
              {CONTENTS.map((content, index) => (
                <Link
                  key={`content-${index}`}
                  type={'anchor'}
                  onClick={() => {
                    const offsetTop = document.getElementById(content.id).offsetTop;
                    document.getElementById('body').scrollTo({
                      top: offsetTop - 60, //60 - height of header
                      behavior: 'smooth'
                    })
                  }}
                >
                  {translate(content.name) || content.altName}
                </Link>
              ))}
            </div>

            <div className={styles.generalInfo} id={'general-info'}>
              <div className={styles.sTitle}>
                {translate('rmp_report_subtitle_general_info') || 'Allgemeine Informationen'}
              </div>
              <div className={styles.description}>
                {translate('rmp_report_copy_general_info') || GENERAL_INFO_DESCRIPTION}
              </div>
            </div>

            <div className={styles.classifyResults} id={'classify-results'}>
              <div className={styles.sTitle}>
                {translate('rmp_report_subtitle_general_result_info') || 'Die Ergebnisse richtig einordnen'}
              </div>
              <div className={styles.description}>
                {translate('rmp_report_copy_general_result_info') || GENERAL_INFO_DESCRIPTION}
              </div>
              <div className={styles.diagram}>
                <div className={styles.headerLabels}>
                  <span>{translate('rmp_report_chart_tl_l') || 'Gering'}</span>
                  <span className={styles.center}>{translate('rmp_report_chart_tl_c') || 'Durchschnitt'}</span>
                  <span className={styles.right}>{translate('rmp_report_chart_tl_r') || 'Stark'}</span>
                </div>
                <img src={RmpDiagram} alt={'rmp-diagram'}/>
                <div className={styles.footerLabels}>
                  <span>{translate('rmp_report_chart_bl_l') || '20 %'}</span>
                  <span>{translate('rmp_report_chart_bl_c') || '60 %'}</span>
                  <span>{translate('rmp_report_chart_bl_r') || '20 %'}</span>
                </div>
              </div>
              <div className={styles.callOutContainer}>
                <div className={styles.triangle}></div>
                <div className={styles.callOut}>
                  {translate('rmp_report_copy_general_result_info_callout') || GENERAL_INFO_DESCRIPTION}
                </div>
              </div>
            </div>

            <div className={styles.quickResults} id={'quick-results'}>
              <div className={styles.sTitle}>
                {translate('rmp_report_subtitle_results_ataglance') || 'Die Ergebnisse auf einen Blick'}
              </div>
              <div className={styles.subBlock}>
                <div className={styles.title}>
                  {translate('rmp_report_label_results_strong') || 'Stark ausgeprägte Motive'}
                </div>
                <div className={styles.links}>
                  {strongResults.length === 0 ?
                    '-' :
                    strongResults.map((motive, index) => (
                      <Link
                        key={`strong-motive-${index}`}
                        type={'anchor'}
                        onClick={() => {
                          const offsetTop = document.getElementById(motive.id).offsetTop;
                          document.getElementById('body').scrollTo({
                            top: offsetTop - 60, //60 - height of header
                            behavior: 'smooth'
                          })
                        }}
                      >
                        {`${translate(`rmp_results_label_${motive.id}`) || motive.name} (${setDecimalSeperator(motive.result, 2)})`}
                      </Link>
                    ))
                  }
                </div>
              </div>
              <div className={styles.subBlock}>
                <div className={styles.title}>
                  {translate('rmp_report_label_results_weak') || 'Gering ausgeprägte Motive'}
                </div>
                <div className={styles.links}>
                  {weakResults.length === 0 ?
                    '-' :
                    weakResults.map((motive, index) => (
                      <Link
                        key={`week-motive-${index}`}
                        type={'anchor'}
                        onClick={() => {
                          const offsetTop = document.getElementById(motive.id).offsetTop;
                          document.getElementById('body').scrollTo({
                            top: offsetTop - 60, //60 - height of header
                            behavior: 'smooth'
                          })
                        }}
                      >
                        {`${translate(`rmp_results_label_${motive.id}`) || motive.name} (${setDecimalSeperator(motive.result, 2)})`}
                      </Link>
                    ))
                  }
                </div>
              </div>
              <div className={styles.subBlock}>
                <div className={styles.title}>
                  {translate('rmp_report_label_results_average') || 'Durchschnittlich ausgeprägte Motive'}
                </div>
                <div className={styles.links}>
                  {averageResults.length === 0 ?
                    '-' :
                    averageResults.map((motive, index) => (
                      <Link
                        key={`average-motive-${index}`}
                        type={'anchor'}
                        onClick={() => {
                          const offsetTop = document.getElementById(motive.id).offsetTop;
                          document.getElementById('body').scrollTo({
                            top: offsetTop - 60, //60 - height of header
                            behavior: 'smooth'
                          })
                        }}
                      >
                        {`${translate(`rmp_results_label_${motive.id}`) || motive.name} (${setDecimalSeperator(motive.result, 2)})`}
                      </Link>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className={styles.detailedResults} id={'detailed-results'}>
              <div className={styles.sTitle}>
                {translate('rmp_report_subtitle_results_detailed') || 'Die Ergebnisse im Detail'}
              </div>

              {strongResults.concat(weakResults).concat(averageResults).map((resultItem, index) => (
                <div className={styles.dimension} id={resultItem.id} key={index}>
                  <div className={styles.title}>
                    {translate(`rmp_results_label_${resultItem.id}`) || resultItem.name}
                  </div>
                  <div className={styles.description}>
                    {translate(`rmp_report_${resultItem.id}_gen_description`) || DEFAULT_DIMENSION_DESCRIPTION}
                  </div>
                  <div className={styles.diagramBlock}>
                    <div className={styles.diagramHeader}>
                      <div className={styles.left}>{translate('big5_report_facet_result') || 'Ergebnis'}</div>
                      <div className={styles.right}>{setDecimalSeperator(resultItem.result, 2)}</div>
                    </div>
                    <div className={styles.diagram}>
                      <RmpBipolarDiagram
                        width={width}
                        score={resultItem.result + 2}
                        showNumbers={true}
                      />
                    </div>
                    <div className={styles.underTitles}>
                      <div className={resultItem.result < -RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
                        {translate('rmp_results_scale_min') || 'gering'}
                      </div>
                      <div className={resultItem.result > RMP_FIRST_MARKER_POSITION ? styles.blue: null}>
                        {translate('rmp_results_scale_max') || 'stark'}
                      </div>
                    </div>
                  </div>

                  <div className={styles.callOutContainer}>
                    <div className={styles.triangle}></div>
                    <div className={styles.callOut}>
                      {renderCalloutComponent(resultItem.result, resultItem.id)}
                    </div>
                  </div>

                  <RmpResultTable
                    dimensionId={resultItem.id}
                    score={resultItem.result}
                  />
                </div>
              ))}
            </div>

            <div className={styles.copyright}>
              <span onClick={() => setCopyrightDialogVisible(true)}>
                {translate('rmp_ass_copyrightlink', ['{{year}}', new Date().getFullYear()]) || DEFAULT_COPYRIGHT_TITLE}
              </span>
            </div>
          </div>
        </div>
      </div>

      {copyrightDialogVisible &&
        <Modal
          header={translate('rmp_ass_copyrightlink', ['{{year}}', new Date().getFullYear()]) || DEFAULT_COPYRIGHT_TITLE}
          secondaryButtonTitle={translate('okay_lbl')}
          onClose={() => setCopyrightDialogVisible(false)}
        >
          {translate('rmp_ass_info_description_copyright') || DEFAULT_COPYRIGHT_DESCRIPTION}
        </Modal>
      }

    </div>
  )
};

export default RmpReport;
