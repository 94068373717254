// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './AssessmentPageWithFooter.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { useWindowHeight, useDebounce } from 'utils/hooks';
import { pxToNumber } from 'utils/styleTools';

// STORE

// CONFIG & DATA

// COMPONENT: AssessmentPageWithFooter
const AssessmentPageWithFooter = (props) => {
  // PROPS
  const {
    children,
    copyrightShort, // copyrightShort displayed only if supplied
    copyrightDescriptionHeader,  // shows modal on click on copyrightShort if both are supplied
    copyrightDescriptionContent,   // shows modal on click on copyrightShort if both are supplied
    nextButtonText, // required
    onClickNext, // disables button if not supplied
  } = props;

  const translate = useTranslate();

  // COMPONENT/UI STATE and REFS
  const [showModal, setShowModal] = useState();

  // iOS 100vh problem
  const windowHeight = useWindowHeight();
  const debouncedWindowHeight = useDebounce(windowHeight, 250);
  // footer top
  const footerTopInit = window.innerHeight - pxToNumber(styles.introFooterHeight) - pxToNumber(styles.footerHeight)
  const [ footerTop, setFooterTop ] = useState(footerTopInit);
  useEffect(() => {
    let top = debouncedWindowHeight - pxToNumber(styles.introFooterHeight) - pxToNumber(styles.footerHeight);

    setFooterTop(top);
  }, [ debouncedWindowHeight ]);

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: AssessmentPageWithFooter
  return (
    <div className={classNames(styles.assessmentPageWithFooter)}>

      <div className={styles.scrollableContent}>
        <div className={styles.grid}>
          <div className={styles.container}>

            {/* CHILDREN */}
            <div className={styles.children}>
              { children }
            </div>

            {/*COPYRIGHT*/}
            { copyrightShort && (
              <div className={styles.copyright}>
                <span onClick={() => setShowModal(true)}>
                  { copyrightShort }
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*FOOTER*/}
      <div className={styles.footer}
        style={{top: `${footerTop}px`}}>
        <div className={styles.footerGrid}>
          <div className={styles.footerContent}>
            <Button
              size={'L'}
              disabled={!onClickNext}
              onClick={onClickNext}
            >{ nextButtonText }</Button>
          </div>
        </div>
      </div>

      {/*MODAL*/}
      {showModal && copyrightDescriptionHeader && copyrightDescriptionContent && (
        <div className={styles.modal}>
          <Modal
            header={copyrightDescriptionHeader}
            secondaryButtonTitle={translate('ok') || 'OK'}
            onClose={() => setShowModal(false)}
          >
            { copyrightDescriptionContent }
          </Modal>
        </div>
      )}

    </div>
  );
};

export default AssessmentPageWithFooter;
