import React, { lazy } from 'react';

// OTHER COMPONENTS
const StaticMain = lazy(() => import('./pages/StaticMain'  /* webpackChunkName: "adminUG" */));

export const routesConfigStatic = {
  show: {
    forUserGroup: 'all'
  },
  routes: [
    {
      path: '/sso-login',
      pageType: 'standalone',
      pageWrapper: { compensateHeader: true },
      PageComponent: () => <StaticMain />,
      LazyPageComponent: 'SsoLogin',
      show: {
        needsLogout: true,
        forFlavor: ['bluquist', 'balancedYou'],
      },
      tools: {
        browserTitleKey: 'login_button_login'
      }
    }
  ]
};
