import * as fromActionTypes from 'store/actionTypes/wellBeing';

const initialState = {
  history: null,
  myWellBeing: null,
  orgHistory: null,
  orgIndex: null,
  orgIndexUserCount: 0,
  orgIndexNotEnoughData: false,
  success: false,

  loading: false,
  submitWellbeingIndexProcessing: false,

  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.INIT_SUBMIT_WELLBEING_INDEX: {
      return {
        ...state,
        submitWellbeingIndexProcessing: false,
        success: false
      };
    }
    case fromActionTypes.SUBMIT_WELLBEING_INDEX: {
      return {
        ...state,
        submitWellbeingIndexProcessing: true
      };
    }
    case fromActionTypes.SUBMIT_WELLBEING_INDEX_FULFILLED: {
      return {
        ...state,
        success: true,
        submitWellbeingIndexProcessing: false,
        error: null
      };
    }
    case fromActionTypes.SUBMIT_WELLBEING_INDEX_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        success: false,
        submitWellbeingIndexProcessing: false,
        error
      };
    }
    case fromActionTypes.GET_WELLBEING_HISTORY: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_WELLBEING_HISTORY_FULFILLED: {
      const {wellBeingHistory} = action.payload;
      return {
        ...state,
        history: wellBeingHistory,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_WELLBEING_HISTORY_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        success: false,
        loading: false,
        error
      };
    }
    case fromActionTypes.GET_WELLBEING_INDEX_HISTORY: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_WELLBEING_INDEX_HISTORY_FULFILLED: {
      const {wellBeingHistory} = action.payload;
      return {
        ...state,
        history: wellBeingHistory,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_WELLBEING_INDEX_HISTORY_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        success: false,
        loading: false,
        error
      };
    }
    case fromActionTypes.GET_WELLBEING_INDEX: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_WELLBEING_INDEX_FULFILLED: {
      const {wellBeing} = action.payload;
      return {
        ...state,
        myWellBeing: wellBeing,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_WELLBEING_INDEX_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        success: false,
        loading: false,
        error
      };
    }
    case fromActionTypes.GET_ORG_WELLBEING_INDEX: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_ORG_WELLBEING_INDEX_FULFILLED: {
      const {wellbeing, users, notEnoughData = false} = action.payload;
      return {
        ...state,
        orgIndex: wellbeing,
        orgIndexUserCount:  users,
        orgIndexNotEnoughData: notEnoughData,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_ORG_WELLBEING_INDEX_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        success: false,
        loading: false,
        error
      };
    }
    case fromActionTypes.INIT_GET_ORG_WELLBEING_HISTORY: {
      return {
        ...state,
        orgHistory: null,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_ORG_WELLBEING_HISTORY: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_ORG_WELLBEING_HISTORY_FULFILLED: {
      const {wellBeingHistory} = action.payload;
      return {
        ...state,
        orgHistory: wellBeingHistory,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_ORG_WELLBEING_HISTORY_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        success: false,
        loading: false,
        error
      };
    }
    default: {
      return state;
    }
  }
};
