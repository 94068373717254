import React, { useEffect, useState } from 'react'
import styles from './LeaderUserGroup.module.scss';

import { useDispatch, useSelector } from 'react-redux';

import * as api from 'api';

import { useTranslate } from 'utils/translator';

import { getConfigurationExtended } from 'store/actions/configuration';
import { selectConfigurationLoading, selectSwitches } from 'store/selectors/configuration';
import { selectUserRoleNext } from 'store/selectors/currentUser';

import Toggle from 'ui/basic/forms/Toggle';


const DESCRIPTIONS = [
  'Für Mitarbeiter die andere Mitarbeiter führen',
  'Alle Rechte des Basisprofils',
  'Zugriff auf Rollenmanagement (Rollenerstellung, Rollenprofile & Besetzung)',
  'Zugriff auf Team-Management Funktionen (Teamerstellung, Team-Profile & vieles mehr)'
];

const CONFIG_SWITCHES = [
  {
    id: 'employeesInvite',
    title: 'usergroup_settings_leader_right1',
    description: 'usergroup_settings_leader_right1_descr'
  },
  {
    id: 'candidatesInvite',
    title: 'invite_candidates_permission',
    description: 'invite_candidates_permission_descr'
  },
  {
    id: 'employeesView',
    title: 'usergroup_settings_leader_right9',
    description: 'usergroup_settings_leader_right9_descr'
  },
  {
    id: 'candidatesView',
    title: 'view_candidates_permission',
    description: 'view_candidates_permission_descr'
  },
  {
    id: 'rolesView',
    title: 'usergroup_settings_leader_right2',
    description: 'usergroup_settings_leader_right2_descr',
    childrenItems: ['rolesCreate', 'rolesMatch']
  },
  {
    id: 'rolesCreate',
    title: 'usergroup_settings_leader_right3',
    description: 'usergroup_settings_leader_right3_descr',
    parentItem: 'rolesView'
  },
  {
    id: 'rolesMatch',
    title: 'usergroup_settings_leader_right4',
    description: 'usergroup_settings_leader_right4_descr',
    parentItem: 'rolesView'
  },
  {
    id: 'teamsView',
    title: 'usergroup_settings_leader_right8',
    description: 'usergroup_settings_leader_right8_descr',
    childrenItems: ['teamsCreate', 'teamsStaff']
  },
  {
    id: 'teamsCreate',
    title: 'usergroup_settings_leader_right6',
    description: 'usergroup_settings_leader_right6_descr',
    parentItem: 'teamsView'
  },
  {
    id: 'teamsStaff',
    title: 'usergroup_settings_leader_right7',
    description: 'usergroup_settings_leader_right7_descr',
    parentItem: 'teamsView'
  },
  {
    id: 'vacanciesView',
    title: 'usergroup_settings_leader_view_vacancies',
    description: 'usergroup_settings_leader_view_vacancies_descr',
    childrenItems: ['vacanciesCreate']
  },
  {
    id: 'vacanciesCreate',
    title: 'usergroup_settings_leader_create_vacancy',
    description: 'usergroup_settings_leader_create_vacancy_descr',
    parentItem: 'vacanciesView'
  }
]


const LeaderUserGroup = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const userRoleNext = useSelector(selectUserRoleNext);

  // CONFIG SWITCHES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const configSwitchesFromStore = useSelector(selectSwitches);
  const [configSwitchUpdateProcessing, setConfigSwitchUpdateProcessing] = useState();
  const [configSwitches, setConfigSwitches] = useState([]);
  useEffect(() => {
    if (configSwitchesFromStore && configSwitchesFromStore.length > 0) {
      const internal = CONFIG_SWITCHES.map(configSwitchItem => {
        const configSwitchFromStore = configSwitchesFromStore.find(switchItem => switchItem.id === configSwitchItem.id);

        return {
          ...configSwitchItem,
          active: !!(configSwitchFromStore && configSwitchFromStore.status)
        }
      })

      setConfigSwitches(internal);
    }
  }, [configSwitchesFromStore])

  const getIsDisabled = (configSwitch) => {
    if (!configSwitch || !configSwitch.parentItem) {
      return false;
    }

    const parentItem = configSwitches.find(rightItem => rightItem.id === configSwitch.parentItem);
    return parentItem && parentItem.active === false;
  }

  const configurationLoading = useSelector(selectConfigurationLoading);

  const handleToggleChange = (configSwitch, value) => {
    setConfigSwitchUpdateProcessing(true);

    // on toggling off, all children items that are dependent from parent should also be deactivated
    if (!value && configSwitch.childrenItems) {
      const configSwitchIds = [configSwitch.id, ...configSwitch.childrenItems.map(childItem => childItem)];
      Promise.all(configSwitchIds.map(configSwitchId => {
        return api.post(`core/config/switches/${configSwitchId}`, {active: value})
      }))
        .then(() => {
          dispatch(getConfigurationExtended({ userGroup: userRoleNext }));
          setConfigSwitchUpdateProcessing(false);
        })
    } else {
      api
        .post(`core/config/switches/${configSwitch.id}`, {active: value})
        .then(({ok, status, data}) => {
          if (ok && status === 200) {
            dispatch(getConfigurationExtended({ userGroup: userRoleNext }));
          }

          setConfigSwitchUpdateProcessing(false);
        })
    }
  }

  return (
    <div className={styles.leaderUserGroup}>
      <div className={styles.main}>
        <div className={styles.title}>
          {translate('usergroup_settings_leader') || 'Leader'}
        </div>
        <div className={styles.description}>
          {DESCRIPTIONS.map((description, index) => (
            <div className={styles.descRow} key={`description-${index}`}>
              <span>-</span>
              <span>{translate(`usergroup_settings_leader_descr${index + 1}`) || description}</span>
            </div>
          ))}
        </div>

        <div className={styles.rights}>
          <div className={styles.title}>
            {translate('usergroup_settings_leader_rights') ||
            'Rechte  für die Nutzergruppe “Leader” ändern'
            }
          </div>
          <div className={styles.description}>
            {translate('usergroup_settings_leader_rights_descr') ||
            'Hier kannst du die spezifischen Rechte für diese Nutzergruppe ändern'
            }
          </div>
          <div className={styles.rows}>
            {configSwitches.map((configSwitchItem, index) => (
              <div className={styles.row} key={index}>
                <div className={styles.row1}>
                  <span className='blu-typeLabel'>{translate(configSwitchItem.title)}</span>
                  <Toggle
                    id={`toggle-${index}`}
                    checked={configSwitchItem.active}
                    disabled={getIsDisabled(configSwitchItem) || configSwitchUpdateProcessing || configurationLoading}
                    onChange={(checked) => handleToggleChange(configSwitchItem, checked)}
                  />
                </div>
                <div className={styles.row2}>
                  {translate(configSwitchItem.description)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
};

export default LeaderUserGroup;
