export const getItem = key => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return { done: false, message: 'Error', error };
  }
};

export const setItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
    return { done: true, message: 'Item was setted' };
  } catch (error) {
    return { done: false, message: 'Error', error };
  }
};

export const removeItem = key => {
  try {
    localStorage.removeItem(key);
    return { done: true, message: 'Item was removed' };
  } catch (error) {
    return { done: false, message: 'Error', error };
  }
};
export const clearStorage = () => {
  try {
    localStorage.clear();
    return { done: true, message: 'Storage was cleared' };
  } catch (error) {
    return { done: false, message: 'Error', error };
  }
};

