import React from 'react';
import styles from './MetricCard.module.scss';
import Card from 'ui/basic/containers/Card';

const MetricCard = (props) => {
  const {
    title,
    items = [],
    hints = []
  } = props;

  return (
    <Card hasPaddingsSmall hasFullHeight>
      {/*TITLE*/}
      {title && <div className={styles.title}>{title}</div>}

      {/*ITEMS*/}
      <div className={styles.items}>
        {items.map((item, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.label}>{item.label}</div>
            <div className={styles.value}>{item.value}</div>
            <div className={styles.hint}>{item.hint}</div>
          </div>
        ))}
      </div>

      {/*HINTS*/}
      {hints.length > 0 &&
      <div className={styles.hints}>
        {hints.map((hint, index) => (
          <div className={styles.hint} key={index}>{hint}</div>
        ))}
      </div>
      }
    </Card>
  )
};

export default MetricCard;
