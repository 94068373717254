// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './QuestionSegments.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
import {getCompetencyDescription, getCompetencyName, getTranslationIds} from './translationIds';

const CONFIG = {
  answerDelay: 200,
  animationStartDelay: 200,
  ALLOWED_KEYS: ['1', '2', '3', '4', '5']
};

// COMPONENT: QuestionSegments
const QuestionSegments = (props) => {
  // PROPS
  const {
    question,
    // range = {}, // gonna use later maybe
    selectedValue,
    clickBlock,
    onAnswer = () => {},
    onAnimation = () => {},
  } = props;

  const translate = useTranslate();

  // COMPONENT/UI STATE and REFS
  const assessmentType = question.id.split('_')[0];

  const questionContainerRef = useRef();

  const [showAnimation, setShowAnimation] = useState(true);
  const [answered, setAnswered] = useState(false);
  const [closing, setClosing] = useState(false);
  const [clickedCellNumber, setClickedCellNumber] = useState();
  const [hoveredCellNumber, setHoveredCellNumber] = useState();
  const [width, setWidth] = useState();
  const translationIds = getTranslationIds(assessmentType);

  const questionContainerClassNames = classNames(
    styles.questionContainer,
  );

  // SPECIAL HOOKS
  const getWidth = useCallback(() => {
    const container = questionContainerRef.current;
    if (!container) {
      return 256;
    }
    return container.clientWidth;
  }, []);

  const handleCellClick = useCallback((cellNumber) => {
    if (clickBlock || answered) return;

    setClickedCellNumber(cellNumber);
    setAnswered(true);
    setTimeout(() => {
      setClosing(true);
      onAnimation();
    }, CONFIG.animationStartDelay);
    setTimeout(() => {
      onAnswer(cellNumber, CONFIG.animationStartDelay + CONFIG.answerDelay);
    }, CONFIG.animationStartDelay + CONFIG.answerDelay);
  }, [clickBlock, answered, onAnswer, onAnimation]);

  const handleResize = useCallback(() => {
    setWidth(getWidth());
  }, [getWidth]);

  const handleKeyUp = useCallback((event) => {
    if (CONFIG.ALLOWED_KEYS.includes(event.key)) {
      handleCellClick(Number(event.key));
    }
  }, [handleCellClick]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('keyup', handleKeyUp);
    setTimeout(() => {
      handleResize();
    },5);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleResize, handleKeyUp]);

  // EFFECT HOOKS
  useEffect(() => {
    setClickedCellNumber(selectedValue);
    setShowAnimation(false);
    setTimeout(() => {
      setShowAnimation(true);
    });
  }, [question, selectedValue]);

  // STORE HOOKS

  // METHODS
  const getBackgroundColor = (cellId) => {
    if ((clickedCellNumber === null || clickedCellNumber === undefined) &&
      (hoveredCellNumber === null || hoveredCellNumber === undefined)) {
      return styles.colorGrey4;
    }

    if (clickedCellNumber >= cellId) {
      return styles.colorPrimary1;
    }

    if (hoveredCellNumber >= cellId) {
      return styles.colorPrimary3;
    }
  };

  // EVENT HANDLES

  // HELPERS

  // RENDERS
  const getCells = () => {
    const cells = [];
    const cellWidth = width / 5;
    //answers for competencies assessment should in range of [1...5].
    //that's why within for-loop index starts with 1, not with 0, because index i will be handled as answer for question.
    for (let i=1; i<6; i++) {
      const cell =
        <div
          key={`competencies-cell-${i}`}
          className={styles.cell}
          style={{
            width: `${cellWidth}px`,
            backgroundColor: getBackgroundColor(i)
          }}
          onClick={() => handleCellClick(i)}
          onMouseOver={() => setHoveredCellNumber(i)}
          onMouseOut={() => setHoveredCellNumber(null)}
        >
        </div>;
      cells.push(cell);
    }
    return cells;
  };

  // RENDER: QuestionSegments
  if (!question) {
    return null;
  }

  return (
    <div className={classNames(styles.questionSegments, {
      [styles.closing]: closing
    })}>
      <div className={styles.container}>
        <div className={styles.label}>
          {translate(translationIds.guidanceQuestion) || 'Wie stark ist die folgende Kompetenz bei dir ausgeprägt?'}
        </div>
        {showAnimation ?
          <div className={questionContainerClassNames} ref={questionContainerRef}>
            <div className={styles.question}>
              {translate(getCompetencyName(assessmentType, question.id.split('_').pop())) || question.question }
            </div>
            <div className={styles.chooser} style={{width: `${width}px`}}>
              {getCells()}
            </div>
            <div className={styles.labels}>
              <span>{translate(translationIds.hintLeft) || 'gering'}</span>
              <span>{translate(translationIds.hintRight) || 'stark'}</span>
            </div>
            <div className={styles.description}>
              {translate(getCompetencyDescription(assessmentType, question.id.split('_').pop())) ||
              'Die Fähigkeit, das Verhalten Situationen und Verhältnissen anzupassen.'
              }
            </div>
          </div> : null
        }
      </div>
    </div>
  );
};

export default QuestionSegments;
