import React, { memo } from 'react';
import * as css from 'styles-imports/shared.scss';

const GREY_CIRCLE_DIAMETER = 3;

const LineDiagram = memo((props) => {
  const {score, range, width} = props;

  const innerWidth = width - 16.5;
  const viewBox =`0 0 ${width} 15`;
  const step = (innerWidth / 9);
  let start = 7.5;
  let circles = [];

  const getCircles = () => {
    for (let i=0; i<10; i++) {
      circles.push(
        <rect key={`index-${i}`} x={start} y="6" width="3" height="3" rx="1.5" fill={css.colorGrey3}/>
      );
      start += step;
    }
    return circles;
  };

  return (
    <svg width={width} height="15" viewBox={viewBox}>
      {/*RANGES*/}
      {(range && range.length > 0) &&
      <rect
        x={(range[0] - 1) * step + 7.5} //-1 to start from 0
        width={(range[1] - range[0]) * step + GREY_CIRCLE_DIAMETER}
        height="15"
        rx="8"
        fill={css.colorPrimary3}
      />}

      {/*GREY LINE*/}
      <rect x="7.5" y="7" width={innerWidth} height="1" fill={css.colorGrey3}/>

      {/*GREY CIRCLES*/}
      {getCircles()}

      {/*BLUE MARKER*/}
      {score && <rect x={(step * (score - 1)) + 1.5} width="15" height="15" rx="7.5" fill={css.colorPrimary1}/>}
    </svg>
  );
});

LineDiagram.defaultProps = {
  width: 250
};

export default  LineDiagram;
