import React, { useEffect, useState, useCallback } from 'react';

// 3RD PARTY
import { useLocation, useHistory } from 'react-router-dom';

// STORE NEXT
import { Provider, useSelector, useDispatch} from 'react-redux';
import storeNext from 'features/framework/utils/storeNext';
import { LoggedIn } from 'features/framework/components/LoggedIn';
import LoadConfiguration from 'features/framework/components/LoadConfiguration';
import { MainLayout } from 'features/framework/components/MainLayout';

// OTHER COMPONENTS
import {Loading} from 'ui/basic';

// STORE OLD
import {
  getLanguages,
  changeLanguage,
  loginFulfilled,
  getUserSettings,
  getCurrentUser,
  getJobFamilies
} from 'store/actions';
import { getConfigurationExtended, getConfigurationSuccess } from 'store/actions/configuration';
import { setNextRoute } from 'store/actions/routing';
import { getShowTranslationKeys } from 'store/selectors/debug';
import { getCurrentUserId, getEmail, getFirstName, getLastName, selectUserIsBasicDataReady, selectUserProfileCompletedNext, selectUserSubRoleNext } from 'store/selectors/currentUser';
import { selectIsLoggedIn, selectLoginAuthToken } from 'store/selectors/login';
import { selectUserRoleNext, getIsUserTeamLeader } from 'store/selectors/currentUser';
import { selectVacancyHasCandidates } from 'features/+candidates/store/vacancy/vacancy.selectors';
import { selectAssessmentsNext, selectCapabilitiesNext, selectFeaturesNext } from 'store/selectors/configuration';
import { selectCandidateHasRoleFitPage } from 'features/+candidates/store/candidate/candidate.selectors';
import { getCareerLevels } from 'store/actions/staticValues';

// UTILS
import * as api from 'api';
import * as localStorage from 'utils/localStorage';
import { TranslateContext, getTranslate } from 'utils/translator';
import {
  TRACKING_ENABLE,
  TRACKING_SITE_ID
} from 'utils/configuration';



const Main = () => {

  // TRANSLATE: setup context & translate function based on store data
  const translations = useSelector((state) => state && state.localisation && state.localisation.translations);
  useEffect(() => {
    console.log('translations', translations);
  }, [translations])
  const languages = useSelector((state) => state && state.localisation && state.localisation.languages);
  useEffect(() => {
    console.log('languages', languages);
  }, [languages])
  const showTranslationKeys = useSelector(getShowTranslationKeys);
  useEffect(() => {
    console.log('showTranslationKeys', showTranslationKeys);
  }, [showTranslationKeys])
  const getTranslateMemo = useCallback(() => {
    return getTranslate(translations, showTranslationKeys);
  }, [translations, showTranslationKeys]);
  const [ translate, setTranslate ] = useState(() => { return getTranslateMemo(translations, showTranslationKeys) });
  useEffect(() => {
    console.log('translations', translations, showTranslationKeys);
    const newTranslate = getTranslate(translations, showTranslationKeys);
    setTranslate(() => newTranslate);
  }, [translations, showTranslationKeys]);


  // STORE OLD
  const dispatch = useDispatch();

  // features & assessments next
  const featuresNext = useSelector(selectFeaturesNext);
  const assessmentsNext = useSelector(selectAssessmentsNext);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  // INITIALISE
  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getCareerLevels());

    if (capabilitiesNext.rolesCreate) {
      dispatch(getJobFamilies());
    }

    const currentLanguage = localStorage.getItem('selectedLanguage') || 'en_US';
    const token = localStorage.getItem('token');

    dispatch(changeLanguage({language: currentLanguage}));

    if (token && token !== 'undefined') {
      dispatch(loginFulfilled({ token: token }));
    }
  }, [ dispatch, capabilitiesNext ]);

  const handleConfiguration = (({ instance, company }) => {
    console.log('onConfiguration', instance, company)
    dispatch(getConfigurationSuccess({ company, deployment: {
      isAdminFrontend: instance.flavor === 'adminFrontend' ? true : false
    }}))
  });

  // LOGIN
  const isLoggedIn = useSelector(selectIsLoggedIn);
  useEffect(() => {
    console.log('isLoggedIn', isLoggedIn);
    if (isLoggedIn) {
      dispatch(getCurrentUser());
      dispatch(getUserSettings());
    }
  }, [dispatch, isLoggedIn]);

  // USER
  const [ tokenExpiration, setTokenExpiration ] = useState();
  const authToken = useSelector(selectLoginAuthToken);
  useEffect(() => {
    if (authToken) {
      setTokenExpiration(Date.now() + api.SESSION_DURATION);
    }
  }, [authToken]);
  const userIsBasicDataReady = useSelector(selectUserIsBasicDataReady);
  const userId = useSelector(getCurrentUserId);
  const userMail = useSelector(getEmail);
  const userFirstName = useSelector(getFirstName);
  const userLastName = useSelector(getLastName);
  // user next
  const userRoleNext = useSelector(selectUserRoleNext);
  const userSubRoleNext = useSelector(selectUserSubRoleNext);
  const userProfileCompletedNext = useSelector(selectUserProfileCompletedNext);

  // post login requests
  useEffect(() => {
    if (userIsBasicDataReady && userRoleNext && userSubRoleNext) {
      dispatch(getConfigurationExtended({ userGroup: userRoleNext, userSubGroup: userSubRoleNext }));
    }
  }, [dispatch, userIsBasicDataReady, userRoleNext, userSubRoleNext]);

  const getIsConfigExtendedReady = () => {
    return userIsBasicDataReady && featuresNext && assessmentsNext && capabilitiesNext;
  }

  // loading
  const isLoading = useSelector(state => state.loading);
  useEffect(() => {
    console.log('isLoading', isLoading);
  }, [isLoading]);

  // cross store communication
  const [ stateExtras, setStateExtras ] = useState({
    vacanyHasCandidates: false,
    candidateHasRoleFitPage: false,
  })
  const vacancyHasCandidates = useSelector(selectVacancyHasCandidates);
  const candidateHasRoleFitPage = useSelector(selectCandidateHasRoleFitPage);
  const isUserTeamLeader = useSelector(getIsUserTeamLeader);

  useEffect(() => {
    if (
      vacancyHasCandidates !== stateExtras.vacancyHasCandidates
      || candidateHasRoleFitPage !== stateExtras.candidateHasRoleFitPage
      || isUserTeamLeader !== stateExtras.isUserTeamLeader
    ) {
      const stateExtras = {
        vacancyHasCandidates,
        candidateHasRoleFitPage,
        rolesModuleActive: !!featuresNext?.roles,
        isUserTeamLeader,
      };
      setStateExtras(stateExtras);
    }
  }, [
    stateExtras,
    vacancyHasCandidates,
    candidateHasRoleFitPage,
    featuresNext,
    isUserTeamLeader,
  ]);

  // store params from mail dialogues in localstorage
  // after login user will be redirected to the respective page depending on params
  const searchParams = window.location.search;
  if (searchParams && searchParams.includes('mailType')) {
    let mailType, assessmentId;
    const splittedValues = searchParams.split('?mailType=');

    if (searchParams.includes('assessmentId')) {
      mailType = splittedValues[1].split('&assessmentId=')[0];
      assessmentId = splittedValues[1].split('&assessmentId=')[1];
    } else {
      mailType = splittedValues[1];
    }

    localStorage.setItem('mailType', mailType);
    localStorage.setItem('assessmentId', assessmentId);
  }

  // store: routing
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    dispatch(setNextRoute(location));
  }, [location, history, dispatch]);

  // TRACKING
  const [isTrackingSetUp, setIsTrackingSetUp] = useState(false);
  // matomo tracking: setup after login
  useEffect(() => {
    if(isLoggedIn && TRACKING_ENABLE === 'ENABLE' && userMail && !isTrackingSetUp) {
      setIsTrackingSetUp(true);

      // console.log('SETTING UP TRACKING');
      let visitorId;
      // setup MATOMO
      const _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['setUserId', userMail]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      _paq.push([ function() {
        visitorId = this.getVisitorId();
        api.addCustomHeaders({ 'X-VISITOR-ID': visitorId });
      }]);
      (function() {
        const u='//analytics.bluquist.cloud/';
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', TRACKING_SITE_ID]); // <--- SITE ID
        const d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript';
        g.async=true;
        g.src=u+'matomo.js';
        s.parentNode.insertBefore(g,s);
      })();
    }
  }, [isLoggedIn, userMail, isTrackingSetUp]);

  return (
    <TranslateContext.Provider value={{translate}}>

      <Loading opaque visible={isLoading || !translations
        || (isLoggedIn && !getIsConfigExtendedReady()
          )
      } />

      <Provider store={storeNext}>
        <LoadConfiguration onConfiguration={handleConfiguration} />

        <LoggedIn
          loading={isLoading || !translations || (isLoggedIn && !getIsConfigExtendedReady())}
          tokenId={authToken} tokenExpiration={tokenExpiration}
          userId={userId} userGroup={userRoleNext}
          profileCompleted={userProfileCompletedNext}
          userFirstName={userFirstName} userLastName={userLastName}
          features={featuresNext}
          assessments={assessmentsNext}
          capabilities={capabilitiesNext}
          stateExtras={stateExtras}
        />

        <MainLayout />
      </Provider>

    </TranslateContext.Provider>
  );
};

export default Main;
