// VACANCY STATUS
export const VACANCY_STATUSES = {
  OPEN: 'open',
  STAFFED: 'staffed',
  ARCHIVED: 'archived'
}

// CURRENCY
export const CURRENCY_IDS = {
  EURO: 'eur',
  USD: 'usd',
  GBP: 'gbp',
  CHF: 'chf'
}

export const CURRENCY_SYMBOLS = {
  [CURRENCY_IDS.EURO]: '€',
  [CURRENCY_IDS.USD]: '$',
  [CURRENCY_IDS.GBP]: '£',
  [CURRENCY_IDS.CHF]: 'CHF'
}

export const CURRENCY_OPTIONS = [
  {label: 'Euro', value: CURRENCY_IDS.EURO},
  {label: 'USD', value: CURRENCY_IDS.USD},
  {label: 'GBP', value: CURRENCY_IDS.GBP},
  {label: 'CHF', value: CURRENCY_IDS.CHF}
]

// EXPERIENCE
export const EXPERIENCE_OPTIONS = [
  {value: 'y1', label: '1 Jahr'},
  {value: 'y2', label: '2 Jahre'},
  {value: 'y3', label: '3 Jahre'},
  {value: 'y4', label: '4 Jahre'},
  {value: 'y5', label: '5 Jahre'},
  {value: 'y6', label: '6 Jahre'},
  {value: 'y7', label: '7 Jahre'},
  {value: 'y8', label: '8 Jahre'},
  {value: 'y9', label: '9 Jahre'},
  {value: 'y10+', label: '10+ Jahre'}
]

// OCCUPATION TYPE
export const OCCUPATION_TYPES = [
  {label: 'fixed_position', labelFallback: 'Fixed position', value: 'fixed_position'},
  {label: 'limited_contract', labelFallback: 'Limited contract', value: 'limited_contract'},
  {label: 'freelance', labelFallback: 'Freelance', value: 'freelance'},
  {label: 'internship', labelFallback: 'Internship', value: 'internship'},
  {label: 'apprenticeship', labelFallback: 'Apprenticeship', value: 'apprenticeship'}
]

