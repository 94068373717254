// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Start.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA

// COMPONENT: Start
const Start = (props) => {
  // PROPS
  const {
    state = { inject: {} },
    // answer = {},
    // onAnswer = () => {},
    controls = () => {},
   } = props;

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: Start
  return (
    <div className={classNames(styles.start)}>

      {/* BREATHE */}
      <div className={styles.breatheContainer}>
        <div className={styles.breathe}></div>
      </div>

      {/* CONTENT */}
      <div className={styles.content}>

        {/* TITLE */}
        <div className={styles.title}>
          {/* {translate('cp_intro') || 'Hallo, schön dass du da bist. Bevor wir loslegen müssen wir dich ein wenig besser Kennenlernen.'} */}
          { translate('onboarding_candidate_start', ['{{username}}', state.inject.firstName])
            || (<>
                  Hallo {state.inject.firstName}
                  <br/>
                  Erfahre wie bluquist funktioniert
                  und dir hilft den passenden Job zu finden.
                </>)}
        </div>

        {/* CONTROLS */}
        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        )}
      </div>

    </div>
  );
};

export default Start;
