import * as action from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/settings'
import {call, put, takeEvery} from 'redux-saga/effects';
import * as api from 'api';
import {LANGUAGES} from 'utils/configuration/const/languages';
import {IS_ADMIN_FRONTEND} from 'utils/configuration';
import { getConfigurationExtended } from 'store/actions/configuration';

function* updateSettings({payload}) {
  try {
    const {status, ok, data} = yield call(
      api.put,
      'core/company/features',
      payload
    );

    if (ok && status === 200) {
      yield put(action.updateSettingsFulfilled(data));
      yield put(action.getSettings());
      yield put(action.getCurrentUser());
      yield put(getConfigurationExtended());
    } else {
      yield put(action.updateSettingsRejected(data));
    }
  } catch (error) {
    yield put(action.updateSettingsRejected(error));
  }
}

function* updateRegistrationDomains({payload}) {
  try {
    const {status, ok, data} = yield call(
      api.put,
      'core/company/settings',
      {openRegistrationDomains: payload.domains}
    );

    if (ok && status === 200) {
      yield put(action.updateRegistrationDomainsFulfilled());
      yield put(action.getRegistrationDomains());
    } else {
      yield put(action.updateRegistrationDomainsRejected(data));
    }
  } catch (error) {
    yield put(action.updateRegistrationDomainsRejected(error));
  }
}

function* updateUserSettings({ payload }) {
  const { userSettingsDto = {}, showToast } = payload;

  try {
    const language = userSettingsDto.language || (localStorage.getItem('selectedLanguage') || LANGUAGES.EN);
    const languageIsChanged = userSettingsDto.language !== localStorage.getItem('selectedLanguage');

    const {status, ok, data} = yield call(
      api.put,
      'core/user/settings',
      { ...userSettingsDto, language },
    );

    if (ok && status === 200) {
      yield put(action.updateUserSettingsFulfilled(languageIsChanged));

      if (showToast) {
        yield put(action.showToast(
          'user_settings_upd_toast_title',
          'user_settings_upd_toast_descr',
          true,
        ));
      }

      yield put(action.changeLanguage({
        language,
        staticValuesToRefetch: ['careerLevels', 'jobFamilies']
      }));
      yield put(action.getUserSettings());
    } else {
      yield put(action.updateUserSettingsRejected(data));
    }
  } catch (error) {
    yield put(action.updateUserSettingsRejected(error));
  }
}

function* updateCompanySettings({payload}) {
  try {
    const headerItems = {};
    if (IS_ADMIN_FRONTEND && payload.companyId) {
      headerItems['x-admin-company'] = payload.companyId;
    }

    const {status, ok, data} = yield call(
      api.put,
      'core/company/settings',
      payload.companySettingsDto,
      headerItems
    );

    if (ok && status === 200) {
      yield put(action.updateCompanySettingsFulfilled());
      yield put(action.getCompanySettings(payload.companyId));
    } else {
      yield put(action.updateCompanySettingsRejected({error: data.error}));
    }
  } catch (error) {
    yield put(action.updateCompanySettingsRejected(error));
  }
}

export function* watchUpdateSettingsRequest() {
  yield takeEvery(fromActionTypes.UPDATE_SETTINGS, updateSettings);
}

export function* watchUpdateRegistrationDomains() {
  yield takeEvery(fromActionTypes.UPDATE_REGISTRATION_DOMAINS, updateRegistrationDomains);
}

export function* watchUpdateUserSettings() {
  yield takeEvery(fromActionTypes.UPDATE_USER_SETTINGS, updateUserSettings);
}

export function* watchUpdateCompanySettings() {
  yield takeEvery(fromActionTypes.UPDATE_COMPANY_SETTINGS, updateCompanySettings);
}
