export const GET_ALL_ASSESSMENTS = 'GET_ALL_ASSESSMENTS';
export const GET_ALL_ASSESSMENTS_PENDING = 'GET_ALL_ASSESSMENTS_PENDING';
export const GET_ALL_ASSESSMENTS_FULFILLED = 'GET_ALL_ASSESSMENTS_FULFILLED';
export const GET_ALL_ASSESSMENTS_REJECTED = 'GET_ALL_ASSESSMENTS_REJECTED';

export const getAllAssessments = () => {
  return {
    type: GET_ALL_ASSESSMENTS,
    payload: null
  };
};

export const getAllAssessmentsFulfilled = () => {
  return {
    type: GET_ALL_ASSESSMENTS_FULFILLED,
    payload: null
  };
};

export const getAllAssessmentsRejected = ({ error }) => {
  return {
    type: GET_ALL_ASSESSMENTS_REJECTED,
    payload: { error }
  };
};

