import React, {useCallback, useEffect, useState} from 'react';
import styles from './SkillsResultSkeleton.module.scss';


const SkillsResultSkeleton = () => {
  const [skeleton, setSkeleton] = useState();

  const getSubItems = () => {
    const subItems = [];
    for (let i=0; i<14; i++) {
      subItems.push(
        <div key={`index-${i}`} className={styles.subItemsGroup}>
          <div className={styles.subItem1}></div>
          <div className={styles.subItem2}></div>
        </div>
      );
    }
    return subItems;
  };

  const getSkeleton = useCallback(() => {
    return (
      <div id={'container-id'} className={styles.skeletonContainer}>
        <div className={styles.skeletonBlock}>
          <div className={styles.header}></div>
          <div className={styles.subItems}>{getSubItems()}</div>
        </div>
      </div>
    );
  }, []);

  const handleResize = useCallback(() => {
    setSkeleton(getSkeleton());
  }, [getSkeleton]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);


  return skeleton || getSkeleton();
};

export default SkillsResultSkeleton;
