import React, {useState} from 'react';
import styles from './TeamPotentialResult.module.scss';

import * as api from 'api';

import { useTranslate } from 'utils/translator';
import {useBreakpoint} from 'utils/hooks';

import {ImgCircle, InfoTitle, LineDiagram, Card, Modal, TeamDiagramBarsNext, Callout} from 'ui/basic';

const DEFAULT_INFO_TITLE = 'Potential';
const DEFAULT_INFO_DESCRIPTION =
  'In addition to personality, individual preferences play a particularly important role' +
  'in the question of what drives people in their thoughts and actions.' +
  'In a professional context, preferences influence our motivation to pursue certain goals' +
  'and make decisions. If our everyday working life matches our professional preferences,' +
  'we tend to feel better and are more motivated.';

const DEFAULT_DIMENSION_DESCRIPTION =
  'Selbstwirksamkeit ist die Überzeugung, dass auch schwierige Situationen und' +
  ' Herausforderungen aus eigener Kraft erfolgreich gemeistert werden können.'

const LOW = [1, 3.9]
const MEDIUM = [4, 6.9];


const TeamPotentialResult = (props) => {
  const {profile, referenceProfile, showAverage = true} = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const [showModal, setShowModal] = useState();
  const [modalHeader, setModalHeader] = useState();
  const [modalHeaderPosition, setModalHeaderPosition] = useState();
  const [modalSubHeader, setModalSubHeader] = useState();
  const [modalPreHeader, setModalPreHeader] = useState();
  const [modalContent, setModalContent] = useState();

  // METHODS
  const getDimensionsData = () => {
    if (!profile) {
      return [];
    }

    const potentialDimensions = [];

    profile.results.forEach(potentialResult => {
      let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results.find(r => r.dimensionId === potentialResult.id);
        if (thisResult) {
          range = [thisResult.minValue, thisResult.maxValue];
        }
      }

      potentialDimensions.push({
        id: potentialResult.id,
        label: potentialResult.name,
        range,
        data: potentialResult.values.map(userValue => {
          const {user, value} = userValue;

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            value
          }
        })
      });
    })

    return potentialDimensions;
  };

  const getSubHeader = (value, userName, dimensionName) => {
    if (value >= LOW[0] && value <= LOW[1]) {
      return translate('potential_dimension_low',
        ['{{dimension}}', dimensionName, '{{username}}', userName]) ||
        `Das Merkmal ${dimensionName} ist bei ${userName} gering ausgeprägt.`;
    }

    if (value >= MEDIUM[0] && value <= MEDIUM[1]) {
      return translate('potential_dimension_medium',
        ['{{dimension}}', dimensionName, '{{username}}', userName]) ||
        `Das Merkmal ${dimensionName} ist bei ${userName} mittel ausgeprägt.`;
    }

    return translate('potential_dimension_high',
      ['{{dimension}}', dimensionName, '{{username}}', userName]) ||
      `Das Merkmal ${dimensionName} ist bei ${userName} hoch ausgeprägt.`;
  };

  const getCalloutContent = (dimensionId, score) => {
    if (score >= LOW[0] && score <= LOW[1]) {
      return translate(`employee_result_low__${dimensionId}`) || `The ${dimensionId} is low`;
    }

    if (score >= MEDIUM[0] && score <= MEDIUM[1]) {
      return translate(`employee_result_medium__${dimensionId}`) || `The ${dimensionId} is medium`;
    }

    return translate(`employee_result_high__${dimensionId}`) || `The ${dimensionId} is high`;
  }

  return (
    <Card>
      <InfoTitle
        title={translate('potential_results_title') || 'Potential'}
        onClick={() => {
          setModalHeader(translate('potential_results_details_title') || DEFAULT_INFO_TITLE);
          setModalHeaderPosition('left');
          setModalSubHeader(null);
          setModalPreHeader(null);
          setModalContent(translate('potential_results_details_description') || DEFAULT_INFO_DESCRIPTION);

          setShowModal(true);
        }}
      />

      <div className={styles.diagram}>
        <TeamDiagramBarsNext
          labelAverage={translate('team_profile_average_title') || 'Teamdurchschnitt'}
          showAverage={showAverage}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: ` || 'Referenzprofil:')}
          onItemClick={(item) => {
            const userName = item.label;
            const userImage = item.img;
            const value = item.value;
            const dimensionId = item.dimensionId;
            const dimensionName = item.dimensionName;

            setModalHeader(userName);
            setModalHeaderPosition('center');
            setModalSubHeader(getSubHeader(value, userName, dimensionName));

            setModalPreHeader(
              <ImgCircle
                src={userImage}
                size={'M'}
                label1={userName.split(' ')[0]}
                label2={userName.split(' ')[1]}
              />
            );

            setModalContent(
              <div className={styles.infoModalResult}>
                <div className={styles.reportDiagram}>
                  <div className={styles.header}>
                    <span>{translate('big5_report_facet_result') || 'Ergebnis'}</span>
                    <span>{Number(value).toFixed(1)}</span>
                  </div>

                  <LineDiagram
                    score={value}
                    width={bp.isXs ? 412 : (bp.isXs ? 256 : 586)}
                  />

                  <div className={styles.footer}>
                    <span>{translate('big5_report_resut_hint1') || 'gering ausgeprägt'}</span>
                    <span>{translate('big5_report_resut_hint2') || 'stark ausgeprägt'}</span>
                  </div>
                </div>

                <div className={styles.copyDescription}>
                  {translate(`potential_results_details_description_dim${dimensionId.split('pot_')[1]}`) ||
                  DEFAULT_DIMENSION_DESCRIPTION
                  }
                </div>

                <div className={styles.callout}>
                  <Callout>
                    {getCalloutContent(dimensionId, value)}
                  </Callout>
                </div>
              </div>
            );

            setShowModal(true);
          }}
        />
      </div>

      {showModal &&
      <Modal
        header={modalHeader}
        headerPosition={modalHeaderPosition}
        subHeader={modalSubHeader}
        subHeaderPosition={'center'}
        preHeader={modalPreHeader}
        secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
        onClose={() => setShowModal(false)}
        onConfirm={() => setShowModal(false)}
      >
        {modalContent}
      </Modal>
      }
    </Card>
  )
};

export default TeamPotentialResult;
