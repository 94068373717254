// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './NestedWrapper.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: NestedWrapper
const NestedWrapper = (props) => {
  // PROPS
  const {
    children,
    autoMargins = true,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: NestedWrapper
  return (
    <div className={classNames(styles.nestedWrapper, {
      [styles.autoMargins]: autoMargins
    })}>
      { children }
    </div>
  );
};

export default NestedWrapper;
