import * as fromActionTypes from 'store/actionTypes/teamOverview';

//GET TEAMS
export const getTeams = (requestDto) => {
  return {
    type: fromActionTypes.GET_TEAMS,
    payload: {requestDto}
  };
};

export const getTeamsFulfilled = ({ teams, totalCount }) => {
  return {
    type: fromActionTypes.GET_TEAMS_FULFILLED,
    payload: { teams, totalCount }
  };
};

export const getTeamsRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_TEAMS_REJECTED,
    payload: {error}
  };
};

//GET TEAM BY ID
export const getTeam = (teamId) => {
  return {
    type: fromActionTypes.GET_TEAM,
    payload: {teamId}
  };
};

export const getTeamFulfilled = ({ team }) => {
  return {
    type: fromActionTypes.GET_TEAM_FULFILLED,
    payload: { team }
  };
};

export const getTeamRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_TEAM_REJECTED,
    payload: {error}
  };
};

export const initTeam = () => {
  return {
    type: fromActionTypes.INIT_TEAM,
    payload: {}
  };
};

// GET TEAM USERS
export const getTeamUsers = (teamId) => {
  return {
    type: fromActionTypes.GET_TEAM_USERS,
    payload: {teamId}
  };
};

export const getTeamUsersFulfilled = ({ teamUsers }) => {
  return {
    type: fromActionTypes.GET_TEAM_USERS_FULFILLED,
    payload: { teamUsers }
  };
};

export const getTeamUsersRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_TEAM_USERS_REJECTED,
    payload: {error}
  };
};

//GET TEAM TYPES
export const getTeamTypes = () => {
  return {
    type: fromActionTypes.GET_TEAM_TYPES,
    payload: {}
  };
};

export const getTeamTypesFulfilled = ({teamTypes}) => {
  return {
    type: fromActionTypes.GET_TEAM_TYPES_FULFILLED,
    payload: {teamTypes}
  };
};

export const getTeamTypesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_TEAM_TYPES_REJECTED,
    payload: { error }
  };
};

//ADD USERS TO TEAM
export const initAddUsers = () => {
  return {
    type: fromActionTypes.INIT_ADD_USERS,
    payload: {}
  };
};

export const addUsers = (addUsersDto) => {
  return {
    type: fromActionTypes.ADD_USERS,
    payload: {addUsersDto}
  };
};

export const addUsersFulfilled = () => {
  return {
    type: fromActionTypes.ADD_USERS_FULFILLED,
    payload: {}
  };
};

export const addUsersRejected = ({ error }) => {
  return {
    type: fromActionTypes.ADD_USERS_REJECTED,
    payload: { error }
  };
};

//DELETE USER FROM TEAM
export const initDeleteUser = () => {
  return {
    type: fromActionTypes.INIT_DELETE_USER,
    payload: {}
  };
};

export const deleteUser = ({teamId, userId}) => {
  return {
    type: fromActionTypes.DELETE_USER,
    payload: {teamId, userId}
  };
};

export const deleteUserFulfilled = () => {
  return {
    type: fromActionTypes.DELETE_USER_FULFILLED,
    payload: {}
  };
};

export const deleteUserRejected = ({ error }) => {
  return {
    type: fromActionTypes.DELETE_USER_REJECTED,
    payload: { error }
  };
};

//ADD ROLES TO TEAM
export const initAddPositions = () => {
  return {
    type: fromActionTypes.INIT_ADD_POSITIONS,
    payload: {}
  };
};

export const addPositions = ({teamId, roles}) => {
  return {
    type: fromActionTypes.ADD_POSITIONS,
    payload: {teamId, roles}
  };
};

export const addPositionsFulfilled = () => {
  return {
    type: fromActionTypes.ADD_POSITIONS_FULFILLED,
    payload: {}
  };
};

export const addPositionsRejected = ({ error }) => {
  return {
    type: fromActionTypes.ADD_POSITIONS_REJECTED,
    payload: { error }
  };
};

//GET POSITIONS
export const getPositions = (teamId) => {
  return {
    type: fromActionTypes.GET_POSITIONS,
    payload: {teamId}
  };
};

export const getPositionsFulfilled = ({positions}) => {
  return {
    type: fromActionTypes.GET_POSITIONS_FULFILLED,
    payload: {positions}
  };
};

export const getPositionsRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_POSITIONS_REJECTED,
    payload: { error }
  };
};

//DELETE POSITION
export const initDeletePosition = () => {
  return {
    type: fromActionTypes.INIT_DELETE_POSITION,
    payload: {}
  };
};

export const deletePosition = ({deletePositionDto}) => {
  return {
    type: fromActionTypes.DELETE_POSITION,
    payload: {deletePositionDto}
  };
};

export const deletePositionFulfilled = () => {
  return {
    type: fromActionTypes.DELETE_POSITION_FULFILLED,
    payload: {}
  };
};

export const deletePositionRejected = ({ error }) => {
  return {
    type: fromActionTypes.DELETE_POSITION_REJECTED,
    payload: { error }
  };
};

//FILL POSITION
export const initFillPosition = () => {
  return {
    type: fromActionTypes.INIT_FILL_POSITION,
    payload: {}
  };
};

export const fillPosition = ({fillPositionDto}) => {
  return {
    type: fromActionTypes.FILL_POSITION,
    payload: {fillPositionDto}
  };
};

export const fillPositionFulfilled = () => {
  return {
    type: fromActionTypes.FILL_POSITION_FULFILLED,
    payload: {}
  };
};

export const fillPositionRejected = ({ error }) => {
  return {
    type: fromActionTypes.FILL_POSITION_REJECTED,
    payload: { error }
  };
};

//GET ASSESSMENT RESULTS FOR TEAM MEMBERS
export const getAssessmentResults = () => {
  return {
    type: fromActionTypes.GET_ASSESSMENT_RESULTS,
    payload: {}
  };
};

export const getAssessmentResultsFulfilled = ({assessmentResults}) => {
  return {
    type: fromActionTypes.GET_ASSESSMENT_RESULTS_FULFILLED,
    payload: {assessmentResults}
  };
};

export const getAssessmentResultsRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_ASSESSMENT_RESULTS_REJECTED,
    payload: { error }
  };
};

//GET TEAM PROFILES
export const initTeamProfiles = () => {
  return {
    type: fromActionTypes.INIT_TEAM_PROFILES,
    payload: {}
  };
};

export const getTeamProfiles = (teamId) => {
  return {
    type: fromActionTypes.GET_TEAM_PROFILES,
    payload: {teamId}
  };
};

export const getTeamProfilesFulfilled = ({teamProfiles}) => {
  return {
    type: fromActionTypes.GET_TEAM_PROFILES_FULFILLED,
    payload: {teamProfiles}
  };
};

export const getTeamProfilesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_TEAM_PROFILES_REJECTED,
    payload: { error }
  };
};

// UPDATE TEAM
export const updateTeam = (updateTeamDto) => {
  return {
    type: fromActionTypes.UPDATE_TEAM,
    payload: {updateTeamDto}
  };
};

export const updateTeamFulfilled = () => {
  return {
    type: fromActionTypes.UPDATE_TEAM_FULFILLED,
    payload: {}
  };
};

export const updateTeamRejected = ({ error }) => {
  return {
    type: fromActionTypes.UPDATE_TEAM_REJECTED,
    payload: { error }
  };
};

// UPDATE USER ROLE
export const initUpdateUserRole = () => {
  return {
    type: fromActionTypes.INIT_UPDATE_USER_ROLE,
    payload: {}
  };
};

export const updateUserRole = (updateUserRoleDto) => {
  return {
    type: fromActionTypes.UPDATE_USER_ROLE,
    payload: {updateUserRoleDto}
  };
};

export const updateUserRoleFulfilled = ({updatedUserRole}) => {
  return {
    type: fromActionTypes.UPDATE_USER_ROLE_FULFILLED,
    payload: {updatedUserRole}
  };
};

export const updateUserRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.UPDATE_USER_ROLE_REJECTED,
    payload: { error }
  };
};
