import * as fromActionTypes from 'store/actionTypes/signUp';

export const initSignUp = () => {
  return {
    type: fromActionTypes.INIT_SIGN_UP,
    payload: { }
  };
};

export const signUp = (signUpDto) => {
  return {
    type: fromActionTypes.SIGN_UP,
    payload: { signUpDto }
  };
};

export const signUpFulFilled = (payload) => {
  return {
    type: fromActionTypes.SIGN_UP_FULFILLED,
    payload: payload
  };
};

export const signUpRejected = ({ error }) => {
  return {
    type: fromActionTypes.SIGN_UP_REJECTED,
    payload: { error }
  };
};

export const sendSignUpEmail = () => {
  return {
    type: fromActionTypes.SEND_SIGN_UP_EMAIL,
    payload: {}
  };
};

export const sendSignUpEmailFulfilled = () => {
  return {
    type: fromActionTypes.SEND_SIGN_UP_EMAIL_FULFILLED,
    payload: {}
  };
};

export const sendSignUpEmailRejected = ({ error }) => {
  return {
    type: fromActionTypes.SEND_SIGN_UP_EMAIL_REJECTED,
    payload: { error }
  };
};
