/* eslint-disable no-useless-escape */ // needed for some of the markdown espaces
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Legal.module.scss';

import {useHistory, useParams} from 'react-router';

import { Icons } from 'assets/icons';

import { useTranslate } from 'utils/translator';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';
import {markdown} from 'utils/textTools';

import { Icon } from 'ui/basic/micro-ui/Icon';

const example =
`[blu-markdown]

# Bedingungen für die Nutzung von bluquist.

[blu-table-of-contents](Inhaltsübersicht)

## 1. Einleitung
### Vertrag

Sie erklären sich damit einverstanden, dass Sie mit bluquist einen rechtsverbindlichen Vertrag abschließen (auch bei Nutzung unserer Dienste im Namen eines Unternehmens), wenn Sie sich durch Klicken auf „Jetzt anmelden“, „Mitglied von bluquist werden“, „Registrieren“ oder ähnliches bei bluquist registrieren, auf unsere Dienste zugreifen oder diese (wie unten beschrieben) nutzen. Stimmen Sie diesem Vertrag („Vertrag“ oder „Nutzervereinbarung“) nicht zu, sollten Sie nicht auf „Jetzt Mitglied werden“ (oder ähnliches) klicken und auf keinerlei Weise auf unsere Dienste zugreifen. Wenn Sie diesen Vertrag kündigen möchten, können Sie dies jederzeit durch das Löschen Ihres Kontos tun und indem Sie nicht mehr auf unsere Dienste zugreifen bzw. diese nicht mehr nutzen.

### bluquist – part of BLUE EXCELLENCE - The Human Potential Company

Sie schließen diesen Vertrag mit der Blue Excellence GmbH (haftungsbeschränkt) ab. Sie ist verantwortlichen für Ihre personenbezogenen Daten, die unseren Diensten bereitgestellt, für oder durch diese erfasst oder im Zusammenhang mit ihnen verarbeitet werden.

### Dienste

Dieser Vertrag gilt für bluquist.com, die bluquist Human Potential Plattform, Apps und andere mit bluquist verbundene Websites, Apps, Mitteilungen und sonstige Dienste, die ausdrücklich unter diesem Vertrag angeboten werden. Registrierte Nutzer unserer Dienste sind „Mitglieder“, nicht registrierte Nutzer sind „Besucher“.

### Mitglieder und Besucher

Wenn Sie sich für die Dienste von bluquist registrieren und bluquist beitreten, werden Sie Mitglied. Wenn Sie sich nicht für unsere Dienste registrieren, können Sie auf bestimmte Funktionen als „Besucher“ zugreifen.

Dieser Vertrag ist wirksam für Mitglieder und Besucher. Als Mitglied unserer Dienste unterliegt die Erfassung, Nutzung und Weitergabe Ihrer personenbezogenen Daten der Datenschutzrichtlinie (#TZ muss um die Services erweitert werden). Für Besucher werden keine personenbezogenen Daten erfasst.

### Änderungen

Wir haben das Recht, diesen Vertrag sowie unsere Datenschutzrichtlinie zu ändern. Sobald wir wesentliche Änderungen vornehmen, benachrichtigen wir Sie über unsere Dienste oder auf andere Art und Weise, damit Sie Gelegenheit haben, die Änderungen zu überprüfen, bevor sie in Kraft treten. Wir akzeptieren, dass Änderungen nicht rückwirkend vorgenommen werden können. Wenn Sie mit irgendwelchen Änderungen nicht einverstanden sind, können Sie Ihr Konto löschen. Nachdem wir unsere Änderungen dieser Bedingungen veröffentlicht oder Sie über solche Änderungen benachrichtigt haben, und Sie weiterhin unsere Dienste Ihrerseits nutzen, bedeutet, dass Sie sich mit den aktualisierten Bedingungen ab dem Datum ihres Inkrafttretens einverstanden erklären.

## 2. Pflichten

### Zugang

Um die Dienste nutzen zu können, stimmen Sie zu nur ein Konto auf bluquist zu erstellen und zu verwenden, das Sie mit ihrem echten Namen erstellt haben. Das Erstellen eines Kontos unter Angabe falscher Informationen stellt einen Verstoß gegen unsere Bedingungen dar.

### Konto

Mitglieder sind Kontobesitzer und erklären sich dadurch einverstanden ein sicheres Passwort zu wählen, dieses geheim und vertraulich zu halten sowie keinen Teil Ihres Kontos an dritte (z.B. Kontakte) zu übertragen. Sie tragen die Verantwortung für sämtliche über Ihr Benutzerkonto vorgenommenen Aktionen, es sei denn, Sie haben das Konto geschlossen oder einen Missbrauch gemeldet.

In Bezug auf das Verhältnis zwischen Ihnen und anderen Parteien (einschließlich Ihres Arbeitgebers) gilt, dass Ihr Konto Ihnen gehört. Wurden die Dienste aber von einer anderen Partei zu Ihrer Nutzung erworben (beispielsweise eine von Ihrem Arbeitgeber gekaufte Lizenz), hat die für die Dienste zahlende Partei das Recht, den Zugriff auf die Dienste sowie Ihre Nutzung solcher bezahlten Dienste zu kontrollieren, verfügt jedoch über keinerlei Rechte im Zusammenhang mit Ihrem persönlichen Konto.

### Zahlung

Wenn Sie unsere gebührenpflichtigen Dienste („Premium-Dienste“) erwerben, erklären Sie sich einverstanden, uns die entsprechenden Gebühren und Steuern zu zahlen. Bei Nichtzahlung dieser Gebühren werden Ihre gebührenpflichtigen Dienste beendet. Des Weiteren erklären Sie sich mit Folgendem einverstanden:

Ihr Kauf unterliegt möglicherweise Devisengebühren oder standortbedingten oder von Devisenkursen abhängigen Preisunterschieden.

Wir können Ihre Zahlungsmethode (z. B. Kreditkarte) speichern und belasten, um eine Unterbrechung unserer Dienste zu vermeiden und um sie zur Bezahlung anderer Dienste zu verwenden, die Sie möglicherweise erwerben.

Wenn Sie eine Mitgliedschaft kaufen, wird Ihre Zahlungsmethode jeweils bei Beginn einer Mitgliedschaftsperiode automatisch mit den für diese Periode anfallenden Gebühren und Steuern belastet. Kündigen Sie bitte vor dem Verlängerungsdatum, um künftige Belastungen zu vermeiden. Informieren Sie sich darüber, wie Sie Ihre Premium-Dienste kündigen oder aussetzen können. 

Wir berechnen die von Ihnen zu zahlenden Steuern auf Basis der Rechnungsdaten, die Sie uns zum Zeitpunkt des Kaufs zur Verfügung stellen.

Eine Kopie Ihrer Rechnung erhalten Sie über Ihre Kontoeinstellungen auf bluquist oder per E-Mail.

### Mitteilungen und Nachrichten

Sie erklären sich einverstanden, dass wir Ihnen innerhalb des Dienstes oder an die von Ihnen bereitgestellten Kontaktdaten (z. B. E-Mail-Adresse, Handy-Nummer, Postanschrift) Mitteilungen und Nachrichten zukommen lassen. Sie erklären sich einverstanden, Ihre Kontaktdaten auf dem aktuellen Stand zu halten.

Bitte überprüfen Sie Ihre Einstellungen, um die Nachrichten, die Sie von uns erhalten, zu steuern und zu begrenzen.

### Teilen

Unsere Dienste ermöglichen es Ihnen, Nachrichten und Informationen zu teilen, beispielsweise über Ihr Profil oder innerhalb der Dienste. Informationen und Inhalte, die Sie teilen können von anderen Mitgliedern, Besuchern oder Dritten gesehen werden (auch außerhalb der Dienste). Wir respektieren die von Ihnen getroffenen Entscheidungen darüber, wer welche Inhalte oder Informationen sehen darf (z. B. die Sichtbarkeit Ihres Profils für ihre Teamkollegen oder Ihre Organisation).

Wir sind nicht verpflichtet, Informationen oder Inhalte auf unserem Dienst zu veröffentlichen, und können diese mit oder ohne vorherige Benachrichtigung entfernen.

## 3. Rechte und Einschränkungen
### bluquist Lizenz

Im Vertragsverhältnis zwischen Ihnen und bluquist sind Sie der alleinige Eigentümer aller Informationen und Daten, die Sie an die Dienste übermitteln und durch deren Nutzung eingeben und/oder zur Verfügung stellen oder dort veröffentlichen. Sie gewähren bluquist und unseren Partnern die folgende nicht ausschließliche Lizenz: Das weltweite, übertragbare, unbefristete und unterlizenzierbare Recht, Informationen und Inhalte, die Sie über unsere Dienste und Dienste von Dritten bereitstellen, ohne weitere Zustimmung Ihrerseits oder Mitteilung und/oder Entschädigungszahlung an Sie oder Dritte zu nutzen, zu kopieren, zu modifizieren, zu verteilen, zu veröffentlichen und zu verarbeiten, abgeleitete Werke davon zu erstellen und anderweitig zu verwenden. Diese Rechte sind wie folgt eingeschränkt:

Sie können diese Lizenz für spezifische Inhalte beenden, indem Sie derartige Inhalte aus den Diensten löschen oder Ihr Konto schließen, wobei zu berücksichtigen ist, dass (a) andere Personen die auf den Diensten geteilten Inhalte möglicherweise kopiert, ihrerseits geteilt oder gespeichert haben, und (b) dass wir eine angemessene Frist haben, diese Inhalte aus Sicherungs- und sonstigen Systemen zu entfernen.

Wir veröffentlichen Ihre Inhalte (einschließlich Sponsored Content) nicht in Anzeigen für die Produkte und Dienste Dritter, die sich an andere Personen richten, ohne dafür Ihre diesbezügliche separate Einwilligung erhalten zu haben. Wir behalten uns jedoch das Recht vor, ohne Entschädigung an Sie oder Dritte, neben Ihren Inhalte und Informationen Anzeigen zu schalten.

Wir holen Ihre Zustimmung ein, wenn wir anderen Personen das Recht erteilen möchten, Ihre Inhalte außerhalb unserer Dienste zu veröffentlichen.

Obwohl wir Ihre Inhalte möglicherweise bearbeiten und Änderungen an der Formatierung vornehmen (indem wir beispielsweise Übersetzungen oder Transkriptionen anfertigen, Größe, Layout oder Dateityp ändern oder Metadaten entfernen), ändern wir den Sinngehalt Ihrer Beiträge nicht.

Da Sie Eigentümer Ihrer Inhalte und Informationen sind und wir nur nicht-ausschließliche Rechte daran haben, können Sie diese Inhalte und Informationen anderen zur Verfügung stellen, beispielsweise unter einer Creative-Commons-Lizenz.

Sie erklären sich damit einverstanden, dass bluquist auf alle Informationen und personenbezogenen Daten, die Sie in Übereinstimmung mit den Bedingungen der Datenschutzrichtlinie und Ihren Optionen (einschließlich Einstellungen) bereitstellen, zugreifen und diese verwenden und speichern darf.

Indem Sie Vorschläge oder sonstiges Feedback bezüglich unserer Dienste an bluquist senden, erklären Sie sich damit einverstanden, dass bluquist dieses Feedback ohne Entschädigung an Sie zu beliebigen Zwecken nutzen und teilen kann (aber nicht dazu verpflichtet ist).

Sie erklären sich damit einverstanden, nur Inhalte und Informationen bereitzustellen, die nicht gegen das Gesetz verstoßen oder die Rechte (einschließlich geistiger Eigentumsrechte) anderer Personen verletzen. Sie stimmen auch zu, dass Ihre Profildaten der Wahrheit entsprechen. bluquist kann gesetzlich dazu verpflichtet sein, in bestimmten Ländern gewisse Informationen oder Inhalte zu entfernen.

### Einschränkungen

bluquist behält sich das Recht vor, Ihre Nutzung der Dienste, einschließlich der Anzahl der Assessments oder Kontakte und der Fähigkeit, mit anderen Mitgliedern Kontakt aufzunehmen, einzuschränken. LinkedIn behält sich das Recht vor, Ihr Konto einzuschränken, auszusetzen oder zu löschen, wenn Sie gegen diesen Vertrag oder Gesetze verstoßen oder die Dienste missbrauchen.

## 4. Haftungsausschluss und Haftungsbeschränkung

### Gewährleistungsausschluss

bluquist und seine verbundenen Unternehmen übernehmen keinerlei Gewähr oder Garantie hinsichtlich der Dienste und garantieren nicht, dass die Dienste unterbrechungsfrei oder fehlerfrei sein werden. Die Dienste (einschließlich Inhalte und Informationen) werden ohne Mängelgewähr und wie verfügbar bereitgestellt. Im höchsten gesetzlich zulässigen Ausmaß schließen bluquist und seine verbundenen Unternehmen (Partner) jedwede stillschweigende oder gesetzliche Gewährleistung, einschließlich stillschweigender Garantien in Bezug auf Rechtsmängelhaftung, Fehlerfreiheit von Daten, Nichtverletzung von Rechten Dritter, Marktgängigkeit oder Eignung für einen bestimmten Zweck aus.

## 5. Beendigung

Sowohl Sie als auch bluquist können diesen Vertrag jederzeit durch Benachrichtigung der anderen Partei beenden. Bei Beendigung verlieren Sie das Recht, auf die Dienste zuzugreifen oder sie zu nutzen.

### Nach Beendigung gilt weiterhin Folgendes:

Wir sind berechtigt, Ihr Feedback zu nutzen und offenzulegen.
Mitglieder und/oder Besucher sind berechtigt, Inhalte und Informationen, die Sie über die Dienste geteilt haben, weiter zu teilen.
Abschnitte 4 (Haftungsausschluss und Haftungsbeschränkung), 6 (Anwendbares Recht und Streitschlichtung), 7 (Allgemeine Bedingungen) und 8 (Verhaltensregeln) dieses Vertrags.
Alle Beträge, die eine der Parteien vor der Beendigung der anderen schuldet, sind nach Beendigung weiterhin schuldig.

- Wir sind berechtigt, Ihr Feedback zu nutzen und offenzulegen.

## 6. Anwendbares Recht und Streitschlichtung

Sie und bluquist vereinbaren, dass die Gesetze von Deutschland für alle Streitfälle bezüglich dieses Vertrags und/oder der Dienste gelten. Sie und bluquist vereinbaren, dass Ansprüche und Streitfälle nur in Mannheim, Deutschland, im Prozesswege verfolgt werden können, und beide Parteien stimmen der personenbezogenen Zuständigkeit der Gerichte von Mannheim, Deutschland, zu.

## 7. Allgemeine Bedingungen

Befindet ein Gericht mit Amtsbefugnis über diesen Vertrag, dass ein Teil des Vertrags nicht durchsetzbar ist, geben wir und Sie dem Gericht unser Einverständnis, die Bedingungen so zu ändern, dass der betreffende Teil durchsetzbar ist, wobei dessen Absicht unverändert bleibt. Ist dies dem Gericht nicht möglich, erklären sich beide Parteien einverstanden, das Gericht aufzufordern, den undurchsetzbaren Teil zu entfernen und den Rest dieses Vertrags weiterhin durchzusetzen.

Dieser Vertrag (einschließlich zusätzlicher Bedingungen, über die wir Sie jeweils dann informieren, wenn Sie bestimmte Funktionen der Dienste verwenden), ist die einzige Vereinbarung zwischen uns bezüglich der Dienste und ersetzt alle vorherigen Vereinbarungen zu den Diensten.

Wenn wir nichts unternehmen, um einem Verstoß gegen diesen Vertrag entgegenzuwirken, bedeutet das nicht, dass bluquist sein Recht auf die Durchsetzung dieses Vertrags aufgegeben hat. Es ist Ihnen nicht gestattet, diesen Vertrag (oder Ihre Mitgliedschaft oder die Nutzung der Dienste) ohne unsere Genehmigung an irgendwelche Personen abzutreten oder zu übertragen. Sie bestätigen jedoch, dass bluquist diesen Vertrag an seine verbundenen Unternehmen oder an eine Partei, die das Unternehmen aufkauft, ohne Ihre Genehmigung abtreten kann. In diesem Vertrag gibt es keinerlei Drittbegünstigte.

Sie bestätigen, dass Sie rechtliche Benachrichtigungen an uns nur an die unter Abschnitt 10 genannten Adressen senden werden.

## 8. Verhaltensregeln

### Wenn Sie auf die bluquist Dienste zugreifen und sie nutzen, erklären Sie sich damit einverstanden, dass Sie:

sich alle anwendbaren Gesetze einhalten, einschließlich, und ohne Einschränkung, Datenschutzgesetze, geistige Eigentumsgesetze, Anti-Spam-Gesetze, Ausfuhrbestimmungen, Steuergesetze und aufsichtsrechtliche Anforderungen;

uns korrekte Informationen liefern und dafür sorgen, dass sich diese immer auf dem aktuellen Stand befinden;
in Ihrem Profil Ihren richtigen Namen verwenden;
die Dienste auf professionelle Art und Weise nutzen.

### Wenn Sie auf die bluquist Dienste zugreifen und sie nutzen, erklären Sie sich damit einverstanden, dass Sie NICHT:

- unrechtmäßig oder unprofessionell handeln;
ein Konto unter Verwendung einer falschen Identität oder der Identität einer anderen Person erstellen;
- bei der Eingabe Ihrer Profilinformationen persönliche, bildungsbezogene oder berufliche Informationen falsch darstellen;
falsche oder ungenaue Antworten geben, wenn Sie auf eine Frage bezüglich privater Informationen antworten;
- mehrere Konten erstellen;
- das Konto einer anderen Person benutzen oder versuchen, es zu benutzen;
- Daten, die Sie unter Verletzung dieses Vertrags erhalten haben, zu verwenden, offenzulegen oder zu verteilen;
das geistige Eigentum oder andere Rechte von bluquist zu verletzen, einschließlich, ohne Einschränkung:  (i) das Kopieren oder Verteilen der Software oder Technologie im Zusammenhang mit unserer Website; und (ii) die Verwendung des Wortes " bluquist                    " oder unserer Logos in einem Firmennamen, einer E-Mail oder einer URL;
Modifizieren, Anpassen, Übersetzen, Zurückentwickeln, Dekompilieren, Disassemblieren, Entziffern oder anderweitiger Versuch, den Quellcode der Website oder einer damit verbundenen Technologie und Software abzuleiten;
den Zugriff auf die Seite oder ihre Dienstleistungen zu verkaufen, zu sponsern oder anderweitig zu monetarisieren;
ein Konto zu verkaufen, zu tauschen, weiterzuverkaufen, zu übertragen oder anderweitig für unbefugte kommerzielle Zwecke zu nutzen;
Bots oder andere automatisierte Methoden für den Zugriff auf die Website verwenden;
die Verfügbarkeit, Leistung oder Funktionalität der Website für jegliche Wettbewerbszwecke zu überwachen;
sich mit "Framing", "Mirroring" oder einer anderen Simulation des Erscheinungsbildes oder der Funktion der Website beschäftigen;
die Seite oder ihr Erscheinungsbild zu überlagern oder anderweitig zu verändern;
Entfernen von Urheberrechts-, Marken- oder anderen Eigentumshinweisen, die in oder auf der Seite enthalten sind;
Funktionen oder Dienste der Seite für andere Zwecke als den ursprünglich vorgesehenen zu nutzen;
jegliche Sicherheitsmerkmale der Seite außer Kraft setzen oder versuchen, diese außer Kraft zu setzen;
sich an Aktivitäten zu beteiligen, die die Seite (oder die mit der Seite verbundenen Server und Netzwerke) stören oder unterbrechen;
Viren, Würmer, Defekte, Trojanische Pferde, beschädigte Dateien, Hoaxes oder andere schädliche Codes oder Elemente zerstörerischer oder betrügerischer Natur auf der Seite zu verbreiten;
ein automatisiertes System oder eine Software zu verwenden, unabhängig davon, ob sie von einer dritten Partei oder anderweitig betrieben wird, um Daten von der Website zu extrahieren (z.B. Screen Scraping oder Crawling);
den Betrieb der Seite zu stören oder die Seite unangemessen zu belasten;
und Umgehung aller Einschränkungen der Website oder Aussetzungen der Funktionalität.
`;

const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_'
};

const Legal = (props) => {
  // PROPS
  let { propSubPage, onClose } = props;

  let { routerSubPage } = useParams();
  const subpage = propSubPage || routerSubPage;

  const translate = useTranslate();

  // STATE
  const [ anchorClicks, setAnchorClicks ] = useState(1);

  // HOOKS
  const history = useHistory();

  let mdContent;
  let titleKey;

  switch(subpage) {
    case 'provider': {
      mdContent = translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}legal_provider`);
      titleKey = 'foo_item_provider';
      document.title = `bluquist - ${translate('foo_item_provider')}`;
      break;
    }
    case 'data-protection-privacy': {
      mdContent = translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}legal_data_protection_privacy`);
      titleKey = 'main_navigation_item_sec_2';
      document.title = `bluquist - ${translate('main_navigation_item_sec_2')}`;
      break;
    }
    case 'terms-of-use': {
      mdContent = translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}legal_terms_of_use`);
      titleKey = 'legal_terms_of_use_title';
      document.title = `bluquist - ${translate('legal_terms_of_use_title')}`;
      break;
    }
    case 'privacy-agreement': {
      mdContent = translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}legal_privacy_agreement`);
      titleKey = 'legal_privacy_agreement_title';
      document.title = `bluquist - ${translate('legal_privacy_agreement_title')}`;
      break;
    }
    default: {
      titleKey = 'main_navigation_item_sec_2';
      mdContent = translate(example) || markdown(example);
      document.title = `bluquist - ${translate('main_navigation_item_sec_2')}`;
    }
  }

  const handleContentClick = () => {
    setAnchorClicks(anchorClicks + 1);
  };

  return createPortal(
    <div className={styles.legal}>
      <div className={styles.header}>
        <span>{translate(titleKey)}</span>
        <div
          className={styles.close}
          onClick={() => {
            onClose ? onClose() : history.go(-anchorClicks);
          }}
        >
          <Icon icon={Icons.CloseBig}/>
        </div>
      </div>
      <div
        onClick={handleContentClick}
        className={styles.contentGrid}
      >
        <div className={styles.content}>
          { mdContent }
        </div>
      </div>
    </div>,
    document.body,
  )
}

export default Legal;
