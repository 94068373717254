// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './NonexistentInstance.module.scss';

// OTHER COMPONENTS
// import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// COMPONENT: NonexistentInstance
const NonexistentInstance = () => {
  
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: NonexistentInstance
  return (
    <div className={styles.nonexistentInstance}>
      <div className={styles.container}>
        <div className={styles.title}>
          { translate('nonexistent_instance_title') } 
        </div>
        <div>
          { translate('nonexistent_instance_description_1') }
        </div>
        {/* Restore after merge with BQP-775
        <div>
          { translate('nonexistent_instance_description_2') } 
        </div>
        <div className={styles.button}>
          <Button
            onClick={() => {
              window.location.href = 'https://app.bluquist.net/login';
            }}
          >
            { translate('nonexistent_instance_btn') }
          </Button>
        </div>
        */}
      </div>
    </div>
  );
};

export default NonexistentInstance;
