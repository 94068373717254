import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './Big5Report.module.scss';

import {useSelector} from 'react-redux';

import {Icons} from 'assets/icons';
import {ReactComponent as AnchorImage} from 'assets/icons/icn_arrow_anchor.svg';

import {Callout, Icon, LineDiagram} from 'ui/basic';

// UTILS
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { numberWithForcedDecimals } from 'utils/numberFormatting';


const DEFAULT_DIMENSION_DESCRIPTION =
  'Das Merkmal Verträglichkeit beschreibt den Stellenwert,' +
  'den eine Person altruistisches Verhalten zuschreibt. ' +
  'Zu den Kernbereichen dieses Merkmals zählen außerdem Vertrauen und Kompromissbereitschaft.';

const DEFAULT_FACET_DESCRIPTION =
  'Subjektive Überzeugung von positiven Handlungsabsichten anderer Menschen';

const DEFAULT_CALLOUT_VERY_LOW =
  'Personen mit einem sehr niedrigen Maß an "Verträglichkeit" sind sehr bestrebt danach' +
  'ihre eigene Meinung durchzusetzen, auch wenn dies bedeutet sich auf längere Diskussionen' +
  'oder Streitigkeiten einlassen zu müssen. Dadurch wirken sie auf andere oft unnachgiebig' +
  'und kompromisslos. Für Positionen, in denen oft verhandelt werden muss,' +
  'kann dieses Verhalten von Vorteil sein. Zudem sind für diese Menschen Tätigkeitsbereiche sinnvoll,' +
  'in denen sie selbst entscheiden können und kaum Teamarbeit gefragt ist.';

const DEFAULT_CALLOUT_LOW =
  'Personen mit einem niedrigen Maß an "Verträglichkeit" scheuen selten Diskussionen' +
  'und Konflikte wenn es darum geht die eigene Meinung durchzusetzen.' +
  ' Um sich von anderen Standpunkten überzeugen zu lassen, bedarf es einer rationalen Argumentation.' +
  ' Das Arbeiten im Team fällt ihnen schwerer und sie arbeiten lieber unabhängig.' +
  'Solange die Teamziele mit den eigenen übereinstimmen, sind sie aber durchaus bereit im Team mitzuarbeiten.';

const DEFAULT_CALLOUT_MEDIUM =
  'Personen mit einem mittleren Maß an "Verträglichkeit" agieren sowohl kompromissbereit und' +
  ' kooperativ als auch durchsetzungsfähig, wenn es darum geht ihre eigene Meinung zu verdeutlichen.' +
  ' Es fällt ihnen nicht schwer sich in ein Team einzufügen, da sie eigene' +
  ' Ziele verfolgen aber trotzdem dem Team entgegenkommen. Um ihre Fähigkeiten optimal entfalten zu können,' +
  'werden diese Personen am besten in Positionen eingesetzt,' +
  'die Teamarbeit und eigenverantwortliches Handeln gleichermaßen erfordern.';

const DEFAULT_CALLOUT_HIGH =
  'Personen mit einem hohen Maß an "Verträglichkeit" neigen  zu Nachgiebigkeit und' +
  'starker Anpassungsfähigkeit. Sie fühlen sich sehr wohl innerhalb von Teamaktivitäten' +
  'und agieren sehr hilfsbereit und kooperativ. Die persönlichen Bedürfnisse werden' +
  'in Gruppenaktivitäten häufig denen der Gruppe untergeordnet und angepasst.' +
  'Da sie Konfrontationen gerne aus dem Weg gehen, kann es leicht passieren,' +
  'dass sie ihre eigenen Ziele nicht durchsetzen können.';

const DEFAULT_CALLOUT_VERY_HIGH =
  'Personen mit einem sehr hohen Maß an "Verträglichkeit" haben in der Regel ein stark' +
  'ausgeprägtes Harmoniebedürfnis und versuchen jegliche Konfrontation zu meiden' +
  'auch wenn dafür die eigenen Bedürfnisse und Interessen nicht zu tragen kommen.' +
  'Zudem zeichnen sie sich durch ihre meist sehr hohe Hilfsbereitschaft und' +
  ' Anpassungsfähigkeit aus. Sie arbeiten sehr gerne in Gruppen und unterstützen' +
  'ihre Teammitglieder tatkräftig. Tätigkeiten, in denen eine hohe Durchsetzungsfähigkeit' +
  'und ein gewisses Konfliktpotenzial vorhanden ist, sind eher ungeeignet für diese Personen.';


const Big5Report = (props) => {
  const {dimensionsResults, facetsResults, anchor, onClose} = props;
  const ref = useRef();
  const [width, setWidth] = useState();
  const [myAnchor, setMyAnchor] = useState(anchor);

  const translate = useTranslate();
  const bp = useBreakpoint();

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, []);

  const big5ResultSchema = useSelector(
    state => state.assessments && state.assessments[ASSESSMENT_TYPES.BIG5] && state.assessments[ASSESSMENT_TYPES.BIG5].resultSchema
  );

  // const getWidth = () => {
  //
  // };

  const scrollDown = useCallback(() => {
    const offsetTop = document.getElementById(myAnchor).offsetTop;
    document.getElementById('body')
      .scrollTo({
        top: offsetTop - 100,
        behavior: 'smooth'
      });
  }, [myAnchor]);

  const getCallout = (dimensionIndex, dimension) => {
    const result = dimensionsResults[dimensionIndex];
    if (result >= 1 && result <= 2) {
      return translate(`big5_report_dim_${dimension.id}_callout_very_low`) || DEFAULT_CALLOUT_VERY_LOW;
    }
    if (result > 2 && result <= 4) {
      return translate(`big5_report_dim_${dimension.id}_callout_low`) || DEFAULT_CALLOUT_LOW;
    }
    if (result > 4 && result <= 6) {
      return translate(`big5_report_dim_${dimension.id}_callout_medium`) || DEFAULT_CALLOUT_MEDIUM;
    }
    if (result > 6 && result <= 8) {
      return translate(`big5_report_dim_${dimension.id}_callout_high`) || DEFAULT_CALLOUT_HIGH;
    }
    return translate(`big5_report_dim_${dimension.id}_callout_very_high`) || DEFAULT_CALLOUT_VERY_HIGH;
  };

  const handleResize = useCallback(() => {
    let width = 278;

    const container = ref.current;
    if (bp.isXs) {
      if (!container) {
        width = 278;
      }
      width = window.innerWidth - 32;
    }

    if (bp.isS) {
      if (!container) {
        width = 476;
      }
      width = container.clientWidth;
    }

    if (bp.isM || bp.isL || bp.isXl) {
      if (!container) {
        width = 640;
      }
      width = container.clientWidth;
    }

    setWidth(width);
  }, [bp]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  useEffect(() => {
    if (myAnchor) {
      scrollDown();
    }
  }, [myAnchor, scrollDown]);

  return (
    <div className={styles.big5Report}>
      <div className={styles.header}>
        <span>{translate('big5_report_header_title') || 'BIG 5 - Report'}</span>
        <div
          className={styles.close}
          onClick={onClose}
        >
          <Icon icon={Icons.CloseBig}/>
        </div>
      </div>
      <div className={styles.bodyContainer} id='body'>
        <div className={styles.body}>
          <div className={styles.main} ref={ref}>
            <div className={styles.title}>{translate('big5_report_title') || 'BIG 5 Report'}</div>
            <div className={styles.contents}>
              {big5ResultSchema.map((dimension, index) => (
                  <span
                    key={`dimension-content-${index}`}
                    onClick={() => setMyAnchor(dimension.name.replace(/\s/g, '-').toLowerCase())}
                    className={styles.content}
                  >
                    {dimension.name || translate(`big5_report_dim_${dimension.id}`)}
                    <AnchorImage/>
                  </span>
                )
              )}
            </div>
            {big5ResultSchema.map((dimension, dimensionIndex) => {
              return (
                <div className={styles.dimension} key={`dimension-${dimensionIndex}`}>
                  <div className={styles.title} id={dimension.name.replace(/\s/g, '-').toLowerCase()}>
                    {translate(`big5_report_dim_${dimension.id}`) || dimension.name}
                  </div>
                  <div className={styles.description}>
                    {translate(`big5_report_dim_${dimension.id}_description`) || DEFAULT_DIMENSION_DESCRIPTION}
                  </div>
                  <div className={styles.score}>
                    <div className={styles.row1}>
                      <span className={styles.text}>
                        {translate('big5_report_dimension_result') || 'Gesamtergebnis'}
                      </span>
                      <span className={styles.number}>
                        { numberWithForcedDecimals(dimensionsResults[dimensionIndex]) }
                      </span>
                    </div>
                    <LineDiagram score={dimensionsResults[dimensionIndex]} width={width}/>
                    <div className={styles.row2}>
                      <span>{translate('big5_report_resut_hint1') || 'Gering ausgeprägt'}</span>
                      <span>{translate('big5_report_resut_hint2') || 'Stark ausgeprägt'}</span>
                    </div>
                  </div>

                  <div className={styles.callOutContainer}>
                    <Callout>
                      {getCallout(dimensionIndex, dimension)}
                    </Callout>
                  </div>

                  <div className={styles.facets}>
                    <div className={styles.title}>
                      {translate(`big5_report_dim_${dimension.id}_facets`) || `Die Facetten von ${dimension.name}`}
                    </div>
                    {dimension.subDimensions.map((facet, facetIndex) => {
                      return (
                        <div className={styles.facet} key={`facet-${facetIndex}`}>
                          <div className={styles.title}>
                            {translate(`big5_report_dim_${dimension.id}_facet_${facet.id}`) || facet.name}
                          </div>
                          <div className={styles.description}>
                            {translate(`big5_report_dim_${dimension.id}_facet_${facet.id}_description`) || DEFAULT_FACET_DESCRIPTION}
                          </div>
                          <div className={styles.score}>
                            <div className={styles.row1}>
                              <span className={styles.text}>{translate('big5_report_facet_result') || 'Ergebnis'}</span>
                              <span className={styles.number}>{ numberWithForcedDecimals(facetsResults[dimensionIndex][facetIndex]) }</span>
                            </div>
                            <LineDiagram score={facetsResults[dimensionIndex][facetIndex]} width={width}/>
                            <div className={styles.row2}>
                              <span>{translate('big5_report_resut_hint1') || 'Gering ausgeprägt'}</span>
                              <span>{translate('big5_report_resut_hint2') || 'Stark ausgeprägt'}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Big5Report;
