import React, {useEffect, useState} from 'react';
import styles from './Toggle.module.scss';
import classNames from 'classnames';


const Toggle = (props) => {
  const {id, checked, disabled, onChange} = props;
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className={classNames(styles.toggle, {[styles.disabled]: disabled})}>
      <input
        id={id}
        type="checkbox"
        name={'toggleSwitch'}
        className={styles.toggleCheckbox}
        checked={isChecked}
        onChange={handleChange}
      />
      <label className={styles.toggleLabel} htmlFor={id}>
        <span className={styles.toggleInner} />
        <span className={styles.toggleSwitch} />
      </label>
    </div>
  );
};

Toggle.defaultProps = {
  id: 'toggle',
  checked: false
};

export default Toggle;
