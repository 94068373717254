// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import styles from './SliderSingle.module.scss';

// ASSETS
import {ReactComponent as ArrowLeft} from 'assets/icons/icn_arrow_left.svg';
import {ReactComponent as ArrowRight} from 'assets/icons/icn_arrow_right.svg';


// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useAutoReset, useWindowWidth, useDebounce, useThrottle } from 'utils/hooks';
import { pxToNumber } from 'utils/styleTools';

// STORE

// CONFIG & DATA
const CONFIG = {
  dragThreshold: 12, // px
  dragFPS: 64,
  dragEndDelay: 25, // ms
};

// COMPONENT: SliderSingle
const SliderSingle = (props) => {
  // PROPS
  const {
    value,
    from = 0,
    to = 5,
    step = 1,
    onChange = () => {}
   } = props;

  // UI
  const [ touched, setTouched ] = useState(false);
  const [ transitioning, setTransitioning ] = useAutoReset(false, Number(styles.animationDurationMs));

  // UPDATE EXTERNALVALUE & TOUCHED based on value prop
  const [ externalValue, setExternalValue ] = useState(0);
  useEffect(() => {
    if (!isNaN(value)) {
      setTouched(true);
      setExternalValue(value);
    }
  }, [value]);

  // KNOB POSTION & BAR WIDTH
  const [ knobTranslate, setKnobTranslate ] = useState(0);
  const setKnobPositionByValue = useCallback((newValue) => {
    let knobTranslate = 0;

    const valueDistanceFor100Percent = to - from;
    const valueDistanceForValue = newValue - from;

    const percentDistanceForValue = valueDistanceForValue / valueDistanceFor100Percent * 100;

    knobTranslate = percentDistanceForValue;

    setKnobTranslate(knobTranslate);

    // console.log(`newValue: ${newValue}, centerValue: ${centerValue}, from: ${from}, to: ${to}, knobTranslate: ${knobTranslate}`);

  }, [from, to]);

  // MOVE KNOB based on VALUES: newValue, centerValue, from, to
  useEffect(() => {
    setKnobPositionByValue(externalValue, from, to);
  }, [externalValue, from, to, setKnobPositionByValue]);


  // UPDATE PIXELWIDTH & NODE based on debouncedWindowWidth
  const [ pixelWidth, setPixelWidth ] = useState(0);
  const [ node, setNode ] = useState(0);
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 300);
  const refCb = useCallback(newNode => {
    if ( newNode !== node && newNode !== null ) {
      setNode(newNode);
    }
  }, [node]);
  useEffect(() => {
    if (debouncedWindowWidth) {  /* just for linter */
      const pixelWidth = node.getBoundingClientRect().width;
      setPixelWidth(pixelWidth);
      // console.log('pixelWidth', pixelWidth);
    }
  }, [node, debouncedWindowWidth]);


  // MOVE KNOB based on PIXELS
  const setKnobPositionByPixels = useCallback((xDistanceFromComponent) => {
    let newValue = 0;
    const valueWidth = to - from;
    newValue = xDistanceFromComponent / pixelWidth * valueWidth;
    newValue = newValue + from;
    // console.log('setKnobPosition newValue:', newValue);

    setInternalValue(newValue);
    setKnobPositionByValue(newValue);
    setTouched(true);
  }, [from, to, pixelWidth, setKnobPositionByValue]);

  // UPDATE INTERNALVALUE
  const [ internalValue, setInternalValue ] = useState(0);

  // UPDATE ROUNDEDVALUE AND PROPAGATE to parent via onChange
  const [ roundedValue, setRoundedValue ] = useState(0);
  useEffect(() => {

    let newRoundedValue = internalValue / step;
    newRoundedValue = Math.round(newRoundedValue) * step;
    // prevent rounding errors and 0.500000000003 type situations
    newRoundedValue = Number(parseFloat(newRoundedValue).toPrecision(2));

    if (newRoundedValue !== roundedValue) {
      setRoundedValue(newRoundedValue);
      onChange(newRoundedValue);
    }
  }, [internalValue, step, roundedValue, onChange]);



  // DRAGGING
  const [ dragging, setDragging ] = useState(false);
  const [ dragOrigin, setDragOrigin ] = useState(false);
  const [ componentOrigin, setComponentOrigin ] = useState(false);
  const [ dragPosition, setDragPosition ] = useState(false);
  const throttledDragPosition = useThrottle(dragPosition, 1000 / CONFIG.dragFPS);

  useEffect(() => {
    if (throttledDragPosition !== false) {
      // console.log('throttledPosition', throttledDragPosition);
      setKnobPositionByPixels(throttledDragPosition);
    }
  }, [throttledDragPosition, setKnobPositionByPixels]);

  const resetDragging = () => {
    setDragging(false);
    setDragOrigin(false);
    setComponentOrigin(false);
    setDragPosition(false);
  };

  const handleDragStart = (event) => {
    // console.log('drag start');

    const pageX = event.pageX || event.touches[0].pageX;
    const componentOrigin = node.getBoundingClientRect().x;
    const dragOrigin = pageX - componentOrigin;

    setDragOrigin(dragOrigin);
    setComponentOrigin(componentOrigin);
  };

  const handleDragMove = (event) => {
    if (!dragOrigin) return;
    // console.log('move');
    const pageX = event.pageX || event.changedTouches[0].pageX;
    let dragPosition = pageX - componentOrigin;

    if (!dragging) {
      if (Math.abs(dragOrigin - dragPosition) > CONFIG.dragThreshold) {
        // console.log('set dragging true');
        setDragging(true);
      }
    }
    else {
      // console.log('dragging');
      // set position to bounds, when leaving bounds
      // but set position in any case so when dragging quickly to start/end
      // will give desired results
      if (dragPosition > pixelWidth) {
        dragPosition = pixelWidth;
      }
      if (dragPosition < 0) {
        dragPosition = 0;
      }
      setDragPosition(dragPosition);
    }
  };

  const handleDragEnd = (event) => {
    // console.log('dragend', event);
    handleDragMove(event); // update position one last time
    // prevent click and reset
    // event.preventDefault();

    // give a delay so dragmove can complete properly
    setTimeout(() => {
      resetDragging();
    }, CONFIG.dragEndDelay);
  };


  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleClick = (event) => {
    console.log('handle click');
    const pageX = event.pageX;
    const componentX = node.getBoundingClientRect().x;
    const clickX = pageX - componentX;

    // change position with transition
    setTransitioning(true);
    setKnobPositionByPixels(clickX);
  };
  const handleDoubleClick = (event) => {
    console.log('handle double click');
    // reset dragging
    resetDragging();
    // reset touched, change position with transition
    setTouched(false);
    setKnobPositionByValue(from);
    setInternalValue(from);
    setTransitioning(true);
  };
  const handleDoubleClickStop = (event) => {
    console.log('double click stop');
    event.stopPropagation();
  };


  // HELPERS

  // RENDERS

  // RENDER: SliderSingle
  return (
    <div ref={refCb} className={classNames(styles.sliderSingle, {
      [styles.transitioning]: transitioning,
    })}
      onClick={handleClick}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}

      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >

      {/* LINE */}
      <div className={styles.lineLayer}>
        <div className={styles.line}></div>
      </div>

      {/* KNOB */}
      <div className={classNames(styles.knobLayer, {
          [styles.touched]: touched,
        })}
        style={{
          marginLeft: `calc(${knobTranslate}% - ${pxToNumber(styles.knobXY) * knobTranslate / 100}px)`
        }}>
        <div className={classNames(styles.knob)}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          onDoubleClick={handleDoubleClick}
          onClick={handleDoubleClickStop}
          >
          <div className={styles.knobBackground}>
            <div className={styles.knobArrows}>
              <ArrowLeft/>
              <ArrowRight/>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SliderSingle;
