// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './QuestionIntro.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, Link } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA

// COMPONENT: QuestionIntro
const QuestionIntro = (props) => {
  // PROPS
  const {
    onNext = () => {},
    setModalCancelShow = () => {},
   } = props;

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleNext = () => {
    onNext();
  };

  // HELPERS

  // RENDERS

  // RENDER: QuestionIntro
  return (
    <div className={classNames(styles.questionIntro)}>

      {/* BREATHE */}
      <div className={styles.breatheContainer}>
        <div className={styles.breathe}></div>
      </div>

      {/* CONTENT */}
      <div className={styles.content}>

        {/* TITLE */}
        <div className={styles.title}>
          {translate('cp_intro') || 'Hallo, schön dass du da bist. Bevor wir loslegen müssen wir dich ein wenig besser Kennenlernen.'}
        </div>

        {/* CONTROLS */}
        <div className={styles.controls}>
          <div className={styles.logout}>
            <Link onClick={() => {
              setModalCancelShow(true);
            }}>{translate('cp_link_cancel') || 'Abmelden und später forsetzen'}</Link>
          </div>
          <div className={styles.button}>
            <Button onClick={handleNext}>
              {translate('cp_intro_btn') || 'Profil erstellen'}
            </Button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default QuestionIntro;
