import {HIDE_TOAST, SHOW_TOAST} from 'store/actions/toast';

const initialState = {
  isVisible: false,
  title: '',
  content: null,
  shouldTranslate: false,
};


export default (state = initialState, action) => {
  switch(action.type) {
    case SHOW_TOAST: {
      const { title, content, shouldTranslate } = action.payload;
      return {
        ...state,
        isVisible: true,
        title,
        content,
        shouldTranslate,
      }
    }
    case HIDE_TOAST: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
