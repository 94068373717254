export const RESET = '[VACANCY] RESET';
export const reset = () => {
  return { type: RESET };
};


export const GET_VACANCY = '[VACANCY] GET_VACANCY';
export const getVacancy = ({ id, loading = true }) => {
  return {
    type: GET_VACANCY,
    payload: { id, loading }
  };
};

export const GET_VACANCY_SUCCESS = '[VACANCY] GET_VACANCY_SUCCESS';
export const getVacancySuccess = ({ data }) => {
  return {
    type: GET_VACANCY_SUCCESS,
    payload: { data }
  };
};

export const GET_VACANCY_FAIL = '[VACANCY] GET_VACANCY_FAIL';
export const getVacancyFail = ({ error }) => {
  return {
    type: GET_VACANCY_FAIL,
    payload: { error }
  };
};

// GET VACANCY DESCRIPTION
export const GET_VACANCY_DESCRIPTION = '[VACANCY] GET_VACANCY_DESCRIPTION';
export const getVacancyDescription = ({ id}) => {
  return {
    type: GET_VACANCY_DESCRIPTION,
    payload: { id}
  };
};

export const GET_VACANCY_DESCRIPTION_SUCCESS = '[VACANCY] GET_VACANCY_DESCRIPTION_SUCCESS';
export const getVacancyDescriptionSuccess = ({ data }) => {
  return {
    type: GET_VACANCY_DESCRIPTION_SUCCESS,
    payload: { data }
  };
};

export const GET_VACANCY_DESCRIPTION_FAIL = '[VACANCY] GET_VACANCY_DESCRIPTION_FAIL';
export const getVacancyDescriptionFail = ({ error }) => {
  return {
    type: GET_VACANCY_DESCRIPTION_FAIL,
    payload: { error }
  };
};

// GET VACANCY CANDIDATES
export const GET_VACANCY_CANDIDATES = '[VACANCY] GET_VACANCY_CANDIDATES';
export const getVacancyCandidates = ({ id }) => {
  return {
    type: GET_VACANCY_CANDIDATES,
    payload: { id }
  };
};

export const GET_VACANCY_CANDIDATES_SUCCESS = '[VACANCY] GET_VACANCY_CANDIDATES_SUCCESS';
export const getVacancyCandidatesSuccess = ({ data }) => {
  return {
    type: GET_VACANCY_CANDIDATES_SUCCESS,
    payload: { data }
  };
};

export const GET_VACANCY_CANDIDATES_FAIL = '[VACANCY] GET_VACANCY_CANDIDATES_FAIL';
export const getVacancyCandidatesFail = ({ error }) => {
  return {
    type: GET_VACANCY_CANDIDATES_FAIL,
    payload: { error }
  };
};

// GET VACANCY MATCHES
export const GET_VACANCY_MATCHES = '[VACANCY] GET_VACANCY_MATCHES';
export const getVacancyMatches = ({ id, loading = true}) => {
  return {
    type: GET_VACANCY_MATCHES,
    payload: { id, loading }
  };
};

export const GET_VACANCY_MATCHES_SUCCESS = '[VACANCY] GET_VACANCY_MATCHES_SUCCESS';
export const getVacancyMatchesSuccess = ({ data }) => {
  return {
    type: GET_VACANCY_MATCHES_SUCCESS,
    payload: { data }
  };
};

export const GET_VACANCY_MATCHES_FAIL = '[VACANCY] GET_VACANCY_MATCHES_FAIL';
export const getVacancyMatchesFail = ({ error }) => {
  return {
    type: GET_VACANCY_MATCHES_FAIL,
    payload: { error }
  };
};

// GET VACANCY PROFILE
export const GET_VACANCY_PROFILE = '[VACANCY] GET_VACANCY_PROFILE';
export const getVacancyProfile = ({ id }) => {
  return {
    type: GET_VACANCY_PROFILE,
    payload: { id }
  };
};

export const GET_VACANCY_PROFILE_SUCCESS = '[VACANCY] GET_VACANCY_PROFILE_SUCCESS';
export const getVacancyProfileSuccess = ({ data }) => {
  return {
    type: GET_VACANCY_PROFILE_SUCCESS,
    payload: { data }
  };
};

export const GET_VACANCY_PROFILE_FAIL = '[VACANCY] GET_VACANCY_PROFILE_FAIL';
export const getVacancyProfileFail = ({ error }) => {
  return {
    type: GET_VACANCY_PROFILE_FAIL,
    payload: { error }
  };
};
