import { translate } from 'utils/translator/translator';

export const translateSortOptions = (sortOptions) => {
  return sortOptions.map(({ translationKey, translationFallback, sortBy, sortDirection }) => {
    const value = sortBy;
    const label = translate(translationKey) || translationFallback;
    return {
      value,
      label,
      sortBy,
      sortDirection,
    };
  });
};

export const findSortIndex = (({ sortOptions, sortBy, sortDirection }) => {
  return sortOptions
  .findIndex((option) => {
    return option.sortBy === sortBy
    && option.sortDirection === sortDirection
  });
});

// HELPERS
const LAST_NAME_SORT_OPTIONS = [
  {
    translationFallback: 'Last name A-Z',
    translationKey: 'table_sortby_name_asc',
    sortBy: 'user.lastName',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Last name Z-A',
    translationKey: 'table_sortby_name_desc',
    sortBy : 'user.lastName',
    sortDirection: 'desc'
  },
];

const POSITION_SORT_OPTIONS = [
  {
    translationFallback: 'Position ascending',
    translationKey: 'table_sortby_position_asc',
    sortBy: 'user.companyData.profession',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Position descending',
    translationKey: 'table_sortby_position_desc',
    sortBy: 'user.companyData.profession',
    sortDirection: 'desc',
  },
];

const STATUS_SORT_OPTIONS = [
  {
    translationFallback: 'Status aufsteigend',
    translationKey: 'table_sortby_status_asc',
    sortBy: 'status',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Status absteigend',
    translationKey: 'table_sortby_status_desc',
    sortBy: 'status',
    sortDirection: 'desc',
  },
];


// EMPLOYEES
export const EMPLOYEES_SORT_OPTIONS = [
  ...LAST_NAME_SORT_OPTIONS,
  ...POSITION_SORT_OPTIONS,
  {
    translationFallback: 'Career Level A-Z',
    translationKey: 'table_sortby_careerlevel_asc',
    sortBy: 'user.companyData.careerLevel',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Career Level Z-A',
    translationKey: 'table_sortby_careerlevel_desc',
    sortBy: 'user.companyData.careerLevel',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Since ascending',
    translationKey: 'table_sortby_since_asc',
    sortBy: 'user.companyData.companyEntryDate',
    sortDirection: 'asc',

  },
  {
    translationFallback: 'Since descending',
    translationKey: 'table_sortby_since_desc',
    sortBy: 'user.companyData.companyEntryDate',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Status ascending',
    translationKey: 'table_sortby_status_asc',
    sortBy: 'status',
    sortDirection: 'asc',

  },
  {
    translationFallback: 'Status descending',
    translationKey: 'table_sortby_status_desc',
    sortBy: 'status',
    sortDirection: 'desc',
  },
];


// ROLES
export const ROLES_SORT_OPTIONS = [
  {
    translationFallback: 'Role A-Z',
    translationKey: 'table_sortby_role_asc',
    sortBy: 'name',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Role Z-A',
    translationKey: 'table_sortby_role_desc',
    sortBy : 'name',
    sortDirection: 'desc'
  },
  {
    translationFallback: 'Career Level A-Z',
    translationKey: 'table_sortby_careerlevel_asc',
    sortBy: 'careerLevel',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Career Level Z-A',
    translationKey: 'table_sortby_careerlevel_desc',
    sortBy: 'careerLevel',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Employees ascending',
    translationKey: 'table_sortby_employeecount_asc',
    sortBy: 'users',
    sortDirection: 'asc',

  },
  {
    translationFallback: 'Employees descending',
    translationKey: 'table_sortby_employeecount_desc',
    sortBy: 'users',
    sortDirection: 'desc',
  }
];


// STAFFING
export const STAFFING_SORT_OPTIONS = [
  {
    translationFallback: 'Total descending',
    translationKey: 'table_sortby_totalscore_desc',
    sortBy: 'score',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Total ascending',
    translationKey: 'table_sortby_totalscore_asc',
    sortBy: 'score',
    sortDirection: 'asc',
  },
  ...LAST_NAME_SORT_OPTIONS,
  {
    translationFallback: 'Competencies ascending',
    translationKey: 'table_sortby_competencies_asc',
    sortBy: 'layerScores.4',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Competencies descending',
    translationKey: 'table_sortby_competencies_desc',
    sortBy: 'layerScores.4',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Personality ascending',
    translationKey: 'table_sortby_personality_asc',
    sortBy: 'layerScores.1',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Personality descending',
    translationKey: 'table_sortby_personality_desc',
    sortBy: 'layerScores.1',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Preferences ascending',
    translationKey: 'table_sortby_preferences_asc',
    sortBy: 'layerScores.2',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Preferences descending',
    translationKey: 'table_sortby_preferences_desc',
    sortBy: 'layerScores.2',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Potential ascending',
    translationKey: 'table_sortby_potential_asc',
    sortBy: 'layerScores.3',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Potential descending',
    translationKey: 'table_sortby_potential_desc',
    sortBy: 'layerScores.3',
    sortDirection: 'desc',
  }
];


// TEAMS
export const TEAMS_SORT_OPTIONS = [
  {
    translationFallback: 'Teamname A-Z',
    translationKey: 'table_sortby_team_name_asc',
    sortBy: 'name',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Teamname Z-A',
    translationKey: 'table_sortby_team_name_desc',
    sortBy: 'name',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Teamtype A-Z',
    translationKey: 'table_sortby_team_type_asc',
    sortBy: 'type',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Teamtype Z-A',
    translationKey: 'table_sortby_team_type_desc',
    sortBy: 'type',
    sortDirection: 'desc',
  },
  // Options hidden, see: https://blueexcellence.atlassian.net/browse/BQDQ-748
  /*
  {
    translationFallback: 'Role count ascending',
    translationKey: 'table_sortby_team_roles_count_asc',
    sortBy: 'roleCount',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Role count descending',
    translationKey: 'table_sortby_team_roles_count_desc',
    sortBy: 'roleCount',
    sortDirection: 'desc',
  },
  */
  {
    translationFallback: 'Team members ascending',
    translationKey: 'table_sortby_team_employees_count_asc',
    sortBy: 'users',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Team members descending',
    translationKey: 'table_sortby_team_employees_count_desc',
    sortBy: 'users',
    sortDirection: 'desc',
  },
  // Options hidden, see: https://blueexcellence.atlassian.net/browse/BQDQ-748
  /*
  {
    translationFallback: 'Status ascending',
    translationKey: 'table_sortby_status_asc',
    sortBy: 'status',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Status descending',
    translationKey: 'table_sortby_status_desc',
    sortBy: 'status',
    sortDirection: 'desc',
  },
  */
];

export const CANDIDATES_SORT_OPTIONS = [
  ...LAST_NAME_SORT_OPTIONS,
  {
    translationFallback: 'Career Level A-Z',
    translationKey: 'table_sortby_careerlevel_asc',
    sortBy: 'candidateData.careerLevel',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Career Level Z-A',
    translationKey: 'table_sortby_careerlevel_desc',
    sortBy: 'candidateData.careerLevel',
    sortDirection: 'desc',
  },
  ...STATUS_SORT_OPTIONS,
]

export const VACANCIES_SORT_OPTIONS = [
  {
    translationFallback: 'Name A-Z',
    translationKey: 'table_vacancy_name_asc',
    sortBy: 'name',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Name Z-A',
    translationKey: 'table_vacancy_name_desc',
    sortBy: 'name',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Career level ascending',
    translationKey: 'table_sortby_careerlevel_asc',
    sortBy: 'role.careerLevel',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Career level descending',
    translationKey: 'table_sortby_careerlevel_desc',
    sortBy: 'role.careerLevel',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Latest first (sorts by creation date)',
    translationKey: 'table_sortby_created_date_desc',
    sortBy: 'creationDate',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Start date',
    translationKey: 'table_start_date_asc',
    sortBy: 'metaData.startDate',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Status',
    translationKey: 'table_sortby_status_open',
    sortBy: 'status.open',
    sortDirection: 'asc'
  },
  {
    translationFallback: 'Status',
    translationKey: 'table_sortby_status_staffed',
    sortBy: 'status.staffed',
    sortDirection: 'asc'
  },
  {
    translationFallback: 'Status',
    translationKey: 'table_sortby_status_archived',
    sortBy: 'status.archived',
    sortDirection: 'asc'
  },
  {
    translationFallback: 'Candidates number A-Z',
    translationKey: 'table_candidates_number_asc',
    sortBy: 'candidates',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Candidates number A-Z',
    translationKey: 'table_candidates_number_desc',
    sortBy: 'candidates',
    sortDirection: 'desc',
  }
]

// USERS
export const USERS_SORT_OPTIONS = [
  ...LAST_NAME_SORT_OPTIONS,
  ...POSITION_SORT_OPTIONS,
  {
    translationFallback: 'Last active ascending',
    translationKey: 'table_lastactive_column_label',
    sortBy: 'user.lastActive',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Last active descending',
    translationKey: 'table_sortby_lastactive',
    sortBy: 'user.lastActive',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'User group ascending',
    translationKey: 'table_sortby_usergroup',
    sortBy: 'user.role',
    sortDirection: 'asc',
  },
  ...STATUS_SORT_OPTIONS,
];


// USERS ADMIN
export const USERS_ADMIN_SORT_OPTIONS = [
  {
    translationFallback: 'Last name A-Z',
    translationKey: 'table_sortby_name_asc',
    sortBy: 'user.lastName',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Last name Z-A',
    translationKey: 'table_sortby_name_desc',
    sortBy : 'user.lastName',
    sortDirection: 'desc'
  },
  {
    translationFallback: 'E-Mail A-Z',
    translationKey: 'table_sortby_mail_asc',
    sortBy: 'user.mail',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'E-Mail Z-A',
    translationKey: 'table_sortby_mail_desc',
    sortBy : 'user.mail',
    sortDirection: 'desc'
  },
  {
    translationFallback: 'Last active ascending',
    translationKey: 'table_lastactive_column_label',
    sortBy: 'user.lastActive',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Last active descending',
    translationKey: 'table_sortby_lastactive',
    sortBy: 'user.lastActive',
    sortDirection: 'desc',
  },
];


// INSTANCES
export const INSTANCES_SORT_OPTIONS = [
  {
    translationFallback: 'Latest first',
    translationKey: 'table_sortby_created_date_asc',
    sortBy: 'created',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Oldest first',
    translationKey: 'table_sortby_created_date_desc',
    sortBy: 'created',
    sortDirection: 'desc',
  },
];


// CONTENT PERFORMANCE
export const CONTENT_PERFORMANCE_SORT_OPTIONS = [
  {
    translationFallback: 'Helpful ascending',
    translationKey: 'table_sortby_pos_feedback_asc',
    sortBy: 'totalHelpful',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Helpful descending',
    translationKey: 'table_sortby_pos_feedback_desc',
    sortBy: 'totalHelpful',
    sortDirection: 'desc',
  },
  {
    translationFallback: 'Not helpful ascending',
    translationKey: 'table_sortby_neg_feedback_asc',
    sortBy: 'totalNotHelpful',
    sortDirection: 'asc',
  },
  {
    translationFallback: 'Not helpful descending',
    translationKey: 'table_sortby_neg_feedback_desc',
    sortBy: 'totalNotHelpful',
    sortDirection: 'desc',
  },
];
