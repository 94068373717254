// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import { Link as DomLink } from 'react-router-dom';
import styles from './Logo.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// COMPONENTS
import { Link as UiBasicLink } from 'ui/basic';

// STORE OLD
import { useSelector } from 'react-redux';
import { selectLogo } from 'store/selectors/configuration';

// COMPONENT: LogoInside
const LogoInside = (props) => {
  // PROPS
  const {
    size = 'M', // 'L'
    compensatePadding = false,
    link = undefined,
  } = props;

  const logo = useSelector(selectLogo);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: LogoInside
  let component = (
    <div className={classNames(styles.logoInside, {
      [styles[`size${size && size.toUpperCase()}`]]: size,
      [styles.compensatePadding]: compensatePadding,
      [styles.link]: Boolean(link),
    })}>
      <img src={logo} alt='logo' />
    </div>
  );

  if (link) {
    const Wrapper = link.startsWith('http') ? UiBasicLink : DomLink;
    component = <Wrapper to={link}>{ component }</Wrapper>;
  }

  return component;
};

export default LogoInside;
