import React, {useEffect, useState} from 'react';
import styles from './OrganisationSettings.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {Toggle, HotChips, Link, Checkbox} from 'ui/basic';
import { LogoUpload } from './LogoUpload';

// UTILS
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';
import { useTranslate } from 'utils/translator';
import REGEXES from 'utils/configuration/const/regexes';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';
import { scrollIntoView } from 'utils/scrolling';

// STORE
import {useDispatch, useSelector} from 'react-redux';
import * as fromSettingsSelectors from 'store/selectors/settings';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import {
  getCompanySettings, getSettings,
  updateCompanySettings,
  updateRegistrationDomains,
  updateSettings
} from 'store/actions';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';


const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_'
};

const DEFAULT_DESCRIPTION = 'Hier kannst du die Verfügbarkeit von Funktionen für deine Organisation konfigurieren.';
const DEFAULT_MODULES_DESCRIPTION =
  'Hier kannst Organisations-Einstellungen vornehmen, wie z.B. verfügbare Assessments oder Struktureinstellungen.';
const DEFAULT_ASSESSMENT_DESCRIPTION =
  'Dieses Modul ermittelt die Ausprägungen der Big Five-Merkmale: Offenheit für Erfahrungen,' +
  'Gewissenhaftigkeit, Extraversion, Verträglichkeit und Emotionale Stabilität.';

const SETTINGS = [
  {id: 'registration', label: 'org_settings_registration_short', altLabel: 'Registrierungsfreigabe'},
  {id: 'modules', label: 'org_settings_modules', altLabel: 'Module & Funktionen'},
  {id: 'notifications', label: 'gen_settings_notifications', altLabel: 'Benachrichtigungen'},
  {id: 'assessments', label: 'org_settings_assessments', altLabel: 'Assessments konfigurieren'},
  {id: 'logo', label: 'org_settings_logo' /*trans: Eigenes Logo hochladen*/ }
];

const MODULES = [
  {
    id: 'MODULE_ROLEMAPPING',
    title: 'org_settings_roles',
    altTitle: 'Rollen-Management & Matching',
    description: 'org_settings_roles_descr',
    altDescription: 'Hier kannst du Verfügbarkeit des Rollen-Managements ändern. ' +
      'Aktivierte Funktionen sind für die Nutzergruppe “Leader” & Admin sichtbar',
    excludeForBalancedYou: true
  },
  {
    id: 'MODULE_TEAMMAPPING',
    title: 'org_settings_teams',
    altTitle: 'Team-Management & Matching',
    description: 'org_settings_teams_descr',
    altDescription: 'Hier kannst du Verfügbarkeit des Team-Managements ändern.' +
      ' Aktivierte Funktionen sind für die Nutzergruppe “Leader” & Admin sichtbar',
    excludeForBalancedYou: true
  },
  {
    id: 'MODULE_WELLBEING',
    title: 'org_settings_assessments_wellbeing',
    altTitle: 'Well-Being',
    description: 'org_settings_assessments_wellbeing_descr',
    altDescription: DEFAULT_ASSESSMENT_DESCRIPTION
  },
  {
    id: 'INTEGRATION_GARMIN',
    title: 'org_settings_garmin',
    altTitle: 'Garmin-Connect',
    description: 'org_settings_garmin_descr',
    altDescription: 'Ermöglicht Nutzern das Verbinden von Garmin-Accounts und die Ermittlung von Gesundheitsdaten. Die Ergebnisse sind anonymisiert in der Organisationsübersicht verfügbar',
    excludeForBluquist: true
  }
];

const NOTIFICATIONS = [
  {
    key: 'assessmentReminderMails',
    title: 'notification_settings__assessments_title',
    altTitle: 'Assessments',
    description: 'notification_settings__assessments_descr',
    altDescription: 'Assessments description'
  },
  {
    key: 'wellbeingMails',
    title: 'notification_settings__wellbeing_title',
    altTitle: 'Well-being',
    description: 'notification_settings__wellbeing_descr',
    altDescription: 'Well-being description'
  },
  {
    key: 'wellbeingWeeklyReport',
    title: 'notification_settings__wb_weekly_report_title',
    altTitle: 'Well-being weekly report',
    description: 'notification_settings__wb_weekly_report_descr',
    altDescription: 'Well-being weekly report description'
  },
  {
    // key: 'newFeatureMails',
    title: 'gen_settings_notifications_new_functions',
    altTitle: 'New functions',
    description: 'gen_settings_notifications_new_functions_descr',
    altDescription: 'New functions description'
  },
];


const OrganisationSettings = () => {

  const translate = useTranslate();
  const dispatch = useDispatch();

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const settings = useSelector(state => state?.settings?.features);
  const fetchingSettings = useSelector(state => Boolean(state.settings?.processing));

  const companySettings = useSelector(fromSettingsSelectors.getCompanySettings);
  const companySettingsError = useSelector(fromSettingsSelectors.getCompanySettingsError);
  const updateCompanySettingsProcessing = useSelector(fromSettingsSelectors.getUpdateCompanySettingsProcessing);
  const updateCompanyFeaturesProcessing = useSelector(fromSettingsSelectors.getUpdateCompanyFeaturesProcessing);

  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);

  useEffect(() => {
    if (fetchingSettings || settings) {
      return;
    }

    dispatch(getSettings());
  }, [dispatch, fetchingSettings, settings]);

  useEffect(() => {
    if (!companySettings || Object.keys(companySettings).length === 0) {
      dispatch(getCompanySettings());
    }
  }, [dispatch, companySettings]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [assessments, setAssessments] = useState([]);
  useEffect(() => {
    if (assessments.length > 0 || !settings) {
      return;
    }

    setAssessments(settings
      .filter(s => s.category?.includes('assessment') && (s.active || !s.protected))
      .map(ass => {
        const obj = {...ass};
        obj.showDefaultUserActive = true;

        if (ass.id.includes(ASSESSMENT_TYPES.MINDFULNESS_JOURNEY)) {
          Object.assign(obj, {accessKey: 'canAccessMindfulnessJourney'})
        }

        const thisAssessment = assessmentsFromStore.find(a => ass.id.includes(a.id));
        if (thisAssessment?.render_results === 'clifton') {
          obj.showDefaultUserActive = false;
        }


        return obj;
      }));
  }, [settings, assessments.length, assessmentsFromStore]);

  const modules = MODULES
    .filter(module => (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU && !module.excludeForBalancedYou) ||
      (CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST && !module.excludeForBluquist)
    )
    .map(module => {
      const found = settings?.find(s => s.id === module.id);
      return {
        ...module,
        active: Boolean(found?.active)
      }
    });

  const notifications = NOTIFICATIONS.map(notification => {
    const notificationActive = companySettings && companySettings[notification.key];
    return {
      ...notification,
      active: !!notificationActive
    }
  });

  // HANDLERS
  const handleAssessmentToggleChange = (assessment, active) => {
    if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU && assessment.id !== 'MODULE_WELLBEING') {
      return;
    }

    const tempModules = modules.map(m => ({id: m.id, active: m.active}));
    let tempAssessments;

    if (CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST) {
      tempAssessments = assessments
        .filter(assessment => !assessment.accessKey || (assessment.accessKey && me[assessment.accessKey]))
        .map(localAssessment => ({
          id: localAssessment.id,
          defaultUserActive: localAssessment.defaultUserActive,
          active: localAssessment.id === assessment.id ? active : localAssessment.active
        }));
    } else {
      tempAssessments = assessments
        .filter(localAssessment => localAssessment.id === 'MODULE_WELLBEING')
        .map(localAssessment => ({
          id: localAssessment.id,
          defaultUserActive: localAssessment.defaultUserActive,
          active: localAssessment.id === assessment.id ? active : localAssessment.active
        }))
    }

    dispatch(updateSettings({
      features: CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ?
        tempAssessments :
        tempModules.concat(tempAssessments)
    }))
  }

  const handleAssessmentCheckboxChange = (assessment, activeForNewUser) => {
    const tempModules = modules.map(m => ({id: m.id, active: m.active}));
    const tempAssessments = assessments
      .filter(assessment => !assessment.accessKey || (assessment.accessKey && me[assessment.accessKey]))
      .map(localAssessment => ({
        id: localAssessment.id,
        active: localAssessment.active,
        defaultUserActive: localAssessment.id === assessment.id ? activeForNewUser : localAssessment.defaultUserActive
      }));

    dispatch(updateSettings({
      features: CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ?
        tempAssessments :
        tempModules.concat(tempAssessments)
    }))
  }

  return (
    <div className={styles.organisationSettings}>
      <div className={styles.main}>
        <div className={styles.title}>
          {translate('org_settings_title') || 'Organisations-Einstellungen'}
        </div>
        <div className={styles.description}>
          {translate('org_settings_description') || DEFAULT_DESCRIPTION}
        </div>
        <div className={styles.content}>
          {SETTINGS.map((s, index) => {
            if (s.accessKeys && !s.accessKeys.every(accessKey => me[accessKey])) {
              return null;
            }

            return (
              <Link
                key={`link${index}`}
                type={'anchor'}
                onClick={() => scrollIntoView(s.id)}
              >
                {translate(s.label) || s.altLabel}
              </Link>
            )
          })}
        </div>

        <div className={styles.registration} id={'registration'}>
          <div className={styles.title}>
            {translate('org_settings_registration_long') || 'Registrierungsfreigabe für ausgewählte E-Mail-Domains'}
          </div>
          <div className={styles.description}>
            {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}org_settings_registration_descr`) ||
            'Lege fest, welche Nutzer sich ohne Einladung für bluquist registrieren können, ' +
            'indem du ausgewählte E-Mail-Domains für die Registrierung freigibst. Du kannst diese Einstellung jederzeit ändern.'
            }
          </div>
          <div className={styles.domains}>
            <HotChips
              values={(companySettings && companySettings.openRegistrationDomains) ? companySettings.openRegistrationDomains: []}
              label={translate('org_settings_registration_domains_label') || 'E-Mail-Domains festlegen'}
              hint={
                translate('org_settings_registration_domains_hint') || 'Gib eine E-Mail-Domain ein und bestätige mit “enter”'
              }
              errorHint={
                translate('org_settings_registration_domains_error_hint') || 'Bitte gib eine gültige Domain an wie z.B domain.com an'
              }
              errorHintDuplicate={
                translate('org_settings_registration_domains_duplicate_hint') || 'Diese Domain ist bereits in der Liste'
              }
              placeholder='example.com'
              validate={{
                pattern: REGEXES.DOMAIN,
                onChange: true
              }}
              onUpdate={(domains) => {
                dispatch(updateRegistrationDomains(domains));
              }}
            />

          </div>
        </div>

        <div className={styles.modules} id={'modules'}>
          <div className={styles.title}>
            {translate('org_settings_modules') || 'Module & Funktionen'}
          </div>
          <div className={styles.description}>
            {translate('org_settings_modules_descr') || DEFAULT_MODULES_DESCRIPTION}
          </div>

          <div className={styles.rows}>
            {modules.map((module, index) => {
              return (
                <div className={styles.row} key={`role-${index}`}>
                  <div className={styles.row1}>
                    <span className='blu-typeLabel'>{translate(module.title) || module.altTitle}</span>
                    <Toggle
                      id={`module-toggle${index}`}
                      checked={module.active}
                      disabled={updateCompanyFeaturesProcessing}
                      onChange={(value) => {
                        const tempModules = modules.map(m => {
                          if (m.id === module.id) {
                            return {id: m.id, active: value}
                          }

                          return {id: m.id, active: m.active}
                        });

                        const tempAssessments = assessments
                          .filter(assessment => !assessment.accessKey || (assessment.accessKey && me[assessment.accessKey]))
                          .map(assessment => ({
                            id: assessment.id,
                            active: assessment.active
                        }));

                        dispatch(updateSettings({
                          features: tempModules.concat(tempAssessments)
                        }))
                      }}
                    />
                  </div>
                  <div className={styles.row2}>
                    {translate(module.description) || module.altDescription}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {/*NOTIFICATION SETTINGS*/}
        <div className={styles.modules} id={'notifications'}>
          <div className={styles.title}>
            {translate('gen_settings_notifications') || 'Notifications'}
          </div>
          <div className={styles.description}>
            {translate('org_settings_notifications_descr') || 'Notifications description'}
          </div>

          {companySettingsError &&
          <div className={classNames(styles.error, styles.paddingTopS)}>
            {companySettingsError.errorMessage}
          </div>
          }

          <div className={styles.rows}>
            {notifications.map((notification, index) => {
              return (
                <div className={styles.row} key={`role-${index}`}>
                  <div className={styles.row1}>
                    <span className='blu-typeLabel'>{translate(notification.title) || notification.altTitle}</span>
                    <Toggle
                      id={`notification-toggle${index}`}
                      checked={notification.active}
                      disabled={updateCompanySettingsProcessing}
                      onChange={value => {
                        if (!notification.key) {
                          return;
                        }

                        const {externalDataProcessingContext, ...existingCompanySettings} = companySettings;

                        dispatch(updateCompanySettings({
                          ...existingCompanySettings,
                          [notification.key]: value
                        }))
                      }}
                    />
                  </div>
                  <div className={styles.row2}>
                    {translate(notification.description) || notification.altDescription}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className={styles.assessments} id={'assessments'}>
          <div className={styles.title}>
            {translate('org_settings_assessments') || 'Assessments konfigurieren'}
          </div>
          <div className={styles.description}>
            {translate('org_settings_assessments_descr') ||
            'Hier kannst du die Verfügbarkeit von Assessments ändern. Aktivierte Assessments sind für alle Nutzer verfügbar.'
            }
          </div>
          <div className={styles.rows}>
            {assessments.map((assessment, index) => {
              if (assessment.accessKey && !me[assessment.accessKey]) {
                return null;
              }

              return (
                <div className={styles.row} key={`assessment-${index}`}>
                  <div className={styles.row1}>
                    <span className='blu-typeLabel'>{assessment.name}</span>
                    <Toggle
                      id={`assessment-toggle${index}`}
                      checked={assessment.active}
                      disabled={(CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU && assessment.id !== 'MODULE_WELLBEING') ||
                        (assessment.active && assessment.protected) ||
                        updateCompanyFeaturesProcessing
                      }
                      onChange={(value) => {
                        assessment.active = value;
                        handleAssessmentToggleChange(assessment, value);
                      }}
                    />
                  </div>
                  <div className={styles.row2}>
                    {assessment.description}
                  </div>

                  {assessment.showDefaultUserActive &&
                    <div className={styles.row3}>
                      <Checkbox
                        name={translate('assessment_active_for_new_users') || 'Standard für neue Nutzer'}
                        checked={assessment.defaultUserActive}
                        disabled={!assessment.active || updateCompanyFeaturesProcessing}
                        onChange={(checked) => {
                          assessment.defaultUserActive = checked;
                          handleAssessmentCheckboxChange(assessment, checked);
                        }}
                      />
                    </div>
                  }

                </div>
              )
            })}
          </div>
        </div>


        {/* LOGO */}
        <div className={styles.logo} id='logo'>

          <div className={styles.title}>
            {/* Eigenes Logo hochladen */}
            { translate('org_settings_logo') }

            <div className={styles.description}>
              {/* You can upload your own company logo... */}
              { translate('org_settings_logo_description') }
            </div>

            <div className={styles.domains}>
              <LogoUpload />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
};

export default OrganisationSettings;
