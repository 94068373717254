import React, { useEffect, useState} from 'react';
import styles from './OrgWellBeingResult.module.scss';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/de';

import { useTranslate } from 'utils/translator';
import * as fromWellBeingSelectors from 'store/selectors/wellBeing';
import {ReactComponent as VeryHappy} from 'assets/well-being/very-happy.svg';
import {ReactComponent as Happy} from 'assets/well-being/happy.svg';
import {ReactComponent as Neutral} from 'assets/well-being/neutral.svg';
import {ReactComponent as Sad} from 'assets/well-being/sad.svg';
import {ReactComponent as VerySad} from 'assets/well-being/very-sad.svg';

import {getOrgWellBeingHistory, initGetOrgWellBeingHistory} from 'store/actions';

import {LANGUAGES} from 'utils/configuration/const/languages';
import {WELL_BEING_MODES} from 'utils/configuration/const/well-being';
import { setDecimalSeperator } from 'utils/userpreference';

import {
  InfoTitle, Callout, ProgressCircle,
  Button, WellbeingDiagram, Card, PillGroup, Modal,
} from 'ui/basic';


const MAX_WELLBEING_INDEX = 5;

const WELL_BEING_EMOJIS = {
  1: <VerySad/>,
  2: <Sad/>,
  3: <Neutral/>,
  4: <Happy/>,
  5: <VeryHappy/>
};

let orgWellBeingDailyHistory;

const OrgWellBeingResult = (props) => {
  const {
    compactMode,
    wellbeingIndex, // overrides the value from the store
    userCount, // overrides the value from store
    wellbeingHistory, // overrides the value from the store
  } = props;

  const translate = useTranslate();

  // INFO MODAL
  const [showModal, setShowModal] = useState(false);
  const [modalHeader, setModalHeader] = useState();
  const [modalHeaderPosition, setModalHeaderPosition] = useState('left');
  const [modalPreHeader, setModalPreHeader] = useState()
  const [modalContent, setModalContent] = useState();
  const [modalButtonsPosition, setModalButtonsPosition] = useState('right');
  const [modalHint, setModalHint] = useState();


  // CHART
  const [showChart, setShowChart] = useState(false);
  const [chartViewMode, setChartViewMode] = useState(WELL_BEING_MODES.WEEKLY);

  // STATE
  const [filteredWellBeingHistory, setFilteredWellBeingHistory] = useState([]);
  const [ currentWellbeingHistory, setCurrentWellbeingHistory ] = useState([]);
  const [wellBeingYear, setWellBeingYear] = useState();

  // STORE
  const dispatch = useDispatch();

  const orgWellBeingIndex = useSelector(fromWellBeingSelectors.getOrgWellBeingIndex);
  const orgWellBeingIndexUserCount = useSelector(fromWellBeingSelectors.getOrgWellBeingIndexUserCount);
  const orgWellBeingIndexNotEnoughData = useSelector(fromWellBeingSelectors.getOrgWellBeingIndexNotEnoughData);
  const orgWellBeingHistory = useSelector(fromWellBeingSelectors.getOrgWellBeingHistory);

  // LANGUAGE
  const currentLanguage = useSelector(
    state => state.localisation && state.localisation.currentLanguage
  );

  const getDate = (date) => {
    if (chartViewMode === WELL_BEING_MODES.WEEKLY) {
      return date;
    }

    const m = moment(date);
    m.locale(currentLanguage === LANGUAGES.DE ? 'de' : 'en');
    return m.format('MMMM');
  };

  const getWeeklyCalloutText = () => {

    if (orgWellBeingIndexNotEnoughData) {
      return translate('wellbeing_org_result_not_enough_data');
    }

    if (!orgWellBeingDailyHistory || orgWellBeingDailyHistory.length === 0) {
      return translate(`wellbeing_org_${chartViewMode}_no_result`) || 'No well-being index for previous day';
    }

    const currentWeek = orgWellBeingDailyHistory[orgWellBeingDailyHistory.length - 1];
    const prevWeek = orgWellBeingDailyHistory[orgWellBeingDailyHistory.length - 2];

    let diff;
    if (currentWeek && prevWeek) {
      diff = Number((currentWeek.index - prevWeek.index).toFixed(1));
    }

    if (!diff) {
      return translate('wb_weekly_report_md_wbi_no_diff') || 'The general well-being has not changed compared to last week. '
    } else if (diff > 0 && diff <= 0.9) {
      return translate('wb_weekly_report_md_wbi_slight_impr') || 'General well-being has improved slightly compared to last week.';
    } else if (diff < -0 && diff >= -0.9) {
      return translate('wb_weekly_report_md_wbi_slight_deter') || 'The general well-being has slightly worsened compared to last week.';
    } else if (diff >= 1 && diff <= 1.9) {
      return translate('wb_weekly_report_md_wbi_strong_impr') || 'The general well-being has improved significantly compared to last week.';
    } else if (diff <= -1 && diff >= -1.9) {
      return translate('wb_weekly_report_md_wbi_strong_deter') || 'The general well-being has worsened noticeably compared to last week.';
    } else if (diff >= 2 && diff <= 5) {
      return translate('wb_weekly_report_md_wbi_very_strong_impr') || 'The general well-being has improved a lot compared to last week.';
    } else {
      return translate('wb_weekly_report_md_wbi_very_strong_deter') || 'The general well-being has worsened a lot compared to last week.'
    }
  };

  const getCalloutText = (index) => {
    const currentWellBeing = currentWellbeingHistory[index];
    const prevWellBeing = currentWellbeingHistory[index - 1];

    if (!currentWellBeing || !prevWellBeing) {
      return translate(`wellbeing_org_${chartViewMode}_no_result`) || 'No well-being index for previous day'
    }

    const diff = Number((currentWellBeing.index - prevWellBeing.index).toFixed(1));
    if (diff === 0) {
      return translate(`wellbeing_org_${chartViewMode}_result_no_difference`) || 'The general well-being has not changed compared to last week. '
    } else if (diff > 0 && diff <= 0.9) {
      return translate(`wellbeing_org_${chartViewMode}_result_slight_impr`) || 'General well-being has improved slightly compared to last week.';
    } else if (diff < -0 && diff >= -0.9) {
      return translate(`wellbeing_org_${chartViewMode}_result_slight_deter`) || 'The general well-being has slightly worsened compared to last week.';
    } else if (diff >= 1 && diff <= 1.9) {
      return translate(`wellbeing_org_${chartViewMode}_result_strong_impr`) || 'The general well-being has improved significantly compared to last week.';
    } else if (diff <= -1 && diff >= -1.9) {
      return translate(`wellbeing_org_${chartViewMode}_result_strong_deter`) || 'The general well-being has worsened noticeably compared to last week.';
    } else if (diff >= 2 && diff <= 5) {
      return translate(`wellbeing_org_${chartViewMode}_result_very_strong_impr`) || 'The general well-being has improved a lot compared to last week.';
    } else {
      return translate(`wellbeing_org_${chartViewMode}_result_very_strong_deter`) || 'The general well-being has worsened a lot compared to last week.'
    }
  };

  useEffect(() => {
    if (!orgWellBeingHistory) {
      dispatch(getOrgWellBeingHistory());
    }
  }, [dispatch, orgWellBeingHistory]);

  useEffect(() => {
    return () => {
      dispatch(initGetOrgWellBeingHistory());
    }
  }, [dispatch]);

  useEffect(() => {
    const internalOrgWellBeingHistory = [...(wellbeingHistory ||orgWellBeingHistory || [])];
    if (internalOrgWellBeingHistory && internalOrgWellBeingHistory.length > 0) {

      //orgWellBeingDailyHistory field is needed for weekly comparison text
      if (!orgWellBeingDailyHistory) {
        orgWellBeingDailyHistory = internalOrgWellBeingHistory;
      }

      setFilteredWellBeingHistory(internalOrgWellBeingHistory);
    }
  }, [wellbeingHistory, orgWellBeingHistory, chartViewMode]);

  useEffect(() => {
    let currentWellbeingChartHistory;

    if (!filteredWellBeingHistory || (filteredWellBeingHistory && filteredWellBeingHistory.length === 0)) {
      currentWellbeingChartHistory = [];
    } else {
      currentWellbeingChartHistory = [...filteredWellBeingHistory];
    }

    setCurrentWellbeingHistory(currentWellbeingChartHistory);
  }, [chartViewMode, filteredWellBeingHistory]);

  return (
    <Card>
      <div className={classNames(styles.wellBeingResult, {[styles.compact]: compactMode})}>
        <InfoTitle
          title={translate('wellbeing_ind_result_title') || 'Wohlbefinden'}
          onClick={() => {
            setModalHeader(translate('wellbeing_ind_result_title') || 'Wohlbefinden');
            setModalHeaderPosition('left');
            setModalContent(translate('wellbeing_ind_result_info_modal_description') || 'Wohlbefinden Beschreibung');
            setModalPreHeader(null);
            setModalButtonsPosition('right');
            setModalHint(null);

            setShowModal(true);
          }}
        />

        <div className={styles.wellBeingIndexPanel}>
          <div className={styles.wellBeingIndex}>
            {/* WBI TITLE OK */}
            <span className={styles.title}>
              {translate('wellbeing_org_result_body_text') || 'Dein Wellbeing-Index'}
              <br/>

              {/* USER COUNT */}
              { !orgWellBeingIndexNotEnoughData && (
                <span className={styles.userCount}>
                  {translate('wellbeing_org_result_user_count', ['{{userCount}}', userCount || orgWellBeingIndexUserCount])}
                </span>
              )}
            </span>

            {/* INDEX DISPLAY */}
            <div className={styles.indexContainer}>
              <div className={styles.index}>{`${setDecimalSeperator(wellbeingIndex || orgWellBeingIndex, 2) || '-'} / 5`}</div>
              <div className={styles.progress}>
                <ProgressCircle
                  size='M'
                  progress={Math.ceil((wellbeingIndex || orgWellBeingIndex) * 100 / MAX_WELLBEING_INDEX)}
                  showWellBeingIcon={true}
                />
              </div>
            </div>
          </div>

          {showChart
          ?
            <Button
              looks={'tertiary'}
              size={'S'}
              onClick={() => setShowChart(false)}
            >
              {translate('wellbeing_ind_result_hide_progress_btn') || 'Verlauf ausblenden'}
            </Button>
          :
            (currentWellbeingHistory && currentWellbeingHistory.length> 0) && (
              <Button
                looks={'tertiary'}
                size={'S'}
                onClick={() => setShowChart(true)}
              >
                {translate('wellbeing_ind_result_show_progress_btn') || 'Verlauf anzeigen'}
              </Button>
            )
          }
        </div>

        {/* WEEKLY CALLOUT */}
        { !showChart &&
        <div className={styles.calloutBlock}>
          <Callout trianglePosition={'right'}>
            {getWeeklyCalloutText()}
          </Callout>
        </div>
        }

        {/* WELLBEING DIAGRAM */}
        { showChart &&
          <div className={styles.chart}>
            {/*DATE CONTROLS*/}
            <div className={styles.controls}>
              <PillGroup
                pillItems={[
                  // {id: WELL_BEING_MODES.WEEKLY, label: translate('wellbeing_ind_result_toggle_weeks') || 'Wochen'},
                  {id: WELL_BEING_MODES.WEEKLY, label: 'Weeks'},
                  {id: WELL_BEING_MODES.MONTHLY, label: translate('wellbeing_ind_result_toggle_months') || 'Monate'},
                ]}
                onPillClick={(pillItem) => {
                  if (chartViewMode === pillItem.id) {
                    return;
                  }

                  dispatch(getOrgWellBeingHistory(pillItem.id));
                  setChartViewMode(pillItem.id);
                }}
              />

              {/*YEAR*/}
              <div>{wellBeingYear}</div>
            </div>

            <WellbeingDiagram
              points={currentWellbeingHistory.map(d => d.index)}
              dates={chartViewMode === WELL_BEING_MODES.WEEKLY ?
                currentWellbeingHistory.map(d=> d.week ? d.week.split('-')[1] : 0) :
                currentWellbeingHistory.map(d => d.date)
              }
              mode={chartViewMode}
              infoModalIsOpen={showModal}
              onPaginationAvailabilities={([pageLeftAvailable, pageRightAvailable, pagePosition]) => {
                if (pagePosition < 0) {
                  return;
                }

                const position = 6 * pagePosition;
                const chunk = currentWellbeingHistory.slice(position, position + 6);
                const lastChunk = chunk[chunk.length - 1];

                if (!lastChunk) {
                  return;
                }

                // YEAR
                let year = '-';
                if (lastChunk.week) {
                  year = lastChunk.week.split('-')[0];
                } else {
                  year = new Date(lastChunk.date).getFullYear();
                }

                setWellBeingYear(year);
              }}
              onClick={(date, wellBeingIndex, index) => {
                setModalPreHeader(
                  <div className={styles.wellBeingModalPreHeader}>
                    {WELL_BEING_EMOJIS[Math.ceil(wellBeingIndex)]}
                  </div>
                );

                setModalHeaderPosition('center');
                setModalHeader(
                  <div className={styles.wellBeingModalHeader}>
                    <div>
                      {translate(
                        `wellbeing_org_${chartViewMode}_result_info_modal_title`,
                        ['{{date}}', getDate(date)]
                      ) || 'Dein Wellbeing vom 17.08.'}
                    </div>
                    <div className={styles.index}>
                      {`${wellBeingIndex} / 5`}
                    </div>
                  </div>
                );

                setModalContent(
                  <Callout>
                    <div style={{textAlign: 'center'}}>
                      {getCalloutText(index)}
                    </div>
                  </Callout>
                );

                setModalHint((currentWellbeingHistory && currentWellbeingHistory[index]) &&
                  (translate('wellbeing_org_result_modal_hint', ['{{employees}}', currentWellbeingHistory[index].users]) ||
                    '* auf Basis von 10 anonymisierten Angaben ermittelt')
                );
                setModalButtonsPosition('center');
                setShowModal(true);
              }}
            />
          </div>
        }

        <div className={styles.progressButton}>
          {showChart ?
            <Button
              looks={'tertiary'}
              size={'S'}
              onClick={() => setShowChart(false)}
            >
              {translate('wellbeing_ind_result_hide_progress_btn') || 'Verlauf ausblenden'}
            </Button> :
            (filteredWellBeingHistory && filteredWellBeingHistory.length> 0) &&
            <Button
              looks={'tertiary'}
              size={'S'}
              onClick={() => setShowChart(true)}
            >
              {translate('wellbeing_ind_result_show_progress_btn') || 'Verlauf anzeigen'}
            </Button>
          }
        </div>

        {showModal &&
        <Modal
          header={modalHeader}
          headerPosition={modalHeaderPosition}
          preHeader={modalPreHeader}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          buttonsPosition={modalButtonsPosition}
          hint={modalHint}
          onClose={() => setShowModal(false)}
        >
          {modalContent}
        </Modal>
        }
      </div>
    </Card>
  )
};

OrgWellBeingResult.defaultProps = {
  compactMode: true
};

export default OrgWellBeingResult;
