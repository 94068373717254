// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useRef, useState} from 'react';
import styles from './Recommendations.module.scss';

// ASSETS
import {ReactComponent as SleepKeyVisual} from 'assets/balanced-you/garmin/keyvisuals/sleep.svg';
import {ReactComponent as StressKeyVisual} from 'assets/balanced-you/garmin/keyvisuals/stress.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {ButtonCircular, Modal, Scrollable} from 'ui/basic';

// UTILS
import {useBreakpoint} from 'utils/hooks';
import {useTranslate} from 'utils/translator';
import {randomizeArray} from 'utils/randomize';
import {disableScrollingOnBody, enableScrollingOnBody} from 'utils/scrolling';

// STORE
import {useDispatch, useSelector} from 'react-redux';
import {initSubmitContentFeedback, submitContentFeedback} from 'store/actions';

// CONFIG & DATA
// const Config = {};

// COMPONENT: Recommendations
const Recommendations = (props) => {
  // PROPS
  const { userId, sleepClass, stressClass, timestamp } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();
  const recommendationsScrollableRef = useRef();
  const bp = useBreakpoint();

  const feedbackSubmittedSuccessfully = useSelector(state => state.survey && state.survey.success);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ recommendationsPageLeftAvailable, setRecommendationsPageLeftAvailable] = useState(false);
  const [ recommendationsPageRightAvailable, setRecommendationsPageRightAvailable] = useState(false);

  const handleRecommendationsPaginationAvailabilities = ([pageLeftAvailable, pageRightAvailable, pagePosition]) => {
    setRecommendationsPageLeftAvailable(pageLeftAvailable);
    setRecommendationsPageRightAvailable(pageRightAvailable);
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  // RECOMMENDATIONS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const getAlternatingOrder = (array1 = [], array2 = [], randomize) => {
    const finalArray = [];
    let internalArray1 = array1;
    let internalArray2 = array2;

    if (randomize) {
      internalArray1 = randomizeArray(array1);
      internalArray2 = randomizeArray(array2);
    }

    if (internalArray1.length === internalArray2.length) {
      for (let i = 0; i < internalArray1.length; i++) {
        finalArray.push(internalArray1[i]);
        finalArray.push(internalArray2[i]);
      }

      return finalArray.slice(0, 8);
    }

    if (internalArray1.length > internalArray2.length) {
      for (let i = 0; i < internalArray2.length; i++) {
        finalArray.push(internalArray1[i]);
        finalArray.push(internalArray2[i]);
      }

      const rest = internalArray1.slice(internalArray2.length, internalArray1.length);

      return [...finalArray, ...rest].slice(0, 8);
    }

    if (internalArray2.length > internalArray1.length) {
      for (let i = 0; i < internalArray1.length; i++) {
        finalArray.push(internalArray1[i]);
        finalArray.push(internalArray2[i]);
      }

      const rest = internalArray2.slice(internalArray1.length, internalArray2.length);

      return [...finalArray, ...rest].slice(0, 8);
    }

    return finalArray;
  }

  const [recommendations, setRecommendations] = useState([]);
  useEffect(() => {
    if ((!sleepClass && !stressClass) || recommendations.length > 0) {
      return;
    }

    if (timestamp && localStorage.getItem(`recommendations_${userId}_${timestamp}`)) {
      setRecommendations(JSON.parse(localStorage.getItem(`recommendations_${userId}_${timestamp}`)));
      return;
    }

    const sleepBlueRecommendationsNumber = 3;
    const sleepBlueRecommendations = new Array(sleepBlueRecommendationsNumber).fill(1).map((value, index) => `sleep_blue_recommendation${index + 1}`);

    const sleepRedRecommendationsNumber = 7;
    const sleepRedRecommendations = new Array(sleepRedRecommendationsNumber).fill(1).map((value, index) => `sleep_red_recommendation${index + 1}`);

    const stressBlueRecommendationsNumber = 5;
    const stressBlueRecommendations = new Array(stressBlueRecommendationsNumber).fill(1).map((value, index) => `stress_blue_recommendation${index + 1}`);

    const stressRedRecommendationsNumber = 7;
    const stressRedRecommendations = new Array(stressRedRecommendationsNumber).fill(1).map((value, index) => `stress_red_recommendation${index + 1}`);

    let internalRecommendations = [];
    const sleepClassInternal = sleepClass;
    const stressClassInternal = stressClass;

    if ((sleepClassInternal === 'good' || sleepClassInternal === 'medium-good') && !stressClassInternal) {
      internalRecommendations = randomizeArray(sleepBlueRecommendations).slice(0, 7);
    }

    if ((sleepClassInternal === 'bad' || sleepClassInternal === 'medium-bad') && !stressClassInternal) {
      internalRecommendations = randomizeArray(sleepRedRecommendations).slice(0, 7);
    }

    if ((stressClassInternal === 'good' || stressClassInternal === 'medium-good') && !sleepClassInternal) {
      internalRecommendations = randomizeArray(stressBlueRecommendations).slice(0, 7);
    }

    if ((stressClassInternal === 'bad' || stressClassInternal === 'medium-bad') && !sleepClassInternal) {
      internalRecommendations = randomizeArray(stressRedRecommendations).slice(0, 7);
    }

    // sleep and stress are BLUE
    if ((sleepClassInternal === 'good' || sleepClassInternal === 'medium-good') && sleepClassInternal === stressClassInternal) {
      internalRecommendations = getAlternatingOrder(sleepBlueRecommendations, stressBlueRecommendations, true);
    }

    // sleep and stress are RED
    if ((sleepClassInternal === 'bad' || sleepClassInternal === 'medium-bad') && sleepClassInternal === stressClassInternal) {
      internalRecommendations = getAlternatingOrder(sleepRedRecommendations, stressRedRecommendations, true);
    }

    // sleep is LIGHT BLUE, stress is BLUE and sleep is worse than stress
    if (sleepClassInternal === 'medium-good' && stressClassInternal === 'good') {
      internalRecommendations = [...randomizeArray(sleepBlueRecommendations), ...randomizeArray(stressBlueRecommendations)].slice(0, 8);
    }

    // sleep is RED, stress is LIGHT RED and sleep is worse than stress
    if (sleepClassInternal === 'bad' && stressClassInternal === 'medium-bad') {
      internalRecommendations = [...randomizeArray(sleepRedRecommendations), ...randomizeArray(stressRedRecommendations)].slice(0, 8);
    }

    // sleep is RED, stress is BLUE and sleep is worse than stress
    if ((sleepClassInternal === 'bad' || sleepClassInternal === 'medium-bad') &&
      (stressClassInternal === 'good' || stressClassInternal === 'medium-good')
    ) {
      internalRecommendations = [...randomizeArray(sleepRedRecommendations), ...randomizeArray(stressBlueRecommendations)].slice(0, 8);
    }

    // stress is LIGHT BLUE, sleep is BLUE and stress is worse than stress
    if (stressClassInternal === 'medium-good' && sleepClassInternal === 'good') {
      internalRecommendations = [...randomizeArray(stressBlueRecommendations), ...randomizeArray(sleepBlueRecommendations)].slice(0, 8);
    }

    // stress is RED, sleep is LIGHT RED and stress is worse than sleep
    if (stressClassInternal === 'bad' && sleepClassInternal === 'medium-bad') {
      internalRecommendations = [...randomizeArray(stressRedRecommendations), ...randomizeArray(sleepRedRecommendations)].slice(0, 8);
    }

    // stress is RED, sleep is BLUE and stress is worse than stress
    if ((stressClassInternal === 'bad' || stressClassInternal === 'medium-bad') &&
      (sleepClassInternal === 'good' || sleepClassInternal === 'medium-good')
    ) {
      internalRecommendations = [...randomizeArray(stressRedRecommendations), ...randomizeArray(sleepBlueRecommendations)].slice(0, 8);
    }

    if (timestamp) {
      localStorage.setItem(`recommendations_${userId}_${timestamp}`, JSON.stringify(internalRecommendations));
    }

    setRecommendations(internalRecommendations);
  }, [sleepClass, stressClass, recommendations.length, timestamp, userId]);

  const [recommendationId, setRecommendationId] = useState();
  const [recommendationModalVisible, setRecommendationModalVisible] = useState();

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, [bp, recommendationModalVisible]);

  // RENDER: Recommendations
  return (
    <div className={classNames(styles.recommendations)}>
      <div className={styles.title}>{translate('body_battery_recommendations_title')}</div>

      <div className={styles.recommendationsScrollableItems}>
        <ButtonCircular
          icon={'ArrowLeft'}
          looks={'primary'}
          disabled={!recommendationsPageLeftAvailable}
          onClick={() => { recommendationsScrollableRef.current.pageLeft()}}
        />
        <Scrollable
          ref={recommendationsScrollableRef}
          pagination drag
          showPaginationBubbles={bp.isXs ? false : true}
          pageMargin={bp.isXs ? 8 : 16}
          onPaginationAvailabilities={handleRecommendationsPaginationAvailabilities}
        >
          {recommendations.map((recommendation, index) => {
            const recommendationImage = recommendation.includes('sleep') ? <SleepKeyVisual /> : <StressKeyVisual />;

            return (
              <div
                key={index}
                className={styles.recommendation}
                onClick={() => {
                  setRecommendationId(recommendation);
                  setRecommendationModalVisible(true);
                }}
              >
                {recommendationImage}

                <div className={styles.text}>
                  <div className='blu-typeLabel'>
                    {translate(`${recommendation}_title`)}
                  </div>
                </div>
              </div>
            )
          })}
        </Scrollable>

        <ButtonCircular
          icon={'ArrowRight'}
          looks={'primary'}
          disabled={!recommendationsPageRightAvailable}
          onClick={() => { recommendationsScrollableRef.current.pageRight()}}
        />
      </div>

      {recommendationModalVisible &&
        <Modal
          header={translate(`${recommendationId}_title`)}
          secondaryButtonTitle={translate('close_lbl') || 'Schließen'}
          feedbackSubmitted={feedbackSubmittedSuccessfully}
          onFeedbackSubmit={
            ((feedback) => {
              dispatch(submitContentFeedback({
                contentId: recommendationId,
                contentType: 'Garmin recommendations',
                helpful: feedback === 'Yes'
              }))
            })
          }
          onClose={() => {
            dispatch(initSubmitContentFeedback());
            setRecommendationModalVisible(false)
          }}
        >
          {translate(`${recommendationId}_copy`)}
        </Modal>
      }
    </div>
  );
};

export default Recommendations;
