import * as fromActionTypes from 'store/actionTypes/roles';

//PLATFORM ROLES
export const getPlatformRoles = () => {
  return {
    type: fromActionTypes.GET_PLATFORM_ROLES,
    payload: {}
  };
};

export const getPlatformRolesFulfilled = ({ roles }) => {
  return {
    type: fromActionTypes.GET_PLATFORM_ROLES_FULFILLED,
    payload: { roles }
  };
};

export const getPlatformRolesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_PLATFORM_ROLES_REJECTED,
    payload: { error }
  };
};

export const createPlatformRole = (payload) => {
  return {
    type: fromActionTypes.CREATE_PLATFORM_ROLE,
    payload: {payload}
  };
};

export const createPlatformRoleFulfilled = ({ response }) => {
  return {
    type: fromActionTypes.CREATE_PLATFORM_ROLE_FULFILLED,
    payload: {response}
  };
};

export const createPlatformRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.CREATE_PLATFORM_ROLE_REJECTED,
    payload: {error}
  };
};

export const updatePlatformRole = (updatePlatformDto) => {
  return {
    type: fromActionTypes.UPDATE_PLATFORM_ROLE,
    payload: {updatePlatformDto}
  };
};

export const updatePlatformRoleFulfilled = ({ response }) => {
  return {
    type: fromActionTypes.UPDATE_PLATFORM_ROLE_FULFILLED,
    payload: {response}
  };
};

export const updatePlatformRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.UPDATE_PLATFORM_ROLE_REJECTED,
    payload: {error}
  };
};

//ORGANISATIONAL ROLES
export const getOrgRoles = (requestDto) => {
  return {
    type: fromActionTypes.GET_ORG_ROLES,
    payload: {requestDto}
  };
};

export const getOrgRolesFulfilled = ({ roles,totalCount }) => {
  return {
    type: fromActionTypes.GET_ORG_ROLES_FULFILLED,
    payload: { roles, totalCount }
  };
};

export const getOrgRolesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_ORG_ROLES_REJECTED,
    payload: { error }
  };
};

//GET ORG ROLE BY ID
export const initGetOrgRole = () => {
  return {
    type: fromActionTypes.INIT_GET_ORG_ROLE,
    payload: {}
  };
};

export const getOrgRole = (id) => {
  return {
    type: fromActionTypes.GET_ORG_ROLE,
    payload: {id}
  };
};

export const getOrgRoleFulfilled = ({ role }) => {
  return {
    type: fromActionTypes.GET_ORG_ROLE_FULFILLED,
    payload: {role}
  };
};

export const getOrgRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_ORG_ROLE_REJECTED,
    payload: { error }
  };
};

//CREATE ORG ROLE
export const initCreateOrgRole = () => {
  return {
    type: fromActionTypes.INIT_CREATE_ORG_ROLE,
    payload: {}
  };
};

export const createOrgRole = (newRoleDto) => {
  return {
    type: fromActionTypes.CREATE_ORG_ROLE,
    payload: {newRoleDto}
  };
};

export const createOrgRoleFulfilled = ({ roleId }) => {
  return {
    type: fromActionTypes.CREATE_ORG_ROLE_FULFILLED,
    payload: {roleId}
  };
};

export const createOrgRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.CREATE_ORG_ROLE_REJECTED,
    payload: {error}
  };
};

//UPDATE ORG ROLE
export const initUpdateOrgRole = () => {
  return {
    type: fromActionTypes.INIT_UPDATE_ORG_ROLE,
    payload: {}
  };
};

export const updateOrgRole = (updateRoleDto) => {
  return {
    type: fromActionTypes.UPDATE_ORG_ROLE,
    payload: {updateRoleDto}
  };
};

export const updateOrgRoleFulfilled = () => {
  return {
    type: fromActionTypes.UPDATE_ORG_ROLE_FULFILLED,
    payload: {}
  };
};

export const updateOrgRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.UPDATE_ORG_ROLE_REJECTED,
    payload: {error}
  };
};

//DELETE ORG ROLE
export const initDeleteOrgRole = () => {
  return {
    type: fromActionTypes.INIT_DELETE_ORG_ROLE,
    payload: {}
  };
};

export const deleteOrgRole = (roleId, roleName) => {
  return {
    type: fromActionTypes.DELETE_ORG_ROLE,
    payload: {roleId, roleName}
  };
};

export const deleteOrgRoleFulfilled = ({deletedRoleId, deletedRoleName}) => {
  return {
    type: fromActionTypes.DELETE_ORG_ROLE_FULFILLED,
    payload: {deletedRoleId, deletedRoleName}
  };
};

export const deleteOrgRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.DELETE_ORG_ROLE_REJECTED,
    payload: {error}
  };
}

//GET REFERENCES PROFILES
export const getReferencesProfiles = ({ids}) => {
  return {
    type: fromActionTypes.GET_REFERENCES_PROFILES,
    payload: {ids}
  };
};

export const getReferencesProfilesFulfilled = ({referencesProfiles}) => {
  return {
    type: fromActionTypes.GET_REFERENCES_PROFILES_FULFILLED,
    payload: {referencesProfiles}
  };
};

export const getReferencesProfilesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_REFERENCES_PROFILES_REJECTED,
    payload: {error}
  };
};

//GET EXPANDED REFERENCES
export const getExpandedReferences = ({ids}) => {
  return {
    type: fromActionTypes.GET_EXPANDED_REFERENCES,
    payload: {ids}
  };
};

export const getExpandedReferencesFulfilled = ({expandedReferences}) => {
  return {
    type: fromActionTypes.GET_EXPANDED_REFERENCES_FULFILLED,
    payload: {expandedReferences}
  };
};

export const getExpandedReferencesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_EXPANDED_REFERENCES_REJECTED,
    payload: {error}
  };
};
