import * as fromActionTypes from 'store/actionTypes/employeeOverview';

const initialState = {
  employees: null,
  totalCount: 0,
  employee: null,
  employeeError: null,
  loading: false,
  error: null
};


export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.GET_EMPLOYEES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_EMPLOYEES_FULFILLED: {
      const { employees, totalCount } = action.payload;
      return {
        ...state,
        employees,
        totalCount,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_EMPLOYEES_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.INIT_EMPLOYEE: {
      return {
        ...state,
        employee: null,
        employeeError: null
      };
    }
    case fromActionTypes.GET_EMPLOYEE: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_EMPLOYEE_FULFILLED: {
      const { employee } = action.payload;
      return {
        ...state,
        employee,
        loading: false,
        employeeError: null
      };
    }
    case fromActionTypes.GET_EMPLOYEE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        employeeError: error
      };
    }
    default: {
      return state;
    }
  }
};

