import React from 'react';

// INTERMISSIONS

// OTHER COMPONENTS
import { Button } from 'ui/basic';

export const mindfulnessConfig = {
  id: 'mindfulness',
  title: 'Mindfulness',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  loadingPage: {
    render: (state) => (
      <>
        <h4>
          { state.title }
        </h4>
        <br/>
        <br/>
        <br/>
        <Button disabled={true}>
          Start
        </Button>
      </>
    )
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <>
          <h4>
            { state.title }
          </h4>
          <br/>
          <br/>
          <br/>
          <Button
            // disabled={state.questionIndex === state.pages.length - 1}
            onClick={() => {
              next();
            }}
          >
            Start
          </Button>
        </>
      )
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => (
        <h4>End</h4>
      )
    }
  ]
};
