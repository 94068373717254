import React, {useState} from 'react';
import styles from './TeamNineLevelsResult.module.scss';

import * as api from 'api';

// UTILS
import { useTranslate } from 'utils/translator';
import {DIMENSION_BLOCKS, getResistanceText, getSubHeaderLong} from 'utils/nineLevels';
import {NINE_LEVELS_ORDER} from 'utils/configuration/const/assessment-types';

// UI
import NineLevelsDiagram from 'ui/molecules/NineLevelsResult/NineLevelsDiagram';
import {Card, ImgCircle, InfoTitle, Modal, TeamDiagram9LevelsNext} from 'ui/basic';
import {useBreakpoint} from "../../../utils/hooks";

const DEFAULT_INFO_TITLE = 'Türkis';
const DEFAULT_INFO_DESCRIPTION =
  'Der Mensch im Level türkis hat Nachhaltigkeit und Ganzheitlichkeit als richtungs- weisende ' +
  'Maxime seines Handelns. TÜRKIS denkt holistisch-global, ökolo- gisch und intuitiv.' +
  ' Er konzentriert sich auf das Wohlergehen der Welt und richtet sein Leben und ' +
  'Arbeiten danach aus. Durch seine altruistische Haltung kann er' +
  ' sowohl Beobachter als auch Gestalter sein.';

const DEFAULT_COPYRIGHT_TITLE = '© 9 Levels System of Value';
const DEFAULT_COPYRIGHT_DESCRIPTION =
  'All uses of this content are covered by this Copyright Notice, ' +
  'which may not be removed or altered. No modidfications of the content are permitted, ' +
  'including any translations into another language. ' +
  'No rights are granted for use of any portion of this content without the express, ' +
  'written permission obtained in advance from IDS Publishing Corporation, ' +
  'including creation of any derivative work for puoblication such as documents that quote,' +
  ' cite, or are based upon this content , whether for research puposes or otherwise.';


const TeamNineLevelsResult = (props) => {
  // PROPS
  const {profile, referenceProfile, showAverage} = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const [modalHeader, setModalHeader] = useState();
  const [modalHeaderPosition, setModalHeaderPosition] = useState('center');
  const [modalSubHeader, setModalSubHeader] = useState();
  const [modalPreHeader, setModalPreHeader] = useState();
  const [modalContent, setModalContent] = useState();
  const [modalVisible, setModalVisible] = useState();

  // STORE

  // METHODS
  const getDimensionsData = () => {
    if (!profile) {
      return [];
    }

    const nineLevelsDimensions = [];

    profile.results.forEach(nineLevelsResult => {
      if (nineLevelsResult.id.includes('_res_')) {
        return;
      }

      let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results.find(r => r.dimensionId === nineLevelsResult.id);
        if (thisResult) {
          range = [thisResult.minValue, thisResult.maxValue];
        }
      }

      const dimensionId = nineLevelsResult.id.replace('cyan', 'turquoise');
      const dimensionOrder = NINE_LEVELS_ORDER[dimensionId.split('levels_')[1]];
      nineLevelsDimensions[dimensionOrder] = {
        id: dimensionId,
        label: translate(`${dimensionId}_info_title`) || nineLevelsResult.name,
        color: dimensionId.split('levels_')[1],
        range,
        data: nineLevelsResult.values.map(userValue => {
          const {user, value} = userValue;

          // get resistance dimension
          const resDimension = profile.results.find(resultItem => {
            return resultItem.id === nineLevelsResult.id.split('_').join('_res_');
          });

          // get resistance dimension value for given user
          let resDimensionUserValue;
          if (resDimension) {
            resDimensionUserValue = resDimension.values.find(valueItem => {
              return valueItem.user.id === user.id;
            })
          }

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            leftValue: resDimensionUserValue ? resDimensionUserValue.value : 0,
            rightValue: value
          }
        })
      }
    })

    return nineLevelsDimensions;
  };

  return (
    <Card>
      <InfoTitle
        title={translate('9levels_results_title') || '9 Levels Personal Value System'}
        onClick={() => {
          setModalHeader(translate('9levels_results_details_title') || DEFAULT_INFO_TITLE);
          setModalHeaderPosition('left');
          setModalContent(translate('9levels_results_details_description') || DEFAULT_INFO_DESCRIPTION);
          setModalSubHeader(null);
          setModalPreHeader(null);

          setModalVisible(true);
        }}
      />

      <div className={styles.nineLevelsTeamDiagram}>
        <TeamDiagram9LevelsNext
          showAverage={showAverage}
          labelLeft={translate('ninelevels_team_diagram__resistance_lbl') || 'Widerstand'}
          labelLeftAverage={translate('ninelevels_team_diagram__resistance_team_average_lbl') ||
          'Teamdurchschnitt Widerstand'
          }
          labelRight={translate('ninelevels_team_diagram__level_expression_lbl') || 'Level-Ausprägung'}
          labelRightAverage={translate('ninelevels_team_diagram__level_exp_team_average_lbl') ||
          'Teamdurchschnitt Level-Ausprägung'
          }
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: ` || 'Referenzprofil:')}
          onItemClick={(item) => {
            setModalHeader(item.label);
            setModalHeaderPosition('center');
            setModalSubHeader(getSubHeaderLong({
                dimensionName: item.dimensionName,
                dimensionValue: item.rightValue
              },
              item.label
            ));
            setModalPreHeader(
              <ImgCircle
                src={item.img}
                size={'M'}
                label1={item.label.split(' ')[0]}
                label2={item.label.split(' ')[1]}
              />
            );

            setModalContent(
              <div className={styles.nineLevelsModalContent}>
                <div className={styles.dimensionName}>{item.dimensionName}</div>

                {/*DIAGRAM*/}
                <div className={styles.diagram}>
                  <NineLevelsDiagram
                    width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
                    results={[item.leftValue, item.rightValue]}
                    color={item.dimensionColor}
                  />
                  <div className={styles.footer}>
                    <span>{item.leftValue}</span>
                    <span>{item.rightValue}</span>
                  </div>
                </div>

                {/*DESCRIPTION*/}
                <div className={styles.description}>
                  {translate(`${item.dimensionId}_info_description`)}
                </div>

                {/*BLOCKS*/}
                <div className={styles.blocks}>
                  {DIMENSION_BLOCKS.map((block, index) => (
                    <div className={styles.block} key={index}>
                      <div className={styles.label}>
                        {translate(block.label) || block.altLabel}
                      </div>
                      <div className={styles.description}>
                        {translate(block.description.replace('*', item.dimensionId.split('_')[1])) ||
                        block.altDescription
                        }
                      </div>
                    </div>
                  ))}

                  <div className={styles.block}>
                    <div className={styles.label}>
                      {translate('9levels_report__level__resistance') || 'Widerstand'}
                    </div>
                    <div className={styles.description}>
                      {translate(getResistanceText(item.leftValue), [
                        '{{level}}', translate(`levels_results_label_${item.dimensionId.split('levels_')[1]}`),
                        '{{points}}', item.leftValue
                      ]) ||
                      'Dein Widerstand gegenüber PURPUR ist mit 41 Punkten mittel.'}
                    </div>
                  </div>

                </div>
              </div>
            );

            setModalVisible(true);
          }}
        />

        <div
          className={styles.copyright}
          onClick={() => {
            setModalHeader(translate('9levels_ass_info_title_copyright') || DEFAULT_COPYRIGHT_TITLE);
            setModalHeaderPosition('left');
            setModalContent(translate('9levels_ass_info_description_copyright') || DEFAULT_COPYRIGHT_DESCRIPTION);

            setModalSubHeader(null);
            setModalPreHeader(null);

            setModalVisible(true);
          }}
        >
          {translate('9levels_ass_copyrightlink') || '© 9 Levels of Value Systems'}
        </div>
      </div>

      {modalVisible &&
      <Modal
        header={modalHeader}
        headerPosition={modalHeaderPosition}
        subHeader={modalSubHeader}
        subHeaderPosition={'center'}
        preHeader={modalPreHeader}
        secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
        onClose={() => setModalVisible(false)}
        onConfirm={() => setModalVisible(false)}
      >
        {modalContent}
      </Modal>
      }
    </Card>
  )
}

export default TeamNineLevelsResult;
