import {call, put, takeEvery} from 'redux-saga/effects';
import * as fromActionTypes from 'store/actionTypes/bqActions';
import * as fromActions from 'store/actions/bqActions';
import {get, post} from 'api';

function* getAction(payload) {
  try {
    const { status, ok, data } = yield call(get, `core/action/${payload.actionId}`);

    if (ok && status === 200) {
      yield put(fromActions.getActionFulfilled({ actionInfo: data}));
    } else {
      yield put(fromActions.getActionRejected({ error: data.error }));
    }
  } catch(error) {
    yield put(fromActions.getActionRejected({ error }));
  }
}

function* executeAction({payload}) {
  try {
    const {executeActionDto} = payload;

    const { status, ok, data } = yield call(post, `core/action/${executeActionDto.actionId}`, executeActionDto.params);

    if (ok && status === 200) {
      yield put(fromActions.executeActionFulfilled({ executedActionInfo: data}));
    } else {
      yield put(fromActions.executeActionRejected({ error: data.error }));
    }
  } catch(error) {
    yield put(fromActions.executeActionRejected({ error }));
  }
}

export function* watchGetAction() {
  yield takeEvery(fromActionTypes.GET_ACTION, getAction);
}

export function* watchExecuteAction() {
  yield takeEvery(fromActionTypes.EXECUTE_ACTION, executeAction);
}
