// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Pill.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classnames from 'classnames';

// OTHER COMPONENTS

// UTILS

// CONFIG & DATA

// COMPONENT: Pill
const Pill = (props) => {
  // PROPS
  const { children, active, looks, onClick } = props;

  // COMPONENT/UI STATE and REFS

  // STORE HOOKS

  // EFFECT HOOKS

  // OTHER HOOKS

  // METHODS

  // HELPERS, HANDLES, RENDERS

  // RENDER: Pill
  const pillProps = {
    onClick
  };
  return (
    <div className={`${classnames(styles.pill, styles[looks], {
      [styles.active]: active
    })}`}
    {...pillProps}>
      { children }
    </div>
  );
};

export default Pill;
