// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from "react";
import styles from "./InputFile.module.scss";

// ASSETS
import { IconsSvg } from "assets/icons";

// 3RD PARTY
import classNames from "classnames";

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from "utils/translator";

// STORE

// CONFIG & DATA
// const Config = {};
const MB = 1024 * 1024;

// COMPONENT: InputFile
const InputFile = props => {
  // PROPS
  const {
    label = '',
    maxSizeMB = 5,
    allowedTypes = [],
    initialFileName,
    disabled,
    error = '',
    inputRef,
    onChange = () => {},
    onValid = () => {},
    onInvalid = () => {}
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  const [validationErrors, setValidationErrors] = useState({});
  const [selectedFileName, setSelectedFileName] = useState(
    initialFileName || null
  );
  useEffect(() => {
    setSelectedFileName(initialFileName);
  }, [initialFileName]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  function validate(file) {
    const errors = {};

    const suffix = file.name
      .split(".")
      .pop()
      .toLowerCase();

    if (!file) {
      errors.required = true;
    }

    if (file.size > maxSizeMB * MB) {
      errors.size = true;
    }

    if (allowedTypes.length !== 0 && allowedTypes.includes(suffix) === false) {
      errors.type = true;
    }

    if (Object.keys(errors).length === 0) {
      setValidationErrors({});
      return true;
    }

    setValidationErrors(errors);
  }

  function handleOnChange(e) {
    const file = e.target.files[0];
    const isValid = validate(file);

    if (isValid) {
      onChange(file);
      onValid(file);
      setSelectedFileName(file.name);
    } else {
      onInvalid();
      setSelectedFileName(null);
    }
  }

  // RENDER: InputFile
  return (
    <div
      className={classNames(
        styles.inputFile,
        !selectedFileName ? styles.unselected : styles.selected,
        disabled === true ? styles.disabled : undefined
      )}
    >
      <label>
        <input
          type="file"
          ref={inputRef}
          onChange={handleOnChange}
          disabled={disabled}
        />

        {!selectedFileName && (
          <span>
            <IconsSvg.Upload /> {label}
            {/* Dokument auswählen */}
          </span>
        )}

        {selectedFileName && (
          <div className={styles.inputAlt}>
            <IconsSvg.Document />
            <span>{selectedFileName}</span>
            <IconsSvg.Edit />
          </div>
        )}
      </label>

      { error && (
        <div className={styles.error}>
          { error }
        </div>
      )}

      {/* not sure if this is needed, since you can not not select a file */}
      {validationErrors.required && (
        <div className={styles.error}>
          {/* Wird benötigt. */}
          {translate("input_file_required")}
        </div>
      )}

      {validationErrors.size && (
        <div className={styles.error}>
          {/* Die Datei übersteigt die zulässige Größe von */}
          {translate("input_file_max_size")} {maxSizeMB} MB.
        </div>
      )}

      {validationErrors.type && (
        <div className={styles.error}>
          {/* Die Datei hat ein ungültiges Format. Aus Sicherheitsgründen
          unterstützen wir nur die o.g. Dateiformate. */}
          {translate("input_file_wrong_type")}
        </div>
      )}
    </div>
  );
};

export default InputFile;
