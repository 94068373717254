// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useState} from 'react';
import styles from './GarminOrgResult.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {Button, Card, CollapsiblePanel, InfoTitle, Modal} from 'ui/basic';
import GarminIcon from 'features/+garmin/ui/basic/GarminIcon';
import GarminBarDiagram from 'features/+garmin/ui/basic/GarminBarDiagram';
import GarminOrgReport from '../GarminOrgReport/GarminOrgReport';

// UTILS
import {useTranslate} from 'utils/translator/translator';
import {isValid} from 'utils/numbers';
import {timestampToFullDate} from 'utils/dateTools';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: GarminOrgResult
const GarminOrgResult = (props) => {
  // PROPS
  const { result, history, showPendingState } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [infoModalVisible, setInfoModalVisible] = useState();

  const [reportVisible, setReportVisible] = useState();

  // BODY BATTERY: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [bodyBatteryDistribution, setBodyBatteryDistribution] = useState();
  const [bodyBatteryVeryGoodPercentage, setBodyBatteryVeryGoodPercentage] = useState();
  const [bodyBatteryGoodPercentage, setBodyBatteryGoodPercentage] = useState();
  const [bodyBatteryBadPercentage, setBodyBatteryBadPercentage] = useState();
  const [bodyBatteryVeryBadPercentage, setBodyBatteryVeryBadPercentage] = useState();
  useEffect(() => {
    if (!result || !result.bodyBattery || !result.bodyBattery.score || !result.bodyBattery.score.distribution) {
      return;
    }
    const distribution = result.bodyBattery.score.distribution;
    const totalNumber = distribution.good + distribution['medium-good'] + distribution['medium-bad'] + distribution.bad;

    setBodyBatteryGoodPercentage(Math.round(distribution['medium-good'] * 100 / totalNumber));
    setBodyBatteryVeryGoodPercentage(Math.round(distribution.good * 100 / totalNumber));
    setBodyBatteryBadPercentage(Math.round(distribution['medium-bad'] * 100 / totalNumber));
    setBodyBatteryVeryBadPercentage(Math.round(distribution.bad * 100 / totalNumber));

    setBodyBatteryDistribution(distribution);
    }, [result]);

  const [bodyBatteryPrevWeekVeryGoodPercentage, setBodyBatteryPrevWeekVeryGoodPercentage] = useState();
  const [bodyBatteryPrevWeekGoodPercentage, setBodyBatteryPrevWeekGoodPercentage] = useState();
  const [bodyBatteryPrevWeekBadPercentage, setBodyBatteryPrevWeekBadPercentage] = useState();
  const [bodyBatteryPrevWeekVeryBadPercentage, setBodyBatteryPrevWeekVeryBadPercentage] = useState();
  useEffect(() => {
    if (!history || !history.bodyBattery || history.bodyBattery.length < 2) {
      return;
    }

    const bodyBattery = history.bodyBattery[1];

    const now = new Date().getTime();
    const daysDiff = (now - (bodyBattery.timestamp * 1000)) / 1000 / 60 / 60 / 24;

    const distribution = bodyBattery.score.distribution;
    const totalNumber = distribution.good + distribution['medium-good'] + distribution['medium-bad'] + distribution.bad;

    if (daysDiff > 7 && daysDiff < 14) {
      setBodyBatteryPrevWeekVeryGoodPercentage(Math.round(distribution.good * 100 / totalNumber));
      setBodyBatteryPrevWeekGoodPercentage(Math.round(distribution['medium-good'] * 100 / totalNumber));
      setBodyBatteryPrevWeekBadPercentage(Math.round(distribution['medium-bad'] * 100 / totalNumber));
      setBodyBatteryPrevWeekVeryBadPercentage(Math.round(distribution.bad * 100 / totalNumber));
    }
  }, [history]);


  // SLEEP: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [sleepDistribution, setSleepDistribution] = useState();
  const [sleepVeryGoodPercentage, setSleepVeryGoodPercentage] = useState();
  const [sleepGoodPercentage, setSleepGoodPercentage] = useState();
  const [sleepBadPercentage, setSleepBadPercentage] = useState();
  const [sleepVeryBadPercentage, setSleepVeryBadPercentage] = useState();
  useEffect(() => {
    if (!result || !result.sleep || !result.sleep.score || !result.sleep.score.distribution) {
      return;
    }
    const distribution = result.sleep.score.distribution;
    const totalNumber = distribution.good + distribution['medium-good'] + distribution['medium-bad'] + distribution.bad;

    setSleepGoodPercentage(Math.round(distribution['medium-good'] * 100 / totalNumber));
    setSleepVeryGoodPercentage(Math.round(distribution.good * 100 / totalNumber));
    setSleepBadPercentage(Math.round(distribution['medium-bad'] * 100 / totalNumber));
    setSleepVeryBadPercentage(Math.round(distribution.bad * 100 / totalNumber));

    setSleepDistribution(distribution);
  }, [result]);

  const [sleepPrevWeekVeryGoodPercentage, setSleepPrevWeekVeryGoodPercentage] = useState();
  const [sleepPrevWeekGoodPercentage, setSleepPrevWeekGoodPercentage] = useState();
  const [sleepPrevWeekBadPercentage, setSleepPrevWeekBadPercentage] = useState();
  const [sleepPrevWeekVeryBadPercentage, setSleepPrevWeekVeryBadPercentage] = useState();
  useEffect(() => {
    if (!history || !history.sleep || history.sleep.length < 2) {
      return;
    }

    const sleep = history.sleep[1];

    const now = new Date().getTime();
    const daysDiff = (now - (sleep.timestamp * 1000)) / 1000 / 60 / 60 / 24;

    const distribution = sleep.score.distribution;
    const totalNumber = distribution.good + distribution['medium-good'] + distribution['medium-bad'] + distribution.bad;

    if (daysDiff > 7 && daysDiff < 14) {
      setSleepPrevWeekVeryGoodPercentage(Math.round(distribution.good * 100 / totalNumber));
      setSleepPrevWeekGoodPercentage(Math.round(distribution['medium-good'] * 100 / totalNumber));
      setSleepPrevWeekBadPercentage(Math.round(distribution['medium-bad'] * 100 / totalNumber));
      setSleepPrevWeekVeryBadPercentage(Math.round(distribution.bad * 100 / totalNumber));
    }
  }, [history]);


  // STRESS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [stressDistribution, setStressDistribution] = useState();
  const [stressVeryGoodPercentage, setStressVeryGoodPercentage] = useState();
  const [stressGoodPercentage, setStressGoodPercentage] = useState();
  const [stressBadPercentage, setStressBadPercentage] = useState();
  const [stressVeryBadPercentage, setStressVeryBadPercentage] = useState();
  useEffect(() => {
    if (!result || !result.stress || !result.stress.score || !result.stress.score.distribution) {
      return;
    }
    const distribution = result.stress.score.distribution;
    const totalNumber = distribution.good + distribution['medium-good'] + distribution['medium-bad'] + distribution.bad;

    setStressGoodPercentage(Math.round(distribution['medium-good'] * 100 / totalNumber));
    setStressVeryGoodPercentage(Math.round(distribution.good * 100 / totalNumber));
    setStressBadPercentage(Math.round(distribution['medium-bad'] * 100 / totalNumber));
    setStressVeryBadPercentage(Math.round(distribution.bad * 100 / totalNumber));

    setStressDistribution(distribution);
  }, [result]);

  const [stressPrevWeekVeryGoodPercentage, setStressPrevWeekVeryGoodPercentage] = useState();
  const [stressPrevWeekGoodPercentage, setStressPrevWeekGoodPercentage] = useState();
  const [stressPrevWeekBadPercentage, setStressPrevWeekBadPercentage] = useState();
  const [stressPrevWeekVeryBadPercentage, setStressPrevWeekVeryBadPercentage] = useState();
  useEffect(() => {
    if (!history || !history.stress || history.stress.length < 2) {
      return;
    }

    const stress = history.stress[1];

    const now = new Date().getTime();
    const daysDiff = (now - (stress.timestamp * 1000)) / 1000 / 60 / 60 / 24;

    const distribution = stress.score.distribution;
    const totalNumber = distribution.good + distribution['medium-good'] + distribution['medium-bad'] + distribution.bad;

    if (daysDiff > 7 && daysDiff < 14) {
      setStressPrevWeekVeryGoodPercentage(Math.round(distribution.good * 100 / totalNumber));
      setStressPrevWeekGoodPercentage(Math.round(distribution['medium-good'] * 100 / totalNumber));
      setStressPrevWeekBadPercentage(Math.round(distribution['medium-bad'] * 100 / totalNumber));
      setStressPrevWeekVeryBadPercentage(Math.round(distribution.bad * 100 / totalNumber));
    }
  }, [history]);


  // RENDER: Bar diagram footer text
  const renderFooterText = (currentPercentage, prevWeekPercentage, employeesNumber) => {
    let footerText = `${currentPercentage} % (${employeesNumber} ${translate('employees_lbl')})`;

    if (isValid(prevWeekPercentage)) {
      const diff = currentPercentage - prevWeekPercentage;
      const prefix = diff >= 0 ? '+' : '-';
      footerText = `${footerText}   ${prefix}${Math.abs(diff)} % ${translate('garmin_comparison_text')}`;
    }

    return footerText;
  }

  // RENDER: Skeleton
  if (!showPendingState && !result) {
    return (
      <div className={styles.garminOrgResultSkeleton}>
        <Card>
          <div className={styles.header}></div>
          <div className={styles.subHeader}></div>

          {new Array(6).fill(1).map((value, index) => (
            <div className={styles.bars} key={index}>
              <div></div>
              <div></div>
            </div>
          ))}
        </Card>
      </div>
    )
  }

  // RENDER: GarminOrgResult
  return (
    <div className={classNames(styles.garminOrgResult)}>
      <Card hasNoPaddings>
        {/*IF NO RESULT*/}
        {(showPendingState && !result) &&
          <div className={styles.blockWithPaddings}>
            <InfoTitle
              title={translate('garmin_connect')}
              className={styles.infoTitle}
              onClick={() => setInfoModalVisible(true)}
            />
            <div className={classNames('blu-typeCopy', 'marginTopS')}>
              {translate('garmin_org_result_pending_copy')}
            </div>
          </div>
        }

        {result &&
          <>
            <div className={styles.blockWithPaddings}>
              <InfoTitle
                title={translate('garmin_connect')}
                className={styles.infoTitle}
                onClick={() => setInfoModalVisible(true)}
              />

              <div className={styles.energyBlock}>
                <div className={styles.energyHeader}>
                  <GarminIcon looks='body-battery' size='S' />
                  <span>{translate('org_battery_trend')}</span>
                </div>

                <div className={styles.energyContent}>
                  {/*LEFT*/}
                  <div className={styles.left}>
                    <div>
                      {translate('body_battery_cohort_summary', ['{{value}}', bodyBatteryBadPercentage + bodyBatteryVeryBadPercentage])}
                    </div>
                    <Button
                      size='S'
                      looks='secondary'
                      onClick={() => setReportVisible(true)}
                    >{translate('learn_more')}</Button>
                  </div>

                  {/*RIGHT*/}
                  <div className={styles.right}>
                    <GarminBarDiagram
                      looks={'blue'}
                      value={bodyBatteryVeryGoodPercentage}
                      footerText={renderFooterText(
                        bodyBatteryVeryGoodPercentage,
                        bodyBatteryPrevWeekVeryGoodPercentage,
                        bodyBatteryDistribution ? bodyBatteryDistribution.good : 0
                      )}
                    />
                    <GarminBarDiagram
                      looks={'lightBlue'}
                      value={bodyBatteryGoodPercentage}
                      footerText={renderFooterText(
                        bodyBatteryGoodPercentage,
                        bodyBatteryPrevWeekGoodPercentage,
                        bodyBatteryDistribution ? bodyBatteryDistribution['medium-good'] : 0
                      )}
                    />
                    <GarminBarDiagram
                      looks={'lightRed'}
                      value={bodyBatteryBadPercentage}
                      footerText={renderFooterText(
                        bodyBatteryBadPercentage,
                        bodyBatteryPrevWeekBadPercentage,
                        bodyBatteryDistribution ? bodyBatteryDistribution['medium-bad'] : 0
                      )}
                    />
                    <GarminBarDiagram
                      looks={'red'}
                      value={bodyBatteryVeryBadPercentage}
                      footerText={renderFooterText(
                        bodyBatteryVeryBadPercentage,
                        bodyBatteryPrevWeekVeryBadPercentage,
                        bodyBatteryDistribution ? bodyBatteryDistribution.bad : 0
                      )}
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className={styles.collapsiblePanels}>
              {/*Sleep*/}
              <CollapsiblePanel
                header={(
                  <div className={styles.collapsibleInnerHeader}>
                    <GarminIcon
                      looks='sleep'
                      size='S'
                    />
                    <span className='blu-typeLabel'>{translate('sleep_score')}</span>
                  </div>
                )}
                headerClassName={styles.collapsibleHeader}
                showTopBorder={false}
              >
                <>
                  {!sleepDistribution &&
                    <div className={styles.noValueCopy}>
                      {translate('garmin_org_result_pending_copy')}
                    </div>
                  }

                  {sleepDistribution &&
                    <div className={styles.energyContent}>
                      {/*LEFT*/}
                      <div className={styles.left}>
                        <div>
                          {translate('sleep_cohort_summary', ['{{value}}', sleepBadPercentage + sleepVeryBadPercentage])}
                        </div>
                      </div>

                      {/*RIGHT*/}
                      <div className={styles.right}>
                        <GarminBarDiagram
                          looks={'blue'}
                          value={sleepVeryGoodPercentage}
                          footerText={renderFooterText(
                            sleepVeryGoodPercentage,
                            sleepPrevWeekVeryGoodPercentage,
                            sleepDistribution ? sleepDistribution.good : 0
                          )}
                        />
                        <GarminBarDiagram
                          looks={'lightBlue'}
                          value={sleepGoodPercentage}
                          footerText={renderFooterText(
                            sleepGoodPercentage,
                            sleepPrevWeekGoodPercentage,
                            sleepDistribution ? sleepDistribution['medium-good'] : 0
                          )}
                        />
                        <GarminBarDiagram
                          looks={'lightRed'}
                          value={sleepBadPercentage}
                          footerText={renderFooterText(
                            sleepBadPercentage,
                            sleepPrevWeekBadPercentage,
                            sleepDistribution ? sleepDistribution['medium-bad'] : 0
                          )}
                        />
                        <GarminBarDiagram
                          looks={'red'}
                          value={sleepVeryBadPercentage}
                          footerText={renderFooterText(
                            sleepVeryBadPercentage,
                            sleepPrevWeekVeryBadPercentage,
                            sleepDistribution ? sleepDistribution.bad : 0
                          )}
                        />
                      </div>
                    </div>
                  }
                </>
              </CollapsiblePanel>

              {/*Stress*/}
              <CollapsiblePanel
                header={(
                  <div className={styles.collapsibleInnerHeader}>
                    <GarminIcon
                      looks='stress'
                      size='S'
                    />
                    <span className='blu-typeLabel'>{translate('day_stress')}</span>
                  </div>
                )}
                headerClassName={styles.collapsibleHeader}
                showTopBorder={false}
              >
                <>
                  {!stressDistribution &&
                    <div className={styles.noValueCopy}>{translate('garmin_org_result_pending_copy')}</div>
                  }

                  {stressDistribution &&
                    <div className={styles.energyContent}>
                      {/*LEFT*/}
                      <div className={styles.left}>
                        <div>
                          {translate('stress_cohort_summary', ['{{value}}', stressBadPercentage + stressVeryBadPercentage])}
                        </div>
                      </div>

                      {/*RIGHT*/}
                      <div className={styles.right}>
                        <GarminBarDiagram
                          looks={'blue'}
                          value={stressVeryGoodPercentage}
                          footerText={renderFooterText(
                            stressVeryGoodPercentage,
                            stressPrevWeekVeryGoodPercentage,
                            stressDistribution ? stressDistribution.good : 0
                          )}
                        />
                        <GarminBarDiagram
                          looks={'lightBlue'}
                          value={stressGoodPercentage}
                          footerText={renderFooterText(
                            stressGoodPercentage,
                            stressPrevWeekGoodPercentage,
                            stressDistribution ? stressDistribution['medium-good'] : 0
                          )}
                        />
                        <GarminBarDiagram
                          looks={'lightRed'}
                          value={stressBadPercentage}
                          footerText={renderFooterText(
                            stressBadPercentage,
                            stressPrevWeekBadPercentage,
                            stressDistribution ? stressDistribution['medium-bad'] : 0
                          )}
                        />
                        <GarminBarDiagram
                          looks={'red'}
                          value={stressVeryBadPercentage}
                          footerText={renderFooterText(
                            stressVeryBadPercentage,
                            stressPrevWeekVeryBadPercentage,
                            stressDistribution ? stressDistribution.bad : 0
                          )}
                        />
                      </div>
                    </div>
                  }
                </>
              </CollapsiblePanel>
            </div>

            {(result.bodyBattery && result.bodyBattery.score && result.bodyBattery.score.timestamp) &&
              <div className={styles.date}>
                {translate('garmin_org_result_date', ['{{date}}', timestampToFullDate(result.bodyBattery.score.timestamp)])}
              </div>
            }
          </>
        }
      </Card>

      {/*INFO MODAL*/}
      {infoModalVisible &&
        <Modal
          header={translate('garmin_connect_modal_title')}
          secondaryButtonTitle={translate('ok')}
          onClose={() => setInfoModalVisible(false)}
        >
          {translate('garmin_connect_modal_copy')}
        </Modal>
      }

      {/*FULL PAGE REPORT MODAL*/}
      {reportVisible &&
        <GarminOrgReport
          result={result}
          history={history}
          onClose={() => setReportVisible(false)}
        />
      }
    </div>
  );
};

export default GarminOrgResult;
