// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useState} from 'react';
import styles from './CandidateExternalProfile.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import CandidateExternalSummary from '../CandidateExternalSummary';
import UserAssessmentsResults from '../UserAssessmentsResults';
import CandidateExternalRoleFit from '../CandidateExternalRoleFit';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';
import {getMappedCandidate} from 'utils/users';

// STORE
import * as api from 'api';

// CONFIG & DATA
// const Config = {};

const PAGES = [
  {value: 'summary', label: 'candidate_summary'},
  {value: 'assessments', label: 'candidate_assessments'},
  {value: 'rolefit', label: 'candidate_rolefit'}
];

// COMPONENT: CandidateProfile
const CandidateExternalProfile = (props) => {
  // PROPS
  const { id, token, results } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [currentPage, setCurrentPage] = useState(PAGES[0].value);

  // CANDIDATE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [candidate, setCandidate] = useState();
  useEffect(() => {
    api.get(`recruiting/candidates/${id}`, {expand: 'jobs'}, {Authorization: `Bearer ${token}`})
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          setCandidate(getMappedCandidate(data));
        }
      })
  }, [id, token])

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const renderPage = () => {
    const thisCandidate = candidate;
    if (thisCandidate.isAnonymous) {
      thisCandidate.firstName = undefined;
      thisCandidate.lastName = undefined;
      thisCandidate.name = translate('candidate_lbl');
    }

    switch (currentPage) {
      case 'summary':
        return <CandidateExternalSummary candidate={thisCandidate} token={token}/>
      case 'assessments':
        return (
          <div className={styles.assessmentsResults}>
            <UserAssessmentsResults
              user={thisCandidate}
              isCandidate={thisCandidate && thisCandidate.candidateData}
              results={results}
            />
          </div>
        )
      case 'rolefit':
        return <CandidateExternalRoleFit candidate={thisCandidate} token={token} results={results}/>;
      default:
    }
  }

  if (!candidate) {
    return null;
  }

  // RENDER: CandidateProfile
  return (
    <div className={classNames(styles.candidateExternalProfile)}>
      {/*NAVIGATION*/}
      <div className={styles.navigation}>
        {PAGES
          .filter(page => {
            if (page.value === 'rolefit' &&
              (!candidate.jobs || candidate.jobs.length === 0 || !candidate.jobs.find(job => job.incomplete === false))
            ) {
              return false;
            }
            return true;
          })
          .map((page, index) => (
          <div
            key={index}
            className={classNames(styles.item, {[styles.active]: currentPage === page.value})}
            onClick={() => setCurrentPage(page.value)}
          >{translate(page.label)}</div>
        ))}
      </div>

      {renderPage()}
    </div>
  );
};

export default CandidateExternalProfile;
