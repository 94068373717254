import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import { createPortal } from 'react-dom';
import styles from './NineLevelsResult.module.scss';

import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';

// ASSETS
import {IconsSvg} from 'assets/icons';

// UTILS
import { useTranslate } from 'utils/translator';
import {useBreakpoint} from 'utils/hooks';
import {DIMENSION_BLOCKS, getResistanceText, getSubHeaderShort} from 'utils/nineLevels';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

import {initSubmitContentFeedback, submitContentFeedback} from 'store/actions';

import {ReactComponent as InfoBlue} from 'assets/icons/icn_info_blue.svg';

import NineLevelsDiagram from './NineLevelsDiagram';
import RmpResultSkeleton from 'ui/molecules/RmpResult/RmpResultSkeleton';
import {InfoTitle, Button, Modal} from 'ui/basic';
import NineLevelsReport from './NineLevelsReport';

import {LayoutContext} from 'features/framework/components/MainLayout/MainLayout';


const DEFAULT_INFO_TITLE = 'Türkis';
const DEFAULT_INFO_DESCRIPTION =
  'Der Mensch im Level türkis hat Nachhaltigkeit und Ganzheitlichkeit als richtungs- weisende ' +
  'Maxime seines Handelns. TÜRKIS denkt holistisch-global, ökolo- gisch und intuitiv.' +
  ' Er konzentriert sich auf das Wohlergehen der Welt und richtet sein Leben und ' +
  'Arbeiten danach aus. Durch seine altruistische Haltung kann er' +
  ' sowohl Beobachter als auch Gestalter sein.';

const DEFAULT_COPYRIGHT_TITLE = '© 9 Levels System of Value';
const DEFAULT_COPYRIGHT_DESCRIPTION =
  'All uses of this content are covered by this Copyright Notice, ' +
  'which may not be removed or altered. No modidfications of the content are permitted, ' +
  'including any translations into another language. ' +
  'No rights are granted for use of any portion of this content without the express, ' +
  'written permission obtained in advance from IDS Publishing Corporation, ' +
  'including creation of any derivative work for puoblication such as documents that quote,' +
  ' cite, or are based upon this content , whether for research puposes or otherwise.';


const NineLevelsResult = (props) => {
  const {
    results = [],
    signatureValues,
    forReport,
    showReportButton = true,
    requestFeedback = true,
    footerText,
    noDataText,
    showExportIcon,
    exportView,
    onExport = () => {}
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const ref = useRef();
  const bp = useBreakpoint();

  const DownloadIcon = IconsSvg.DownloadSvg;

  const { mainNavIsOpenDebounced } = useContext(LayoutContext) || {};

  // STATE
  const [width, setWidth] = useState();

  const [currentDimensionId, setCurrentDimensionId] = useState();

  const [showModal, setShowModal] = useState();
  const [modalHeader, setModalHeader] = useState();
  const [modalSubHeader, setModalSubHeader] = useState();
  const [modalScrollable, setModalScrollable] = useState();
  const [modalContent, setModalContent] = useState();
  const [modalPrimaryButtonTitle, setModalPrimaryButtonTitle] = useState();
  const [showModalFeedback, setShowModalFeedBack] = useState();

  const [showReport, setShowReport] = useState(false);

  const showRange = results && results[0] && Array.isArray(results[0].range);

  // SELECTORS
  const nineLevelsAssessment = useSelector(state => state.assessments && state.assessments[ASSESSMENT_TYPES.NINE_LEVELS]);
  const feedbackSubmittedSuccessfully = useSelector(state => state.survey && state.survey.success);

  // METHODS

  const handleResize = useCallback(() => {
    const rowsRef = ref.current;
    if (rowsRef) {
      setWidth(rowsRef.clientWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 500)
  }, [mainNavIsOpenDebounced, handleResize, results]);

  if (!results && (nineLevelsAssessment && nineLevelsAssessment.resultsPending === undefined)) {
    return <RmpResultSkeleton/>
  }

  return (
    <div className={classNames(styles.nineLevelsResult, {[styles.forReport]: forReport})}>
      <div id={'container-id'} className={styles.container}>
        <div className={styles.mainHeader}>
          {exportView ?
            <span className='blu-typeLabel'>{translate('9levels_results_title') || '9 Levels Personal Value System'}</span> :
            <InfoTitle
              title={translate('9levels_results_title') || '9 Levels Personal Value System'}
              onClick={() => {
                setModalHeader(translate('9levels_results_details_title') || DEFAULT_INFO_TITLE);
                setModalContent(translate('9levels_results_details_description') || DEFAULT_INFO_DESCRIPTION);
                setModalSubHeader(null);
                setShowModalFeedBack(false);
                setModalScrollable(false);
                setModalPrimaryButtonTitle(null);

                setShowModal(true);
              }}
            />
          }

          {(!bp.isXs && !exportView && showExportIcon && !nineLevelsAssessment.resultsPending && !noDataText) &&
          <DownloadIcon onClick={onExport}/>
          }
        </div>

        {noDataText && <div className={styles.noDataText}>{noDataText}</div>}

        {!showRange && (nineLevelsAssessment && nineLevelsAssessment.resultsPending) &&
        <div className={styles.pendingText}>
          {translate('rmp_results_pending_copy') ||
          'Die Ergebnisse werden berechnet und stehen in Kürze zur Verfügung.'
          }
        </div>
        }

        {!noDataText &&
        <>
          <div
            className={styles.rows}
            ref={ref}
          >
            {results.map((resultItem, index) => {
              return (
                <div className={styles.row} key={index}>
                  <div className={styles.header}>
                    {translate(`levels_results_label_${resultItem.id.split('_').pop()}`) || resultItem.name}

                    {!exportView &&
                    <InfoBlue onClick={() => {
                      if (!showReportButton) {
                        setModalHeader(translate(`${resultItem.id}_info_title`) || DEFAULT_INFO_TITLE);
                        setModalContent(translate(`${resultItem.id}_info_description`) || DEFAULT_INFO_DESCRIPTION);

                        setShowModal(true);
                        return;
                      }

                      const dimensionName = translate(`levels_results_label_${resultItem.id.split('_').pop()}`) ||
                        resultItem.name;
                      const color = resultItem.id.split('levels_')[1];

                      setCurrentDimensionId(resultItem.id);

                      setModalHeader(
                        <div className={styles.modalHeaderContent}>
                          {/* colored block on the left */}
                          <div style={{
                            backgroundColor: styles[`color${color.substring(0,1).toUpperCase() + color.substring(1, color.length)}1`]
                          }}></div>
                          <span>{translate(`${resultItem.id}_info_title`) || DEFAULT_INFO_TITLE}</span>
                        </div>
                      );
                      setModalSubHeader(getSubHeaderShort(dimensionName, resultItem.result ? resultItem.result[1] : 0));
                      setModalPrimaryButtonTitle(translate('go_to_report_lbl') || 'Zum Report');
                      setModalScrollable(true);
                      setShowModalFeedBack(true);

                      setModalContent(
                        <div className={styles.modalContent}>
                          <div className={styles.dimensionName}>{dimensionName}</div>

                          {/*DIAGRAM*/}
                          <div className={styles.diagram}>
                            <NineLevelsDiagram
                              width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
                              results={resultItem.result}
                              color={resultItem.id.split('levels_')[1]}
                            />
                            <div className={styles.footer}>
                              <span>
                                {(resultItem.result && resultItem.range) ?
                                  resultItem.result[0] :
                                  (resultItem.result ? resultItem.result[0] : resultItem.range[0])
                                }
                              </span>
                              <span>
                                {(resultItem.result && resultItem.range) ?
                                  resultItem.result[1] :
                                  (resultItem.result ? resultItem.result[1] : resultItem.range[1])
                                }
                              </span>
                            </div>
                          </div>

                          {/*DESCRIPTION*/}
                          <div className={styles.description}>
                            {translate(`${resultItem.id}_info_description`) || DEFAULT_INFO_DESCRIPTION}
                          </div>

                          {/*BLOCKS*/}
                          <div className={styles.blocks}>
                            {DIMENSION_BLOCKS.map((block, index) => (
                              <div className={styles.block} key={index}>
                                <div className={styles.label}>
                                  {translate(block.label) || block.altLabel}
                                </div>
                                <div className={styles.description}>
                                  {translate(block.description.replace('*', resultItem.id.split('_').pop())) ||
                                  block.altDescription
                                  }
                                </div>
                              </div>
                            ))}

                            <div className={styles.block}>
                              <div className={styles.label}>
                                {translate('9levels_report__level__resistance') || 'Widerstand'}
                              </div>
                              <div className={styles.description}>
                                {translate(getResistanceText(resultItem.result[0]), [
                                  '{{level}}', translate(`levels_results_label_${resultItem.id.split('_').pop()}`),
                                  '{{points}}', resultItem.result[0]
                                ]) ||
                                'Dein Widerstand gegenüber PURPUR ist mit 41 Punkten mittel.'}
                              </div>
                            </div>

                          </div>
                        </div>
                      );

                      setShowModal(true);
                    }}
                    />
                    }
                  </div>

                  <NineLevelsDiagram
                    width={width}
                    range={resultItem.range}
                    results={resultItem.result}
                    color={resultItem.id.split('levels_')[1]}
                  />

                  <div className={styles.footer}>
                    {(resultItem.range && !resultItem.result) ?
                      <span style={{marginLeft: 'auto'}}>
                        {`${resultItem.range[0]} ${translate('to_lbl') || 'to'} ${resultItem.range[1]}`}
                      </span> :

                      <>
                        <span>
                          {(resultItem.result && resultItem.range) ?
                            resultItem.result[0] :
                            (resultItem.result ? resultItem.result[0] : (resultItem.range && resultItem.range[0]))
                          }
                        </span>
                        <span>
                          {(resultItem.result && resultItem.range) ?
                            resultItem.result[1] :
                            (resultItem.result ? resultItem.result[1] : (resultItem.range && resultItem.range[1]))
                          }
                        </span>
                      </>
                    }
                  </div>
                </div>
              )
            })}
          </div>

          {(!showRange && showReportButton && (nineLevelsAssessment && !nineLevelsAssessment.resultsPending) && !exportView) &&
          <div className={styles.showReport}>
            <Button
              size={'S'}
              looks={'secondary'}
              onClick={() => setShowReport(true)}
            >
              {translate('9levels_results_show_report_btn') || 'Report ansehen'}
            </Button>
          </div>
          }

          {footerText && <div className={styles.footerText}>{footerText}</div>}

          <div
            className={styles.copyright}
            onClick={() => {
              setModalHeader(translate('9levels_ass_info_title_copyright') || DEFAULT_COPYRIGHT_TITLE);
              setModalContent(translate('9levels_ass_info_description_copyright') || DEFAULT_COPYRIGHT_DESCRIPTION);
              setModalSubHeader(null);
              setModalScrollable(false);
              setShowModalFeedBack(false);
              setModalPrimaryButtonTitle(null);

              setShowModal(true);
            }}
          >
            {translate('9levels_ass_copyrightlink') || '© 9 Levels of Value Systems'}
          </div>
        </>
        }

        {showModal &&
        <Modal
          header={modalHeader}
          subHeader={modalSubHeader}
          primaryButtonTitle={modalPrimaryButtonTitle}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          controlScrollbar={!forReport}
          onClose={() => {
            dispatch(initSubmitContentFeedback());
            setShowModal(false);
          }}
          onConfirm={modalScrollable ?
            (() => {
              setShowModal(false);
              setShowReport(true);
            }) :
            (() => setShowModal(false))
          }
          feedbackSubmitted={feedbackSubmittedSuccessfully}
          onFeedbackSubmit={(requestFeedback && showModalFeedback) ?
            ((feedback) => {
              dispatch(submitContentFeedback({
                contentId: currentDimensionId,
                contentType: 'recommendation',
                helpful: feedback === 'Yes'
              }))
            }) :
            null
          }
        >
          {modalContent}
        </Modal>
        }
      </div>

      { showReport && createPortal(
        <NineLevelsReport
          results={results}
          signatureValues={signatureValues}
          onClose={() => setShowReport(false)}
        />,
        document.body,
      )}
    </div>
  )
};

export default NineLevelsResult;
