/*
All watchers are spawnd here
*/
import { spawn } from 'redux-saga/effects';

import {CONFIGURATION, CONFIGURATION_OPTIONS, IS_ADMIN_FRONTEND} from 'utils/configuration';

// ACTIONS
import {watchGetAction, watchExecuteAction} from './bqActions';

// ASSESSMENTS
import watchListAssessmentsRequest from './listAssessments';
import watchGetAssessmentRequest from './getAssessment';
import watchGetAssessmentQuestionsRequest from './getAssessmentQuestions';
import watchGetAssessmentAnswersRequest from './getAssessmentAnswers';
import {watchgetAssessmentResultRequest, watchGetPrevAssessmentResultRequest} from './getAssessmentResult';
import watchSaveAssessmentAnswersRequest, {watchSaveAssessmentAnswer} from './saveAssessmentAnswers';
import watchRefreshAllAssessmentsRequest from './refreshAssessments';
import {watchRegisterAssessment} from './assessments';

//ADMIN
import {
  watchAddCompanyUsers,
  watchDeleteCompanyUser,
  watchRemoveCompanyUser,
  watchEditCompanyUserRole
} from './admin';

// CANDIDATES
import {watchGetCandidate, watchGetCandidateAssessments} from 'features/+candidates/store/candidate/candidate.sagas';

// CAREERLEVELS
import watchGetCareerLevelsRequest from './getCareerLevels';

// CONFIGURATION
import { watchGetConfiguration, watchGetConfigurationExtended } from './configuration';

// CURRENT USER
import watchGetUserRequest from './getCurrentUser';
import watchUpdateUserRequest, {watchProlongSession} from './updateCurrentUser';
import watchGetUserProfilePictureRequest from './getUserProfilePicture';
import watchUploadUserProfilePictureRequest from './uploadUserProfilePicture';

// FILTER LIST DATA
import { watchGetListItems, watchResets } from './filterListEntities';

// LOCALISATION
import watchGetLanguageStringsRequest from './getTranslations';
import watchGetLanguagesRequest from './getLanguages';
import watchChangeLanguageRequest from './changeLanguage';

// LOGIN & USER HANDLING
import watchLoginRequest from './login';
import watchSignUpRequest from './signUp';
import {
  watchRequestResetPasswordRequest,
  watchResetPasswordRequest
} from './resetPassword';

// ORGANIZATION
import { watchGetMindJourneyScore, watchGetStatistic, watchGetMindJourneyResults } from './organization';

// PAGES
import { watchGetEmployees, watchGetEmployee } from './employeeOverview';
import {
  watchGetSettingsRequest,
  watchGetRegistrationDomains,
  watchGetAllFeatures,
  watchGetUserSettings,
  watchGetCompanySettings
} from './getSettings';

import {
  watchUpdateSettingsRequest,
  watchUpdateRegistrationDomains,
  watchUpdateUserSettings,
  watchUpdateCompanySettings
} from './updateSettings';

//PROFILE
import {watchGetProfile} from './profile';

//ROLES
import {
  watchGetPlatformRoles,
  watchCreatePlatformRole,
  watchUpdatePlatformRole,
  watchGetOrgRoles,
  watchGetOrgRole,
  watchCreateOrgRole,
  watchUpdateOrgRole,
  watchDeleteOrgRole,
  watchGetReferencesProfiles,
  watchGetExpandedReferences
} from './roles';

//STAFFING
import {
  watchGetStaffingEmployee,
  watchSetUserRole,
  watchDeleteUserRole
} from './staffing';

//STATIC
import {
  watchGetCountries,
  watchGetEducation,
  watchGetCompanyIndustries,
  watchGetCurrencies,
  watchGetCareerLevels,
  watchGetJobFamilies
} from './staticValues';

//SUPPORT
import {watchRequestSupport} from './support';

//SURVEY
import {watchSubmitFeedback, watchSubmitHelpfulInfoFeedback, watchGetContentFeedback} from './survey';

//TEAMS
import {
  watchGetTeams,
  watchGetTeam,
  watchGetTeamUsers,
  watchGetTeamTypes,
  watchAddUsers,
  watchDeleteUser,
  watchAddPositions,
  watchGetPositions,
  watchDeletePosition,
  watchFillPosition,
  watchGetTeamProfiles,
  watchUpdateTeam,
  watchUpdateUserRole
} from './teamOverview';

// VACANCIES
import {
  watchGetVacancy,
  watchGetVacancyCandidates,
  watchGetVacancyMatches, watchGetVacancyProfile,
  watchGetVacancyDescription
} from 'features/+candidates/store/vacancy/vacancy.sagas';

//WELL-BEING
import {
  watchSubmitWellBeingIndex,
  watchGetWellBeingHistory,
  watchGetWellBeingIndexHistory,
  watchGetWellBeingIndex,
  watchGetOrgWellBeingIndex,
  watchGetOrgWellBeingHistory
} from './wellBeing';

// ??
import watchSetMenuItemRequest from './setMenuItem';

//ADMIN SAGAS
import {watchGetPlans} from 'features/+admin/store/sagas/planOverview';
import {
  watchGetIndustries,
  watchCreateInstance,
  watchGetInstances,
  watchGetInstance,
  watchGetInstanceRegistrationDomains,
  watchUpdateInstanceRegistrationDomains,
  watchGetUserStatistics,
  watchRebuildCache
} from 'features/+admin/store/sagas/instanceOverview';

// GARMIN SAGAS
import {
  watchGetUserScore,
  watchGetConnectionStatus,
  watchGetUserHistory,
  watchGetOrgScore,
  watchGetOrgHistory
} from 'features/+garmin/store/garmin.sagas';

export default function* rootSaga() {
  // ACTIONS
  yield spawn(watchGetAction);
  yield spawn(watchExecuteAction);

  // ASSESSMENTS
  yield spawn(watchListAssessmentsRequest);
  yield spawn(watchGetAssessmentRequest);
  yield spawn(watchGetAssessmentQuestionsRequest);
  yield spawn(watchGetAssessmentAnswersRequest);
  yield spawn(watchgetAssessmentResultRequest);
  yield spawn(watchGetPrevAssessmentResultRequest);
  yield spawn(watchRefreshAllAssessmentsRequest)
  yield spawn(watchSaveAssessmentAnswersRequest);
  yield spawn(watchSaveAssessmentAnswer);
  yield spawn(watchRegisterAssessment);

  //ADMIN
  yield spawn(watchDeleteCompanyUser);
  yield spawn(watchRemoveCompanyUser);
  yield spawn(watchAddCompanyUsers);
  yield spawn(watchEditCompanyUserRole);

  // CAREERLEVELS
  yield spawn(watchGetCareerLevelsRequest);

  // CONFIGURATION
  yield spawn(watchGetConfiguration);
  yield spawn(watchGetConfigurationExtended);

  // CURRENT USER
  yield spawn(watchGetUserRequest);
  yield spawn(watchUpdateUserRequest);
  yield spawn(watchProlongSession);
  yield spawn(watchGetUserProfilePictureRequest);
  yield spawn(watchUploadUserProfilePictureRequest);

  // FILTER LIST DATA
  yield spawn(watchGetListItems);
  yield spawn(watchResets);

  // LOCALISATION
  yield spawn(watchGetLanguageStringsRequest);
  yield spawn(watchGetLanguagesRequest);
  yield spawn(watchChangeLanguageRequest);

  // LOGIN & USER HANDLING
  yield spawn(watchLoginRequest);
  yield spawn(watchSignUpRequest);
  yield spawn(watchRequestResetPasswordRequest);
  yield spawn(watchResetPasswordRequest);

  //ORGANIZATION
  yield spawn(watchGetMindJourneyScore);
  yield spawn(watchGetStatistic);
  yield spawn(watchGetMindJourneyResults);

  // PAGES
  yield spawn(watchGetEmployees);
  yield spawn(watchGetEmployee);

  //PROFILE
  yield spawn(watchGetProfile);

  //ROLES
  yield spawn(watchGetPlatformRoles);
  yield spawn(watchCreatePlatformRole);
  yield spawn(watchUpdatePlatformRole);
  yield spawn(watchGetOrgRoles);
  yield spawn(watchGetOrgRole);
  yield spawn(watchCreateOrgRole);
  yield spawn(watchUpdateOrgRole);
  yield spawn(watchDeleteOrgRole);
  yield spawn(watchGetReferencesProfiles);
  yield spawn(watchGetExpandedReferences);

  //STAFFING
  yield spawn(watchGetStaffingEmployee);
  yield spawn(watchSetUserRole);
  yield spawn(watchDeleteUserRole);

  //STATIC
  yield spawn(watchGetCountries);
  yield spawn(watchGetEducation);
  yield spawn(watchGetCompanyIndustries);
  yield spawn(watchGetCurrencies);
  yield spawn(watchGetCareerLevels);
  yield spawn(watchGetJobFamilies);

  //SUPPORT
  yield spawn(watchRequestSupport);

  //SURVEY
  yield spawn(watchSubmitFeedback);
  yield spawn(watchSubmitHelpfulInfoFeedback);
  yield spawn(watchGetContentFeedback);

  //SETTINGS
  yield spawn(watchGetSettingsRequest);
  yield spawn(watchGetRegistrationDomains);
  yield spawn(watchGetAllFeatures);
  yield spawn(watchGetUserSettings);
  yield spawn(watchGetCompanySettings);
  yield spawn(watchUpdateSettingsRequest);
  yield spawn(watchUpdateRegistrationDomains);
  yield spawn(watchUpdateUserSettings);
  yield spawn(watchUpdateCompanySettings);

  //TEAMS
  yield spawn(watchGetTeams);
  yield spawn(watchGetTeam);
  yield spawn(watchGetTeamUsers);
  yield spawn(watchGetTeamTypes);
  yield spawn(watchAddUsers);
  yield spawn(watchDeleteUser);
  yield spawn(watchAddPositions);
  yield spawn(watchGetPositions);
  yield spawn(watchDeletePosition);
  yield spawn(watchFillPosition);
  yield spawn(watchGetTeamProfiles);
  yield spawn(watchUpdateTeam);
  yield spawn(watchUpdateUserRole);

  // CANDIDATES
  yield spawn(watchGetCandidate);
  yield spawn(watchGetCandidateAssessments);

  // VACANCIES
  yield spawn(watchGetVacancy);
  yield spawn(watchGetVacancyCandidates);
  yield spawn(watchGetVacancyMatches);
  yield spawn(watchGetVacancyProfile);
  yield spawn(watchGetVacancyDescription);

  //WELL-BEING
  yield spawn(watchSubmitWellBeingIndex);
  yield spawn(watchGetWellBeingHistory);
  yield spawn(watchGetWellBeingIndexHistory);
  yield spawn(watchGetWellBeingIndex);
  yield spawn(watchGetOrgWellBeingIndex);
  yield spawn(watchGetOrgWellBeingHistory);

  // ??
  yield spawn(watchSetMenuItemRequest);


  if (IS_ADMIN_FRONTEND) {
    //ADMIN SAGAS
    yield spawn(watchGetPlans);
    yield spawn(watchGetIndustries);
    yield spawn(watchCreateInstance);
    yield spawn(watchGetInstances);
    yield spawn(watchGetInstance);
    yield spawn(watchGetInstanceRegistrationDomains);
    yield spawn(watchUpdateInstanceRegistrationDomains);
    yield spawn(watchGetUserStatistics);
    yield spawn(watchRebuildCache);
  }

  if (CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU) {
    yield spawn(watchGetUserScore);
    yield spawn(watchGetConnectionStatus);
    yield spawn(watchGetUserHistory);
    yield spawn(watchGetOrgScore);
    yield spawn(watchGetOrgHistory);
  }
}

