// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './SpiderDiagramNext.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import BluCSSTransition from 'ui/basic/containers/BluCSSTransition';

// UTILS
import { joinNumbers } from 'utils/textTools';
import { setDecimalSeperator } from 'utils/userpreference';

// CONFIG & DATA
const DIAGRAM = {
  viewBox: '0 0 25 25',
  radius: 10.5,
  xOrigin: 12.5,
  yOrigin: 12.5,
  valueRange: 10,
  minRange: 0.3,
  labelRadius: 11.3,
  pointRadius: .15, // data point radius in viewbox coordinates
  pointRadiusHoverArea: 1.25,
  arcRadius: 20, // data lines/range radius
  viewBoxPixelFactor: 270 / 25, // css width / viewBox width for diagram (needed for positioning labels)
};

// COMPONENT: SpiderDiagramNext
const SpiderDiagramNext = (props) => {
  // PROPS
  const {
    dimensions = [],
    lineValues = [],
    lineValuesSecondary = [],
    rangeValues = [],
    dimensionsHovers = [],
    onHover,
    onDimensionClick
  } = props;

  // COMPONENT/UI STATE and REFS

  // STORE HOOKS

  // EFFECT HOOKS

  // OTHER HOOKS

  // METHODS
  const handleDimensionClick = (dimension) => {
    if (onDimensionClick) {
      onDimensionClick(dimension);
    }
  };

  // HELPERS, HANDLES, RENDERS
  // HELPER: getRadialPoint
  const getRadialPoint = (angle, radius = DIAGRAM.radius) => {
    const x = radius * Math.cos(angle) + DIAGRAM.xOrigin;
    const y = radius * Math.sin(angle) + DIAGRAM.yOrigin;

    return [x, y];
  };
  // HELPER: getRadialAngle
  const getRadialAngle = (dimensions, dimensionIndex) => {
    // calculate angle and offset by 90 degrees to start on vertical line
    const angle = dimensionIndex * 2 * Math.PI / dimensions.length
      - Math.PI / 2;

    return angle;
  };
  // HELPER: getDataPathString
  const getDataPathString = (dimensions, values) => {
    let pathString = dimensions.map((dimension, dimensionIndex) => {
      const angle = getRadialAngle(dimensions, dimensionIndex);
      const [xPoint, yPoint] = getRadialPoint(angle, values[dimensionIndex]);
      let pathFragment;

      // first index? add start point
      if (dimensionIndex === 0) {
        pathFragment = `M ${xPoint} ${yPoint}`;
      }
      // all other indices, create arc
      else {
        // add a nice smooth radius to the lines
        pathFragment = `A ${DIAGRAM.arcRadius} ${DIAGRAM.arcRadius} 0 0 1 ${xPoint} ${yPoint}`;
      }

      // last index? close the shape with another arc
      if (dimensionIndex === dimensions.length -1) {
        const anglePointEnd = getRadialAngle(dimensions, 0);
        const [xPointEnd, yPointEnd] = getRadialPoint(anglePointEnd, values[0]);
        pathFragment += ` A ${DIAGRAM.arcRadius} ${DIAGRAM.arcRadius} 0 0 1 ${xPointEnd} ${yPointEnd}`;
      }

      return pathFragment;
    });
    // join coordinates
    pathString = pathString.join(' ');
    return pathString;
  };

  // RENDER: GridCircles
  const renderSvgGridCircles = () => {
    const svgGridCircles = [];
    for (let i = DIAGRAM.valueRange; i > 0; i--) {
      svgGridCircles.push(
        <circle key={i} className={styles.gridCircle}
          cx={DIAGRAM.xOrigin} cy={DIAGRAM.yOrigin} r={i-Number(styles.strokeWidth)} />
      ); // r=i-stroke-width
    }
    return svgGridCircles;
  };

  // RENDER: GridLines
  const renderSvgGridLines = (dimensions) => {
    const svgGridLines = dimensions.map((dimension, dimensionIndex) => {

      const angle = getRadialAngle(dimensions, dimensionIndex);
      const [xLine, yLine] = getRadialPoint(angle);

      return (
        <line key={dimensionIndex}
          className={styles.gridLine}
          x1={DIAGRAM.xOrigin} y1={DIAGRAM.yOrigin}
          x2={xLine} y2={yLine}
        />
      );
    });

    return svgGridLines;
  };

  // RENDER: GridNumbers
  const renderSvgGridNumbers = (dimensions) => {
    const svgGridNumbers = dimensions.map((dimension, dimensionIndex) => {
      const angle = getRadialAngle(dimensions, dimensionIndex);
      const [xPoint, yPoint] = getRadialPoint(angle, DIAGRAM.labelRadius);
      return (
        <text key={dimensionIndex}
          className={classNames(styles.gridNumber, { [styles.hover]: dimensionsHovers[dimensionIndex] })}
          x={xPoint - .3} y={yPoint + .3}
          onMouseEnter={() => { onHover && onHover(dimensionIndex, true) }}
          onMouseLeave={() => { onHover && onHover(dimensionIndex, false) }}
          onClick={() => handleDimensionClick(dimension)}
        > { dimensionIndex + 1 } </text>
      );
    });
    return svgGridNumbers;
  }

  // RENDER: DataPoints
  const renderSvgDataPoints = (dimensions, lineValues) => {
    const svgDataPoints = dimensions.map((dimension, dimensionIndex) => {
      const angle = getRadialAngle(dimensions, dimensionIndex);
      const [xPoint, yPoint] = getRadialPoint(angle, lineValues[dimensionIndex]);

      return (
        <g key={dimensionIndex}>
          <circle
            className={classNames(styles.dataPoint, { [styles.hover]: dimensionsHovers[dimensionIndex] })}
            cx={xPoint} cy={yPoint} r={DIAGRAM.pointRadius}
          />
          <circle
            className={classNames(styles.dataPointHoverArea, { [styles.hover]: dimensionsHovers[dimensionIndex] })}
            cx={xPoint} cy={yPoint} r={DIAGRAM.pointRadiusHoverArea}
            onMouseEnter={() => { onHover(dimensionIndex, true) }}
            onMouseLeave={() => { onHover(dimensionIndex, false) }}
            onClick={() => handleDimensionClick(dimension)}
          />
        </g>
      );
    });
    return svgDataPoints;
  };

  // RENDER: DataLines
  const renderSvgDataLines = (dimensions, lineValues, cssClass = 'dataLine') => {
    const pathString = getDataPathString(dimensions, lineValues);
    // create path element
    return <path className={styles[cssClass]} d={pathString} />;
  }

  // RENDER: DataRange
  const renderSvgDataRange = (dimensions, rangeValues = []) => {
    if (rangeValues.length === 0) {
      return null;
    }

    const minValues = [];
    const maxValues = [];

    rangeValues.map(([value1, value2]) => {
      let min = Math.min(value1, value2)
      let max = Math.max(value1, value2);

      // make sure we have a minimum range of DIAGRAM.minRange
      // and make sure it's within DIAGRAM.valueRange
      if (max-min < DIAGRAM.minRange) {
        if (max < DIAGRAM.valueRange - DIAGRAM.minRange) {
          max = max + DIAGRAM.minRange;
        }
        else {
          min = min - DIAGRAM.minRange;
        }
      }

      minValues.push(min);
      maxValues.push(max);
      return false;
    });

    const pathStringOuter = getDataPathString(dimensions, minValues);

    const pathStringInner = getDataPathString(dimensions, maxValues);

    const pathString = pathStringOuter + ' ' + pathStringInner;
    return <path className={styles.dataRange} d={pathString} />;
  };

  // RENDER: TextLabels
  const renderTextLabel = (dimensions) => {

    return dimensions.map((dimension, dimensionIndex) => {
      // get angle and point
      const angle = getRadialAngle(dimensions, dimensionIndex);
      const point = getRadialPoint(angle, DIAGRAM.labelRadius);
      const pointInPixels = [
        // X: -12.5 to start from center
        (point[0] - 12.5) * DIAGRAM.viewBoxPixelFactor,
        point[1] * DIAGRAM.viewBoxPixelFactor
      ];

      // positioning depending on left or right half of circle
      let style = {};
      // all dimension to the right and center top and center bottom
      if (dimensionIndex <= (dimensions.length) / 2) {
        style.marginTop = pointInPixels[1] + 'px';
        style.marginLeft = pointInPixels[0] + 'px';
      }
      // all dimensions to the left
      else {
        style.marginTop = pointInPixels[1] + 'px';
        style.marginLeft = `calc(-${styles.labelWidth}px + ${pointInPixels[0] - 30/*offset*/}px)`;
        style.textAlign = 'right';
      }

      return (
        <div key={dimensionIndex}
          className={classNames(styles.textLabel, { [styles.hover]: dimensionsHovers[dimensionIndex] })}
          style={style}
          onMouseEnter={() => { onHover(dimensionIndex, true) }}
          onMouseLeave={() => { onHover(dimensionIndex, false) }}
          onClick={() => handleDimensionClick(dimension)}
        >
            { dimension.label }
        </div>
      );
    });

  };

  // RENDER: ValueLabels
  const renderValueLabels = (dimensions, lineValues) => {

    return dimensions.map((dimension, dimensionIndex) => {
      // get angle and point
      const lineValue = lineValues[dimensionIndex];
      const range = (rangeValues && rangeValues[dimensionIndex]) || [];

      const value = lineValue || Math.max(...range);
      const angle = getRadialAngle(dimensions, dimensionIndex);
      const point = getRadialPoint(angle, value);
      const pointInPixels = [
        // X: -12.5 to start from center
        (point[0] - 12.5) * DIAGRAM.viewBoxPixelFactor,
        point[1] * DIAGRAM.viewBoxPixelFactor
      ];

      // positioning
      let style = {};
      style.marginTop = pointInPixels[1] + 'px';
      style.marginLeft = pointInPixels[0] + 'px';

      // valueText
      const valueText = !isNaN(lineValue)
        ? setDecimalSeperator(lineValue)
        : (joinNumbers(range, '-', setDecimalSeperator));

      return (
        <BluCSSTransition key={dimensionIndex}
          in={dimensionsHovers[dimensionIndex]}
          classNames={{ ...styles }}>
          <div className={styles.valueLabel}
            style={style}
            onMouseEnter={() => { onHover(dimensionIndex, true) }}
            onMouseLeave={() => { onHover(dimensionIndex, false) }}
            onClick={() => handleDimensionClick(dimension)}
          >
              <span className={styles.value}> { valueText } </span>
              <span className={styles.text}> { dimension.label } </span>
          </div>
        </BluCSSTransition>
      );
    });
  };

  // RENDER: SpiderDiagramNext
  return (
    <div className={`${styles.spiderDiagramNext}`}>

      {/* LABELS */}
      <div className={styles.labels}>
        {renderTextLabel(dimensions)}
        {lineValues && renderValueLabels(dimensions, lineValues)}
      </div>

      {/* SVG */}
      <svg viewBox={DIAGRAM.viewBox}>
        {/* grid circles */}
        {renderSvgGridCircles()}
        {/* grid lines */}
        {renderSvgGridLines(dimensions)}
        {/* grid numbers */}
        {renderSvgGridNumbers(dimensions)}
        {/* data range */}
        {(rangeValues && rangeValues.length) && renderSvgDataRange(dimensions, rangeValues)}
        {/* data lines */}
        { lineValuesSecondary && lineValuesSecondary.length && renderSvgDataLines(dimensions, lineValuesSecondary, 'dataLineSecondary')}
        {lineValues && lineValues.length && renderSvgDataLines(dimensions, lineValues)}
        {/* data points */}
        {lineValues && lineValues.length && renderSvgDataPoints(dimensions, lineValues)}
      </svg>

    </div>
  );
};

export default SpiderDiagramNext;
