import React from 'react';
import styles from './Profile.module.scss';

// 3RD PARTY
import classNames from 'classnames';

import {useHistory} from 'react-router';

// OTHER COMPONENTS
import RolesPopover from './RolesPopover';
import {CollapsiblePanel, ImgCircle} from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import {getMonths} from 'utils/months';



const Profile = (props) => {
  const { user = {}, userImage, roleClickable = true, showCompanyData = true } = props;

  const translate = useTranslate();
  const history = useHistory();

  const isActive = user.status === undefined || (user.status && user.status.percentage !== -1);

  return (
    <div className={styles.profile}>
      <div className={styles.imageBlock}>
        <div className={classNames(styles.left, {[styles.halfVisible]: !isActive})}>
          <ImgCircle
            className='darker'
            src={userImage || (user.userPicture ?? api.getUserImageUrl(user.id))}
            label1={user.name ? user.name.split(' ')[0] : ''}
            label2={user.name ? user.name.split(' ')[1] : ''}
            size='M'
          />
        </div>
        <div className={styles.right}>
          <div className={styles.name}>{user.name}</div>
          {(user.orgRoles && user.orgRoles.length > 0)  ?
            <div className={styles.roles}>
              {user.orgRoles.length > 1 ?
                <RolesPopover
                  roles={user.orgRoles}
                  roleClickable={roleClickable}
                  onClick={(roleId) => {
                    if (roleClickable) {
                      history.push(`/roles/${roleId}/reference-profile`)
                    }
                  }}
                /> :
                <span onClick={() => {
                  if (roleClickable) {
                    history.push(`/roles/${user.orgRoles[0].id}/reference-profile`)
                  }
                }}>
                  {user.orgRoles[0].name}
                </span>
              }
            </div> :
            <div className={styles.position}>{user.position || '-'}</div>
          }
          {isActive ?
            <div className={styles.deactivated}>{user.userGroup}</div> :
            <div className={styles.deactivated}>
              {translate('user_settings_deactivated_user_short') || 'Profil deaktiviert'}
            </div>
          }
        </div>
      </div>

      {showCompanyData &&
      <div className={styles.center}>
        <CollapsiblePanel header={translate('ps_label_expandable') || 'Details'}>
          <div className={styles.body}>
            <div className={styles.rows}>
              <div className={styles.row}>
                <div className={styles.title}>
                  {translate('cp_subheadline_timeincomp') ||
                  'In Company since'}
                </div>
                <div className={styles.value}>
                  {user.since
                    ? getMonths()[new Date(user.since).getMonth()] + ' ' + new Date(user.since).getFullYear()
                    : '-'}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.title}>
                  {translate('cp_label_dropdown_careerlevel') ||
                  'Career Level'}
                </div>
                <div className={styles.value}>{user.careerLevel || '-'}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.title}>Email</div>
                <div className={styles.value}>{user.email}</div>
              </div>
            </div>
          </div>
        </CollapsiblePanel>
      </div>
      }

    </div>
  )
};

export default Profile;
