import React, { useRef, useState } from 'react';
import styles from './QuickAccessMenu.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// UTILS
import { useTranslate } from 'utils/translator';
import { handleRipple } from 'utils/ripple';

// OTHER COMPONENTS
import { BluCSSTransition, Button } from 'ui/basic';

// STORE
import { useSelector } from 'react-redux';
import {
  selectCapabilities,
  selectFeatures,
  selectInstanceIsAdminFrontend
} from 'features/framework/storeNext/configurationSlice';


const QuickAccessMenu = () => {
  const translate = useTranslate();
  const menuRef = useRef();
  const history = useHistory();

  const [ quickAccessMenuIsOpen, setQuickAccessMenuIsOpen ] = useState(false);

  const isAdmin = useSelector(selectInstanceIsAdminFrontend);
  const capabilities = useSelector(selectCapabilities);
  const features = useSelector(selectFeatures);

  const buttonClassNames = classNames(
    styles.buttonBlock,
    {[styles.active]: quickAccessMenuIsOpen}
  );

  const handleBlur = ((event) => {
    if (event.relatedTarget !== menuRef.current) {
      setQuickAccessMenuIsOpen(false);
    }
  });

  return (
    <div onBlur={handleBlur}>
      <div className={buttonClassNames}>
        <Button
          size='S'
          onClick={() => setQuickAccessMenuIsOpen(!quickAccessMenuIsOpen)}
          leadingIcon={IconsSvg.Plus}
        >
          { translate('subnavigation_qa_button_label') || 'Neu' }
        </Button>
      </div>

      <BluCSSTransition in={quickAccessMenuIsOpen} classNames={ {...styles }}>
        <div
            ref={menuRef}
            tabIndex={'0'}
            className={styles.quickAccessMenu}
          >

          { !isAdmin && (
            <>
              { ((capabilities.employeesInvite && features.employees) ||
                (capabilities.candidatesInvite && features.candidates)) && (
                <div
                  className={styles.menuItem}
                  onClick={(event) => {
                    handleRipple(event, styles.colorPrimary3);
                    window.setTimeout(() => {
                      setQuickAccessMenuIsOpen(false);
                      history.push('/user/invite')
                    }, styles.animationDurationShortMs)
                  }}
                >
                  <span>{translate('subnavigation_qa_item1') || 'Mitarbeiter einladen'}</span>
                </div>
              )}

              { (capabilities.rolesCreate && features.roles) && (
                <div className={styles.menuItem}
                  onClick={(event) => {
                    handleRipple(event, styles.colorPrimary3);
                    window.setTimeout(() => {
                      setQuickAccessMenuIsOpen(false);
                      history.push('/roles/new')
                    }, styles.animationDurationShortMs)
                  }}>
                  <span>{translate('subnavigation_qa_item2') || 'Neue Rolle erstellen'}</span>
                </div>
              )}

              { (capabilities.teamsCreate && features.teams) && (
                <div className={styles.menuItem}
                  onClick={(event) => {
                    handleRipple(event, styles.colorPrimary3);
                    window.setTimeout(() => {
                      setQuickAccessMenuIsOpen(false);
                      history.push('/teams/new');
                    }, styles.animationDurationShortMs)
                  }}>
                  <span>{translate('subnavigation_qa_item3') || 'Team erstellen'}</span>
                </div>
              )}

              { (features.candidates && capabilities.vacanciesCreate) && (
                <div className={styles.menuItem}
                  onClick={(event) => {
                    handleRipple(event, styles.colorPrimary3);
                    window.setTimeout(() => {
                      setQuickAccessMenuIsOpen(false);
                      history.push('/vacancies/new');
                    }, styles.animationDurationShortMs)
                  }}
                >
                  <span>{translate('create_vacancy_title') || 'Neue Vakanz erstellen'}</span>
                </div>
              )}
            </>
          )}

          { isAdmin && (
            <div className={styles.menuItem}
              onClick={(event) => {
                handleRipple(event, styles.colorPrimary3);
                window.setTimeout(() => {
                  setQuickAccessMenuIsOpen(false);
                  history.push('/admin/new-instance');
                }, styles.animationDurationShortMs)
              }}>

              <span>{translate('admin_create_instance_btn') || 'Instanz erstellen'}</span>
            </div>
          )}
        </div>
      </BluCSSTransition>
    </div>
  )
};

export default QuickAccessMenu;
