import React, {useState} from 'react';
import styles from './SurveyFloatingMenu.module.scss';

// ASSETS
import {ReactComponent as BqContact} from 'assets/icons/icn_bq_contact.svg';
import {ReactComponent as CloseBig} from 'assets/icons/icn_close_big.svg';

// 3RD PARTY
import {useHistory} from 'react-router';

// STORE OLD
import { useSelector } from 'react-redux';


// UTILS
import { useTranslate } from 'utils/translator';


const SurveyFloatingMenu = (props) => {
  // PROPS

  const translate = useTranslate();
  const history = useHistory();

  const currentLanguage = useSelector(state => state.localisation && state.localisation.currentLanguage);

  const [surveyMenuOpened, setSurveyMenuOpened] = useState(false);

  return (
    <div className={styles.surveyFloatingMenu}>
      {surveyMenuOpened ?
        <div className={styles.surveyMenu}>
          <div className={styles.menuItems}>
            {/*FEEDBACK*/}
            <div
              onClick={() => {
                setSurveyMenuOpened(false);
                history.push('/survey');
              }}
            >{translate('survey_floating_menu_feedback') || 'Feedback geben'}</div>

            {/*SUPPORT*/}
            <div
              onClick={() => {
                setSurveyMenuOpened(false);
                history.push('/support');
              }}
            >{translate('survey_floating_menu_support') || 'Support erhalten'}</div>

            {/*HELP CENTER*/}
            <div
              onClick={() => {
                setSurveyMenuOpened(false);
                const helpLink = currentLanguage === 'de_DE' ? 'https://help.bluquist.com/' : 'https://help.bluquist.com/en/';
                window.open(helpLink, '_blank');
              }}
            >{translate('gen_settings_help_link') || 'Zum Help Center'}</div>
          </div>
          <div className={styles.closeIcon} onClick={() => setSurveyMenuOpened(false)}>
            <CloseBig/>
          </div>
        </div> :
        <div className={styles.icon} onClick={() => setSurveyMenuOpened(true)}>
          <BqContact/>
        </div>
      }
    </div>
  )
};

export default SurveyFloatingMenu;
