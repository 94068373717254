import React from 'react';
import styles from './PoweredBy.module.scss';

import logo from 'assets/bq_brandmark.svg';

import { useTranslate } from 'utils/translator';

const PoweredBy = () => {
  const translate = useTranslate();

  return (
    <div className={styles.poweredBy}>
      <span>{translate('powered_by_lbl') || 'Powered by'}</span>
      <img src={logo} alt={'logo'} />
    </div>
  )
};

export default PoweredBy;
