export const GET_MIND_JOURNEY_SCORE = 'GET_MIND_JOURNEY_SCORE';
export const GET_MIND_JOURNEY_SCORE_FULFILLED = 'GET_MIND_JOURNEY_SCORE_FULFILLED';
export const GET_MIND_JOURNEY_SCORE_REJECTED = 'GET_MIND_JOURNEY_SCORE_REJECTED';

export const GET_STATISTIC = 'GET_STATISTIC';
export const GET_STATISTIC_FULFILLED = 'GET_STATISTIC_FULFILLED';
export const GET_STATISTIC_REJECTED = 'GET_STATISTIC_REJECTED';

export const GET_MIND_JOURNEY_RESULTS = 'GET_MIND_JOURNEY_RESULTS';
export const GET_MIND_JOURNEY_RESULTS_FULFILLED = 'GET_MIND_JOURNEY_RESULTS_FULFILLED';
export const GET_MIND_JOURNEY_RESULTS_REJECTED = 'GET_MIND_JOURNEY_RESULTS_REJECTED';
