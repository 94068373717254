/*
uploadUserProfilePicture
Upload a profile picture for the current user

AUTHORIZATIONS:
blueacademyAuth
REQUEST BODY SCHEMA: image/jpg
string <binary>
*/
import {post_binary}  from 'api';
import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';

function* uploadUserProfilePicture(binary) {
  try {
    yield put(action.loading());
    const {status,ok,data}  = yield call(
      post_binary,
      'core/user/picture',
      binary
    );

   if (ok && status === 200) {
    yield put(action.uploadUserProfilePictureFulfilled());
    yield put(action.getUserProfilePicture());
   }else{
      yield put(action.uploadUserProfilePictureRejected(data));
   }
  } catch (error) {
    yield put(action.uploadUserProfilePictureRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}
export default function* watchUploadUserProfilePictureRequest() {
  while (true) {
    try {
       const { payload } = yield take(action.UPLOAD_USER_PROFILE_PICTURE);
      yield call(uploadUserProfilePicture,payload);
    } catch (error) {
      yield put(action.uploadUserProfilePictureRejected(error));
    }
  }
}
