import React, {useState} from 'react';
import styles from './ResetPasswordRequestForm.module.scss';

import { useTranslate } from 'utils/translator';

import {Link, Button, InputNext} from 'ui/basic';

const ResetPasswordRequestForm = ({error, onResetPassword}) => {
  const [email, setEmail] = useState('');

  const translate = useTranslate();

  return (
    <div className={styles.resetPasswordRequestForm}>
      <div className={styles.title}>{translate('resetpw_title') || 'Passwort zurücksetzen'}</div>
      <div className={styles.subTitle}>
        {translate('resetpw_copy') ||
        'Gib deine Account-Emailadresse ein. Wir senden dir einen Link zum Erstellen eines neuen Passworts.'
        }
      </div>

      <form onSubmit={(event) => {
        event.preventDefault();
        onResetPassword(email.trim());
      }}>
        <InputNext
          placeholder={translate('resetpw_form_email_label') || 'Email'}
          value={email}
          autofocus
          errorMessage={error}
          onChange={value => setEmail(value)}
        />

        <div className={styles.bottom}>
          <Link to={'/'}>{translate('resetpw_link_login') || 'Login'}</Link>
          <Button
            disabled={!email}
            type={'submit'}
          >
            {translate('resetpw_button_send') || 'Senden'}
          </Button>
        </div>
      </form>

    </div>
  );
};

export default ResetPasswordRequestForm;
