import * as fromActionTypes from 'store/actionTypes/profile';

const initialState = {
  profile: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.INIT_PROFILE: {
      return {
        ...state,
        loading: false,
        profile: null
      };
    }
    case fromActionTypes.GET_PROFILE: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_PROFILE_FULFILLED: {
      const { profile } = action.payload;
      return {
        ...state,
        profile,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_PROFIlE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    default: {
      return state;
    }
  }
};
