// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './CollapsibleNext.module.scss';

// ASSETS
import { ReactComponent as ArrowDown } from 'assets/icons/icn_arrow_down.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/icn_info_blue.svg';


// STORE

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { BluCSSTransition } from 'ui/basic';

// UTILS

// CONFIG & DATA

// COMPONENT: CollapsibleNext
const CollapsibleNext = (props) => {
  // PROPS
  const {
      id,
      children,
      size = 'S',
      headerClassName,
      showTopBorder, // if CollapsibleNext is first child has same effect
      withBorders,
      isHovered, isOpenOverride, isOpenInitially,
      isExpandable = true,
      headerSubtle, headerHighlight, header, headerRight,
      label,
      onMouseEnter, onMouseLeave,
      hasInfoIcon,
      onInfoIconClick = () => {},
    } = props;

  // COMPONENT/UI STATE and REFS
  const [ isExpanded, setIsExpanded ] = useState(isOpenInitially);

  // STORE HOOKS

  // EFFECT HOOKS
  useEffect(() => {
    setIsExpanded(isOpenOverride);
  }, [isOpenOverride]);

  // OTHER HOOKS

  // METHODS
  const handleInfoIconClick = (event) => {
    if (hasInfoIcon) {
      event.stopPropagation(); // prevent toggle of Collapsible
      onInfoIconClick();
    }
  };

  // HELPERS, HANDLES, RENDERS

  // RENDER: CollapsibleNext
  return (
    <div
      id={id}
      className={classNames(styles.collapsibleNext, styles[`size${size.toUpperCase()}`], {
        [styles.isHovered]: isHovered,
        [styles.showTopBorder]: showTopBorder,
        [styles.withBorders]: withBorders,
        [styles.isExpandable]: isExpandable,
        [styles.isExpanded]: isExpanded
      })}
    >
      <div className={classNames(styles.header, headerClassName)}
        onClick={() => {
          if (!isExpandable) {
            return;
          }
          setIsExpanded(!isExpanded)
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>

        {/* label */}
        { label && (
          <div className={styles.label}>
            { label }
          </div>
        )}

        <div className={styles.headers}>
          {/* headerSubtle */}
          { headerSubtle &&
            <div className={styles.headerSubtle}>{ headerSubtle }</div>
          }

          {/* headerHighlight */}
          { headerHighlight &&
            <div className={classNames(styles.headerHighlight)}>{ headerHighlight }</div>
          }

          {/* header */}
          <div className={styles.headerNormal}>{ header }</div>

          {/* headerRight */}
          { (headerRight || hasInfoIcon) &&
            <div className={classNames(styles.headerRight, {
              [styles.hasInfoIcon]: hasInfoIcon
            })}
              onClick={handleInfoIconClick}>
              <div className={styles.label}>{ headerRight }</div>
              { hasInfoIcon && (
                <div className={styles.infoIcon}
                  onClick={handleInfoIconClick}>
                  <InfoIcon />
                </div>
              )}
            </div>
          }

          {isExpandable && <div className={styles.arrow}><ArrowDown /></div>}
        </div>

      </div>
      <BluCSSTransition in={isExpanded}
        classNames={{ ...styles }} >
        <div className={styles.content}>
          { children }
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default CollapsibleNext;
