import React, { memo } from 'react';
import styles from './AssessmentCheckMark.module.scss';
import { ReactComponent as Checkmark } from 'assets/icons/icn_check_small.svg';


const AssessmentCheckMark = memo((props) => {
  return (
    <div className={styles.assessmentCheckMark}>
      <div className={styles.checkMark}>
        <Checkmark />
      </div>
      <span>{props.label}</span>
    </div>
  );
});

export default AssessmentCheckMark;
