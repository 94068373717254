import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'features/+admin/store/actionTypes/planOverview';
import * as fromActions from 'features/+admin/store/actions';

import {get} from 'api';

function* getPlans() {
  try {
    const { status, ok, data } = yield call(get, 'core/platform/plans');

    if (ok && status === 200) {
      yield put(fromActions.getPlansFulfilled({plans: data.plans}));
    } else {
      yield put(fromActions.getPlansRejected({error: data}));
    }
  }
  catch(error) {
    yield put(fromActions.getPlansRejected({error}));
  }
}

export function* watchGetPlans() {
  yield takeEvery(fromActionTypes.GET_PLANS, getPlans);
}
