import { configureStore } from '@reduxjs/toolkit'

import counterReducer from 'features/framework/storeNext/counterSlice'
import configurationReducer from 'features/framework/storeNext/configurationSlice'
import layoutReducer from 'features/framework/storeNext/layoutSlice'
import routeReducer from 'features/framework/storeNext/routeSlice'
import testReducer from 'features/framework/storeNext/testSlice'

export const storeNext = configureStore({
  reducer: {
    counter: counterReducer,
    configuration: configurationReducer,
    layout: layoutReducer,
    route: routeReducer,
    test: testReducer,
  },
  devTools: true
})

export default storeNext;

/**
 * Generic function to extract snapshot data from storeNext via selector.
 * Warning: this is not a replacement for useSelector since it won't
 * cause rerenders when storeNext data changes. It retrieves data
 * exactly once at the time of execution. If you're not sure what impact
 * this will have, you probably shouldn't use it for your use case.
 * See: StoreNext component instead.
 *
 * @callback selector
 * @returns {*}
 */
 export const getSnapshotFromStoreNext = (selector) => {
  const state = storeNext.getState();
  return selector(state);
};
