import {get}  from 'api';
import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';

function* getCareerLevels() {
  try {
    const {status,ok,data}  = yield call(
      get,
      '/core/config/profile/careerLevels'
    );

    if (ok && status === 200) {
      yield put(action.getCareerLevelsFulfilled(data));
    }else{
      yield put(action.getCareerLevelsRejected(data));
    }
  } catch (error) {
    yield put(action.getCareerLevelsRejected(error));
  }
}
export default function* watchGetCareerLevelsRequest() {
  while (true) {
    try {
      yield take(action.GET_CAREER_LEVELS);
      yield call(getCareerLevels);
    } catch (error) {
      yield put(action.getCareerLevelsRejected(error));
    }
  }
}
