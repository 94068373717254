import React, {useState} from 'react';
import styles from './TeamBig5Result.module.scss';

import * as api from 'api';

import { useTranslate } from 'utils/translator';
import {useBreakpoint} from 'utils/hooks';
import { setDecimalSeperator } from 'utils/userpreference';

import {ImgCircle, InfoTitle, LineDiagram, Card, Modal, Callout, TeamDiagramBarsNext} from 'ui/basic';
import {
  DEFAULT_CALLOUT_HIGH,
  DEFAULT_CALLOUT_LOW,
  DEFAULT_CALLOUT_MEDIUM, DEFAULT_CALLOUT_VERY_HIGH,
  DEFAULT_CALLOUT_VERY_LOW
} from 'ui/molecules/Big5Result/Big5Result.mockData';

const DEFAULT_INFO_TITLE = 'Big5';
const DEFAULT_INFO_DESCRIPTION =
  'In addition to personality, individual preferences play a particularly important role' +
  'in the question of what drives people in their thoughts and actions.' +
  'In a professional context, preferences influence our motivation to pursue certain goals' +
  'and make decisions. If our everyday working life matches our professional preferences,' +
  'we tend to feel better and are more motivated.';


const TeamBig5Result = (props) => {
  // PROPS
  const {profile, referenceProfile, showAverage} = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  // STATE
  const [showModal, setShowModal] = useState();
  const [modalHeader, setModalHeader] = useState();
  const [modalHeaderPosition, setModalHeaderPosition] = useState();
  const [modalSubHeader, setModalSubHeader] = useState();
  const [modalPreHeader, setModalPreHeader] = useState();
  const [modalContent, setModalContent] = useState();

  // STORE

  // METHODS
  const getDimensionsData = () => {
    if (!profile) {
      return [];
    }

    const big5Dimensions = [];

    profile.results.forEach(big5Result => {
      let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results.find(r => r.dimensionId === big5Result.id);
        if (thisResult) {
          range = [thisResult.minValue, thisResult.maxValue];
        } else {
          // for auto generated roles there are no range values for dimension itself
          // it should be calculated based on sub dimensions values
          let subDimensionsIds = big5Result.subDimensions ? big5Result.subDimensions.map(subDimension => subDimension.id) : [];
          let subDimensionsMinValues = referenceProfile.results
            .filter(result => subDimensionsIds.includes(result.dimensionId))
            .map(result => result.minValue);
          let subDimensionsMaxValues = referenceProfile.results
            .filter(result => subDimensionsIds.includes(result.dimensionId))
            .map(result => result.maxValue);

          const totalMinValue = subDimensionsMinValues.reduce((total, value) => total + value, 0) / subDimensionsMinValues.length;
          const totalMaxValue = subDimensionsMaxValues.reduce((total, value) => total + value, 0) / subDimensionsMinValues.length;

          range = [totalMinValue, totalMaxValue];
        }
      }

      big5Dimensions.push({
        id: big5Result.id,
        label: big5Result.name,
        range,
        data: big5Result.values.map(userValue => {
          const {user, value} = userValue;

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            value
          }
        })
      });
    })

    return big5Dimensions;
  };

  const getLabelDescription = (value, username, dimensionName) => {
    if (value >= 1 && value <= 2) {
      return translate('big5_dimension_very_low',
        ['{{dimension}}', dimensionName, '{{username}}', username]) ||
        `Das Merkmal ${dimensionName} ist bei ${username} sehr gering ausgeprägt.`;
    }

    if (value > 2 && value <= 4) {
      return translate('big5_dimension_low',
        ['{{dimension}}', dimensionName, '{{username}}', username]) ||
        `Das Merkmal ${dimensionName} ist bei ${username} gering ausgeprägt.`;
    }

    if (value > 4 && value <= 6) {
      return translate('big5_dimension_medium',
        ['{{dimension}}', dimensionName, '{{username}}', username]) ||
        `Das Merkmal ${dimensionName} ist bei ${username} mittel ausgeprägt.`;
    }

    if (value > 6 && value <= 8) {
      return translate('big5_dimension_high',
        ['{{dimension}}', dimensionName, '{{username}}', username]) ||
        `Das Merkmal ${dimensionName} ist bei ${username} hoch ausgeprägt.`;
    }

    return translate('big5_dimension_very_high',
      ['{{dimension}}', dimensionName, '{{username}}', username]) ||
      `Das Merkmal ${dimensionName} ist bei ${username} sehr hoch ausgeprägt.`;
  };

  const getCalloutText = (value, dimensionId) => {
    if (value >= 1 && value <= 2) {
      return translate(`big5_report_dim_${dimensionId}_callout_very_low`) || DEFAULT_CALLOUT_VERY_LOW;
    }
    if (value > 2 && value <= 4) {
      return translate(`big5_report_dim_${dimensionId}_callout_low`) || DEFAULT_CALLOUT_LOW;
    }
    if (value > 4 && value <= 6) {
      return translate(`big5_report_dim_${dimensionId}_callout_medium`) || DEFAULT_CALLOUT_MEDIUM;
    }
    if (value > 6 && value <= 8) {
      return translate(`big5_report_dim_${dimensionId}_callout_high`) || DEFAULT_CALLOUT_HIGH;
    }
    return translate(`big5_report_dim_${dimensionId}_callout_very_high`) || DEFAULT_CALLOUT_VERY_HIGH;
  }

  return (
    <Card>
      <InfoTitle
        title={translate('big5_results_title') || 'Persönlichkeit'}
        onClick={() => {
          setModalHeader(translate('big5_results_info_title') || DEFAULT_INFO_TITLE);
          setModalHeaderPosition('left');
          setModalPreHeader(null);
          setModalSubHeader(null);
          setModalContent(translate('big5_results_info_description') || DEFAULT_INFO_DESCRIPTION);
          setShowModal(true);
        }}
      />

      <div className={styles.diagram}>
        <TeamDiagramBarsNext
          labelAverage={translate('team_profile_average_title') || 'Teamdurchschnitt'}
          showAverage={showAverage}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: ` || 'Referenzprofil:')}
          onItemClick={(item) => {
            const userId = item.id;
            const userName = item.label;
            const userImage = item.img;
            const totalValue = item.value;
            const dimensionId = item.dimensionId;
            const dimensionName = item.dimensionName;

            const big5Dimension = profile.results.find(result => result.id === dimensionId);

            const subDimensions = big5Dimension.subDimensions.map(subDimension => {
              const valueItem = subDimension.values.find(v => v.user?.id === userId);
              return {
                name: subDimension.name,
                result: (valueItem && valueItem.value) ? valueItem.value : 0
              }
            })

            setModalHeader(userName);
            setModalHeaderPosition('center');
            setModalSubHeader(getLabelDescription(totalValue, userName, dimensionName));
            setModalPreHeader(
              <ImgCircle
                src={userImage}
                size={'M'}
                label1={userName.split(' ')[0]}
                label2={userName.split(' ')[1]}
              />
            );

            setModalContent(
              <div className={styles.infoModalResult}>
                <div className={styles.reportDiagram}>
                  <div className={styles.header}>
                    <span>{translate('big5_report_facet_result') || 'Ergebnis'}</span>
                    <span>{setDecimalSeperator(totalValue)}</span>
                  </div>

                  <LineDiagram
                    score={totalValue}
                    width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
                  />
                </div>

                <Callout>
                  {getCalloutText(totalValue, dimensionId)}
                </Callout>

                <div className={styles.facets}>
                  <div className={styles.title}>
                    {translate(`big5_report_dim_${dimensionId}_facets`) || 'Die Facetten von Verträglichkeit'}
                  </div>

                  {subDimensions.map((subDimension, index) => (
                    <div className={styles.facet} key={`subDimension-${index}`}>
                      <div className={styles.facetHeader}>
                        <span>{subDimension.name}</span>
                        <span>{setDecimalSeperator(subDimension.result)}</span>
                      </div>

                      <LineDiagram
                        score={subDimension.result}
                        width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
                      />

                      <div className={styles.facetFooter}>
                        <span>{translate('big5_report_resut_hint1') || 'gering ausgeprägt'}</span>
                        <span>{translate('big5_report_resut_hint2') || 'stark ausgeprägt'}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );

            setShowModal(true);
          }}
        />
      </div>

      {showModal &&
      <Modal
        header={modalHeader}
        headerPosition={modalHeaderPosition}
        subHeader={modalSubHeader}
        subHeaderPosition={'center'}
        preHeader={modalPreHeader}
        secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
        onClose={() => setShowModal(false)}
        onConfirm={() => setShowModal(false)}
      >
        {modalContent}
      </Modal>
      }

    </Card>
  )
};

export default TeamBig5Result;
