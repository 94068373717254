import * as fromActionTypes from 'store/actionTypes/support';

const initialState = {
  success: false,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.INIT_REQUEST_SUPPORT: {
      return {
        ...state,
        success: false
      }
    }
    case fromActionTypes.REQUEST_SUPPORT: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.REQUEST_SUPPORT_FULFILLED: {
      return {
        success: true,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.REQUEST_SUPPORT_REJECTED: {
      const { error } = action.payload;
      return {
        success: false,
        loading: false,
        error
      };
    }
    default: {
      return state;
    }
  }
};
