import React, {lazy} from 'react';

const TeamsMain = lazy(() => import('./pages/TeamsMain'  /* webpackChunkName: "teams" */));


export const routesConfigTeams = {
  show: {
    forUserGroup: ['employee', 'leader', 'admin'],
    forFeature: 'teams',
    forCapabilities: { employee: 'teamsView', leader: 'teamsView' }
  },
  routes: [
    {
      path: '/teams',
      PageComponent: () => <TeamsMain />,
      LazyPageComponent: 'TeamOverview',
      inMainNav: {
        labelKey: 'main_navigation_item_4'
      },
      subRoutes: [
        {
          path: '/teams/:teamId/profile',
          PageComponent: () => <TeamsMain />,
          LazyPageComponent: 'TeamProfile',
          tools: {
            browserTitleKey: 'main_navigation_item_4'
          },
          subRoutes: [
            {
              path: '/teams/:teamId/profile',
              inSubNav: {
                labelKey: 'team_subnav_profile'
              }
            },
            {
              path: '/teams/:teamId/staffing',
              PageComponent: () => <TeamsMain />,
              LazyPageComponent: 'TeamStaffing',
              inSubNav: {
                labelKey: 'team_subnav_staffing'
              },
              tools: {
                browserTitleKey: 'main_navigation_item_4'
              }
            },
            {
              path: '/teams/:teamId/details',
              PageComponent: () => <TeamsMain />,
              LazyPageComponent: 'TeamDetails',
              inSubNav: {
                labelKey: 'team_subnav_details'
              },
              tools: {
                browserTitleKey: 'main_navigation_item_4'
              }
            },
          ]
        }
      ]
    },
    {
      path: '/teams/new',
      pageType: 'modal',
      PageComponent: () => <TeamsMain />,
      LazyPageComponent: 'NewTeam',
      show: {
        forCapabilities: { employee: 'teamsCreate', leader: 'teamsCreate' }
      },
      tools: {
        browserTitleKey: 'main_navigation_item_4'
      }
    },
    {
      path: '/teams/:teamId/edit',
      pageType: 'modal',
      PageComponent: () => <TeamsMain />,
      LazyPageComponent: 'EditTeam',
      show: {
        show: (state, stateExtras) => {
          const { configuration } = state;
          // Wait till user data has been fetched
          if (typeof stateExtras.isUserTeamLeader !== 'boolean') {
            return true;
          }
          // Redirect in case edit capability is missing
          return configuration?.capabilities?.teamsCreate || stateExtras.isUserTeamLeader;
        },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_4'
      }
    },
  ]
};
