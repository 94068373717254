import { createSlice, createSelector } from '@reduxjs/toolkit';

// initialState
const initialState = {
  ready: false
};

// selectors
export const selectTest = state => state.test;
export const selectReady = createSelector(
  selectTest,
  (test) => test.ready
);


// slice & reducers
export const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    setReady: (state, action) => {
      const ready = action.payload;
      state.ready = ready;
    }
  }
})

// reducer
export default testSlice.reducer

// actions
export const {
  setReady
} = testSlice.actions;
