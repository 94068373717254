
export const getTranslationIds = (assessmentType) => {
  switch (assessmentType) {
    case 'big5':
      return {
        headerTitle: 'big5_ass_tb_title',
        headerCancel: 'big5_ass_tb_link_cancel',
        headerComplete: 'big5_ass_tb_link_complete',
        introTitle: 'big5_ass_intro_title',
        introDescription: 'big5_teaser_info_details',
        introWelcome: 'big5_ass_intro_subtitle',
        introCollapsibleLabel: 'big5_ass_intro_exp_label',
        introLongDescription: 'big5_ass_intro_exp_details',
        introContinue: 'big5_ass_intro_button_continue',
        guidanceTitle: 'big5_ass_guidance_callout_title',
        guidanceDescription: 'big5_ass_guidance_copy',
        guidanceItem1: 'big5_ass_guidance_cl_item1',
        guidanceItem2: 'big5_ass_guidance_cl_item2',
        guidanceItem3: 'big5_ass_guidance_cl_item3',
        guidanceItem4: 'big5_ass_guidance_cl_item4',
        guidanceItem5: 'big5_ass_guidance_cl_item5',
        guidanceButton: 'big5_ass_guidance_button_start',
        abortTitle: 'big5_ass_hintmodal_cancel_title',
        abortDescription: 'big5_ass_hintmodal_cancel_description',
        abortCancel: 'big5_ass_hintmodal_cancel_button_quit',
        abortContinue: 'big5_ass_hintmodal_cancel_button_resume',
        successTitle: 'big5_ass_success_title',
        successNext: 'big5_ass_success_button_next',
        successResults: 'big5_ass_success_button_view_results',
        breathTitle: 'big5_ass_interstitial_title'
      };
    case 'custom':
      return {
        breathTitle: 'big5_ass_interstitial_title'
      };
    case 'potential':
      return {
        headerTitle: 'potential_ass_tb_title',
        headerCancel: 'potential_ass_tb_link_cancel',
        headerComplete: 'potential_ass_tb_link_complete',
        introTitle: 'potential_ass_intro_title',
        introDescription: 'potential_ass_intro_description',
        introWelcome: 'potential_ass_intro_subtitle',
        introCollapsibleLabel: 'potential_ass_intro_exp_label',
        introLongDescription: 'potential_ass_intro_exp_details',
        introContinue: 'potential_ass_intro_button_continue',
        guidanceTitle: 'potential_ass_guidance_callout_title',
        guidanceDescription: 'potential_ass_guidance_copy',
        guidanceItem1: 'potential_ass_guidance_cl_item1',
        guidanceItem2: 'potential_ass_guidance_cl_item2',
        guidanceItem3: 'potential_ass_guidance_cl_item3',
        guidanceItem4: 'potential_ass_guidance_cl_item4',
        guidanceItem5: 'potential_ass_guidance_cl_item5',
        guidanceButton: 'potential_ass_guidance_button_start',
        abortTitle: 'potential_ass_hintmodal_cancel_title',
        abortDescription: 'potential_ass_hintmodal_cancel_description',
        abortCancel: 'potential_ass_hintmodal_cancel_button_quit',
        abortContinue: 'potential_ass_hintmodal_cancel_button_resume',
        successTitle: 'potential_ass_success_title',
        successNext: 'potential_ass_success_button_next',
        successResults: 'potential_ass_success_button_view_results',
        breathTitle: 'potential_ass_interstitial_title'
      };
    case 'keyCompetencies':
      return {
        headerTitle: 'keycomp_ass_tb_title',
        headerCancel: 'keycomp_ass_tb_link_cancel',
        headerComplete: 'keycomp_ass_tb_link_complete',
        introTitle: 'keycomp_ass_intro_title',
        introDescription: 'keycomp_ass_intro_description',
        introWelcome: 'keycomp_ass_intro_subtitle',
        introCollapsibleLabel: 'keycomp_ass_intro_exp_label',
        introLongDescription: 'keycomp_ass_intro_exp_details',
        introContinue: 'keycomp_ass_intro_button_continue',
        guidanceTitle: 'keycomp_ass_guidance_callout_title',
        guidanceDescription: 'keycomp_ass_guidance_copy',
        guidanceItem1: 'keycomp_ass_guidance_cl_item1',
        guidanceItem2: 'keycomp_ass_guidance_cl_item2',
        guidanceItem3: 'keycomp_ass_guidance_cl_item3',
        guidanceItem4: 'keycomp_ass_guidance_cl_item4',
        guidanceItem5: 'keycomp_ass_guidance_cl_item5',
        guidanceButton: 'keycomp_ass_guidance_button_start',
        abortTitle: 'keycomp_ass_hintmodal_cancel_title',
        abortDescription: 'keycomp_ass_hintmodal_cancel_description',
        abortCancel: 'keycomp_ass_hintmodal_cancel_button_quit',
        abortContinue: 'keycomp_ass_hintmodal_cancel_button_resume',
        successTitle: 'keycomp_ass_success_title',
        successNext: 'keycomp_ass_success_button_next',
        successResults: 'keycomp_ass_success_button_view_results',
        breathTitle: 'keycomp_ass_interstitial_title'
      };
    case 'leadershipCompetencies':
      return {
        headerTitle: 'lscomp_ass_tb_title',
        headerCancel: 'lscomp_ass_tb_link_cancel',
        headerComplete: 'lscomp_ass_tb_link_complete',
        introTitle: 'lscomp_ass_intro_title',
        introDescription: 'lscomp_ass_intro_description',
        introWelcome: 'lscomp_ass_intro_subtitle',
        introCollapsibleLabel: 'lscomp_ass_intro_exp_label',
        introLongDescription: 'lscomp_ass_intro_exp_details',
        introContinue: 'lscomp_ass_intro_button_continue',
        guidanceTitle: 'lscomp_ass_guidance_callout_title',
        guidanceDescription: 'lscomp_ass_guidance_copy',
        guidanceItem1: 'lscomp_ass_guidance_cl_item1',
        guidanceItem2: 'lscomp_ass_guidance_cl_item2',
        guidanceItem3: 'lscomp_ass_guidance_cl_item3',
        guidanceItem4: 'lscomp_ass_guidance_cl_item4',
        guidanceItem5: 'lscomp_ass_guidance_cl_item5',
        guidanceButton: 'lscomp_ass_guidance_button_start',
        abortTitle: 'lscomp_ass_hintmodal_cancel_title',
        abortDescription: 'lscomp_ass_hintmodal_cancel_description',
        abortCancel: 'lscomp_ass_hintmodal_cancel_button_quit',
        abortContinue: 'lscomp_ass_hintmodal_cancel_button_resume',
        successTitle: 'lscomp_ass_success_title',
        successNext: 'lscomp_ass_success_button_next',
        successResults: 'lscomp_ass_success_button_view_results',
        breathTitle: 'lscomp_ass_interstitial_title'
      };
    case 'workPreferences':
      return {
        headerTitle: 'wp_ass_tb_title',
        headerCancel: 'wp_ass_tb_link_cancel',
        headerComplete: 'wp_ass_tb_link_complete',
        introTitle: 'wp_ass_intro_title',
        introDescription: 'wp_ass_intro_description',
        introWelcome: 'wp_ass_intro_subtitle',
        introCollapsibleLabel: 'wp_ass_intro_exp_label',
        introLongDescription: 'wp_ass_intro_exp_details',
        introContinue: 'wp_ass_intro_button_continue',
        guidanceTitle: 'wp_ass_guidance_callout_title',
        guidanceDescription: 'wp_ass_guidance_copy',
        guidanceItem1: 'wp_ass_guidance_cl_item1',
        guidanceItem2: 'wp_ass_guidance_cl_item2',
        guidanceItem3: 'wp_ass_guidance_cl_item3',
        guidanceItem4: 'wp_ass_guidance_cl_item4',
        guidanceItem5: 'wp_ass_guidance_cl_item5',
        guidanceButton: 'wp_ass_guidance_button_start',
        abortTitle: 'wp_ass_hintmodal_cancel_title',
        abortDescription: 'wp_ass_hintmodal_cancel_description',
        abortCancel: 'wp_ass_hintmodal_cancel_button_quit',
        abortContinue: 'wp_ass_hintmodal_cancel_button_resume',
        successTitle: 'wp_ass_success_title',
        successNext: 'wp_ass_success_button_next',
        successResults: 'wp_ass_success_button_view_results',
        breathTitle: 'wp_ass_interstitial_title'
      };
    case 'rmp':
      return {
        headerTitle: 'rmp_ass_tb_title',
        headerCancel: 'rmp_ass_tb_link_cancel',
        headerComplete: 'rmp_ass_tb_link_complete',
        introTitle: 'rmp_ass_intro_title',
        introDescription: 'rmp_ass_intro_description',
        introWelcome: 'rmp_ass_intro_subtitle',
        introCollapsibleLabel: 'rmp_ass_intro_exp_label',
        introLongDescription: 'rmp_ass_intro_exp_details',
        introContinue: 'rmp_ass_intro_button_continue',
        guidanceTitle: 'rmp_ass_guidance_callout_title',
        guidanceDescription: 'rmp_ass_guidance_copy',
        guidanceItem1: 'rmp_ass_guidance_cl_item1',
        guidanceItem2: 'rmp_ass_guidance_cl_item2',
        guidanceItem3: 'rmp_ass_guidance_cl_item3',
        guidanceItem4: 'rmp_ass_guidance_cl_item4',
        guidanceItem5: 'rmp_ass_guidance_cl_item5',
        guidanceButton: 'rmp_ass_guidance_button_start',
        abortTitle: 'rmp_ass_hintmodal_cancel_title',
        abortDescription: 'rmp_ass_hintmodal_cancel_description',
        abortCancel: 'rmp_ass_hintmodal_cancel_button_quit',
        abortContinue: 'rmp_ass_hintmodal_cancel_button_resume',
        successTitle: 'rmp_ass_success_title',
        successNext: 'rmp_ass_success_button_next',
        successResults: 'rmp_ass_success_button_view_results',
        breathTitle: 'rmp_ass_interstitial_title',
        copyright: 'rmp_ass_copyrightlink',
        copyrightInfoTitle: 'rmp_ass_info_title_copyright',
        copyrightInfoDescription: 'rmp_ass_info_description_copyright',
        finalPageDescription: 'rmp_ass_success_description',
        viewProfileButton: 'rmp_ass_success_button_view_profile'
      };
    case 'nineLevels':
      return {
        headerTitle: '9levels_ass_tb_title',
        headerCancel: '9levels_ass_tb_link_cancel',
        headerComplete: '9levels_ass_tb_link_complete',
        introTitle: '9levels_ass_intro_title',
        introDescription: '9levels_ass_intro_description',
        introWelcome: '9levels_ass_intro_subtitle',
        introCollapsibleLabel: '9levels_ass_intro_exp_label',
        introLongDescription: '9levels_ass_intro_exp_details',
        introContinue: '9levels_ass_intro_button_continue',
        guidanceTitle: '9levels_ass_guidance_callout_title',
        guidanceDescription: '9levels_ass_guidance_copy',
        guidanceItem1: '9levels_ass_guidance_cl_item1',
        guidanceItem2: '9levels_ass_guidance_cl_item2',
        guidanceItem3: '9levels_ass_guidance_cl_item3',
        guidanceItem4: '9levels_ass_guidance_cl_item4',
        guidanceItem5: '9levels_ass_guidance_cl_item5',
        guidanceButton: '9levels_ass_guidance_button_start',
        abortTitle: '9levels_ass_hintmodal_cancel_title',
        abortDescription: '9levels_ass_hintmodal_cancel_description',
        abortCancel: '9levels_ass_hintmodal_cancel_button_quit',
        abortContinue: '9levels_ass_hintmodal_cancel_button_resume',
        successTitle: '9levels_ass_success_title',
        successNext: '9levels_ass_success_button_next',
        successResults: '9levels_ass_success_button_view_results',
        breathTitle: '9levels_ass_interstitial_title',
        copyright: '9levels_ass_copyrightlink',
        copyrightInfoTitle: '9levels_ass_info_title_copyright',
        copyrightInfoDescription: '9levels_ass_info_description_copyright',
        finalPageDescription: '9levels_ass_success_description',
        viewProfileButton: '9levels_ass_success_button_view_profile'
      };
    case 'mindfulness':
      return {
        headerTitle: 'mind_journey_ass_tb_title',
        headerCancel: 'mind_journey_ass_tb_link_cancel',
        headerComplete: 'mind_journey_ass_tb_link_complete',
        introTitle: 'mind_journey_ass_intro_title',
        introDescription: 'mind_journey_ass_intro_description',
        introWelcome: 'mind_journey_ass_intro_subtitle',
        introCollapsibleLabel: 'mind_journey_ass_intro_exp_label',
        introLongDescription: 'mind_journey_ass_intro_exp_details',
        introContinue: 'mind_journey_ass_intro_button_continue',
        guidanceTitle: 'mind_journey_ass_guidance_callout_title',
        guidanceDescription: 'mind_journey_ass_guidance_copy',
        guidanceItem1: 'mind_journey_ass_guidance_cl_item1',
        guidanceItem2: 'mind_journey_ass_guidance_cl_item2',
        guidanceItem3: 'mind_journey_ass_guidance_cl_item3',
        guidanceItem4: 'mind_journey_ass_guidance_cl_item4',
        guidanceItem5: 'mind_journey_ass_guidance_cl_item5',
        guidanceButton: 'mind_journey_ass_guidance_button_start',
        abortTitle: 'mind_journey_ass_hintmodal_cancel_title',
        abortDescription: 'mind_journey_ass_hintmodal_cancel_description',
        abortCancel: 'mind_journey_ass_hintmodal_cancel_button_quit',
        abortContinue: 'mind_journey_ass_hintmodal_cancel_button_resume',
        successTitle: 'mind_journey_ass_success_title',
        successNext: 'mind_journey_ass_success_button_next',
        successResults: 'mind_journey_ass_success_button_view_results',
        breathTitle: 'mind_journey_ass_interstitial_title'
      };
    default:
      return {};
  }
};
