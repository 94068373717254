// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useState} from 'react';
import styles from './PillGroup.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// STORE

// OTHER COMPONENTS
import { Pill, Scrollable } from 'ui/basic';

// UTILS
import {isValid} from 'utils/numbers';

// CONFIG & DATA

// COMPONENT: PillGroup
const PillGroup = (props) => {
  // PROPS
  const {
    pillItems = [],
    looks,
    fadeWhite,
    activeIndex,
    onPillClick = (pillItem, index) => {},
  } = props;

  // COMPONENT/UI STATE and REFS
  const [activeIndexInternal, setActiveIndexInternal] = useState(0);

  // STORE HOOKS

  // EFFECT HOOKS
  useEffect(() => {
    if (isValid(activeIndex)) {
      setActiveIndexInternal(activeIndex);
    }
  }, [activeIndex]);

  // OTHER HOOKS

  // METHODS
  const handleClick = (index, pillItem) => {
    // console.log('PillGroup click', index);
    setActiveIndexInternal(index);
    onPillClick(pillItem, index);
  };

  // HELPERS, HANDLES, RENDERS
  // RENDER: Pills, with active
  const pillItemsJsx = pillItems.map((pillItem, index) => {
    const pillProps = {
      key: index,
      // return index onClick
      onClick: () => {handleClick(index, pillItem)},
      active: index === activeIndexInternal,
      looks
    };
    return (
      <Pill {...pillProps}>
        {pillItem.label}
      </Pill>
    );
  });

  // RENDER: PillGroup
  return (
    <div className={classNames(styles.pillGroup, {
      [styles.fadeWhite]: fadeWhite
    })}>
      {/* Pills */}
      <Scrollable scroll fade fadeWhite scrollOnClick>
        <div className={styles.pillContainer}>
          {pillItemsJsx}
        </div>
      </Scrollable>
      {/* debug */}
      {/* <br/>
      <strong>active</strong>
      <br/>
      index: {activeIndex}
      <br/>
      label: {pillItems[activeIndex].label} */}
    </div>
  );
};

export default PillGroup;
