// REACT, STYLE, STORIES & COMPONENT
import React, {useCallback, useEffect, useState} from 'react';
import styles from './GarminBarDiagram.module.scss';

// ASSETS

// 3RD PARTY
import * as css from 'styles-imports/shared.scss';
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import {isValid} from 'utils/numbers';
import {useDebounce, useWindowWidth} from 'utils/hooks';

// STORE

// CONFIG & DATA
// const Config = {};
const DIAGRAM_HEIGHT = 16;


// COMPONENT: GarminBarDiagram
const GarminBarDiagram = (props) => {
  // PROPS
  const { value, looks, footerText } = props;

  // SPECIAL HOOKS
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);

  const [diagramWidth, setDiagramWidth] = useState(0);
  const [diagramColor, setDiagramColor] = useState();

  const [ svgNode, setSvgNode ] = useState();
  const diagramRef = useCallback(node => {
    if (node) {
      setSvgNode(node);
    }
  }, []);

  useEffect(() => {
    if (svgNode) {
      setDiagramWidth(svgNode.clientWidth);
    }
    // eslint-disable-next-line
  }, [debouncedWindowWidth, svgNode]);

  const [barWidth, setBarWidth] = useState(0);
  useEffect(() => {
    if (!isValid(value) || !isValid(diagramWidth)) {
      return;
    }

    setBarWidth(Math.round(diagramWidth * value / 100));
  }, [value, diagramWidth]);

  useEffect(() => {
    if (!isValid(value) || diagramColor) {
      return;
    }

    let color;
    if (value >= 0 && value <= 25) {
      color = 'red';
    } else if (value > 25 && value <= 50) {
      color = 'lightRed';
    } else if (value > 50 && value <= 75) {
      color = 'lightBlue';
    } else if (value > 75 && value <= 100) {
      color = 'blue';
    }

    setDiagramColor(color);
  }, [value, diagramColor]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  let hValue = diagramWidth - 10;
  if (hValue < 0) {
    hValue = 0;
  }

  // RENDER: GarminBarDiagram
  return (
    <div className={styles.garminBarDiagram} ref={diagramRef}>
      <svg width={diagramWidth} height={DIAGRAM_HEIGHT} viewBox={`0 0 ${diagramWidth} ${DIAGRAM_HEIGHT}`}>
        <path d={`M0,0 h${hValue} q10,0 10,8 q0,8 -10,8 h-${hValue} z`} fill={css.colorGrey4} />

        {(barWidth - 10) >= 0 &&
          <path
            d={`M0,0 h${barWidth - 10} q10,0 10,8 q0,8 -10,8 h-${barWidth - 10} z`}
            className={classNames(styles.diagram, styles[looks])}
          />
        }
      </svg>

      {footerText && <div className={styles.footer}>{footerText}</div>}
    </div>
  );
};

export default GarminBarDiagram;
