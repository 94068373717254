export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_ASSESSMENT_PENDING = 'GET_ASSESSMENT_PENDING';
export const GET_ASSESSMENT_FULFILLED = 'GET_ASSESSMENT_FULFILLED';
export const GET_ASSESSMENT_REJECTED = 'GET_ASSESSMENT_REJECTED';

export const getAssessment = (id) => {
  return {
    type: GET_ASSESSMENT,
    payload: id
  };
};

export const getAssessmentFulfilled = (assessment) => {
  return {
    type: GET_ASSESSMENT_FULFILLED,
    payload: assessment
  };
};

export const getAssessmentRejected = ({ error }) => {
  return {
    type: GET_ASSESSMENT_REJECTED,
    payload: { error }
  };
};

