import { get } from 'api';
import * as action from 'store/actions';
import {call, put, take} from 'redux-saga/effects';

export function* listAssessments() {
  try {
    const {status,ok,data} = yield call(get, '/core/assessments');

    if (ok && status === 200) {
      yield put(action.listAssessmentsFulfilled({data})
      );
    } else {
      yield put(action.listAssessmentsRejected(data));
    }
  } catch (error) {
    yield put(action.listAssessmentsRejected({error}));
  }
}

export default function* watchlistAssessmentsRequest() {
  while (true) {
    try {
      yield take(action.LIST_ASSESSMENTS);
      yield call(listAssessments);
    } catch (error) {
      yield put(action.listAssessmentsRejected({error}));
    }
  }
}

