import React, {useEffect, useRef, useState} from 'react';
import styles from './WellBeingResult.module.scss';

import {useDispatch, useSelector} from 'react-redux';

import * as moment from 'moment';
import 'moment/locale/de';

import {getWellBeingIndexHistory} from 'store/actions';
import * as fromWellBeingSelectors from 'store/selectors/wellBeing';

import {ReactComponent as VeryHappy} from 'assets/well-being/very-happy.svg';
import {ReactComponent as Happy} from 'assets/well-being/happy.svg';
import {ReactComponent as Neutral} from 'assets/well-being/neutral.svg';
import {ReactComponent as Sad} from 'assets/well-being/sad.svg';
import {ReactComponent as VerySad} from 'assets/well-being/very-sad.svg';

import { useTranslate } from 'utils/translator';
import {LANGUAGES} from 'utils/configuration/const/languages';
import {WELL_BEING_MODES} from 'utils/configuration/const/well-being';
import { setDecimalSeperator } from 'utils/userpreference';

import {InfoTitle, Callout, ProgressCircle, Button, WellbeingDiagram, Card, PillGroup, Modal} from 'ui/basic';

const MAX_WELLBEING_INDEX = 5;

const WELL_BEING_EMOJIS = {
  1: <VerySad/>,
  2: <Sad/>,
  3: <Neutral/>,
  4: <Happy/>,
  5: <VeryHappy/>
};


const WellBeingResult = (props) => {
  const {wellBeing } = props;

  const translate = useTranslate();
  const ref = useRef();

  const [showModal, setShowModal] = useState(false);
  const [modalHeader, setModalHeader] = useState();
  const [modalHeaderPosition, setModalHeaderPosition] = useState('left');
  const [modalPreHeader, setModalPreHeader] = useState()
  const [modalContent, setModalContent] = useState();

  const [showChart, setShowChart] = useState(false);
  const [filteredWellBeingHistory, setFilteredWellBeingHistory] = useState([]);
  const [currentWellbeingHistory, setCurrentWellbeingHistory] = useState([]);
  const [chartViewMode, setChartViewMode] = useState(WELL_BEING_MODES.DAILY);
  const [wellBeingYear, setWellBeingYear] = useState();

  // STORE
  const dispatch = useDispatch();
  const wellBeingHistory = useSelector(fromWellBeingSelectors.getWellBeingHistory);
  // const wellBeingLoading = useSelector(fromWellBeingSelectors.getWellBeingLoading);

  const currentLanguage = useSelector(
    state => state.localisation && state.localisation.currentLanguage
  );


  const getDate = (date) => {
    if (chartViewMode === WELL_BEING_MODES.DAILY) {
      return moment(date).format('DD.MM.');
    }

    if (chartViewMode === WELL_BEING_MODES.WEEKLY) {
      return date;
    }

    const m = moment(date);
    m.locale(currentLanguage === LANGUAGES.DE ? 'de' : 'en');
    return m.format('MMMM');
  };

  useEffect(() => {
    let currentHistory;

    if (!filteredWellBeingHistory || filteredWellBeingHistory.length === 0) {
      currentHistory = [];
    } else {
      currentHistory = [...filteredWellBeingHistory];
    }

    setCurrentWellbeingHistory(currentHistory);

  }, [chartViewMode, filteredWellBeingHistory]);


  useEffect(() => {
    if (wellBeingHistory) {
      const internalWellBeingHistory = [...wellBeingHistory];

      setFilteredWellBeingHistory(internalWellBeingHistory);
    }
  }, [wellBeingHistory, chartViewMode]);

  return (
    <Card>
      <div className={styles.wellBeingResult} ref={ref}>
        <InfoTitle
          title={translate('wellbeing_ind_result_title') || 'Wohlbefinden'}
          onClick={() => {
            setModalHeader(translate('wellbeing_ind_result_title') || 'Wohlbefinden');
            setModalHeaderPosition('left');
            setModalPreHeader(null);
            setModalContent(translate('wellbeing_ind_result_info_modal_description') || 'Wohlbefinden Beschreibung');

            setShowModal(true);
          }}
        />

        <div className={styles.wellBeingIndexPanel}>
          <div className={styles.wellBeingIndex}>
          <span className={styles.title}>
            {translate('wellbeing_ind_result_body_text') || 'Dein Wellbeing-Index'}
          </span>
            <span className={styles.index}>{`${setDecimalSeperator(wellBeing.wellbeing) || '-'} / 5`}</span>
            <div className={styles.progress}>
              <ProgressCircle
                size='M'
                progress={Math.ceil(wellBeing.wellbeing * 100 / MAX_WELLBEING_INDEX)}
                showWellBeingIcon={true}
              />
            </div>
          </div>
          {showChart ?
            <Button
              looks={'tertiary'}
              size={'S'}
              onClick={() => setShowChart(false)}
            >
              {translate('wellbeing_ind_result_hide_progress_btn') || 'Verlauf ausblenden'}
            </Button> :
            (currentWellbeingHistory && currentWellbeingHistory.length> 0) &&
            <Button
              looks={'tertiary'}
              size={'S'}
              onClick={() => setShowChart(true)}
            >
              {translate('wellbeing_ind_result_show_progress_btn') || 'Verlauf anzeigen'}
            </Button>

          }
        </div>

        {/* CALLOUT */}
        { (!showChart && wellBeing.quote) &&
          <div className={styles.calloutBlock}>
            <Callout trianglePosition={'left'}>
              {wellBeing.quote}
            </Callout>
          </div>
        }

        {/* WELLBEING CHART */}
        {showChart &&
          <div className={styles.chart}>
            {/*DATE CONTROLS*/}
            <div className={styles.controls}>
              <PillGroup
                pillItems={[
                  {id: WELL_BEING_MODES.DAILY, label: translate('wellbeing_ind_result_toggle_days') || 'Tage'},
                  // {id: WELL_BEING_MODES.WEEKLY, label: translate('wellbeing_ind_result_toggle_weeks') || 'Wochen'},
                  {id: WELL_BEING_MODES.WEEKLY, label: 'Weeks'},
                  {id: WELL_BEING_MODES.MONTHLY, label: translate('wellbeing_ind_result_toggle_months') || 'Monate'},
                ]}
                onPillClick={(pillItem) => {
                  if (chartViewMode === pillItem.id) {
                    return;
                  }

                  dispatch(getWellBeingIndexHistory(pillItem.id));
                  setChartViewMode(pillItem.id);
                }}
              />

              {/*YEAR*/}
              <div>{wellBeingYear}</div>
            </div>

            <WellbeingDiagram
              points={currentWellbeingHistory.map(d => d && d.index)}
              dates={chartViewMode === WELL_BEING_MODES.WEEKLY ?
                currentWellbeingHistory.map(d => d && d.week ? d.week.split('-')[1] : 0) :
                currentWellbeingHistory.map(d => d && d.date)
              }
              mode={chartViewMode}
              infoModalIsOpen={showModal}
              onPaginationAvailabilities={([pageLeftAvailable, pageRightAvailable, pagePosition]) => {
                if (pagePosition < 0) {
                  return;
                }

                const position = 6 * pagePosition;
                const chunk = wellBeingHistory.slice(position, position + 6);
                const lastChunk = chunk[chunk.length - 1];

                if (!lastChunk) {
                  return;
                }

                // YEAR
                let year = '-';
                if (lastChunk.week) {
                  year = lastChunk.week.split('-')[0];
                } else {
                  year = new Date(lastChunk.date).getFullYear();
                }

                setWellBeingYear(year);
              }}
              onClick={(date, wellBeingIndex) => {
                setModalPreHeader(
                  <div className={styles.wellBeingInfoPreHeader}>
                    {WELL_BEING_EMOJIS[Math.ceil(wellBeingIndex)]}
                  </div>
                );

                setModalHeaderPosition('center');
                setModalHeader(
                  <div className={styles.wellBeingInfoHeader}>
                    <div>
                      {translate(
                        `wellbeing_ind_${chartViewMode}_result_info_modal_title`,
                        ['{{date}}', getDate(date)]
                      ) || `Dein Wellbeing vom ${getDate(date)}`}
                    </div>
                    <div className={styles.index}>{`${wellBeingIndex} / 5`}</div>
                  </div>
                );

                const wbHistory = filteredWellBeingHistory.find(fwbh => fwbh.date === date);
                if (wbHistory && wbHistory.comment) {
                  setModalContent(
                    <Callout>
                      <div style={{textAlign: 'center'}}>{wbHistory.comment}</div>
                    </Callout>
                  );
                } else {
                  setModalContent(null);
                }

                setShowModal(true);
              }}
            />
          </div>
        }

        <div className={styles.xsVisibleButton}>
          {showChart ?
            <Button
              looks={'tertiary'}
              size={'S'}
              onClick={() => setShowChart(false)}
            >
              {translate('wellbeing_ind_result_hide_progress_btn') || 'Verlauf ausblenden'}
            </Button> :
            (currentWellbeingHistory && currentWellbeingHistory.length> 0) &&
            <Button
              looks={'tertiary'}
              size={'S'}
              onClick={() => setShowChart(true)}
            >
              {translate('wellbeing_ind_result_show_progress_btn') || 'Verlauf anzeigen'}
            </Button>
          }
        </div>

        {showModal &&
        <Modal
          header={modalHeader}
          headerPosition={modalHeaderPosition}
          preHeader={modalPreHeader}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          onClose={() => setShowModal(false)}
        >
          {modalContent}
        </Modal>
        }
      </div>
    </Card>
  )
};

export default WellBeingResult;
