// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionRequirements.module.scss';
import stylesMultiSelect from 'ui/basic/forms/MultiSelect/MultiSelect.module.scss';

// ASSETS
import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDownSearchable, InputNext, MultiSelect, Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { translate } from 'utils/translator/translator';

// STORE

// CONFIG & DATA
// const Config = {};

export const partTimeOption = {
  label: 'cp_job_requirements_parttime_question',
  name: 'partTime',
  isInput: true,
  inputLabel: 'cp_job_requirements_parttime_placeholder',
  inputType: 'number'
}

export const salaryOption = {
  label: 'cp_job_requirements_salary_question',
  name: 'salarySpecific',
  isCustom: (optionValue = ['', 'EUR'], setValue) => (
    <div className={stylesMultiSelect.custom} onClick={(event) => {
      event.stopPropagation();
    }}>
      <InputNext
        size='responsive'
        label={translate('cp_job_requirements_salary_placeholder')}
        type='number'
        value={optionValue[0]}
        onChange={(salary) => setValue([salary, optionValue[1]])}
      />
      <DropDownSearchable
        size='responsive'
        placeholder={optionValue[1]?.toUpperCase()}
        activeValue={optionValue[1]}
        options={[
          {value: 'eur', label: 'EUR'},
          {value: 'usd', label: 'USD'},
          {value: 'chf', label: 'CHF'},
          {value: 'gbp', label: 'GBP'}
        ]}
        onChange={(currencyOption) => {
          if (currencyOption) {
            setValue([optionValue[0], currencyOption.value]);
          }
        }}
      />
    </div>
  )
}

export const remoteOption = {
  label: 'cp_job_requirements_remote_question',
  name: 'remote',
  dropDownPlaceholder: 'cp_job_requirements_remote_placeholder',
  dropDownFilterPlaceholder: 'search_lbl',
  isDropDown: new Array(10).fill(10).map((v, index) => ({
    label: `${v * (index + 1)}% Remote`,
    value: v * (index + 1)
  }))
}


const REQUIREMENT_OPTIONS = [
  partTimeOption,
  salaryOption,
  remoteOption
]


// COMPONENT: QuestionRequirements
const QuestionRequirements = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
  } = props;

  const TrashIcon = IconsSvg.Trash;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // MULTI SELECT
  const [ selectedOptions, setSelectedOptions ] = useState([]);
  useEffect(() => {
    if (answer.value && answer.value.selectedOptions) {
      setSelectedOptions(answer.value.selectedOptions);
    }
    else {
      setSelectedOptions([]);
    }
  }, [answer.value]);

  const [requirementOptions, setRequirementOptions] = useState(REQUIREMENT_OPTIONS);
  useEffect(() => {
    const requirementOptionsInternal = [...REQUIREMENT_OPTIONS];

    if (selectedOptions.length > 0) {
      const partTime = selectedOptions.find(selectedOption => selectedOption.name === 'partTime');
      if (partTime) {
        requirementOptionsInternal[0] = partTime;
      }

      const salary = selectedOptions.find(selectedOption => selectedOption.name === 'salarySpecific');
      if (salary) {
        requirementOptionsInternal[1] = salary;
      }

      const remote = selectedOptions.find(selectedOption => selectedOption.name === 'remote');
      if (remote) {
        requirementOptionsInternal[2] = remote;
      }

      setRequirementOptions(requirementOptionsInternal);
    }
  }, [selectedOptions]);

  const handleSelect = (selectedOptions) => {
    publishNewAnswer(selectedOptions, customRequirements);
  };

  // HELPERS
  const publishNewAnswer = (selectedOptions, customRequirements) => {
    setSelectedOptions(selectedOptions);
    setCustomRequirements(customRequirements);

    const isValid = () => {
      // check options
      const hasSelectedOptions = selectedOptions.length;
      const allSelectedOptionsOk = selectedOptions
      .map(({ name, value }) => (name === 'salarySpecific' ? value?.[0] : value))
      .every(Boolean);

      // check extra requirements
      const anyCustomRequirementsOk = customRequirements.some(Boolean);

      return allSelectedOptionsOk && (anyCustomRequirementsOk || hasSelectedOptions);
    }

    const newAnswer = {
      value: {
        selectedOptions,
        customRequirements
      },
      name: 'requirements',
      isValid: isValid()
    };

    onAnswer(newAnswer);
  };

  // CUSTOM REQUIREMENTS
  const [ customRequirements, setCustomRequirements ] = useState([]);
  useEffect(() => {
    if (answer.value && answer.value.customRequirements) {
      setCustomRequirements(answer.value.customRequirements);
    }
    else {
      setCustomRequirements([]);
    }
  }, [answer.value]);


  const handleAdd = () => {
    setCustomRequirements([...customRequirements, '']);
  };

  const setCustomRequirement = (index, newRequirement) => {
    customRequirements[index] = newRequirement;
    const newCustomRequirements = [...customRequirements];
    setCustomRequirements(newCustomRequirements);

    publishNewAnswer(selectedOptions, customRequirements);
  };


  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: QuestionRequirements
  return (
    <div className={classNames(styles.questionRequirements)}>

      {/* MULTI SELECT */}
      <div className={styles.formRow}>
        <MultiSelect
          size={size}
          options={requirementOptions}
          onChange={handleSelect}
        />

      </div>

      {/* CUSTOM */}
      { customRequirements.length > 0 && (
        <div className={classNames(styles.formRow, styles.biggerMargin)}>
          { customRequirements.map((requirement, index) => (
            <div key={index} className={styles.formRow}>
              <div className={styles.customInput}>
                <InputNext
                  size={size}
                  placeholder={translate('cp_job_requirements_add_input_placeholder') || 'Anforderung hinzufügen'}
                  value={requirement}
                  onChange={(value) => setCustomRequirement(index, value)}
                />
                <div
                  className={styles.deleteInput}
                  onClick={() => {
                    const customRequirementsInternal = [...customRequirements];
                    customRequirementsInternal.splice(index, 1);
                    setCustomRequirements(customRequirementsInternal);

                    publishNewAnswer(selectedOptions, customRequirementsInternal);
                  }}
                >
                  <TrashIcon />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* ADD CUSTOM */}
      <div className={classNames(styles.formRow, styles.biggerMargin, styles.center)}>
        <Button
          looks='tertiary'
          size={size === 'responsive' ? 'L' : 'S'}
          leadingIcon={IconsSvg.Plus}
          onClick={handleAdd}
        >
          {translate('cp_job_requirements_add_btn') || 'Weitere Anforderung hinzufügen'}
        </Button>
      </div>

    </div>
  );
};

export default QuestionRequirements;
