import React, { memo } from 'react';
import styles from './RmpBipolarDiagram.module.scss';
import {isValid} from 'utils/numbers';
import { setDecimalSeperator } from 'utils/userpreference';

const RMP_FIRST_MARKER_POSITION = 0.84;
const RMP_SECOND_MARKER_POSITION = 1.7;

const MIN_DISTANCE = 0.05;
const HIGHEST_VALUE = 2;

const RmpBipolarDiagram = memo((props) => {
  const {score, range, width, showNumbers} = props;

  const height = showNumbers ? 42 : 15;
  const viewBox =`0 0 ${width} ${height}`;
  const step = (width / 4);
  const center = width / 2;
  /*
  In order to show the markers position properly on diagram, some position changes need to be done:
  - to show blue markers right on the center of the vertical lines we need to subtract 7.5 from its x coordinate.
    7.5 - is the half of the blue marker's width.
  - if the score is equal to 4 (last position of the diagram) we need to subtract 15, otherwise blue marker is not fully visible.
    15 - is the width of the blue marker.
  - if the score is equal to 0 (first position of the diagram) x position is set to 0, otherwise it is also not fully visible.
   */
  const markerPosition = (score === 0) ? 0 : (score === 4 ? (step * score) - 15 : (step * score) - 7.5);

  let progressX;
  let progressWidth;

  if ((step * score) < center) {
    progressX = step * score;
    progressWidth = center - (step * score);
  } else {
    progressX = center;
    progressWidth = (step * score) - center;
  }

  let range1;
  let range2;
  if (range && range.length > 0) {
    range1 = range[0];
    range2 = range[1];

    if (range1 === range2) {
      if (range2 + MIN_DISTANCE > HIGHEST_VALUE) {
        range1 -= MIN_DISTANCE;
      } else {
        range2 += MIN_DISTANCE;
      }
    }
  }

  return (
    <svg className={styles.rmpBipolarDiagram} width={width} height={height} viewBox={viewBox}>
      <rect x={progressX} width={isValid(progressWidth) ? progressWidth : 0} height="15" fill={styles.colorPrimary4}/>

      {/*RANGE*/}
      {(range && range.length > 0) &&
      <rect
        x={range1 * step}
        width={isValid((range2 - range1) * step) ? (range2 - range1) * step : 0}
        height="15" rx="7.5" fill={styles.colorPrimary3}
      />
      }

      <rect x="0" y="7" width={isValid(width) ? width : 0} height="1" fill={styles.colorGrey3}/>
      <rect x={center} width={1} height={15} fill={styles.colorGrey3}/>
      <rect x={center + (step * RMP_FIRST_MARKER_POSITION)} y={3} width={1} height={9} fill={styles.colorGrey3}/>
      <rect x={center + (step * RMP_SECOND_MARKER_POSITION)} y={5} width={1} height={5} fill={styles.colorGrey3}/>
      <rect x={center - (step * RMP_FIRST_MARKER_POSITION)} y={3} width={1} height={9} fill={styles.colorGrey3}/>
      <rect x={center - (step * RMP_SECOND_MARKER_POSITION)} y={5} width={1} height={5} fill={styles.colorGrey3}/>

      {/*RESULT MARKER*/}
      {isValid(score) &&
      <rect x={markerPosition} width="15" height="15" rx="7.5" fill={styles.colorPrimary1}/>
      }

      {/*NUMBERS*/}
      {showNumbers &&
      <>
        {/* 3, 7, 14 are for centralizing numbers right under vertical lines, -2 to display comma correctly */}
        <text x={(center - (step * RMP_FIRST_MARKER_POSITION)) - 14} y={height - 2} className={styles.text}>{setDecimalSeperator(-RMP_FIRST_MARKER_POSITION, 2)}</text>
        <text x={(center - (step * RMP_SECOND_MARKER_POSITION)) - 7} y={height - 2} className={styles.text}>{setDecimalSeperator(-RMP_SECOND_MARKER_POSITION, 1)}</text>
        <text x={center - 3} y={height - 2} className={styles.text}>0</text>
        <text x={(center + (step * RMP_FIRST_MARKER_POSITION)) - 14} y={height - 2} className={styles.text}>{setDecimalSeperator(RMP_FIRST_MARKER_POSITION, 2)}</text>
        <text x={(center + (step * RMP_SECOND_MARKER_POSITION)) - 7} y={height - 2} className={styles.text}>{setDecimalSeperator(RMP_SECOND_MARKER_POSITION, 1)}</text>
      </>
      }
    </svg>
  );
});

RmpBipolarDiagram.defaultProps = {
  width: 250,
  score: 2,
  showNumbers: false
};

export default RmpBipolarDiagram;
