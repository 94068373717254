import * as fromActionTypes from 'store/actionTypes/bqActions';

// GET ACTION
export const getAction = (actionId) => {
  return {
    type: fromActionTypes.GET_ACTION,
    payload: {actionId}
  };
};

export const getActionFulfilled = ({ actionInfo}) => {
  return {
    type: fromActionTypes.GET_ACTION_FULFILLED,
    payload: { actionInfo }
  };
};

export const getActionRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_ACTION_REJECTED,
    payload: { error }
  };
};

// EXECUTE ACTION
export const executeAction = (executeActionDto) => {
  return {
    type: fromActionTypes.EXECUTE_ACTION,
    payload: {executeActionDto}
  };
};

export const executeActionFulfilled = ({executedActionInfo}) => {
  return {
    type: fromActionTypes.EXECUTE_ACTION_FULFILLED,
    payload: {executedActionInfo}
  };
};

export const executeActionRejected = ({ error }) => {
  return {
    type: fromActionTypes.EXECUTE_ACTION_REJECTED,
    payload: { error }
  };
};
