export const getWellBeingHistory = store => (
  (store.wellBeing && store.wellBeing.history) && store.wellBeing.history
)

export const getMyWellBeing = store => (
  (store.wellBeing && store.wellBeing.myWellBeing) && store.wellBeing.myWellBeing
);

export const getOrgWellBeingIndex = store => (
  (store.wellBeing && store.wellBeing.orgIndex) && store.wellBeing.orgIndex
)
export const getOrgWellBeingIndexUserCount = store => (
  (store.wellBeing && store.wellBeing.orgIndexUserCount)  && store.wellBeing.orgIndexUserCount
)
export const getOrgWellBeingIndexNotEnoughData = store => (
  (store.wellBeing && store.wellBeing.orgIndexNotEnoughData) && store.wellBeing.orgIndexNotEnoughData
)

export const getOrgWellBeingHistory = store => (
  (store.wellBeing && store.wellBeing.orgHistory) && store.wellBeing.orgHistory
)
