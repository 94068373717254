export const getRegistrationDomains = store => (
  (store.settings && store.settings.registrationDomains) ? store.settings.registrationDomains : []
)

export const getAllFeatures = store => store.settings && store.settings.allFeatures;

export const selectCompanyFeatures = store => store.settings?.features || [];

export const getUserSettings = store => store.settings && store.settings.userSettings;
export const getUserSettingsError = store => store.settings && store.settings.userSettingsError;
export const getUpdateUserSettingsProcessing = store => store.settings && store.settings.updateUserSettingsProcessing;
export const getUpdateUserSettingsSuccess = store => store.settings && store.settings.updateUserSettingsSuccess;
export const selectLanguageIsChanged = store => store.settings && store.settings.languageChanged;

export const getCompanySettings = store => store.settings && store.settings.companySettings;
export const getCompanySettingsError = store => store.settings && store.settings.companySettingsError;
export const getUpdateCompanySettingsProcessing = store => store.settings && store.settings.updateCompanySettingsProcessing;

export const getUpdateCompanyFeaturesProcessing = store => store.settings && store.settings.updateCompanyFeaturesProcessing;
