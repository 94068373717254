import React, {useState} from 'react';
import styles from './DragAndDrop.module.scss';
import {ReactComponent as Add} from 'assets/icons/icn_add.svg';
import {ReactComponent as Minus} from 'assets/icons/icn_minus.svg';
import {ReactComponent as Anchor} from 'assets/icons/icn_arrow_anchor.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useTranslate } from 'utils/translator';

const DROPPABLE1 = 'droppable1';
const DROPPABLE2 = 'droppable2';

const SELECTED_ITEMS_MAX_COUNT = 3;

const DEFAULT_PLACEHOLDER =
  'Bewege die 3 Begriffe, die dir am wichtigsten sind hier her und bringe sie in eine Reihenfolge.' +
  'Der Begriff, den du am wichtigsten einstufst, ist an höchster Stelle.';


const DragAndDrop = (props) => {
  const {items, onDrop} = props;

  const translate = useTranslate();

  const [localItems, setLocalItems] = useState(items || []);
  const [selectedItems, setSelectedItems] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const getItemStyle = (droppableId, isDragging, draggableStyle) => {
    return {
      boxShadow: isDragging ? '0px 2px 20px rgba(0, 0, 0, 0.2)' : 'none',
      ...draggableStyle,
      ...(!isDragging ? {} : {
        marginTop: '-50px',
        marginBottom: '-8px',
      })
    }
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source.droppableId === DROPPABLE1 ? localItems : selectedItems,
        source.index,
        destination.index
      );

      if (source.droppableId === DROPPABLE1) {
        setLocalItems(items);
      } else {
        setSelectedItems(items);
      }

      if (onDrop) {
        onDrop(items);
      }
    } else {
      const result = move(
        source.droppableId === DROPPABLE1 ? localItems : selectedItems,
        source.droppableId === DROPPABLE1 ? selectedItems : localItems,
        source,
        destination
      );

      setLocalItems(result.droppable1);
      setSelectedItems(result.droppable2);

      if (onDrop) {
        onDrop(result.droppable2);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={styles.dragAndDrop}>

        {/*SOURCE ITEMS*/}
        <Droppable droppableId={DROPPABLE1}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.left}
            >
              {localItems.map((localItem, index) => (
                <Draggable
                  key={localItem.id}
                  draggableId={localItem.id}
                  index={index}
                  isDragDisabled={selectedItems.length === SELECTED_ITEMS_MAX_COUNT}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={styles.item}
                      style={getItemStyle(
                        DROPPABLE1,
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {localItem.name}
                      <Add onClick={() => {
                        if (selectedItems.length === SELECTED_ITEMS_MAX_COUNT) {
                          return;
                        }

                        const altLocalItems = [...localItems];
                        const altSelectedItems = [...selectedItems];

                        const itemIndex = altLocalItems.indexOf(localItem);

                        //delete item from local items
                        altLocalItems.splice(itemIndex, 1);
                        setLocalItems(altLocalItems);

                        //add item to selected items
                        altSelectedItems.push(localItem);
                        setSelectedItems(altSelectedItems);

                        if (onDrop) {
                          onDrop(altSelectedItems);
                        }
                      }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>

        <Droppable droppableId={DROPPABLE2}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.right}
            >
              <div className={styles.selectedItems}>
                {selectedItems.map((selectedItem, index) => (
                  <Draggable key={selectedItem.id} draggableId={selectedItem.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.item}
                        style={getItemStyle(
                          DROPPABLE2,
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {selectedItem.name}
                        <Minus onClick={() => {
                          const altLocalItems = [...localItems];
                          const altSelectedItems = [...selectedItems];

                          const itemIndex = altSelectedItems.indexOf(selectedItem);

                          //delete selected item from selected items
                          altSelectedItems.splice(itemIndex, 1);
                          setSelectedItems(altSelectedItems);

                          //add selected item to local items
                          altLocalItems.push(selectedItem);
                          setLocalItems(altLocalItems);

                          if (onDrop) {
                            onDrop(altSelectedItems);
                          }
                        }}/>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>

              {/*PLACEHOLDER*/}
              {selectedItems.length === 0 &&
              <div className={styles.placeholder}>
                {translate('9levels_ass_dnd_placeholder') || DEFAULT_PLACEHOLDER}
                <div className={styles.label}>
                  <Anchor/>
                  drag & drop
                </div>
              </div>
              }
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
};

DragAndDrop.defaultProps = {
  items: [
    {id: '1', name: 'Wertschätzung der Einzigartigkeit'},
    {id: '2', name: 'Toller Firmenwagen'},
    {id: '3', name: 'Gutes Aussehen'},
    {id: '4', name: 'Eigene Sekretärin'}
  ]
};

export default DragAndDrop;
