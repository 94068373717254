export const PLATFORM_ROLES = {
  PLATFORM_USER: 'PLATFORM_USER',

  COMPANY_USER: 'COMPANY_USER',

  TEAM_MEMBER: 'TEAM_MEMBER',

  TEAM_LEADER: 'TEAM_LEADER',

  CANDIDATE: 'RECRUITING_CANDIDATE',

  COMPANY_LEADER: 'COMPANY_LEADER',
  COMPANY_LEADER_CUSTOM: 'COMPANY_LEADER_CUSTOM',

  COMPANY_ADMIN: 'COMPANY_ADMINISTRATOR',

  BLUQUIST_ADMIN: 'BLUQUIST_ADMIN',

};

export const MAPPED_PLATFORM_ROLES = {
  COMPANY_USER: 'user',

  TEAM_MEMBER: 'teamMember',

  TEAM_LEADER: 'teamLeader',

  RECRUITING_CANDIDATE: 'candidate',

  COMPANY_LEADER: 'companyLeader',
  COMPANY_LEADER_CUSTOM: 'companyLeader',

  COMPANY_ADMINISTRATOR: 'orgAdmin',

  BLUQUIST_ADMIN: 'bluquistAdmin',
};
