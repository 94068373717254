import * as fromActionTypes from 'features/+admin/store/actionTypes/planOverview';

export const getPlans = () => {
  return {
    type: fromActionTypes.GET_PLANS,
    payload: {}
  };
};

export const getPlansFulfilled = ({plans}) => {
  return {
    type: fromActionTypes.GET_PLANS_FULFILLED,
    payload: { plans }
  };
};

export const getPlansRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_PLANS_REJECTED,
    payload: { error }
  };
};
