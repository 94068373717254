import React, {useEffect, useState} from 'react';
import styles from './Support.module.scss';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import {initRequestSupport, requestSupport} from 'store/actions';

import { useTranslate } from 'utils/translator';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';
import {Button, TextArea} from 'ui/basic';

const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_'
};

const Support = () => {

  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const supportRequestedSuccessfully = useSelector(state => state.support && state.support.success);

  const [answer, setAnswer] = useState();

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const finalPage =
    <div className={styles.finalPage}>
      <div className={styles.emoji}>
        <span role='img' aria-label='emoji'>📨</span>
      </div>
      <div className={styles.finalPageTitle}>
        {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}support_final_page_descr`) ||
        'Vielen Dank für deine Anfrage. Wir helfen dir schnellstmöglich.'}
      </div>
      <Button
        size='M'
        onClick={() => history.push('/')}
      >
        {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}support_final_page_btn`) || 'Zurück zu bluquist'}
      </Button>
    </div>

  useEffect(() => {
    if (supportRequestedSuccessfully) {
      setFeedbackSubmitted(true);
    }
  }, [supportRequestedSuccessfully]);

  useEffect(() => {
    return () => {
      dispatch(initRequestSupport());
    }
  }, [dispatch]);

  return (
    <div className={styles.support}>
      {/*HEADER*/}
      <div className={styles.header}>
        <span>{translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}support_page_header_title`) || 'Support'}</span>
        <div
          className={styles.close}
          onClick={() => history.goBack()}
        >
          {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}support_page_header_cancel`) || 'abbrechen'}
        </div>
      </div>

      {/*CONTENT*/}
      <div className={styles.content}>
        <div className={styles.gridContent}>
          <div className={styles.main}>
            {feedbackSubmitted ?
              finalPage :
              <>
                <div className={styles.title}>
                  {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}support_page_title`) || 'Wie können wir dir helfen?'}
                </div>
                <div className={styles.description}>
                  {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}support_page_description`) ||
                  'Falls du Probleme oder Fragen hast schick uns hier gerne direkt eine Anfrage und' +
                  ' wir melden uns innerhalb von 24 Stunden bei dir via Mail. '
                  }
                </div>

                <div className={styles.block}>
                  <TextArea
                    label={translate('by_support_page_question_placeholder') || 'Deine Antwort'}
                    onChange={(value) => {
                      setAnswer(value);
                    }}
                  />
                </div>

                <Button
                  size='M'
                  disabled={!answer}
                  onClick={() => {
                    if (!answer) {
                      return;
                    }

                    dispatch(requestSupport({
                      problemDescription: answer,
                      name: me && (me.firstName + ' ' + me.lastName),
                      email: me && me.mail
                    }))
                  }}
                >
                  {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}support_page_submit_btn`) || 'Absenden'}
                </Button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default Support;
