import React, {useEffect, useState} from 'react';
import styles from './UserAssessmentsResults.module.scss';

import classNames from 'classnames';

import * as api from 'api';

import {useDispatch, useSelector} from 'react-redux';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import {listAssessments} from 'store/actions';

import { useTranslate } from 'utils/translator';
import {getMappedNineLevelsResults} from 'utils/assessment';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';
import { scrollIntoView } from 'utils/scrolling';

import {
  Link,
  Profile
} from 'ui/basic';

import {
  Big5Result,
  CompetenciesResult,
  NineLevelsResult,
  PotentialResult,
  RmpResult,
  WorkPreferencesResult,
  SkillsResult,
  CliftonResult
} from 'ui/molecules';


const ASSESSMENT_RESULT_TITLES = {
  [ASSESSMENT_TYPES.BIG5]: 'big5_results_title',
  [ASSESSMENT_TYPES.POTENTIAL]: 'potential_results_title',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'workpreferences_results_title',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'keycomp_results_title',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'leadershipcomp_results_title',
  [ASSESSMENT_TYPES.RMP]: 'rmp_results_title',
  [ASSESSMENT_TYPES.NINE_LEVELS]: '9levels_results_title',
};


const UserAssessmentsResults = (props) => {
  const {user, token, isCandidate, results} = props;

  const dispatch = useDispatch();
  const translate = useTranslate();

  const [ userImage, setUserImage ] = useState();
  useEffect(() => {
    if (userImage || !token) {
      return;
    }

    api.get_base64('/core/user/picture', {}, {Authorization: `Bearer ${token}`})
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setUserImage(`data:image/png;base64, ${data}`);
      }
    })
    .catch(error => {
      console.error(error.message);
    })
  }, [ token, userImage ]);

  // ASSESSMENTS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);
  useEffect(() => {
    dispatch(listAssessments());
  }, [dispatch]);

  const [reorderedResults, setReorderedResults] = useState([]);
  useEffect(() => {
    if (reorderedResults.length > 0) {
      return;
    }

    const resultsInternal = [];

    if (results && results.length > 0) {
      assessmentsFromStore.forEach(assessment => {
        const foundItem = results.find(resultItem => resultItem.assessment === assessment.id);
        if (foundItem) {
          resultsInternal.push({
            ...foundItem,
            title: assessment.title
          })
        }
      });

      setReorderedResults(resultsInternal);
    }
  }, [assessmentsFromStore, reorderedResults, results]);

  const renderResultComponent = (resultItem) => {
    const assessmentType = resultItem.assessment;

    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return <Big5Result results={resultItem.result.results} requestFeedback={false} />;
      case ASSESSMENT_TYPES.POTENTIAL:
        return <PotentialResult showCallout results={resultItem.result.results} requestFeedback={false} />
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return <WorkPreferencesResult results={resultItem.result.results} />
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return (
          <CompetenciesResult
            assessmentType={assessmentType}
            results={resultItem.result.results}
          />
        )
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return (
          <CompetenciesResult assessmentType={assessmentType} results={resultItem.result.results} />
        )
      case ASSESSMENT_TYPES.RMP:
        return (
          <RmpResult results={resultItem.result.results} requestFeedback={false} />
        )
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return (
          <NineLevelsResult
            results={getMappedNineLevelsResults(resultItem.result.results)}
            signatureValues={resultItem.result.results.filter(rItem => rItem.id.includes('highvalues'))}
            requestFeedback={false}
          />
        )
      default:
        const thisAssessment = assessmentsFromStore.find(a => a.id === assessmentType);
        if (thisAssessment && thisAssessment.render_results === 'clifton') {
          return (
            <CliftonResult strengths={resultItem.result.results} />
          )
        } else {
          return (
            <SkillsResult
              assessmentType={assessmentType}
              results={resultItem.result.results}
            />
          )
        }
    }
  };

  return (
    <div className={styles.userAssessmentsResults}>
      <div className={styles.gridContainer}>
        <div className={styles.personalInfo}>
          <div className={classNames(styles.subBlock, {[styles.forCandidate]: isCandidate})}>

            {/*if user's data is not anonymized*/}
            {(user && !user.isAnonymous) ?
              <div className={styles.profile}>
                <Profile
                  user={user}
                  userImage={userImage}
                  showCompanyData={false}
                />
              </div> :
              (isCandidate &&
                <div style={{marginBottom: '32px'}}>
                  <div className='blu-typeXxs'>{translate('candidate_profile') || 'Kandidat:innen-Profil'}</div>
                  <div className='blu-typeHint' style={{marginTop: '4px'}}>
                    {user.candidateData ? user.candidateData.currentPosition : ''}
                  </div>
                </div>
              )
            }

            {(reorderedResults.length > 0) &&
            <div className={styles.anchors}>
              <div className={styles.title}>
                {translate('employee_assessements_quick_access') || 'Schnellzugriff'}
              </div>
              {reorderedResults.map((resultItem, index) => (
                <Link
                  key={`assessment-anchor-${index}`}
                  type={'anchor'}
                  onClick={() => scrollIntoView(resultItem.assessment)}
                >
                  {translate(ASSESSMENT_RESULT_TITLES[resultItem.assessment]) || resultItem.title}
                </Link>
              ))}
            </div>
            }

          </div>
        </div>

        <div className={styles.assessmentsInfo}>
          {(reorderedResults.length > 0) &&
          <>
            <div className={styles.title}>
              {translate('employee_strengths') || 'Stärken und Präferenzen'}
            </div>

            {/*ASSESSMENTS RESULTS*/}
            <div>
              {reorderedResults.map((resultItem, index) => {
                if (!resultItem || !resultItem.result) {
                  return null;
                }

                return (
                  <div
                    id={resultItem.assessment}
                    key={`result-${index}`}
                    className={styles.assessment}
                  >
                    {renderResultComponent(resultItem)}
                  </div>
                )
              })}
            </div>
          </>
          }

          {(results && results.length === 0) &&
          <div className={styles.noAssessments}>
            <div className={styles.title}>
              {translate('employee_no_assessments', ['{{username}}', user && user.name]) ||
              '{{username}} hat noch keine Assessments durchgeführt.'
              }
            </div>
            <div className={styles.description}>
              {translate('employee_no_assessments_descr') ||
              'Sobald Assessments durchgeführt wurden erscheinen hier die Ergebnisse.'}
            </div>
          </div>
          }

        </div>

      </div>

    </div>
  )
};

export default UserAssessmentsResults;
