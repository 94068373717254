// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import styles from './QuestionBubbles.module.scss';

// ASSETS
import {ReactComponent as CursorUp} from 'assets/icons/icn_cursor_up.svg';
import {ReactComponent as CursorDown} from 'assets/icons/icn_cursor_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { breakpoints, useBreakpoint } from 'utils/hooks';
import {isValid} from 'utils/numbers';

// OTHER COMPONENTS
import {Button} from 'ui/basic';


// STORE

// CONFIG & DATA
const CONFIG = {
  answerDelay: 200
};

const DEFAULT_HINT = 'You can use the numbers 1-{{endNumber}} on your keyboard to select. Use {{key arrow up / key arrow down}} on your keyboard to switch between questions.';

// COMPONENT: QuestionBubbles
const QuestionBubbles = (props) => {
  // PROPS
  const {
    question = {},
    range = [],
    selectedValue,
    clickBlock,
    onAnswer = () => {},
    onAnimation = () => {},
    onHelp = () => {}
  } = props;

  // COMPONENT/UI STATE and REFS
  const translate = useTranslate();
  const bp = useBreakpoint();

  // useAnswerOptions
  const [ rangeInternal, setRangeInternal ] = useState([]);
  useEffect(() => {
    if (question.useAnswerOptions && question.answerOptions?.length) {
      // console.log('range', question.answerOptions)
      setRangeInternal(question.answerOptions);
    }
    else {
      setRangeInternal(range);
    }
  }, [question.useAnswerOptions, question.answerOptions, range]);

  // selectedValue
  const [ internalIndex, setInternalIndex ] = useState();
  useEffect(() => {
    const selectedOption = rangeInternal.filter((option) => option.value === selectedValue)[0];
    if (selectedOption) {
      const selectedIndex = rangeInternal.findIndex((option) => option === selectedOption);
      setInternalIndex(selectedIndex);
    }
  }, [selectedValue, rangeInternal]);

  // answered & animate
  const [ answered, setAnswered ] = useState();
  const [ animate, setAnimate ] = useState();

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS
  const handleAnswer = useCallback((index) => {
    if (clickBlock || answered) return;
    const selectedOption = rangeInternal[index];
    const value = selectedOption.value;
    if (isNaN(value)) return;

    setInternalIndex(index);
    setAnswered(true);
    setTimeout(() => {
      setAnimate(true);
      onAnimation();
      setTimeout(() => {
        onAnswer(value, CONFIG.answerDelay);
      }, Number(styles.animationDurationMs));
    }, CONFIG.answerDelay);
  }, [clickBlock, answered, onAnswer, onAnimation, rangeInternal]);

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // NUMBER CONTROLS
    if (!isNaN(event.key)) {
      const selectedIndex = rangeInternal
      .findIndex((option) => Number(option.value) === Number(event.key));
      if (selectedIndex > -1) {
        event.preventDefault();
        handleAnswer(selectedIndex);
      }
    }
  }, [handleAnswer, rangeInternal]);
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  // HELPERS


  // RENDERS
  const hintTranslation = translate('assessment_selection_items__hint', ['{{endNumber}}', rangeInternal.length]) ||
    (DEFAULT_HINT.replace('{{endNumber}}', rangeInternal.length));

  const renderHint = () => {
    const hint = [];
    const split = hintTranslation.split('. ');

    if (split && split[1]) { //split[1] - getting the second sentence
      split[1]
        .split('{{key arrow up / key arrow down}}')
        .forEach((s, index) => {
          if (index === 0) {
            hint.push(
              <div className={styles.line2} key={`H${index}`}>
                <span>{s}</span>
                <CursorUp />
                <CursorDown />
              </div>
            )
          } else {
            hint.push(<span key={`H${index}`}>{s}</span>)
          }
      });
    } else {
      hint.push(split[0]);
    }

    return hint;
  }

  // RENDER: QuestionBubbles
  return (
    <div className={classNames(styles.questionBubbles, {
      [styles.animate]: animate
    })}>

      {/* QUESTION LABEL */}
      { question.questionLabel && (
        <div className={styles.questionLabel}>
          { question.questionLabel }
        </div>
      )}

      {/* QUESTION TITLE */}
      <div className={classNames(styles.questionTitle, {
        [styles.isBlue]: question.questionLabel || question.questionDescription
      })}>
        {isValid(question.number) ? `${question.number} - ${question.question}` : question.question}
      </div>

      {/* QUESTION DESCRIPTION */}
      { question.questionDescription && (
        <div className={styles.questionDescription}>
          { question.questionDescription }
        </div>
      )}

      {/* OPTIONS CONTAINER */}
      <div className={styles.optionsContainer}>

        {/* OPTIONS */}
        <div className={styles.options}>

          { rangeInternal.map((option, index) => (
            <React.Fragment key={index}>
              <div className={classNames(styles.option, {
                  [styles.selected]: index === internalIndex
                })}
                onClick={() => {
                  handleAnswer(index);
                }}
              >

                {/* CHECKBOX */}
                <div className={styles.checkbox}></div>

                {/* LABEL LIGHT */}
                { option.labelLight && !question.hideLabelLight && bp.bpWidth > breakpoints.S.bpWidth && (
                  <div className={styles.labelLight}>
                    { option.labelLight }
                  </div>
                )}

                {/* LABEL */}
                <div className={styles.label}>
                  { option.label || translate(option.translationKey) || option.translationFallback}
                </div>
              </div>
            </React.Fragment>
          ))}

        </div>

      </div>

      {/* HELP WITH SELECTION BUTTON */}
      <div className={styles.helpButton}>
        <Button
          size={'S'}
          looks={'tertiary'}
          onClick={onHelp}
        >
          {translate('assessment_help_button') || 'Hilfe bei der Auswahl'}
        </Button>
      </div>

      {/*HINT*/}
      {bp.bpWidth > breakpoints.M.bpWidth &&
      <div className={styles.hint}>
        <div className={styles.line2}>
          {hintTranslation.split('. ')[0] + '.'}
        </div>

        <div className={styles.line2}>{renderHint()}</div>
      </div>
      }

    </div>
  );
};

export default QuestionBubbles;
