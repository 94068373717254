// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useState} from 'react';
import styles from './EditCandidateProfilePanel.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import RadioButtonGroup from 'ui/basic/forms/RadioButtonGroup';
import QuestionJobFamily from 'ui/molecules/DialogSequence/components/profile-completion/QuestionJobFamily';
import QuestionIndustry from 'ui/molecules/DialogSequence/components/profile-completion/QuestionIndustry';
import QuestionRequirements from 'ui/molecules/DialogSequence/components/profile-completion/QuestionRequirements';
import QuestionAspects from 'ui/molecules/DialogSequence/components/profile-completion/QuestionAspects';
import QuestionStartDate from 'ui/molecules/DialogSequence/components/profile-completion/QuestionStartDate';
import QuestionName from 'ui/molecules/DialogSequence/components/profile-completion/QuestionName';
import QuestionLocation from 'ui/molecules/DialogSequence/components/profile-completion/QuestionLocation';
import QuestionNationality from 'ui/molecules/DialogSequence/components/profile-completion/QuestionNationality';
import QuestionDegree from 'ui/molecules/DialogSequence/components/profile-completion/QuestionDegree';
import {
  partTimeOption,
  remoteOption, salaryOption
} from 'ui/molecules/DialogSequence/components/profile-completion/QuestionRequirements/QuestionRequirements';
import stylesMultiSelect from 'ui/basic/forms/MultiSelect/MultiSelect.module.scss';
import {
  cultureAspectOption, opportunitiesAspectOption, salaryAspectOption
} from 'ui/molecules/DialogSequence/components/profile-completion/QuestionAspects/QuestionAspects';
import {Button, DropDownSearchable, InputNext, SidePanel, UploadImage} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { dateHasValidFormat } from 'utils/dateTools';
import { isNumber } from 'utils/numbers';

// STORE
import * as api from 'api';
import {useDispatch, useSelector} from 'react-redux';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromSelectors from 'store/selectors/staticValues';
import * as fromStaticValueActions from 'store/actions/staticValues';
import {getCurrentUser} from 'store/actions';

// CONFIG & DATA
// const Config = {};

const NAVIGATION_ITEMS = [
  {id: 'basic', label: 'basic_info'},
  {id: 'experience', label: 'experience'},
  {id: 'requirements', label: 'requirements'}
]

// COMPONENT: EditCandidateProfilePanel
const EditCandidateProfilePanel = (props) => {
  // PROPS
  const {
    onClose = () => {},
    showConfirmModal = () => {}
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);

  const [currentPage, setCurrentPage] = useState(NAVIGATION_ITEMS[0].id);

  const GENDER_OPTIONS = [
    {
      label: translate('cp_gender_male') || 'Männlich',
      value: 'm'
    },
    {
      label: translate('cp_gender_female') || 'Weiblich',
      value: 'w'
    },
    {
      label: translate('cp_gender_diverse') || 'Divers',
      value: 'd'
    }
  ];

  // STATIC VALUES FROM CONFIG: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const countries = useSelector(fromSelectors.selectCountries);
  useEffect(() => {
    if (countries.length === 0) {
      dispatch(fromStaticValueActions.getCountries());
    }
  }, [dispatch, countries.length]);

  const educationOptions = useSelector(fromSelectors.selectEducation);
  useEffect(() => {
    if (educationOptions.length === 0) {
      dispatch(fromStaticValueActions.getEducation());
    }
  }, [dispatch, educationOptions.length]);

  const careerLevelOptions = useSelector(fromSelectors.selectCareerLevelOptions);
  useEffect(() => {
    if (careerLevelOptions.length === 0) {
      dispatch(fromStaticValueActions.getCareerLevels());
    }
  }, [dispatch, careerLevelOptions.length]);

  const jobFamilyOptions = useSelector(fromSelectors.selectJobFamilyOptions);
  useEffect(() => {
    if (jobFamilyOptions.length === 0) {
      dispatch(fromStaticValueActions.getJobFamilies());
    }
  }, [dispatch, jobFamilyOptions.length]);

  const industryOptions = useSelector(fromSelectors.selectIndustryOptions);
  useEffect(() => {
    if (industryOptions.length === 0) {
      dispatch(fromStaticValueActions.getIndustries());
    }
  }, [dispatch, industryOptions.length]);

  // USER DATA: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [candidateInfo, setCandidateInfo] = useState();
  useEffect(() => {
    if (!me || countries.length === 0) {
      return;
    }

    const candidateInfoInternal = {
      firstName: me.firstName,
      lastName: me.lastName,
      gender: (me.candidateData && me.candidateData.gender) ? me.candidateData.gender : undefined,
      country: (me.candidateData && me.candidateData.country) ? me.candidateData.country : undefined,
      city: (me.candidateData && me.candidateData.city) ? me.candidateData.city : undefined,
      nationality: (me.candidateData && me.candidateData.nationality) ? me.candidateData.nationality[0] : undefined,

      education: (me.candidateData && me.candidateData.education) ? me.candidateData.education : undefined,
      currentPosition: (me.candidateData && me.candidateData.currentPosition) ? me.candidateData.currentPosition : undefined,
      careerLevel: (me.candidateData && me.candidateData.careerLevel) ? me.candidateData.careerLevel : undefined,
      currentJobFamily: (me.candidateData && me.candidateData.currentJobFamily) ?
        me.candidateData.currentJobFamily :
        undefined,

      industryExperience: (me.candidateData && me.candidateData.industryExperience) ?
        me.candidateData.industryExperience.map(indExperience => ({
          name: indExperience.industry || indExperience.industry_name,
          custom: !indExperience.industry,
          experience: indExperience.experience
        })) : []
    }

    // JOB REQUIREMENTS
    if (me.candidateData) {
      const candidateDataInternal = me.candidateData;
      const requirements = {};
      const selectedOptions = [];

      if (candidateDataInternal.remotePreferred) {
        selectedOptions.push({
          ...remoteOption,
          selected: true,
          value: candidateDataInternal.remotePreferred
        });
      }

      if (candidateDataInternal.desiredSalary) {
        selectedOptions.push({
          ...salaryOption,
          selected: true,
          value: [candidateDataInternal.desiredSalary, candidateDataInternal.currency || 'EUR'],
          isCustom: (optionValue = [candidateDataInternal.desiredSalary, candidateDataInternal.currency || 'EUR'], setValue) => (
            <div className={stylesMultiSelect.custom} onClick={(event) => {
              event.stopPropagation();
            }}>
              <InputNext
                label={translate('cp_job_requirements_salary_placeholder')}
                type='number'
                value={optionValue[0]}
                onChange={(salary) => setValue([salary, optionValue[1]])}
              />
              <DropDownSearchable
                placeholder={optionValue[1]?.toUpperCase()}
                activeValue={optionValue[1]}
                options={[
                  {value: 'eur', label: 'EUR'},
                  {value: 'usd', label: 'USD'},
                  {value: 'chf', label: 'CHF'},
                  {value: 'gbp', label: 'GBP'}
                ]}
                onChange={(currencyOption) => {
                  if (currencyOption) {
                    setValue([optionValue[0], currencyOption.value]);
                  }
                }}
              />
            </div>
          )
        });
      }

      if (candidateDataInternal.desiredWeeklyHours) {
        selectedOptions.push({
          ...partTimeOption,
          selected: true,
          value: candidateDataInternal.desiredWeeklyHours
        });
      }

      if (selectedOptions.length > 0) {
        requirements.selectedOptions = selectedOptions;
      }

      if (candidateDataInternal.additionalRequirements) {
        requirements.customRequirements = candidateDataInternal.additionalRequirements;
      }

      candidateInfoInternal['requirements'] = requirements;
    }

    // MOTIVATION
    if (me.candidateData && me.candidateData.motivation) {
      const selectedOptions = [];
      const customMotivations = [];
      me.candidateData.motivation.forEach(m => {
        if (m === 'culture') {
          selectedOptions.push({
            ...cultureAspectOption,
            selected: true
          })
        } else if (m === 'salary') {
          selectedOptions.push({
            ...salaryAspectOption,
            selected: true
          })
        } else if (m === 'opportunities') {
          selectedOptions.push({
            ...opportunitiesAspectOption,
            selected: true
          })
        } else {
          customMotivations.push(m);
        }
      })

      candidateInfoInternal['motivations'] = {
        selectedOptions,
        customAspects: customMotivations
      };
    }

    // START DATE
    if (me.candidateData && me.candidateData.earliestStart) {
      const earliestStart = me.candidateData.earliestStart;
      const dateRegex = new RegExp(/^([A-z0-9]{2})\.([A-z0-9]{2})\.([A-z0-9]{4})$/, 'g');

      let radioValue;
      let dateValue;
      if (earliestStart === 'now') {
        radioValue = 'now';
      } else if (dateRegex.test(earliestStart)) {
        radioValue = 'preferredDate';
        dateValue = earliestStart;
      } else if (earliestStart) {
        radioValue = 'noticePeriod';
        dateValue = earliestStart;
      }

      candidateInfoInternal['startDate'] = {
        radioValue,
        dateValue
      }
    }

    setCandidateInfo(candidateInfoInternal);
  }, [me, countries, translate]);

  // HANDLERS
  const [updateUserProcessing, setUpdateUserProcessing] = useState();
  const collectData = () => {
    const candidateData = {
      gender: candidateInfo.gender,
      country: candidateInfo.country,
      city: candidateInfo.city,
      education: candidateInfo.education,
      nationality: [candidateInfo.nationality].filter(Boolean),
      currentPosition: candidateInfo.currentPosition,
      careerLevel: candidateInfo.careerLevel,
      industryExperience: []
    };

    candidateData.currentJobFamily = candidateInfo.currentJobFamily.id || candidateInfo.currentJobFamily.name || '';

    if (candidateInfo.industryExperience && candidateInfo.industryExperience.length > 0) {
      candidateData['industryExperience'] = candidateInfo.industryExperience && candidateInfo.industryExperience.map(indExperience => ({
        industry: indExperience.name,
        experience: indExperience.experience
      }))
    }

    if (candidateInfo.requirements?.selectedOptions) {
      const remote = candidateInfo.requirements.selectedOptions.find(selectedOption => selectedOption.name === 'remote');
      candidateData.remotePreferred = remote ? remote.value : 0;

      const partTime = candidateInfo.requirements.selectedOptions.find(selectedOption => selectedOption.name === 'partTime');
      candidateData.desiredWeeklyHours = partTime ? Number(partTime.value) : 0;

      const salary = candidateInfo.requirements.selectedOptions.find(selectedOption => selectedOption.name === 'salarySpecific');
      if (salary) {
        candidateData['desiredSalary'] = Number(salary.value[0]);
        candidateData['currency'] = salary.value[1];
      } else {
        candidateData['desiredSalary'] = 0;
      }
    }

    candidateData.additionalRequirements = candidateInfo?.requirements?.customRequirements;

    if (candidateInfo.motivations) {
      const selectedMotivations = candidateInfo.motivations.selectedOptions || [];
      const customMotivations = candidateInfo.motivations.customAspects || [];

      candidateData['motivation'] = [...selectedMotivations.map(m => m.name), ...customMotivations];
    }

    if (candidateInfo.startDate) {
      const radioValue = candidateInfo.startDate.radioValue;
      const dateValue = candidateInfo.startDate.dateValue;
      if (radioValue === 'now') {
        candidateData['earliestStart'] = 'now';
      } else {
        candidateData['earliestStart'] = dateValue;
      }
    }

    return {
      firstName: candidateInfo.firstName,
      lastName: candidateInfo.lastName,
      candidateData
    };
  }

  const valuesNotEqual = (value1, value2) => {
    if ((value1 === undefined || value1 === null) && (value2 === undefined || value2 === null)) {
      return false;
    }

    if ((Array.isArray(value1) && value1.length === 0 && (value2 === undefined || value2 === null)) ||
      (Array.isArray(value2) && value2.length === 0 && (value1 === undefined || value1 === null))
    ) {
      return false;
    }

    return value1 !== value2;
  }

  const isCandidateDataChanged = () => {
    const collectedCandidateInfo = collectData();

    const newCandidateData = collectedCandidateInfo.candidateData;
    const oldCandidateData = me.candidateData;

    if (valuesNotEqual(collectedCandidateInfo.firstName, me.firstName) ||
      valuesNotEqual(collectedCandidateInfo.lastName, me.lastName)) {
      return true;
    }

    for (let key in newCandidateData) {
      if (key === 'industryExperience') {
        if (newCandidateData[key].length !== oldCandidateData[key].length) {
          return true;
        } else {
          for (let i = 0; i < newCandidateData[key].length; i++) {
            const industryExperienceItem = newCandidateData[key][i];
            const newIndustry = industryExperienceItem.industry;
            const oldIndustryItem = oldCandidateData[key][i];

            if ((!isNumber(newIndustry) && valuesNotEqual(oldIndustryItem.industry_name, newIndustry)) ||
              (isNumber(newIndustry) && valuesNotEqual(oldIndustryItem.industry, newIndustry)) ||
              (valuesNotEqual(industryExperienceItem.experience, oldCandidateData[key][i].experience))
            ) {
              return true;
            }
          }
        }
      } else if (key === 'currentJobFamily') {
        if (valuesNotEqual(newCandidateData[key], oldCandidateData[key].id) &&
          valuesNotEqual(newCandidateData[key], oldCandidateData[key].name)
        ) {
          return true;
        }
      } else if ((key === 'motivation' || key === 'additionalRequirements' || key === 'nationality') &&
        newCandidateData[key] && oldCandidateData[key]
      ) {
        if (newCandidateData[key].length !== oldCandidateData[key].length) {
          return true;
        } else {
          for (let i = 0; i < newCandidateData[key].length; i++) {
            const candidateDataItem = newCandidateData[key][i];
            if (valuesNotEqual(candidateDataItem, oldCandidateData[key][i])) {
              return true;
            }
          }
        }
      } else {
        if (valuesNotEqual(newCandidateData[key], oldCandidateData[key])) {
          return true;
        }
      }
    }

    return false;
  }

  const handleUpdateUser = () => {
    setUpdateUserProcessing(true);
    const payload = collectData();

    api.post('core/user/update', payload)
      .then(({ok, status}) => {
        setUpdateUserProcessing(false);
        if (ok && status === 200) {
          dispatch(getCurrentUser());
          onClose();
        }
      })
      .catch(error => {
        console.error(error.message);
        setUpdateUserProcessing(false);
      })
  }

  /**
   * Check whether all required fields have been filled in.
   * 
   * @returns {Boolean}
   */
  const isRequiredInfoAvailable = () => {
    const checkCareerLevel = () => {
      const {
        careerLevel,
        currentPosition,
        currentJobFamily,
        industryExperience,
      } = candidateInfo;

      // Career starters don't need to fill in the other job fields.
      // Only check that industry experience fields are ok.
      if (careerLevel === 1) {
        return industryExperience.every(({ experience, name }) => (name && experience))
          || industryExperience.every(({ experience, name }) => (!name && !experience)); 
      }

      const isValidJobFamily = currentJobFamily.id || currentJobFamily.name;
      const isValidIndustryExperience = industryExperience.every(({ name, experience }) => name && experience);

      return isValidJobFamily && currentPosition && isValidIndustryExperience;
    }

    const checkJobRequirements = () => {
      const { selectedOptions = [], customRequirements = [] } = candidateInfo.requirements;

      // check options
      const hasSelectedOptions = selectedOptions.length;
      const allSelectedOptionsOk = selectedOptions
      .map(({ name, value }) => (name === 'salarySpecific' ? value?.[0] : value))
      .every(Boolean);

      // check extra requirements
      const anyCustomRequirementsOk = customRequirements.some(Boolean);

      return allSelectedOptionsOk && (anyCustomRequirementsOk || hasSelectedOptions);
    }

    const checkStartDate = () => {
      const { radioValue, dateValue } = candidateInfo.startDate;
      if (radioValue === 'now') {
        return true;
      }
      if (radioValue === 'preferredDate') {
        return dateHasValidFormat(dateValue);
      }
      return Boolean(dateValue);
    }

    return (
      candidateInfo.firstName
      && candidateInfo.lastName
      && candidateInfo.country
      && candidateInfo.education
      && checkCareerLevel()
      && checkJobRequirements()
      && checkStartDate()
    );
  }

  if (!candidateInfo) {
    return null;
  }

  // RENDER: EditCandidateProfilePanel
  return (
    <SidePanel
      size={'M'}
      closeOnCancel={false}
      onClose={() => {
        if (isCandidateDataChanged()) {
          showConfirmModal(collectData());
        } else {
          onClose();
        }
      }}
    >
      <div className={styles.editCandidateProfilePanel}>
        {/*HEADER*/}
        <div className={styles.header}>
          {translate('edp_edit_profile_data') || 'Edit Profile Data'}
        </div>

        {/*SUB HEADER*/}
        <div className={styles.subHeader}>
          {NAVIGATION_ITEMS.map((navigationItem, index) => (
            <div
              key={index}
              className={classNames(styles.navigationItem, {[styles.active]: currentPage === navigationItem.id})}
              onClick={() => setCurrentPage(navigationItem.id)}
            >
              {translate(navigationItem.label) || navigationItem.label}
            </div>
          ))}
        </div>

        {/*CONTENT*/}
        <div className={styles.content}>
          {/*BASIC INFO PAGE*/}
          {currentPage === 'basic' &&
          <>
            <div className={styles.block}>
              <UploadImage
                image={me.picture}
                altImageText={me.firstName.substring(0, 1) + me.lastName.substring(0, 1)}
                text={translate('edp_link_edit_profile_picture') || 'Change Image'}
              />
            </div>

            <div className={styles.block}>
              <QuestionName
                size='S'
                answer={{
                  firstName: candidateInfo.firstName,
                  lastName: candidateInfo.lastName
                }}
                onAnswer={({ firstName, lastName }) => setCandidateInfo((state) => ({ ...state, firstName, lastName }))}
              />
            </div>

            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('rmp_ass_form_gender_label') || 'Geschlecht'}</div>
              <RadioButtonGroup
                name={'radio-button-group'}
                selectedValue={candidateInfo.gender}
                items={GENDER_OPTIONS}
                deselectable
                onChange={(gender) => setCandidateInfo((state) => ({ ...state, gender }))}
              />
            </div>

            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('residence') || 'Wohnort'}</div>
              <QuestionLocation
                size='S'
                answer={{
                  country: candidateInfo.country,
                  city: candidateInfo.city
                }}
                onAnswer={({ city, country }) => setCandidateInfo((state) => ({ ...state, city, country }))}
              />
            </div>

            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('nationality') || 'Nationalität'}</div>
              <QuestionNationality
                size='S'
                answer={{
                  value: candidateInfo.nationality,
                  nationality: candidateInfo.nationality
                }}
                onAnswer={({ value }) => setCandidateInfo((state) => ({ ...state, nationality: value }))}
              />
            </div>
          </>
          }

          {/*EXPERIENCE PAGE*/}
          {currentPage === 'experience' &&
          <>
            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('highest_school_degree') || 'Höchster Schulabschluss'}</div>
              <QuestionDegree
                size='S'
                answer={{
                  value: candidateInfo.education
                }}
                onAnswer={({ value }) => setCandidateInfo((state) => ({ ...state, education: value }))}
              />
            </div>

            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('position_and_careerlevel') || 'Position und Karrierestufe'}</div>
              <InputNext
                size='S'
                label={translate('current_position')}
                value={candidateInfo.currentPosition}
                onChange={(currentPosition) => setCandidateInfo((state) => ({ ...state, currentPosition }))}
              />
              <DropDownSearchable
                size='S'
                placeholder={translate('please_select_lbl') || 'Bitte wählen'}
                filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
                activeValue={(me.candidateData && me.candidateData.careerLevel) ? me.candidateData.careerLevel : undefined}
                options={careerLevelOptions}
                onChange={({ value }) => setCandidateInfo((state) => ({ ...state, careerLevel: value }))}
              />
            </div>

            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('job_family') || 'Jobfamilie'}</div>
              <QuestionJobFamily
                size='S'
                answer={{
                  useDropDown: !!candidateInfo.currentJobFamily.id,
                  value: candidateInfo.currentJobFamily.id || candidateInfo.currentJobFamily.name
                }}
                onAnswer={({ useDropDown, value }) => setCandidateInfo((state) => ({
                  ...state,
                  currentJobFamily: {
                    id: useDropDown ? value : undefined,
                    name: !useDropDown ? value : undefined,
                  }
                }))}
              />
            </div>

            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('industry_experience') || 'Branchenerfahrung'}</div>
              <QuestionIndustry
                size='S'
                answer={candidateInfo.industryExperience.length ? candidateInfo.industryExperience : undefined}
                onAnswer={({ value }) => setCandidateInfo((state) => ({ ...state, industryExperience: value }))}
              />
            </div>
          </>
          }

          {/*REQUIREMENTS PAGE*/}
          {currentPage === 'requirements' &&
          <>
            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('job_requirements') || 'Job-Anforderungen'}</div>
              <QuestionRequirements
                size='S'
                answer={{value: candidateInfo.requirements}}
                onAnswer={({ value }) => setCandidateInfo((state) => ({ ...state, requirements: value }))}
              />
            </div>

            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('job_motivation') || 'Job-Motivation'}</div>
              <QuestionAspects
                size='S'
                answer={{value: candidateInfo.motivations}}
                onAnswer={({ value }) => setCandidateInfo((state) => ({ ...state, motivations: value }))}
              />
            </div>

            <div className={styles.block}>
              <div className='blu-typeLabel'>{translate('start_termin') || 'Start-Termin'}</div>
              <QuestionStartDate
                size='S'
                answer={{value: candidateInfo.startDate}}
                onAnswer={({ value }) => setCandidateInfo((state) => ({ ...state, startDate: value }))}
              />
            </div>
          </>
          }
        </div>

        {/*FOOTER*/}
        <div className={styles.footer}>
          <Button
            size='M'
            looks='secondary'
            onClick={() => {
              if (isCandidateDataChanged()) {
                showConfirmModal(collectData());
              } else {
                onClose();
              }
            }}
          >{translate('cancel_lbl') || 'Abbrechen'}</Button>
          <Button
            size='M'
            disabled={updateUserProcessing || !isRequiredInfoAvailable()}
            onClick={handleUpdateUser}
          >{translate('save_lbl') || 'Speichern'}</Button>
        </div>
      </div>

    </SidePanel>
  )
};

export default EditCandidateProfilePanel;
