import React, { useEffect, useState } from 'react';
import styles from './CompleteProfile.module.scss';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import {
  logoutFulfilled,
  updateCurrentUser
} from 'store/actions';
import { getConfiguration } from 'store/actions/configuration';
import { selectCompanyHasExternalLegalAgreements } from 'store/selectors/configuration';
import * as fromStaticValueSelectors from 'store/selectors/staticValues';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

import { useTranslate } from 'utils/translator';
import { getYears } from 'utils/years';
import { getMonths } from 'utils/months';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';

import {Legal} from 'features/framework/pages';
import {UploadImage, Button, DropDown, Checkbox, InputNext} from 'ui/basic';

const DEFAULT_TITLE = 'Hello, complete your profile to use bluquist.';
const DEFAULT_DESCRIPTION =
  'Your profile enables you to make your own potential visible and develop it in a targeted manner.';

const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_'
};

const CompleteProfile = () => {

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  // CONFIG MANAGEMENT
  useEffect(() => {
    dispatch(getConfiguration());
  }, [dispatch]);

  const hasExternalLegalAgreements = useSelector(selectCompanyHasExternalLegalAgreements);


  const [showTermsDialog, setShowTermsDialog] = useState(false);
  const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profession, setProfession] = useState();
  const [careerLevel, setCareerLevel] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [firstNameIsValidating, setFirstNameIsValidating] = useState(false);
  const [lastNameIsValidating, setLastNameIsValidating] = useState(false);

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);

  const careerLevelOptions = useSelector(fromStaticValueSelectors.selectCareerLevelOptions);

  const TERMS_OF_USE = translate('cp_cm_label_tos') || 'Ich bestätige, dass ich die {{link.Nutzungsbedingungen}} gelesen habe und sie akzeptiere.';
  const PRIVACY_POLICY = translate('cp_cm_label_privacy') || 'Ich bestätige, dass ich die Bestimmungen der  {{link.Datenschutzerklärung}} gelesen habe und sie akzeptiere.';

  const getParsedText = (text, content='terms') => {
    const joinedText = [];
    const part1 = text.split(/{{link./);
    if (part1.length > 1) {
      joinedText.push(part1[0]);
      joinedText.push(
        <span
          className={styles.policy}
          onClick={() => {
            if (content === 'terms') {
              setShowTermsDialog(true);
            } else {
              setShowPrivacyDialog(true);
            }
          }}
        >
          {part1[1].split('}}')[0]}
        </span>
      );
      joinedText.push(part1[1].split('}}')[1]);
    } else {
      joinedText.push(text);
    }
    return joinedText;
  };

  // HELPERS
  const isFormValid = () => {
    if (hasExternalLegalAgreements) {
      return firstName.length === 0 || lastName.length === 0;
    }
    else {
      return firstName.length === 0 || lastName.length === 0 || !termsOfUseChecked || !privacyPolicyChecked;
    }
  };

  return (
    <div className={styles.completeProfile}>
      <div className={styles.container}>
        {CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ?
          <div className={styles.title}>
            {(translate('by_cp_title', ['{{company}}', me.company || '']) || DEFAULT_TITLE)}
          </div> :
          <div className={styles.title}>
            {translate('cp_title') || DEFAULT_TITLE}
          </div>
        }
        <div className={styles.description}>
          {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}cp_description`) || DEFAULT_DESCRIPTION}
        </div>
        <UploadImage
          altImageText={firstName.substring(0, 1) + lastName.substring(0, 1)}
          text={translate('edp_link_edit_profile_picture') || 'Change Image'}
          handleWithoutStore={true}
        />
        <div className={styles.infoBlock1}>
          <InputNext
            placeholder={translate('cp_label_input_name') || 'Name'}
            value={firstName}
            errorMessage={(firstNameIsValidating && firstName.length === 0) && (translate('cp_label_input_name_error_nofill') || 'Name is empty')}
            onChange={value => {
              setFirstName(value);
              setFirstNameIsValidating(true);
            }}
          />

          <InputNext
            placeholder={translate('cp_label_input_surname') || 'Surname'}
            value={lastName}
            errorMessage={(lastNameIsValidating && lastName.length === 0) && (translate('cp_label_input_surname_error_nofill') || 'Surname is empty')}
            onChange={value => {
              setLastName(value);
              setLastNameIsValidating(true);
            }}
          />

          <InputNext
            placeholder={translate('cp_label_input_position') || 'Actual Position'}
            value={profession}
            onChange={value => setProfession(value)}
          />

          <DropDown
            placeholder={translate('cp_label_dropdown_careerlevel') || 'Career Level'}
            options={careerLevelOptions}
            selectedOption={careerLevel}
            onChange={option => setCareerLevel(option)}
          />
        </div>
        <div className={styles.infoBlock2}>
          <div className={styles.title}>
            {translate('cp_subheadline_timeincomp') || 'In Company since'}
          </div>
          <div className={styles.selects}>
            <div className={styles.select}>
              <DropDown
                placeholder={translate('cp_label_dropdown_month') || 'Choose month'}
                options={getMonths().map((month, index) => ({
                  value: index,
                  label: month
                }))}
                selectedOption={month}
                onChange={option => setMonth(option)}
              />
            </div>
            <div className={styles.select}>
              <DropDown
                placeholder={translate('cp_label_dropdown_year') || 'Choose year'}
                options={getYears().reverse().map(year => ({ value: year, label: year }))}
                selectedOption={year}
                onChange={option => setYear(option)}
              />
            </div>
          </div>
        </div>
        {/* DATA PROCESSING */}
        { !hasExternalLegalAgreements && (
          <>
            <div className={styles.checkBox}>
              <Checkbox
                name={getParsedText(TERMS_OF_USE, 'terms')}
                onChange={value => setTermsOfUseChecked(value)}
              />
            </div>
            <div className={styles.checkBox}>
              <Checkbox
                name={getParsedText(PRIVACY_POLICY, 'privacyPolicy')}
                onChange={value => setPrivacyPolicyChecked(value)}
              />
            </div>
          </>
        )}
        { hasExternalLegalAgreements && (
          <>
            <div className={styles.checkBox}>
              <span className='blu-link' onClick={() => {
                setShowTermsDialog(true);
              }}>
                {translate('legal_terms_of_use_title') || 'Nutzungsbedingungen'}
              </span>
            </div>
            <div className={styles.checkBox}>
              <span className='blu-link' onClick={() => {
                setShowPrivacyDialog(true);
              }}>
                {translate('legal_privacy_agreement_title') || 'Datenschutzerklärung'}
              </span>
            </div>
          </>
        )}
        <div className={styles.buttons}>
          <div
            className={styles.logOut}
            onClick={() => {
              localStorage.removeItem('token');
              dispatch(logoutFulfilled());
              window.location.href = '/logged-out';
            }}
          >
            {translate('cp_link_cancel') || 'Log out and continue later'}
          </div>
          <Button
            disabled={isFormValid()}
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  firstName: firstName,
                  lastName: lastName,
                  profession: profession,
                  careerLevel: careerLevel ? careerLevel.value : undefined,
                  companyEntryDate: (year && month) ?
                    new Date(
                      year.value,
                      month.value
                    ).getTime() / 1000 : undefined
                })
              );
            }}
          >
            {translate('cp_button_complete') || 'Complete'}
          </Button>
        </div>
      </div>

      { showTermsDialog && (
        <Legal propSubPage='terms-of-use' onClose={() => setShowTermsDialog(false)} />
      )}

      { showPrivacyDialog && (
        <Legal propSubPage='privacy-agreement' onClose={() => setShowPrivacyDialog(false)} />
      )}
    </div>
  );
};

export default CompleteProfile;
