// REACT, STYLE, STORIES & COMPONENT
import React, {useState, useRef, useEffect} from 'react';
import styles from './InputNext.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';
import { ReactComponent as ArrowLeftLong } from 'assets/icons/icn_arrow_left_long.svg';
import { ReactComponent as VisibilityOn } from 'assets/icons/icn_vis_on.svg';


// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { capitalise } from 'utils/textTools';


// COMPONENT: InputNext
const InputNext = React.forwardRef((props, ref) => {
  // PROPS
  const {
    // dom props
    id,
    className,
    size = '', // M (default), L, Responsive
    name,
    type = 'text',
    disabled,
    autofocus,
    autoComplete,
    placeholder, label, value,
    // component props
    hint,
    domain,
    errorMessage,
    icon,
    onChange, onBlur, onFocus, onClick = () => {},
    onConfirm,
    validate, onError,
    onArrowUp = () => {},
    onArrowDown = () => {},
    bigButton = false,
    bigButtonDisabled = true,
  } = props;

  // COMPONENT/UI STATE and REFS
  const inputRef = useRef(null);
  const [ focus, setFocus ] = useState(false);
  const [ valueInternal, setValueInternal ] = useState(value || '');
  const [ typeInternal, setTypeInternal ] = useState(type);
  const [ smallLabel, setSmallLabel ] = useState(value ? true : false);
  const [ error, setError ] = useState(null);

  let Icon = icon && IconsSvg[icon];
  let ArrowRight = IconsSvg.ArrowRight;

  // HELPERS
  const getError = (value, validate = {}) => {
    const error = {};

    // required
    if (validate.required && (!value || !value.length)) {
      error.required = true;
    }
    // min
    if (typeof validate.min === 'number' && value.length < validate.min) {
      error.min = true;
    }
    // max
    if (typeof validate.max === 'number' && value.length > validate.max) {
      error.max = true;
    }
    // pattern
    if (validate.pattern && !value.match(validate.pattern)) {
      error.pattern = true;
    }

    if (Object.keys(error).length === 0) {
      return null;
    }
    else {
      return error;
    }

  };

  // METHODS
  const validateValue = (value, validate) => {

    const error = getError(value, validate);
    setError(error);
    onError && onError(error);
    return error;

  };

  const confirm = () => {
    // no onConfirm => do nothing
    if (!onConfirm) {
      return;
    }
    // no validate => call onConfirm and return
    else if (!validate) {
      onConfirm(valueInternal);
      setValueInternal('');
      return;
    }

    // check for errors
    let error = validateValue(valueInternal, validate);

    // error? => return
    if (error) {
      return;
    }
    // no error => onConfirm, reset value, validate again
    else {
      onConfirm(valueInternal);
      setValueInternal('');
      // prevent empty confirms with onChange and required
      validate.onChange && validate.required && validateValue(valueInternal, validate);
    }
  }

  useEffect(() => {
    setValueInternal(value || '');
    setSmallLabel(!!value);
  }, [value]);

  useEffect(() => {
    setTypeInternal(type);
  }, [type]);

  useEffect(() => {
    if (autofocus) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  // RENDER: InputNext
  const domProps = {
    id,
    type: typeInternal,
    value: valueInternal,
    disabled,
    name,
    autoComplete,
    placeholder
  };
  return (
    // CONTAINER & onConfirm
    <div className={classNames(styles.inputContainer, {
      [styles[`size${capitalise(size)}`]]: size
    })}
      onKeyUp={(event) => {
        if (event.key !== 'Enter') return;
        confirm();
      }}>
      {/* LABEL */}
      {label && !placeholder &&
        <label className={`${styles.label} ${classNames({[styles.small]: smallLabel, [styles.disabled]: disabled})}`}>
          {label}
        </label>
      }

      <input
        className={classNames(styles[className], styles.input, {
          [styles.placeholder]: placeholder,
          [styles.hasIcon]: icon,
          [styles.hasDomain]: domain,
          [styles.hasConfirmButton]: onConfirm && !domain,
          [styles.error]: error
        })}
        {...domProps}
        ref={inputRef}
        noValidate
        onChange={(event) => {
          if (error) { // reset error onChange
            setError(null);
            onError && onError(null);
          }

         // handle new value
         const value = event.target.value;
         setValueInternal(value);
         // fire on change?
         onChange && onChange(value, getError(value, validate));
         // validate.onChange?
         validate && validate.onChange && validateValue(value, validate);
       }}
       onFocus={() => {
         setSmallLabel(true);
         setFocus(true);
         // prevent empty confirms with onChange and required
         validate && validate.onChange && validateValue(valueInternal, validate);
         onFocus && onFocus();
       }}
       onBlur={() => {
         setSmallLabel(valueInternal ? true : false);
         setFocus(false);
         onBlur && onBlur(valueInternal);
         // allow empty when not required
         if (validate && !validate.required) {
           setError(null);
           onError && onError(null);
         }
         else {
           validate && !validate.onChange && validateValue(valueInternal, validate);
         }
       }}
       onClick={onClick}
       onKeyUp={(event) => {
          if (event.key !== 'Enter') event.stopPropagation();
          // preventive measure so form elements in Dialogs / Assessments etc.
          // don't trigger page navigations
          event.key === 'ArrowUp' && onArrowUp(event);
          event.key === 'ArrowDown' && onArrowDown(event);
       }}
        onKeyDown={(event) => {
          // Prevent inclusion of letter 'e' in number fields
          if (typeInternal !== 'number') {
            return;
          }
          const numberInputInvalidChars = ['e', 'E'];
          if (numberInputInvalidChars.includes(event.key)) {
            event.preventDefault();
          }
        }}
      />

      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

      {/*HINT*/}
      {(hint && !errorMessage) && <div className={styles.hint}>{hint}</div>}

      {domain &&
        <div className={styles.domain}>{domain}</div>
      }

      {/* ICON */}
      { icon &&
        <Icon className={styles.icon}/>
      }

      {/* confirmButton */}
      {(onConfirm && !domain && !bigButton) &&
      <div
        className={classNames(styles.confirmButton, {[styles.hide]: !(onConfirm && focus), [styles.error]: error})}
        onClick={() => {
          inputRef.current.focus(); // set focus again (lost due to click outside of input)
          confirm();
        }}>
        <ArrowRight className={styles.arrowRight}/>
      </div>
      }

      {/* bigButton */}
      { bigButton && (
        <div className={classNames(styles.bigButton, {
            [styles.hide]: !focus,
            [styles.disabled]: bigButtonDisabled
          })}
          onClick={() => {
            !bigButtonDisabled && confirm();
          }}
        >
          <ArrowLeftLong />
        </div>
      )}

      {/*PASSWORD VISIBILITY ICON*/}
      {(type === 'password' && valueInternal.length > 0) &&
      <div
        className={classNames(styles.password, {[styles.visible]: typeInternal !== 'password'})}
        onClick={() => {
          if (typeInternal === 'password') {
            setTypeInternal('text');
          } else {
            setTypeInternal('password');
          }
        }}
      >
        <VisibilityOn />
      </div>
      }

    </div>
  );
});

export default InputNext;
