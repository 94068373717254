import * as localStorage from 'utils/localStorage';
import * as actionType from 'store/actions';

const initialState = {
  languages: null,
  currentLanguage: null,
  translations: null,
  processing: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.CHANGE_LANGUAGE_FULFILLED:
      if (action.payload) {
        localStorage.setItem('selectedLanguage', action.payload);
      }
      return {
        ...state,
        currentLanguage: action.payload
      };
    case actionType.GET_LANGUAGES:
      return {
        ...state,
        processing: true
      };
    case actionType.GET_LANGUAGES_REJECTED:
      return {
        ...state,
        processing: false,
        error:action.payload
      };
    case actionType.GET_LANGUAGES_FULFILLED:
      return {
        ...state,
        processing: false,
        ...action.payload
      };
    case actionType.GET_TRANSLATIONS:
      return {
        ...state,
        processing: true
      };
    case actionType.GET_TRANSLATIONS_REJECTED:
      return {
        ...state,
        processing: false,
        error:action.payload
      };
    case actionType.GET_TRANSLATIONS_FULFILLED:
      return {
        ...state,
        processing: false,
        translations: { ...action.payload.data }
      };
    default:
      return state;
  }
};
