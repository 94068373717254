import React, { useState } from 'react';
// component
import styles from './DropDownMenu.module.scss';
// Components
import BluCSSTransition from 'ui/basic/containers/BluCSSTransition';
// Project
import { handleRipple } from 'utils/ripple'
// 3rd party
import classNames from 'classnames';


const DropDownMenu = (props) => {
  const { className, options, onChange, children } = props;

  const [flyoutIsOpen, setFlyoutIsOpen] = useState(false);

  return (
    <div
      tabIndex={'0'}
      className={classNames(styles.container, styles[className])}
      onBlur={() => setFlyoutIsOpen(false)}
    >
      <div
        className={classNames(styles.dropDownMenu, {
          [styles.open]: flyoutIsOpen
        })}
        onClick={() => setFlyoutIsOpen(!flyoutIsOpen)}
      >
        {children}
      </div>
      <BluCSSTransition in={flyoutIsOpen} classNames={{ ...styles }}>
        <div className={styles.flyout}>
          {options.map((option, index) => {
            const menuItemProps = {
              key: index,
              className: styles.menuItem,
              onClick: event => {
                handleRipple(event, styles.colorPrimary3);

                if (onChange) {
                  onChange(option.id);
                }
                window.setTimeout(() => {
                  setFlyoutIsOpen(false);
                }, styles.animationDurationLongMs);
              }
            };

            return (
              <div {...menuItemProps}>
                <span>{option.label}</span>
              </div>
            );
          })}
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default DropDownMenu;
