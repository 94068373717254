import * as fromActionTypes from 'store/actionTypes/assessments';

export const initAssessmentsError = () => {
  return {
    type: fromActionTypes.INIT_ASSESSMENTS_ERROR,
    payload: {}
  };
};

//register assessment
export const initRegisterAssessment = () => {
  return {
    type: fromActionTypes.INIT_REGISTER_ASSESSMENT,
    payload: {}
  };
};

export const registerAssessment = (registrationDto) => {
  return {
    type: fromActionTypes.REGISTER_ASSESSMENT,
    payload: {registrationDto}
  };
};

export const registerAssessmentFulfilled = () => {
  return {
    type: fromActionTypes.REGISTER_ASSESSMENT_FULFILLED,
    payload: {}
  };
};

export const registerAssessmentRejected = ({ error }) => {
  return {
    type: fromActionTypes.REGISTER_ASSESSMENT_REJECTED,
    payload: { error }
  };
};
