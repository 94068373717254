import React, { memo } from 'react';
import * as css from 'styles-imports/shared.scss';

const GAP_WIDTH = 4;
const RANGE_AREA_HEIGHT = 16;

const SegmentedLineDiagram = memo((props) => {
  const {width, height, score, range} = props;

  const innerWidth = width - 16;
  const segmentWidth = innerWidth / 5;
  let start = 0;
  let rest = score;

  const rectangles = [];
  const filledRectangles = [];

  const getRectangles = (yPos) => {
    for (let i=1; i<=5; i++) {
      rectangles.push(
        <rect
          key={`index-${i}`}
          x={start}
          y={yPos}
          width={segmentWidth}
          height={height}
          rx='2'
          fill={css.colorGrey3}
        />
      );

      start = start + (segmentWidth + GAP_WIDTH);
    }

    return rectangles;
  };

  const getFilledRectangles = (yPos = 0) => {
    start = 0;

    for (let i=1; i<=5; i++) {
      if (rest === 0) {
        return filledRectangles;
      }

      if (rest - 1 >= 0) {
        filledRectangles.push(
          <rect key={`index-${i}`} x={start} y={yPos} width={segmentWidth} height={height} rx="2" fill={css.colorPrimary1}/>
        );

        start = start + (segmentWidth + GAP_WIDTH);
        rest = rest - 1;
        continue;
      }

      if (rest - 1 < 0) {
        filledRectangles.push(
          <rect key={`index-${i}`} x={start} y={yPos} width={segmentWidth * rest} height={height} rx="2" fill={css.colorPrimary1}/>
        );

        return filledRectangles;
      }
    }

    return filledRectangles;
  };

  if (range && range.length > 0 && range[0] && !!Number(range[0])) {
    const rangeStart = (segmentWidth * range[0]) + ((range[0] - 1) * GAP_WIDTH);
    const rangeEnd = (segmentWidth * range[1]) + ((range[1] - 1) * GAP_WIDTH);

    return (
      <svg
        viewBox={`0 0 ${width} ${RANGE_AREA_HEIGHT}`}
        width={width}
        height={RANGE_AREA_HEIGHT}
      >
        {/*FILLED RANGE AREA*/}
        <rect
          x={rangeStart}
          y={0}
          width={rangeEnd - rangeStart}
          height={RANGE_AREA_HEIGHT}
          rx='8'
          fill={css.colorPrimary3}
        />

        {/*GREY RECTANGLES*/}
        {getRectangles(RANGE_AREA_HEIGHT/2 - (height/2))}

        {/*FILLED RECTANGLES*/}
        {getFilledRectangles(RANGE_AREA_HEIGHT/2 - (height/2))}
      </svg>
    )
  } else {
    return (
      <svg
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={20}
      >
        {getRectangles(0)}
        {getFilledRectangles()}
      </svg>
    )
  }
});

SegmentedLineDiagram.defaultProps = {
  width: 304,
  height: 4
};

export default  SegmentedLineDiagram;
