import React, {useEffect, useState} from 'react';
import styles from './TextArea.module.scss';
import classNames from 'classnames';


const TextArea = (props) => {
  const {value, label, placeholder, hint, disabled, onChange} = props;

  const [smallLabel, setSmallLabel] = useState(value ? true : false);
  const [valueInternal, setValueInternal] = useState(value || '');

  useEffect(() => {
    if (value) {
      setValueInternal(value);
      setSmallLabel(true);
    }
  }, [value]);

  return (
    <div className={styles.textarea}>

      {(label && !placeholder) &&
      <label className={
        classNames(
          styles.label,
          {[styles.small]: smallLabel},
          {[styles.disabled]: disabled}
        )}
      >
        {label}
      </label>}

       <textarea
         placeholder={placeholder}
         disabled={disabled}
         value={valueInternal}
         onChange={event => {
           setValueInternal(event.target.value);
           onChange(event.target.value)
         }}
         onFocus={() => {
           setSmallLabel(true);
         }}
         onBlur={() => {
           setSmallLabel(valueInternal ? true : false);
         }}
       />

      {hint && <div className={styles.hint}>{hint}</div>}
    </div>
  )
};

TextArea.defaultProps = {
  disabled: false
};

export default TextArea;
