export const eventBus = {
  addListener: (eventName, callback) => {
    document.addEventListener(eventName, callback)
  },
  removeListener: (eventName, callback) => {
    document.removeEventListener(eventName, callback);
  },
  dispatch: (eventName, data) => {
    document.dispatchEvent(new CustomEvent(eventName, { detail: data }))
  }
};
