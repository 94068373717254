import store from 'store';

export const numberWithForcedDecimals = (number, options = {}) => {

  const { decimals = 1, currentLanguageOverride, addPlus } = options;

  let formattedNumber = Number(number);
  if (isNaN(formattedNumber)) {
    return '';
  }

  formattedNumber =  formattedNumber.toFixed(decimals);

  const state = store.getState();
  const currentLanguage = currentLanguageOverride || (state && state.localisation && state.localisation.currentLanguage);

  if (currentLanguage === 'de_DE') {
    formattedNumber = formattedNumber.replace('.', ',');
  }

  if (addPlus && number >= 0) {
    formattedNumber = `+${formattedNumber}`;
  }

  return formattedNumber;
};


