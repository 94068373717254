import React, {memo} from 'react';
import styles from './WorkPreferencesResult.module.scss';

import { useTranslate } from 'utils/translator';
import {isValid} from 'utils/numbers';

import BipolarDiagram from 'ui/basic/diagrams/BipolarDiagram';


const WorkPreferencesResultItem = memo((props) => {
  const {index, width, score, range, dimension} = props;

  const translate = useTranslate();

  return (
    <div className={styles.item}>
      <div className={styles.header}>{translate(`workpreferences_results_label_dim${index}`) || dimension}</div>
      <BipolarDiagram
        width={width}
        //work pref assessment values are in range of [-5...5]
        //+5 because svg doesn't accept negative values
        score={isValid(score) && score + 5}
        range={range && [range[0] + 5, range[1] + 5]}
      />
      <div className={styles.titles}>
        <div className={score < 0 ? styles.blue : null}>{translate(`workpreferences_results_min_dim${index}`) || 'Label 1'}</div>
        <div className={score > 0 ? styles.blue: null}>{translate(`workpreferences_results_max_dim${index}`) || 'Label 2'}</div>
      </div>
    </div>
  )
});

export default WorkPreferencesResultItem;
