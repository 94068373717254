import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import AssessmentEnd from '../intermissions/AssessmentEnd';

// UTILS
// import { translate } from 'utils/translator/translator';
// import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

// CONFIG

// OTHER COMPONENTS

export const customConfig = {
  id: 'custom',
  title: 'Custom Assessment',
  storageValidityWindow: 0, // disable
    // storage doesn't support customAssessmentId handling ontop of type='custom'
  loadingPage: {
    // INTRO with disabled next button and skeleton
    render: (state) => (
      <AssessmentIntro
        skeletonOnly
      />
    )
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType='custom'
          title={state.title}
          description={state.description}
          onClickNext={() => next()}
        />
      )
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => (
        <AssessmentGuidance
          content={state.guidanceContent}
          onClickNext={() => next()}
        />
      )
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => {
        const { finishable, handleFinish, errorEnd } = extras;
        return (
          <AssessmentEnd
            assessmentType={state.customAssessmentId}
            finishable={finishable}
            handleFinish={handleFinish}
            errorEnd={errorEnd}
          />
        )

      }
    }
  ]
};
