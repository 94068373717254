import React from 'react';
import styles from './AssessmentIntro.module.scss';

// ASSETS
import Big5Image from 'assets/assessments/big5.svg';
import CustomImage from 'assets/assessments/custom.svg';
import PotentialImage from 'assets/assessments/potential.svg';
import WorkPreferenceImage from 'assets/assessments/work-preference.svg';
import KeyCompetenciesImage from 'assets/assessments/key-competencies.svg';
import LeadershipCompetenciesImage from 'assets/assessments/leadership-competencies.svg';
import RmpImage from 'assets/assessments/rmp.svg';
import NineLevelsImage from 'assets/assessments/9levels.svg';
import BalancedYouImage from 'assets/assessments/balanced-you.svg';

import { ReactComponent as SkeletonXl } from './assets/skeleton_xl.svg';
import { ReactComponent as SkeletonS } from './assets/skeleton_s.svg';
import { ReactComponent as SkeletonXs } from './assets/skeleton_xs.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentPageWithFooter } from '../AssessmentPageWithFooter';


// UTILS
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import {normalizeText} from 'utils/normalizeText';
import {markdown} from 'utils/textTools';

// CONFIG & DATA
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';
import {ASSESSMENT_NEXT_TYPES} from '../../AssessmentNext.config';
import {getTranslationIds} from '../../AssessmentNext.translations';


const AssessmentIntro = (props) => {

  const {
    assessmentType,
    skeletonOnly = false, // only shows skeleton and disables next button
    title = '',
    description = '',
    onClickNext
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const { bpWidth, bps, isS } = useBreakpoint();

  const assessmentNextName = ASSESSMENT_NEXT_TYPES[assessmentType];
  const translationIds = getTranslationIds(assessmentNextName);

  const getImage = () => {
    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return Big5Image;
      case ASSESSMENT_TYPES.POTENTIAL:
        return PotentialImage;
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return WorkPreferenceImage;
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return KeyCompetenciesImage;
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return LeadershipCompetenciesImage;
      case ASSESSMENT_TYPES.RMP:
        return RmpImage;
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return NineLevelsImage;
      case ASSESSMENT_TYPES.BALANCED_YOU:
          return BalancedYouImage;
      case 'custom':
        return CustomImage;
      default:
        return Big5Image;
    }
  };

  return (
    <AssessmentPageWithFooter
      nextButtonText={translate('assessment_continue') || 'Weiter'}
      onClickNext={onClickNext}>

      <div className={classNames(styles.assessmentIntro)}>

        {/* SKELETON */}
        { skeletonOnly && (
          <div className={styles.skeleton}>
            { bpWidth > bps.S.bpWidth && (
              <SkeletonXl />
            )}
            { isS && (
              <SkeletonS />
            )}
            { bpWidth <= bps.Xs.bpWidth && (
              <SkeletonXs />
            )}

          </div>
        )}

        { !skeletonOnly && (
          <>
            {/* IMAGE */}
            <div className={styles.imageBlock}>
              <img
                src={getImage()}
                alt={'intro'}
              />
            </div>

            {/* TITLE */}
            <div className={styles.title}>
              {translate(translationIds.introTitle) || markdown(title) || normalizeText(assessmentType)}
            </div>

            {/* DESCRIPTION */}
            <div className={styles.description}>
              {translate(translationIds.introDescription) || markdown(description)}
            </div>
          </>
        )}

      </div>
    </AssessmentPageWithFooter>
  )
};

export default AssessmentIntro;
