export const GET_CAREER_LEVELS = 'GET_CAREER_LEVELS';
export const GET_CAREER_LEVELS_FULFILLED = 'GET_CAREER_LEVELS_FULFILLED';
export const GET_CAREER_LEVELS_REJECTED = 'GET_CAREER_LEVELS_REJECTED';

export const getCareerLevels = () => {
  return {
    type: GET_CAREER_LEVELS,
    payload: null
  };
};

export const getCareerLevelsFulfilled = (careerLevels) => {
  return {
    type: GET_CAREER_LEVELS_FULFILLED,
    payload: careerLevels
  };
};

export const getCareerLevelsRejected = (error) => {
  return {
    type: GET_CAREER_LEVELS_REJECTED,
    payload:  error
  };
};
