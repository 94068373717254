// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
// import styles from './Breadcrumb.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import BreadcrumbInside from './BreadcrumbInside';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
import storeNext from 'features/framework/utils/storeNext';
import { Provider } from 'react-redux';

// CONFIG & DATA
// const Config = {};

// COMPONENT: Breadcrumb
const Breadcrumb = (props) => {
  // PROPS

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Breadcrumb
  return (
    <Provider store={storeNext}>
      <BreadcrumbInside {...props} />
    </Provider>
  );
};

export default Breadcrumb;
