import * as fromActionTypes from 'store/actionTypes/survey';

// SUBMIT FEEDBACK
export const initSubmitFeedback = () => {
  return {
    type: fromActionTypes.INIT_SUBMIT_FEEDBACK,
    payload: {}
  };
};

export const submitFeedback = (feedbackDto) => {
  return {
    type: fromActionTypes.SUBMIT_FEEDBACK,
    payload: {feedbackDto}
  };
};

export const submitFeedbackFulfilled = () => {
  return {
    type: fromActionTypes.SUBMIT_FEEDBACK_FULFILLED,
    payload: {}
  };
};

export const submitFeedbackRejected = ({ error }) => {
  return {
    type: fromActionTypes.SUBMIT_FEEDBACK_REJECTED,
    payload: { error }
  };
};

// SUBMIT CONTENT FEEDBACK
export const initSubmitContentFeedback = () => {
  return {
    type: fromActionTypes.INIT_SUBMIT_CONTENT_FEEDBACK,
    payload: {}
  };
};

export const submitContentFeedback = (feedbackDto) => {
  return {
    type: fromActionTypes.SUBMIT_CONTENT_FEEDBACK,
    payload: {feedbackDto}
  };
};

export const submitContentFeedbackFulfilled = () => {
  return {
    type: fromActionTypes.SUBMIT_CONTENT_FEEDBACK_FULFILLED,
    payload: {}
  };
};

export const submitContentFeedbackRejected = ({ error }) => {
  return {
    type: fromActionTypes.SUBMIT_CONTENT_FEEDBACK_REJECTED,
    payload: { error }
  };
};

// GET CONTENT FEEDBACK
export const getContentFeedback = (requestDto) => {
  return {
    type: fromActionTypes.GET_CONTENT_FEEDBACK,
    payload: {requestDto}
  };
};

export const getContentFeedbackFulfilled = ({contentFeedback, totalCount}) => {
  return {
    type: fromActionTypes.GET_CONTENT_FEEDBACK_FULFILLED,
    payload: {contentFeedback, totalCount}
  };
};

export const getContentFeedbackRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_CONTENT_FEEDBACK_REJECTED,
    payload: { error }
  };
};
