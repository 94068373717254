import React from 'react';
import styles from './BasicUserGroup.module.scss';

import { useTranslate } from 'utils/translator';

const DESCRIPTIONS = [
  'Für Mitarbeiter ohne Personal- oder Führungsverantwortung',
  'Assessments durchführen und Ergebnisse in individuellem Profil ansehen',
  'Assessments durchführen und Ergebnisse in individuellem Profil ansehen'
];

const BasicUserGroup = () => {

  const translate = useTranslate();

  return (
    <div className={styles.basicUserGroup}>
      <div className={styles.main}>
        <div className={styles.title}>
          {translate('usergroup_settings_basic') || 'Basisprofil (Mitarbeiter)'}
        </div>

        <div className={styles.description}>
          {DESCRIPTIONS.map((description, index) => (
            <div className={styles.descRow} key={`description-${index}`}>
              <span>-</span>
              <span>{translate(`usergroup_settings_basic_descr${index + 1}`) || description}</span>
            </div>
          ))}
        </div>

      </div>
    </div>
  )
};

export default BasicUserGroup;
