// REACT, STYLE & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './UserManagement.module.scss';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import * as fromActions from 'store/actions/filterListEntities';
import * as fromSelectors from 'store/selectors/filterListEntities';

// 3RD PARTY
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import FilterListNext from 'ui/basic/molecules/FilterListNext';
import { findSortIndex, translateSortOptions, USERS_SORT_OPTIONS } from 'ui/basic/molecules/FilterListNext/FilterListNext.data';

// UTILS
import { useTranslate } from 'utils/translator';
import {selectCapabilitiesNext} from 'store/selectors/configuration';

// CONFIG & DATA
const entity = 'users';
const SORT_OPTIONS = USERS_SORT_OPTIONS;

const EMPTY_TITLE = 'Lade Mitarbeiter zu bluquist ein.';
const EMPTY_TEXT = (
  <>
    Es sind noch keine Mitarbeiter an Bord. Lade Mitarbeiter zu bluquist ein, um auf ihre Profile zuzugreifen.
    <br/><br/>
    Den Status der Profilerstellung kannst du anschließend hier verfolgen.
  </>
);
const EMPTY_FILTER_TITLE = 'Es wurden keine Mitarbeiter mit diesem Namen gefunden.'
const EMPTY_FILTER_TEXT = 'Bitte überprüfe deine Eingabe oder lade den Mitarbeiter zu bluquist ein.';

// COMPONENT: UserManagement
const UserManagement = () => {

  // STATE HOOKS
  const [ sortOptions, setSortOptions ] = useState([]);
  const [ activeSortIndex, setActiveSortIndex ] = useState(0);


  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  // STORE
  const translations = useSelector(state => state.localisation && state.localisation.translations);

  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const state = useSelector((state) => {
    return fromSelectors.selectStateByEntity(state, entity);
  });

  // initialise users
  useEffect(() => {
    dispatch(fromActions.getListItems({
      entity,
      // show old data while loading new data
      // (when coming back to the page and there is data from before)
      showLastDataWhileLoading: true
    }));
  }, [dispatch]);

  // SORT OPTIONS TRANSLATION
  useEffect(() => {
    const sortOptions = translateSortOptions(SORT_OPTIONS);
    const sortIndex = findSortIndex({
      sortOptions,
      sortBy: state.sortBy,
      sortDirection: state.sortDirection,
    });

    setActiveSortIndex(sortIndex);
    setSortOptions(sortOptions);
  }, [translations, state.sortBy, state.sortDirection]);

  // RENDER: UserManagement
  return (
    <div className={styles.userManagement}>

      <FilterListNext
        listType={'users'}
        listItems={state.listItems || []}

        loading={state.loading}
        showLastDataWhileLoading={state.showLastDataWhileLoading}
        // useFullLoading={state.firstLoad}
        showEmptyPage={state.noDataAtAll}

        onItemClick={(user) => {
          history.push(`/settings/users/${user.user.id}`);
        }}
        disableItem={(user) => {
          // return user.user.registrationStatus === 'invited';
          return false;
        }}

        searchPlaceholder={translate('users_settings_search_placeholder') || 'Nach Nutzer suchen'}
        activeSearchString={state.searchString}
        onSearch={(newSearchString) => {
          if (state.searchString !== newSearchString) {
            dispatch(fromActions.setSearchString({
              entity,
              searchString: newSearchString
            }));
          }
        }}

        activePageCount={state.pageCount}
        activePageIndex={state.pageIndex}
        onPage={(newPageIndex) => {
          dispatch(fromActions.setPageIndex({
            entity,
            pageIndex: newPageIndex
          }));
        }}

        activeSortIndex={activeSortIndex}
        sortOptions={sortOptions}
        onSort={(newSortIndex) => {
          const { sortBy, sortDirection } = SORT_OPTIONS[newSortIndex];
          dispatch(fromActions.setSort({
            entity,
            sortBy,
            sortDirection
          }));
        }}

        headline={translate('users_settings_title') || 'Alle Nutzer'}
        buttonText={capabilitiesNext.employeesInvite ? (translate('users_settings_invite_employee_btn') || 'Mitarbeiter einladen') : null}
        onButtonClick={() => {
          history.push('/user/invite')
        }}
        buttonSecondaryText={translate('users_settings_manage_usergroups_btn') || 'Nutzergruppen verwalten'}
        onButtonSecondaryClick={() => {
          history.push('/settings/user-groups/basic');
        }}

        emptyTitle={translate('users_settings_no_users_title') || EMPTY_TITLE}
        emptyText={translate('users_settings_no_users_descr') || EMPTY_TEXT}
        emptyFilterTitle={translate('users_settings_filter_no_users_title') || EMPTY_FILTER_TITLE}
        emptyFilterText={translate('users_settings_filter_no_users_descr') || EMPTY_FILTER_TEXT}

      />
    </div>
  )
};

export default UserManagement;
