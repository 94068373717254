import * as actions from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/assessments';

const initialState = {
  assessment: {},
  assessmentRegistrationSuccess: undefined,
  assessmentRegistrationProcessing: false,
  assessmentRegistrationError: null,

  saveAnswersProcessing: false,
  saveAnswersSuccess: false,

  getQuestionsProcessing: false,

  saveAnswerProcessing: false,

  processing: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ALL_ASSESSMENTS:
      return {
        ...state,
        loading: true
      };
    case actions.GET_ASSESSMENT:
    case actions.GET_ASSESSMENT_RESULT:
    case actions.LIST_ASSESSMENTS:
    case actions.GET_ASSESSMENT_ANSWERS:
    case actions.GET_ASSESSMENT_ANSWERS_FULFILLED:
      return {
        ...state,
        // ...action.payload
      }
    case actions.GET_ASSESSMENT_QUESTIONS:
      return {
        ...state,
        getQuestionsProcessing: true
      }
    case actions.GET_ALL_ASSESSMENTS_FULFILLED:
    case actions.GET_ALL_ASSESSMENTS_REJECTED:
      return {
        ...state,
        loading: false
      };
    case actions.GET_ASSESSMENT_FULFILLED:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null
      };
    case actions.GET_ASSESSMENT_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case actions.GET_ASSESSMENT_RESULT_REJECTED:
      return {
        ...state,
        ...action.payload
      };
    case actions.LIST_ASSESSMENTS_REJECTED:
    case actions.GET_ASSESSMENT_RESULT_FULFILLED:
    case actions.GET_PREV_ASSESSMENT_RESULT_FULFILLED:
    case actions.GET_PREV_ASSESSMENT_RESULT_REJECTED:
    case actions.LIST_ASSESSMENTS_FULFILLED:
      return {
        ...action.payload
      }
    case fromActionTypes.INIT_ASSESSMENTS_ERROR:
      return {
        ...state,
        ...action.payload,
        error: null
      };
    case actions.INIT_GET_ASSESSMENT_QUESTIONS:
      return {
        ...state,
        ...action.payload
      };
    case actions.GET_ASSESSMENT_QUESTIONS_FULFILLED:
      return {
        ...state,
        getQuestionsProcessing: false,
        ...action.payload,
        error: null,
      };
    case actions.GET_ASSESSMENT_QUESTIONS_REJECTED:
      return {
        ...state,
        getQuestionsProcessing: false,
        error: action.payload.error
      }
    case fromActionTypes.INIT_REGISTER_ASSESSMENT:
      return {
        ...state,
        processing: false,
        assessmentRegistrationProcessing: false,
        assessmentRegistrationSuccess: undefined
      };
    case fromActionTypes.REGISTER_ASSESSMENT:
      return {
        ...state,
        assessmentRegistrationProcessing: true
      }
    case fromActionTypes.REGISTER_ASSESSMENT_FULFILLED:
      return {
        ...state,
        assessmentRegistrationProcessing: false,
        assessmentRegistrationSuccess: true
      };
    case fromActionTypes.REGISTER_ASSESSMENT_REJECTED:
      const { error } = action.payload.error;
      return {
        ...state,
        assessmentRegistrationProcessing: false,
        assessmentRegistrationSuccess: false,
        assessmentRegistrationError: error
      };
    case actions.INIT_SAVE_ASSESSMENT_ANSWERS:
      return {
        ...state,
        saveAnswersProcessing: false,
        saveAnswersSuccess: false
      }
    case actions.SAVE_ASSESSMENT_ANSWERS:
      return {
        ...state,
        saveAnswersProcessing: true,
        saveAnswersSuccess: false
      }
    case actions.SAVE_ASSESSMENT_ANSWERS_FULFILLED:
      return {
        ...state, saveAnswersProcessing: false,
        saveAnswersSuccess: true,
        error: null
      };
    case actions.SAVE_ASSESSMENT_ANSWERS_REJECTED:
      return {
        ...state,
        saveAnswersProcessing: false,
        saveAnswersSuccess: false,
        error: action.payload.error
      };
    case fromActionTypes.SAVE_ASSESSMENT_ANSWER:
      return {
        ...state,
        saveAnswerProcessing: true,
        error: null
      };
    case fromActionTypes.SAVE_ASSESSMENT_ANSWER_FULFILLED:
      return {
        ...state,
        saveAnswerProcessing: false,
        error: null
      };
    case fromActionTypes.SAVE_ASSESSMENT_ANSWER_REJECTED:
      return {
        ...state,
        saveAnswerProcessing: false,
        error: action.payload.error.error
      };
    default:
      return { ...state };
  }
};
