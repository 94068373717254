// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './GarminTrend.module.scss';

// ASSETS
import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: GarminTrend
const GarminTrend = (props) => {
  // PROPS
  const { label, direction = 'positive' } = props;

  const Arrow = IconsSvg.Arrow;

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: GarminTrend
  return (
    <div className={classNames(styles.garminTrend)}>
      <span>{label}</span>
      <Arrow className={styles[direction]} />
    </div>
  );
};

export default GarminTrend;
