export const COMPANY_FEATURES = {
  TEAM_MAPPING: 'MODULE_TEAMMAPPING',
  ROLE_MAPPING: 'MODULE_ROLEMAPPING',
  WELL_BEING: 'MODULE_WELLBEING',
  INTEGRATION_GARMIN: 'INTEGRATION_GARMIN',
  BIG5_ASSESSMENT: 'ASSESSMENT_BIG5',
  POTENTIAL_ASSESSMENT: 'ASSESSMENT_POTENTIAL',
  WORK_PREF_ASSESSMENT: 'ASSESSMENT_WORK-PREFERENCE',
  KEY_COMP_ASSESSMENT: 'ASSESSMENT_KEY-COMP',
  LEAD_COMP_ASSESSMENT: 'ASSESSMENT_LEAD-COMP',
  RMP_ASSESSMENT: 'ASSESSMENT_RMP',
  NINE_LEVELS_ASSESSMENT: 'ASSESSMENT_9LEVELS'
};
