import React, { useCallback, useEffect, useState } from 'react';
import styles from './OptionsMenu.module.scss';

// 3RD PARY
import classNames from 'classnames';

// UTILS
import { handleRipple } from 'utils/ripple';
import { useBreakpoint, useDebounce, useWindowWidth } from 'utils/hooks';

// OTHER COMPONENTS
import { ReactComponent as OptionsIcon } from 'assets/icons/icn_options.svg';
import { BluCSSTransition } from 'ui/basic';


const OptionsMenu = (props) => {
  const {
    withBackground,
    withBackgroundLight,
    withBackgroundDark,
    flyoutPosition = 'right',
    options,
    onClick
  } = props;

  // SPECIAL HOOKS
  const { isXs } = useBreakpoint();
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 300);

  const [ node, setNode ] = useState();
  const ref = useCallback(newNode => {
    if (newNode !== node && newNode !== null) {
      setNode(newNode);
    }
  }, [ node ]);

  const [ leftPosition, setLeftPosition ] = useState();
  useEffect(() => {
    if (debouncedWindowWidth) {
      const left = node.getBoundingClientRect().left;
      setLeftPosition(isXs ? -(left - 16) : 'unset');
    }
  }, [ node, isXs, debouncedWindowWidth ]);

  const [showMenu, setShowMenu] = useState(false);

  const showBackground = withBackground;

  if (!options?.length) {
    return null;
  }

  // RENDER
  return (
    <div
      tabIndex={'0'}
      ref={ref}
      className={classNames(styles.optionsMenu, {
        [styles.withBackground]: withBackground,
        [styles.withBackgroundLight]: withBackgroundLight,
        [styles.withBackgroundDark]: withBackgroundDark
      })}
      onBlur={() => {
        setTimeout(() => {
          setShowMenu(false);
        }, 100);
      }}
      onClick={(event) => {
        event.stopPropagation();
        // prevent further propagation for instance for use in Cards
        // with their own onClick behavior.
        setShowMenu(!showMenu);
      }}
    >

      { showBackground && (
        <div className={classNames(styles.background, {[styles.active]: showMenu})}>
          <OptionsIcon />
        </div>
      )}

      { !showBackground && <OptionsIcon />}

      <BluCSSTransition in={showMenu} classNames={{ ...styles }}>
        <div
          style={{left: leftPosition}}
          className={classNames(
            styles.flyout,
            styles[flyoutPosition]
          )}>
          {options.map((option, index) => {
            const menuItemProps = {
              key: index,
              className: styles.menuItem,
              onClick: event => {
                handleRipple(event, styles.colorPrimary3);
                window.setTimeout(() => {
                  onClick(option.value, event);
                  setShowMenu(false);
                }, styles.animationDurationLongMs);
              }
            };

            return (
              <div {...menuItemProps}>
                <span>{option.label}</span>
              </div>
            );
          })}
        </div>
      </BluCSSTransition>
    </div>
  )
};

OptionsMenu.defaultProps = {
  options: [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'}
  ]
};

export default OptionsMenu;
