// REACT, STYLE, STORIES & COMPONENT
import React, {useRef, useState} from 'react';
import styles from './GarminIndividualResult.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {Card, CollapsiblePanel, InfoTitle, Button, Modal} from 'ui/basic';
import GarminIndividualReport from '../GarminIndividualReport';
import GarminIcon from 'features/+garmin/ui/basic/GarminIcon';

// UTILS
import {timestampToFullDate} from 'utils/dateTools';
import {useTranslate} from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: GarminIndividualResult
const GarminIndividualResult = (props) => {
  // PROPS
  const { result, showPendingState } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const noSleepCopyRef = useRef();

  const [reportPage, setReportPage] = useState();
  const [reportVisible, setReportVisible] = useState();

  const [infoModalVisible, setInfoModalVisible] = useState();
  const [infoModalTitle, setInfoModalTitle] = useState();
  const [infoModalCopy, setInfoModalCopy] = useState();

  const bodyBatteryScoreClass = result && result.bodyBattery && result.bodyBattery.score && result.bodyBattery.score.class;
  const bodyBatteryTimestamp = result && result.bodyBattery && result.bodyBattery.score && result.bodyBattery.score.timestamp;

  const sleepScoreClass = result && result.sleep && result.sleep.score && result.sleep.score.class;
  const stressScoreClass = result && result.stress && result.stress.score && result.stress.score.class;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleSleepPanelExpand = () => {
    const noSleepCopyElement = noSleepCopyRef.current;
    let links = noSleepCopyElement && noSleepCopyElement.getElementsByTagName('a');

    if (!links || !links[0] || (links[0] && links[0]['target'])) {
      return;
    }

    links[0]['target'] = '_blank';
  }


  // RENDER: Skeleton
  if (!showPendingState && !result) {
    return (
      <div className={styles.garminIndividualResultSkeleton}>
        <Card>
          <div className={styles.header}></div>
          <div className={styles.subHeader}></div>

          {new Array(6).fill(1).map((value, index) => (
            <div className={styles.bars} key={index}>
              <div></div>
              <div></div>
            </div>
          ))}
        </Card>
      </div>
    )
  }

  // RENDER: GarminIndividualResult
  return (
    <div className={classNames(styles.garminIndividualResult)}>
      <Card hasNoPaddings>
        {/*IF NO RESULT*/}
        {(showPendingState && !result) &&
          <div className={styles.blockWithPaddings}>
            <InfoTitle
              title={translate('garmin_connect')}
              className={styles.infoTitle}
              onClick={() => {
                setInfoModalTitle(translate('garmin_connect_modal_title'));
                setInfoModalCopy(translate('garmin_connect_modal_copy'));
                setInfoModalVisible(true);
              }}
            />
            <div className={classNames('blu-typeCopy', 'marginTopS')}>
              {translate('garmin_ind_result_pending_copy')}
            </div>

            <div
              className={styles.additionalInfo}
              onClick={() => {
                setInfoModalTitle(translate('garmin_ind_result_pending_additional_info_modal_title'));
                setInfoModalCopy(translate('garmin_ind_result_pending_additional_info_modal_copy'));
                setInfoModalVisible(true);
              }}
            >
              {translate('garmin_ind_result_pending_additional_info')}
            </div>
          </div>
        }

        {result &&
          <>
            <div className={styles.blockWithPaddings}>
              <InfoTitle
                title={translate('garmin_connect')}
                className={styles.infoTitle}
                onClick={() => {
                  setInfoModalTitle(translate('garmin_connect_modal_title'));
                  setInfoModalCopy(translate('garmin_connect_modal_copy'));
                  setInfoModalVisible(true);
                }}
              />
              <GarminIcon
                looks='body-battery'
                size='M'
                scoreClass={bodyBatteryScoreClass}
              />
              <div className={classNames('blu-typeLabel', 'marginTopXs')}>
                {translate(`body_battery_${bodyBatteryScoreClass}_title`)}
              </div>
              <div className={classNames('blu-typeCopy', 'marginTopXxs')}>
                {translate(`body_battery_${bodyBatteryScoreClass}_copy_short`)}
              </div>
              <Button
                size='S'
                looks='secondary'
                onClick={() => {
                  setReportPage('body-battery');
                  setReportVisible(true);
                }}
              >{translate('learn_more')}</Button>
            </div>

            <div className={styles.collapsiblePanels}>
              {/*Sleep*/}
              <CollapsiblePanel
                header={(
                  <div className={styles.collapsibleInnerHeader}>
                    <GarminIcon
                      looks='sleep'
                      size='S'
                      scoreClass={sleepScoreClass}
                    />
                    <span className='blu-typeLabel'>{translate('sleep')}</span>
                  </div>
                )}
                headerClassName={styles.collapsibleHeader}
                showTopBorder={false}
                onExpand={handleSleepPanelExpand}
              >
                <>
                  <div className={classNames('blu-typeLabel', 'marginTopXxs')}>
                    {sleepScoreClass ?
                      translate(`sleep_${sleepScoreClass}_title`) :
                      translate('sleep_no_value_title')
                    }
                  </div>

                  <div className={classNames('blu-typeCopy', 'marginTopXxs')} ref={noSleepCopyRef}>
                    {sleepScoreClass ?
                      translate(`sleep_${sleepScoreClass}_copy_short`) :
                      translate('sleep_no_value_copy')
                    }
                  </div>
                </>

              </CollapsiblePanel>

              {/*Stress*/}
              <CollapsiblePanel
                header={(
                  <div className={styles.collapsibleInnerHeader}>
                    <GarminIcon
                      looks='stress'
                      size='S'
                      scoreClass={stressScoreClass}
                    />
                    <span className='blu-typeLabel'>{translate('stress')}</span>
                  </div>
                )}
                headerClassName={styles.collapsibleHeader}
                showTopBorder={false}
              >
                {stressScoreClass &&
                  <>
                    <div className={classNames('blu-typeLabel', 'marginTopXxs')}>
                      {translate(`stress_${stressScoreClass}_title`)}
                    </div>
                    <div className={classNames('blu-typeCopy', 'marginTopXxs')}>
                      {translate(`stress_${stressScoreClass}_copy_short`)}
                    </div>
                  </>
                }
              </CollapsiblePanel>
            </div>

            <div className={styles.blockWithPaddings}>
              <div className={styles.hint}>
                {translate('garmin_data_from', ['{{date}}', timestampToFullDate(bodyBatteryTimestamp)])}
              </div>
            </div>
          </>
        }
      </Card>

      {/*INFO MODAL*/}
      {infoModalVisible &&
        <Modal
          header={infoModalTitle}
          secondaryButtonTitle={translate('ok')}
          onClose={() => setInfoModalVisible(false)}
        >
          {infoModalCopy}
        </Modal>
      }

      {/*FULL PAGE REPORT MODAL*/}
      {reportVisible &&
        <GarminIndividualReport
          page={reportPage}
          userScore={result}
          onClose={() => setReportVisible(false)}
        />
      }
    </div>
  );
};

export default GarminIndividualResult;
