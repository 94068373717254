import { createSlice, createSelector } from '@reduxjs/toolkit';

// initialState
const initialState = {
  mainNav: {
    isOpen: false,
    isOpenDebounced: false,
  },
  subNav: {
    isOpen: false
  }
};

// selectors
export const selectLayout = state => state.layout;
// mainNav
const selectMainNav = createSelector(
  selectLayout,
  (layout) => layout.mainNav
);
export const selectMainNavIsOpen = createSelector(
  selectMainNav,
  (mainNav) => mainNav.isOpen
)
export const selectMainNavIsOpenDebounced = createSelector(
  selectMainNav,
  (mainNav) => mainNav.isOpenDebounced
)
// subNav
const selectSubNav = createSelector(
  selectLayout,
  (layout) => layout.subNav
);
export const selectSubNavIsOpen = createSelector(
  selectSubNav,
  (subNav) => subNav.isOpen
)

// slice & reducers
export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {

    // mainNav
    setMainNavIsOpen: (state, action) => {
      const open = action.payload;
      state.mainNav.isOpen = open;
    },
    toggleMainNavIsOpen: (state) => {
      state.mainNav.isOpen = !state.mainNav.isOpen;
    },
    setMainNavIsOpenDebounced: (state, action) => {
      const open = action.payload;
      state.mainNav.isOpenDebounced = open;
    },
    toggleMainNavIsOpenDebounced: (state) => {
      state.mainNav.isOpenDebounced = !state.mainNav.isOpenDebounced;
    },

    // subNav
    setSubNavIsOpen: (state, action) => {
      const open = action.payload;
      state.subNav.isOpen = open;
    },
    toggleSubNavIsOpen: (state) => {
      state.subNav.isOpen = !state.subNav.isOpen;
    }
  }
})

// reducer
export default layoutSlice.reducer

// actions
export const {
  setMainNavIsOpen, toggleMainNavIsOpen,
  setMainNavIsOpenDebounced, toggleMainNavIsOpenDebounced,
  setSubNavIsOpen, toggleSubNavIsOpen,
} = layoutSlice.actions;
