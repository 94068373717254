import React, {useCallback, useEffect, useState} from 'react';
import styles from './ShareProfileLinkModal.module.scss';

import * as api from 'api';

import classNames from 'classnames';

import {showToast} from 'store/actions';
import {useDispatch} from 'react-redux';

import { useTranslate } from 'utils/translator';
import {dateHasValidFormat, isTimestampInPast, timestampToFullDate} from 'utils/dateTools';

import {IconsSvg} from 'assets/icons';
import {Button, Checkbox, InputMasked, InputNext, InputPassword, Link, Modal, PillGroup} from 'ui/basic';

const VIEW_TYPES = {
  CREATE_LINK: 'create link',
  MANAGE_LINKS: 'manage links'
}

const TrashIcon = IconsSvg.Trash;


const ShareProfileLinkModal = (props) => {
  const {
    userId,
    isCandidate,
    passwordRequired,
    passwordNeedsValidation,
    showPersonalLinks,
    onClose
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();

  const shareTokenApi = isCandidate ? `recruiting/candidates/${userId}/share` : '/core/user/profile/share';

  const [view, setView] = useState(VIEW_TYPES.CREATE_LINK);

  const [password, setPassword] = useState();
  const [passwordCorrect, setPasswordCorrect] = useState();

  const [validTo, setValidTo] = useState();
  const [validToErrorMessage, setValidToErrorMessage] = useState();

  const [description, setDescription] = useState();
  const [isAnonymized, setIsAnonymized] = useState();

  const [createProfileLinkProcessing, setCreateProfileLinkProcessing] = useState();

  const [deleteModalVisible, setDeleteModalVisible] = useState();
  const [linkIdToDelete, setLinkIdToDelete] = useState();
  const [deleteProcessing, setDeleteProcessing] = useState();

  const getUserProfileLinks = useCallback(() => {
    api.get(shareTokenApi, {user: userId})
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          setProfileLinks(data.tokens
            .filter(token => token.isPersonal === showPersonalLinks)
            .sort((itemA, itemB) => itemB.creationDate - itemA.creationDate)
            .map(token => {
              return {
                ...token,
                expired: token.validUntil !== -1 && isTimestampInPast(token.validUntil),
                validIndefinitely: token.validUntil === -1
              }
          }));
        }
      })
      .catch(error => console.error(error.message))
  }, [userId, showPersonalLinks, shareTokenApi]);

  const [profileLinks, setProfileLinks] = useState([]);
  useEffect(() => {
    getUserProfileLinks();
  }, [getUserProfileLinks]);

  const handleCandidateShareTokenCreate = () => {
    setCreateProfileLinkProcessing(true);

    let validUntil;
    if (validTo) {
      const splitArray = validTo.split('.');
      // -1 for month because month numeration starts with 0
      // 0 - January, 11 - December
      validUntil = (new Date(splitArray[2], splitArray[1] - 1, splitArray[0], 23, 59, 59, 0).getTime()) / 1000;
    } else {
      validUntil = -1;
    }

    const queryParams = userId ? `user=${userId}` : '';

    api.post(`${shareTokenApi}?${queryParams}`, {
      password,
      validUntil,
      description,
      anonymous: !!isAnonymized
    })
      .then(({ok, status, data}) => {
        setCreateProfileLinkProcessing(false);

        if (ok && status === 200) {
          setView(VIEW_TYPES.MANAGE_LINKS);
          getUserProfileLinks();
          navigator.clipboard.writeText(`${window.location.origin}/share/${data.id}`);

          const toastHeader = translate('share_profile_link_created_toast_header') || 'Profil-Link erstellt und kopiert.';
          const toastContent = translate('share_profile_link_created_toast_content') ||
            'Der Profil-Link wurde erfolgreich erstellt und in die Zwischenablage kopiert. ';
          dispatch(showToast(toastHeader, toastContent));
        }
      })
      .catch(error => {
        setCreateProfileLinkProcessing(false);
        console.error(error.message);
      });
  }

  const handleEmployeeShareTokenCreate = () => {
    setCreateProfileLinkProcessing(true);

    let validUntil;
    if (validTo) {
      const splitArray = validTo.split('.');
      // -1 for month because month numeration starts with 0
      // 0 - January, 11 - December
      validUntil = (new Date(splitArray[2], splitArray[1] - 1, splitArray[0], 23, 59, 59, 0).getTime()) / 1000;
    } else {
      validUntil = -1;
    }

    const queryParams = userId ? `user=${userId}` : '';

    api.put(`${shareTokenApi}?${queryParams}`, {
      password,
      validUntil,
      description,
      anonymous: !!isAnonymized
    })
      .then(({ok, status, data}) => {
        setCreateProfileLinkProcessing(false);

        if (ok && status === 200) {
          setView(VIEW_TYPES.MANAGE_LINKS);
          getUserProfileLinks();
          navigator.clipboard.writeText(`${window.location.origin}/share/${data.id}`);

          const toastHeader = translate('share_profile_link_created_toast_header') || 'Profil-Link erstellt und kopiert.';
          const toastContent = translate('share_profile_link_created_toast_content') ||
            'Der Profil-Link wurde erfolgreich erstellt und in die Zwischenablage kopiert. ';
          dispatch(showToast(toastHeader, toastContent));
        }
      })
      .catch(error => {
        setCreateProfileLinkProcessing(false);
        console.error(error.message);
      });
  }

  return (
    <div>
      <Modal
        header={view === VIEW_TYPES.CREATE_LINK ?
          (translate('share_profile_create_link_title') || 'Profil-Link erzeugen') :
          (translate('share_profile_manage_links_title') || 'Profil-Links verwalten')
        }
        preHeader={(
          <PillGroup
            pillItems={[
              {id: VIEW_TYPES.CREATE_LINK, label: (translate('share_profile_create_link_title') || 'Profil-Link erstellen')},
              {id: VIEW_TYPES.MANAGE_LINKS, label: (translate('share_profile_manage_links_title') || 'Profil-Links verwalten')}
            ]}
            activeIndex={view === VIEW_TYPES.CREATE_LINK ? 0 : 1}
            onPillClick={pillItem => {
              setPassword(undefined);
              setValidTo(undefined)
              setDescription(undefined);
              setIsAnonymized(undefined);

              setView(pillItem.id);
            }}
          />
        )}
        primaryButtonTitle={view === VIEW_TYPES.CREATE_LINK &&
        (translate('share_profile_create_link_btn') || 'Link erstellen')
        }
        primaryButtonDisabled={(passwordRequired && (!password || !passwordCorrect)) ||
        ((validTo && validTo.length < 10) || validToErrorMessage) || createProfileLinkProcessing
        }
        secondaryButtonTitle={translate('close_lbl') || 'Schließen'}
        closeOnConfirm={false}
        onClose={onClose}
        onConfirm={isCandidate ? handleCandidateShareTokenCreate : handleEmployeeShareTokenCreate}
      >
        <div className={styles.shareProfileLinkModalContent}>
          {/*CREATE EXTERNAL PROFILE LINK*/}
          {view === VIEW_TYPES.CREATE_LINK &&
          <>
            <div className={styles.copy}>
              {translate('share_profile_create_link_copy') ||
              'Du kannst einen Link erstellen, der den Zugriff für externe Benutzer ermöglicht. Nutzer mit dem Link können das Profil einsehen aber nicht bearbeiten.'
              }
            </div>

            <div className={styles.label}>
              {translate('share_profile_create_link_label') || 'Wähle die Einstellungen für den Profil-Link.'}
            </div>

            <div className={styles.inputs}>
              <InputPassword
                placeHolder={passwordRequired ? (translate('share_profile_mandatory_password_placeholder') || 'Passwort wählen (erforderlich)') :
                  (translate('share_profile_optional_password_placeholder') || 'Passwort wählen (optional)')
                }
                showCriteria={!!passwordNeedsValidation}
                hideCriteriaOnBlur={false}
                onInputChanged={(value, correct) => {
                  setPassword(value);
                  setPasswordCorrect(correct);
                }}
              />
              <InputMasked
                label={translate('share_profile_validto_placeholder') || 'Gültig bis (optional)'}
                hint={translate('share_profile_validto_hint') ||
                'Der Link bleibt bis zum gewählten Datum verfügbar. Wenn du kein Datum festlegst, bleibt der Link unbegrenzt gültig.'
                }
                mask={'99.99.9999'}
                errorMessage={validToErrorMessage}
                onChange={value => {
                  if (!value) {
                    setValidTo(undefined);
                    setValidToErrorMessage('');
                    return;
                  }

                  const splitArray = value.split('.');
                  const day = splitArray[0] && Number(splitArray[0]);
                  const month = splitArray[1] && Number(splitArray[1]);
                  const year = splitArray[2] && Number(splitArray[2]);

                  if (!dateHasValidFormat(value)) {
                    setValidToErrorMessage(translate('share_profile_validto_invalid_format_msg') || 'Invalid format');
                    // -1 for month because month numeration starts with 0
                    // 0 - January, 11 - December
                  } else if (isTimestampInPast(new Date(year, month - 1, day).getTime() / 1000)) {
                    setValidToErrorMessage(translate('share_profile_validto_date_in_past_msg') || 'Date in the past');
                  } else {
                    setValidToErrorMessage('');
                  }

                  setValidTo(value);
                }}
              />
              <InputNext
                label={translate('share_profile_description_placeholder') || 'Beschreibung (optional)'}
                onChange={value => setDescription(value)}
              />
              <Checkbox
                name={translate('share_profile_anonymize_checkbox_name') ||
                'Anonymisierte Darstellung (persönliche Daten werden ausgeblendet)'
                }
                onChange={value => setIsAnonymized(value)}
              />
            </div>
          </>
          }

          {/*MANAGE PROFILE LINKS*/}
          {view === VIEW_TYPES.MANAGE_LINKS &&
          <div className={styles.rows}>
            {profileLinks.length === 0 &&
            (translate('share_profile_no_links') ||
              'Es liegen keine Links für dieses Profil vor. Sobald Profil-Links erstellt wurden, kannst du sie hier einsehen und verwalten.')
            }

            {profileLinks.length > 0 && profileLinks.map((profileLink, index) => (
              <div className={styles.row} key={index}>
                <div className={styles.line1}>
                  <Link
                    to={`${window.location.origin}/share/${profileLink.id}`}
                    openInNewTab
                    active={!profileLink.expired}
                  >
                    {`${window.location.origin}/share/${profileLink.id} ${profileLink.expired ? `(${(translate('expired_lbl') || '(expired)')})` : ''}`}
                  </Link>

                  <div
                    className={styles.trashIcon}
                    onClick={() => {
                      setLinkIdToDelete(profileLink.id);
                      setDeleteModalVisible(true);
                    }}
                  >
                    <TrashIcon />
                  </div>
                  <Button
                    size={'S'}
                    looks={'secondary'}
                    disabled={profileLink.expired}
                    onClick={() => {
                      navigator.clipboard.writeText(`${window.location.origin}/share/${profileLink.id}`);
                    }}
                  >
                    {translate('copy_lbl') || 'Kopieren'}
                  </Button>
                </div>

                <div className={styles.line2}>
                  <span>
                    {translate('share_profile_created_at') || 'Erstellt:'} {timestampToFullDate(profileLink.creationDate)}
                  </span>
                  {!profileLink.validIndefinitely &&
                  <span className={classNames({[styles.expired]: profileLink.expired})}>
                    {translate('share_profile_valid_until')} {timestampToFullDate(profileLink.validUntil)}
                  </span>
                  }
                  {profileLink.isPasswordProtected &&
                  <span>{translate('share_profile_pwd_protected') || 'passwortgeschützt'}</span>
                  }
                  <span>
                    {translate('share_profile_anonymization') || 'Anonymisierung: '}
                    {profileLink.isAnonymous ?
                      (translate('active_lbl') || 'aktiv') :
                      (translate('inactive_lbl') || 'inaktiv')
                    }
                  </span>

                  {profileLink.description &&
                  <span>{translate('description_lbl') || 'Beschreibung'} {profileLink.description}</span>
                  }
                </div>
              </div>
            ))
            }

          </div>
          }

        </div>
      </Modal>

      {deleteModalVisible &&
      <Modal
        header={translate('share_profile_delete_link_modal_header') || 'Bestätigung erforderlich'}
        secondaryButtonTitle={translate('cancel_lbl') || 'Abbrechen'}
        redButtonTitle={translate('delete_lbl') || 'Löschen'}
        redButtonDisabled={deleteProcessing}
        closeOnConfirm={false}
        onClose={() => setDeleteModalVisible(false)}
        onConfirm={() => {
          setDeleteProcessing(true);

          api._delete(`${shareTokenApi}/${linkIdToDelete}`)
            .then(({ok, status}) => {
              setDeleteProcessing(false);

              if (ok && status === 200) {
                setDeleteModalVisible(false);
                getUserProfileLinks();

                const toastHeader = translate('share_profile_link_deleted_toast_header') || 'Profil-Link entfernt';
                const toastContent = translate('share_profile_link_deleted_toast_content') || 'Der Link wurde erfolgreich gelöscht. Das Profil ist nicht länger über diesen Link erreichar.';
                dispatch(showToast(toastHeader, toastContent));
              }
            })
            .catch(error => {
              setDeleteProcessing(false);
              console.error(error.message);
            });
        }}
      >
        {translate('share_profile_delete_link_modal_content') ||
        'Bitte bestätige die Löschung des Links. Das Profil ist anschließend nicht mehr über diesen Link erreichbar.'}
      </Modal>
      }
    </div>
  )
};

export default ShareProfileLinkModal;
