// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useRef } from 'react';
import styles from './LogoUpload.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, InputFile } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import * as api from 'api';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { selectLogo, selectIsCustomLogoActive } from 'store/selectors/configuration';
import { setConfigurationCustomLogoUrl } from 'store/actions/configuration';

// CONFIG & DATA
const Config = {
  maxSizeMB: 3,
};

// COMPONENT: LogoUpload
const LogoUpload = (props) => {
  // PROPS
  // const { children } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isCustomLogoActive = useSelector(selectIsCustomLogoActive);
  const logo = useSelector(selectLogo);

  // INPUT REF
  const inputRef = useRef();

  // LOGO UPLOAD: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ processing, setProcessing ] = useState(false);
  const [ backendError, setBackendError ] = useState('');

  const getLogoUrlWithCacheBust = () => {
    return `${api.getCustomLogoUrl()}?${Date.now()}`;
  };

  const handleLogoUpload = async (file) => {
    setBackendError();

    const form = new FormData();
    form.append('image[image]', file);

    const upload = await api.post('/core/company/logo', form, {
      'Content-Type': 'multipart/form-data'
    });
    if (!upload.ok) {
      const data = upload.data;
      setBackendError(data.error && data.error.errorMessage);
    }
    else {
      dispatch(setConfigurationCustomLogoUrl(getLogoUrlWithCacheBust()));
    }
  };

  const handleLogoDelete = async () => {
    setProcessing(true);
    const _delete = await api._delete('/core/company/logo');

    setProcessing(false);
    if (!_delete.ok) {
      setBackendError(_delete.data && _delete.data.error && _delete.data.errorMessage);
    }
    else {
      dispatch(setConfigurationCustomLogoUrl());
      // reset input
      if(inputRef && inputRef.current) {
        inputRef.current.value = '';
      };
    }
  };

  // RENDER: LogoUpload
  return (
    <div className={classNames(styles.logoUpload)}>
      <InputFile
        // Logo hochladen
        label={translate('org_settings_logo_upload')}
        inputRef={inputRef}
        initialFileName={(isCustomLogoActive && 'logo.png') || null}
        maxSizeMB={Config.maxSizeMB}
        allowedTypes={['svg', 'jpg', 'jpeg', 'png']}
        error={backendError}
        onValid={handleLogoUpload}
      />

      { isCustomLogoActive && (
        <>
          <div className={styles.preview}>
            {<img src={logo} alt='logo' />}
          </div>

          <div className={styles.delete}>
            <Button looks='secondary' size='S'
              showProgressIndicator={processing}
              onClick={handleLogoDelete}>
              {/* Standard-Logo wiederherstellen */}
              { translate('org_settings_logo_reset') }
            </Button>
          </div>
        </>
      )}

    </div>
  );
};

export default LogoUpload;
