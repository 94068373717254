import * as fromActionTypes from 'store/actionTypes/resetPassword';

const initialState = {
  status: 'invalid',
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fromActionTypes.INIT_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        status: 'invalid'
      }
    case fromActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state
      };
    case fromActionTypes.RESET_PASSWORD_REQUEST_FULFILLED:
      return {
        ...state,
        status: 'valid'
      };
    case fromActionTypes.RESET_PASSWORD_REQUEST_REJECTED:
      return {
        ...state,
        status: 'invalid',
        error: action.payload
      };
    default:
      return state;
  }
};
