// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './CandidateProfile.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {CollapsibleNext, ImgCircle} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import * as api from 'api';

// CONFIG & DATA
// const Config = {};
const CANDIDATE_INFO = [
  {value: 'mail', labelFallback: 'E-Mail'},
  {value: 'yearOfBirth', labelKey: 'year_of_birth', labelFallback: 'Alter', lookInCandidateData: true},
  {value: 'gender', labelKey: 'rmp_ass_form_gender_label', labelFallback: 'Geschlecht', lookInCandidateData: true},
  {value: 'residenceWithNationality', labelKey: 'residence_and_nationality', labelFallback: 'Wohnhaft in / Nationalität', lookInCandidateData: true},
  {value: 'education', labelKey: 'highest_school_degree', labelFallback: 'Höchster Schulabschluss', lookInCandidateData: true},
  {value: 'careerLevel', labelKey: 'current_career_level', labelFallback: 'Akt. Karrierestufe', lookInCandidateData: true},
  {value: 'positionAndJobFamily', labelKey: 'position_and_jobFamily', labelFallback: 'Aktuelle Position / Job-Familie', lookInCandidateData: true},
  {value: 'industryExperienceMapped', labelKey: 'industry_experience', labelFallback: 'Branchenerfahrung', lookInCandidateData: true},
  {value: 'motivation', labelKey: 'motivation', labelFallback: 'Motivation', lookInCandidateData: true},
  {value: 'jobRequirements', labelKey: 'job_requirements', labelFallback: 'Job-Anforderungen', lookInCandidateData: true},
  {value: 'earliestStart', labelKey: 'start_termin', labelFallback: 'Start-Termin', lookInCandidateData: true},
];

// COMPONENT: CandidateProfile
const CandidateProfile = (props) => {
  // PROPS
  const {candidate = {}} = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: CandidateProfile
  return (
    <div className={styles.candidateProfile}>
      <div className={styles.imageBlock}>
        <div className={classNames(styles.left, {[styles.halfVisible]: candidate.disabled})}>
          <ImgCircle
            className='darker'
            src={api.getUserImageUrl(candidate.id)}
            label1={candidate.name ? candidate.name.split(' ')[0] : ''}
            label2={candidate.name ? candidate.name.split(' ')[1] : ''}
            size='M'
          />
        </div>
        <div className={styles.right}>
          <div className={styles.name}>{candidate.name}</div>
          <div className={styles.position}>{candidate.candidateData ? candidate.candidateData.currentPosition : ''}</div>
        </div>
      </div>

      <div className='marginTopS'>
        <CollapsibleNext
          header={<span className='blu-typeLabel'>Details</span>}
          headerClassName={styles.header}
          withBorders
        >
          <>
            {CANDIDATE_INFO.map((candidateInfo, index) => {
                let value = '-';
                if (candidateInfo.lookInCandidateData) {
                  value = candidate.candidateData[candidateInfo.value];
                } else {
                  value = candidate[candidateInfo.value];
                }

                return (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.label}>
                      {candidateInfo.labelKey ?
                        (translate(candidateInfo.labelKey) || candidateInfo.labelFallback) :
                        candidateInfo.labelFallback
                      }
                    </div>
                    <div className={styles.value}>
                      {!value && '-'}

                      {value && (
                        Array.isArray(value) ? <ul>{value.map((v, index) => <li key={index}>{v}</li>)}</ul> : value
                      )}
                    </div>
                  </div>
                )
              }
            )}
          </>
        </CollapsibleNext>
      </div>

    </div>
  )
};

export default CandidateProfile;
