import React, {memo} from 'react';
import styles from './InfoTitle.module.scss';
import {ReactComponent as InfoBlue} from 'assets/icons/icn_info_blue.svg';
import classNames from 'classnames';

const InfoTitle = memo((props) => {
  const {title, type = 'bold', fullWidth, className, onClick} = props;

  return (
    <div
      className={classNames(
        styles.infoTitle,
        styles[type],
        {[styles.fullWidth]: fullWidth},
        className
      )}
      onClick={onClick}
    >
      <span>{title}</span>
      <InfoBlue onClick={onClick}/>
    </div>
  )
});

export default InfoTitle;
