import * as fromActionTypes from 'store/actionTypes/roles';
import {PLATFORM_ROLES} from 'utils/configuration/const/roles';

const initialState = {
  roles: [],
  roleNext: '',
  orgRoles: null,
  totalCount: 0,

  orgRole: null,
  orgRoleError: null,

  referencesProfiles: null,
  expandedReferences: null,

  newOrgRoleProcessing: false,
  newOrgRoleSuccess: false,
  newOrgRoleError: null,
  createdOrgRoleId: null,

  updateProcessing: false,
  updateOrgRoleSuccess: false,
  updateError: null,

  deleteProcessing: false,
  deleteSuccess: false,
  deletedRoleId: null,
  deletedRoleName: '',
  deleteError: null,

  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.GET_PLATFORM_ROLES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_PLATFORM_ROLES_FULFILLED: {
      const { roles } = action.payload;
      return {
        ...state,
        roles,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_PLATFORM_ROLES_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.CREATE_PLATFORM_ROLE: {
      return {
        ...state,
        ...action.payload,
        loading: true
      };
    }
    case fromActionTypes.CREATE_PLATFORM_ROLE_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.CREATE_PLATFORM_ROLE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.UPDATE_PLATFORM_ROLE: {
      return {
        ...state,
        ...action.payload,
        loading: true
      };
    }
    case fromActionTypes.UPDATE_PLATFORM_ROLE_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.UPDATE_PLATFORM_ROLE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.GET_ORG_ROLES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_ORG_ROLES_FULFILLED: {
      const { roles, totalCount } = action.payload;
      return {
        ...state,
        orgRoles: roles,
        totalCount,
        loading: false,
        error: null
      };
    }
    case fromActionTypes.GET_ORG_ROLES_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error
      };
    }
    case fromActionTypes.INIT_GET_ORG_ROLE: {
      return {
        ...state,
        loading: false,
        orgRole: null,
        expandedReferences: null,
        error: null
      };
    }
    case fromActionTypes.GET_ORG_ROLE: {
      return {
        ...state,
        loading: true,
        orgRoleError: null
      };
    }
    case fromActionTypes.GET_ORG_ROLE_FULFILLED: {
      const {role} = action.payload;
      return {
        ...state,
        orgRole: role,
        loading: false,
        orgRoleError: null
      };
    }
    case fromActionTypes.GET_ORG_ROLE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        orgRoleError: error
      };
    }
    case fromActionTypes.INIT_CREATE_ORG_ROLE: {
      return {
        ...state,
        newOrgRoleProcessing: false,
        newOrgRoleSuccess: false,
        newOrgRoleError: null,
        error: null
      };
    }
    case fromActionTypes.CREATE_ORG_ROLE: {
      return {
        ...state,
        newOrgRoleProcessing: true
      };
    }
    case fromActionTypes.CREATE_ORG_ROLE_FULFILLED: {
      const {roleId} = action.payload;

      return {
        ...state,
        newOrgRoleProcessing: false,
        newOrgRoleSuccess: true,
        createdOrgRoleId: roleId,
        newOrgRoleError: null,
        error: null
      };
    }
    case fromActionTypes.CREATE_ORG_ROLE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        newOrgRoleProcessing: false,
        newOrgRoleSuccess: false,
        newOrgRoleError: error
      };
    }
    case fromActionTypes.INIT_UPDATE_ORG_ROLE: {
      return {
        ...state,
        updateProcessing: false,
        updateOrgRoleSuccess: false,
        referencesProfiles: null,
        orgRoleError: null
      };
    }
    case fromActionTypes.UPDATE_ORG_ROLE: {
      return {
        ...state,
        updateProcessing: true,
        orgRoleError: null
      };
    }
    case fromActionTypes.UPDATE_ORG_ROLE_FULFILLED: {
      return {
        ...state,
        updateProcessing: false,
        updateOrgRoleSuccess: true,
        orgRoleError: null
      };
    }
    case fromActionTypes.UPDATE_ORG_ROLE_REJECTED: {
      const {error} = action.payload;

      return {
        ...state,
        updateProcessing: false,
        updateOrgRoleSuccess: false,
        orgRoleError: error
      };
    }
    case fromActionTypes.INIT_DELETE_ORG_ROLE: {
      return {
        ...state,
        deleteProcessing: false,
        deletedRoleId: null,
        deletedRoleName: '',
        deleteSuccess: false,
        deleteError: null
      };
    }
    case fromActionTypes.DELETE_ORG_ROLE: {
      return {
        ...state,
        deleteProcessing: true
      };
    }
    case fromActionTypes.DELETE_ORG_ROLE_FULFILLED: {
      const {deletedRoleId, deletedRoleName} = action.payload;
      return {
        ...state,
        deleteProcessing: false,
        deleteSuccess: true,
        deletedRoleId,
        deletedRoleName,
        deleteError: null
      };
    }
    case fromActionTypes.DELETE_ORG_ROLE_REJECTED: {
      return {
        ...state,
        deleteProcessing: false,
        deleteSuccess: false,
        deleteError: action.payload.error
      };
    }
    case fromActionTypes.GET_REFERENCES_PROFILES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_REFERENCES_PROFILES_FULFILLED: {
      const {referencesProfiles} = action.payload;
      const thisOrgRole = {...state.orgRole};

      if (!thisOrgRole || !thisOrgRole.referenceProfile || !referencesProfiles) {
        return {
          ...state,
          loading: false
        }
      }

      thisOrgRole.referenceProfile.profile.generated.forEach(assessmentProfile => {
        const referenceProfileAssessmentResults = assessmentProfile.results;

        referenceProfileAssessmentResults.forEach(referenceProfileAssessmentResult => {
          //add new 'users' property to each dimension with name and result
          referenceProfileAssessmentResult.users = thisOrgRole.referenceProfile.references
            .filter(simpleReference => {
              const userAllAssessmentResults = referencesProfiles[simpleReference.userId];
              const userAssessment = userAllAssessmentResults.find(r => r.assessment === assessmentProfile.assessmentId);
              return userAssessment && userAssessment.result
            })
            .map(simpleReference => {
              const userAllAssessmentResults = referencesProfiles[simpleReference.userId];
              const userAssessment = userAllAssessmentResults.find(r => r.assessment === assessmentProfile.assessmentId);

              const userAssessmentResults = userAssessment.result.results;
              const value = userAssessmentResults.find(r => r.id === referenceProfileAssessmentResult.dimensionId);

              return {
                id: simpleReference.userId,
                name: simpleReference.userId,
                result: value.result
              }
            })
        })
      });

     return {
       ...state,
       orgRole: thisOrgRole,
       referencesProfiles,
       loading: false
     };
    }
    case fromActionTypes.GET_REFERENCES_PROFILES_REJECTED: {
      const {error} = action.payload;
      return {
        ...state,
        loading: false,
        orgRoleError: error
      };
    }
    case fromActionTypes.GET_EXPANDED_REFERENCES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.GET_EXPANDED_REFERENCES_FULFILLED: {
      const {expandedReferences} = action.payload;
      const thisOrgRole = {...state.orgRole};

      if (!thisOrgRole || !thisOrgRole.referenceProfile || !expandedReferences) {
        return {
          ...state,
          loading: false
        }
      }

      const mappedExpandedReferences = thisOrgRole.referenceProfile.references.map(reference => {
        const userInfo = expandedReferences[reference.userId];

        let name = (!userInfo.firstName && !userInfo.lastName) ? userInfo.mail : `${userInfo.firstName} ${userInfo.lastName}`;
        let isAnonymous = false;

        //if user has only 'PLATFORM_USER' that means that user is removed from current company
        //if user is removed instead of the name, 'Anonymous user' will be displayed
        if (userInfo.roles && userInfo.roles.length === 1 && userInfo.roles[0].role === PLATFORM_ROLES.PLATFORM_USER) {
          isAnonymous = true;
        }

        return {
          userId: reference.userId,
          name,
          isAnonymous,
          profession: userInfo.companyData ? userInfo.companyData.profession : ''
        }
      });

      thisOrgRole.referenceProfile.expandedReferences = mappedExpandedReferences;

      return {
        ...state,
        orgRole: thisOrgRole,
        expandedReferences: mappedExpandedReferences,
        loading: false
      };
    }
    case fromActionTypes.GET_EXPANDED_REFERENCES_REJECTED: {
      const {error} = action.payload;
      return {
        ...state,
        loading: false,
        orgRoleError: error
      };
    }
    default: {
      return state;
    }
  }
};
