// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useState} from 'react';
import styles from './CandidateCompleteProfile.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import DialogSequence from 'ui/molecules/DialogSequence';
import {useDispatch, useSelector} from 'react-redux';

// UTILS
import * as api from 'api';

// STORE
import {useHistory} from 'react-router';
import { logoutFulfilled } from 'store/actions';
// import * as action from 'store/actions';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromStaticValueActions from 'store/actions/staticValues';
import * as fromCurrentUserActions from 'store/actions/getCurrentUser';


// CONFIG & DATA
// const Config = {};

// COMPONENT: CandidateCompleteProfile
const CandidateCompleteProfile = () => {
  // PROPS

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const [ userId, setUserId ] = useState();
  // IMPORTANT: set this via state
  // otherwise some store updates will trigger an update for me.id
  // and rerender and reset DialogSequence
  useEffect(() => {
    if (me && me.id && me.id !== userId) {
      setUserId(me.id);
    }
  }, [me, userId]);

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fromStaticValueActions.getCountries());
    dispatch(fromStaticValueActions.getEducation());
    dispatch(fromStaticValueActions.getIndustries());
    dispatch(fromStaticValueActions.getCareerLevels());
    dispatch(fromStaticValueActions.getJobFamilies());
  }, [dispatch]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // HANDLERS
  const handleAnswer = (answer) => {
    api.get('core/user/heartbeat');
  }

  const handleAllAnswers = (answers, setFinishable, setErrorEnd) => {
    const payload = {
      firstName: answers[1].firstName,
      lastName: answers[1].lastName
    }

    const candidateData = {
      country: answers[2].country
    };

    // CITY
    if (answers[2].city) {
      candidateData['city'] = answers[2].city;
    }

    // NATIONALITY
    if (answers[3] && answers[3].isValid) {
      candidateData['nationality'] = [answers[3].value];
    }

    // GENDER
    if (answers[4] && answers[4].isValid) {
      candidateData['gender'] = answers[4].value;
    }

    // YEAR OF BIRTH
    if (answers[5] && answers[5].isValid) {
      candidateData['yearOfBirth'] = Number(answers[5].value);
    }

    // SCHOOL DEGREE
    if (answers[6] && answers[6].isValid) {
      candidateData['education'] = answers[6].value;
    }

    // CAREER LEVEL
    if (answers[7] && answers[7].isValid) {
      candidateData['careerLevel'] = answers[7].value;
    }

    // CURRENT POSITION
    if (answers[8] && answers[8].isValid) {
      candidateData['currentPosition'] = answers[8].value;
    }

    // CURRENT JOB FAMILY
    if (answers[9] && answers[9].isValid) {
      candidateData['currentJobFamily'] = answers[9].value;
    }

    // INDUSTRY EXPERIENCE
    if (answers[10] && answers[10].isValid) {
      candidateData['industryExperience'] = answers[10].value.map(answer => ({
        industry: answer.name,
        experience: answer.experience
      }));
    }

    // JOB REQUIREMENTS
    if (answers[11] && answers[11].isValid) {
      const selectedOptions = answers[11].value.selectedOptions;
      if (selectedOptions && selectedOptions.length > 0) {
        selectedOptions.forEach(selectedOption => {
          switch (selectedOption.name) {
            case 'partTime': {
              if (selectedOption.selected) {
                candidateData['desiredWeeklyHours'] = Number(selectedOption.value);
              }
              break;
            }
            case 'salarySpecific': {
              if (selectedOption.selected) {
                candidateData['desiredSalary'] = Number(selectedOption.value[0]);
                candidateData['currency'] = selectedOption.value[1];
              }
              break;
            }
            case 'remote': {
              if (selectedOption.selected) {
                candidateData['remotePreferred'] = selectedOption.value;
              }
              break;
            }
            default:
          }
        })
      }

      const customReqs = answers[11].value.customRequirements;
      if (customReqs && customReqs.length > 0) {
        candidateData['additionalRequirements'] = customReqs;
      }
    }

    // MOTIVATION
    if (answers[12] && answers[12].isValid) {
      const selectedOptions = answers[12].value.selectedOptions;
      if (selectedOptions && selectedOptions.length > 0) {
        candidateData['motivation'] = selectedOptions
          .filter(selectedOption => selectedOption.selected)
          .map(selectedOption => selectedOption.name);
      }

      const customAspects = answers[12].value.customAspects;
      if (customAspects && customAspects.length > 0) {
        if (candidateData.motivation && Array.isArray(candidateData.motivation)) {
          candidateData['motivation'] = [...candidateData.motivation, ...customAspects];
        } else {
          candidateData['motivation'] = customAspects;
        }
      }
    }

    // START DATE
    if (answers[13] && answers[13].isValid) {
      const value = answers[13].value;
      if (value.radioValue === 'now') {
        candidateData['earliestStart'] = 'now';
      } else if (value.dateValue) {
        candidateData['earliestStart'] = value.dateValue;
      }
    }

    payload.candidateData = candidateData;

    api.post('core/user/update', payload)
      .then(({ok, status}) =>{
        if (ok && status === 200) {
          dispatch(fromCurrentUserActions.getCurrentUser());
          // dispatch(action.getUserProfilePicture());
          setFinishable(true);
        }
      })
      .catch(error => {
        console.error(error.message);
        setErrorEnd(error.message);
      })
  }

  const handleFinish = () => {
    history.push('/');
  };

  const handleCancel = () => {
    localStorage.removeItem('token');
    dispatch(logoutFulfilled());
    window.location.href = '/logged-out';
  };

  // RENDER: CandidateCompleteProfile
  return (
    <div className={classNames(styles.candidateCompleteProfile)}>
      <DialogSequence
        type={'candidateProfileCompletion'}
        userId={userId}
        configOverride={{}}

        showLanguageToggle={true}

        onAnswer={handleAnswer}
        onAllAnswers={handleAllAnswers}
        onFinish={handleFinish}
        onCancel={handleCancel}
        onError={() => {}}
      />
    </div>
  );
};

export default CandidateCompleteProfile;
