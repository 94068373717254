import React, {memo, useCallback, useContext, useEffect, useRef, useState} from 'react';
import styles from './WorkPreferencesResult.module.scss';

import WorkPreferencesResultItem from './WorkPreferencesResultItem';
import WorkPreferencesResultSkeleton from './WorkPreferencesResultSkeleton';
import {InfoTitle, Modal} from 'ui/basic';

import {LayoutContext} from 'features/framework/components/MainLayout/MainLayout';

// ASSETS
import {IconsSvg} from 'assets/icons';

// UTILS
import { useTranslate } from 'utils/translator';
import {useBreakpoint} from 'utils/hooks';

const DEFAULT_INFO_TITLE = 'Work Preferences';
const DEFAULT_INFO_DESCRIPTION =
  'In addition to personality, individual preferences play a particularly important role' +
  'in the question of what drives people in their thoughts and actions.' +
  'In a professional context, preferences influence our motivation to pursue certain goals' +
  'and make decisions. If our everyday working life matches our professional preferences,' +
  'we tend to feel better and are more motivated.';


const WorkPreferencesResult = memo((props) => {
  const {results, noDataText, footerText, showExportIcon, exportView, onExport = () => {}} = props;

  const translate = useTranslate();
  const ref = useRef();
  const bp = useBreakpoint();

  const DownloadIcon = IconsSvg.DownloadSvg;

  const [infoPageIsVisible, setInfoPageIsVisible] = useState();
  const [width, setWidth] = useState();

  const { mainNavIsOpenDebounced } = useContext(LayoutContext) || {};

  const getWidth = useCallback(() => {
    if (bp.isXs) {
      if (!ref.current) {
        return 256;
      }
      return document.body.clientWidth - 80;
    }

    if (bp.isS) {
      if (!ref.current) {
        return 277;
      }
      return ref.current.clientWidth - 32;
    }

    if (bp.isM || bp.isL || bp.isXl) {
      if (!ref.current) {
        return 648;
      }

      return ref.current.clientWidth - 64;
    }

    return 256;
  }, [bp]);

  const handleResize = useCallback(() => {
    setWidth(getWidth());
  }, [getWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 500)
  }, [results, mainNavIsOpenDebounced, handleResize]);

  if (!results) {
    return (
      <div className={styles.workPreferencesResult}>
        <WorkPreferencesResultSkeleton/>
      </div>
    );
  }

  return (
    <div className={styles.workPreferencesResult}>
      <div className={styles.container} ref={ref}>
        <div className={styles.mainHeader}>
          {exportView ?
            <span className='blu-typeLabel'>{translate('workpreferences_results_title') || 'Work Preferences'}</span> :
            <InfoTitle
              title={translate('workpreferences_results_title') || 'Work Preferences'}
              onClick={() => setInfoPageIsVisible(true)}
            />
          }

          {(!bp.isXs && !exportView && showExportIcon) &&
          <DownloadIcon onClick={onExport}/>
          }
        </div>

        {noDataText && <div className={styles.noDataText}>{noDataText}</div>}

        {!noDataText &&
        <>
          <div className={styles.rows}>
            {results.map((result, index) => {
              return (
                <WorkPreferencesResultItem
                  key={`dimension-${index}`}
                  index={index + 1}
                  width={width}
                  score={result.result}
                  range={result.range}
                  dimension={result.name}
                />
              )
            })}
          </div>

          {footerText && <div className={styles.footerText}>{footerText}</div>}
        </>
        }
      </div>

      {infoPageIsVisible &&
        <Modal
          header={translate('workpreferences_results_info_title') || DEFAULT_INFO_TITLE}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          onConfirm={() => setInfoPageIsVisible(false)}
          onClose={() => setInfoPageIsVisible(false)}
        >
          {translate('workpreferences_results_info_description') || DEFAULT_INFO_DESCRIPTION}
        </Modal>
      }
    </div>
  )
});

export default WorkPreferencesResult;
