import {timestampToFullDate} from 'utils/dateTools';
import {isValid} from 'utils/numbers';
import {translate} from 'utils/translator/translator';
import {CAREER_LEVELS_BY_ID} from 'utils/configuration/const/career-level';

export const getMappedVacancyData = (vacancy) => {
  const metaData = vacancy.metaData;

  let location = [];
  if (metaData.locationCountry) {
    location.push(translate(`config_country_${metaData.locationCountry}`));
  }
  if (metaData.locationCity) {
    location.push(metaData.locationCity);
  }
  if (metaData.remote) {
    const remoteValue = `${translate('create_vacancy_remote_option')} ${metaData.remote}%`;
    location.push(remoteValue);
  }

  let salaryRange = [];
  if (isValid(metaData.salaryFrom)) {
    salaryRange.push(metaData.salaryFrom);
  }
  if (isValid(metaData.salaryTo)) {
    salaryRange.push(metaData.salaryTo);
  }

  let utilization;
  if (metaData.utilization) {
    utilization = metaData.utilization === 100 ?
      `${translate('full_time_lbl') || 'Vollzeit'} (${metaData.utilization}%)` :
      `${translate('part_time_lbl') || 'Teilzeit'} (${metaData.utilization}%)`;
  }

  let roleCareerLevel;
  if (vacancy.role && vacancy.role.careerLevel) {
    const careerLevelObj = CAREER_LEVELS_BY_ID[vacancy.role.careerLevel];
    if (careerLevelObj) {
      roleCareerLevel = translate(`${careerLevelObj.translationKey}`) || careerLevelObj.translationFallback;
    }
  }

  return {
    ...vacancy,
    candidates: vacancy.candidates && Array.isArray(vacancy.candidates) ?
      vacancy.candidates.map(candidate => ({
        user: typeof candidate === 'string' ? {id: candidate} : candidate
      })) : [],
    metaData: {
      ...metaData,
      employmentTypeValue: metaData.employmentType,
      employmentType: translate(metaData.employmentType) || metaData.employmentType,
      startDateString: metaData.startDate && timestampToFullDate(metaData.startDate),
      location: location.length > 1 ? location.join(' / ') : location[0],
      salaryRange: salaryRange.length > 1 ?
        (`${salaryRange.join(' - ')} ${metaData.currency || ''}`) :
        salaryRange[0],
      utilizationString: utilization,
      roleCareerLevel
    }
  }
}
