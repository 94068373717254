import { createSelector } from '@reduxjs/toolkit';

export const selectCandidate = (state) => {
  return state.candidate;
};

export const selectCandidateBaseInfo = (state) => {
  return state.candidate.baseInfo;
};

export const selectCandidateSummary = (state) => {
  const candidate = selectCandidate(state);
  return candidate.summary;
};

export const selectCandidateAssessments = (state) => {
  const candidate = selectCandidate(state);
  return candidate.assessments;
};


export const selectCandidateHasRoleFitPage = createSelector(
  selectCandidateBaseInfo,
  (baseInfo) => {
    const { jobs = [] } = baseInfo;

    let hasCompletedJobWithRole = false;
    jobs.forEach(job => {
      if (!job.incomplete && job.roleId) {
        hasCompletedJobWithRole = true;
      }
    })

    return !baseInfo.disabled && hasCompletedJobWithRole
      ? true : false;
  }
)
