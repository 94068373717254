import React, { useEffect, useRef, useState } from 'react';
import styles from './AssessmentTeaserNext.module.scss';

import classNames from 'classnames';

import Big5Image from 'assets/assessments/big5.svg';
import PotentialImage from 'assets/assessments/potential.svg';
import WorkPreferenceImage from 'assets/assessments/work-preference.svg';
import KeyCompetenciesImage from 'assets/assessments/key-competencies.svg';
import LeadershipCompetenciesImage from 'assets/assessments/leadership-competencies.svg';
import RmpImage from 'assets/assessments/rmp.svg';
import NineLevelsImage from 'assets/assessments/9levels.svg';
import CustomSkillsImage from 'assets/assessments/custom-skills.svg';
import BalancedYouImage from 'assets/assessments/balanced-you.svg';
import GarminImage from 'assets/balanced-you/garmin.svg';

import { getTranslationIds } from './translationIds';

import { useTranslate } from 'utils/translator';
import { useDebounce, useWindowWidth } from 'utils/hooks';
import { getImageHeight } from 'utils/imageSize';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

import { Button, Card, Modal } from 'ui/basic';


const AssessmentTeaserNext = (props) => {
  const {
    assessmentType = ASSESSMENT_TYPES.BIG5,
    title,
    description,
    scope,
    details,
    fullWidth,
    forStart = true, forContinue, forImport, forConnect,
    onClick,
    onModal, // passes learn more modal to this function on button click
    onModalClose,
    onImport,
    onLearnMore
  } = props;

  const translate = useTranslate();
  const ref = useRef();

  // STATE
  const [ showModal, setShowModal ] = useState(false);

  const translationIds = getTranslationIds(assessmentType);

  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);

  const [ imageHeight, setImageHeight ] = useState();
  useEffect(() => {
    if (ref && ref.current) {
      setImageHeight(getImageHeight(ref.current.clientWidth));
    }
  }, [debouncedWindowWidth]);

  const getImage = () => {
    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return Big5Image;
      case ASSESSMENT_TYPES.POTENTIAL:
        return PotentialImage;
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return WorkPreferenceImage;
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return KeyCompetenciesImage;
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return LeadershipCompetenciesImage;
      case ASSESSMENT_TYPES.RMP:
        return RmpImage;
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return NineLevelsImage;
      case ASSESSMENT_TYPES.BALANCED_YOU:
        return BalancedYouImage;
      case 'garmin':
        return GarminImage;
      default:
        return CustomSkillsImage;
    }
  };

  // HANDLERS
  const handleLearnMore = () => {
    if (onLearnMore) {
      onLearnMore();
      return;
    }

    if (onModal) {
      onModal(renderModal());
    } else {
      setShowModal(true)
    }
  }

  const renderModal = () => (
    <Modal
      header={translate(translationIds.infoTitle) || title}
      primaryButtonTitle={(forStart && !forContinue)
        ? (translate('assessment_reminder_md_start_btn'))
        : (translate('resume_assessment_lbl'))
      }
      secondaryButtonTitle={translate('close_lbl')}
      onConfirm={onClick}
      onClose={() => {
        setShowModal(false)
        if (onModalClose) {
          onModalClose();
        }
      }}
    >
      { translate(translationIds.infoDetails) || details }
    </Modal>
  );

  return (
    <div className={classNames(
      styles.assessmentTeaser, {[styles.fullWidth]: fullWidth}
    )}>
      <Card hasNoPaddings>
        <div className={styles.content}>
          {/*IMAGE*/}
          <div className={classNames(styles.imageBlock, styles[assessmentType])} ref={ref}>
            <img
              alt={'teaser'}
              height={assessmentType === ASSESSMENT_TYPES.MINDFULNESS_JOURNEY ? imageHeight : '100%'}
              src={getImage()}
            />
          </div>

          {/*TEXT CONTENT*/}
          <div className={styles.textBlock}>
            <div className={styles.xxsTitle}>
              { translate(translationIds.title) || title }
            </div>
            <div className={styles.copy}>
              { translate(translationIds.description) || description }
            </div>

            <div className={styles.buttons}>
              { (forStart && !forContinue) && (
                <Button
                  size={'S'}
                  onClick={onClick}
                >
                  { translate('assessment_reminder_md_start_btn') || 'Starten' }
                </Button>
              )}

              { forConnect && (
                <Button
                  size={'S'}
                  onClick={onClick}
                >
                  { translate('connect_lbl') }
                </Button>
              )}

              { forContinue && (
                <Button
                  size={'S'}
                  onClick={onClick}
                >
                  { translate('resume_assessment_lbl') || 'Fortsetzen' }
                </Button>
              )}

              <Button
                size={'S'}
                looks={'secondary'}
                onClick={handleLearnMore}
              >
                { translate('big5_results_button_learnmore_dim1') || 'Mehr erfahren' }
              </Button>

              { forImport && (
                <Button
                  size={'S'}
                  looks={'tertiary'}
                  onClick={onImport}
                >
                  { translate('profile_import_lbl') || 'Profil importieren' }
                </Button>
              )}
            </div>

            {/*SCOPE DETAILS*/}
            { !forContinue && (
              <div className={styles.label}>
                { translate(translationIds.scope) || scope }
              </div>
            )}

          </div>
        </div>
      </Card>

      {showModal && (
        renderModal()
      )}

    </div>
  );
};

export default AssessmentTeaserNext;
