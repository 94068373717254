import ARROW_ANCHOR from './icn_arrow_anchor.svg';
import ARROW_DOWN from './icn_arrow_down.svg';
import ARROW_LEFT from './icn_arrow_left.svg';
import ARROW_RIGHT from './icn_arrow_right.svg';
import BURGER from './icn_burger.svg';
import CHECK_SMALL from './icn_check_small.svg';
import CLOSE_BIG from './icn_close_big.svg';
import CURSOR_UP from './icn_cursor_up.svg';
import CURSOR_DOWN from './icn_cursor_down.svg';
import INFO from './icn_info.svg';
import INFO_BLUE from './icn_info_blue.svg';
import MENU_CLOSE from './icn_menu_close.svg';
import PLAY from './icn_play.svg';
import PLUS from './plus.svg';
import SEARCH_BIG from './icn_search_big.svg';
import VISIBILITY_ON from './icn_vis_on.svg';
import VISIBILITY_OFF from './icn_vis_off.svg';
import BQ_CONTACT from './icn_bq_contact.svg';
import CHAIN from './icn_chain.svg';
import OPTIONS from './icn_options.svg';
import DOWNLOAD from './icn_download.svg';
import DOCUMENT from './icn_doc.svg';
import EXTERNAL_LINK from './icn_external_link.svg';

import { ReactComponent as ARROW_ANCHOR_SVG } from './icn_arrow_anchor.svg';
import { ReactComponent as ARROW_SVG } from './icn_arrow.svg';
import { ReactComponent as ARROW_DOWN_SVG } from './icn_arrow_down.svg';
import { ReactComponent as ARROW_UP_SVG } from './icn_arrow_up.svg';
import { ReactComponent as ARROW_LEFT_SVG } from './icn_arrow_left.svg';
import { ReactComponent as ARROW_RIGHT_SVG } from './icn_arrow_right.svg';
import { ReactComponent as BURGER_SVG } from './icn_burger.svg';
import { ReactComponent as BQ_CONTACT_SVG } from './icn_bq_contact.svg';
import { ReactComponent as CHECK_BIG_SVG } from './icn_check_big.svg';
import { ReactComponent as CHECK_SMALL_SVG } from './icn_check_small.svg';
import { ReactComponent as CLOSE_BIG_SVG } from './icn_close_big.svg';
import { ReactComponent as CURSOR_UP_SVG } from './icn_cursor_up.svg';
import { ReactComponent as CURSOR_DOWN_SVG } from './icn_cursor_down.svg';
import { ReactComponent as INFO_SVG } from './icn_info.svg';
import { ReactComponent as INFO_BLUE_SVG } from './icn_info_blue.svg';
import { ReactComponent as MENU_CLOSE_SVG } from './icn_menu_close.svg';
import { ReactComponent as PLAY_SVG } from './icn_play.svg';
import { ReactComponent as PLUS_SVG } from './plus.svg';
import { ReactComponent as SEARCH_BIG_SVG } from './icn_search_big.svg';
import { ReactComponent as VISIBILITY_ON_SVG } from './icn_vis_on.svg';
import { ReactComponent as VISIBILITY_OFF_SVG } from './icn_vis_off.svg';
import { ReactComponent as BLOCK_SVG } from './icn_block.svg';
import { ReactComponent as WELL_BEING_SVG } from './icn_wbi.svg';
import { ReactComponent as CHAIN_SVG } from './icn_chain.svg';
import { ReactComponent as OPTIONS_SVG } from './icn_options.svg';
import { ReactComponent as TRASH_SVG } from './icn_trash.svg';
import { ReactComponent as PEOPLE_SEARCH_SVG } from './people_search.svg';
import { ReactComponent as DOWNLOAD_SVG } from './icn_download.svg';
import { ReactComponent as DOCUMENT_SVG } from './icn_doc.svg';
import { ReactComponent as EXTERNAL_LINK_SVG } from './icn_external_link.svg';
import { ReactComponent as UPLOAD_SVG } from './icn_upload.svg';
import { ReactComponent as EDIT_SVG } from './icn_edit.svg';

export const Icons = {
  ArrowAnchor: ARROW_ANCHOR,
  ArrowDown: ARROW_DOWN,
  ArrowLeft: ARROW_LEFT,
  ArrowRight: ARROW_RIGHT,
  Burger: BURGER,
  CloseBig: CLOSE_BIG,
  CheckSmall: CHECK_SMALL,
  CursorUp: CURSOR_UP,
  CursorDown: CURSOR_DOWN,
  Info: INFO,
  InfoBlue: INFO_BLUE,
  MenuClose: MENU_CLOSE,
  Play: PLAY,
  Plus: PLUS,
  SearchBig: SEARCH_BIG,
  VisibilityOn: VISIBILITY_ON,
  VisibilityOff: VISIBILITY_OFF,
  BqContact: BQ_CONTACT,
  Chain: CHAIN,
  Options: OPTIONS,
  Download: DOWNLOAD,
  Document: DOCUMENT,
  ExternalLink: EXTERNAL_LINK
};

export const IconsSvg = {
  ArrowAnchor: ARROW_ANCHOR_SVG,
  Arrow: ARROW_SVG,
  ArrowDown: ARROW_DOWN_SVG,
  ArrowUp: ARROW_UP_SVG,
  ArrowLeft: ARROW_LEFT_SVG,
  ArrowRight: ARROW_RIGHT_SVG,
  Burger: BURGER_SVG,
  BqContact: BQ_CONTACT_SVG,
  CloseBig: CLOSE_BIG_SVG,
  CheckBig: CHECK_BIG_SVG,
  CheckSmall: CHECK_SMALL_SVG,
  CursorUp: CURSOR_UP_SVG,
  CursorDown: CURSOR_DOWN_SVG,
  Info: INFO_SVG,
  InfoBlue: INFO_BLUE_SVG,
  MenuClose: MENU_CLOSE_SVG,
  Play: PLAY_SVG,
  Plus: PLUS_SVG,
  SearchBig: SEARCH_BIG_SVG,
  VisibilityOn: VISIBILITY_ON_SVG,
  VisibilityOff: VISIBILITY_OFF_SVG,
  Block: BLOCK_SVG,
  WellBeing: WELL_BEING_SVG,
  Chain: CHAIN_SVG,
  Options: OPTIONS_SVG,
  Trash: TRASH_SVG,
  PeopleSearch: PEOPLE_SEARCH_SVG,
  DownloadSvg: DOWNLOAD_SVG,
  Document: DOCUMENT_SVG,
  ExternalLink: EXTERNAL_LINK_SVG,
  Upload: UPLOAD_SVG,
  Edit: EDIT_SVG
};

