import React, {memo, useEffect, useState} from 'react';
import RadioButton from '../RadioButton';


const RadioButtonGroup = memo((props) => {
  const {selectedValue, items, deselectable, onChange = () => {}} = props;

  const [selectedValueInternal, setSelectedValueInternal] = useState(selectedValue);

  const componentDidMount = () => {
    setSelectedValueInternal(selectedValue);
  };

  useEffect(componentDidMount, []);

  useEffect(() => {
    setSelectedValueInternal(selectedValue);
  }, [selectedValue]);

  return (
    items.map((item, index) => {
      return (
        <RadioButton
          key={`radio-button-${index}`}
          title={item.label}
          value={item.value}
          checked={selectedValueInternal === item.value}
          deselectable={deselectable}
          onChange={(value) => {
            setSelectedValueInternal(value);
            onChange(value);
          }}
        />

      )
    })
  );
});

export default RadioButtonGroup;
