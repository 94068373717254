import {PLATFORM_ROLES} from 'utils/configuration/const/roles';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from './configuration';
import {isValid} from './numbers';
import {ASSESSMENT_TYPES} from './configuration/const/assessment-types';

const ALLOWED_PLATFORM_ROLES = [
  PLATFORM_ROLES.COMPANY_USER,
  PLATFORM_ROLES.CANDIDATE,
  PLATFORM_ROLES.COMPANY_LEADER_CUSTOM,
  PLATFORM_ROLES.COMPANY_ADMIN
];

const BALANCED_YOU_ALLOWED_PLATFORM_ROLES = [
  PLATFORM_ROLES.COMPANY_USER,
  PLATFORM_ROLES.COMPANY_ADMIN
];

export const getAssessmentRange = (assessmentId, minValue, maxValue) => {
  const ASSESSMENT_MIN_VALUES = {
    [ASSESSMENT_TYPES.BIG5]: 1,
    [ASSESSMENT_TYPES.POTENTIAL]: 1,
    [ASSESSMENT_TYPES.WORK_PREFERENCES]: -5,
    [ASSESSMENT_TYPES.RMP]: -2,
    [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 1,
    [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 1
  };

  const ASSESSMENT_MAX_VALUES = {
    [ASSESSMENT_TYPES.BIG5]: 1,
    [ASSESSMENT_TYPES.POTENTIAL]: 10,
    [ASSESSMENT_TYPES.WORK_PREFERENCES]: 5,
    [ASSESSMENT_TYPES.RMP]: 2,
    [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 5,
    [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 5
  };

  let minValueInternal;
  let maxValueInternal;

  if (isValid(minValue) && isValid(maxValue)) {
    minValueInternal = minValue;
    maxValueInternal = maxValue;
  }

  if (!isValid(minValue) && isValid(maxValue)) {
    minValueInternal = ASSESSMENT_MIN_VALUES[assessmentId];
    maxValueInternal = maxValue;
  }

  if (isValid(minValue) && !isValid(maxValue)) {
    minValueInternal = minValue;
    maxValueInternal = ASSESSMENT_MAX_VALUES[assessmentId];
  }

  // if min value is equal to max value -> subtract 0.1 so that the range is shown on diagram
  if (minValueInternal === maxValueInternal) {
    minValueInternal -= 0.1;
  }

  return [minValueInternal, maxValueInternal];
};

export const getFilteredRoles = (allRoles) => {
  const allMappedRoles = allRoles.map(r => r.role);
  const allowedRoles = CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ? BALANCED_YOU_ALLOWED_PLATFORM_ROLES : ALLOWED_PLATFORM_ROLES;

  if (allRoles.length === 0) {
    return allRoles;
  }

  return allowedRoles.map(role => {
    const basicRole = role.split('_CUSTOM')[0];

    if (allMappedRoles.includes(role)) {
      return role;
    } else {
      //if no custom role found, basic role will be added to array
      return basicRole;
    }
  });
}
