// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
import styles from './Maintenance.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { IS_MAINTENANCE_MODE } from 'utils/configuration';


// COMPONENT: Maintenance
const Maintenance = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const history = useHistory();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // Return to home once app is no longer on maintenance
  useEffect(() => {
    if (!IS_MAINTENANCE_MODE) {
      history.push('/');
    }
  }, [ history ]);

  // RENDER: Maintenance
  return (
    <div className={styles.maintenance}>
      <div className={styles.container}>
        <div className={styles.coffee}>
          <span role='img' aria-label='emoji'>☕</span>
        </div>
        <div className={styles.title}>
          { translate('maintenance_title') } 
        </div>
        <div>
          { translate('maintenance_description') }
        </div>
        <div className={styles.button}>
          <Button
            leadingIcon={IconsSvg.ExternalLink}
            size={'S'}
            looks={'secondary'}
            onClick={() => { window.location.href = 'https://bluquist.com'; }}
          >
            { translate('maintenance_btn') }
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
