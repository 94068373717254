import React, {useEffect, useState} from 'react';
import styles from './Settings.module.scss';

// ASSETS
import {ReactComponent as Checkmark} from 'assets/icons/icn_check_big.svg';

// 3RD PARTY
import classNames from 'classnames';
import {useHistory} from 'react-router';

// STORE
import {useDispatch, useSelector} from 'react-redux';
import {
  logoutFulfilled, updateUserSettings,
  resetUpdateCurrentUser, updateCurrentUser, initSignUp, getCompanySettings
} from 'store/actions';
import * as fromSettingsSelectors from 'store/selectors/settings';
import { selectCompanyHasExternalLegalAgreements } from 'store/selectors/configuration';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

// OTHER COMPONENTS
import {TextArea} from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { translate } from 'utils/translator/translator';
import { handleRipple } from 'utils/ripple';
import { matchPasswords } from 'utils/validator';
import { CONFIGURATION, CONFIGURATION_OPTIONS } from 'utils/configuration';
import { scrollIntoView } from 'utils/scrolling';

import {Toggle, Toast, Button, InputPassword, Link, Checkbox, Card, Modal} from 'ui/basic';

const DEFAULT_DESCRIPTION = ' Passe hier die allgemeinen Einstellungen der Anwendung an.';
const DEFAULT_NOTIFICATIONS_DESCRIPTION =
  'Wähle hier aus, welche Benachrichtigungen du per Email erhalten möchtest.';
const DEFAULT_NEWS_DESCRIPTION =
  '  Werde benachrichtigt wenn neue Funktionen & Inhalte in bluquist zur Verfügung stehen';
const DEFAULT_CHANGEPASSW_DESCRIPTION =
  'Falls du dein Passwort ändern möchtest, kannst du das hier tun. Wir empfehlen dir dein Passwort regelmäßig zu ändern um maximale Datensicherheit zu gewährleisten.';
const DEFAULT_SUPPORT_DESCRIPTION =
  'Optional description to offer additional guidance on what users can expect from this setting Teaser.';

const SETTINGS = [
  {id: 'languages', label: 'gen_settings_language', altLabel: 'Sprache'},
  {id: 'notifications', label: 'gen_settings_notifications', altLabel: 'Benachrichtigungen'},
  {id: 'change-password', label: 'gen_settings_change_password', altLabel: 'Neues Passwort wählen'},
  {id: 'delete-profile', label: 'gen_settings_delete_profile', altLabel: 'Nutzerprofil löschen'}
];

const LANGUAGES = [
  {id: 'de_DE', name: translate('langswitch_popover_menu_item_1') || 'Deutsch'},
  {id: 'en_US', name: translate('langswitch_popover_menu_item_2') || 'English'}
];


const Settings = () => {

  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  // change password
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordIsCorrect, setNewPasswordIsCorrect] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const currentLanguage = useSelector(
    state => state.localisation && state.localisation.currentLanguage
  );

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const passwordIsUpdated = useSelector(state => state.currentUser && !!state.currentUser.success);
  const userSettings = useSelector(fromSettingsSelectors.getUserSettings);
  const userSettingsError = useSelector(fromSettingsSelectors.getUserSettingsError);
  const updateUserSettingsProcessing = useSelector(fromSettingsSelectors.getUpdateUserSettingsProcessing);

  // delete profile
  const [deleteMyProfileProcessing, setDeleteMyProfileProcessing] = useState();
  const [deleteMyProfileErrorMessage, setDeleteMyProfileErrorMessage] = useState();
  const [deleteMyProfileModal1Visible, setDeleteMyProfileModal1Visible] = useState();
  const [deleteMyProfileModal2Visible, setDeleteMyProfileModal2Visible] = useState();
  const [deleteMyProfileFeedback, setDeleteMyProfileFeedback] = useState();
  const [deleteMyProfilePassword, setDeleteMyProfilePassword] = useState();

  const companyHasExternalLegalAgreements = useSelector(selectCompanyHasExternalLegalAgreements);

  const companySettings = useSelector(fromSettingsSelectors.getCompanySettings);
  useEffect(() => {
    if (!companySettings || Object.keys(companySettings).length === 0) {
      dispatch(getCompanySettings());
    }
  }, [dispatch, companySettings]);

  useEffect(() => {
    setOldPassword('');
    setNewPassword('');
    setConfirmedPassword('');
    setShowSuccessMessage(passwordIsUpdated);
  }, [passwordIsUpdated]);

  useEffect(() => {
    dispatch(resetUpdateCurrentUser());
  }, [dispatch]);

  // HANDLES
  const handleHelpClick = () => {
    const helpLink = currentLanguage === 'de_DE' ? 'https://help.bluquist.com/' : 'https://help.bluquist.com/en/';
    window.open(helpLink, '_blank').focus();
  };

  const handleSupportClick = () => history.push('/support');

  // RENDER Content (settings anchors)
  const renderContent = () => {
    const settings = [ ...SETTINGS ];
    if (companyHasExternalLegalAgreements) {
      // Remove delete profile anchor
      const idx = settings.findIndex((el) => el.id === 'delete-profile');
      settings.splice(idx, 1);
    }

    return settings.map((s, index) => (
      <Link
        key={`settings-${index}`}
        type={'anchor'}
        onClick={() => scrollIntoView(s.id)}
      >
        {translate(s.label) || s.altLabel}
      </Link>
    ))
  };

  // RENDER Settings
  return (
    <div className={styles.settings}>
      <div className={styles.main}>
        <div className={styles.title}>
          {translate('gen_settings_title') || 'Allgemeine Einstellungen'}
        </div>
        <div className={styles.description}>
          {translate('gen_settings_description') || DEFAULT_DESCRIPTION}
        </div>
        <div className={styles.content}>
          { renderContent() }
        </div>

        <div className={styles.languages} id={'languages'}>
          <div className={styles.title}>
            {translate('gen_settings_choose_language') || 'Sprache wählen'}
          </div>

          <div className={styles.rows}>
            {LANGUAGES.map((language, index) => {
              return (
                <div
                  key={`language-${index}`}
                  className={classNames(styles.language, {[styles.active]: currentLanguage === language.id})}
                  onClick={(event) => {
                    handleRipple(event, styles.colorPrimary3);
                    setTimeout(() => {
                      dispatch(updateUserSettings({
                        language: language.id,
                        assessmentReminderMails: userSettings.assessmentReminderMails,
                        newFeatureMails: userSettings.newFeatureMails,
                        wellbeingMails: userSettings.wellbeingMails,
                        wellbeingOnlyWeekdays: userSettings.wellbeingOnlyWeekdays,
                        wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport
                      }));
                    })
                  }}
                >
                  <span>{language.name}</span>
                  {currentLanguage === language.id && <Checkmark/>}
                </div>
              )
            })}
          </div>
        </div>

        <div className={styles.notifications} id={'notifications'}>
          <div className={styles.title}>
            {translate('gen_settings_notifications') || 'Benachrichtigungen'}
          </div>
          <div className={styles.description}>
            {translate('gen_settings_notifications_descr') || DEFAULT_NOTIFICATIONS_DESCRIPTION}
          </div>

          {userSettingsError &&
          <div className={styles.error}>
            {userSettingsError.errorMessage}
          </div>
          }

          <div className={styles.rows}>
            <div className={styles.row}>
              <div className={styles.row1}>
                <span className='blu-typeLabel'>{translate('notification_settings__assessments_title') || 'Assessments'}</span>
                <Toggle
                  id={'toggle1'}
                  //company settings have higher priority.
                  // If setting is deactivated for company, it should also be shown as deactivated for user settings
                  checked={(companySettings && !companySettings.assessmentReminderMails) ? false : userSettings.assessmentReminderMails}
                  disabled={(companySettings && !companySettings.assessmentReminderMails) || updateUserSettingsProcessing}
                  onChange={value => {
                    dispatch(updateUserSettings({
                      assessmentReminderMails: value,
                      language: userSettings.language,
                      newFeatureMails: userSettings.newFeatureMails,
                      wellbeingMails: userSettings.wellbeingMails,
                      wellbeingOnlyWeekdays: userSettings.wellbeingOnlyWeekdays,
                      wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport
                    }));
                  }}
                />
              </div>
              <div className={styles.row2}>
                {translate('notification_settings__assessments_descr') ||
                  'Erhalte Benachrichtigungen  über verfügbare Assessments'}
              </div>
            </div>

            {me.canAccessWellBeing &&
            <div className={styles.row}>
              <div className={styles.row1}>
                <span className='blu-typeLabel'>
                  {translate('notification_settings__wellbeing_title') || 'Tägliche Wohlbefindenmessung'}
                </span>
                <Toggle
                  id={'toggle2'}
                  checked={(companySettings && !companySettings.wellbeingMails) ? false : userSettings.wellbeingMails}
                  disabled={(companySettings && !companySettings.wellbeingMails) || updateUserSettingsProcessing}
                  onChange={value => {
                    dispatch(updateUserSettings({
                      wellbeingMails: value,
                      assessmentReminderMails: userSettings.assessmentReminderMails,
                      language: userSettings.language,
                      newFeatureMails: userSettings.newFeatureMails,
                      wellbeingOnlyWeekdays: userSettings.wellbeingOnlyWeekdays,
                      wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport
                    }));
                  }}
                />
              </div>
              <div className={styles.row2}>
                {translate('notification_settings__wellbeing_descr') ||
                'Erhalte eine tägliche E-Mail zur Wohlbefindenmessung (One-Click-Messung)'}
              </div>
              <Checkbox
                name={translate('notification_settings__wellbeing_checkbox') || 'Nur an Wochentagen'}
                checked={!!userSettings.wellbeingOnlyWeekdays}
                disabled={(companySettings && !companySettings.wellbeingMails) || updateUserSettingsProcessing}
                onChange={value => {
                  dispatch(updateUserSettings({
                    wellbeingOnlyWeekdays: value,
                    assessmentReminderMails: userSettings.assessmentReminderMails,
                    language: userSettings.language,
                    newFeatureMails: userSettings.newFeatureMails,
                    wellbeingMails: userSettings.wellbeingMails,
                    wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport
                  }));
                }}
              />
            </div>
            }

            <div className={styles.row}>
              <div className={styles.row1}>
                <span className='blu-typeLabel'>{translate('gen_settings_notifications_new_functions') || 'Neue Funktionen & Inhalte'}</span>
                <Toggle
                  id={'toggle3'}
                  checked={(companySettings && companySettings.newFeatureMails === false) ? false : userSettings.newFeatureMails}
                  disabled={(companySettings && companySettings.newFeatureMails === false) || updateUserSettingsProcessing}
                  onChange={value => {
                    dispatch(updateUserSettings({
                      newFeatureMails: value,
                      assessmentReminderMails: userSettings.assessmentReminderMails,
                      language: userSettings.language,
                      wellbeingOnlyWeekdays: userSettings.wellbeingOnlyWeekdays,
                      wellbeingMails: userSettings.wellbeingMails,
                      wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport
                    }));
                  }}
                />
              </div>
              <div className={styles.row2}>
                {translate('gen_settings_notifications_new_functions_descr') || DEFAULT_NEWS_DESCRIPTION}
              </div>
            </div>
          </div>
        </div>

        {/*CHANGE PASSWORD*/}
        <form className={styles.changePassword} id={'change-password'}
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(updateCurrentUser({
              password: newPassword,
              passwordOld: oldPassword
            }));
          }}>
          <div className={styles.title}>
            {translate('gen_settings_change_password') || 'Neues Passwort wählen'}
          </div>
          <div className={styles.description}>
            {translate('gen_settings_change_password_desc') || DEFAULT_CHANGEPASSW_DESCRIPTION}
          </div>
          <div className={styles.inputs}>
            <div className={styles.input}>
              <InputPassword
                placeHolder={translate('gen_settings_change_password_input1_placeholder') || 'Aktuelles Passwort'}
                value={oldPassword}
                onChange={(element, value) => setOldPassword(value)}
              />
            </div>
            <div className={styles.input}>
              <InputPassword
                placeHolder={translate('gen_settings_change_password_input2_placeholder') || 'Neues Passwort'}
                value={newPassword}
                showCriteria={true}
                onInputChanged={(value, correct) => {
                  setNewPassword(value);
                  setNewPasswordIsCorrect(correct);
                }}
              />
            </div>
            <div className={styles.input}>
              <InputPassword
                placeHolder={translate('gen_settings_change_password_input3_placeholder') || 'Neues Passwort wiederholen'}
                value={confirmedPassword}
                onInputChanged={(value, correct) => {
                  setConfirmedPassword(value);
                }}
                hint={matchPasswords(newPassword, confirmedPassword) || translate('signup_form_password_error_hint_notmatching')}
              />
            </div>
          </div>
          <Button
            size={'S'}
            type='submit'
            disabled={!oldPassword || !newPasswordIsCorrect || !matchPasswords(newPassword, confirmedPassword)}
            onClick={() => {
              dispatch(updateCurrentUser({
                password: newPassword,
                passwordOld: oldPassword
              }));
            }}
          >
            {translate('gen_settings_change_password_btn') || 'Speichern'}
          </Button>
        </form>

        {/*DELETE MY PROFILE*/}
        {!companyHasExternalLegalAgreements &&
        <div className={styles.deleteMyProfile} id='delete-profile'>
          <div className={styles.label}>
            {translate('delete_my_profile_title') || 'Mein Nutzer-Profil löschen'}
          </div>
          <div className={styles.copy}>
            {translate('delete_my_profile_copy', ['{{instance_url}}', window.location.host]) ||
            'Falls du dein Profil auf {{instance}} löschen möchtest, kannst du das hier tun. Sämtliche Profildaten, inklusive deiner Assessment-Ergebnisse werden anschließend gelöscht und sind danach nicht mehr aufrufbar.'
            }
          </div>
          <Button
            looks={'cancel'}
            size={'S'}
            onClick={() => setDeleteMyProfileModal1Visible(true)}
          >
            {translate('delete_my_profile_btn') || 'Profil loschen'}
          </Button>
        </div>
        }

      </div>

      <div className={styles.other}>
        <div className={styles.xxsTitle}>{translate('gen_settings_support_and_help') || 'Hilfe & Support'}</div>

        <Card hasPaddingsSmall hasHover
          onClick={handleHelpClick}
        >
          <div className={styles.label}>{translate('gen_settings_help_label') || 'bluquist Help Center'}</div>
          <div className={styles.copy}>
            {translate('gen_settings_help_copy') || 'Lerne mehr über die Funktionsweise von bluquist.'}
          </div>

          <Link onClick={handleHelpClick}>
            {translate('gen_settings_help_link') || 'Zum Help Center'}
          </Link>
        </Card>

        <Card hasPaddingsSmall hasHover
          onClick={handleSupportClick}
        >
          <div className={styles.label}>
            {translate('gen_settings_support_title') || 'Datenschutz & Sicherheit bei bluquist'}
          </div>
          <div className={styles.copy}>
            {translate('gen_settings_support_descr') || DEFAULT_SUPPORT_DESCRIPTION}
          </div>

          <Link onClick={handleSupportClick}>
            {CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST ?
              'support@bluquist.com' :
              (translate('by_gen_settings_support_learnmore') || 'Mehr erfahren')
            }
          </Link>
        </Card>

      </div>

      {showSuccessMessage &&
        <Toast onClose={() => setShowSuccessMessage(false)}>
          <>
            <div className={styles.toastTitle}>
              {translate('gen_settings_change_password_success_title') || 'Passwort erfolgreich geändert'}
            </div>
            <div className={styles.toastDescription}>
              {translate('gen_settings_change_password_success_descr') ||
              'Du kannst dich beim nächsten Login mit deinem neuen Passwort anmelden'
              }
            </div>
          </>
        </Toast>
      }

      {/* DELETE MY PROFILE MODAL 1*/}
      {deleteMyProfileModal1Visible &&
      <Modal
        header={translate('delete_account_feedback') || 'Wir würden uns über dein Feedback freuen!'}
        primaryButtonTitle={translate('delete_account_btn') || 'Weiter zur Löschung'}
        ignoredKeys={'Enter'}
        onClose={() => setDeleteMyProfileModal1Visible(false)}
        onConfirm={() => {
          setDeleteMyProfileModal1Visible(false);
          setDeleteMyProfileModal2Visible(true);
        }}
      >
        <div className='marginTopS'>
          <TextArea
            placeholder={translate('survey_page_header_title') || 'Dein Feedback'}
            onChange={value => setDeleteMyProfileFeedback(value)}
          />
        </div>
      </Modal>
      }

      {/* DELETE MY PROFILE MODAL 2*/}
      {deleteMyProfileModal2Visible &&
      <Modal
        header={translate('delete_my_profile_modal_header') || 'Bist du sicher, dass du dein Nutzerprofil löschen möchtest?'}
        redButtonTitle={translate('delete_lbl') || 'Loschen'}
        redButtonDisabled={deleteMyProfileProcessing}
        secondaryButtonTitle={translate('cancel_lbl') || 'Cancel'}
        closeOnConfirm={false}
        onClose={() => setDeleteMyProfileModal2Visible(false)}
        onConfirm={() => {
          setDeleteMyProfileProcessing(true);

          // sending feedback
          if (deleteMyProfileFeedback) {
            api.post('/core/platform/feedback', {accountDeletionFeedback: deleteMyProfileFeedback});
          }

          api.post('/core/user/delete', {password: deleteMyProfilePassword})
            .then(({ok, status, data}) => {
              setDeleteMyProfileProcessing(false);

              if (ok && status === 200) {
                localStorage.removeItem('token');
                dispatch(logoutFulfilled());
                //'registeredSuccessfully' needs to be set to false for newly signed-up users
                dispatch(initSignUp());
                window.location.href = '/profile-deleted';
              } else {
                setDeleteMyProfileErrorMessage(data.error.errorMessage);
              }
            })
            .catch(error => {
              console.error(error.message);
              setDeleteMyProfileProcessing(false);
              setDeleteMyProfileErrorMessage(error.message);
            })
        }}
      >

        <div className={styles.deleteMyProfileModalContent}>
          {translate('delete_my_profile_modal_content', ['{{instance_url}}', window.location.host]) ||
          ' Sämtliche Profildaten inklusive deiner Assessmentergebnisse werden unwiderruflich gelöscht.'
          }

          {/* CONFIRM PASSWORD */}
          <InputPassword
            placeHolder={translate('share_profile_login_password_placeholder') || 'Passwort eingeben'}
            onInputChanged={(value) => setDeleteMyProfilePassword(value)}
          />

          {/* ERROR HINT */}
          {deleteMyProfileErrorMessage &&
          <div className={styles.error}>
            {deleteMyProfileErrorMessage}
          </div>
          }

        </div>
      </Modal>
      }

    </div>
  )
};

export default Settings;
