export const LIST_ASSESSMENTS = 'LIST_ASSESSMENTS';
export const LIST_ASSESSMENTS_PENDING = 'LIST_ASSESSMENTS_PENDING';
export const LIST_ASSESSMENTS_FULFILLED = 'LIST_ASSESSMENTS_FULFILLED';
export const LIST_ASSESSMENTS_REJECTED = 'LIST_ASSESSMENTS_REJECTED';

export const listAssessments = () => {
  return {
    type: LIST_ASSESSMENTS,
    payload: {}
  };
};

export const listAssessmentsFulfilled = (assessments) => {
  return {
    type: LIST_ASSESSMENTS_FULFILLED,
    payload: assessments
  };
};

export const listAssessmentsRejected = ({ error }) => {
  return {
    type: LIST_ASSESSMENTS_REJECTED,
    payload: { error }
  };
};

