import { all, call, put, takeEvery, select } from 'redux-saga/effects';

// STORE
import * as fromActions from 'store/actions/configuration';

// UTILS
import * as api from 'api';
import { COMPANY_ID, IS_ADMIN_FRONTEND } from 'utils/configuration';
import { selectUserRoleNext, selectUserSubRoleNext } from 'store/selectors/currentUser';


function* getConfiguration () {
  try {
    const { settings, customLogo } = yield all({
      settings: call(api.get, `core/company/settings?company=${COMPANY_ID}`),
      customLogo: call(api.get, `core/company/${COMPANY_ID}/logo`),
    });

    if (settings.ok || customLogo.ok) {
      const deployment = {
        isAdminFrontend: IS_ADMIN_FRONTEND
      };

      const company = {
        companyId: COMPANY_ID,
        customLogoUrl: customLogo.ok && api.getCustomLogoUrl(),
      };

      if (settings.ok) {
        Object.assign(company, {...settings.data})
      }

      yield put(fromActions.getConfigurationSuccess({ deployment, company }));
    }
    else {
      const { ok, status, error } = settings;
      yield put(fromActions.getConfigurationError({ ok, status, error }));
    }
  }
  catch (error) {
    yield put(fromActions.getConfigurationError({ error }));
  }
}



function* getConfigurationExtended (action) {

  let { userGroup, userSubGroup } = action.payload || {};
  if (!userGroup || userSubGroup) {
    const userRoleNext= yield select(selectUserRoleNext);
    userGroup = userRoleNext;
    const userSubRoleNext = yield select(selectUserSubRoleNext)
    userSubGroup = userSubRoleNext;
  }

  try {
    const { features, switches } = yield all({
      features: call(api.get, 'core/features'),
      switches: call(api.get, 'core/config/switches')
    });

    if (features.ok && switches.ok) {
      yield put(fromActions.getConfigurationExtendedSuccess({
        features: features.data.features,
        userGroup,
        userSubGroup,
        switches: switches.data.switches,
      }));
    }
    else {
      const { ok, status, error } = features;
      yield put(fromActions.getConfigurationError({ ok, status, error }));
    }
  }
  catch (error) {
    yield put(fromActions.getConfigurationError({ error }));
  }
}

export function* watchGetConfiguration() {
  yield takeEvery(fromActions.GET_CONFIGURATION, getConfiguration);
}

export function* watchGetConfigurationExtended() {
  yield takeEvery(fromActions.GET_CONFIGURATION_EXTENDED, getConfigurationExtended);
}
