import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import KeyCompetenciesAssessmentEnd from '../intermissions/KeyCompetenciesAssessmentEnd';

// UTILS
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

// OTHER COMPONENTS

export const keyCompetenciesConfig = {
  id: 'keyCompetencies',
  title: 'Key Competencies',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 30 * 1000,
  // storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  modalHelpContentTranslationKey: 'keycomp_assessment_help_info_content',
  loadingPage: {
    // INTRO with disabled next button and skeleton
    render: (state) => (
      <AssessmentIntro
        skeletonOnly
      />
    )
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType={ASSESSMENT_TYPES.KEY_COMPETENCIES}
          onClickNext={() => next()}
        />
      )
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.KEY_COMPETENCIES}
          onClickNext={() => next()}
        />
      )
    },
    // BREATHE is set in Big5AssessmentPage
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => {
        const { finishable, handleFinish, errorEnd } = extras;
        return (
          <KeyCompetenciesAssessmentEnd
            finishable={finishable}
            handleFinish={handleFinish}
            errorEnd={errorEnd}
          />
        )
      }
    }
  ]
};
