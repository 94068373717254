import * as fromActionTypes from 'store/actionTypes/staffing';

export const initStaffingEmployee = () => {
  return {
    type: fromActionTypes.INIT_STAFFING_EMPLOYEE,
    payload: {}
  };
};

//GET STAFFING EMPLOYEE
export const getStaffingEmployee = (roleId, userId) => {
  return {
    type: fromActionTypes.GET_STAFFING_EMPLOYEE,
    payload: {roleId, userId}
  };
};

export const getStaffingEmployeeFulfilled = ({ employee }) => {
  return {
    type: fromActionTypes.GET_STAFFING_EMPLOYEE_FULFILLED,
    payload: { employee }
  };
};

export const getStaffingEmployeeRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_STAFFING_EMPLOYEE_REJECTED,
    payload: { error }
  };
};

//SETTING ROLE TO EMPLOYEE
export const setUserRole = (userId, roleId) => {
  return {
    type: fromActionTypes.SET_USER_ROLE,
    payload: {userId, roleId}
  };
};

export const setUserRoleFulfilled = () => {
  return {
    type: fromActionTypes.SET_USER_ROLE_FULFILLED,
    payload: {}
  };
};

export const setUserRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.SET_USER_ROLE_REJECTED,
    payload: { error }
  };
};

//DELETE USER ROLE
export const initDeleteUserRole = () => {
  return {
    type: fromActionTypes.INIT_DELETE_USER_ROLE,
    payload: {}
  };
};

export const deleteUserRole = (userId, roleId) => {
  return {
    type: fromActionTypes.DELETE_USER_ROLE,
    payload: {userId, roleId}
  };
};

export const deleteUserRoleFulfilled = () => {
  return {
    type: fromActionTypes.DELETE_USER_ROLE_FULFILLED,
    payload: {}
  };
};

export const deleteUserRoleRejected = ({ error }) => {
  return {
    type: fromActionTypes.DELETE_USER_ROLE_REJECTED,
    payload: { error }
  };
};

