// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionGender.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentRadioButtonGroup } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionGender
const QuestionGender = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
   } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const options = [
    {
      label: translate('cp_gender_male') || 'Männlich',
      value: 'm'
    },
    {
      label: translate('cp_gender_female') || 'Weiblich',
      value: 'w'
    },
    {
      label: translate('cp_gender_diverse') || 'Divers',
      value: 'd'
    }
  ];

  const [ gender, setGender ] = useState('');
  useEffect(() => {
    setGender(answer.gender);
  }, [answer.gender]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // EVENT HANDLES
  const handleGender = (gender) => {
    setGender(gender);
    onAnswer({ value: gender, name: 'gender', isValid: !!gender });
  };

  // RENDER: QuestionGender
  return (
    <div className={classNames(styles.questionGender)}>

      {/* FORM */}
      <div className={classNames(styles.formRow, styles.radioButtonGroupContainer)}>
        <div className={styles.assessmentRadioButtonGroup}>
          <AssessmentRadioButtonGroup
            name={'radio-button-group'}
            selectedValue={answer.value}
            items={options}
            deselectable
            onChange={(value) => {
              // console.log(value, gender);
              if (value !== gender) {
                // console.log('set', value);
                handleGender(value);
              }
            }}
          />
        </div>
      </div>

    </div>
  );
};

export default QuestionGender;
