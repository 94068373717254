import { get } from 'api';
import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';

function* getTranslations(language) {
  try {
    const {status,ok,data} = yield call(
      get,
      `/core/language/${language}`
    );

    if (ok && status === 200) {
      yield put(
        action.getTranslationsFulfilled({
           data
        })
      );
    } else {
      yield put(
        action.getTranslationsRejected(data)
      );
    }
  } catch (error) {
    yield put(action.getTranslationsRejected(error));
  }
}
export default function* watchGetLanguageStringsRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.GET_TRANSLATIONS);

      yield call(getTranslations, payload);
    } catch (error) {
      yield put(action.getTranslationsRejected(error));
    }
  }
}
/*
response={
  duration:2,
  problem:null,
  originalError:null,
  ok:true,
  status:200,
  headers:,
  config:,
  data:
}
*/
