import React, {useState} from 'react';
import styles from './Introduction.module.scss';
import {useHistory} from 'react-router';
import {Button, Icon} from 'ui/basic';
import {Icons} from 'assets/icons';
import Image1 from 'assets/keyvisuals/create_team.svg';
import AssessmentScreenshotImage from 'assets/keyvisuals/assessment-screenshot.svg';
import FeaturesImage from 'assets/keyvisuals/features.svg';
import FeedbackImage from 'assets/keyvisuals/feedback.svg';

import { useTranslate } from 'utils/translator';
import {markdown} from 'utils/textTools';

import {useSelector} from 'react-redux';
import * as currentUserSelector from 'store/selectors/currentUser';

const DEFAULT_SCREEN1_TITLE =
  `[blu-markdown]

   Hallo {{username}}.
   Erfahre in einer kurzen Einführung, wie dir bluquist bei deiner persönlichen Entwicklung hilft.`;

const DEFAULT_SCREEN2_DESCRIPTION =
  '[blu-markdown]' +
  'Über kurze spielerische Assessments kannst du umfassende Erkenntnisse zu dir selbst gewinnen,' +
  'die für deine inidividuelle Entwicklung extrem hilfreich sind. Dazu zählen unter anderem:<br/><br/>' +
  '- deine Persönlichkeitsmerkmale <br/><br/>' +
  '- deine Lebensmotive und Motivationen <br/><br/>' +
  '- deine Arbeitspräferenzen <br/><br/>' +
  '- deine Kompetenzen <br/><br/>' +
  '- dein situatives Wohlbefinden';

const DEFAULT_SCREEN3_DESCRIPTION =
  '[blu-markdown]' +
  'bluquist ist ein sogenanntes People Analytics Tool und nutzt Daten von Mitarbeitern & Teams um ihre zielgerichtete Entwicklung zu unterstützen.<br/><br/>' +
  'Darüber hinaus nutzt die Applikation aggregierte Daten um Rollen für deine Organisation zu verwalten und passgenau zu besetzen. ' +
  'Das eröfffnet nicht nur deiner Organisation, sondern auch Dir neue Möglichkeiten in der Weiterentwicklung.<br/><br/>' +
  'Deine Daten werden stets verschlüsselt übertragen und sind auf deutschen Servern bestmöglich geschützt.<br/><br/>' +
  'Du kannst zudem jederzeit die vollständige Löschung deiner personenbezogenen Daten veranlassen.';

const DEFAULT_SCREEN4_DESCRIPTION =
  '[blu-markdown]' +
  'Du testest gerade die Beta-Version von bluquist.' +
  'Wir fügen in Kürze neue Funktion hinzu, die deiner Organisation das Leben einfacher machen. Dazu zählen:<br/><br/>' +
  '- Intelligentes Rollen-Management <br/><br/>' +
  '- Datengestütze Rollenbesetzung <br/><br/>' +
  '- Datengestütztes Team-Management <br/><br/>' +
  '- Funktionen & Inhalte zur Potenzialentwicklung von Mitarbeitern & Teams';

const DEFAULT_SCREEN5_DESCRIPTION =
  '[blu-markdown]' +
  'Als Freund und Partner von bluquist ist uns deine Meinung besonders wichtig.' +
  'Wir freuen uns auf dein Feedback und wünschen dir viel Erfolg mit bluquist.<br/><br/>' +
  'Falls du Support benötigst, stehen wir dir selbstverständlich gerne zur Seite.';

const SCREENS = [
  {
    id: 1,
    title: DEFAULT_SCREEN1_TITLE,
    description: null,
    buttonTitle: 'Loslegen',
    image: Image1,
    smallImage: false,
  },
  {
    id: 2,
    title: 'Lerne dich selbst, deine Stärken und deine Präferenzen besser kennen.',
    description: DEFAULT_SCREEN2_DESCRIPTION,
    buttonTitle: 'Weiter',
    image: AssessmentScreenshotImage,
    smallImage: false,
  },
  {
    id: 3,
    title: 'Das passiert mit deinen Daten',
    description: DEFAULT_SCREEN3_DESCRIPTION,
    buttonTitle: 'Weiter',
    image: Image1,
    smallImage: true
  },
  {
    id: 4,
    title: 'Neue Funktionen in der Warteschlange.',
    description: DEFAULT_SCREEN4_DESCRIPTION,
    buttonTitle: 'Weiter',
    image: FeaturesImage,
    smallImage: true
  },
  {
    id: 5,
    title: 'Vielen Dank für deine Unterstützung!',
    description: DEFAULT_SCREEN5_DESCRIPTION,
    buttonTitle: 'Los geht\'s',
    image: FeedbackImage,
    smallImage: true
  }
];

const Introduction = () => {

  const translate = useTranslate();
  const history = useHistory();

  const [screen, setScreen] = useState(1);

  const firstName = useSelector(currentUserSelector.getFirstName);


  const getComponent = () => {
    const component = SCREENS[screen - 1];
    const title = translate(`introduction_screen${component.id}_title`, ['{{username}}', firstName]) || component.title;
    const description = translate(`introduction_screen${component.id}_description`) || component.description;

    return (
      <>
        <div className={component.smallImage ? styles.smallImage : styles.image}>
          <img alt='introduction-screen' src={component.image}/>
        </div>
        <div className={component.smallImage ? styles.bigText : styles.text}>
          <div className={styles.title}>
            {title}
          </div>
          {component.description &&
          <div className={styles.description}>
            {markdown(description)}
          </div>
          }
          <Button
            size='M'
            onClick={() => {
              //set screen to null first to have animation working
              setScreen(null);

              setTimeout(() => {
                if (component.id === SCREENS.length) {
                  history.push('/');
                  return;
                }
                setScreen(component.id + 1)
              })
            }}
          >
            {translate(`introduction_screen${component.id}_btn`) || component.buttonTitle}
          </Button>
        </div>
      </>
  )};

  if (!screen) {
    return null;
  }

  return (
    <div className={styles.introduction}>
      {/*HEADER*/}
      <div className={styles.header}>
        <span>{translate('introduction_header_title') || 'Einführung'}</span>
        <div
          className={styles.close}
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={Icons.CloseBig}/>
        </div>
      </div>

      {/*CONTENT*/}
      <div className={styles.content}>
        <div className={styles.gridContent}>
          {getComponent()}
        </div>
      </div>
    </div>
  )
};

export default Introduction;
