export const INIT_GET_ASSESSMENT_QUESTIONS = 'INIT_GET_ASSESSMENT_QUESTIONS';
export const GET_ASSESSMENT_QUESTIONS = 'GET_ASSESSMENT_QUESTIONS';
export const GET_ASSESSMENT_QUESTIONS_PENDING = 'GET_ASSESSMENT_QUESTIONS_PENDING';
export const GET_ASSESSMENT_QUESTIONS_FULFILLED = 'GET_ASSESSMENT_QUESTIONS_FULFILLED';
export const GET_ASSESSMENT_QUESTIONS_REJECTED = 'GET_ASSESSMENT_QUESTIONS_REJECTED';

export const initGetAssessmentQuestions = (assessmentId) => {
  return {
    type: INIT_GET_ASSESSMENT_QUESTIONS,
    payload: {assessmentId}
  };
};

export const getAssessmentQuestions = (id, stage) => {
  return {
    type: GET_ASSESSMENT_QUESTIONS,
    payload: {id, stage}
  };
};

export const getAssessmentQuestionsFulfilled = (questions) => {
  return {
    type: GET_ASSESSMENT_QUESTIONS_FULFILLED,
    payload: questions
  };
};

export const getAssessmentQuestionsRejected = ({ error }) => {
  return {
    type: GET_ASSESSMENT_QUESTIONS_REJECTED,
    payload: { error }
  };
};

