import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Big5Result.module.scss';

import { useDispatch, useSelector } from 'react-redux';

import { getAssessment, initSubmitContentFeedback, submitContentFeedback } from 'store/actions';

/* UTILS */
import { useTranslate } from 'utils/translator';
import { joinNumbers } from 'utils/textTools';
import { numberWithForcedDecimals } from 'utils/numberFormatting';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { useBreakpoint } from 'utils/hooks';
import { isValid } from 'utils/numbers';
import { setDecimalSeperator } from 'utils/userpreference';

// 3RD PARTY
import classNames from 'classnames';

// ASSETS
import { IconsSvg } from 'assets/icons';

import {
  DEFAULT_CALLOUT_HIGH,
  DEFAULT_CALLOUT_LOW,
  DEFAULT_CALLOUT_MEDIUM, DEFAULT_CALLOUT_VERY_HIGH,
  DEFAULT_CALLOUT_VERY_LOW,
  DEFAULT_DIMENSIONS,
  DEFAULT_FACETS, DEFAULT_INFO
} from './Big5Result.mockData';

import Big5ResultSkeleton from './Big5ResultSkeleton';
import {
  Button, CollapsibleNext,
  InfoTitle,
  SpiderDiagramNext,
  Card,
  Modal, LineDiagram, Callout
} from 'ui/basic';

import Big5Report from './Big5Report';


/*
  The Big5Assement has 5 dimensions and each dimension has 6 facets
  The results property is received as an array consisting of 5 arrays, each of these 5 arrays
  holds the results for the 6 facets of that dimension
*/

const DIMENSIONS = [1, 2, 3, 4, 5];
const FACETS = [1, 2, 3, 4, 5, 6];
const FACET  = '_facet';
const DIMENSION  = 'big5_results_label_dim';

const Big5Result = (props) => {
  const {
    results,
    showReportButton = true,
    openReport,
    requestFeedback = true,
    footerText,
    noDataText,
    showExportIcon,
    exportView,
    onExport
  } = props;

  // console.log('BIG5 results', results);
  // console.log('BIG5 results', JSON.stringify(results, null, 2));
  const translate = useTranslate();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  // Spider Hovers
  const [ dimensionsHovers, setDimensionsHovers ] = useState([
    // true|false value for each dimensions
    // 0 => true|false depending if hover is set for dimension 0
  ]);

  const setHover = (dimensionIndex, isHover) => {
    const dimensionsHovers = new Array(dimensions.length).fill(false);
    dimensionsHovers[dimensionIndex] = isHover;

    setDimensionsHovers(dimensionsHovers);
  };

  // STATE
  const [assessmentResults, setAssessmentResults] = useState();
  const [assessmentRanges, setAssessmentRanges] = useState();
  const [dimensions, setDimensions] = useState([]);
  const [dimensionsIds, setDimensionsIds] = useState([]);
  const [facets, setFacets] = useState([]);
  const [totalResults, setTotalResults] = useState([]);
  const [totalRanges, setTotalRanges] = useState([]);
  const [anchor, setAnchor] = useState();
  const [showReport, setShowReport] = useState(false);
  const [infoPageIsVisible, setInfoPageIsVisible] = useState(false);

  const [currentDimensionIndex, setCurrentDimensionIndex] = useState();

  const [modalHeader, setModalHeader] = useState();
  const [modalSubHeader, setModalSubHeader] = useState();
  const [modalContent, setModalContent] = useState();
  const [modalPrimaryButtonTitle, setModalPrimaryButtonTitle] = useState();
  const [modalScrollable, setModalScrollable] = useState(false);
  const [showModalFeedback, setShowModalFeedBack] = useState();

  // SELECTORS
  const currentLanguage = useSelector(state => state.localisation?.currentLanguage);

  const resultSchemaLanguage = useSelector(state => state.assessments?.[ASSESSMENT_TYPES.BIG5]?.resultSchemaLanguage);
  const resultSchema = useSelector(state => state.assessments?.[ASSESSMENT_TYPES.BIG5]?.resultSchema);

  const DownloadIcon = IconsSvg.DownloadSvg;

  const feedbackSubmittedSuccessfully = useSelector(state => state.survey && state.survey.success);

  const showRange = results && results[0] && Array.isArray(results[0].range);

  const getDimensionLabel = (nr) => {
    if (dimensions && dimensions[nr - 1]) {
      return dimensions[nr - 1];
    }
    return translate(`${DIMENSION}${nr}`) || DEFAULT_DIMENSIONS[nr - 1];
  };

  const getFacetLabel = (dim, facet) => {
    if (facets && facets[dim - 1] && facets[dim - 1][facet - 1]) {
      return facets[dim - 1][facet - 1];
    }
    return (
      translate(`${DIMENSION}${dim}${FACET}${facet}`) ||
      DEFAULT_FACETS[dim - 1][facet - 1]
    );
  };

  const parseResultSchema = (resultSchema) => {
    const ass_dimensions = [];
    const assessmentDimensionsIds = [];
    const subdimensions = [];
    resultSchema.forEach((dimension, i) => {
      ass_dimensions.push(dimension.name);
      assessmentDimensionsIds.push(dimension.id);
      const dimfacets = [];
      if (dimension.subDimensions) {
        dimension.subDimensions.forEach((subDimension, j) => {
          dimfacets.push(subDimension.name);
        });
      }
      subdimensions.push(dimfacets);
    });
    setDimensions(ass_dimensions);
    setDimensionsIds(assessmentDimensionsIds);
    setFacets(subdimensions);
  };

  const getCalloutText = (value, dimensionId) => {
    if (value >= 1 && value <= 2) {
      return translate(`big5_report_dim_${dimensionId}_callout_very_low`) || DEFAULT_CALLOUT_VERY_LOW;
    }
    if (value > 2 && value <= 4) {
      return translate(`big5_report_dim_${dimensionId}_callout_low`) || DEFAULT_CALLOUT_LOW;
    }
    if (value > 4 && value <= 6) {
      return translate(`big5_report_dim_${dimensionId}_callout_medium`) || DEFAULT_CALLOUT_MEDIUM;
    }
    if (value > 6 && value <= 8) {
      return translate(`big5_report_dim_${dimensionId}_callout_high`) || DEFAULT_CALLOUT_HIGH;
    }
    return translate(`big5_report_dim_${dimensionId}_callout_very_high`) || DEFAULT_CALLOUT_VERY_HIGH;
  }

  const getSubHeader = (dimension, dimensionValue) => {
    let dimensionValueNumber = dimensionValue;
    // if dimensionValue is not a number
    if (!isValid(dimensionValue)) {
      dimensionValueNumber = Number(dimensionValue.replace(',', '.'));
    }

    if (dimensionValueNumber >= 1 && dimensionValueNumber <= 2) {
      return translate('big5_dimension_very_low__short_descr',
        ['{{dimension}}', dimension]) ||
        `Das Merkmal ${dimension} ist sehr gering ausgeprägt.`;
    }

    if (dimensionValueNumber > 2 && dimensionValueNumber <= 4) {
      return translate('big5_dimension_low__short_descr',
        ['{{dimension}}', dimension]) ||
        `Das Merkmal ${dimension} ist  gering ausgeprägt.`;
    }

    if (dimensionValueNumber > 4 && dimensionValueNumber <= 6) {
      return translate('big5_dimension_medium__short_descr',
        ['{{dimension}}', dimension]) ||
        `Das Merkmal ${dimension} ist mittel ausgeprägt.`;
    }

    if (dimensionValueNumber > 6 && dimensionValueNumber <= 8) {
      return translate('big5_dimension_high__short_descr',
        ['{{dimension}}', dimension]) ||
        `Das Merkmal ${dimension} ist hoch ausgeprägt.`;
    }

    return translate('big5_dimension_very_high__short_descr',
      ['{{dimension}}', dimension]) ||
      `Das Merkmal ${dimension} ist sehr hoch ausgeprägt.`;
  };

  useEffect(() => {
    if (openReport && totalResults && assessmentResults) {
      setShowReport(true);
    }
  }, [openReport, totalResults, assessmentResults]);

  useEffect(() => {
    if (results && resultSchema) {
      const resultsMatrix = [];
      const rangesMatrix = [];
      let start = 0;

      const dimensionsLength = resultSchema.reduce((total, r) => total + r.subDimensions.length, 0);

      resultSchema.forEach(dimension => {
        const subDimensionsLength = dimension.subDimensions.length;

        if (results && results[0] && results[0].result) {
          resultsMatrix.push(results.slice(start, (start + subDimensionsLength)).map(a => a.result));
        }

        if (results && results[0] && results[0].range) {
          rangesMatrix.push(results.slice(start, (start + subDimensionsLength)).map(a => a.range));
        }

        //results includes only subDimensions
        if (results.length <= dimensionsLength) {
          start += subDimensionsLength;
        } else {
          //results includes subDimensions + dimensions
          //+1 because after sub dimensions values there is one more value for total result;
          start += (subDimensionsLength + 1);
        }
      });

      setAssessmentResults(resultsMatrix);
      setAssessmentRanges(rangesMatrix);
      parseResultSchema(resultSchema);
    }
  }, [results, resultSchema]);

  useEffect(() => {
    if (assessmentResults && assessmentResults.length > 0) {
      const total = assessmentResults.map((results) => (
        (results.reduce((total, result) => total + result) / results.length).toFixed(1)
      ));
      setTotalResults(total);
    }
  }, [assessmentResults]);

  useEffect(() => {
    if (assessmentRanges && assessmentRanges.length > 0) {
      const total = assessmentRanges.map((results) => {
        const firstLevelResult1 = results.map(result => result[0]);
        const firstLevelResult2 = results.map(result => result[1]);

        const secondLevelResult1 =
          (firstLevelResult1.reduce((total, result) => total + result) / firstLevelResult1.length)
            .toFixed(1);
        const secondLevelResult2 =
          (firstLevelResult2.reduce((total, result) => total + result) / firstLevelResult2.length)
            .toFixed(1);

        return [Number(secondLevelResult1), Number(secondLevelResult2)];
      });

      setTotalRanges(total);
    } else {
      setTotalRanges([]);
    }
  }, [assessmentRanges]);

  useEffect(() => {
    if (!resultSchema || resultSchemaLanguage !== currentLanguage) {
      dispatch(getAssessment(ASSESSMENT_TYPES.BIG5));
    }
  }, [ dispatch, resultSchema, resultSchemaLanguage, currentLanguage ]);

  const getSubItem = (dimension, facet) => {
    let result;
    let range = [];

    if (assessmentResults && assessmentResults.length > 0) {
      result = assessmentResults && assessmentResults[dimension - 1][facet - 1];
    }

    if (assessmentRanges && assessmentRanges.length > 0) {
      range = (assessmentRanges && assessmentRanges[dimension - 1][facet - 1]) || [];
    }

    // const resultIsValid = isValid(result);

    const formattedResult = setDecimalSeperator(numberWithForcedDecimals(result));
    const formattedRange = range.length
      ? `${numberWithForcedDecimals(range[0])}-${numberWithForcedDecimals(range[1])}`
      : '';

    const header = getFacetLabel(dimension, facet);
    const headerHighlight = formattedRange
      ? formattedRange
      : formattedResult;

    const headerRight = formattedRange
      ? formattedResult
      : '';


    return (
      <CollapsibleNext key={`${dimension}-${facet}`}
        isExpandable={false}
        headerHighlight={headerHighlight}
        header={header}
        headerRight={headerRight}
      />
    );
  }

  const renderExpandedModalContent = (dimensionResult, dimensionIndex) => {
    return (
      <div className={styles.infoModalResult}>
        <div className={styles.reportDiagram}>
          <div className={styles.header}>
            <span>{translate('big5_report_facet_result') || 'Ergebnis'}</span>
            <span>{setDecimalSeperator(dimensionResult)}</span>
          </div>

          <LineDiagram
            score={dimensionResult}
            width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
          />

          <div className={styles.footer}>
            <span>{translate('big5_report_resut_hint1') || 'gering ausgeprägt'}</span>
            <span>{translate('big5_report_resut_hint2') || 'stark ausgeprägt'}</span>
          </div>
        </div>

        <div className={classNames('blu-typeCopy', 'marginTopXs')}>
          <Callout>
            {getCalloutText(dimensionResult, dimensionsIds[dimensionIndex])}
          </Callout>
        </div>

        <div className={styles.facets}>
          <div className={styles.facetsTitle}>
            {translate(`big5_report_dim_${dimensionsIds[dimensionIndex]}_facets`) ||
            'Die Facetten von Verträglichkeit'}
          </div>

          { FACETS.map((subDimension, index) => {
            const subDimensionValue = numberWithForcedDecimals(assessmentResults[dimensionIndex][subDimension - 1]);

            return (
              <div className={styles.facet} key={`subDimension-${index}`}>
                <div className={styles.facetHeader}>
                  <span>{getFacetLabel(dimensionIndex + 1, subDimension)}</span>
                  <span>{ subDimensionValue }</span>
                </div>

                <LineDiagram
                  score={subDimensionValue}
                  width={bp.isS ? 412 : (bp.isXs ? 256 : 586)}
                />

                <div className={styles.facetFooter}>
                  <span>{translate('big5_report_resut_hint1') || 'gering ausgeprägt'}</span>
                  <span>{translate('big5_report_resut_hint2') || 'stark ausgeprägt'}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  if (!results?.length || !resultSchema
    || ((assessmentResults && assessmentResults.length > 0) && !totalResults)
    || ((assessmentRanges && assessmentRanges.length > 0) && !totalRanges)
    || (!assessmentResults && !totalResults && !assessmentRanges && !totalRanges)
  ) {
    return (
      <div className={styles.big5Result}>
        <Big5ResultSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.big5Result}>

      {/* CARD */}
      <Card hasBreakout='S'>

        {/* TITLE */}
        <div className={styles.title}>
          {exportView ?
            <span className='blu-typeLabel'>{translate('big5_results_title') || 'Persönlichkeit'}</span> :
            <InfoTitle
              title={translate('big5_results_title') || 'Persönlichkeit'}
              onClick={() => {
                setModalHeader(translate('big5_results_title') || 'Persönlichkeit');
                setModalSubHeader(null);
                setModalContent(translate('big5_results_info_description') || DEFAULT_INFO);
                setModalPrimaryButtonTitle(null);
                setModalScrollable(false);
                setShowModalFeedBack(false);
                setInfoPageIsVisible(true);
              }}
            />
          }

          {(!bp.isXs && !exportView && showExportIcon) &&
          <DownloadIcon onClick={onExport}/>
          }
        </div>

        {noDataText && <div className={styles.noDataText}>{noDataText}</div>}

        {!noDataText &&
        <>
          {/* SPIDERDIAGRAMNEXT */}
          <SpiderDiagramNext
            lineValues={totalResults}
            rangeValues={totalRanges}
            dimensions={(resultSchema || []).map((dimension) => {
              return { label: dimension.name, value: dimension.id };
            })}
            dimensionsHovers={dimensionsHovers}
            onHover={setHover}
            onDimensionClick={(dimension) => {
              if (!showReportButton) {
                return;
              }

              const dimensionId = dimension.value;
              const dimensionName = dimension.label;
              const dimensionIndex = dimensionsIds.findIndex(dId => dId === dimensionId);
              const dimensionResult = totalResults[dimensionIndex];

              setCurrentDimensionIndex(dimensionIndex);

              setModalHeader(dimensionName);
              setModalSubHeader(getSubHeader(dimensionName, dimensionResult));

              setModalPrimaryButtonTitle(translate('go_to_report_lbl') || 'Zum Report');
              setShowModalFeedBack(true);
              setModalScrollable(true);

              setModalContent(renderExpandedModalContent(dimensionResult, dimensionIndex));

              setInfoPageIsVisible(true);
            }}
          />

          {/* COLLAPSIBLES / DIMENSIONS */}
          <div className={styles.collapsibles}>
            {DIMENSIONS.map((dimension, dimensionIndex) => {
              const lineValue = totalResults[dimensionIndex];
              const range = (totalRanges && totalRanges[dimensionIndex]) || [];

              const formattedResult = setDecimalSeperator(numberWithForcedDecimals(lineValue));
              const formattedRange = range.length
                ? joinNumbers(range, '-', numberWithForcedDecimals)
                : '';

              const headerHighlight = range.length
                ? formattedRange
                : formattedResult;
              const headerRight = range.length
                ? formattedResult
                : '';

              return (
                <CollapsibleNext
                  key={dimensionIndex}
                  isExpandable={!exportView}
                  headerSubtle={dimensionIndex + 1}
                  headerHighlight={headerHighlight}
                  header={getDimensionLabel(dimension)}
                  headerRight={headerRight}
                  isHovered={dimensionsHovers[dimensionIndex]}
                  onMouseEnter={() => { setHover(dimensionIndex, true)}}
                  onMouseLeave={() => { setHover(dimensionIndex, false)}}
                >
                  {FACETS.map(facet => getSubItem(dimension, facet))}
                  {/*SHOW REPORT BUTTON*/}
                  {(!showRange && showReportButton) &&
                  <div className={styles.button}>
                    <Button
                      size={'S'}
                      looks={'secondary'}
                      onClick={() => {
                        setCurrentDimensionIndex(dimension - 1);

                        setModalHeader(getDimensionLabel(dimension));
                        setModalSubHeader(getSubHeader(getDimensionLabel(dimension), formattedResult));
                        setModalPrimaryButtonTitle(translate('go_to_report_lbl') || 'Zum Report');
                        setShowModalFeedBack(true);
                        setModalScrollable(true);
                        setModalContent(renderExpandedModalContent(lineValue, dimension - 1));

                        setInfoPageIsVisible(true);
                      }}
                    >
                      {translate(`big5_results_button_learnmore_dim${dimension}`) || 'Mehr erfahren'}
                    </Button>
                  </div>
                  }
                </CollapsibleNext>
              );
            })}
          </div>

          {footerText &&
          <div className={styles.footerText}>
            {footerText}
          </div>
          }

          {(!showRange && showReportButton && !exportView) &&
          <div className={styles.reportButton}>
            <Button
              size={'S'}
              looks={'secondary'}
              onClick={() => {
                setAnchor(null);
                setShowReport(true);
              }}
            >
              {translate('big5_results_button_showreport') || 'Report ansehen'}
            </Button>
          </div>
          }
        </>
        }
      </Card>

      {infoPageIsVisible && (
        <Modal
          header={modalHeader}
          subHeader={modalSubHeader}
          primaryButtonTitle={modalPrimaryButtonTitle}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          feedbackSubmitted={feedbackSubmittedSuccessfully}
          onFeedbackSubmit={(requestFeedback && showModalFeedback) ?
            ((feedback) => {
              dispatch(submitContentFeedback({
                contentId: dimensions[currentDimensionIndex],
                contentType: 'recommendation',
                helpful: feedback === 'Yes'
              }))
            }) :
            null
          }
          onClose={() => {
            dispatch(initSubmitContentFeedback());
            setInfoPageIsVisible(false);
          }}
          onConfirm={modalScrollable ?
            (() => {
              setInfoPageIsVisible(false);

              setAnchor(dimensions[currentDimensionIndex].replace(/\s/g, '-').toLowerCase());
              setShowReport(true);
            }) :
            (() => setInfoPageIsVisible(false))
          }
        >
          {modalContent}
        </Modal>
      )}

      { showReport && createPortal(
        <Big5Report
          dimensionsResults={totalResults}
          facetsResults={assessmentResults}
          anchor={anchor}
          onClose={() => setShowReport(false)}
        />,
        document.body,  // ModalsAndPanels
      )}

    </div>
  );
};

export default Big5Result;
