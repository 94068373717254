import React, { useRef, useState } from 'react';
import styles from './CompactAssessmentTeaser.module.scss';
import Big5Image from 'assets/assessments/big5.svg';
import PotentialImage from 'assets/assessments/potential.svg';
import WorkPreferenceImage from 'assets/assessments/work-preference.svg';
import KeyCompetenciesImage from 'assets/assessments/key-competencies.svg';
import LeadershipCompetenciesImage from 'assets/assessments/leadership-competencies.svg';
import RmpImage from 'assets/assessments/rmp.svg';
import NineLevelsImage from 'assets/assessments/9levels.svg';
import BalancedYouImage from 'assets/assessments/balanced-you.svg';

import { useTranslate } from 'utils/translator';
import { getTranslationIds } from './translationIds';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import {useHistory} from 'react-router';

import {InfoTitle, Modal} from 'ui/basic';
import {normalizeText} from 'utils/normalizeText';

const DEFAULT_SCOPE = '20 - 25 min / 120 Fragen';
const DEFAULT_INFO =
  'Persönlichkeitsmerkmale sind zeitlich stabile' +
  'Eigenschaften einer Person, durch die die Menschen sich unterscheiden.' +
  'Sie bilden den Grundstein für unser Handeln, unsere Vorlieben und unsere Stärken' +
  'und Schwächen​. Ob privat oder im Beruf – seine eigene Persönlichkeit oder' +
  'die anderer Personen zu kennen ermöglicht das Verständnis bestimmter Verhaltensmuster​.';


const CompactAssessmentTeaser = (props) => {
  const { assessmentType } = props;
  const ref = useRef();

  const translate = useTranslate();
  const history = useHistory();
  const [infoPageIsVisible, setInfoPageIsVisible] = useState(false);
  const translationIds = getTranslationIds(assessmentType);

  const getImage = () => {
    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return Big5Image;
      case ASSESSMENT_TYPES.POTENTIAL:
        return PotentialImage;
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return WorkPreferenceImage;
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return KeyCompetenciesImage;
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return LeadershipCompetenciesImage;
      case ASSESSMENT_TYPES.RMP:
        return RmpImage;
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return NineLevelsImage;
      case ASSESSMENT_TYPES.BALANCED_YOU:
        return BalancedYouImage;
      default:
        return Big5Image;
    }
  };

  return (
    <div className={styles.compactAssessmentTeaser} ref={ref}>
      <div className={styles.container} tabIndex={'0'}
        onClick={() => history.push(`/assessments/${assessmentType}`)}>
        <div
          className={styles.imageBlock}
        >
          <img
            alt={'teaser'}
            src={getImage()}
          />
        </div>
        <div className={styles.textBlock}>
          <InfoTitle
            title={translate(translationIds.title) || normalizeText(assessmentType)}
            onClick={(event) => {
              event.stopPropagation();
              setInfoPageIsVisible(true)
            }}
          />
          <div className={styles.scopeInfo}>
            {translate(translationIds.scope) || DEFAULT_SCOPE}
          </div>
        </div>
      </div>

      {infoPageIsVisible && (
        <Modal
          header={translate(translationIds.infoTitle) || 'Warum ist das wichtig?'}
          primaryButtonTitle={translate('assessment_reminder_md_start_btn') || 'Starten'}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          onConfirm={() => history.push(`/assessments/${assessmentType}`)}
          onClose={() => setInfoPageIsVisible(false)}
        >
          {translate(translationIds.infoDetails) || DEFAULT_INFO}
        </Modal>
      )}
    </div>
  );
};

CompactAssessmentTeaser.defaultProps = {
  assessmentType: ASSESSMENT_TYPES.BIG5
};

export default CompactAssessmentTeaser;
