import {
  GET_REGISTRATION_DOMAINS,
  GET_REGISTRATION_DOMAINS_FULFILLED,
  GET_REGISTRATION_DOMAINS_REJECTED,
  GET_ALL_FEATURES,
  GET_ALL_FEATURES_FULFILLED,
  GET_ALL_FEATURES_REJECTED,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_FULFILLED,
  GET_USER_SETTINGS_REJECTED,
  GET_COMPANY_SETTINGS,
  GET_COMPANY_SETTINGS_FULFILLED,
  GET_COMPANY_SETTINGS_REJECTED
} from 'store/actionTypes/settings';

export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_FULFILLED = 'GET_SETTINGS_FULFILLED';
export const GET_SETTINGS_REJECTED = 'GET_SETTINGS_REJECTED';

export const getSettings = () => {
  return {
    type: GET_SETTINGS,
    payload: {}
  };
};

export const getSettingsFulfilled = (settings) => {
  return {
    type: GET_SETTINGS_FULFILLED,
    payload: settings
  }
};

export const getSettingsRejected = ({ error }) => {
  return {
    type: GET_SETTINGS_REJECTED,
    payload: { error }
  };
};

export const getRegistrationDomains = () => {
  return {
    type: GET_REGISTRATION_DOMAINS,
    payload: {}
  };
};

export const getRegistrationDomainsFulfilled = ({domains}) => {
  return {
    type: GET_REGISTRATION_DOMAINS_FULFILLED,
    payload: {domains}
  }
};

export const getRegistrationDomainsRejected = ({ error }) => {
  return {
    type: GET_REGISTRATION_DOMAINS_REJECTED,
    payload: { error }
  };
};

// GET ALL FEATURES
export const getAllFeatures = () => {
  return {
    type: GET_ALL_FEATURES,
    payload: {}
  };
};

export const getAllFeaturesFulfilled = ({features}) => {
  return {
    type: GET_ALL_FEATURES_FULFILLED,
    payload: {features}
  }
};

export const getAllFeaturesRejected = ({ error }) => {
  return {
    type: GET_ALL_FEATURES_REJECTED,
    payload: { error }
  };
};

// GET USER SETTINGS
export const getUserSettings = () => {
  return {
    type: GET_USER_SETTINGS,
    payload: {}
  };
};

export const getUserSettingsFulfilled = ({userSettings}) => {
  return {
    type: GET_USER_SETTINGS_FULFILLED,
    payload: {userSettings}
  }
};

export const getUserSettingsRejected = ({ error }) => {
  return {
    type: GET_USER_SETTINGS_REJECTED,
    payload: { error }
  };
};

// GET COMPANY SETTINGS
export const getCompanySettings = (companyId) => {
  return {
    type: GET_COMPANY_SETTINGS,
    payload: {companyId}
  };
};

export const getCompanySettingsFulfilled = ({companySettings}) => {
  return {
    type: GET_COMPANY_SETTINGS_FULFILLED,
    payload: {companySettings}
  }
};

export const getCompanySettingsRejected = ({ error }) => {
  return {
    type: GET_COMPANY_SETTINGS_REJECTED,
    payload: { error }
  };
};
