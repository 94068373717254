import React from 'react';

const Icon = (props) => {
  const { icon, width, height } = props;
  const w = width ? (!isNaN(width) ? width + 'px' : height) : '';
  const h = height ? (!isNaN(height) ? height + 'px' : height) : '';
  const style = { width: w, height: h };
  return <img src={icon} alt="icon" style={style}></img>;
};

export default Icon;
