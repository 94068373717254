import React from 'react';
import styles from './ProfilePanel.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutFulfilled } from 'store/actions';
import { useTranslate } from 'utils/translator';
import {IS_ADMIN_FRONTEND} from 'utils/configuration';
import {
  getCompany,
  getEmail,
  getFirstName,
  getLastName,
  getProfession,
  getUserPicture
} from 'store/selectors/currentUser';
import {Button, SidePanel} from 'ui/basic';


const ProfilePanel = (props) => {
  const { onClose, onEditProfile } = props;

  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const firstName = useSelector(state => getFirstName(state));
  const lastName = useSelector(state => getLastName(state));
  const mail = useSelector(state => getEmail(state));
  const profession = useSelector(state => getProfession(state));
  const userPicture = useSelector(state => getUserPicture(state));
  const company = useSelector(state => getCompany(state));

  const name = (!firstName && !lastName) ? mail : `${firstName} ${lastName}`;

  return (
    <SidePanel onClose={onClose}>
      <div className={styles.profilePanel}>
        <div className={styles.avatar}>
          {userPicture ? (
            <img src={userPicture} alt={'user'} />
          ) : (
            (!firstName && !lastName) ? mail.substring(0, 1) : (firstName.substring(0, 1) + lastName.substring(0, 1))
          )}
        </div>
        <div className={styles.name}>{name}</div>
        <div className={styles.occupation}>{profession}</div>
        <div className={styles.occupation}>{company}</div>
        <div className={styles.buttons}>
          {!IS_ADMIN_FRONTEND &&
          <Button
            size={'S'}
            looks={'secondary'}
            onClick={() => {
              history.push('/my-profile');
              onClose();
            }}
          >
            {translate('pp_button_myprofile') || 'Mein Profil'}
          </Button>
          }
          <Button
            size={'S'}
            looks={'secondary'}
            onClick={onEditProfile}
          >
            {translate('pp_button_editprofile') || 'Profil bearbeiten'}
          </Button>
          <Button
            size={'S'}
            looks={'secondary'}
            onClick={() => {
              if (IS_ADMIN_FRONTEND) {
                history.push('/admin/settings');
              } else {
                history.push('/settings');
              }
              onClose();
            }}
          >
            {translate('pp_button_settings') || 'Einstellungen'}
          </Button>
          <Button
            size={'S'}
            looks={'tertiary'}
            onClick={() => {
              localStorage.removeItem('token');
              dispatch(logoutFulfilled());
              window.location.href = '/logged-out';
            }}
          >
            {translate('pp_button_logout') || 'Abmelden'}
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};

export default ProfilePanel;
