import React, { memo } from 'react';
import styles from './RadioButton.module.scss';
import classnames from 'classnames';

const RadioButton = memo((props) => {
  const {isError, disabled, checked, value, title, deselectable, onChange = () => {}} = props;

  const classNames = classnames(
    styles.radioButton,
    {[styles.error]: isError},
    {[styles.disabled]: disabled},
    {[styles.checked]: checked});

  return (
    <label
      className={classNames}
      onClick={() => {
        if (checked && !deselectable) {
          return;
        }

        //if radio button is deselectable, 'null' should be returned to parent component
        onChange(checked ? null : value)
      }}
    >
      <span className={styles.name}>{title}</span>
      <span className={styles.checkmark}></span>
    </label>
  );
});

RadioButton.defaultProps = {
  disabled: false,
  name: '',
  title: ''
};

export default RadioButton;
