// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useCallback } from 'react';
import styles from './QuestionSlider.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { SliderSingle } from 'ui/basic';
import {Button } from 'ui/basic';


// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
const CONFIG = {
  answerDelay: 200,
  defaultAnswer: 0,
};

// COMPONENT: QuestionSlider
const QuestionSlider = (props) => {
  // PROPS
  const {
    question,
    range = {
      answerFrom: 0,
      answerStep: 1,
      answerTo: 5,
    },
    selectedValue,
    clickBlock,
    onAnswer = () => {},
    onAnimation = () => {},
    onHelp = () => {},
  } = props;

  const translate = useTranslate();

  // COMPONENT/UI STATE and REFS
  const [value, setValue] = useState();
  const [answered, setAnswered] = useState(false);
  useEffect(() => {
    if (!answered && !isNaN(selectedValue)) { // don't update the value anymore when answered
      setValue(selectedValue);
      setAnswer(selectedValue);
    }
  }, [selectedValue, answered]);
  const [answer, setAnswer] = useState(CONFIG.defaultAnswer);

  // SPECIAL HOOKS

  // EFFECT HOOKS
  useEffect(() => {
    setAnswer((selectedValue || range.answerFrom));
  }, [selectedValue, range])

  // STORE HOOKS

  // METHODS
  const handleAnswer = useCallback((answer) => {
    if (clickBlock || answered) return;
    if (isNaN(answer)) return;

    setAnswered(true);
    onAnimation();
    setTimeout(() => {
      onAnswer(answer, CONFIG.answerDelay);
    }, CONFIG.answerDelay);
  }, [clickBlock, answered, onAnswer, onAnimation]);

  // EVENT HANDLES

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // NUMBER CONTROLS
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAnswer(answer);
    }
  }, [handleAnswer, answer]);
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  // HELPERS

  // RENDERS

  // RENDER: QuestionSlider
  if (!question) {
    return null;
  }
  return (
    <div className={classNames(styles.questionSlider, {
      [styles.closing]: answered
    })}>
      <div className={styles.container}>
        <div className={styles.questionContainer}>
          <div className={styles.question}>
            {(question && question.question) || (translate('9levels_ass_dnd_title') || 'Was mir am wichtigsten ist ...')}
          </div>
          <div className={styles.sliderBlock}>
            <SliderSingle
              value={value}
              from={range.answerFrom}
              step={range.answerStep}
              to={range.answerTo}
              onChange={(value) => setAnswer(value)}
            />
            <div className={styles.labels}>
              <span>{translate('9levels_ass_slider_disagree') || 'Trifft nicht zu'}</span>
              <span>{translate('9levels_ass_slider_agree') || 'Trifft zu'}</span>
            </div>
          </div>

          {/* ANSWER BUTTON */}
          <Button
            size='M'
            onClick={() => {
              handleAnswer(answer);
            }}
          >
            {translate('9levels_ass_continue_btn') || 'Weiter'}
          </Button>

          {/* HELP BUTTON */}
          <div className={styles.helpButton}>
            <Button
              size={'S'}
              looks={'tertiary'}
              onClick={onHelp}
            >
              {translate('assessment_help_button') || 'Hilfe bei der Auswahl'}
            </Button>
          </div>


        </div>
      </div>
    </div>
  );
};

export default QuestionSlider;
