import * as fromActions from 'store/actions/staticValues';

const initialState = {
  loading: false,
  countries: [],
  nationalities: [],

  education: [],
  educationOptions: [],

  industries: [],
  industryOptions: [],

  currencies: [],
  currencyOptions: [],

  careerLevels: [],
  careerLevelOptions: [],

  jobFamilies: [],
  jobFamilyOptions: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case fromActions.GET_COUNTRIES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_COUNTRIES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        countries: data.map(d => ({value: d.id, label: d.country})),
        nationalities: data.map(d => ({value: d.id, label: d.nationality}))
      };
    }
    case fromActions.GET_COUNTRIES_ERROR: {
      return {
        ...state,
        loading: false
      }
    }
    case fromActions.GET_EDUCATION: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_EDUCATION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        education: data,
        educationOptions: data.map(d => ({value: d.id, label: d.name}))
      };
    }
    case fromActions.GET_EDUCATION_ERROR: {
      return {
        ...state,
        loading: false
      }
    }
    case fromActions.GET_INDUSTRIES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_INDUSTRIES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        industries: data,
        industryOptions: data.map(d => ({value: d.id, label: d.name}))
      };
    }
    case fromActions.GET_INDUSTRIES_ERROR: {
      return {
        ...state,
        loading: false
      }
    }
    case fromActions.GET_CURRENCIES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_CURRENCIES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        currencies: data,
        currencyOptions: data.map(d => ({value: d.id, label: d.name}))
      };
    }
    case fromActions.GET_CURRENCIES_ERROR: {
      return {
        ...state,
        loading: false
      }
    }
    case fromActions.GET_CAREER_LEVELS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_CAREER_LEVELS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        careerLevels: data,
        careerLevelOptions: data.map(d => ({value: d.id, label: d.name}))
      };
    }
    case fromActions.GET_CAREER_LEVELS_ERROR: {
      return {
        ...state,
        loading: false
      }
    }
    case fromActions.GET_JOB_FAMILIES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.GET_JOB_FAMILIES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        jobFamilies: data,
        jobFamilyOptions: data.map(d => ({value: d.id, label: d.name}))
      };
    }
    case fromActions.GET_JOB_FAMILIES_ERROR: {
      return {
        ...state,
        loading: false
      }
    }
    default: {
      return state;
    }
  }
}
