import { createSelector } from '@reduxjs/toolkit';

export const selectVacancy = (state) => {
  return state.vacancy;
};


export const selectVacancyBaseInfo = createSelector(
  selectVacancy,
  (vacancy) => vacancy.baseInfo
)

export const selectVacancyCandidates = createSelector(
  selectVacancyBaseInfo,
  vacancyBaseInfo => vacancyBaseInfo.candidates || []
)

export const selectVacancyHasCandidates = createSelector(
  selectVacancyCandidates,
  (vacancyCandidates) => {
    return vacancyCandidates.length ? true : false;
  }
)

export const selectVacancyMatches = (state) => {
  return state.vacancy.matching;
};

export const selectVacancyProfile = (state) => {
  return state.vacancy.profile;
};
