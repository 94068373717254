// REACT, STYLE, STORIES & COMPONENT
import React from 'react';

// OTHER COMPONENTS
import LogoInside from './LogoInside';

// STORE
import { Provider } from 'react-redux';
import store from 'store';

// COMPONENT: Logo
const Logo = (props) => {
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Logo
  return (
    <Provider store={store}>
      <LogoInside { ...props } />
    </Provider>
  );
};

export default Logo;
