// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
import styles from './Breathe.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';
import {markdown} from 'utils/textTools';
import { sToNumber } from 'utils/styleTools';

// STORE

// CONFIG & DATA
const CONFIG = {
  extraEndWait: 300,
};


export const breatheText = `[blu-markdown]3x tief durchatmen.
Du hast es gleich geschafft!`;

// COMPONENT: Breathe
const Breathe = (props) => {

  // PROPS
  const {
    text,
    onSkip = () => {}
  } = props;


  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS
  useEffect(() => {
    const animationBreatheDuration = sToNumber(styles.animationBreatheDuration);
    const animationBreatheDelay = sToNumber(styles.animationBreatheDelay);
    const animationBreatheRepeat = Number(styles.animationBreatheRepeat);

    const autoCloseTime = CONFIG.extraEndWait
      + 1000 * (animationBreatheRepeat * animationBreatheDuration + animationBreatheDelay);

    const timerId = setTimeout(() => {
      onSkip();
    }, autoCloseTime);

    // cleanup timer
    return () => {
      clearTimeout(timerId);
    };
  }, [onSkip]);

  // STORE HOOKS

  // METHODS
  const handleClick = () => {
    onSkip();
  };

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: Breathe
  return (
    <div className={classNames(styles.breathe)}>

      {/* CONTENT */}
      <div className={styles.content}>

        {/* TITLE */}
        <div className={styles.title}>
          {text && markdown(text)}
          {!text && translate('assessment_interstitial')}
        </div>

        {/* CIRCLE */}
        <div className={styles.circle}></div>

        {/* SKIP */}
        <div
          className={styles.skip}
          onClick={handleClick}
        >
          {translate('assessment_skip') || 'Überspringen'}
        </div>

      </div>

    </div>
  );
};

export default Breathe;
