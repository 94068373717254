// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Job.module.scss';

// ASSETS
import { ReactComponent as Teaser } from './teaser.svg';


// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: Job
const Job = (props) => {
  // PROPS
  const {
    // onAnswer = () => {},
    controls = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Job
  return (
    <div className={classNames(styles.job)}>
      <div className={styles.content}>
        <div className={styles.teaser}>
          <Teaser />
        </div>
        <div className={styles.title}>
          { translate('onboarding_candidate_job_title') || 'Finde den Job der zu dir und deinen Stärken passt.'}
        </div>
        <div className={styles.text}>
          { translate('onboarding_candidate_job_text') || `
            Die über dich gewonnen Daten werden verwendet
            um deine individuellen Anforderungen und
            Fähigkeiten mit dem richtigen Job zu matchen.
          `}
        </div>
        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        )}
      </div>
    </div>
  );
};

export default Job;
