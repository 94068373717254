// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionName.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
const Config = {
  minLengthFirstName: 2,
  minLengthLastName: 2,
};


// COMPONENT: QuestionName
const QuestionName = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
  } = props;

  const translate = useTranslate();


  // COMPONENT/UI STATE and REFS
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');

  // EFFECTS: UPDATE BASED ON PROPS
  useEffect(() => {
    setFirstName(answer.firstName);
  }, [answer.firstName]);
  useEffect(() => {
    setLastName(answer.lastName);
  }, [answer.lastName]);

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleFirstName = (firstName) => {
    setFirstName(firstName);
    checkAnswers({firstName, lastName});
  };

  const handleLastName = (lastName) => {
    setLastName(lastName);
    checkAnswers({ firstName, lastName });
  };

  // HELPERS
  const checkAnswers = ({ firstName, lastName }) => {

    const isValid = firstName && lastName
      && firstName.length >= Config.minLengthFirstName
      && lastName.length >= Config.minLengthLastName;

    const answer = { firstName, lastName, isValid };

    onAnswer(answer);
  };

  // RENDERS

  // RENDER: QuestionName
  return (
    <div className={classNames(styles.questionName)}>

      {/* FORM */}
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={firstName}
          label={translate('cp_firstname_placeholder') || 'Dein Vorname'}
          onChange={handleFirstName}>
        </InputNext>
      </div>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={lastName}
          label={translate('cp_lastname_placeholder') || 'Dein Nachname'}
          onChange={handleLastName}>
        </InputNext>
      </div>

    </div>
  );
};

export default QuestionName;
