import React from 'react';
import styles from './LeadershipCompetenciesAssessmentEnd.module.scss';

// 3RD PARTY
import { useHistory } from 'react-router';

// STORE
import { useDispatch } from 'react-redux';
import { getAssessmentResult } from 'store/actions';

// OTHER COMPONENTS
import Button from 'ui/basic/forms/Button';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// CONFIG & DATA
import { getTranslationIds } from 'ui/molecules/AssessmentNext/AssessmentNext.translations';
import { ASSESSMENT_NEXT_TYPES } from 'ui/molecules/AssessmentNext/AssessmentNext.config';

const LeadershipCompetenciesAssessmentEnd = (props) => {
  const {
    finishable,
    handleFinish = () => {},
    errorEnd,
  } = props;

  const dispatch = useDispatch();
  const translate = useTranslate();
  const history = useHistory();

  const assessmentType = ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES;

  const translationIds = getTranslationIds(ASSESSMENT_NEXT_TYPES[assessmentType]);

  const handleViewResults = () => {
    dispatch(getAssessmentResult(assessmentType));
    handleFinish(() => history.push(`/my-profile?goTo=${assessmentType}`));
  }

  return (
    <div className={styles.assessmentEnd}>
      { errorEnd }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role={'img'} aria-label={'applause'}>&#128588;</span>

          <div className={styles.sTitle}>
            { translate(translationIds.successTitle) || 'You\'ve done it. Great!' }
          </div>

          <div className={styles.buttons}>
            <Button
              size={'M'}
              looks={'secondary'}
              disabled={!finishable}
              onClick={handleViewResults}
            >
              { translate('assessment_view_results') || 'Ergebnisse ansehen' }
            </Button>
          </div>
        </>
      )}

    </div>
  )
};

export default LeadershipCompetenciesAssessmentEnd;
