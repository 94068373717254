// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './QuickAccess.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import { BluCSSTransition } from 'ui/basic';
import QuickAccessMenu from './QuickAccessMenu';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE OLD
import { useSelector } from 'react-redux';
import { selectCapabilities } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuickAccess
const QuickAccess = () => {
  // PROPS

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  const capabilities = useSelector(selectCapabilities);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: QuickAccess
  return (
    <BluCSSTransition
      classNames={{ ...styles }}
      in={capabilities.employeesInvite || capabilities.rolesCreate || capabilities.teamsCreate}
    >
      <QuickAccessMenu />
    </BluCSSTransition>
  );
};

export default QuickAccess;
