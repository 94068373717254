// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionDegree.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDownSearchable } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import {useSelector} from 'react-redux';
import * as fromSelectors from 'store/selectors/staticValues';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionDegree
const QuestionDegree = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
   } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const educationOptions = useSelector(fromSelectors.selectEducationOptions);

  const [ degree, setDegree ] = useState('');
  useEffect(() => {
    setDegree(answer.degree);
  }, [answer.degree]);


  // EVENT HANDLES
  const handleDegree = (degree) => {
    setDegree(degree);
    onAnswer({ value: degree, name: 'degree', isValid: !!degree });
  };

  // RENDER: QuestionDegree
  return (
    <div className={classNames(styles.questionDegree)}>

      {/* FORM */}
      <div className={styles.formRow}>
        <DropDownSearchable
          size={size}
          placeholder={translate('please_select_lbl') || 'Bitte wählen'}
          filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
          activeValue={answer.value || ''}
          options={educationOptions}
          onChange={(option) => {
            const value = option?.value ?? '';
            if (value !== degree) {
              handleDegree(value);
            }
          }}
        />
      </div>

    </div>
  );
};

export default QuestionDegree;
