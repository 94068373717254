import { createSelector } from '@reduxjs/toolkit';

export const getCurrentUser = store => store.currentUser;

export const getCurrentUserId = (store) => {
  const currentUser = getCurrentUser(store);
  return currentUser && currentUser.id;
};

export const getFirstName = store => (
  (getCurrentUser(store) && getCurrentUser(store).firstName) ? getCurrentUser(store).firstName : ''
)

export const selectUserRoleNext = createSelector(
  getCurrentUser,
  user => user.roleNext
)

export const selectUserSubRoleNext = createSelector(
  getCurrentUser,
  user => user.subRoleNext
)

export const selectTeamsForTeamLeader = createSelector(
  getCurrentUser,
  user => user.teamsForTeamLeader
)

export const selectUserProfileCompletedNext = createSelector(
  getCurrentUser,
  selectUserRoleNext,
  (user, userRoleNext) => user.firstName || userRoleNext === 'admin' || userRoleNext === 'bluquistAdmin' ? true : false
)

export const selectUserIsBasicDataReady = createSelector(
  getCurrentUser,
  currentUser => currentUser && currentUser.id && currentUser.mail && currentUser.role && currentUser.roleNext
)


export const getLastName = store => (
  (getCurrentUser(store) && getCurrentUser(store).lastName) ? getCurrentUser(store).lastName : ''
)

export const getEmail = store => (
  getCurrentUser(store) ? getCurrentUser(store).mail : ''
)

export const getCompany = store => (
  getCurrentUser(store) ? getCurrentUser(store).company : ''
)

export const getUserPicture = store => (
  getCurrentUser(store) ? getCurrentUser(store).picture : ''
)

export const getProfession = store => {
  const currentUser = getCurrentUser(store);
  return (currentUser && currentUser.companyData) ? currentUser.companyData.profession : ''
}

export const getCareerLevel = store => {
  const currentUser = getCurrentUser(store);
  return (currentUser && currentUser.companyData) ? currentUser.companyData.careerLevel : ''
}

export const getCompanyEntryDate = store => {
  const currentUser = getCurrentUser(store);
  return (currentUser && currentUser.companyData) ? currentUser.companyData.companyEntryDate : ''
}

export const getIsUserTeamLeader = store => {
  const teamId = store.teamOverview?.team?.id;
  const teamsForTeamLeader = store.currentUser?.teamsForTeamLeader;
  if (teamId === undefined || teamsForTeamLeader === undefined) {
    return null;
  }
  return teamId && teamsForTeamLeader.includes(teamId);
}
