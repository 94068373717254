// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './StandaloneHeader.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

// OTHER COMPONENTS
import Logo from '../../Logo';
import { DropDownSubtle } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import * as localStorage from 'utils/localStorage';

// STORE OLD
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from 'store/actions';
import { IS_MAINTENANCE_MODE } from 'utils/configuration';

// CONFIG & DATA
// const Config = {};
const LANGUAGES = {
  de_DE: 'Deutsch',
  en_US: 'English'
};

// COMPONENT: StandloneHeader
const StandaloneHeader = (props) => {
  // PROPS

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();
  const location = useLocation();

  // LANGUAGE SWITCH
  const allLanguages = useSelector((state) => {
    return (state.localisation && state.localisation.languages) ? state.localisation.languages : [];
  });
  const [ languageOptions, setLanguageOptions ] = useState([]);
  useEffect(() => {
    const languageOptions = allLanguages.map((languageId) => ({
      value: languageId.split('_')[0].toUpperCase(), // EN
      label: LANGUAGES[languageId] // English
    }));
    setLanguageOptions(languageOptions);
  }, [allLanguages]);
  const currentLanguage = useSelector(state => state.localisation && state.localisation.currentLanguage);
  const handleLanguageChange = (index) => {
    localStorage.setItem('overrideLanguage', true);
    dispatch(changeLanguage({language: allLanguages[index]}));
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const getLink = () => {
    {/* Restore after merge with BQP-775 */}
    {/* TODO: Remove 2nd condition on next line */}
    if (IS_MAINTENANCE_MODE || location.pathname === '/nonexistent-instance') {
      return undefined;
    }
    if (location.pathname === '/nonexistent-instance') {
      return 'https://app.bluquist.net/login';
    }
    return '/';
  }

  // RENDER: StandloneHeader
  return (
    <div className={classNames(styles.standaloneHeader)}>
      <div className={styles.left}>
        <Logo
          size='L'
          compensatePadding
          link={getLink()}
        />
      </div>
      <div className={styles.right}>
        <DropDownSubtle
          className='alignRight'
          options={languageOptions}
          activeIndex={allLanguages.indexOf(currentLanguage)}
          onChange={(index) => {
            handleLanguageChange(index);
          }}>
          {translate('langswitch_popover_label') || 'Choose language'}
        </DropDownSubtle>
      </div>
    </div>
  );
};

export default StandaloneHeader;
