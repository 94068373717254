import * as fromActionTypes from 'store/actionTypes/employeeOverview';

export const getEmployees = (requestDto) => {
  return {
    type: fromActionTypes.GET_EMPLOYEES,
    payload: {requestDto}
  };
};

export const getEmployeesFulfilled = ({ employees, totalCount}) => {
  return {
    type: fromActionTypes.GET_EMPLOYEES_FULFILLED,
    payload: { employees, totalCount }
  };
};

export const getEmployeesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_EMPLOYEES_REJECTED,
    payload: { error }
  };
};

export const initEmployee = () => {
  return {
    type: fromActionTypes.INIT_EMPLOYEE,
    payload: {}
  };
};

export const getEmployee = (employeeId) => {
  return {
    type: fromActionTypes.GET_EMPLOYEE,
    payload: {employeeId}
  };
};

export const getEmployeeFulfilled = ({ employee }) => {
  return {
    type: fromActionTypes.GET_EMPLOYEE_FULFILLED,
    payload: { employee }
  };
};

export const getEmployeeRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_EMPLOYEE_REJECTED,
    payload: { error }
  };
};

