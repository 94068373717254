import { createSlice, createSelector } from '@reduxjs/toolkit';

// UTILS
import * as api from 'api';
import { COMPANY_ID, IS_ADMIN_FRONTEND, CONFIGURATION, SUB_CONFIGURATION } from 'utils/configuration';
import { CAPABILITIES_SWITCHES } from 'utils/configuration/const/capabilities';
import getAllConfiguredRoutes, {
  applyStandaloneRoutesFilter,
  applyModalRoutesFilter,
  applyMainNavRoutesFilter,
  applyNestedRoutesFilter,
} from 'features/framework/utils/routeNext';


const initialState = {
  initialised: false,
  initialisedExtended: false,
  loading: false,
  loadingBackground: false,
  loadingExtended: false,
  loadingExtendedBackground: false,
  loadingExternal: true,
  loggingIn: false,
  error: null,


  instance: {
    flavor: 'bluquist', // 'bluquist', 'adminFrontend', 'balancedYou'
    subFlavor: '', // 'mindfulnessJourney' (but only with flavor = 'balancedYou')
  },
  company: {
    // tbd:
    companyId: '',
    customLogoUrl: '',
    hasExternalLegalAgreements: false,

    // api spec:
    // "invitationDomainLimit": "string",
    // "openRegistrationDomains": [
    // null
    // ],
    // "wellbeingMails": true,
    // "assessmentReminderMails": true,
    // "externalDataProcessingContext": true,
    // "custom": { }
  },
  token: {
    id: '',
    expiration: ''
  },
  settings: {
    language: 'en_US',
    translations: {},
  },
  user: {
    userId: '',
    userGroup: '', // jobShare, profileShare, candidate, employee, leader, admin, bluquistAdmin
    profileCompleted: false,
    firstName: '',
    lastName: '',
  },
  features: {
    employees: false,
    candidates: false,
    roles: false,
    teams: false,
    wellbeing: false,
    balancedYou: false,
  },
  assessments: {
    balancedYou: false,
    big5: false,
    keyComp: false,
    leadComp: false,
    potential: false,
    workPref: false,
    nineLevels: false,
    rmp: false,
  },
  capabilities: CAPABILITIES_SWITCHES.reduce((acc, el) => {
    acc[el] = false;
    return acc; 
  }, {}),
  stateExtras: {
    vacanyHasCandidates: false,
    candidateHasRoleFitPage: false,
  }
}

// selectors
export const selectConfiguration = state => state.configuration;
export const selectLoading = createSelector(
  selectConfiguration,
  (configuration) => configuration.loading
)
export const selectInitialised = createSelector(
  selectConfiguration,
  (configuration) => configuration.initialised
)
export const selectInitialisedExtended = createSelector(
  selectConfiguration,
  (configuration) => configuration.initialisedExtended
)
export const selectLoadingExternal = createSelector(
  selectConfiguration,
  configuration => configuration.loadingExternal
)
export const selectLoggingIn = createSelector(
  selectConfiguration,
  (configuration) => configuration.loggingIn
)
// instance selectors
export const selectInstance = createSelector(
  selectConfiguration,
  (configuration) => configuration.instance
)
export const selectInstanceIsAdminFrontend = createSelector(
  selectInstance,
  (instance) => instance.flavor === 'adminFrontend'
)
export const selectInstanceFlavor = createSelector(
  selectInstance,
  (instance) => instance.flavor
)
export const selectInstanceSubFlavor = createSelector(
  selectInstance,
  (instance) => instance.subFlavor
)
// company selectors
export const selectCompany = createSelector(
  selectConfiguration,
  (configuration) => configuration.company
)
// plan selectors
export const selectPlan = createSelector(
  selectConfiguration,
  (configuration) => configuration.plan || {} // mock for 21.9 and 22.0 release
)
export const selectPlanType = createSelector(
  selectPlan,
  (plan) => plan.type
)
export const selectPlanRestricted = createSelector(
  selectPlanType,
  type => type === 'restricted'
)
// token selectors
const selectToken = createSelector(
  selectConfiguration,
  (configuration) => configuration.token
)
export const selectIsLoggedIn = createSelector(
  selectToken,
  (token) => token.id && token.expiration
)
export const selectInstanceIsNonexistent = createSelector(
  selectConfiguration,
  (configuration) => Boolean(configuration?.error?.nonexistent),
)
const selectSettings = createSelector(
  selectConfiguration,
  (configuration) => configuration.settings
);
export const selectSettingsLanguage = createSelector(
  selectSettings,
  settings => settings.language
)
const selectSettingsTranslations = createSelector(
  selectSettings,
  settings => settings.translations
)
export const selectActiveTranslations = createSelector(
  selectSettingsLanguage,
  selectSettingsTranslations,
  (language, translations) => {
    return translations[language];
  }
)
// user selectors
const selectUser = createSelector(
  selectConfiguration,
  configuration => configuration.user
)
export const selectUserProfileCompleted = createSelector(
  selectUser,
  (user) => user.profileCompleted
)
// user: userGroup
export const selectUserGroup = createSelector(
  selectUser,
  (user) => user.userGroup
)
export const selectUserIsAdmin = createSelector(
  selectUser,
  user => user.userGroup === 'admin'
)
export const selectUserFirstName = createSelector(
  selectUser,
  user => user.firstName
)
export const selectUserLastName = createSelector(
  selectUser,
  user => user.lastName
)
// features selectors
export const selectFeatures = createSelector(
  selectConfiguration,
  (configuration) => configuration.features
)
const isFeatureActive = (feature) => feature ? true : false;
export const selectFeature = createSelector(
  [
    selectFeatures,
    (state, featureId) => featureId
  ],
  (features, featureId) => isFeatureActive(features[featureId])
)
// assessments selectors
export const selectAssessments = createSelector(
  selectConfiguration,
  (configuration) => configuration.assessments
)
export const selectAssessment = createSelector(
  [
    selectAssessments,
    (state, assessmentId) => assessmentId
  ],
  (assessments, assessmentId) => isFeatureActive(assessments[assessmentId])
)
// capabilities selectors
export const selectCapabilities = createSelector(
  selectConfiguration,
  configuration => configuration.capabilities
)
const isCapabilityValid = (capability) => capability ? true : false;
export const selectCapability = createSelector(
  [
    selectCapabilities,
    (state, capability) => capability
  ],
  (capabilities, capability) => isCapabilityValid(capabilities[capability]),
)

// state extras
export const selectStateExtras = createSelector(
  selectConfiguration,
  (configuration) => configuration.stateExtras
)
// routing selectors
export const selectRoutesFromConfiguration = (configuration) => {

  // routes should only be based on configuration
  // but we pass in a fake state object so we can use selectors
  const allRoutes = getAllConfiguredRoutes({ configuration });
  const standaloneRoutes = applyStandaloneRoutesFilter(allRoutes);
  const mainNavRoutes = applyMainNavRoutesFilter(allRoutes);
  const modalRoutes = applyModalRoutesFilter(allRoutes);
  const nestedRoutes = applyNestedRoutesFilter(allRoutes);

  return {
    allRoutes,
    standaloneRoutes,
    mainNavRoutes,
    modalRoutes,
    nestedRoutes
  };
}
// todo: memoize this selector
export const selectRoutes = createSelector(
  selectConfiguration,
  selectRoutesFromConfiguration
);

export const selectRoutesFromConfigurationAndStateExtras = (configuration, stateExtras) => {

  // routes should only be based on configuration
  // but we pass in a fake state object so we can use selectors
  const allRoutes = getAllConfiguredRoutes({ configuration }, stateExtras);
  const standaloneRoutes = applyStandaloneRoutesFilter(allRoutes);
  const mainNavRoutes = applyMainNavRoutesFilter(allRoutes);
  const modalRoutes = applyModalRoutesFilter(allRoutes);
  const nestedRoutes = applyNestedRoutesFilter(allRoutes);

  return {
    allRoutes,
    standaloneRoutes,
    mainNavRoutes,
    modalRoutes,
    nestedRoutes
  };
}

export const selectRoutesWithStateExtras = createSelector(
  selectConfiguration,
  selectStateExtras,
  selectRoutesFromConfigurationAndStateExtras
)



// basic thunk
export const fetchConfiguration = () => async (dispatch, getState) => {
  const loading = selectLoading(getState());

  // prevent repeated calls
  if (loading) return;

  // for admin front-end it is enough to have a flavor within 'instance'
  // no need to fetch the company settings and logo
  if (IS_ADMIN_FRONTEND) {
    return dispatch(configurationReceived({
      instance: {
        flavor: 'adminFrontend'
      }
    }));
  }

  const promises = [
    api.get(`core/company/settings?company=${COMPANY_ID}`),
    api.get(`core/company/${COMPANY_ID}/logo`),
  ];

  // If token is missing, also check whether company exists
  if (!localStorage.getItem('token')) {
    promises.push(api.get(`core/company/exists/${COMPANY_ID}`));
  }

  dispatch(configurationLoading());
  Promise.all(promises)
  .then(([ companySettings, customLogo, companyExists ]) => {
    if (companyExists?.data?.exists === false) {
      return dispatch(configurationFailed({
        ok: false,
        nonexistent: true,
        problem: 'Company does not exist',
      }));
    }

    if (companySettings.ok) {
      const companyId = COMPANY_ID;
      const customLogoUrl = customLogo.ok && api.getCustomLogoUrl();
      const hasExternalLegalAgreements = companySettings.data.externalDataProcessingContext;
      let flavor = 'bluquist';
      let subFlavor = '';
      if (CONFIGURATION === 'BALANCED_YOU') flavor = 'balancedYou';
      if (SUB_CONFIGURATION === 'MINDFULNESS_JOURNEY') subFlavor = 'mindfulnessJourney';
      const instance = {
        flavor,
        subFlavor
      };
      const company = {
        ...companySettings.data,
        companyId,
        customLogoUrl,
        hasExternalLegalAgreements,
      };
      return dispatch(configurationReceived({ instance, company }));
    }
    else {
      const { ok, status, data, problem } = companySettings;
      return dispatch(configurationFailed({ ok, status, data, problem }));
    }
  })
};


export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    configurationLoading: (state, action) => {
      state.loading = true;
    },
    configurationReceived: (state, action) => {
      const { instance, company } = action.payload;

      state.loading = false;
      state.initialised = true;

      state.instance = {
        ...state.instance,
        ...instance,
      };
      state.company = {
        ...state.company,
        ...company,
      };
    },
    configurationFailed: (state, action) => {
      const error = action.payload;

      state.loading = false;
      state.initialised = false;
      state.error = error;
    },
    login: (state, action) => {
      const {
        tokenId, tokenExpiration,
        userId, userGroup,
        profileCompleted,
        userFirstName, userLastName,
      } = action.payload
      state.token = {
        id: tokenId,
        expiration: tokenExpiration
      }
      state.user.userId = userId
      state.user.userGroup = userGroup
      state.user.profileCompleted = profileCompleted
      state.user.firstName = userFirstName
      state.user.lastName = userLastName
    },
    logout: (state) => initialState,
    reset: (state) => initialState,
    setInitialisedExtended: (state, action) => {
      state.initialisedExtended = action.payload;
    },
    setLoadingExternal: (state, action) => {
      state.loadingExternal = action.payload;
    },
    setProductFlavor: (state, action) => {
      const flavor = action.payload;
      state.instance.flavor = flavor;
    },
    setProductSubFlavor: (state, action) => {
      const subFlavor = action.payload;
      state.instance.subFlavor = subFlavor;
    },
    setUserGroup: (state, action) => {
      const userGroup = action.payload;
      state.user.userGroup = userGroup;
    },

    setCapabilities: (state, action) => {
      const capabilities = action.payload;
      state.capabilities = capabilities;
    },
    setFeatures: (state, action) => {
      const features = action.payload;
      state.features = features;
    },
    setAssessments: (state, action) => {
      const assessments = action.payload;
      state.assessments = assessments;
    },
    setStateExtras: (state, action) => {
      const stateExtras = action.payload;
      state.stateExtras = stateExtras;
    }
  },
})

export default configurationSlice.reducer


export const {
  reset,
  configurationLoading, configurationReceived, configurationFailed,
  login, logout, setInitialisedExtended, setLoadingExternal,
  setProductFlavor, setFeatures, setAssessments,
  setUserGroup, setCapabilities,
  setStateExtras
} = configurationSlice.actions
