import * as actiontype from 'store/actions';

import * as signupActionTypes from 'store/actionTypes/signUp';

const initialState = {
  authToken: null,
  status: 'invalid',
  processing: false,
  error: null
};

export default (state=initialState, action) => {
  switch (action.type) {
    case actiontype.LOGIN:
    return {
        ...state,
        processing: true
      };
    case actiontype.LOGOUT_FULFILLED:
    return {
        ...state,
        status:'invalid',
        processing: false
      };
    case actiontype.LOGIN_REJECTED:
    case actiontype.GET_CURRENT_USER_REJECTED:
      return {
        ...state,
        status:'invalid',
        processing: false,
        error:action.payload
      };
    case actiontype.LOGIN_FULFILLED:
    case signupActionTypes.SIGN_UP_FULFILLED :
      let { token } = action.payload;
      token = token || state.authToken;

      return {
        ...state,
        authToken: token,
        status:'valid',
        error: null,
        processing: false
      };
    case actiontype.AUTH_TOKEN_FETCHED:
      const {authToken} = action.payload;

      return {
        ...state,
        authToken,
        error: null,
        processing: false
      };
    default:
      return state;
  }
};
