// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Data.module.scss';

// ASSETS
import { ReactComponent as Teaser } from './teaser.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: Data
const Data = (props) => {
  // PROPS
  const {
    controls = () => {}
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Data
  return (
    <div className={classNames(styles.data)}>
      <div className={styles.content}>
        <div className={styles.teaser}>
          <Teaser />
        </div>
        <div className={styles.title}>
          { translate('onboarding_candidate_data_title') || 'Deine Daten sind geschützt und jederzeit unter deiner Kontrolle.'}


        </div>
        <div className={styles.text}>
          { translate('onboarding_candidate_data_text') || `
            Die Verarbeitung deiner persönlichen Daten
            erfolgt DSGVO-konform und auf deutschen Servern.
            Alle über dich erhobenen Daten sind für dich einsehbar.
            Dabei behältst du jederzeit die Kontrolle über deine
            Daten und kannst dein Profil einfach
            und unkompliziert löschen.
          `}

        </div>
        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        )}
      </div>
    </div>
  );
};

export default Data;
