import * as fromActions from 'store/actions/routing';

const initialState = {
  isNextRouteFromMenu: false,
  history: [
    // {
    //   fromMenu: true,
    //   location: {}
    // }
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fromActions.ENABLE_NEXT_ROUTE_FROM_MENU: {
      return {
        ...state,
      };
    }
    case fromActions.SET_NEXT_ROUTE: {
      const route = action.payload;
      const history = [...state.history, {
        fromMenu: state.isNextRouteFromMenu,
        location: route
      }];
      return {
        ...state,
        history
      }
    }
    default: {
      return state;
    }
  }
}
