// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useState} from 'react';
import styles from './HotChips.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group'

// OTHER COMPONENTS
import { InputNext, Chip, BluCSSTransition } from 'ui/basic';

// UTILS

// CONFIG & DATA

// COMPONENT: HotChips
const HotChips = (props) => {
  // PROPS
  const {
    type = 'text',
    values,
    label, placeholder,
    hint, errorHint, errorHintDuplicate, confirmHint,
    validate, onUpdate,
  } = props;

  // COMPONENT/UI STATE and REFS
  const [ list, setList ] = useState(values || []);
  const [ error, setError ] = useState(null);
  const [ disabled, setDisabled ] = useState(true);
  const [ focussed, setFocussed ] = useState(false);

  // STORE HOOKS

  // EFFECT HOOKS
  useEffect(() => {
    if (values && values.length > 0) {
      setList(values);
    }
  }, [values]);

  // OTHER HOOKS

  // METHODS
  const addItem = (item) => {
    // prevent duplicates
    if (list.indexOf(item) !== -1) {
      setError({ duplicate: true })
      return;
    }
    list.unshift(item); // add at the beginning for more dynamic feel on mobile
    const newList = [...list];
    setList(newList);
    onUpdate && onUpdate(newList);
    validateLength(newList);
  };
  const removeItem = (item) => {
    const newList = list.filter((listItem) => listItem !== item);
    setList(newList);
    onUpdate && onUpdate(newList);
    validateLength(newList);
  };

  // HELPERS
  const validateLength = (list) => {
    if (!list.length) {
      setError({ empty: true });
    }
    else {
      setError(null);
    }
  };

  // HANDLES
  const handleFocus = () => {
    setFocussed(true);
  };
  const handleBlur = () => {
    validateLength(list);
    setFocussed(false);
  };

  // RENDERS


  // RENDER: HotChips
  return (
    <div className={`${styles.hotChips}`}>
      {label && <div className={styles.label}>
        { label }
      </div>}

      {/* INPUT */}
      <InputNext
        type={type}
        placeholder={placeholder}
        validate={{
          ...(validate || {})
        }}
        onConfirm={(item) => {
          addItem(item);
        }}
        onError={(error) => {
          setError(error);
          handleBlur();
        }}
        onChange={(value, error) => {
          if (error && error.duplicate) {
            setError(null);
          }
          const disabled = error ? true : false;
          setDisabled(disabled);
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        bigButton
        bigButtonDisabled={disabled}
      />

      {/* FLYOUT */}
      { confirmHint && focussed && (
        <div className={classNames(styles.flyoutContainer, {
          [styles.hide]: !focussed
        })}>
          <div className={styles.flyout}>
            { confirmHint }
          </div>
        </div>
      )}

      {/* HINT */}
      {(hint || error) && <div className={classNames(styles.hint, {
        [styles.error]: error
      })}>
        { ((error && error.duplicate && errorHintDuplicate) || (error && errorHint)) || hint }
      </div>}


      {/* CHIPS */}
      <div className={styles.chips}>
        <TransitionGroup>
          { list.map((item) => {

            const label = typeof item === 'string'
              ? item
              : item.label;

            return (
              // key=item because key=index scrambles the animations when removing items
              <BluCSSTransition key={item}
                classNames={{ ...styles }}>
                <Chip onClick={() => {
                    removeItem(item);
                  }}>{label}</Chip>
              </BluCSSTransition>
            );
          })}
        </TransitionGroup>
      </div>

    </div>
  );
};

export default HotChips;
