// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './QuestionExplanatory.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionExplanatory
const QuestionExplanatory = (props) => {
  // PROPS
  const {
    question,
    clickBlock,
    onAnswer
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleButtonClick = () => {
    if (clickBlock) return;
    onAnswer();
  };
  // RENDER: QuestionExplanatory
  return (
    <div className={classNames(styles.questionExplanatory)}>
      <div className={styles.grid}>
        <div className={styles.gridContent}>

          {/* QUESTION */}
          <div className={classNames(styles.formRow, styles.center)}>
            <h5>{ translate(question.question) || question.question }</h5>
          </div>
          {/* DESCRIPTION */}
          <div className={classNames(styles.formRow, styles.center, 'blu-copyStrong')}>
            { translate(question.description) || question.description }
          </div>
          {/* BUTTON */}
          <div className={classNames(styles.formRow, styles.center)}>
            <Button looks='primary' onClick={handleButtonClick}>
              {translate('continue_lbl')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionExplanatory;
