// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
// import styles from './LoadConfiguration.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfiguration, selectCompany, selectInitialised, selectInstance } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: LoadConfiguration
const LoadConfiguration = (props) => {
  // PROPS
  const {
    onConfiguration
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();
  const initialised = useSelector(selectInitialised);
  const company = useSelector(selectCompany)
  const instance = useSelector(selectInstance)

  const [ companyInternal, setCompanyInternal ] = useState();
  const [ instanceInternal, setInstanceInternal ] = useState();

  useEffect(() => {
    dispatch(fetchConfiguration());
  }, [dispatch])
  useEffect(() => {

    // not sure why this is called three times without checking if it's the same
    if (initialised && onConfiguration && (companyInternal !== company || instanceInternal !== instance)) {
      setCompanyInternal(company);
      setInstanceInternal(instance);
      onConfiguration({ company, instance });
    }
  }, [onConfiguration, initialised, company, instance, companyInternal, instanceInternal])

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: LoadConfiguration
  return (<></>);
};

export default LoadConfiguration;
