// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './NineLevelsEnd.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// STORE
import { useDispatch } from 'react-redux';
import { getAssessmentResult } from 'store/actions';

// CONFIG & DATA
import { getTranslationIds } from 'ui/molecules/AssessmentNext/AssessmentNext.translations';


// COMPONENT: NineLevelsEnd
const NineLevelsEnd = (props) => {
  // PROPS
  const {
    state,
    extras,
   } = props;

  // COMPONENT/UI STATE and REFS

  const { loadingDuring, handleFinish, errorEnd } = extras;
  const translationIds = getTranslationIds('nineLevels');


  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();
  const history = useHistory();


  // EFFECT HOOKS
  const [ request, setRequest ] = useState();
  useEffect(() => {
    if (!state || !extras || request) return;

    // Note: disabled sending answers here since we send them on every answer now (as of v21.7)
    // const prevStageNumber = state.assessmentDetails.stages.length;
    // const pages = state.pages;
    // const answers = state.answers;

    const setLoadingDuring = extras.setLoadingDuring;
    const allowFinish = extras.allowFinish;

    // console.log('send answers');

    setLoadingDuring(true);


    // const prevStageAnswers = pages
    // .filter(page => page.stageNumber === prevStageNumber)
    // .map((page) => page.id)
    // .map(questionId => ({
    //   question: questionId,
    //   content: answers[questionId]
    // }));

    // const requestData = {
    //   answers: prevStageAnswers
    // };

    const answersRequest = api
    // send answers
    // .post(`/core/assessments/9levels/${prevStageNumber}/answers`, requestData)
    .get('core/user/heartbeat')
    .then(({ ok, status }) => {
      setTimeout(() => {
        setLoadingDuring(false);
      }, 3000); // wait till page change animation has completed
      allowFinish();
    })
    .catch((error) => {
      setLoadingDuring(false);
      console.error(error.message);
    });

    setRequest(answersRequest);

  }, [state, extras, request]);

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleReport = () => {
    dispatch(getAssessmentResult(ASSESSMENT_TYPES.NINE_LEVELS));
    handleFinish(() => history.push('/my-profile?openReportFor=9levels'))
  };

  // HELPERS

  // RENDERS

  // RENDER: NineLevelsEnd
  return (
    <div className={classNames(styles.nineLevelsEnd)}>
      { errorEnd }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role={'img'} aria-label={'applause'}>&#128588;</span>

          <div className={styles.sTitle}>
            { translate(translationIds.successTitle) || 'You\'ve done it. Great!' }
          </div>

          <div className={styles.buttons}>
            <Button
              size={'M'}
              disabled={loadingDuring}
              looks={'secondary'}
              onClick={handleReport}
            >
              { translate('big5_results_button_showreport') || 'Report ansehen' }
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NineLevelsEnd;
