import React, { useEffect, useState } from 'react';
import styles from './ImgCircle.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { getInitials } from 'utils/textTools';

const ImgCircle = (props) => {
  const {
    className = '',
    src, // string | Promise
    label1 = '',
    label2 = '',
    fullLabel = '',
    size
  } = props;

  // LABEL
  const [ label, setLabel ] = useState('');
  useEffect(() => {
    if (label1 || label2) {
      const initials = getInitials(`${label1 || ''} ${label2 || ''}`);
      setLabel(initials);
    }
  }, [label1, label2]);
  useEffect(() => {
    if (fullLabel) {
      const initials = getInitials(fullLabel);
      setLabel(initials);
    }
  }, [fullLabel]);

  // IMG HANDLING

  const [ imgSrc, setImgSrc ] = useState();
  const [ showImage, setShowImage ] = useState(false);
  const [ loading, setLoading ] = useState(src ? true : false); // false when no src is set

  useEffect(() => {
    const srcType = typeof src;
    if (srcType === 'string') {
      setImgSrc(src);
    }
    if (src !== null && srcType === 'object') {
      src
      .then(response => response.status === 200 && response.data)
      .then((imgBase64) => {
        if (imgBase64) {
          setImgSrc(imgBase64);
          handleSuccess();
        }
        else {
          handleError();
        }
      })
      .catch(() => {
        handleError();
      });
    }
  }, [src])

  // HANDLES
  const handleSuccess = () => {
    setLoading(false);
    setShowImage(true);
  };

  const handleError = () => {
    setLoading(false);
    setShowImage(false);
  };

  // RENDER
  return (
    <div className={classNames(styles.imgCircle, {
      [styles[`size${size}`]]: size,
      [styles.noBorder]: className.includes('noBorder'),
      [styles.noBackground]: className.includes('noBackground'),
      [styles.fontDarker]: className.includes('fontDarker'),
      [styles.darker]: className.includes('darker'),
      [styles.hasHover]: className.includes('hasHover'),
      [styles.loading]: loading || className.includes('loading'),

    })}>

      {/* IMAGE */}
      <div className={classNames(styles.image, {
        [styles.show]: showImage
      })}>
        <img
          src={imgSrc}
          alt={`${label1} ${label2} portrait`}
          onLoad={handleSuccess}
          onError={handleError}
        />
      </div>

      {/* INITIALS */}
      <div>
        { label.toUpperCase() }
      </div>
    </div>
  )
}

export default ImgCircle;
