// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import styles from './QuestionIndustry.module.scss';

// ASSETS
import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDownSearchable, Button, InputNext } from 'ui/basic';

// UTIL
import { useTranslate } from 'utils/translator';
import {EXPERIENCE_OPTIONS} from 'utils/configuration/const/recruiting';

// STORE
import {useSelector} from 'react-redux';
import * as fromSelectors from 'store/selectors/staticValues';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionIndustry
const QuestionIndustry = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = [{}],
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const TrashIcon = IconsSvg.Trash;
  const propsAnswer = answer?.value ?? answer;
  const industryOptions = useSelector(fromSelectors.selectIndustryOptions);
  const [ answerInternal, setAnswerInternal ] = useState(propsAnswer);
  const onAnswerCb = useCallback(onAnswer, []);

  // Use onAnswer cb
  const jsonPropsAnswer = JSON.stringify(propsAnswer);
  useEffect(() => {
    if (JSON.stringify(answerInternal) === jsonPropsAnswer) {
      return;
    }

    onAnswerCb({
      value: answerInternal,
      name: 'industry',
      isValid: answerInternal.every((industry) => industry.name && industry.experience),
    })
  }, [ onAnswerCb, answerInternal, jsonPropsAnswer ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

  const setValue = (index, value, key) => {
    const newAnswerInternal = deepClone(answerInternal);
    newAnswerInternal[index][key] = value;
    setAnswerInternal(newAnswerInternal);
  };

  const toggleCustom = (index) => {
    const newAnswerInternal = deepClone(answerInternal);
    newAnswerInternal[index].custom = !newAnswerInternal[index].custom;
    newAnswerInternal[index].name = '';
    setAnswerInternal(newAnswerInternal);
  };

  const lastIndex = answerInternal.length - 1;

  // RENDER: QuestionIndustry
  return (
    <div className={classNames(styles.questionIndustry)}>

      {/* FORM */}
      { answerInternal.map((industry, index) => {
        return (
          <React.Fragment key={index}>

            {/* INDUSTRY NAME */}
            <div className={classNames(styles.formRow)}>
              { !industry.custom && (
                <DropDownSearchable
                  size={size}
                  placeholder={translate('cp_industry_placeholder') || 'Branche waehlen'}
                  filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
                  activeValue={industry.name}
                  options={industryOptions}
                  onChange={(option) => setValue(index, option?.value ?? '', 'name')}
                />
              )}

              {industry.custom && (
                index === 0 ? (
                  <InputNext
                    size={size}
                    placeholder={translate('cp_industry_input_placeholder') || 'Name der Branche'}
                    value={industry.name}
                    onChange={(value) => setValue(index, value, 'name')}
                  />
                ) : (
                  <div className={styles.customInput}>
                    <InputNext
                      size={size}
                      placeholder={translate('cp_industry_input_placeholder') || 'Name der Branche'}
                      value={industry.name}
                      onChange={(value) => setValue(index, value, 'name')}
                    />
                    <div
                      className={styles.deleteInput}
                      onClick={() => {
                        const newAnswerInternal = deepClone(answerInternal);
                        newAnswerInternal.splice(index, 1);
                        setAnswerInternal(newAnswerInternal);
                      }}
                    >
                      <TrashIcon />
                    </div>
                  </div>
                )
              )}
            </div>

            {/* EXPERIENCE */}
            <div className={classNames(
              styles.formRow,
              {[styles.notFullWidth]: industry.custom && index > 0}
            )}>
              <DropDownSearchable
                size={size}
                placeholder={translate('cp_experience_placeholder') || 'Erfahrung'}
                filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
                activeValue={(industry.experience) || ''}
                options={EXPERIENCE_OPTIONS.map(experienceOption => ({
                  value: experienceOption.value,
                  label: translate(`${experienceOption.value}_label`) || experienceOption.label
                }))}
                onChange={(option) => setValue(index, option?.value ?? '', 'experience')}
              />
            </div>
            <div className={styles.biggerMargin}></div>

          </React.Fragment>
        )
        }
      )}

      <div className={classNames(styles.formRow, styles.biggerMargin, styles.center)}>
        <Button
          looks='tertiary'
          size={size === 'responsive' ? 'L' : 'S'}
          leadingIcon={IconsSvg.Plus}
          onClick={() => setAnswerInternal([ ...deepClone(answerInternal), {} ])}
        >
          {translate('cp_industry_experience_add_btn') || 'Weitere Branche hinzufügen'}
        </Button>
      </div>
      <div className={classNames(styles.formRow, styles.bigMargin, styles.center)}>
        <Button
          looks='tertiary'
          size={size === 'responsive' ? 'L' : 'S'}
          onClick={() => toggleCustom(lastIndex)}>
            { answerInternal[lastIndex].custom 
              ? translate('cp_industry_experience_choose_from_list')
              : translate('cp_industry_experience_not_found')
            }
          </Button>
      </div>

    </div>
  );
};

export default QuestionIndustry;
