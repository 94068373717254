export const GET_COUNTRIES = '[STATIC] GET_COUNTRIES';
export const getCountries = () => {
  return {
    type: GET_COUNTRIES,
    payload: {}
  };
};

export const GET_COUNTRIES_SUCCESS = '[STATIC] GET_COUNTRIES_SUCCESS';
export const getCountriesSuccess = ({data}) => {
  return {
    type: GET_COUNTRIES_SUCCESS,
    payload: {data}
  };
};

export const GET_COUNTRIES_ERROR = '[STATIC] GET_COUNTRIES_ERROR';
export const getCountriesError = ({ error }) => {
  return {
    type: GET_COUNTRIES_ERROR,
    payload: { error }
  };
};

// GET EDUCATION
export const GET_EDUCATION = '[STATIC] GET_EDUCATION';
export const getEducation = () => {
  return {
    type: GET_EDUCATION,
    payload: {}
  };
};

export const GET_EDUCATION_SUCCESS = '[STATIC] GET_EDUCATION_SUCCESS';
export const getEducationSuccess = ({data}) => {
  return {
    type: GET_EDUCATION_SUCCESS,
    payload: {data}
  };
};

export const GET_EDUCATION_ERROR = '[STATIC] GET_EDUCATION_ERROR';
export const getEducationError = ({ error }) => {
  return {
    type: GET_EDUCATION_ERROR,
    payload: { error }
  };
};

// GET INDUSTRIES
export const GET_INDUSTRIES = '[STATIC] GET_INDUSTRIES';
export const getIndustries = () => {
  return {
    type: GET_INDUSTRIES,
    payload: {}
  };
};

export const GET_INDUSTRIES_SUCCESS = '[STATIC] GET_INDUSTRIES_SUCCESS';
export const getIndustriesSuccess = ({data}) => {
  return {
    type: GET_INDUSTRIES_SUCCESS,
    payload: {data}
  };
};

export const GET_INDUSTRIES_ERROR = '[STATIC] GET_INDUSTRIES_ERROR';
export const getIndustriesError = ({ error }) => {
  return {
    type: GET_INDUSTRIES_ERROR,
    payload: { error }
  };
};

// GET CURRENCIES
export const GET_CURRENCIES = '[STATIC] GET_CURRENCIES';
export const getCurrencies = () => {
  return {
    type: GET_CURRENCIES,
    payload: {}
  };
};

export const GET_CURRENCIES_SUCCESS = '[STATIC] GET_CURRENCIES_SUCCESS';
export const getCurrenciesSuccess = ({data}) => {
  return {
    type: GET_CURRENCIES_SUCCESS,
    payload: {data}
  };
};

export const GET_CURRENCIES_ERROR = '[STATIC] GET_CURRENCIES_ERROR';
export const getCurrenciesError = ({ error }) => {
  return {
    type: GET_CURRENCIES_ERROR,
    payload: { error }
  };
};

// GET CAREER LEVELS
export const GET_CAREER_LEVELS = '[STATIC] GET_CAREER_LEVELS';
export const getCareerLevels = () => {
  return {
    type: GET_CAREER_LEVELS,
    payload: {}
  };
};

export const GET_CAREER_LEVELS_SUCCESS = '[STATIC] GET_CAREER_LEVELS_SUCCESS';
export const getCareerLevelsSuccess = ({data}) => {
  return {
    type: GET_CAREER_LEVELS_SUCCESS,
    payload: {data}
  };
};

export const GET_CAREER_LEVELS_ERROR = '[STATIC] GET_CAREER_LEVELS_ERROR';
export const getCareerLevelsError = ({ error }) => {
  return {
    type: GET_CAREER_LEVELS_ERROR,
    payload: { error }
  };
};

// GET JOB FAMILIES
export const GET_JOB_FAMILIES = '[STATIC] GET_JOB_FAMILIES';
export const getJobFamilies = () => {
  return {
    type: GET_JOB_FAMILIES,
    payload: {}
  };
};

export const GET_JOB_FAMILIES_SUCCESS = '[STATIC] GET_JOB_FAMILIES_SUCCESS';
export const getJobFamiliesSuccess = ({data}) => {
  return {
    type: GET_JOB_FAMILIES_SUCCESS,
    payload: {data}
  };
};

export const GET_JOB_FAMILIES_ERROR = '[STATIC] GET_JOB_FAMILIES_ERROR';
export const getJobFamiliesError = ({ error }) => {
  return {
    type: GET_JOB_FAMILIES_ERROR,
    payload: { error }
  };
};
