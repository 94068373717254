import { call, put, takeEvery, takeLatest, select, cancelled } from 'redux-saga/effects';

// filterList
import * as fromActions from '../actions/filterListEntities';
import { Config } from '../reducers/filterListEntities';
import * as fromSelectors from '../selectors/filterListEntities';

// routing
import * as fromRoutingActions from 'store/actions/routing';

// api
import * as api from 'api';
import axios from 'axios';


// GET ENTITIES

function* getListItems (action) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const { entity } = action.payload;

  const entityConfig = Config[entity];
  let apiEndpoint = entityConfig.apiEndpoint;
  const apiEndpointReplace = entityConfig.apiEndpointReplace;
  const apiQueryParameters = entityConfig.apiQueryParameters;
  const apiQueryParametersTypeField = entityConfig.apiQueryParametersTypeField;
  const apiListItemsField = entityConfig.apiListItemsField;

  const {
    dynamicConfig,
    type,
    searchString,
    sortBy, sortDirection, extraSearchParams,
    pageSize, pageIndex
    } = yield select((state) => {
    return fromSelectors.selectStateByEntity(state, entity);
  });

  const { apiEndpointReplaceValue } = dynamicConfig;

  // endpoint configuration via action and Config
  if (apiEndpointReplace && apiEndpointReplaceValue) {
    apiEndpoint = apiEndpoint.replace(apiEndpointReplace, apiEndpointReplaceValue);
  }

  // baseQueryParameters via Config
  try {
    const params = new URLSearchParams();
    params.append('search', searchString);
    params.append('sort', !extraSearchParams ? sortBy : '');
    params.append('sortOrder', !extraSearchParams ? sortDirection : '');
    params.append('offset', pageIndex * pageSize);
    params.append('limit', pageSize);

    if (apiQueryParametersTypeField) {
      params.append(apiQueryParametersTypeField, type);
    }

    let queryParamsAll = apiQueryParameters;
    if (extraSearchParams) {
      queryParamsAll = {...queryParamsAll, ...extraSearchParams};
    }

    if (queryParamsAll) {
      Object.keys(queryParamsAll).forEach(queryParam => {
        if (queryParamsAll[queryParam]) {
          // for the cases when params contains several keys with identical name
          // e.g. expand=user, expand=jobs, etc.
          if (Array.isArray(queryParamsAll[queryParam])) {
            queryParamsAll[queryParam].forEach(value => params.append(queryParam, value))
          } else {
            params.append(queryParam, queryParamsAll[queryParam])
          }
        }
      })
    }

    const { status, ok, data, headers } = yield call(
      api.get,
      apiEndpoint,
      params,
      {},
      {
        cancelToken: source.token
      }
    );

    if (ok) {
      const listItems = data[apiListItemsField] || []; // default to [] here
      // bc. matching with role returns empty object as results are being calced
      const numberOfEntries = Number(headers['x-total-result-count'] || 0);

      // edge case: when the last item of that page was deleted, navigate to previous page
      if (listItems.length === 0 && numberOfEntries !== 0) {
        const newPageIndex = pageIndex > 0 ? pageIndex - 1 : 0;
        yield put(fromActions.setPageIndex({
          entity: entity,
          pageIndex: newPageIndex
        }));
      }
      // default case
      else {
        yield put(fromActions.getListItemsSuccess({
          entity,
          listItems,
          numberOfEntries,
          noDataAtAll: !searchString && !extraSearchParams && !apiQueryParametersTypeField &&
            (!listItems || !listItems.length),
          noDataDuringCalculation: status === 202
        }));
      }
    }
    else {
      yield put(fromActions.getListItemsFail({ entity, status, error: data }));
    }
  }
  finally {
    if (yield cancelled()) {
      // cancel the request
      source.cancel('Operation cancelled by filterListEntities getListItems saga');
    }
  }
}

export function* watchGetListItems() {
  yield takeLatest([ // take latest of all of them to allow cancelling
    fromActions.TOGGLE_TYPE,
    fromActions.GET_LIST_ITEMS,
    fromActions.SET_SEARCH_STRING,
    fromActions.SET_SORT,
    fromActions.SET_PAGE_INDEX
  ], getListItems);
}




// RESETS VIAT ROUTING
function* resetEntity(entity) {

  const { dynamicConfig } = yield select((state) => {
    return fromSelectors.selectStateByEntity(state, entity);
  });

  const { shouldReset, shouldGet } = yield select((state) => {
    return fromSelectors.selectShouldResetByEntity(state, entity);
  });
  // console.log('reset', entity, shouldReset, shouldGet);
  if (shouldReset) {
    yield put(fromActions.reset({ entity }));
  }
  if (shouldGet) {
    yield put(fromActions.getListItems({
      entity,
      // keep dynamicConfig as still within resetPath
      dynamicConfig,
    }));
  }
};

export function* watchResets() {
  yield takeEvery(fromRoutingActions.SET_NEXT_ROUTE, function* employees() { yield resetEntity('employees') });
  yield takeEvery(fromRoutingActions.SET_NEXT_ROUTE, function* roles() { yield resetEntity('roles') });
  yield takeEvery(fromRoutingActions.SET_NEXT_ROUTE, function* roleMatching() { yield resetEntity('roleMatching') });
  yield takeEvery(fromRoutingActions.SET_NEXT_ROUTE, function* teams() { yield resetEntity('teams') });
  yield takeEvery(fromRoutingActions.SET_NEXT_ROUTE, function* candidates() { yield resetEntity('candidates') });
  yield takeEvery(fromRoutingActions.SET_NEXT_ROUTE, function* users() { yield resetEntity('users') });
  yield takeEvery(fromRoutingActions.SET_NEXT_ROUTE, function* adminUsers() { yield resetEntity('adminUsers') });
};
