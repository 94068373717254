import React, { memo, useEffect, useRef, useState } from 'react';
import styles from './AssessmentTeaser.module.scss';
import Big5Image from 'assets/assessments/big5.svg';
import PotentialImage from 'assets/assessments/potential.svg';
import WorkPreferenceImage from 'assets/assessments/work-preference.svg';
import KeyCompetenciesImage from 'assets/assessments/key-competencies.svg';
import LeadershipCompetenciesImage from 'assets/assessments/leadership-competencies.svg';
import RmpImage from 'assets/assessments/rmp.svg';
import NineLevelsImage from 'assets/assessments/9levels.svg';
import BalancedYouImage from 'assets/assessments/balanced-you.svg';
import classNames from 'classnames';
import { getTranslationIds } from './translationIds';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import AssessmentTeaserSkeleton from './AssessmentTeaserSkeleton';

import { useTranslate } from 'utils/translator';
import { getImageHeight } from 'utils/imageSize';
import {normalizeText} from 'utils/normalizeText';

import {Button, InfoTitle, Modal} from 'ui/basic';

const DEFAULT_DESCRIPTION =
  'Dieses Modul ermittelt die Ausprägungen der Big Five-Merkmale: Offenheit für' +
  'Erfahrungen, Gewissenhaftigkeit, Extraversion, Verträglichkeit und Emotionale Stabilität.';

const DEFAULT_SCOPE = '20 - 25 min / 120 Fragen';

const DEFAULT_INFO =
  'Persönlichkeitsmerkmale sind zeitlich stabile' +
  'Eigenschaften einer Person, durch die die Menschen sich unterscheiden.' +
  'Sie bilden den Grundstein für unser Handeln, unsere Vorlieben und unsere Stärken' +
  'und Schwächen​. Ob privat oder im Beruf – seine eigene Persönlichkeit oder' +
  'die anderer Personen zu kennen ermöglicht das Verständnis bestimmter Verhaltensmuster​.';

const AssessmentTeaser = memo((props) => {
  const { assessmentType, showImage, loading, onClick } = props;

  const translate = useTranslate();

  const ref = useRef();
  const [infoPageIsVisible, setInfoPageIsVisible] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const translationIds = getTranslationIds(assessmentType);

  const containerClassNames = classNames(styles.container, {
    [styles.imageVisible]: showImage
  });

  const handleResize = () => {
    const height = ref.current
      ? getImageHeight(ref.current.clientWidth)
      : 'auto';
    setImageHeight(height);
  };

  const getImage = () => {
    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return Big5Image;
      case ASSESSMENT_TYPES.POTENTIAL:
        return PotentialImage;
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return WorkPreferenceImage;
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return KeyCompetenciesImage;
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return LeadershipCompetenciesImage;
      case ASSESSMENT_TYPES.RMP:
        return RmpImage;
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return NineLevelsImage;
      case ASSESSMENT_TYPES.BALANCED_YOU:
        return BalancedYouImage;
      default:
        return Big5Image;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 100);
  }, [loading]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return (
      <div className={styles.assessmentTeaser}>
        <AssessmentTeaserSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.assessmentTeaser}>
      <div className={containerClassNames}>
        {showImage && (
          <div className={styles.imageBlock} ref={ref}>
            <img
              alt={'teaser'}
              src={getImage()}
              width={'100%'}
              height={imageHeight}
            />
          </div>
        )}
        <div className={styles.textBlock}>
          <InfoTitle
            title={translate(translationIds.title) || normalizeText(assessmentType)}
            onClick={() => {
              setInfoPageIsVisible(true);
            }}
          />
          <div className={styles.description}>
            {translate(translationIds.description) || DEFAULT_DESCRIPTION}
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.scopeInfo}>
              {translate(translationIds.scope) || DEFAULT_SCOPE}
            </div>
            <Button
              size={'S'}
              onClick={onClick}
            >
              {translate(translationIds.startButtonTitle) ||
                'Assessment Starten'}
            </Button>
          </div>
        </div>
      </div>

      {infoPageIsVisible && (
        <Modal
          header={translate(translationIds.infoTitle) || 'Warum ist das wichtig?'}
          primaryButtonTitle={translate('assessment_reminder_md_start_btn') || 'Starten'}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn') || 'Schließen'}
          onConfirm={onClick}
          onClose={() => setInfoPageIsVisible(false)}
        >
          {translate(translationIds.infoDetails) || DEFAULT_INFO}
        </Modal>
      )}
    </div>
  );
});

AssessmentTeaser.defaultProps = {
  assessmentType: ASSESSMENT_TYPES.BIG5,
  showImage: true
};

export default AssessmentTeaser;
