export const LOGOUT = 'LOGOUT';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';

export const logout = (username, password) => {
  return {
    type: LOGOUT,
    payload: { username, password }
  };
};

export const logoutFulfilled = () => {
  return {
    type: LOGOUT_FULFILLED,
    payload: ''
  };
};
export const logoutRejected = ({ error }) => {
  return {
    type: LOGOUT_REJECTED,
    payload: { error }
  };
};
