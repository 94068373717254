import {call, put, takeEvery} from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/wellBeing';
import * as fromActions from 'store/actions/wellBeing';
import {get, post} from 'api';
import * as moment from 'moment';
import {timestampToDate} from 'utils/dateTools';

function* submitWellBeingIndex({payload}) {
  try {
    const {wellBeingIndex, comment} = payload.dto;

    const {status, ok, data} = yield call(post, '/core/wellbeing', {
      wellbeing: wellBeingIndex,
      comment
    });
    if (ok && status === 200) {
      yield put(fromActions.submitWellBeingIndexFulfilled());
      yield put(fromActions.getWellBeingIndex());
      yield put(fromActions.getWellBeingHistory());
    } else {
      yield put(fromActions.submitWellBeingIndexRejected({error: data}));
    }
  } catch (error) {
    yield put(fromActions.submitWellBeingIndexRejected({error}));
  }
}

//GET WELL-BEING HISTORY OF INDIVIDUALS
function* getWellBeingHistory() {
  try {
    const { status, ok, data } = yield call(get, '/core/wellbeing');

    if (ok && status === 200) {
      const mappedWellBeingHistory = data.wellbeing.map(wb => ({...wb, date: timestampToDate(wb.date), index: wb.wellbeing}));
      yield put(fromActions.getWellBeingHistoryFulfilled({wellBeingHistory: mappedWellBeingHistory}));
    }
    else {
      yield put(fromActions.getWellBeingHistoryRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getWellBeingHistoryRejected({ error }));
  }
}

//GET WELL-BEING INDEX HISTORY OF INDIVIDUALS
function* getWellBeingIndexHistory({payload}) {
  try {
    const mode = payload.mode || 'daily';

    let dateFrom = moment();
    if (mode === 'monthly') {
      dateFrom = dateFrom.subtract(10, 'months');
    }
    else if (mode === 'weekly') {
      dateFrom = dateFrom.subtract(6, 'months');
    }
    else {
      dateFrom = dateFrom.subtract(1, 'months');
    }
    dateFrom = Math.ceil(dateFrom.valueOf() / 1000);

    const params = {
      mode,
      dateFrom,
      dateTo: Math.ceil(moment().add(1, 'days').valueOf() / 1000)
    };

    const { status, ok, data } = yield call(get, '/core/wellbeing/index/history', params);

    if (ok && status === 200) {
      const mappedWellBeingHistory = data.wellbeing.map(wb => {
        let date = timestampToDate(0);
        if (wb.date) {
          date = timestampToDate(wb.date);
        }
        return {
          ...wb, date
        }
      });

      yield put(fromActions.getWellBeingIndexHistoryFulfilled({wellBeingHistory: mappedWellBeingHistory}));
    }
    else {
      yield put(fromActions.getWellBeingIndexHistoryRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getWellBeingIndexHistoryRejected({ error }));
  }
}

//GET WELL-BEING INDEX OF INDIVIDUALS
function* getWellBeingIndex() {
  try {
    const { status, ok, data } = yield call(
      get,
      '/core/wellbeing/index',
      {
        dateFrom: Math.ceil(moment().subtract(7, 'days').valueOf() / 1000),
        dateTo: Math.ceil(moment().add(1, 'days').valueOf() / 1000)
      }
    );

    if (ok && status === 200) {
      yield put(fromActions.getWellBeingIndexFulfilled({wellBeing: data}));
    }
    else if (status === 422) {
      let dateFrom = moment().subtract(11, 'months');
      dateFrom = Math.ceil(dateFrom.valueOf() / 1000);

      const params = {
        mode: 'weekly',
        dateFrom,
        dateTo: Math.ceil(moment().add(1, 'days').valueOf() / 1000)
      };

      // fetch weekly well-being index history
      const { status, ok, data } = yield call(get, '/core/wellbeing/index/history', params);

      if (ok && status === 200 && (data.wellbeing && data.wellbeing.length > 0)) {
        yield put(fromActions.getWellBeingIndexFulfilled({wellBeing: {
          wellbeing: data.wellbeing[data.wellbeing.length - 1].index,
          quote: ''
        }}));
      } else {
        yield put(fromActions.getWellBeingIndexFulfilled({wellBeing: {
          notEnoughData: true,
          wellbeing: 0,
          quote: ''
        }}));
      }
    }
    else {
      yield put(fromActions.getWellBeingIndexRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getWellBeingIndexRejected({ error }));
  }
}

//GET WELL-BEING INDEX OF ORGANIZATION
function* getOrgWellBeingIndex() {
  try {
    const {status, ok, data} = yield call(
      get,
      'core/wellbeing/company',
      {
        dateFrom: Math.ceil(moment().subtract(7, 'days').valueOf() / 1000),
        dateTo: Math.ceil(moment().add(1, 'days').valueOf() / 1000)
      }
    );
    if (ok && status === 200) {
      yield put(fromActions.getOrgWellBeingIndexFulfilled(data));
    } else if (status === 422) {
      yield put(fromActions.getOrgWellBeingIndexFulfilled({ wellbeing: 0, users: -1, notEnoughData: true }));
    }
    else {
      yield put(fromActions.getOrgWellBeingIndexRejected({error: data}));
    }
  } catch (error) {
    yield put(fromActions.getOrgWellBeingIndexRejected({error}));
  }
}

//GET WELL-BEING HISTORY OF ORGANIZATION
function* getOrgWellBeingHistory({payload}) {
  try {
    const mode = payload.mode || 'weekly';

    let dateFrom = moment();
    if (mode === 'monthly') {
      dateFrom = dateFrom.subtract(10, 'months');
    }
    else if (mode === 'weekly') {
      dateFrom = dateFrom.subtract(6, 'months');
    }
    else {
      dateFrom = dateFrom.subtract(1, 'months');
    }
    dateFrom = Math.ceil(dateFrom.valueOf() / 1000);

    const params = {
      mode,
      dateFrom: dateFrom,
      dateTo: Math.ceil(moment().add(1, 'days').valueOf() / 1000)
    };

    const { status, ok, data } = yield call(get, '/core/wellbeing/company/history', params);

    if (ok && status === 200) {
      const mappedWellBeingHistory = data.wellbeing.map(wb => {
        let date = timestampToDate(0);
        if (wb.date) {
          date = timestampToDate(wb.date);
        }
        return {
          ...wb, date
        }
      });
      yield put(fromActions.getOrgWellBeingHistoryFulfilled({wellBeingHistory: mappedWellBeingHistory}));
    }
    else {
      yield put(fromActions.getOrgWellBeingHistoryRejected({ error: data }));
    }
  }
  catch(error) {
    yield put(fromActions.getOrgWellBeingHistoryRejected({ error }));
  }
}

export function* watchSubmitWellBeingIndex() {
  yield takeEvery(fromActionTypes.SUBMIT_WELLBEING_INDEX, submitWellBeingIndex);
}

export function* watchGetWellBeingHistory() {
  yield takeEvery(fromActionTypes.GET_WELLBEING_HISTORY, getWellBeingHistory);
}

export function* watchGetWellBeingIndexHistory() {
  yield takeEvery(fromActionTypes.GET_WELLBEING_INDEX_HISTORY, getWellBeingIndexHistory);
}

export function* watchGetWellBeingIndex() {
  yield takeEvery(fromActionTypes.GET_WELLBEING_INDEX, getWellBeingIndex);
}

export function* watchGetOrgWellBeingIndex() {
  yield takeEvery(fromActionTypes.GET_ORG_WELLBEING_INDEX, getOrgWellBeingIndex);
}

export function* watchGetOrgWellBeingHistory() {
  yield takeEvery(fromActionTypes.GET_ORG_WELLBEING_HISTORY, getOrgWellBeingHistory);
}
