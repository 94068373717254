export const GET_ASSESSMENT_ANSWERS = 'GET_ASSESSMENT_ANSWERS';
export const GET_ASSESSMENT_ANSWERS_PENDING = 'GET_ASSESSMENT_ANSWERS_PENDING';
export const GET_ASSESSMENT_ANSWERS_FULFILLED = 'GET_ASSESSMENT_ANSWERS_FULFILLED';
export const GET_ASSESSMENT_ANSWERS_REJECTED = 'GET_ASSESSMENT_ANSWERS_REJECTED';

export const getAssessmentAnswers = (id) => {
  return {
    type: GET_ASSESSMENT_ANSWERS,
    payload: id
  };
};

export const getAssessmentAnswersFulfilled = (answers) => {
  return {
    type: GET_ASSESSMENT_ANSWERS_FULFILLED,
    payload: answers
  };
};
export const getAssessmentAnswersRejected = ({ error }) => {
  return {
    type: GET_ASSESSMENT_ANSWERS_REJECTED,
    payload: { error }
  };
};

