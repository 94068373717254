import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import styles from './UserIndividualSettings.module.scss';
import {IconsSvg} from 'assets/icons';
import {
  requestResetPassword,
  initRequestResetPassword,
  getPlatformRoles,
  editCompanyUserRole,
  getEmployee, initEmployee,
  removeCompanyUser,
  showToast, initRemoveCompanyUser
} from 'store/actions';

import * as rolesSelector from 'store/selectors/roles';
import * as employeeOverviewSelector from 'store/selectors/employeeOverview';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import {selectCapabilitiesNext} from 'store/selectors/configuration';

import * as api from 'api';

import { useTranslate } from 'utils/translator';
import {getFilteredRoles} from 'utils/roles';
import {PLATFORM_ROLES} from 'utils/configuration/const/roles';
import {COMPANY_ID, CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';
import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

import {Button, DropDown, Toast, DropDownMenu, Profile, DetailsPageSkeleton, Modal, Toggle} from 'ui/basic';
import {Breadcrumb} from 'features/framework/components/Breadcrumb';

const DEFAULT_OPTION_DESCRIPTION =
  'Kann Assessments durchführen, Rollen & Teams erstellen und ' +
  'hat Zugriff auf Profile von Team-Mitgliedern (direct reports).';

const getRoleOptionLabel = (role, translate) => {
  return (
    <div className={styles.option}>
      <div className={styles.optionTitle}>{translate(`platform_roles_${role}`) || role.toLowerCase()}</div>
      <div className={styles.optionDescription}>
        {translate(`${CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU ? 'by_' : ''}platform_roles_descr_${role}`) ||
        DEFAULT_OPTION_DESCRIPTION}
      </div>
    </div>
  )
};

const ASSESSMENT_TITLES = {
  [ASSESSMENT_TYPES.BIG5]: 'big5_ass_tb_title',
  [ASSESSMENT_TYPES.POTENTIAL]: 'potential_ass_tb_title',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'wp_ass_tb_title',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'keycomp_ass_tb_title',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'lscomp_ass_tb_title',
  [ASSESSMENT_TYPES.RMP]: 'rmp_ass_tb_title',
  [ASSESSMENT_TYPES.NINE_LEVELS]: '9levels_ass_tb_title',
  [ASSESSMENT_TYPES.MINDFULNESS_JOURNEY]: 'mind_journey_ass_tb_title'
};

const ASSESSMENT_DESCRIPTIONS = {
  [ASSESSMENT_TYPES.BIG5]: 'org_settings_assessments_big5_descr',
  [ASSESSMENT_TYPES.POTENTIAL]: 'org_settings_assessments_potential_descr',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'org_settings_assessments_workpref_descr',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'org_settings_assessments_keycomp_descr',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'org_settings_assessments_leadcomp_descr',
  [ASSESSMENT_TYPES.RMP]: 'org_settings_assessments_rmp_descr',
  [ASSESSMENT_TYPES.NINE_LEVELS]: 'org_settings_assessments_9levels_descr',
  [ASSESSMENT_TYPES.MINDFULNESS_JOURNEY]: 'org_settings_assessments_mind_journey_descr'
};


const UserIndividualSettings = () => {

  const translate = useTranslate();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [passwordResetMsgVisible, setPasswordResetMsgVisible] = useState(false);
  const [deleteUserPopupVisible, setDeleteUserPopupVisible] = useState(false);
  const [platformRoles, setPlatformRoles] = useState();
  const [userGroup, setUserGroup] = useState();

  const [assessments, setAssessments] = useState([]);
  const [featuresSaveProcessing, setFeaturesSaveProcessing] = useState();
  const [featuresSaveSuccess, setFeaturesSaveSuccess] = useState();

  const [userProfile, setUserProfile] = useState([]);

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const allPlatformRoles = useSelector(state => rolesSelector.getPlatformRoles(state));
  const employee = useSelector(employeeOverviewSelector.getEmployee);
  const employeeError = useSelector(employeeOverviewSelector.getEmployeeError);
  const resetPasswordStatus = useSelector(state => state.resetPasswordRequest && state.resetPasswordRequest.status);

  // STORE: REMOVE USER
  const companyUserRemoved = useSelector(state => state.admin && state.admin.companyUserRemoved);
  const removedUserId = useSelector(state => state.admin && state.admin.removedUserId);
  const removedUserName = useSelector(state => state.admin && state.admin.removedUserName);
  const removeCompanyUserProcessing = useSelector(state => state.admin && state.admin.removeCompanyUserProcessing);

  useEffect(() => {
    if (companyUserRemoved) {
      setDeleteUserPopupVisible(false);

      const toastHeader = translate('user_settings_delete_user_success_title') || 'Benutzerprofil erfolgreich entfernt';
      const toastContent = translate('user_settings_delete_user_success_descr',
        ['{{username}}', removedUserName || removedUserId]) || 'Alle Nutzerdaten von “Petra Werner” werden gelöscht.';
      dispatch(showToast(toastHeader, toastContent));

      history.push('/settings/users');
    }
  }, [dispatch, history, removedUserId, removedUserName, companyUserRemoved, translate]);

  useEffect(() => {
    return () => {
      dispatch(initRemoveCompanyUser());
    }
  }, [dispatch]);

  const OPTIONS = [
    {
      id: 'password',
      label: translate('user_settings_reset_password') || 'Passwort zurücksetzen',
    },
    {
      id: 'view-profile',
      label: translate('user_settings_view_profile') || 'Mitarbeiterprofil ansehen'
    },
    {
      id: 'delete-user',
      label: translate('user_settings_delete_user') || 'Nutzer entfernen'
    }
  ];

  const getMenuOptions = () => {
    const localOptions = [...OPTIONS];

    //if user has no permissions to view employee profile page or
    //if employee is invited but not yet registered -> hide 'view profile' options
    if (!me.canViewEmployeeIndividual || employee.status.percentage === 0) {
      const index = localOptions.findIndex(option => option.id === 'view-profile');
      localOptions.splice(index, 1);
    }

    return localOptions;
  };

  // USER FEATURES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [userFeatures, setUserFeatures] = useState([]);
  const [userFeaturesRequested, setUserFeaturesRequested] = useState();
  useEffect(() => {
    if (userFeaturesRequested) {
      return;
    }

    api.get(`/core/company/users/${params.userId}/features`)
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          setUserFeatures(data.features);
        }
      })

    setUserFeaturesRequested(true);
  }, [params.userId, userFeaturesRequested]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);

  useEffect(() => {
    if (featuresSaveSuccess ||
      CONFIGURATION !== CONFIGURATION_OPTIONS.BLUQUIST // assessments restriction is only available for Bluquist configuration
    ) {
      return;
    }

    const assessmentsIds = Object.values(ASSESSMENT_TYPES);
    const availableAssessmentsInternal = assessmentsFromStore
      .map(assessment => {
        const thisFeature = userFeatures.find(feature => feature.id.toLowerCase().includes(assessment.id.toLowerCase()));
        const isCustomAssessment = !assessmentsIds.includes(assessment.id);

        return {
          id: assessment.id,
          active: !!(thisFeature && thisFeature.active),
          title: assessment.title,
          description: assessment.description,
          isCustomAssessment
        }
      });

    setAssessments(availableAssessmentsInternal);
  }, [assessments.length, params.userId, assessmentsFromStore, userFeatures, featuresSaveSuccess]);

  useEffect(() => {
    // assessments restriction is only available for Bluquist configuration
    if (CONFIGURATION !== CONFIGURATION_OPTIONS.BLUQUIST) {
      return;
    }

    api.get('core/user/profile', {id: params.userId})
      .then(({ok, status, data}) => {
        if (ok && status === 200) {
          setUserProfile(data.profile);
        }
      })
      .catch(error => console.error(error.message));
  }, [params.userId]);

  useEffect(() => {
    if (resetPasswordStatus === 'valid') {
      setPasswordResetMsgVisible(true);
    }
  }, [resetPasswordStatus]);

  useEffect(() => {
    const filteredRoles = getFilteredRoles(allPlatformRoles);
    setPlatformRoles(filteredRoles.map(role => {
      return {
        value: role,
        label: getRoleOptionLabel(role.split('_CUSTOM')[0].toLowerCase(), translate)
      }
    }))
  }, [allPlatformRoles, translate]);

  useEffect(() => {
    const employeeId = params.userId;

    dispatch(getPlatformRoles());
    dispatch(getEmployee(employeeId));
    return () => {
      dispatch(initRequestResetPassword());
      dispatch(initEmployee());
    }
  }, [dispatch, params.userId]);

  useEffect(() => {
    if (employee && platformRoles) {
      let roleName;
      const userGroup = employee.roles.find(r => {
        roleName = r.role;
        if (roleName.includes('_CUSTOM') && roleName !== PLATFORM_ROLES.COMPANY_LEADER_CUSTOM) {
          roleName = r.role.split('_CUSTOM')[0];
        }

        return r.company && Object.values(PLATFORM_ROLES).includes(roleName)
      });

      setUserGroup({...userGroup, role: roleName});
    }
  }, [employee, platformRoles]);

  const handleMenuChange = (id) => {
    switch (id) {
      case 'password':
        dispatch(requestResetPassword(employee.email, COMPANY_ID));
        break;
      case 'delete-user':
        setDeleteUserPopupVisible(true);
        break;
      case 'view-profile':
        history.push(`/employees/${params.userId}/profile`);
        break;
      default:
        break;
    }
  };

  const handleAssessmentChange = (assessment, assessmentActivated) => {
    setFeaturesSaveProcessing(true);

    const thisAssessment = assessments.find(a => a.id === assessment.id);
    thisAssessment.active = assessmentActivated;

    api.put(`/core/company/users/${params.userId}/features`, {
      features: assessments.map(assessment => {
        const assessmentId = assessment.isCustomAssessment ?
          `custom-assessment_${assessment.id}` :
          `ASSESSMENT_${assessment.id.toUpperCase()}`;

        return {
          id: assessmentId,
          active: assessment.active
        }
      })
    })
      .then(({ok, status, data}) => {
        setFeaturesSaveProcessing(false);

        if (ok && status === 200) {
          setFeaturesSaveSuccess(true);

          let toastTitle;
          let toastContent;

          if (assessmentActivated) {
            toastTitle = translate('user_settings__assessment_activated_toast_title') || 'Assessment aktiviert';
            toastContent = translate('user_settings__assessment_activated_toast_content') ||
              'Das ausgewählte Assessment ist nun für den Nutzer verfügbar.';
          } else {
            toastTitle = translate('user_settings__assessment_deactivated_toast_title') || 'Assessment deaktiviert';
            toastContent = translate('user_settings__assessment_deactivated_toast_content') ||
              'Das ausgewählte Assessment ist nicht mehr für den Nutzer verfügbar. ';
          }


          dispatch(showToast(toastTitle, toastContent));
        }
      })
      .catch(error => {
        setFeaturesSaveProcessing(false);
        console.error(error.message);
      });
  };

  if (employeeError) {
    history.push('/settings/users');
  }

  if (!employee) {
    return (
      <div className={styles.userIndividualSettings}>
        <DetailsPageSkeleton />
      </div>
    )
  }

  return (
    <div className={styles.userIndividualSettings}>
      {/* BREADCRUMB */}
      { (params.userId && employee.name) && (
        <Breadcrumb routesMustInclude={params.userId}
                    label={employee.name}
                    link={`/settings/users/${employee.id}`}
        />
      )}

      <div className={styles.left}>
        <Profile user={employee} roleClickable={capabilitiesNext.rolesView} />
        <div className={styles.buttons}>
          {(employee.status && employee.status.percentage !== -1) ?
            <>
              {/*revoke button is temporarily hidden*/}
              {false &&
              <Button
                size={'S'}
                looks={'secondary'}
              >
                {translate('user_settings_revoke_access') || 'Zugriff widerrufen'}
              </Button>
              }

              <DropDownMenu
                options={getMenuOptions()}
                onChange={(id) => handleMenuChange(id)}
              >
                . . .
              </DropDownMenu>
            </> :
            <Button
              looks={'secondary'}
              size={'S'}
              onClick={() => {}}
            >
              Profil aktivieren
            </Button>
          }
        </div>
      </div>

      {(employee.status && employee.status !== -1) ?
        <div className={styles.right}>
          <div className={styles.title}>
            {translate('user_settings_title', ['{{username}}', employee.name]) ||
            `Nutzereinstellungen von ${employee.name}`}
          </div>

          <div className={styles.userGroups}>
            <div className={styles.title}>
              {translate('user_settings_change_usergroup_title') || 'Nutzergruppe ändern'}
            </div>
            <div className={styles.description}>
              {translate('user_settings_change_usergroup_descr') ||
              'Hier kannst du die Nutzergruppe für diesen Mitarbeiter ändern und die zugehörigen Zugriffrechte bearbeiten.'
              }
            </div>
            <div className={styles.selectTitle}>
              {translate('user_settings_change_usergroup_dropdown_title') ||
              'Ändere die Nutzergruppe für diesen Mitarbeiter'
              }
            </div>
            <div className={styles.select}>
              <DropDown
                size={'L'}
                selectedOption={
                  userGroup ?
                    platformRoles.find(pr => pr.value.toLowerCase().includes(userGroup.role.toLowerCase())) :
                    null
                }
                placeholder={translate('user_settings_change_usergroup_dropdown_placeholder') || 'Nutzergruppe auswählen'}
                options={platformRoles}
                onChange={option => {
                  dispatch(editCompanyUserRole(employee.id, option.value));
                }}
              />
            </div>
          </div>

          {/*ASSESSMENTS*/}
          {CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST &&
          <div className={styles.assessments}>
            <div className={styles.xxsTitle}>
              {translate('user_settings_configure_assessments_title') || 'Verfügbare Assessments konfigurieren'}
            </div>
            <div className={styles.copy}>
              {translate('user_settings_configure_assessments_copy') ||
              'Hier kannst du die Verfügbarkeit von Assessments für diesen Nutzer ändern.'}
            </div>

            <div className={styles.list}>
              {assessments.map((assessment, index) => (
                <div className={styles.listItem} key={index}>
                  <div className={styles.row1}>
                    <span className='blu-typeLabel'>
                      {translate(ASSESSMENT_TITLES[assessment.id]) || assessment.title}

                      {assessment.active && (
                        ` (${
                          userProfile.find(profileItem => profileItem.assessment === assessment.id) &&
                          userProfile.find(profileItem => profileItem.assessment === assessment.id).result ?
                            (translate('reset_assessments__completed_status')) :
                            (translate('reset_assessments__open_status'))
                        })`
                      )}

                      {!assessment.active && ` (${translate('not_available_lbl')})`}
                    </span>
                    <Toggle
                      id={assessment.id}
                      checked={assessment.active}
                      disabled={featuresSaveProcessing}
                      onChange={value => handleAssessmentChange(assessment, value)}
                    />

                  </div>

                  <div className={styles.row2}>
                    {translate(ASSESSMENT_DESCRIPTIONS[assessment.id]) || assessment.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
          }

          {/*teams section is hidden because currently there is no teams functionality*/}
          {false &&
          <div className={styles.teams}>
            <div className={styles.title}>Zugewiesene Teams</div>
            <div className={styles.description}>
              Hier kannst du diesem Nutzer Teams zuweisen und die Rolle im Team vergeben.
            </div>
            <Button
              size={'S'}
              leadingIcon={IconsSvg.Plus}
            >
              Team zuweisen
            </Button>
          </div>
          }
        </div> :
        <div className={styles.right}>
          <div className={styles.title}>
            {translate('user_settings_deactivated_user_long') || 'Dieses Benutzerprofil ist deaktiviert'}
          </div>
          <div className={styles.description}>
            {translate('user_settings_deactivated_user_description') ||
            'Benutzerprofile koennen durch Administratoren innerhalb der Nutzerverwaltung aktiviert werden.'
            }
          </div>
        </div>
      }

      {passwordResetMsgVisible &&
      <Toast onClose={() => {
        setPasswordResetMsgVisible(false);
        dispatch(initRequestResetPassword());
      }}>
        <>
          <div className={styles.toastTitle}>
            {translate('user_settings_reset_password_success_title') || 'Passwort erfolgreich zurückgesetzt'}
          </div>
          <div className={styles.toastDescription}>
            {translate('user_settings_reset_password_success_descr') ||
            'Der Nutzer erhält eine Email mit Anweisungen zum Zurücksetzen des Passworts'
            }
          </div>
        </>
      </Toast>
      }

      {deleteUserPopupVisible &&
      <Modal
        header={translate('user_settings_delete_user_request_title') ||
        'Bist du sicher, dass du diesen Nutzer entfernen möchtest?'
        }
        redButtonTitle={translate('user_settings_delete_user_confirm') || 'Löschen'}
        redButtonDisabled={removeCompanyUserProcessing}
        secondaryButtonTitle={translate('user_settings_delete_user_cancel') || 'Abbrechen'}
        closeOnConfirm={false}
        onConfirm={() => dispatch(removeCompanyUser(employee.id, '', employee.name))}
        onClose={() => setDeleteUserPopupVisible(false)}
      >
        {translate('user_settings_delete_user_request_descr') ||
        'Alle Nutzerinformationen inklusive Assessmentergebnisse werden unwiderruflich gelöscht.'
        }
      </Modal>
      }

    </div>
  )
};

export default UserIndividualSettings;
