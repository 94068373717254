import React from 'react';

import { Introduction } from './pages';
import Big5AssessmentPage from './pages/Assessment/Big5AssessmentPage';
import CustomAssessmentPage from './pages/Assessment/CustomAssessmentPage';
import KeyCompetenciesAssessmentPage from './pages/Assessment/KeyCompetenciesAssessmentPage';
import LeadershipCompetenciesAssessmentPage from './pages/Assessment/LeadershipCompetenciesAssessmentPage';
import NineLevelsAssessmentPage from './pages/Assessment/NineLevelsAssessmentPage';
import PotentialAssessmentPage from './pages/Assessment/PotentialAssessmentPage';
import RmpAssessmentPage from './pages/Assessment/RmpAssessmentPage';
import WorkPreferencesAssessmentPage from './pages/Assessment/WorkPreferencesAssessmentPage';


export const routesConfigCore = {
  show: {
    forUserGroup: 'all'
  },
  routes: [
    {
      path: '/introduction',
      pageType: 'modal',
      PageComponent: () => <Introduction />,
      show: {
        forUserGroup: ['employee', 'leader', 'admin'] // all but candidate
      },
      tools: {
        browserTitleKey: 'introduction_header_title'
      }
    },

    //  ASSESSMENTS
    {
      path: '/assessments/big5',
      pageType: 'modal',
      PageComponent: () => <Big5AssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
    {
      path: '/assessments/potential',
      pageType: 'modal',
      PageComponent: () => <PotentialAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
    {
      path: '/assessments/work-preference',
      pageType: 'modal',
      PageComponent: () => <WorkPreferencesAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
    {
      path: '/assessments/key-comp',
      pageType: 'modal',
      PageComponent: () => <KeyCompetenciesAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
    {
      path: '/assessments/lead-comp',
      pageType: 'modal',
      PageComponent: () => <LeadershipCompetenciesAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
    {
      path: '/assessments/rmp',
      pageType: 'modal',
      PageComponent: () => <RmpAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
    {
      path: '/assessments/9levels',
      pageType: 'modal',
      PageComponent: () => <NineLevelsAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
    {
      path: '/assessments/:customAssessmentId',
      pageType: 'modal',
      PageComponent: () => <CustomAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5'
      }
    },
  ]
};
