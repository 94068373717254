import {get}  from 'api';
import * as action from 'store/actions';
import { call, put, takeEvery } from 'redux-saga/effects';

import {MAPPED_PLATFORM_ROLES, PLATFORM_ROLES} from 'utils/configuration/const/roles';
import {
  CREATE_ROLE_CAPABILITIES,
  CREATE_TEAM_CAPABILITIES, CREATE_VACANCY_CAPABILITIES,
  INVITE_CANDIDATE_CAPABILITIES,
  INVITE_USER_CAPABILITIES,
  MATCH_ROLES_CAPABILITIES,
  STAFF_TEAM_CAPABILITIES, VIEW_CANDIDATES_CAPABILITIES,
  VIEW_ROLES_CAPABILITIES,
  VIEW_TEAMS_CAPABILITIES, VIEW_USERS_CAPABILITIES, VIEW_VACANCIES_CAPABILITIES
} from 'utils/configuration/const/capabilities';
import {CONFIGURATION, CONFIGURATION_OPTIONS, IS_ADMIN_FRONTEND} from 'utils/configuration';
import {COMPANY_FEATURES} from 'utils/configuration/const/company-features';

function* getCurrentUser() {
  try {
    const params = new URLSearchParams();
    params.append('expand', 'roles');
    params.append('expand', 'organizationalRoles');

    const { status, ok, data }  = yield call(get, 'core/user/info', params);

    const currentUserData = data;

    let canViewEmployees = true;
    let canViewRoles = true;
    let canCreateRoles = true;
    let canMatchRoles = true;
    let canInviteEmployees = true;
    let canInviteCandidates = true;
    let canViewCandidates = true;
    let canShareOwnProfile = true;
    let canViewTeams = true;
    let canCreateTeams = true;
    let canEditTeams = true;
    let canStaffTeam = true;
    let canAccessWellBeing = true;
    let canAccessGarmin = false;
    // vacancy
    let canViewVacancies = true;
    let canCreateVacancy = true;
    //settings for 'balanced you' project
    let canViewEmployeeIndividual = true;
    let canAccessRoleSettings = true;
    let canAccessTeamSettings = true;
    let canAccessMindfulnessJourney = false;

    if (ok && status === 200) {
      let role = PLATFORM_ROLES.COMPANY_USER;

      if (currentUserData.roles.find(r => r.role === PLATFORM_ROLES.CANDIDATE && r.company)) {
        role = PLATFORM_ROLES.CANDIDATE;
      }

      if (currentUserData.roles.find(r => r.role === PLATFORM_ROLES.TEAM_LEADER && r.team)) {
        role = PLATFORM_ROLES.TEAM_LEADER;
      }

      if (currentUserData.roles.find(r => r.role === PLATFORM_ROLES.COMPANY_LEADER)) {
        role = PLATFORM_ROLES.COMPANY_LEADER;
      }

      if (currentUserData.roles.find(r => r.role === PLATFORM_ROLES.COMPANY_LEADER_CUSTOM)) {
        role = PLATFORM_ROLES.COMPANY_LEADER_CUSTOM;
      }

      if (currentUserData.roles.find(r => r.role === PLATFORM_ROLES.COMPANY_ADMIN && r.company)) {
        role = PLATFORM_ROLES.COMPANY_ADMIN;
      }

      // roleNext
      let roleNext;
      if (currentUserData.roles.find(role => role.role === 'JOB_SHARE_ROLE')) {
        roleNext = 'jobShare';
      }
      if (currentUserData.roles.find(role => role.role === 'PROFILE_SHARE_ROLE')) {
        roleNext = 'profileShare';
      }
      if (currentUserData.roles.find(role => role.role === PLATFORM_ROLES.CANDIDATE)) {
        roleNext = 'candidate';
      }
      if (currentUserData.roles.find(role => role.role === PLATFORM_ROLES.COMPANY_USER)) {
        roleNext = 'employee';
      }
      if (
        currentUserData.roles.find(role => role.role === PLATFORM_ROLES.COMPANY_LEADER
          || role.role === PLATFORM_ROLES.COMPANY_LEADER_CUSTOM
        )
      ) {
        roleNext = 'leader';
      }
      if (currentUserData.roles.find(role => role.role === PLATFORM_ROLES.COMPANY_ADMIN)) {
        roleNext = 'admin';
      }
      if (currentUserData.roles.find(role => role.role === 'GLOBAL_ADMIN')) {
        roleNext = 'bluquistAdmin'
      }

      // subRoleNext
      let subRoleNext = 'noSubRole';
      let teamsForTeamLeader = [];
      if (currentUserData.roles.find(role => role.role === PLATFORM_ROLES.TEAM_LEADER)) {
        subRoleNext = 'teamLeader';
        teamsForTeamLeader = currentUserData.roles.filter(role => role.role === PLATFORM_ROLES.TEAM_LEADER).map(role => role.team);
      }
      if (currentUserData.roles.find(role => role.role === PLATFORM_ROLES.TEAM_MEMBER)) {
        subRoleNext = 'teamMember';
      }

      let platformRolesResponse;
      // if user is a company user or a candidate or a team member, there is no permission to fetch the list of roles
      // from that reason, roles should be fetched if user has a higher level role with according permissions
      if (role !== PLATFORM_ROLES.COMPANY_USER && role !== PLATFORM_ROLES.CANDIDATE && role !== PLATFORM_ROLES.TEAM_MEMBER) {
        platformRolesResponse = yield call(get, '/core/access/roles');
      }

      const companyFeaturesResponse = yield call(get, 'core/company/features');

      //generate permissions based on configurations
      if (CONFIGURATION !== CONFIGURATION_OPTIONS.BLUQUIST) {
        canCreateRoles = false;
        canCreateTeams = false;
        canEditTeams = false;
        canViewEmployeeIndividual = false;
        canAccessRoleSettings = false;
        canAccessTeamSettings = false;
        canAccessMindfulnessJourney = true;
        canCreateVacancy = false;
      }

      //generate permissions based on role capabilities
      if (platformRolesResponse && platformRolesResponse.ok && platformRolesResponse.status === 200) {
        const platformRoles = platformRolesResponse.data.roles;

        //if user is not an administrator, check the capabilities of user
        if (role !== PLATFORM_ROLES.COMPANY_ADMIN) {
          const myRoles = currentUserData.roles.map(r => r.role);

          const myCapabilities = platformRoles
            .filter(pr => myRoles.includes(pr.role) && ![PLATFORM_ROLES.TEAM_LEADER].includes(pr.role))
            .flatMap(pr => pr.capabilities);

          if (!VIEW_ROLES_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canViewRoles = false;
          }

          if (!CREATE_ROLE_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canCreateRoles = false;
          }

          if (!INVITE_USER_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canInviteEmployees = false;
          }

          if (!INVITE_CANDIDATE_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canInviteCandidates = false;
          }

          if (!VIEW_CANDIDATES_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canViewCandidates = false;
          }

          if (!CREATE_VACANCY_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canCreateVacancy = false;
          }

          if (!VIEW_VACANCIES_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canViewVacancies = false;
          }

          if (!VIEW_USERS_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canViewEmployees = false;
          }

          if (!MATCH_ROLES_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canMatchRoles = false;
          }

          if (!VIEW_TEAMS_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canViewTeams = false;
            canEditTeams = false;
          }

          if (!CREATE_TEAM_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canCreateTeams = false;
          }

          if (!STAFF_TEAM_CAPABILITIES.every(c => myCapabilities.includes(c))) {
            canStaffTeam = false;
          }
        }
      }

      //no well-being for admin module
      if (IS_ADMIN_FRONTEND) {
        canAccessWellBeing = false;
      }

      //if user is a team leader of any team, teams section should be visible
      if (currentUserData.roles.find(r => r.role === PLATFORM_ROLES.TEAM_LEADER)) {
        canViewTeams = true;
        canEditTeams = true;
        canViewEmployees = true;
      }

      if (role === PLATFORM_ROLES.COMPANY_USER || role === PLATFORM_ROLES.CANDIDATE) {
        canViewEmployees = false;
        canInviteEmployees = false;
        canInviteCandidates = false;
        canViewCandidates = false;
        canViewEmployeeIndividual = false;
        canViewRoles = false;
        canCreateRoles = false;
        canMatchRoles = false;
        canViewTeams = false;
        canCreateTeams = false;
        canEditTeams = false;
        canStaffTeam = false;
        canAccessRoleSettings = false;
        canAccessTeamSettings = false;
        canViewVacancies = false;
        canCreateVacancy = false;
      }

      if (role === PLATFORM_ROLES.CANDIDATE) {
        canAccessWellBeing = false;
        canShareOwnProfile = false;
      }

      //generate permissions based on organization settings
      if (companyFeaturesResponse.ok && companyFeaturesResponse.status === 200) {
        const companyFeatures = companyFeaturesResponse.data.features;
        const roleMappingModule = companyFeatures.find(feature => (
          feature.category === 'module' && feature.id === COMPANY_FEATURES.ROLE_MAPPING
        ));
        const teamMappingModule = companyFeatures.find(feature => (
          feature.category === 'module' && feature.id === COMPANY_FEATURES.TEAM_MAPPING
        ));
        const wellBeingModule = companyFeatures.find(feature => (
          feature.category === 'module' && feature.id === COMPANY_FEATURES.WELL_BEING
        ));

        const garminModule = companyFeatures.find(feature => (
          feature.category === 'module' && feature.id === COMPANY_FEATURES.INTEGRATION_GARMIN
        ));

        if (garminModule && garminModule.active && CONFIGURATION === CONFIGURATION_OPTIONS.BALANCED_YOU) {
          canAccessGarmin = true;
        }

        if (!roleMappingModule || (roleMappingModule && !roleMappingModule.active)) {
          canViewRoles = false;
          canCreateRoles = false;
          canMatchRoles = false;
        }

        if (!teamMappingModule || (teamMappingModule && !teamMappingModule.active)) {
          canViewTeams = false;
          canCreateTeams = false;
          canStaffTeam = false;
        }

        if (!wellBeingModule || (wellBeingModule && !wellBeingModule.active)) {
          canAccessWellBeing = false;
        }
      }


      yield put(action.getCurrentUserFulfilled({
        ...currentUserData,
        role: MAPPED_PLATFORM_ROLES[role],
        roleNext,
        subRoleNext,
        teamsForTeamLeader,
        // employees
        canViewEmployees,
        canInviteEmployees,
        canInviteCandidates,
        canViewCandidates,
        canShareOwnProfile,
        canViewEmployeeIndividual,
        // roles
        canViewRoles,
        canCreateRoles,
        canMatchRoles,
        // teams
        canViewTeams,
        canCreateTeams,
        canEditTeams,
        canStaffTeam,
        // well-being
        canAccessWellBeing,
        // settings
        canAccessRoleSettings,
        canAccessTeamSettings,
        // balanced-you
        canAccessMindfulnessJourney,
        // vacancies
        canViewVacancies,
        canCreateVacancy,
        //garmin
        canAccessGarmin
      }));

      yield put(action.getUserProfilePicture(data));
      yield put(action.listAssessments());
    } else {
      //hide error message for error code 1103
      if (data.error && data.error.errorCode === 1103) {
        yield put(action.getCurrentUserRejected({
          error: {
            errorCode: 1103,
            errorMessage: ''
          }
        }));
      } else {
        yield put(action.getCurrentUserRejected(data));
      }
    }
  } catch (error) {
    yield put(action.getCurrentUserRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}

export default function* watchGetUserRequest() {
  yield takeEvery(action.GET_CURRENT_USER, getCurrentUser);
}
