// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionPosition.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
const Config = {
  positionMinLength: 2,
};

// COMPONENT: QuestionPosition
const QuestionPosition = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
   } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const [ position, setPosition ] = useState('');
  useEffect(() => {
    setPosition(answer.value);
  }, [answer.value]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handlePosition = (position) => {
    const isValid = position && position.length >= Config.positionMinLength;
    setPosition(position);
    onAnswer({ value: position, name: 'position', isValid });
  };

  // RENDER: QuestionPosition
  return (
    <div className={classNames(styles.questionPosition)}>

      {/* FORM */}
      <div className={styles.formRow}>
        <InputNext
          size='responsive'
          value={position}
          label={translate('cp_current_position_placeholder') || 'Name der Position'}
          onChange={handlePosition}>
        </InputNext>
      </div>

    </div>
  );
};

export default QuestionPosition;
