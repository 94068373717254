export const ENABLE_NEXT_ROUTE_FROM_MENU = '[ROUTING] ENABLE NEXT ROUTE FROM MENU';
export const enableNextRouteFromMenu = () => {
  return {
    type: ENABLE_NEXT_ROUTE_FROM_MENU,
    payload: {}
  }
};

export const SET_NEXT_ROUTE = '[ROUTING] SET NEXT ROUTE';
export const setNextRoute = (route) => {
  return {
    type: SET_NEXT_ROUTE,
    payload: route
  };
};
