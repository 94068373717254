import React, {useState} from 'react';
import styles from './SignUpForm.module.scss';

import classNames from 'classnames';

import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';

import * as fromSettingsSelectors from 'store/selectors/settings';

import { useTranslate } from 'utils/translator';
import { matchPasswords, validateEmail } from 'utils/validator';
import REGEXES from 'utils/configuration/const/regexes';
import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';

import {Button, InputNext, InputPassword, Link, Modal} from 'ui/basic';

const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_'
};


const SignUpForm = (props) => {
  const { error, onSignUp } = props;

  const translate = useTranslate();
  const history = useHistory();

  const registrationDomains = useSelector(fromSettingsSelectors.getRegistrationDomains);

  const myParams = history.location.search ?
    history.location.search.split('?mail=')[1].split('&invitationId=') :
    [];

  const paramsEmail = myParams[0];
  const invitationId = myParams[1];

  const [email, setEmail] = useState(paramsEmail || '');
  const [password, setPassword] = useState();
  const [confirmedPassword, setConfirmedPassword] = useState();
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [showDomainsInfo, setShowDomainsInfo] = useState(false);

  const emailHint = email &&
    (translate('signup_form_email_error_hint_format') ||
      'Email format ist falsch');

  const noMatchHint = password &&
    (translate('signup_form_password_error_hint_notmatching') ||
      'Eingegebene Passworte sind nicht ähnlich');

  const handleSignUp = (event) => {
    event.preventDefault();

    if (!invitationId && (!email || !validateEmail(email))) {
      return;
    }

    if (invitationId &&
      (!email || !password || !passwordIsValid || !validateEmail(email) || !matchPasswords(password, confirmedPassword))) {
      return;
    }

    onSignUp({
      mail: email,
      password,
      invitationId
    });
  };

  return (
    <div className={styles.signUpForm}>
      <div className={styles.title}>
        {translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}signup_title`) || 'Willkommen bei bluquist'}
      </div>
      <div className={styles.subTitle}>
        {invitationId ?
          (translate('signup_set_pwd_copy') || 'Definiere ein sicheres Passwort für deinen Account.') :
          (translate('signup_copy') || 'Erstelle einen Account um loszulegen.')}
      </div>

      <form onSubmit={handleSignUp}>
        <div className={styles.inputs}>
          <InputNext
            placeholder={translate('signup_form_email_label') || 'Email'}
            hint={validateEmail(email) || emailHint}
            value={email}
            validate={{
              pattern: REGEXES.EMAIL,
              onChange: true
            }}
            disabled={!!paramsEmail}
            errorMessage={error}
            onChange={value => setEmail(value)}
          />

          {!invitationId &&
          <div className={classNames(styles.domains, {[styles.noTopPadding]: ((email && !validateEmail(email)) || error)})}>
            <span onClick={() => setShowDomainsInfo(true)}>
              {translate('reg_domains_hint') || 'Show valid domains '}
            </span>
          </div>
          }

          {invitationId &&
          <>
            <InputPassword
              placeHolder={translate('signup_form_password_label') || 'Passwort'}
              className={styles.passwordInput}
              value={password}
              showCriteria={true}
              onInputChanged={(value, correct) => {
                setPasswordIsValid(correct);
                setPassword(value);
              }}
            />
            <InputPassword
              type={'password'}
              placeHolder={translate('signup_form_password_repeat_label') || 'Passwort wiederholen'}
              name="confirmedPassword"
              className={styles.passwordInput}
              hint={matchPasswords(password, confirmedPassword) || noMatchHint}
              value={confirmedPassword}
              onChange={(currentInput, value) =>
                setConfirmedPassword(value)
              }
            />
          </>
          }

        </div>
        <div className={styles.bottom}>
          {/*hide 'Login' link if sign-up is being processed after invitation */}
          {!paramsEmail && <Link to={'/'}>{translate('login_button_login') || 'Login'}</Link>}
          <Button
            disabled={!invitationId ?
              (!email || !validateEmail(email)) :
              (!email ||
                !password ||
                !passwordIsValid ||
                !validateEmail(email) ||
                !matchPasswords(password, confirmedPassword)
              )}
            type={'submit'}
          >
            {translate('signup_button_signup') || 'Account erstellen'}
          </Button>
        </div>
      </form>

      {showDomainsInfo &&
        <Modal
          header={translate('reg_domains_info_title') || 'Domain validation'}
          secondaryButtonTitle={translate('okay_lbl')}
          onClose={() => setShowDomainsInfo(false)}
        >
          <div className={styles.infoDescription}>
            <div>
              {translate('reg_domains_info_descr') || 'Following domain ending(s) are valid for registration:'}
            </div>
            <div>
              {registrationDomains.map((registrationDomain, index) => (
                <span key={`reg-domain${index}`}>{registrationDomain}</span>
              ))}
            </div>
          </div>
        </Modal>
      }

    </div>
  );
};

export default SignUpForm;
