// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './StartAssessments.module.scss';

// ASSETS
import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import {LANGUAGES} from 'utils/configuration/const/languages';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: StartAssessments
const StartAssessments = (props) => {
  // PROPS
  const {
    state = { inject: { teasers: [] } },
    controls = () => {}
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // HANDLES
  const handleHelpClick = () => {
    const currentLanguage = localStorage.getItem('selectedLanguage') || LANGUAGES.EN;
    const helpLink = currentLanguage === 'de_DE' ? 'https://help.bluquist.com/' : 'https://help.bluquist.com/en/';
    window.open(helpLink, '_blank');
  };

  // RENDER: StartAssessments
  return (
    <div className={classNames(styles.startAssessments)}>
      <div className={styles.content}>
        <div className={styles.title}>
          { translate('onboarding_candidate_start_assessment_title' || 'Starte dein erstes Assessment.')}
        </div>
      </div>
      <div className={classNames(styles.content, styles.breakoutContent)}>
        { state.inject.teasers }
      </div>
      <div className={classNames(styles.content, styles.continue)}>
        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        )}

        <div className={styles.helpText}>
          { translate('onboarding_candidate_start_assessment_text')
            || 'Du hast noch Fragen zur Nutzung von bluquist oder zur Verarbeitung deiner Daten?'
          }
        </div>

        <div className={styles.helpButton}>
          <Button
            looks='secondary'
            leadingIcon={IconsSvg.BqContact}
            onClick={handleHelpClick}
          >
            { translate('onboarding_candidate_start_assessment_help_center') || 'Zum Help-Center'}
          </Button>

        </div>

      </div>
    </div>
  );
};

export default StartAssessments;
