// map a components this.props.looks to a joined string of css module styles `styles[look1] styles[look2] ...`
// or allows to pass in css class names from parent
// <Component looks="isYellow"></Component>
// CSS: .isYellow { }
export function mapLooksToStyles(
  looks = '',
  styles = []
) {
  let customStyles = (looks && looks.trim().split(' ')) || [];
  return customStyles.map(look => styles[look] || look).join(' ');
}

