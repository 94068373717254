// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useState} from 'react';
import styles from './ProfileSummary.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {Button, HotChipsPeople, Modal, OptionsMenu, Profile} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { useLatestCall } from 'utils/hooks';
import {MAPPED_PLATFORM_ROLES} from 'utils/configuration/const/roles';

// STORE
import * as api from 'api';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectCapabilitiesNext } from 'store/selectors/configuration';

// CONFIG & DATA
// const Config = {};

const OPTION_TYPES = {
  OPEN_AVAILABLE_ASSESSMENTS: 'OPEN_AVAILABLE_ASSESSMENTS',
  GO_TO_USER_SETTINGS: 'GO_TO_USER_SETTINGS',
  SHARE_PROFILE_LINK: 'SHARE_PROFILE_LINK',
  EXPORT_ASSESSMENTS_RESULTS: 'EXPORT_ASSESSMENTS_RESULTS'
}

// COMPONENT: ProfileSummary
const ProfileSummary = (props) => {
  // PROPS
  const {
    userRole,
    candidate,
    showControls = true,
    onAssessmentsConfigure = () => {},
    onProfileShare = () => {},
    onVacanciesAdd = () => {},
    onAssessmentsResultsExport = () => {}
  } = props;

  const capabilitiesNext = useSelector(selectCapabilitiesNext);
  const canEdit = !candidate.disabled && capabilitiesNext.vacanciesCreate;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();

  // NEW VACANCIES TO ADD THE CANDIDATE TO: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [addToVacanciesModalVisible, setAddToVacanciesModalVisible] = useState();
  const [addToVacanciesProcessing, setAddToVacanciesProcessing] = useState();

  const [newVacancies, setNewVacancies] = useState([]);
  const [ newVacanciesSearchLoading, newVacanciesSearchResults, setNewVacanciesSearchString ] = useLatestCall(
    'recruiting/jobs',
    ({ jobs }) => jobs.map(({ id, name }) => ({ value: id, label: name })),
  );

  const handleAddToNewVacancies = () => {
    setAddToVacanciesProcessing(true);

    Promise.all(newVacancies.map(vacancy => {
        return api.post(`recruiting/jobs/${vacancy.value}/candidates`, {
          users: [{id: candidate.id}]
        })
      })
    ).then(responseArray => {
      const response = responseArray[0];
      setAddToVacanciesProcessing(false);
      if (response.ok && response.status === 200) {
        setAddToVacanciesModalVisible(false);
        onVacanciesAdd();
      }
    }, reason => {
      setAddToVacanciesProcessing(false);
      console.error(reason);
    });

  }

  // MENU OPTIONS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const opt = [];

    if (!candidate.disabled) {
      opt.push({value: OPTION_TYPES.OPEN_AVAILABLE_ASSESSMENTS, label: translate('available_assessments_option_lbl') || 'Verfügbare Assessments'});
      opt.push({value: OPTION_TYPES.SHARE_PROFILE_LINK, label: translate('manage_profile_links') || 'Profil-Link verwalten'});
    }

    if (userRole === MAPPED_PLATFORM_ROLES.COMPANY_ADMINISTRATOR) {
      opt.splice(1, 0, {value: OPTION_TYPES.GO_TO_USER_SETTINGS, label: translate('user_settings_lbl') || 'Nutzereinstellungen'})
    }

    if (window.location.pathname.includes('assessments')) {
      opt.push({value: OPTION_TYPES.EXPORT_ASSESSMENTS_RESULTS, label: translate('export_assessments_results') || 'Ergebnisgrafiken exportieren'});
    }

    setOptions(opt);
  }, [candidate.disabled, userRole, translate]);


  // RENDER: ProfileSummary
  return (
    <div className={classNames(styles.profileSummary)}>
      <div>
        <Profile user={candidate} showCompanyData={false} />

        {showControls &&
        <div className={styles.buttons}>
          { canEdit &&
          <Button
            size='S'
            looks='secondary'
            onClick={() => setAddToVacanciesModalVisible(true)}
          >
            {translate('add_to_vacancy_btn') || 'Zu Vakanz hinzufügen'}
          </Button>
          }

          {options.length > 0 &&
          <OptionsMenu
            withBackground
            flyoutPosition={canEdit ? 'right' : 'left'}
            options={options}
            onClick={value => {
              switch (value) {
                case OPTION_TYPES.OPEN_AVAILABLE_ASSESSMENTS:
                  if (onAssessmentsConfigure) {
                    onAssessmentsConfigure();
                  }
                  break;
                case OPTION_TYPES.GO_TO_USER_SETTINGS:
                  history.push(`/settings/users/${candidate.id}`);
                  break;
                case OPTION_TYPES.SHARE_PROFILE_LINK:
                  if (onProfileShare) {
                    onProfileShare();
                  }
                  break;
                case OPTION_TYPES.EXPORT_ASSESSMENTS_RESULTS:
                  onAssessmentsResultsExport();
                  break;
                default:
              }
            }}
          />
          }
        </div>
        }
      </div>

      {addToVacanciesModalVisible &&
      <Modal
        header={translate('add_vacancies_to_candidate_long') || 'Kandidat:in einer Vakanz zuweisen'}
        primaryButtonTitle={translate('add_lbl') || 'Hinzufügen'}
        primaryButtonDisabled={addToVacanciesProcessing || newVacancies.length === 0}
        secondaryButtonTitle={translate('cancel_lbl') || 'Abbrechen'}
        closeOnConfirm={false}
        onConfirm={handleAddToNewVacancies}
        onClose={() => setAddToVacanciesModalVisible(false)}
      >
        <div className={styles.addToVacanciesContent}>
          {translate('add_vacancies_to_candidate_copy') ||
          'Das Zuweisen von Vakanzen ermöglicht es dir, Kandidat:innen offenen Stellen (Vakanzen) zuzuordnen und ihre Passung für die zugrundeliegende Rolle zu ermitteln.'
          }

          <HotChipsPeople
            placeholder={translate('add_vacancies_to_candidate_placeholder') || 'Name der Vakanz'}
            label={translate('add_vacancies_to_candidate_label') || 'Vakanzen auswählen'}
            values={newVacancies}
            searchResults={newVacanciesSearchResults}
            loadingSearchResults={newVacanciesSearchLoading}
            noResultsHint={translate('add_vacancies_to_candidate_no_results')}
            onUpdate={vacancies => setNewVacancies(vacancies)}
            onSearch={setNewVacanciesSearchString}
          />
        </div>
      </Modal>
      }
    </div>
  );
};

export default ProfileSummary;
