import * as fromActionTypes from 'store/actionTypes/admin';

const initialState = {
  loading: false,
  companyUserDeleted: false,
  deletedUserId: null,
  deletedUserName: '',
  companyUserRemoved: false,
  removedUserId: null,
  removedUserName: '',
  removeCompanyUserProcessing: false,
  addCompanyUsersProcessing: false,
  addCompanyUsersSuccess: false,
  editUserRoleError: null,
  error: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case fromActionTypes.INIT_STATE: {
      return {
        ...initialState
      };
    }
    case fromActionTypes.INIT_ADD_COMPANY_USERS: {
      return {
        ...state,
        addCompanyUsersProcessing: false,
        addCompanyUsersSuccess: false,
        error: null
      };
    }
    case fromActionTypes.ADD_COMPANY_USERS: {
      return {
        ...state,
        addCompanyUsersProcessing: true,
        addCompanyUsersSuccess: false
      };
    }
    case fromActionTypes.ADD_COMPANY_USERS_FULFILLED: {
      return {
        ...state,
        addCompanyUsersProcessing: false,
        addCompanyUsersSuccess: true,
        error: null
      };
    }
    case fromActionTypes.ADD_COMPANY_USERS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        addCompanyUsersProcessing: false,
        addCompanyUsersSuccess: false,
        error
      };
    }
    case fromActionTypes.EDIT_COMPANY_USER_ROLE: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.EDIT_COMPANY_USER_ROLE_FULFILLED: {
      return {
        ...state,
        loading: false,
        editUserRoleError: null
      };
    }
    case fromActionTypes.EDIT_COMPANY_USER_ROLE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        editUserRoleError: error
      };
    }
    case fromActionTypes.INIT_DELETE_COMPANY_USER: {
      return {
        ...state,
        companyUserDeleted: false,
        deletedUserId: null,
        deletedUserName: '',
        loading: false
      };
    }
    case fromActionTypes.DELETE_COMPANY_USER: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActionTypes.DELETE_COMPANY_USER_FULFILLED: {
      const {deletedUserId, deletedUserName} = action.payload;
      return {
        ...state,
        loading: false,
        companyUserDeleted: true,
        deletedUserId,
        deletedUserName,
        error: null
      };
    }
    case fromActionTypes.DELETE_COMPANY_USER_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        companyUserDeleted: false,
        error
      };
    }
    case fromActionTypes.INIT_REMOVE_COMPANY_USER: {
      return {
        ...state,
        companyUserRemoved: false,
        removedUserId: null,
        removedUserName: '',
        loading: false
      };
    }
    case fromActionTypes.REMOVE_COMPANY_USER: {
      return {
        ...state,
        removeCompanyUserProcessing: true
      };
    }
    case fromActionTypes.REMOVE_COMPANY_USER_FULFILLED: {
      const {removedUserId, removedUserName} = action.payload;
      return {
        ...state,
        loading: false,
        companyUserRemoved: true,
        removedUserId,
        removedUserName,
        removeCompanyUserProcessing: false,
        error: null
      };
    }
    case fromActionTypes.REMOVE_COMPANY_USER_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        companyUserRemoved: false,
        removeCompanyUserProcessing: false,
        error: error
      };
    }
    default: {
      return state;
    }
  }
};
