import React from 'react';
import styles from './ResetPassword.module.scss';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {resetPassword} from 'store/actions';

import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';

import ResetPasswordForm from '../../components/ResetPasswordForm';
import ResetPasswordSuccessForm from '../../components/ResetPasswordSuccessForm';
import PoweredBy from 'ui/basic/micro-ui/PoweredBy';


const ResetPassword = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const error = useSelector(state => state.resetPassword && state.resetPassword.error);
  const resetPasswordSuccessful = useSelector(state => state.resetPassword.status === 'valid');

  const handleResetPassword = (password) => {
    dispatch(resetPassword(password, params.token))
  };

  return (
    <div className={styles.resetPassword}>
      <div className={classNames(styles.container, styles[CONFIGURATION.toLowerCase()])}>
        {CONFIGURATION !== CONFIGURATION_OPTIONS.BLUQUIST &&
        <div className={styles.poweredBy}>
          <PoweredBy />
        </div>
        }

        {resetPasswordSuccessful ?
          <ResetPasswordSuccessForm/> :
          <ResetPasswordForm
            error={error && error.error && error.error.errorMessage}
            onResetPassword={handleResetPassword}
          />
        }
      </div>
    </div>
  );
};

export default ResetPassword;
