import * as fromActions from 'store/actions/configuration';

const initialState = {
  initialised: false,
  loading: false,

  deployment: {
    isAdminFrontend: false,
  },

  company: {
    // tbd:
    companyId: '',
    customLogoUrl: '',
    hasExternalLegalAgreements: false,

    // api spec:
    // "invitationDomainLimit": "string",
    // "openRegistrationDomains": [
    // null
    // ],
    // "wellbeingMails": true,
    // "assessmentReminderMails": true,
    // "externalDataProcessingContext": true,
    // "custom": { }

  },
  features: [],
  userGroup: '',
  userSubGroup: '',
  switches: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fromActions.GET_CONFIGURATION:
    case fromActions.GET_CONFIGURATION_EXTENDED: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActions.GET_CONFIGURATION_SUCCESS: {
      const { company, deployment } = action.payload;

      return {
        ...state,
        deployment,
        company,
        loading: false,
        initialised: true,
      };
    }
    case fromActions.GET_CONFIGURATION_ERROR:
    case fromActions.GET_CONFIGURATION_EXTENDED_ERROR: {
      return {
        ...state,
        loading: false,
      }
    }
    case fromActions.GET_CONFIGURATION_EXTENDED_SUCCESS: {
      let { features, userGroup, userSubGroup, switches } = action.payload;

      features = features || state.features;
      userGroup = userGroup || state.userGroup;
      userSubGroup = userSubGroup || state.userSubGroup;
      switches = switches || state.switches;
      return {
        ...state,
        loading: false,
        features,
        userGroup,
        userSubGroup,
        switches,
      }
    }
    case fromActions.SET_CONFIGURATION_CUSTOM_LOGO_URL: {
      const { customLogoUrl } = action.payload;
      const company = {
        ...state.company,
        customLogoUrl
      };
      return {
        ...state,
        company
      };
    }
    default: {
      return state;
    }
  }
}
