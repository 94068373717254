export const getTeams = store => (
  (store.teamOverview && store.teamOverview.teams) && store.teamOverview.teams
);

export const getTeamsTotalCount = store => (
  (store.teamOverview && store.teamOverview.totalCount) && store.teamOverview.totalCount
);

export const getTeam = store => (
  (store.teamOverview && store.teamOverview.team) && store.teamOverview.team
);


export const getLoading = store => (
  (store.teamOverview && store.teamOverview.team) && store.teamOverview.loading
);

export const getTeamTypes = store => (
  (store.teamOverview && store.teamOverview.teamTypes) ? store.teamOverview.teamTypes : []
);

export const getTeamProfiles = store => (
  (store.teamOverview && store.teamOverview.teamProfiles) && store.teamOverview.teamProfiles
);

export const getTeamProfilesLoading = store => (
  store.teamOverview && store.teamOverview.teamProfilesLoading
);

export const getPositions = store => (
  (store.teamOverview && store.teamOverview.positions) && store.teamOverview.positions
);

export const getTeamCreationSuccess = store => (
  store.teamOverview && store.teamOverview.teamCreationSuccess
);

export const getTeamCreationProcessing = store => (
  store.teamOverview && store.teamOverview.teamCreationProcessing
);

export const getTeamCreationError = store => (
  store.teamOverview && store.teamOverview.teamCreationError
);

export const getCreatedTeamId = store => (
  store.teamOverview && store.teamOverview.createdTeamId
);

export const addUsersSuccess = store => (
  store.teamOverview && store.teamOverview.addUsersSuccess
);

export const addUsersProcessing = store => (
  store.teamOverview && store.teamOverview.addUsersProcessing
);

export const addPositionsSuccess = store => (
  store.teamOverview && store.teamOverview.addPositionsSuccess
);

export const addPositionsProcessing = store => (
  store.teamOverview && store.teamOverview.addPositionsProcessing
);

export const getDeleteTeamUserSuccess = store => (
  store.teamOverview && store.teamOverview.deleteTeamUserSuccess
);

export const getUpdateUserRoleSuccess = store => (
  store.teamOverview && store.teamOverview.updateUserRoleSuccess
);

export const getUpdateUserRole = store => (
  store.teamOverview && store.teamOverview.updatedUserRole
);

export const getFillPositionProcessing = store => (
  store.teamOverview && store.teamOverview.fillPositionProcessing
);

export const getFillPositionSuccess = store => (
  store.teamOverview && store.teamOverview.fillPositionSuccess
);

export const getFillPositionError = store => (
  store.teamOverview && store.teamOverview.fillPositionError
);

export const getDeletePositionSuccess = store => (
  store.teamOverview && store.teamOverview.deletePositionSuccess
);

export const getError = store => store.teamOverview?.error;
