export const selectCountries = (state) => {
  return state.staticValues.countries;
};

export const selectNationalities = (state) => {
  return state.staticValues.nationalities;
};

export const selectEducation = (state) => {
  return state.staticValues.education;
};

export const selectEducationOptions = (state) => {
  return state.staticValues.educationOptions;
};

export const selectIndustries = (state) => {
  return state.staticValues.industries;
};

export const selectIndustryOptions = (state) => {
  return state.staticValues.industryOptions;
};

export const selectCurrencies = (state) => {
  return state.staticValues.currencies;
};

export const selectCurrencyOptions = (state) => {
  return state.staticValues.currencyOptions;
};

export const selectCareerLevels = (state) => {
  return state.staticValues.careerLevels;
};

export const selectCareerLevelOptions = (state) => {
  return state.staticValues.careerLevelOptions;
};

export const selectJobFamilies = (state) => {
  return state.staticValues.jobFamilies;
};

export const selectJobFamilyOptions = (state) => {
  return state.staticValues.jobFamilyOptions;
};

