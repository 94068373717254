// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './AssessmentEnd.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator/translator';
// import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
// import { ASSESSMENT_NEXT_TYPES } from '../../AssessmentNext.config';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: AssessmentEnd
const AssessmentEnd = (props) => {
  // PROPS
  const {
    assessmentType,
    finishable,
    handleFinish = () => {},
    errorEnd
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleViewResults = () => {
    handleFinish(() => history.push(`/my-profile?goTo=${assessmentType}`));
  }

  // RENDER: AssessmentEnd
  return (
    <div className={classNames(styles.assessmentEnd)}>
      { errorEnd }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role='img' aria-label='applause'>&#128588;</span>

          <div className={styles.sTitle}>
            {translate('assessment_success')}
          </div>

          <div className={styles.buttons}>
            <Button
              size={'M'}
              looks={'secondary'}
              disabled={!finishable}
              onClick={handleViewResults}
            >
              {translate('assessment_view_results')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AssessmentEnd;
