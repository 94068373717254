import React, { useEffect, useState } from 'react';
import styles from './UserInvitation.module.scss';

/* ASSETS */
import { Icons } from 'assets/icons';

/* 3RD PARTY */
import { useDispatch, useSelector } from 'react-redux';

import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

/* STORE */
import { addCompanyUsers, getPlatformRoles, getSettings, initAddCompanyUsers } from 'store/actions';
import { useHistory } from 'react-router';
import * as rolesSelector from 'store/selectors/roles';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { selectCapabilitiesNext } from 'store/selectors/configuration';

/* UTILS */
import { useTranslate } from 'utils/translator';
import { getFilteredRoles } from 'utils/roles';
import REGEXES from 'utils/configuration/const/regexes';
import { CONFIGURATION, CONFIGURATION_OPTIONS } from 'utils/configuration';
import { MAPPED_PLATFORM_ROLES, PLATFORM_ROLES } from 'utils/configuration/const/roles';

/* OTHER COMPONENTS */
import { Icon, Button, DropDown, Toggle, BluCSSTransition, TextArea, HotChips, Checkbox } from 'ui/basic';
import { selectCompanyFeatures } from 'store/selectors/settings';

const CONFIGURATION_PREFIX = {
  [CONFIGURATION_OPTIONS.BLUQUIST]: '',
  [CONFIGURATION_OPTIONS.BALANCED_YOU]: 'by_'
};

const ROLES_FOR_COMPANY_LEADER = [
  {name: PLATFORM_ROLES.COMPANY_USER, accessKey: 'employeesInvite'},
  {name: PLATFORM_ROLES.CANDIDATE, accessKey: 'candidatesInvite'}
];

const DEFAULT_OPTION_DESCRIPTION =
  'Kann Assessments durchführen, Rollen & Teams erstellen und ' +
  'hat Zugriff auf Profile von Team-Mitgliedern (direct reports).';

const DEFAULT_EMAILS_DESCRIPTION =
  'Gib die E-Mail-Adressen der Mitarbeiter ein, die du hinzufügen möchtest.' +
  'Wir können leider keine Einladungen an Verteilerlisten versenden.';

const DEFAULT_EMAIL =
  'Hallo,\n' +
  'Du wurdest zu bluquist eingeladen.\n' +
  'Erstelle deinen Account um bluquist zu verwenden und dein ganzes Potenzial zu erkennen.';

const getRoleOptionLabel = (role, translate) => {
  return (
    <div className={styles.option}>
      <div className={styles.optionTitle}>{translate(`platform_roles_${role}`) || role.toLowerCase()}</div>
      <div className={styles.optionDescription}>
        { translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}platform_roles_descr_${role}`) ||
          DEFAULT_OPTION_DESCRIPTION}
      </div>
    </div>
  )
};


const UserInvitation = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const customEmailBaseValue = translate('invite_emp_customization_default');

  const [ emails, setEmails ] = useState([]);
  const [ userGroup, setUserGroup ] = useState();
  const [ customEmail, setCustomEmail ] = useState(customEmailBaseValue || DEFAULT_EMAIL);
  const [ showCustomEmailBlock, setShowCustomEmailBlock ] = useState(false);
  const [ platformRoles, setPlatformRoles ] = useState();

  const [ availableAssessments, setAvailableAssessments ] = useState([]);

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const allPlatformRoles = useSelector(state => rolesSelector.getPlatformRoles(state));
  const addCompanyUsersProcessing = useSelector(state => state.admin.addCompanyUsersProcessing);
  const addCompanyUsersSuccess = useSelector(state => state.admin.addCompanyUsersSuccess);
  const addCompanyUsersError = useSelector(state => state.admin.error);

  useEffect(() => {
    dispatch(getPlatformRoles());
  }, [dispatch]);

  const companyFeatures = useSelector(selectCompanyFeatures);
  const [ companyFeaturesRequested, setCompanyFeaturesRequested ] = useState(false);
  useEffect(() => {
    if (companyFeatures.length || companyFeaturesRequested) {
      return;
    }

    setCompanyFeaturesRequested(true);
    dispatch(getSettings());
  }, [ dispatch, companyFeatures, companyFeaturesRequested ]);

  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessmentsWithNoClifton);

  useEffect(() => {
    if (!companyFeatures || availableAssessments.length > 0 || CONFIGURATION !== CONFIGURATION_OPTIONS.BLUQUIST) {
      return;
    }

    const availableAssessmentsInternal = companyFeatures
    .filter(feature => feature?.category.includes('assessment') && feature.active)
    .map(feature => ({
      id: feature.id,
      name: feature.name,
      active: !!feature.defaultUserActive
    }));

    setAvailableAssessments(availableAssessmentsInternal);
  }, [companyFeatures, assessmentsFromStore, availableAssessments.length]);

  useEffect(() => {
    if (addCompanyUsersSuccess) {
      history.goBack();
    }
  }, [addCompanyUsersSuccess, history]);

  useEffect(() => {
    const filteredRoles = getFilteredRoles(allPlatformRoles);

    setPlatformRoles(filteredRoles
    .filter(role => {
      if (me.role !== MAPPED_PLATFORM_ROLES.COMPANY_LEADER) {
        return true;
      }

      const thisRole = ROLES_FOR_COMPANY_LEADER.find(r => r.name === role);

      return thisRole && (!thisRole.accessKey || capabilitiesNext[thisRole.accessKey]);
    })
    .map(role => ({
      value: role,
      label: getRoleOptionLabel(role.split('_CUSTOM')[0].toLowerCase(), translate)
    }))
    )
  }, [ allPlatformRoles, translate, capabilitiesNext, me ]);

  useEffect(() => {
    if (!platformRoles?.length) {
      return;
    }

    const splitValues = window.location.search.split('?type=');
    const type = splitValues[1];
    const defaultRole = type === 'candidate' ? PLATFORM_ROLES.CANDIDATE : PLATFORM_ROLES.COMPANY_USER;
    const newUserGroup = platformRoles.find(el => el.value === defaultRole) ?? platformRoles[0];
    setUserGroup(newUserGroup);
  }, [ platformRoles ]);

  // COMPONENT UNMOUNT
  useEffect(() => {
    return () => {
      dispatch(initAddCompanyUsers());
    }
  }, [dispatch]);

  const handleSubmit = () => {
    const mails = emails
    .filter(email => email.length > 0)
    .map(email => ({
      mail: email,
      role: userGroup.value,
      features: availableAssessments.map(assessment => ({
        id: assessment.id,
        active: assessment.active
      }))
    }));

    const message = customEmail !== customEmailBaseValue
      ? customEmail
      : undefined;

    dispatch(addCompanyUsers({
      mails,
      message
    }));
  };

  if (!platformRoles) {
    return null;
  }

  return (
    <div className={styles.userInvitation}>
      <div className={styles.header}>
        <span>{ translate('invite_emp_header_title') || 'Mitarbeiter einladen' }</span>
        <div
          className={styles.close}
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={Icons.CloseBig}/>
        </div>
      </div>

      <div className={styles.bodyContainer}>
        <div className={styles.body}>
          <div className={styles.main}>

            {/* TITLE */}
            <div className={styles.title}>
              { translate(`${CONFIGURATION_PREFIX[CONFIGURATION]}invite_emp_title`) || 'Lade Mitarbeiter zu bluquist ein.' }
            </div>
            <div className={styles.emails}>

              {/* DESCRIPTION */}
              <div className={styles.description}>
                { translate('invite_emp_emails_description') || DEFAULT_EMAILS_DESCRIPTION }
              </div>

              {/* HOTCHIPS */}
              <div className={styles.hotChips}>
                <HotChips
                  label={translate('invite_emp_emails_label') || 'Mitarbeitende hinzufügen'}
                  placeholder={translate('invite_emp_emails_placeholder') || 'Email-Adresse der Mitarbeitenden'}
                  hint={translate('invite_emp_emails_hint') || 'Gib eine gültige Email-Adresse ein und bestätige anschließend mit Enter.'}
                  errorHint={translate('invite_emp_emails_hint_invalid') || 'Bitte gib eine gültige Email Adresse ein'}
                  errorHintDuplicate={translate('invite_emp_emails_hint_duplicate') || 'Diese Email Adresse ist bereits in der Liste. Gib eine neue ein!'}
                  confirmHint={translate('invite_emp_emails_confirm_hint')}
                  values={emails}
                  onUpdate={(emails) => setEmails(emails)}
                  validate={{
                    pattern: REGEXES.EMAIL
                  }}
                />
              </div>

              {/* ERROR */}
              <div className={styles.error}>
                {addCompanyUsersError && addCompanyUsersError.errorMessage}
              </div>

            </div>

            {/* USER GROUPS */}
            <div className={styles.userGroups}>
              <div className={styles.description}>
                { translate('invite_emp_usergroups_description') || 'Wähle die Nutzergruppe für die einzuladenden Mitarbeiter aus.' }
              </div>
              <div className={styles.select}>
                <DropDown
                  size={'L'}
                  disabled={platformRoles && platformRoles.length === 1}
                  selectedOption={userGroup}
                  placeholder={translate('invite_emp_usergroups_placeholder') || 'Nutzergruppe auswählen'}
                  options={platformRoles}
                  onChange={option => setUserGroup(option)}
                />
              </div>
            </div>

            <div className={styles.customBlock}>
              <div className={styles.visible}>
                <span className='blu-typeLabel'>
                  { translate('invite_emp_customization_title') || 'Einladung personalisieren' }
                </span>
                <Toggle onChange={(value) => setShowCustomEmailBlock(value)}/>
              </div>
              <BluCSSTransition
                in={showCustomEmailBlock}
                classNames={{...styles}}
              >
                <div className={styles.customEmail}>
                  <div className={styles.title}>
                    { translate('invite_emp_customization_description') ||
                    'Personalisiere die Einladung, die an die einzuladenden Mitarbeiter versandt wird.'
                    }
                  </div>
                  <div className={styles.textarea}>
                    <TextArea
                      placeholder={translate('invite_emp_customization_placeholder') || 'Your personalized text'}
                      value={customEmail}
                      onChange={(value) => setCustomEmail(value)}
                    />
                  </div>
                </div>
              </BluCSSTransition>
            </div>

            {/*ASSESSMENTS*/}
            {CONFIGURATION === CONFIGURATION_OPTIONS.BLUQUIST &&
            <div className={styles.assessments}>
              <div className={styles.xxsTitle}>
                { translate('invite_emp_select_assessments_title') || 'Verfügbare Assessments wählen' }
              </div>

              <div className={styles.list}>
                { availableAssessments.map((assessment, index) => (
                  <Checkbox
                    key={index}
                    name={assessment.name}
                    checked={assessment.active}
                    onChange={value => {
                      const thisAssessment = availableAssessments.find(availableAssessment => availableAssessment.id === assessment.id);

                      thisAssessment.active = value;
                    }}
                  />
                  ))}
              </div>
            </div>
            }

            {/* BUTTONS */}
            <div className={styles.buttons}>
              <Button
                size={'S'}
                disabled={!emails.length || !userGroup || addCompanyUsersProcessing}
                onClick={handleSubmit}
              >
                { translate('invite_emp_btn_invite') || 'Benutzer einladen' }
              </Button>
              <Button
                size={'S'}
                looks={'secondary'}
                onClick={() => history.goBack()}
              >
                { translate('invite_emp_btn_cancel') || 'Abbrechen' }
              </Button>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
};

export default UserInvitation;
