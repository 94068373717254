import React, { useState, useEffect, useCallback } from 'react';
import styles from './Slider.module.scss';
import ReactSlider from 'react-slider';
import classNames from 'classnames';
import {isValid} from 'utils/numbers';

const CONFIG = {
  overlapSharpness: 0.99, // 1 = 100%, setting it to low results in not being able to move outer knob more when it's close to the edge
};


const Slider = (props) => {
  const {min, max, minDistance, size, defaultValue, value, step, showCircles, showVerticalLine, showRmpLines, onChange} = props;

  // STATE
  const [ valueOverlapLeft, setValueOverlapLeft ] = useState(false);
  const [ valueOverlapRight, setValueOverlapRight ] = useState(false);

  // METHODS
  const checkValueOverlapRight = useCallback((valuesInternal) => {
    let overlap = false;
    if (minDistance === 0) {
      let leftValue = 0;
      if (valuesInternal && valuesInternal.length) {
        leftValue = valuesInternal[0];
      }
      else if (value && value.length) {
        leftValue = value[0];
      }

      if (leftValue >= max * CONFIG.overlapSharpness) {
        overlap = true;
      }
      // console.log('leftValue', leftValue, max * CONFIG.overlapSharpness, max);
    }
    // console.log('rightOverlap', overlap);
    setValueOverlapRight(overlap);
  }, [max, minDistance, value]);

  const checkValueOverlapLeft = useCallback((valuesInternal) => {
    let overlap = false;
    if (minDistance === 0) {
      let rightValue = 0;
      if (valuesInternal && valuesInternal.length) {
        rightValue = valuesInternal[1];
      }
      else if (value && value.length) {
        rightValue = value[1];
      }

      if (rightValue <= min + max * (1 - CONFIG.overlapSharpness)) {
        overlap = true;
      }
    }
    // console.log('leftOverlap', overlap);
    setValueOverlapLeft(overlap);
  }, [min, max, minDistance, value]);


  // EFFECTS
  useEffect(() => {
    // console.log('effect');
    checkValueOverlapLeft();
    checkValueOverlapRight();
  }, [checkValueOverlapLeft, checkValueOverlapRight]);


  // RENDERS
  const renderTrack = (props, state) => {
    if (isValid(defaultValue) && !Array.isArray(defaultValue)) {
      return (
        <div
          {...props}
          className={styles.track}
        >
        </div>
      );
    }

    return (
      <div
        {...props}
        className={classNames(
          styles.track,
          {[styles.blue]: state.index === 1}
        )}
      >
      </div>
    )
  };

  const getCircles = () => {
    const circles = [];
    for (let i=0; i<max; i++) {
      circles.push(
        <div
          key={`circle-${i}`}
          className={styles.circle}>
        </div>
      );
    }
    return circles;
  };

  return (
    <div className={classNames(styles.sliderRoot, {
      [styles[`size${size.toUpperCase()}`]]: size,
      [styles.hasValueOverlapRight]: valueOverlapRight,
      [styles.hasValueOverlapLeft]: valueOverlapLeft
    })}>
      {/*CIRCLES*/}
      {showCircles &&
      <div className={styles.circles}>
        {getCircles()}
      </div>}
      {/*VERTICAL LINE ON CENTER*/}
      {showVerticalLine && <div className={styles.verticalLine}></div>}
      {/*RMP LINES*/}
      {showRmpLines &&
      <div className={styles.rmpLines}>
        <div className={classNames(styles.line, styles.center)}></div>
        <div className={classNames(styles.line, styles.left1)}></div>
        <div className={classNames(styles.line, styles.left2)}></div>
        <div className={classNames(styles.line, styles.right1)}></div>
        <div className={classNames(styles.line, styles.right2)}></div>
      </div>
      }

      <ReactSlider
        className={styles.slider}
        thumbClassName={styles.thumb}
        trackClassName={styles.track}
        value={value}
        defaultValue={defaultValue}
        minDistance={minDistance}
        min={min}
        max={max}
        step={step}
        onChange={(value) => {
          // console.log('Slider values', value);
          let valueInternal = value;
          if (Array.isArray(value)) {
            valueInternal = value[1] > max ? [value[0], max] : (value[1] < min ? [min, value[0]] : value)
          }

          checkValueOverlapLeft(valueInternal);
          checkValueOverlapRight(valueInternal);

          if (onChange) {
            onChange(valueInternal)
          }
        }}
        renderTrack={renderTrack}
      />
    </div>
  )
};

Slider.defaultProps = {
  min: 1,
  max: 10,
  size: 'L',
  minDistance: 1,
  defaultValue: [1, 10],
  showVerticalLine: false,
  showRmpLines: false
};

export default Slider;
