import * as fromActionTypes from 'store/actionTypes/resetPassword';

const initialState = {
  status: 'invalid',
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fromActionTypes.RESET_PASSWORD:
      return {
        ...state
      };
    case fromActionTypes.RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        status:'valid',
      };
    case fromActionTypes.RESET_PASSWORD_REJECTED:
      return {
        ...state,
        status:'invalid',
        error:action.payload
      };
    default:
      return state;
  }
};
