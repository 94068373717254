// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import styles from './TeamDiagramBarsNext.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { ImgPin, PillGroup } from 'ui/basic';

// UTILS
import { numberWithForcedDecimals } from 'utils/numberFormatting';

// STORE

// CONFIG & DATA
// const CONFIG = {
//   scaleMin: styles.scaleMin,
//   scaleMax: styles.scaleMax,
//   rowHeight: pxToNumber(styles.rowHeight),
//   barHeight: pxToNumber(styles.barHeight),
//   paddingBottom: pxToNumber(styles.paddingBottom),
// };

const CONFIG = {
  // diagram
  scaleMin: 1,
  scaleMax: 10,
  // dimensions
  rowHeight: 45,
  barHeight: 37,
  markerWidth: 30,
  labelMaxWidth: 165,
  paddingBottom: 20,
};

// COMPONENT: TeamDiagramBarsNext
const TeamDiagramBarsNext = (props) => {
  // PROPS
  const {
    // average
    labelAverage = '',
    showAverage = true,
    // range
    showRanges = false,
    labelRange = '',
    // all
    dimensions = [],
    onItemClick = () => {},
  } = props;

  // COMPONENT/UI STATE and REFS

  // buildup
  const [ isBuildUp, setIsBuildUp ] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBuildUp(false);
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // activeDimension & activeDimensionIndex
  const [ activeDimension, setActiveDimension ] = useState({ data: [] });
  const [ activeDimensionIndex, setActiveDimensionIndex ] = useState(0);
  useEffect(() => {
    const index = activeDimensionIndex >= dimensions.length - 1
      ? dimensions.length - 1
      : activeDimensionIndex;

    setActiveDimensionIndex(index);
    setActiveDimension(dimensions[index]);
  }, [dimensions, activeDimensionIndex]);

  // average
  const [ average, setAverage ] = useState();
  useEffect(() => {
    const average = calcAverage(activeDimension.data.map((item) => item.value));
    setAverage(average);
  }, [activeDimension]);

  // using htmlLayer as diagramEl because of SVG resizing issues
  const [ diagramNode, setDiagramNode ] = useState(null);
  const diagramNodeCb = useCallback((node) => {
    setDiagramNode(node);
  }, []);

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS
  const calcAverage = (data) => {
    return data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / data.length;
  };

  // EVENT HANDLES
  const handlePillClick = (item, index) => {
    setActiveDimensionIndex(index);
  };
  const handleItemClick = (item, index) => {
    onItemClick(item, index);
  };

  // HELPERS

  // RENDERS
  const renderGridLines = () => {
    const gridLineCount = CONFIG.scaleMax - CONFIG.scaleMin + 1;
    const gridLineArray = new Array(gridLineCount).fill(1);

    return gridLineArray.map((item, index) => {
      // the lines stop at the bottom of the last bar
      const y2 = CONFIG.rowHeight * (activeDimension.data.length - 1) + CONFIG.barHeight;
      return (
        <line key={index} x1={0} y1={0} x2={0} y2={y2} />
      );
    })
  }

  // RENDER: TeamDiagramBarsNext
  return (
    <div className={classNames(styles.teamDiagramBarsNext)}>

      {/* PILLGROUP WITH DIMENSIONS */}
      <PillGroup
        pillItems={dimensions.map(({ label }) => {return { label };})}
        onPillClick={handlePillClick}
      />

      {/* LEGEND */}
      <div className={styles.legend}>
        { new Array(CONFIG.scaleMax - CONFIG.scaleMin + 1).fill(0).map((item, index) => (
          <div key={index} className={styles.legendItem}>
            { index + CONFIG.scaleMin }
          </div>
        ))}
      </div>

      {/* DIAGRAM CONTAINER */}
      <div className={styles.diagramContainer}>

        {/* SVG */}
        <svg className={styles.svgContainer}>
          <svg className={styles.svg}>

            {/* GRID & LINES */}
            <g className={styles.grid}>
              { renderGridLines() }
            </g>

          </svg>
        </svg>

        {/* HTML LAYER */}
        <div className={styles.htmlLayer}
          ref={diagramNodeCb}>

          {/* BARS */}
          { diagramNode !== null && (
            <div className={styles.bars}>
              { activeDimension.data.map((item, index) => {

                const value = item.value;
                const label = item.label;

                const step = 100 / (CONFIG.scaleMax - CONFIG.scaleMin);
                const barWidth = isBuildUp
                  ? 0
                  : step * (value - CONFIG.scaleMin);

                // container at 100% for now
                // const barContainerWidth = `calc(${barWidth}% + ${CONFIG.markerWidth / 2}px`;

                const availableLabelSpace = diagramNode.clientWidth * barWidth / 100;
                const moveLabelToRight = availableLabelSpace < CONFIG.labelMaxWidth;

                // BAR CONTAINER
                return (
                  <div key={index}
                    className={styles.barContainer}
                    onClick={() => {
                      handleItemClick({
                          ...item,
                          dimensionId: activeDimension.id,
                          dimensionName: activeDimension.label
                        }, index
                      );
                    }}
                  >

                    {/* BAR */}
                    <div className={styles.bar}
                      style={{
                        width: `${barWidth}%`
                      }
                    }>
                      <div className={styles.barFill}></div>
                      <div className={styles.barHover}></div>
                      <div className={classNames(styles.barLabel, {
                        [styles.moveToRight]: moveLabelToRight
                      })}>
                        { label }
                      </div>

                      {/* MARKER */}
                      <div className={styles.marker}>
                        <ImgPin src={item.img} fullLabel={item.label} />
                      </div>
                    </div>

                  </div>
                );
              })}
            </div>
          )}

          {/* AVERAGE BAR*/}
          { (!showRanges && showAverage) && (
            <div className={styles.averageBarContainer}>
              <div className={styles.averageBar}
                style={{ marginLeft: `${(average - CONFIG.scaleMin) * 100 / (CONFIG.scaleMax - CONFIG.scaleMin)}%`}}></div>
            </div>
          )}

          {/* RANGE AREA */}
          { showRanges && activeDimension.range && (
            <div className={styles.rangeContainer}>
              <div className={styles.range}
                style={{
                  width: isBuildUp ? '0%' : `${( activeDimension.range[1] - activeDimension.range[0]) * 100 / (CONFIG.scaleMax - CONFIG.scaleMin)}%`,
                  marginLeft: isBuildUp ? '0%' : `${(activeDimension.range[0] - CONFIG.scaleMin) * 100 / (CONFIG.scaleMax - CONFIG.scaleMin)}%`,
                }}>
              </div>
            </div>
          )}

        </div>

      </div>

      {/* AVERAGE LABEL */}
      { (!showRanges && labelAverage && showAverage) && (
        <div className={styles.averageLabel}>
          { labelAverage }:
          <span className={styles.highlight}>
            { numberWithForcedDecimals(average) }
          </span>
        </div>
      )}

      {/* RANGE LABEL */}
      { showRanges && activeDimension.range && (
        <div className={styles.rangeLabel}>
          <span className={styles.rangeLegend}></span>
          { labelRange }: <span className={styles.highlight}>
            { numberWithForcedDecimals(activeDimension.range[0]) }-{ numberWithForcedDecimals(activeDimension.range[1]) }
          </span>
        </div>
      )}


    </div>
  );
};

export default TeamDiagramBarsNext;
