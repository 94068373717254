import { get } from 'api';
import * as action from 'store/actions';
import { call, put } from 'redux-saga/effects';
import {takeEvery} from '@redux-saga/core/effects';

export function* getAssessment(payload) {
  try {
    const id = payload.payload;
    const { status, ok, data } = yield call(get, `/core/assessments/${id}`);

    if (ok && status === 200) {
      const assessmentData = data;

      // set reference input type, min and max values
      let referenceInput = assessmentData.render_reference_input || 'stepped-scale';
      let referenceMin = (assessmentData.evaluation &&
        assessmentData.evaluation.normalize_to && assessmentData.evaluation.normalize_to.min) || 1;
      let referenceMax = (assessmentData.evaluation &&
        assessmentData.evaluation.normalize_to && assessmentData.evaluation.normalize_to.max) || 5;

      data.resultSchemaLanguage = localStorage.getItem('selectedLanguage');

      data.renderReferenceInput = referenceInput;
      data.renderReferenceMin = referenceMin;
      data.renderReferenceMax = referenceMax;

      // set info texts
      const infoTexts = data.info_texts;
      if (infoTexts) {
        const {required_time, short_description, long_description, guidance_text, question_help, result_description} = infoTexts;
        data.infoTexts = {
          requiredTime: required_time,
          shortDescription: short_description,
          longDescription: long_description,
          guidanceText: guidance_text,
          questionHelp: question_help,
          resultDescription: result_description
        }
      }

      yield put(action.getAssessmentFulfilled({ id, data }));
    } else {
      yield put(action.getAssessmentRejected({error: data.error}));
    }
  } catch (error) {
    yield put(action.getAssessmentRejected(error));
  }
}

export default function* watchgetAssessmentRequest() {
  yield takeEvery(action.GET_ASSESSMENT, getAssessment);
}
