import React, { useEffect, useRef } from 'react';
import styles from './Button.module.scss';
import classnames from 'classnames';
import { mapLooksToStyles } from 'utils';

const Button = (props) => {
  const {
    children,
    type,
    leadingIcon,
    trailingIcon,
    primaryIcon,
    looks = 'primary',
    disabled, active, width,
    showProgressIndicator,
    onClick
  } = props;
  let { size } = props;

  // defaults
  size = size || 'L';

  const cleanUp = () => {};
  const handleRender = () => {
    if (props.onRender) {
      props.onRender(ref.current);
    }
  };
  const initialize = () => {
    if (ref && ref.current)
      //setRipple(ref.current)
      if (props.onInitialized) {
        props.onInitialized(ref.current);
      }
  };
  const componentDidMount = () => {
    initialize();
    return cleanUp;
  };
  useEffect(componentDidMount, []);
  useEffect(handleRender);
  const w = width
    ? !isNaN(width)
      ? width + 'px'
      : width
    : '';
  const style = { width: w };
  const lookStyles = mapLooksToStyles(looks, styles);
  const ref = useRef();
  const sizeClass = `size${size.toUpperCase()}`;

  // HELPERS
  const buttonProps = {
    style,
    type,
    disabled,
  };

  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;
  const PrimaryIcon = primaryIcon;

  // RENDER: Button
  return (
    <button
      ref={ref}
      {...buttonProps}
      className={classnames(styles.button,
        lookStyles,
        {[styles[sizeClass]]: size},
        {[styles.iconOnly]: primaryIcon},
        {[styles.progressIndicator]: showProgressIndicator}
      )}
      active={active ? '' : null}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <div className={styles.content}>
        {/*ICON ONLY*/}
        {primaryIcon && <PrimaryIcon />}

        {/*NO ICON*/}
        {(!leadingIcon && !trailingIcon && !primaryIcon) &&
        <div>{children}</div>
        }

        {/*LEADING ICON*/}
        {leadingIcon &&
        <>
          <LeadingIcon />
          {children &&
          <div style={{marginLeft: styles.spaceXxs}}>
            {children}
          </div>
          }
        </>
        }

        {/*TRAILING ICON*/}
        {trailingIcon &&
        <>
          {children &&
          <div style={{marginRight: styles.spaceXxs}}>
            {children}
          </div>
          }
          <TrailingIcon />
        </>
        }

      </div>

      {showProgressIndicator &&
      <div className={styles.progressIndicator}></div>
      }
    </button>
  );
};

export default Button;
