import React, { useEffect, useState } from 'react';
import styles from './Footer.module.scss';

import { Link } from 'react-router-dom';

import { getLocal } from 'api';
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useSelector } from 'react-redux'
import { selectConfiguration } from 'features/framework/storeNext/configurationSlice'

const Footer = () => {

  // SPECIAL HOOKS
  const translate = useTranslate();

  // STORE NEXT
  const configuration = useSelector(selectConfiguration);

  const [versionInfo, setVersionInfo] = useState();
  const [showBuildInfo, setShowBuildInfo] = useState(false);

  useEffect(() => {
    const initialize = () => {
      getLocal('/version.json').then(result => {
        const { data } = result;
        setVersionInfo(data);
      });
    };
    initialize();
  }, []);

  const handleVersionClick = () => {
    console.log('configurationSlice', configuration, JSON.stringify(configuration, null, 2));
    setShowBuildInfo(!showBuildInfo)
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.left}>
        {translate('foo_item_copyright') || (
          <span>&copy; bluquist. All rights reserved.</span>
        )}
        {versionInfo && (
          <>
            <span onClick={handleVersionClick}>
              {' '}Version {versionInfo.version}.
            </span>
            { showBuildInfo &&
              <span>
                {' '}Build {versionInfo.shortBuild} {versionInfo.buildDetail}.
              </span>
            }
          </>
        )}
      </div>
      <div className={styles.right}>
        <Link to="/legal/provider">{translate('foo_item_provider') || 'Imprint'}</Link>
        <Link to="/legal/data-protection-privacy">{translate('foo_item_privacy') || 'Data protection & privacy'}</Link>
      </div>
    </footer>
  );
};

export default Footer;
